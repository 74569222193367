import { AnswerType } from '../../(일반채점)/일반채점입력'
import { 자동채점입력_객관식 } from './자동채점입력_객관식'
import { 자동채점입력_주관식 } from './자동채점입력_주관식'

export const UNKNOWN_VALUE = AnswerType.UNKNOWN

const 자동채점 = {
  객관식: 자동채점입력_객관식,
  주관식: 자동채점입력_주관식,
  UNKNOWN_VALUE,
}

export default 자동채점
