import { css } from '@emotion/react'
import styled from '@emotion/styled'

import { colors, typo } from '~/@common/styles'
import { mediaQuery } from '~/@common/styles/mediaQuery'

import _internal from './_internal'

const darkButtonStyle = css`
  background-color: ${colors.gray.$100};
  :active {
    background-color: ${colors.gray.$300};
  }
`

export const keypadHeight = {
  통합키패드: 223,
  초등학생용키패드: 275,
  소수키패드: 223,
} as const

export const keypadHeaderHeight = 40

const S = {
  KeypadButton: styled.button`
    display: flex;
    justify-content: center;
    align-items: center;
    color: ${colors.gray.$900};
    ${typo.heading04};
    font-weight: bold;
    background-color: ${colors.white};

    .dot-button {
      border: 2px solid ${colors.gray.$900};
      border-radius: 50%;
    }
    :active {
      background-color: ${colors.gray.$200};
    }
  `,
  KeypadBoard: styled.div`
    display: grid;
    background: ${colors.gray.$200};
    row-gap: 1px;
    column-gap: 1px;
    border-bottom-left-radius: 14px;
    border-bottom-right-radius: 14px;

    &.소수키패드 {
      height: ${keypadHeight.소수키패드}px;
      grid-template-columns: repeat(3, 1.73fr) 1fr;

      .${_internal.parts.button} {
        &:nth-of-type(4) {
          grid-column-start: 4;
          grid-row-end: span 2;
          ${darkButtonStyle};
        }
        &:nth-of-type(11) {
          grid-row-end: span 2;
          border-bottom-right-radius: 14px;
          ${darkButtonStyle};
        }
        &:nth-of-type(12) {
          border-bottom-left-radius: 14px;
        }
      }
    }

    &.초등학생용키패드 {
      height: ${keypadHeight.초등학생용키패드}px;
      grid-template-columns: repeat(6, 1fr);
      grid-template-rows: repeat(5, 55px);
      .${_internal.parts.button} {
        :nth-of-type(1),
        :nth-of-type(2),
        :nth-of-type(3),
        :nth-of-type(4) {
          ${darkButtonStyle};
        }
        :nth-of-type(5) {
          grid-column-end: 2 span;
          ${darkButtonStyle};
        }
        :nth-of-type(6),
        :nth-of-type(7),
        :nth-of-type(8),
        :nth-of-type(9),
        :nth-of-type(10),
        :nth-of-type(11),
        :nth-of-type(12),
        :nth-of-type(13),
        :nth-of-type(14),
        :nth-of-type(15),
        :nth-of-type(16),
        :nth-of-type(17) {
          grid-column-end: 2 span;
        }
        :nth-of-type(15) {
          border-bottom-left-radius: 14px;
        }
        :nth-of-type(17) {
          border-bottom-right-radius: 14px;
        }
      }
    }
    &.통합키패드 {
      height: ${keypadHeight.통합키패드}px;
      /* grid-template-columns: 62px 82px 82px 82px 62px; */
      grid-template-columns: 1fr 1.32fr 1.32fr 1.32fr 1fr;
      grid-template-rows: repeat(5, 55px);
      .${_internal.parts.button} {
        &:nth-of-type(1) {
          grid-column-start: 1;
          grid-row-end: span 2;
          ${darkButtonStyle};
        }
        &:nth-of-type(5),
        &:nth-of-type(9),
        &:nth-of-type(14),
        &:nth-of-type(18) {
          ${darkButtonStyle};
        }
        &:nth-of-type(10) {
          grid-row-end: span 2;
          border-bottom-left-radius: 14px;
          ${darkButtonStyle};
        }
        :nth-of-type(18) {
          border-bottom-right-radius: 14px;
        }
      }
    }
  `,
  KeyboardHead: styled.div`
    width: 100%;
    height: ${keypadHeaderHeight}px;
    background-color: ${colors.gray.$200};
    border-top-left-radius: 14px;
    border-top-right-radius: 14px;
    display: flex;
    justify-content: flex-end;
    align-items: center;

    button {
      padding: 9px 16px;
      ${typo.body02};
      font-weight: bold;
      color: ${colors.gray.$900};
    }
    ${mediaQuery.underTablet} {
      border-radius: 0;
    }
  `,
}

export default S
