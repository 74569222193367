import styled from '@emotion/styled'

import { useStudentAppMediaQuery } from '~/@common/hooks/useMediaQuery'
import { colors } from '~/@common/styles'
import { Card } from '~/@common/ui/(Card)'
import Table from '~/@common/ui/Table/Table'

const SKELETON_COL3_WIDTH_LIST = [515, 378, 600, 470, 591, 523, 452, 455, 320]

const StudentWorkbookTableSkeleton = () => {
  const { isMobile } = useStudentAppMediaQuery()
  return (
    <>
      {isMobile ? (
        <S.SkeletonCard>
          {SKELETON_COL3_WIDTH_LIST.map((width) => (
            <Card key={width}>
              <div className="dummy"></div>
            </Card>
          ))}
        </S.SkeletonCard>
      ) : (
        <S.SkeletonTable>
          <Table.Header ratio="30px 80px auto 176px" className="header-row">
            <Table.HeaderItem>
              <div className="col1" />
            </Table.HeaderItem>
            <Table.HeaderItem>
              <div className="skeleton-item col2" />
            </Table.HeaderItem>
            <Table.HeaderItem>
              <div
                className="skeleton-item col3"
                style={{ display: 'flex', justifyContent: 'flex-start' }}
              />
            </Table.HeaderItem>
            <Table.HeaderItem>
              <div className="skeleton-item col4" />
            </Table.HeaderItem>
          </Table.Header>
          <Table.Body>
            {SKELETON_COL3_WIDTH_LIST.map((width) => (
              <Table.Row
                key={width}
                ratio="30px 80px auto 176px"
                style={{ border: 'none', height: 70 }}
              >
                <Table.Item>
                  <div className="skeleton-item col1" />
                </Table.Item>
                <Table.Item>
                  <div className="skeleton-item col2" />
                </Table.Item>
                <Table.Item style={{ display: 'flex', justifyContent: 'flex-start' }}>
                  <div className="skeleton-item" style={{ width: width }} />
                </Table.Item>
                <Table.Item>
                  <div className="skeleton-item col4" />
                </Table.Item>
              </Table.Row>
            ))}
          </Table.Body>
        </S.SkeletonTable>
      )}
    </>
  )
}

export default StudentWorkbookTableSkeleton

const S = {
  SkeletonCard: styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;
    height: 100%;
    overflow: hidden;
    > * {
      flex-shrink: 0;
    }
    .dummy {
      height: 97px;
    }
  `,
  SkeletonTable: styled(Table)`
    display: flex;
    overflow: hidden;
    background-color: white;
    width: 100%;
    height: 100%;
    .skeleton-item {
      background-color: ${colors.gray.$400};
      height: 16px;
    }
    .col1,
    .col2,
    .col4 {
      width: 50px;
    }
    .col3 {
      width: 80px;
      margin-left: 15px;
    }
    .col5 {
      width: 94px;
    }
    .col6 {
      width: 86px;
    }
  `,
}
