import styled from '@emotion/styled'
import React from 'react'

import { colors } from '~/@common/styles'
import Radio from '~/@common/ui/radio'

import type { RadioGroupProps } from '../radio/group/RadioGroup'

export const SubTabTextRadioGroup = ({ children, ...props }: RadioGroupProps) => {
  return (
    <Radio.Group {...props}>
      <S.SubTabGroup data-component="ms__SubTabTextRadioGroup">{children}</S.SubTabGroup>
    </Radio.Group>
  )
}

export const SubTabButtonRadioGroup = ({ children, ...props }: RadioGroupProps) => {
  return (
    <Radio.Group {...props}>
      <S.SubTabButtonGroup data-component="ms__SubTabButtonRadioGroup">
        {children}
      </S.SubTabButtonGroup>
    </Radio.Group>
  )
}

const S = {
  SubTabGroup: styled.div`
    display: flex;
    gap: 10px;
    position: relative;

    ${`.SubTabTextRadioItem`} + ${`.SubTabTextRadioItem`} {
      display: flex;
      align-items: center;
      gap: 10px;
    }

    ${`.SubTabTextRadioItem`} + ${`.SubTabTextRadioItem`}:before {
      display: block;
      content: '';
      width: 2px;
      height: 10px;
      background-color: ${colors.gray.$500};
    }
  `,
  SubTabButtonGroup: styled.div`
    display: flex;
    gap: 8px;
  `,
}
