export const kind = 'ms__keyboard'

export default {
  kind,
  parts: {
    head: `${kind}_head`,
    board: `${kind}_board`,
    button: `${kind}_button`,
    completeButton: `${kind}_complete_button`,
    container: `${kind}_container`,
    floater: `${kind}_floater`,
    drawer: `${kind}_drawer`,
  },
}
