import { createContext } from '@radix-ui/react-context'
import type SwiperCore from 'swiper'

export const SwiperControllerConsumerName = 'ui/swiper-controller'

export const [SwiperControllerProvider, useSwiperControllerContext] = createContext<{
  control: SwiperCore | null
  initialized: boolean
  setSwiper: (SwiperCore) => void
  // control의 activeIndex는 observerable 하지 않아서 activeIndex를 만들어서 사용한 것. 꼭 하단의 activeIndex 사용하기
  activeIndex: number
  // slideNext?: () => void
  // slidePrev?: () => void
  isBeginningSlide: boolean
  isEndSlide: boolean
}>(SwiperControllerConsumerName)
