import modalService from '~/@common/services/modal.service'
import { colorTheme } from '~/@common/styles'
import Modal from '~/@common/ui/modal/Modal'

export const LearningUnavailableAlertModal = () => {
  return (
    <Modal.Alert
      css={{ width: 307 }}
      confirm={{
        onClick: () => {
          modalService.closeModal()
        },
        style: {
          background: colorTheme.primary,
        },
        children: '종료하기',
      }}
    >
      <p>
        학습이 불가합니다.
        <br />
        콘텐츠 업데이트 준비중입니다.
      </p>
    </Modal.Alert>
  )
}
