import styled from '@emotion/styled'
import type { ValueOf } from '@mathflat/shared/@types/utilityTypes'
import clsx from 'clsx'
import React, {
  type ComponentProps,
  type ComponentPropsWithoutRef,
  type ComponentPropsWithRef,
  forwardRef,
} from 'react'

import { colors } from '~/@common/styles'
import { mediaQuery } from '~/@common/styles/mediaQuery'

import type { LATEX_NODE_TYPE } from './_constants'
import { KEYPAD_BUTTONS } from './_constants'
import _internal from './_internal'
import S from './_Keypad.style'
import type { KeypadType } from './hook'
import type { VirtualKeyboardService } from './keypad.service'

// private UI only 컴포넌트들입니다. 외부에서 가져다 쓰지 마세요.
// 키패드는 기능연동을 위해 useTooltipVirtualKeypad를 이용해서 만들어야합니다.
export type KeypadButton = {
  value: string
  latexNodeType: ValueOf<typeof LATEX_NODE_TYPE>
  children?: React.ReactNode
  className?: string
}

type KeypadButtonProps = ComponentPropsWithoutRef<'button'> & KeypadButton

const KeypadButton = React.forwardRef<HTMLButtonElement, KeypadButtonProps>(
  ({ children, className, ...props }, ref) => {
    return (
      <S.KeypadButton
        ref={ref}
        {...props}
        className={clsx(_internal.parts.button, className)}
        data-keypad-type={_internal.parts.button}
      >
        {children}
      </S.KeypadButton>
    )
  },
)

KeypadButton.displayName = 'KeypadButton'

const KeypadBoard = ({
  keypadType,
  className,
  onKeypadButtonClick,
  ...props
}: {
  keypadType: KeypadType
  onKeypadButtonClick: (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    latexNodeType: ValueOf<typeof LATEX_NODE_TYPE>,
  ) => void
} & ComponentPropsWithRef<'div'>) => {
  const buttons = KEYPAD_BUTTONS[keypadType]

  return (
    <S.KeypadBoard className={clsx(_internal.parts.board, keypadType, className)} {...props}>
      {buttons.map((buttonInfo, index) => {
        return (
          <KeypadButton
            data-keypad-id={props['data-keypad-id']}
            key={`keypad_${index}`}
            {...buttonInfo}
            onClick={(e) => {
              e.stopPropagation()
              onKeypadButtonClick(e, buttonInfo.latexNodeType)
            }}
          >
            {buttonInfo.children ?? buttonInfo.value}
          </KeypadButton>
        )
      })}
    </S.KeypadBoard>
  )
}

const KeypadHead = ({
  closeKeypad,
  className,
  ...props
}: ComponentProps<'div'> & { closeKeypad: () => void }) => {
  return (
    <S.KeyboardHead className={clsx(_internal.parts.head, className)} {...props}>
      <button onClick={closeKeypad} data-keypad-type={_internal.parts.completeButton}>
        완료
      </button>
    </S.KeyboardHead>
  )
}

export const VirtualKeyboard = ({
  closeKeyboard,
  onKeyButtonClick,
  keypadType,
  className,
  ...props
}: {
  closeKeyboard: VirtualKeyboardService['close']
  onKeyButtonClick: VirtualKeyboardService['onKeyButtonClick']
  keypadType: VirtualKeyboardService['type']
} & ComponentProps<'div'>) => {
  return (
    <KeypadContainer className={clsx(_internal.parts.container, className)} {...props}>
      <KeypadHead closeKeypad={closeKeyboard} />
      <KeypadBoard onKeypadButtonClick={onKeyButtonClick} keypadType={keypadType} />
    </KeypadContainer>
  )
}

export const VirtualKeyboardFloater = forwardRef<
  HTMLDivElement,
  {
    closeKeyboard: VirtualKeyboardService['close']
    onKeyButtonClick: VirtualKeyboardService['onKeyButtonClick']
    keypadType: VirtualKeyboardService['type']
  } & ComponentPropsWithoutRef<'div'>
>(({ closeKeyboard, onKeyButtonClick, keypadType, className, ...props }, ref) => {
  return (
    <KeypadFloaterContainer
      className={clsx(_internal.parts.floater, className)}
      {...props}
      ref={ref}
      data-virtual-keyboard="true"
    >
      <div className="handler" />
      <VirtualKeyboard
        closeKeyboard={closeKeyboard}
        onKeyButtonClick={onKeyButtonClick}
        keypadType={keypadType}
      />
    </KeypadFloaterContainer>
  )
})

VirtualKeyboardFloater.displayName = 'VirtualKeyboardFloater'

export const VirtualKeyboardDrawer = ({
  open,
  className,
  closeKeyboard,
  keypadType,
  onKeyButtonClick,
  style,
  ...props
}: {
  open: VirtualKeyboardService['show']
  closeKeyboard: VirtualKeyboardService['close']
  onKeyButtonClick: VirtualKeyboardService['onKeyButtonClick']
  keypadType: VirtualKeyboardService['type']
} & ComponentProps<'div'>) => {
  return (
    <KeypadDrawer
      className={clsx(_internal.parts.drawer, className)}
      style={{ ...style, display: open ? 'block' : 'none' }}
      {...props}
    >
      <VirtualKeyboard
        closeKeyboard={closeKeyboard}
        keypadType={keypadType}
        onKeyButtonClick={onKeyButtonClick}
      />
    </KeypadDrawer>
  )
}

const KeypadDrawer = styled.div`
  position: sticky;
  border: none;
  padding: 0;
  background: transparent;
  bottom: 0;
  width: 100%;

  .${_internal.parts.container} {
    width: 100%;
    border-radius: 0;
  }

  .${_internal.parts.head} {
    border-radius: 0;
  }

  .${_internal.parts.button} {
    border-radius: 0 !important;
  }
`

const KeypadContainer = styled.div`
  display: flex;
  flex-direction: column;

  width: 375px;
  background-color: transparent;
  border-radius: 14px;
  overflow: hidden;
  box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.15);

  .handler {
    width: 50px;
    height: 5px;
    margin-bottom: 10px;
    border-radius: 5px;
    background: ${colors.gray.$500};
  }

  ${mediaQuery.underTablet} {
    width: 100%;
  }
`

const KeypadFloaterContainer = styled.div`
  display: flex;
  flex-direction: column;

  width: 375px;
  background-color: transparent;
  border-radius: 14px;
  cursor: grab;

  .handler {
    width: 50px;
    height: 5px;
    margin: 0 auto 10px;
    /* margin-bottom: 10px; */
    border-radius: 5px;
    background: ${colors.gray.$500};
  }

  ${mediaQuery.underTablet} {
    width: 100%;
  }
`

export default {
  Button: KeypadButton,
  Board: KeypadBoard,
  Head: KeypadHead,
  VirtualKeyboardDrawer,
  VirtualKeyboard,
  VirtualKeyboardFloater,
}
