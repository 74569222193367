import { css } from '@emotion/react'
import {
  HandwrittenNoteControllerService,
  HandwrittenNoteModeService,
  type HandwrittenNoteTypes,
  WritableHandwrittenNote,
} from '@mathflat/handwritten-note'
import clsx from 'clsx'
import { observer } from 'mobx-react'
import {
  type Dispatch,
  type ReactEventHandler,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'
import { isMobile as isMobileDevice, isTablet } from 'react-device-detect'

import { HandwrittenNoteType } from '~/@common/api/handwrittenNoteApi'
import { TOAST_STRING } from '~/@common/constants/strings'
import { useStudentAppMediaQuery } from '~/@common/hooks/useMediaQuery'
import { useRepository } from '~/@common/hooks/useRepository'
import { errorHandlerService, WorksheetProblemNoteService } from '~/@common/services'
import { AiTutorService } from '~/@common/services/AiTutor.service.ts'
import { IndexedDbService } from '~/@common/services/indexedDb.service'
import modalService from '~/@common/services/modal.service'
import { commonRepo } from '~/@common/services/repo.service.ts'
import { colors, fontWeight } from '~/@common/styles'
import Button from '~/@common/ui/(Button)/Button/Button'
import { Drawer } from '~/@common/ui/(Drawer)/BaseDrawer'
import { SwiperBackButton2, SwiperNextButton2 } from '~/@common/ui/(StyledButton)'
import { Icon } from '~/@common/ui/Icon/Icon'
import Modal from '~/@common/ui/modal/Modal'
import { SwiperController } from '~/@common/ui/SwiperController/SwiperController'
import {
  SwiperControllerConsumerName,
  useSwiperControllerContext,
} from '~/@common/ui/SwiperController/SwiperController.context'
import Switch from '~/@common/ui/Switch/Switch'
import type { Size } from '~/@common/utils/domUtils'
import type { ProblemScoring } from '~/@pages/@common/(ProblemScoring)/@trait/ProblemScoring.trait'
import { SubmittedAnswer } from '~/@pages/@common/(Scoring)/(채점결과)/채점결과'
import { WorksheetScoringCardMobile } from '~/@pages/@widgets/(Worksheet)/WorksheetScoring/(Body)/WorksheetScoringCard.mobile'
import WorksheetScoringByOne from '~/@pages/@widgets/(Worksheet)/WorksheetScoring/WorksheetScoringByOne'
import WorksheetScoringByOneMobile from '~/@pages/@widgets/(Worksheet)/WorksheetScoring/WorksheetScoringByOne.mobile'
import { HandwrittenNoteInfoTooltip } from '~/@pages/@widgets/Tooltip/HandwrittenNoteInfoTooltip'
import HandwrittenNoteMinimalToolbar from '~/@pages/student/@widgets/handwritten-note/HandwrittenNoteMinimalToolbar'
import { useLearningProcessReference } from '~/@pages/student/learning-process/@common/hooks/useGetLearningProcessReference'
import WorksheetAiTutor from '~/@pages/student/learning-process/WorksheetAiTutor.tsx'

import {
  LearningProcessService,
  REFERENCE_TYPE_STUDENT_WORKSHEET,
} from '../../../service/LearningProcess.service'
import WrongLearningVideo from './@widgets/WrongLearningVideo'
import S from './오답유형학습.style'

type Props = {
  isVideoTab: boolean
  setIsVideoTab: Dispatch<React.SetStateAction<boolean>>
  setIsOpenResultTable: Dispatch<React.SetStateAction<boolean>>
}

type NoteData = HandwrittenNoteTypes.NoteData

const WrongLearningView = observer(({ isVideoTab, setIsVideoTab, setIsOpenResultTable }: Props) => {
  const [incorrectProblemIndex, setIncorrectProblemIndex] = useState(0)
  const [imageUrl, setImageUrl] = useState('')
  const [noteContentSize, setNoteContentSize] = useState<Size>()
  const [isNoteContentReady, setIsNoteContentReady] = useState(false)
  const [isProblemImageDrawerOpened, setIsProblemImageDrawerOpened] = useState(false)

  const { isMobile } = useStudentAppMediaQuery()

  const service = useRepository(LearningProcessService)
  const indexedDbService = useRepository(IndexedDbService)
  const noteService = useRepository(WorksheetProblemNoteService)
  const handwrittenNoteModeService = useRepository(HandwrittenNoteModeService)
  const [noteControllerService] = useState(
    new HandwrittenNoteControllerService(handwrittenNoteModeService),
  )

  const [isAiTutorOpened, setIsAiTutorOpened] = useState(false)

  const noteContainerRef = useRef<HTMLDivElement>(null)

  const swiperController = useSwiperControllerContext(SwiperControllerConsumerName)

  const referenceParam = useLearningProcessReference()

  const currentIncorrectProblem = service.오답유형학습_틀린문제[incorrectProblemIndex]

  const resetScreen = useCallback(() => {
    setIsVideoTab(true)
    setIsOpenResultTable(false)
    swiperController.control?.slideTo(service.currentStatus.element, 200)
    setIncorrectProblemIndex(0)
  }, [service.currentStatus.element, swiperController.control])

  const aiTutorService = useRepository(AiTutorService)

  useEffect(() => {
    resetScreen()
  }, [service, service.currentStatus.screen])

  useEffect(() => {
    if (service.currentStatus.element === null) return
    if (isVideoTab) return

    service.changeElement(
      service.currentStatus.step,
      service.currentStatus.screen,
      swiperController.activeIndex,
    )

    aiTutorService.close()
  }, [service, swiperController.activeIndex])

  useEffect(() => {
    if (noteContentSize) {
      setIsNoteContentReady(true)
    }
  }, [noteContentSize])

  useEffect(() => {
    return () => {
      setIsProblemImageDrawerOpened(false)
    }
  }, [])

  const switchTabToPairSimilarProblem = () => {
    setIsProblemImageDrawerOpened(false)
    setIsVideoTab(false)
  }

  const handleImageLoad: ReactEventHandler<HTMLImageElement> = (e) => {
    const contentSize = {
      width: 1200,
      height: Math.max(729 - 40, e.currentTarget.height + 300),
    }
    setNoteContentSize(contentSize)
    setImageUrl(currentIncorrectProblem.problem.problemImageUrl)
  }

  const handleImageError: ReactEventHandler<HTMLImageElement> = (e) => {
    e.currentTarget.onerror = null
  }

  const noteReadFunc = useCallback(async () => {
    try {
      if (service.relationId) {
        const data = await indexedDbService.getStudentWorksheetNote({
          studentWorksheetId: parseInt(service.relationId),
          worksheetProblemId: currentIncorrectProblem.id,
        })
        if (data?.noteData) {
          return data.noteData
        }
      }
    } catch (err) {
      errorHandlerService.handle(err)
    }
  }, [incorrectProblemIndex, indexedDbService, service.relationId, service.오답유형학습_틀린문제])

  const noteWriteFunc = useCallback(
    async (data: NoteData) => {
      try {
        if (service.relationId) {
          await indexedDbService.putStudentWorksheetNote({
            studentWorksheetId: parseInt(service.relationId),
            worksheetProblemId: currentIncorrectProblem.id,
            noteData: data,
            timestamp: new Date().getTime(),
          })
        }
      } catch (err) {
        errorHandlerService.handle(err)
      }
    },
    [incorrectProblemIndex, indexedDbService, service.relationId, service.오답유형학습_틀린문제],
  )

  const onSubmitProblems = async () => {
    const { studentWorksheet } = service.오답유형학습_쌍둥이유사_문제풀이_현재탭_리스트

    // 학습지가 채점완료되었고, 마지막 탭일때 결과로 보냄
    if (studentWorksheet?.status === 'COMPLETE' && service.isLastScreen) {
      setIsOpenResultTable(true)
      return
    }

    // 마지막 Tab일때,
    if (service.isLastScreen) {
      service.isAllMarking
        ? modalService.openModal(
            <Modal.Alert
              confirm={{
                onClick: async () => {
                  if (!service.relationId) {
                    return
                  }
                  try {
                    modalService.showLoader()
                    modalService.closeModal()
                    // 학습지 상태가 완료일때만 보내기 TODO
                    const problemIds = await service.onSubmitPairAndSimilarWorksheetProblmes(
                      service.relationId,
                    )
                    await service.loadAndSetSelfLearningStudentWorksheets(service.relationId)

                    // 필기
                    if (problemIds?.length && service.relationId) {
                      try {
                        await noteService.uploadNotes(
                          Number(service.relationId),
                          problemIds,
                          HandwrittenNoteType.STUDENT_WORKSHEET_SCORING,
                        )
                        window.freshpaint?.track('필기 제출', {
                          ...referenceParam,
                        })
                      } catch (err) {
                        errorHandlerService.handle(err, {
                          message: TOAST_STRING.saveHandwrittenNoteFailed,
                          useRemoteLogging: true,
                        })
                      }
                    }
                    await service.getWrongLearningListAndUpdateScoring(service.relationId)
                    setIsOpenResultTable(true)
                  } catch (error) {
                    errorHandlerService.handle(error)
                  } finally {
                    modalService.hideLoader()
                  }
                },
                children: '제출하기',
              }}
            >
              <>
                제출한 후에는 답을 수정할 수 없어요.
                <br />
                그래도 제출할까요?
              </>
            </Modal.Alert>,
            { modalName: '자기주도학습_오답유형학습문제_제출하기' },
          )
        : modalService.openModal(
            <Modal.Alert
              confirm={{
                onClick: async () => {
                  modalService.closeModal()
                  if (!service.relationId) return

                  const { screenIndex, elementIndex } = service.unMarkedProblemIndexes
                  await service.changeElement(service.currentStatus.step, screenIndex, elementIndex)
                  if (screenIndex === service.currentStatus.screen) {
                    swiperController.control?.slideTo(elementIndex)
                  }
                  setIsVideoTab(false)
                },
                style: {
                  backgroundColor: colors.blue.$500,
                  color: 'white',
                },
              }}
            >
              <>
                다 풀지 않은 문제가 있어요.
                <br />
                모든 문제를 풀고 제출해주세요.
              </>
            </Modal.Alert>,
            { modalName: '자기주도학습_비활성화_단계' },
          )
    }
    // 마지막 탭이 아닌 탭들
    else {
      modalService.openModal(
        <Modal.Alert
          confirm={{
            children: '이동하기',
            onClick: () => {
              service.changeStepAndScreen(
                service.currentStatus.step,
                service.currentStatus.screen + 1,
              )
              setIsAiTutorOpened(false)
              modalService.closeModal()
            },
          }}
        >
          <>다음 오답 유형으로 이동합니다.</>
        </Modal.Alert>,
        { modalName: '자기주도학습_오답유형학습_마지막문제에서_다음탭이동' },
      )
    }
  }

  return (
    <S.Container className={isAiTutorOpened ? 'is-ai-tutor-opened' : ''}>
      <ProblemImageViewerDrawer
        currentIncorrectProblem={currentIncorrectProblem}
        isProblemImageDrawerOpened={isProblemImageDrawerOpened}
        setIsProblemImageDrawerOpened={setIsProblemImageDrawerOpened}
      />

      {isMobile ? (
        <>
          <S.ContentsTabMobile>
            <div
              onClick={() => setIsVideoTab(true)}
              className={clsx('tab', isVideoTab && 'active-tab')}
            >
              <Icon name="icon_play_circle" />
              <p>문제풀이 동영상</p>
            </div>
            <div
              onClick={switchTabToPairSimilarProblem}
              className={clsx('tab', !isVideoTab && 'active-tab')}
            >
              <Icon name="icon_papers" />
              <p>쌍둥이 ・ 유사문제 풀이</p>
            </div>
          </S.ContentsTabMobile>
          <S.ContentsMobile>
            {isVideoTab ? (
              <div className="video-tab-contents">
                <WrongLearningVideo />
                <div className="card-container">
                  <p className="card-title">틀린문제</p>
                  {service.학습모듈_문제풀이.worksheet &&
                    service.학습모듈_문제풀이.problemScoringViewOption && (
                      <WorksheetScoringCardMobile
                        key={currentIncorrectProblem.problem.id}
                        problemScoring={currentIncorrectProblem}
                        // 틀린문제의 type과 viewOption은 이전에 풀었던 학습모듈_문제풀이와 같다
                        type={service.학습모듈_문제풀이.worksheet?.type}
                        viewOption={service.학습모듈_문제풀이.problemScoringViewOption}
                      />
                    )}

                  {service.오답유형학습_틀린문제.length > 1 && (
                    <div className="problem-swiper-mobile">
                      <SwiperController
                        style={{ width: '120px', minWidth: '100%' }}
                        SlotLeft={
                          <SwiperBackButton2
                            iconSize={20}
                            onClick={() => setIncorrectProblemIndex((prev) => prev - 1)}
                            disabled={incorrectProblemIndex <= 0}
                          />
                        }
                        SlotRight={
                          <SwiperNextButton2
                            iconSize={20}
                            onClick={() => setIncorrectProblemIndex((prev) => prev + 1)}
                            disabled={
                              incorrectProblemIndex + 1 >= service.오답유형학습_틀린문제.length
                            }
                          />
                        }
                        SlotCenter={
                          <div>
                            {incorrectProblemIndex + 1}/{service.오답유형학습_틀린문제.length}
                          </div>
                        }
                      />
                    </div>
                  )}
                </div>
                <Button
                  theme="primary"
                  size="large"
                  style={{ margin: '20px 16px 40px' }}
                  onClick={() => setIsVideoTab(false)}
                >
                  쌍둥이・유사 문제 풀러가기
                </Button>
                {isTablet && <HandwrittenNoteInfoTooltip className="handwritten-info-tooltip" />}
              </div>
            ) : (
              <div className="scoring-swiper-container">
                <WorksheetScoringByOneMobile
                  problemScoringColl={
                    service.오답유형학습_쌍둥이유사_문제풀이_현재탭_리스트.problemScoringColl
                  }
                  viewOption={service.학습모듈_문제풀이.problemScoringViewOption}
                  onSubmit={onSubmitProblems}
                  studentWorksheet={
                    service.오답유형학습_쌍둥이유사_문제풀이_현재탭_리스트.studentWorksheet
                  }
                  type={service.오답유형학습_쌍둥이유사_문제풀이_현재탭_리스트.worksheet?.type}
                  problemIndex={service.currentStatus.element}
                />
              </div>
            )}
          </S.ContentsMobile>
        </>
      ) : (
        <>
          <S.ContentsTab className={isAiTutorOpened ? 'is-ai-tutor-opened' : ''}>
            <div className="title">{service.오답유형학습_문제풀이동영상.concept.name}</div>
            {!isVideoTab &&
              referenceParam?.referenceType !== REFERENCE_TYPE_STUDENT_WORKSHEET &&
              commonRepo.Ai튜터_사용가능_여부 && (
                <WorksheetAiTutor
                  problemScoring={
                    service.오답유형학습_쌍둥이유사_문제풀이_현재탭_리스트.problemScoringColl[
                      service.currentStatus.element
                    ]
                  }
                  onClick={() => setIsAiTutorOpened?.((prev) => !prev)}
                  isSelected={isAiTutorOpened}
                />
              )}

            <div className="problem-tab">
              <div
                onClick={() => {
                  setIsAiTutorOpened(false)
                  setIsVideoTab(true)
                }}
                className={clsx(!isVideoTab && 'disabled-tab')}
              >
                <Icon name="icon_play_circle" />
                <p>문제풀이 동영상</p>
              </div>
              <div
                onClick={switchTabToPairSimilarProblem}
                className={clsx(isVideoTab && 'disabled-tab')}
              >
                <Icon name="icon_papers" />
                <p>쌍둥이 ・ 유사문제 풀이</p>
              </div>
            </div>
          </S.ContentsTab>
          <S.Contents className={clsx(!isVideoTab && 'full-tab')}>
            {isVideoTab && service.오답유형학습_문제풀이동영상 && <WrongLearningVideo />}
            <div className={clsx('problem', isAiTutorOpened && 'is-ai-tutor-opened')}>
              {isVideoTab ? (
                <div className="problem-video">
                  <div className="problem-infos">
                    <div className="title-container">
                      <div className="title">틀린 문제</div>
                      <p>
                        문제 번호:
                        {currentIncorrectProblem.문제번호}
                      </p>
                    </div>
                    <div className="info-container">
                      <div className="info">
                        <p>채점결과</p>
                        {(() => {
                          switch (currentIncorrectProblem.채점결과) {
                            case 'CORRECT':
                              return <Icon name="icon_answer_correct" color={colors.blue.$300} />
                            case 'WRONG':
                              return <Icon name="icon_answer_wrong" color={colors.red.$300} />
                            case 'UNKNOWN':
                              return <Icon name="icon_answer_question" color={colors.yellow} />
                          }
                        })()}
                      </div>
                      <Switch
                        className="info__right-switch"
                        checked={isProblemImageDrawerOpened}
                        onChange={() => setIsProblemImageDrawerOpened((prev) => !prev)}
                      >
                        <span>내가 쓴 답 ・ 정답 ・ 해설 보기</span>
                      </Switch>
                    </div>
                  </div>
                  {noteContentSize && (
                    <div css={_noteContainerCss} ref={noteContainerRef}>
                      <WritableHandwrittenNote
                        contentSize={noteContentSize}
                        controllerService={noteControllerService}
                        readFunc={noteReadFunc}
                        writeFunc={noteWriteFunc}
                        saveDelay={100}
                        preventOverflow={isMobileDevice}
                        style={{
                          overflow: 'hidden',
                          backgroundColor: colors.gray.$500,
                        }}
                      >
                        <img src={imageUrl} width={344} alt="문제이미지" />
                      </WritableHandwrittenNote>
                      {isNoteContentReady && noteContainerRef.current && (
                        <HandwrittenNoteMinimalToolbar
                          containerEl={noteContainerRef.current}
                          containerPadding={0}
                          right={0}
                          top={0}
                          controllerService={noteControllerService}
                        />
                      )}
                    </div>
                  )}
                  <div className="problem-image-container">
                    <div className="problem-image">
                      <img
                        onError={handleImageError}
                        onLoad={handleImageLoad}
                        src={currentIncorrectProblem.문제이미지}
                        css={_preloadImageCss}
                      />
                    </div>
                  </div>
                  <div className="problem-button">
                    {service.오답유형학습_틀린문제.length > 1 && (
                      <div className="problem-swiper">
                        <SwiperController
                          SlotLeft={
                            <SwiperBackButton2
                              iconSize={20}
                              onClick={() => setIncorrectProblemIndex((prev) => prev - 1)}
                              disabled={incorrectProblemIndex <= 0}
                            />
                          }
                          SlotRight={
                            <SwiperNextButton2
                              iconSize={20}
                              onClick={() => setIncorrectProblemIndex((prev) => prev + 1)}
                              disabled={
                                incorrectProblemIndex + 1 >= service.오답유형학습_틀린문제.length
                              }
                            />
                          }
                          SlotCenter={
                            <div>
                              {incorrectProblemIndex + 1}/{service.오답유형학습_틀린문제.length}
                            </div>
                          }
                        />
                      </div>
                    )}
                    <Button
                      theme="primary"
                      size="small"
                      style={{ width: 180, height: 36 }}
                      onClick={switchTabToPairSimilarProblem}
                    >
                      쌍둥이・유사 문제 풀러가기
                    </Button>
                  </div>
                </div>
              ) : service.오답유형학습_쌍둥이유사_문제풀이_현재탭_리스트.problemScoringColl?.toArr
                  .length && service.relationId ? (
                <WorksheetScoringByOne
                  problemScoringColl={
                    service.오답유형학습_쌍둥이유사_문제풀이_현재탭_리스트.problemScoringColl
                  }
                  viewOption={
                    service.오답유형학습_쌍둥이유사_문제풀이_현재탭_리스트.problemScoringViewOption
                  }
                  onSubmit={onSubmitProblems}
                  studentWorksheet={
                    service.오답유형학습_쌍둥이유사_문제풀이_현재탭_리스트.studentWorksheet
                  }
                  worksheet={service.오답유형학습_쌍둥이유사_문제풀이_현재탭_리스트.worksheet}
                  isLastScreen={service.isLastScreen}
                  problemIndex={service.currentStatus.element}
                  isAiTutorOpened={isAiTutorOpened}
                  setIsAiTutorOpened={setIsAiTutorOpened}
                />
              ) : (
                <S.EmptyView>
                  <img
                    src={`/images/icons/icon_description_paper_circle.svg`}
                    width={50}
                    height={50}
                    alt="쌍둥이유사문제없음"
                  />
                  <p>매칭된 쌍둥이・유사문제가 없어요.</p>
                  <p>다음 문제를 풀어보세요.</p>
                  <Button
                    onClick={onSubmitProblems}
                    size="small"
                    theme="primary"
                    type="submit"
                    minWidth={136}
                  >
                    {service.isLastScreen ? '제출하기' : '다음'}
                  </Button>
                </S.EmptyView>
              )}
            </div>
          </S.Contents>
        </>
      )}
    </S.Container>
  )
})

export default WrongLearningView

const ProblemImageViewerDrawer = ({
  isProblemImageDrawerOpened,
  setIsProblemImageDrawerOpened,
  currentIncorrectProblem,
}: {
  isProblemImageDrawerOpened: boolean
  setIsProblemImageDrawerOpened: (isProblemImageDrawerOpened: boolean) => void
  currentIncorrectProblem: ProblemScoring<'WORKSHEET'>
}) => {
  const { isDesktop } = useStudentAppMediaQuery()

  return (
    <Drawer
      isOpened={isProblemImageDrawerOpened}
      closeDrawer={() => setIsProblemImageDrawerOpened(false)}
      rootSelector="#app"
      position="absolute"
      placement="left"
      hasBorderRadius={false}
      showBackdrop={false}
    >
      <Drawer.Content
        style={{
          width: isDesktop ? 'calc((100% - 1280px) / 2 + 526px)' : '534px',
        }}
        css={_drawerContentCss}
      >
        <Drawer.Header css={_drawerHeaderCss}>내가 쓴 답 ・ 정답 ・ 해설</Drawer.Header>
        <Drawer.Body>
          <div css={_drawerBodyCss}>
            <section className="problem-image__container">
              <h4 className="container-heading">내가 쓴 답</h4>
              {currentIncorrectProblem.제출한답 === 'UNKNOWN' ? (
                '모름'
              ) : (
                <div className="submitted-answer__wrapper">
                  <SubmittedAnswer
                    제출한답={currentIncorrectProblem.제출한답}
                    문제정답타입={currentIncorrectProblem.problem.type}
                    채점결과={currentIncorrectProblem.채점결과}
                  />
                </div>
              )}
            </section>
            <section className="problem-image__container">
              <h4 className="container-heading">정답</h4>
              <img
                className="container-img__answer"
                src={currentIncorrectProblem.problem.answerImageUrl}
              />
            </section>
            <section className="problem-image__container">
              <h4 className="container-heading">해설</h4>
              <img
                className="container-img"
                src={currentIncorrectProblem.problem.solutionImageUrl}
              />
            </section>
          </div>
        </Drawer.Body>
      </Drawer.Content>
    </Drawer>
  )
}

const _preloadImageCss = css`
  position: absolute;
  left: -999999px;
`

const _noteContainerCss = css`
  position: relative;
  display: flex;
  flex-grow: 1;
  margin-bottom: 20px;
  overflow: hidden;
`

const _drawerContentCss = css`
  min-width: 534px;
  height: calc(100vh - 62px);
  height: calc(100dvh - 62px);
  box-shadow: 10px 4px 20px 0px rgba(0, 0, 0, 0.05);
  margin-top: 0;
`

const _drawerHeaderCss = css`
  border-top: 1px solid ${colors.gray.$300};
  border-radius: 0px;
`

const _drawerBodyCss = css`
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px;

  .problem-image__container {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding-bottom: 20px;
    :not(:last-of-type) {
      border-bottom: 1px solid ${colors.gray.$300};
    }
    .container-heading {
      color: ${colors.gray.$900};
      font-weight: ${fontWeight.bold};
    }
    .container-img {
      width: 344px;
    }

    .container-img__answer {
      width: fit-content;
      height: auto;
    }

    .submitted-answer__wrapper {
      display: flex;
      gap: 4px;
    }
  }
`
