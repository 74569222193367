import styled from '@emotion/styled'
import { observer } from 'mobx-react'
import { useLocation, useNavigate } from 'react-router'

import { routeName } from '~/@common/constants/route'
import { useStudentAppMediaQuery } from '~/@common/hooks/useMediaQuery'
import { useCallbackOnVisibilityChange } from '~/@common/hooks/useCallbackOnVisibilityChange'
import { mediaQuery } from '~/@common/styles/mediaQuery'
import { SubTabTextRadioGroup } from '~/@common/ui/SubTab/SubTabGroup'
import { SubTabButtonGroupItem, SubTabTextRadioGroupItem } from '~/@common/ui/SubTab/SubTabItem'

import StudentWorkbookListWidget from './@widgets/StudentWorkbookList/StudentWorkbooklist.widget'

const StudentWorkbookListPage = () => {
  useCallbackOnVisibilityChange()

  const { pathname } = useLocation()
  const navigate = useNavigate()
  const { isMobile } = useStudentAppMediaQuery()

  const TabComponent = isMobile
    ? SubTabTextRadioGroupItem
    : (props) => <SubTabButtonGroupItem {...props} minWidth={188} />

  return (
    <S.StudentWorkListPage>
      <div className="sub-tab">
        <SubTabTextRadioGroup
          value={pathname}
          onChange={(path) => {
            navigate(path as string)
          }}
        >
          <TabComponent value={routeName.student.generalStudentWorkbookList}>일반교재</TabComponent>
          <TabComponent value={routeName.student.customSignatureStudentWorkbookList}>
            시그니처교재
          </TabComponent>
        </SubTabTextRadioGroup>
      </div>
      {/* 리스트 테이블 */}
      <StudentWorkbookListWidget />
    </S.StudentWorkListPage>
  )
}

export default observer(StudentWorkbookListPage)

const S = {
  StudentWorkListPage: styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 100%;
    height: 100%;
    padding: 8px 16px 0;
    .sub-tab {
      display: flex;
      justify-content: center;
      flex: 0 0 38px;
    }
    ${mediaQuery.underTablet} {
      gap: 8px;
      .sub-tab {
        justify-content: start;
        align-items: center;
        padding-block: 10px;
      }
    }
  `,
}
