import { css } from '@emotion/react'
import type { HandwrittenNoteControllerService } from '@mathflat/handwritten-note'
import { handwrittenNoteConsts, type HandwrittenNoteTypes } from '@mathflat/handwritten-note'
import { s3URL } from '@mathflat/shared/@common/utils/s3'
import clsx from 'clsx'
import { observer } from 'mobx-react'
import React, { type FC, useMemo } from 'react'

import modalService from '~/@common/services/modal.service.tsx'
import { colors, typo } from '~/@common/styles'
import { Icon } from '~/@common/ui/Icon/Icon.tsx'
import type { IconNames } from '~/@common/ui/Icon/iconNames.type.ts'
import Modal from '~/@common/ui/modal/Modal.tsx'
import Switch, { parts } from '~/@common/ui/Switch/Switch.tsx'

import HandwrittenToolbarEraseAllIcon from './HandwrittenToolbarEraseAllIcon.tsx'

const { ERASER_WIDTHS, HIGHLIGHT_PEN_COLORS, HIGHLIGHT_PEN_WIDTHS, PEN_COLORS, PEN_WIDTHS } =
  handwrittenNoteConsts

type Command = HandwrittenNoteTypes.Command
type Tool = HandwrittenNoteTypes.Tool

interface ToolbarData {
  type: Command['type'] | Tool
  iconName: IconNames
  marginLeft?: number
  label?: string
  widths?: number[]
  colors?: string[]
  disabled?: boolean
}

interface Props {
  top?: number
  uiMode?: 'normal' | 'simple' | 'minimal'
  disabled?: boolean
  controllerService: HandwrittenNoteControllerService
}

const HandwrittenNoteToolbar: FC<Props> = ({
  top,
  disabled,
  controllerService,
  uiMode = 'normal',
}) => {
  const { activeTool, toolbarMode, toolWidth, toolColor } = controllerService
  const noteService = controllerService.noteService
  const noteStatus = noteService?.noteData.status

  const toolbarData = useMemo(() => {
    const items: ToolbarData[] = [
      {
        type: 'undo',
        iconName: 'icon_undo',
        disabled: !noteStatus?.canUndo,
      },
      {
        type: 'redo',
        iconName: 'icon_redo',
        marginLeft: 4,
        disabled: !noteStatus?.canRedo,
      },
      {
        type: 'pen',
        iconName: 'icon_pen',
        marginLeft: uiMode === 'simple' ? 4 : 20,
        label: '펜 설정',
        widths: PEN_WIDTHS,
        colors: PEN_COLORS,
      },
      {
        type: 'highlight-pen',
        iconName: 'icon_highlight_pen',
        marginLeft: 4,
        label: '형광펜 설정',
        widths: HIGHLIGHT_PEN_WIDTHS,
        colors: HIGHLIGHT_PEN_COLORS,
      },
      {
        type: 'eraser',
        iconName: 'icon_erase',
        marginLeft: 4,
        label: '지우개 설정',
        widths: ERASER_WIDTHS,
      },
      {
        type: 'erase-all',
        iconName: 'icon_erase',
        marginLeft: 4,
        disabled: !noteStatus?.canEraseAll,
      },
    ]

    if (uiMode === 'simple') {
      items.splice(0, 0, {
        type: 'hidden',
        iconName: 'icon_eye_opened',
      })
    }
    return items
  }, [noteStatus, uiMode])

  const activeToolData = useMemo(() => {
    return toolbarData.find((item) => item.type === activeTool)
  }, [activeTool, toolbarData])

  const handleToolCommand = (type: ToolbarData['type']) => {
    switch (type) {
      case 'undo':
      case 'redo':
      case 'hidden':
        controllerService.command({ type, isToggle: uiMode === 'simple' })
        break
      case 'pen':
      case 'highlight-pen':
      case 'eraser':
        controllerService.command({ type: 'tool', payload: type, isToggle: uiMode === 'simple' })
        break
      case 'erase-all':
        modalService.openModal(
          <Modal.Confirm.Positive
            css={{ width: 310 }}
            cancel={{
              children: '취소하기',
            }}
            confirm={{
              children: '삭제하기',
              onClick: async () => {
                controllerService.command({ type: 'erase-all' })
                modalService.closeModal()
              },
            }}
          >
            <p>필기 내용이 모두 삭제됩니다.</p>
          </Modal.Confirm.Positive>,
          {
            modalName: '필기 전체 삭제',
          },
        )
        break
    }
  }

  const openInteractionGuideModal = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation()
    modalService.openModal(<필기인터랙션가이드 />, {
      modalName: '필기 인터랙션 가이드',
      modalStyle: {
        padding: '0px',
      },
    })
  }

  if (!noteService) {
    return
  }

  const isHideSubToolbar = !controllerService.isWritingMode
  const isHideMainToolbar = uiMode !== 'simple' && toolbarMode === 'simple'

  return (
    <div
      css={_Style}
      className={clsx(uiMode === 'simple' && 'simple')}
      style={{
        top: top !== undefined ? `${top}px` : undefined,
      }}
    >
      <ul
        className="toolbar"
        style={{
          backgroundColor: uiMode === 'simple' ? 'transparent' : undefined,
          border: uiMode === 'simple' ? 0 : undefined,
          display: isHideMainToolbar ? 'none' : undefined,
        }}
      >
        {toolbarData.map((item) => {
          let isOn = item.type === activeTool && !disabled

          if (item.type === 'hidden' && !controllerService.isHiddenNote) {
            isOn = true
          }

          if (uiMode === 'simple' && !controllerService.isWritingMode && item.type !== 'hidden') {
            isOn = false
          }

          const iconSize = item.iconName === 'icon_eye_opened' ? 20 : 40
          const iconName = item.iconName

          if (iconName === 'icon_eye_opened' && controllerService.isHiddenNote) {
            // iconName = 'icon_eye_closed'
          }
          let isButtonDisabled = item.disabled || disabled

          if (
            ['undo', 'redo', 'erase-all'].includes(item.type) &&
            (controllerService.isHiddenNote || !controllerService.isWritingMode)
          ) {
            isButtonDisabled = true
          }

          return (
            <li
              key={item.type}
              className={clsx('item', {
                on: isOn,
              })}
              style={{
                marginLeft: item.marginLeft,
              }}
            >
              <button
                type="button"
                disabled={isButtonDisabled}
                onClick={(e) => {
                  if (item.type === 'erase-all') {
                    e.stopPropagation()
                  }
                  handleToolCommand(item.type)
                }}
                style={{
                  width: '40px',
                  height: '40px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  color: isButtonDisabled
                    ? colors.gray.$400
                    : isOn
                      ? colors.blue.$500
                      : colors.gray.$800,
                }}
              >
                {item.type === 'erase-all' ? (
                  <HandwrittenToolbarEraseAllIcon />
                ) : (
                  <Icon name={iconName} size={iconSize} />
                )}
              </button>
            </li>
          )
        })}
      </ul>
      {activeToolData && toolbarMode === 'full' && !disabled && !isHideSubToolbar && (
        <div className={clsx('sub-toolbar', `sub-toolbar-${activeToolData.type}`)}>
          {activeToolData.label && (
            <div className="label">
              {activeToolData.label}
              <Switch
                className="switch"
                checked={controllerService.needExtraFinger}
                onChange={() =>
                  controllerService.setNeedExtraFinger(!controllerService.needExtraFinger)
                }
              >
                <button className="interaction-guide-button" onClick={openInteractionGuideModal}>
                  손으로 쓰기
                  <Icon name="icon_info_fill" size={20} color={colors.gray.$500} />
                </button>
              </Switch>
            </div>
          )}
          {activeToolData.widths && (
            <ul className="width-preset">
              {activeToolData.widths.map((width) => {
                const isEraserWidth = activeToolData.type === 'eraser'
                const isOn = width === toolWidth
                return (
                  <li
                    key={width}
                    className={clsx('sub-item', {
                      on: isOn,
                    })}
                  >
                    <button
                      type="button"
                      onClick={() =>
                        controllerService.command({ type: 'tool-width', payload: width })
                      }
                    >
                      <div
                        style={{
                          width: `${width}px`,
                          height: `${width}px`,
                          backgroundColor: isEraserWidth ? 'rgba(51, 51, 51, 0.05)' : toolColor,
                          border: isEraserWidth ? `1px solid ${colors.gray.$500}` : '0',
                          borderRadius: '999px',
                        }}
                      ></div>
                    </button>
                    {isOn && activeToolData.type === 'eraser' && (
                      <Icon
                        className="icon-check"
                        name="icon_check_small"
                        color={colors.gray.$700}
                        size={10}
                      />
                    )}
                  </li>
                )
              })}
            </ul>
          )}
          {activeToolData.colors && (
            <>
              <div className="delimiter"></div>
              <ul className="color-preset">
                {activeToolData.colors.map((color) => {
                  const isOn = color === toolColor
                  return (
                    <li
                      key={color}
                      className={clsx({
                        on: isOn,
                      })}
                    >
                      <button
                        type="button"
                        style={{
                          backgroundColor: color,
                        }}
                        onClick={() =>
                          controllerService.command({ type: 'tool-color', payload: color })
                        }
                      ></button>
                      {isOn && (
                        <Icon
                          className="icon-check"
                          name="icon_check_small"
                          color={colors.white}
                          size={10}
                        />
                      )}
                    </li>
                  )
                })}
              </ul>
            </>
          )}
          {!disabled && (
            <div className="sub-toolbar-action">
              <button type="button" onClick={() => controllerService.setToolbarMode('simple')}>
                <Icon name="icon_chevron_down" size={24} rotate={180} />
              </button>
            </div>
          )}
        </div>
      )}
      {controllerService.isWritingMode && controllerService.toolbarMode !== 'full' && !disabled && (
        <button
          type="button"
          className="ui-mode-toggle"
          style={{
            top: uiMode === 'normal' || isHideMainToolbar ? 0 : undefined,
          }}
          onClick={() => controllerService.setToolbarMode('full')}
        >
          <Icon name="icon_chevron_down" size={20} />
        </button>
      )}
    </div>
  )
}

export default observer(HandwrittenNoteToolbar)

const _Style = css`
  width: fit-content;
  position: absolute;
  top: 70px;
  right: 0;
  display: flex;
  flex-direction: column;
  gap: 6px;
  align-items: center;
  z-index: 2;

  &.simple {
    gap: 5px;

    .toolbar {
      padding: 10px 0;
    }

    .sub-toolbar {
      border-radius: 0 0 14px 14px;
      background-color: ${colors.white};
      border-color: ${colors.gray.$400};
    }
    .ui-mode-toggle {
      top: 78px;
      right: 128px;
    }
  }

  ul {
    display: flex;
  }

  .toolbar {
    width: 100%;
    display: flex;
    border-radius: 14px;
    border: 1px solid ${colors.gray.$400};
    background: ${colors.gray.$100};
    padding: 10px;
  }

  button {
    border-radius: 8px;
    position: relative;
    &[disabled] {
      pointer-events: none;
    }

    > svg {
      display: block;
    }
  }

  .on > button {
    background-color: ${colors.gray.$300};
  }

  .item {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;

    .erase-all-text {
      width: 18.6px;
      height: 9.5px;
      position: absolute;
      bottom: 10px;
      right: 7px;
    }
  }

  .sub-toolbar {
    width: 100%;
    padding: 20px 23px 12px 24px;
    border-radius: 14px;
    border: 1px solid ${colors.gray.$400};
    background: ${colors.gray.$100};
    display: flex;
    flex-direction: column;
    gap: 14px;

    .label {
      ${typo.body02};
      font-weight: 700;
      color: ${colors.black};
      display: flex;
      align-items: center;

      .switch {
        margin-left: auto;
        font-weight: normal;
      }

      .interaction-guide-button {
        display: flex;
        align-items: center;
        gap: 4px;
      }
    }

    .delimiter {
      height: 1px;
      background-color: ${colors.gray.$300};
    }

    .icon-check {
      position: absolute;
      top: 50%;
      left: 50%;
      margin-left: -5px;
      margin-top: -5px;
    }

    .width-preset,
    .color-preset {
      justify-content: space-between;
    }

    .width-preset > button {
      width: auto;
      height: auto;
    }

    .color-preset {
      height: 36px;
    }

    .color-preset > li {
      border: 4px solid transparent;
      border-radius: 10px;
      position: relative;

      &.on {
        border-color: ${colors.gray.$300};
      }
    }

    .color-preset button {
      width: 28px;
      height: 28px;

      > div {
        height: 100%;
      }
    }

    .sub-toolbar-action button {
      display: block;
      margin: 0 auto;
    }

    .sub-item {
      display: flex;

      & > button {
        width: 36px;
        height: 36px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
  .sub-toolbar-eraser {
    .width-preset {
      width: 220px;
      height: 40px;
      margin: 0 auto;
      overflow: hidden;
      justify-content: center;
      gap: 47.5px;
    }
    .sub-item {
      position: relative;

      & > button {
        width: auto;
        height: auto;
        background-color: transparent;
      }
    }
  }
  .ui-mode-toggle {
    position: absolute;
    right: 8px;
    top: 72px;
    width: 48px;
    height: 32px;
    border: 1px solid ${colors.gray.$400};
    border-radius: 4px;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`

const 필기인터랙션가이드 = () => {
  return (
    <div css={_modalStyle}>
      <button className="close-button" type="button" onClick={() => modalService.closeModal()}>
        <Icon name="icon_close" color={colors.gray.$900} size={24} />
      </button>
      <img
        alt="필기 인터랙션 가이드"
        src={s3URL.student('images/note_interaction_guide@3x.png')}
        width={898}
        height={615}
      />
    </div>
  )
}

const _modalStyle = css`
  position: relative;
  height: 615px;
  overflow-y: hidden;
  overflow-x: auto;

  img {
    border-radius: 14px;
  }

  .close-button {
    position: absolute;
    height: 78px;
    padding: 27px 24px;
    top: 0;
    right: 0;
  }
`
