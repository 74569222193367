import { useComposedRefs } from '@mathflat/design-system/@common/utils/composeRefs.tsx'
import type { ComponentProps } from 'react'
import React, { useRef } from 'react'

import { colors } from '~/@common/styles'

import IconButton from '../../(Button)/IconButton/IconButton'
import Input from '../Input/Input'

export const kind = 'ms__SearchInput' as const

export interface Props extends Omit<ComponentProps<'input'>, 'value'> {
  onSearch: (text: string) => void
}

const SearchInput = React.forwardRef<HTMLInputElement, Props>(
  ({ onSearch, defaultValue = '', ...props }, ref) => {
    const _ref = useRef<HTMLInputElement | null>(null)

    const composeRefs = useComposedRefs(ref, _ref)
    return (
      <>
        <Input
          {...props}
          defaultValue={defaultValue}
          onChange={(e) => {
            props.onChange?.(e)
          }}
          onKeyUp={(e) => {
            props.onKeyUp?.(e)
            if (e.key === 'Enter') {
              if (e.target instanceof HTMLInputElement) {
                onSearch(e.target.value)
              }
            }
          }}
          ref={composeRefs}
          hasInitIconButton={true}
        />
        <IconButton
          name="icon_search"
          iconSize={20}
          iconStyle={{
            color: colors.gray.$600,
          }}
          onClick={() => {
            if (!_ref.current) return
            onSearch(_ref.current.value)
          }}
        />
      </>
    )
  },
)
SearchInput.displayName = 'Input'

export default SearchInput
