import styled from '@emotion/styled'
import { format } from 'date-fns'
import { throttle } from 'lodash'
import { useRef, useState } from 'react'
import { isMobile } from 'react-device-detect'

import { colors, typo } from '~/@common/styles'
import type { StyleProps } from '~/@common/types'

import type { PlayerTime } from '../MathflatPlayer'

type Props = {
  handleSeeking: (time: number) => void
  playerTime: PlayerTime
} & StyleProps

const SeekBar = ({ handleSeeking, playerTime, ...props }: Props) => {
  const timelineRef = useRef<HTMLDivElement>(null)
  const [showTime, setShowTime] = useState(false)
  const [hoverTime, setHoverTime] = useState<null | number>(null)
  const [isMouseDown, setIsMouseDown] = useState(false)

  const getTime = (event) => {
    if (timelineRef.current) {
      const isTouchEvent = event.touches?.[0] // touchstart => mousemove => mousedown 순으로 실행되므로 touchstart 일때만 처리
      const clientX = isMobile && isTouchEvent ? event.touches[0].clientX : event.clientX

      const timelineRect = timelineRef.current.getBoundingClientRect()
      const offsetX = clientX - timelineRect.left

      const timelineWidth = timelineRect.width
      const time = (offsetX / timelineWidth) * playerTime.total
      return time
    }
    return null
  }

  // const ref = useRef<null | NodeJS.Timeout>(null);
  const ref = useRef<any>(null)

  const throttledSetHoverTime = throttle((event) => {
    const time = getTime(event)

    setHoverTime(time)

    if (time && (isMouseDown || isMobile)) {
      handleSeeking(time)
    }
  }, 300)

  const handleMouseMove = (event) => {
    throttledSetHoverTime(event)
  }

  const handleMouseLeave = () => {
    ref.current = setTimeout(() => {
      setHoverTime(null)
      setShowTime(false)
    }, 1000)
  }

  const handleMouseUp = () => {
    setIsMouseDown(false)

    ref.current = setTimeout(() => {
      setHoverTime(null)
      setShowTime(false)
    }, 1000)
  }

  const handleClick = (event) => {
    const time = getTime(event)

    if (ref.current) {
      clearTimeout(ref.current)
    }

    if (time) {
      setShowTime(true)
      setIsMouseDown(true)
      handleSeeking(time)
    }
  }

  return (
    <S.SeekBar {...props}>
      <S.LoadedTimeBar
        className="time-bar"
        style={{
          width: `${(playerTime.loaded / playerTime.total) * 100}%`,
        }}
      />
      <S.CurrentTimeBar
        className="time-bar"
        style={{
          width: `${(playerTime.current / playerTime.total) * 100}%`,
        }}
      ></S.CurrentTimeBar>
      <S.BackgroundBar className="time-bar" />
      <S.EventBar
        ref={timelineRef}
        style={{
          padding: isMobile ? '8.5px 0' : '6px 0',
        }}
        className="time-bar"
        onMouseDown={handleClick}
        onMouseMove={handleMouseMove}
        onMouseLeave={handleMouseLeave}
        onMouseUp={handleMouseUp}
        onTouchMove={handleMouseMove}
        onTouchEnd={handleMouseLeave}
        onTouchStart={handleClick}
      >
        {showTime && hoverTime && hoverTime !== 0 && (
          <S.Tooltip
            style={{
              left: `${(hoverTime / playerTime.total) * 100}%`,
            }}
          >
            {format(hoverTime * 1000, 'mm:ss')}
          </S.Tooltip>
        )}
        <S.SliderThumb style={{ left: `${(playerTime.current / playerTime.total) * 100}%` }} />
      </S.EventBar>
    </S.SeekBar>
  )
}

const S = {
  SeekBar: styled.div`
    display: flex;
    align-items: center;
    position: relative;
    margin: 0 18px 11px 18px;

    > .time-bar {
      position: absolute;
      width: 100%;
      height: 4px;
    }
  `,
  Tooltip: styled.div`
    position: absolute;
    top: -26px;
    transform: translate(-50%, -50%);
    color: #fff;
    ${typo.caption01};
    border-radius: 4px;
    padding: 4px 8px;
    background: #000;
    opacity: 0.8;
    pointer-events: none;
    user-select: none;

    &:after {
      content: '';
      display: flex;
      position: absolute;
      bottom: 0;
      left: 50%;

      border-top: 4px solid #000;
      border-right: 6px solid transparent;
      border-bottom: 4px solid transparent;
      border-left: 6px solid transparent;
      transform: translate(-50%, 7px);
    }
  `,
  SliderThumb: styled.div`
    display: none;
    position: absolute;
    transform: translate(-50%, -25%);
    right: 0;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: ${colors.blue.$300};
  `,
  CurrentTimeBar: styled.div`
    background: ${colors.blue.$300};
  `,
  LoadedTimeBar: styled.div`
    background: ${colors.gray.$300};
  `,
  BackgroundBar: styled.div`
    background: rgba(217, 217, 217, 0.5);
  `,
  EventBar: styled.div`
    box-sizing: content-box;
    background: transparent;
    cursor: pointer;

    &:hover > div {
      display: flex;
    }
  `,
}

export default SeekBar
