import { WorksheetDomain } from '@mathflat/domain/@entities'
import type { Entity as WorksheetEntity } from '@mathflat/domain/@entities/(Content)/Worksheet/dto'
import { observer } from 'mobx-react'

import { useStudentAppMediaQuery } from '~/@common/hooks/useMediaQuery'
import { useRepository } from '~/@common/hooks/useRepository.ts'
import { AiTutorService } from '~/@common/services/AiTutor.service.ts'
import {
  SwiperBackButton2,
  SwiperDefaultBackButton,
  SwiperDefaultNextButton,
  SwiperNextButton2,
} from '~/@common/ui/(StyledButton)'
import { SwiperController } from '~/@common/ui/SwiperController/SwiperController'
import {
  SwiperControllerConsumerName,
  useSwiperControllerContext,
} from '~/@common/ui/SwiperController/SwiperController.context'

type Props = {
  type: WorksheetEntity.Worksheet['type']
  totalProblemCount?: number
  isAiTutorOpened?: boolean
  openExitConfirmationAiTutor?: ({ onConfirm }: { onConfirm: () => void }) => void
}

export const WorksheetScoringByOneSwiperController = observer(
  ({ type, totalProblemCount = 0, isAiTutorOpened, openExitConfirmationAiTutor }: Props) => {
    const swiperController = useSwiperControllerContext(SwiperControllerConsumerName)

    const { isMobile } = useStudentAppMediaQuery()

    const aiTutorService = useRepository(AiTutorService)

    const isWrongConceptWorksheetAndDesktop =
      type === WorksheetDomain.TYPE.자기주도학습 && !isMobile

    const LeftComponent = isWrongConceptWorksheetAndDesktop
      ? SwiperBackButton2
      : SwiperDefaultBackButton

    const RightComponent = isWrongConceptWorksheetAndDesktop
      ? SwiperNextButton2
      : SwiperDefaultNextButton

    return (
      <SwiperController
        style={{ ...(isMobile && { padding: 0 }) }}
        SlotLeft={
          <LeftComponent
            disabled={swiperController.control?.isBeginning}
            onClick={() => {
              if (isAiTutorOpened) {
                openExitConfirmationAiTutor?.({
                  onConfirm: () => {
                    swiperController.control?.slidePrev()
                    aiTutorService.close()
                  },
                })
                return
              }

              swiperController.control?.slidePrev()
              aiTutorService.close()
            }}
          />
        }
        SlotCenter={
          isWrongConceptWorksheetAndDesktop ? (
            <div>
              {(swiperController.activeIndex ?? 0) + 1}/{totalProblemCount}
              문제
            </div>
          ) : (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                width: '282px',
              }}
            >
              {swiperController.initialized && (
                <>
                  <strong>{(swiperController.activeIndex ?? 0) + 1}번 문제</strong>
                  <strong style={{ margin: '0 4px' }}>/</strong>
                  <p>총 {totalProblemCount} 문제</p>
                </>
              )}
            </div>
          )
        }
        SlotRight={
          <RightComponent
            disabled={swiperController.control?.isEnd}
            onClick={() => {
              if (isAiTutorOpened) {
                openExitConfirmationAiTutor?.({
                  onConfirm: () => {
                    aiTutorService.close()
                    swiperController.control?.slideNext()
                  },
                })
                return
              }
              aiTutorService.close()
              swiperController.control?.slideNext()
            }}
          />
        }
      />
    )
  },
)
