import { keyframes } from '@emotion/react'
import styled from '@emotion/styled'

const Loading = () => {
  return <S.Loading />
}

const rotation = keyframes`
    from {
      -webkit-transform: rotate(0deg);
              transform: rotate(0deg);
    }
    to {
      -webkit-transform: rotate(360deg);
              transform: rotate(360deg);
    }
`

const S = {
  Loading: styled.div`
    position: absolute;
    z-index: 4;
    width: 80px;
    height: 80px;
    border: 3px solid rgba(255, 255, 255, 0.25);
    border-top-color: rgba(0, 0, 0, 0.5);
    border-radius: 50%;
    -webkit-animation: ${rotation} 0.8s ease infinite;
    animation: ${rotation} 0.8s ease infinite;
  `,
}

export default Loading
