import type { Subtract } from '@mathflat/shared/@common/types/numberUtils'

type MediaQueryMin<T extends string = string> = `@media (min-width: ${T}px)`
type MediaQueryMax<T extends string = string> = `@media (max-width: ${T}px)`
type MediaQueryField<T extends number | never = never> = T extends number ? T : undefined

export type MediaQueryParams<
  XL extends number | never = never,
  LG extends number | never = never,
  MD extends number | never = never,
  SM extends number | never = never,
  XS extends number | never = never,
> = {
  xl?: MediaQueryField<XL>
  lg?: MediaQueryField<LG>
  md?: MediaQueryField<MD>
  sm?: MediaQueryField<SM>
  xs?: MediaQueryField<XS>
}
export class MediaQuery<
  XL extends number | never = never,
  LG extends number | never = never,
  MD extends number | never = never,
  SM extends number | never = never,
  XS extends number | never = never,
> {
  protected _xl: MediaQueryField<XL>
  protected _lg: MediaQueryField<LG>
  protected _md: MediaQueryField<MD>
  protected _sm: MediaQueryField<SM>
  protected _xs: MediaQueryField<XS>

  constructor(breakPoint: MediaQueryParams<XL, LG, MD, SM, XS>) {
    this._xl = breakPoint.xl as MediaQueryField<XL>
    this._lg = breakPoint.lg as MediaQueryField<LG>
    this._md = breakPoint.md as MediaQueryField<MD>
    this._sm = breakPoint.sm as MediaQueryField<SM>
    this._xs = breakPoint.xs as MediaQueryField<XS>
  }

  static makeDeskTopFirstMediaQuery<T extends number>(
    params: T,
  ): MediaQueryMax<Subtract<`${T}`, '1'>> {
    return `@media (max-width: ${(params - 1) as Subtract<`${T}`, '1'>}px)`
  }

  static makeMobileFirstMediaQuery<T extends number>(params: T): MediaQueryMin<`${T}`> {
    return `@media (min-width: ${params}px)`
  }
}

// ---------- examples (Desktop-First)
class SampleDesktopFirstMediaQuery<XL extends number, LG extends number> extends MediaQuery<
  XL,
  LG
> {
  constructor(breakPoint: MediaQueryParams<XL, LG>) {
    super(breakPoint)
  }

  get underDesktop() {
    return MediaQuery.makeDeskTopFirstMediaQuery(this._xl)
  }

  get underLaptop() {
    return MediaQuery.makeDeskTopFirstMediaQuery(this._lg)
  }
}

const desktopFirstMediaQuery = new SampleDesktopFirstMediaQuery({
  xl: 1920,
  lg: 1024,
})

// ex) 타입 추론
desktopFirstMediaQuery.underDesktop // @media (max-width: 1919px) 로 타입 추론됨

// ---------- examples (Mobile-First)
class SampleMobileFirstMediaQuery<LG extends number, MD extends number> extends MediaQuery<
  never,
  LG,
  MD
> {
  constructor(breakPoint: MediaQueryParams<never, LG, MD>) {
    super(breakPoint)
  }

  get overMobile() {
    return MediaQuery.makeMobileFirstMediaQuery(this._lg)
  }

  get overTablet() {
    return MediaQuery.makeMobileFirstMediaQuery(this._md)
  }
}

const mobileFirstMediaQuery = new SampleMobileFirstMediaQuery({
  lg: 1024,
  md: 768,
})

// ex) 타입 추론
mobileFirstMediaQuery.overMobile // @media (min-width: 1024px) 로 타입 추론됨
