import clsx from 'clsx'
import { observer } from 'mobx-react'
import { useMemo } from 'react'
import { generatePath, NavLink, useParams } from 'react-router-dom'
import SimpleBar from 'simplebar-react'

import { routeName } from '~/@common/constants'
import { colors, colorTheme } from '~/@common/styles'
import { Icon } from '~/@common/ui/Icon/Icon'

import type { LectureGroup as LectureGroupT } from '../@service/Lecture.service'
import { S } from './LectureGroup.style'

const LectureGroup = ({ lectureGroup }: { lectureGroup: LectureGroupT }) => {
  const params = useParams<{ lectureId: string }>()

  const isAllDone = useMemo(() => {
    return lectureGroup.middleChapterLectures.every(({ isDone }) => isDone)
  }, [lectureGroup])

  return (
    <S.LectureGroup>
      <header>
        <span className={clsx('header-icon', isAllDone && 'all-done')}>
          <Icon
            name="icon_video"
            size={24}
            color={isAllDone ? colorTheme.primary : colors.gray.$500}
          />
        </span>
        {lectureGroup.leadingCurriculum.name}
      </header>
      <ul className="lecture-group-item-list">
        <SimpleBar style={{ maxHeight: '100%' }}>
          {lectureGroup.middleChapterLectures.map((middleChapterLecture) => (
            <NavLink
              key={middleChapterLecture.title}
              to={generatePath(routeName.student.lectureDetail, {
                lectureId: middleChapterLecture.id,
              })}
              replace={'lectureId' in params}
              className={({ isActive }) => clsx('lecture-group-item', isActive && 'is-active')}
            >
              <S.LectureGroupItem>
                <Icon
                  name="icon_circle_play"
                  size={18}
                  color={middleChapterLecture.isDone ? colorTheme.primary : colors.gray.$700}
                />
                <span>{middleChapterLecture.title}</span>
              </S.LectureGroupItem>
            </NavLink>
          ))}
        </SimpleBar>
      </ul>
    </S.LectureGroup>
  )
}

export default observer(LectureGroup)
