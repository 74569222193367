import { useCallback, useEffect, useState } from 'react'
import { isMobile } from 'react-device-detect'

type ScreenOrientationChangeEvent = Event & {
  target:
    | (EventTarget & {
        angle?: number
      })
    | null
}

type OrientationType = 'portrait' | 'landscape'

export interface OrientationData {
  type: OrientationType
  angle: number
}

const angleToOrientationData = (
  angle: OrientationData['angle'] | undefined,
): OrientationData | undefined => {
  if (!isMobile || angle === undefined) {
    return
  }

  switch (Math.abs(angle)) {
    case 0:
      return { angle, type: 'portrait' }
    case 90:
      return { angle, type: 'landscape' }
    case 180:
      return { angle, type: 'portrait' }
    case 270:
      return { angle, type: 'landscape' }
    case 360:
      return { angle, type: 'portrait' }
    default:
      return
  }
}

export const useOrientation = () => {
  const initialAngle = window.orientation ?? window.screen?.orientation?.angle
  const initialOrientationData = angleToOrientationData(initialAngle)
  const [orientationData, setOrientationData] = useState<OrientationData | undefined>(
    initialOrientationData,
  )

  // for iOS 16.4 미만
  const onOrientationChange = useCallback(() => {
    const angle = window.orientation
    setOrientationData(angleToOrientationData(angle))
  }, [])

  const onScreenOrientationChange = useCallback((e: ScreenOrientationChangeEvent) => {
    const angle = e.target?.angle
    setOrientationData(angleToOrientationData(angle))
  }, [])

  useEffect(() => {
    const isSupportScreenOrientationApi = Boolean(screen?.orientation)

    if (isSupportScreenOrientationApi) {
      screen.orientation.addEventListener('change', onScreenOrientationChange)
    } else {
      window.addEventListener('orientationchange', onOrientationChange)
    }

    return () => {
      if (isSupportScreenOrientationApi) {
        screen.orientation.removeEventListener('change', onScreenOrientationChange)
      } else {
        window.removeEventListener('orientationchange', onOrientationChange)
      }
    }
  }, [onOrientationChange, onScreenOrientationChange])

  return {
    ...orientationData,
  }
}
