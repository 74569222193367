import { observer } from 'mobx-react'
import qs from 'qs'
import { useState } from 'react'
import { generatePath, useNavigate, useParams } from 'react-router'
import { useSearchParams } from 'react-router-dom'

import type { studentWorkbookDetailPathParams } from '~/@common/constants/route'
import { routeName } from '~/@common/constants/route'
import { useStudentAppMediaQuery } from '~/@common/hooks/useMediaQuery'
import { useRepository } from '~/@common/hooks/useRepository'
import modalService from '~/@common/services/modal.service'
import { colors } from '~/@common/styles'
import { Drawer } from '~/@common/ui/(Drawer)/BaseDrawer'
import { SwiperDefaultBackButton, SwiperDefaultNextButton } from '~/@common/ui/(StyledButton)'
import { Icon } from '~/@common/ui/Icon/Icon'
import Modal from '~/@common/ui/modal/Modal'
import { SwiperController } from '~/@common/ui/SwiperController/SwiperController'
import type { StudentWorkbookDetailPageDTO } from '~/@entities/StudentWorkbook/StudentWorkbook.dto'
import ProblemScoringViewGuideOption from '~/@pages/@common/(ProblemScoring)/ProblemScoringViewGuideOption'

import { StudentWorkbookDetailService } from '../@service/StudentWorkbookDetail.service'
import StudentWorkbookDetailFooter from './StudentWorkbookDetailFooter'
import StudentWorkbookDetailPageList from './StudentWorkbookDetailPageList'
import StudentWorkbookDetailProblemList from './StudentWorkbookDetailProblemList'

const StudentWorkbookDetailBody = () => {
  const { studentWorkbookId, revisionId, pageNumber } =
    useParams<typeof studentWorkbookDetailPathParams>()

  const service = useRepository(StudentWorkbookDetailService, pageNumber)
  const [searchParams] = useSearchParams()
  const searchParamObject = Object.fromEntries(searchParams.entries())
  const navigate = useNavigate()
  const { isMobile } = useStudentAppMediaQuery()
  const [showMobilePageList, setShowMobilePageList] = useState(false)

  const handlePageNavigate = (pageTo?: StudentWorkbookDetailPageDTO['prevPageNumber']) => {
    if (pageTo) {
      const path = generatePath(routeName.student.studentWorkbookDetail, {
        studentWorkbookId,
        revisionId,
        pageNumber: pageTo,
      })

      setShowMobilePageList(false)
      // TODO: 공통 네비게이트 함수 필요할듯
      navigate(
        {
          pathname: path,
          search: qs.stringify(searchParamObject),
        },
        { replace: true },
      )
    }
  }

  if (!service.workbookDetail || !service.currentPage) return null

  const pageListProps = {
    onPageNavigate: handlePageNavigate,
    allPages: service.workbookDetail.allPages,
  }

  return (
    <>
      <ProblemScoringViewGuideOption viewOption={service.problemScoringViewOption} />
      <div className="problem-card-grid-container">
        {/* layout shift 방지를 위한 조건문 */}
        {!(isMobile && !service.problemScoringViewOption) && (
          <SwiperController
            SlotLeft={
              <SwiperDefaultBackButton
                onClick={() => {
                  handlePageNavigate(service.currentPage?.prevPageNumber)
                }}
                disabled={!service.currentPage.prevPageNumber}
              />
            }
            SlotCenter={
              <button
                onClick={() => {
                  if (!service.workbookDetail) return null

                  if (isMobile) {
                    setShowMobilePageList(true)
                  } else {
                    modalService.openModal(
                      {
                        header: '페이지 리스트',
                        content: <StudentWorkbookDetailPageList {...pageListProps} />,
                      },
                      {
                        modalName: '교재 페이지 선택',
                        hasCloseButton: true,
                      },
                    )
                  }
                }}
              >
                <Icon
                  name="icon_list"
                  size={20}
                  style={{ marginRight: 4 }}
                  color={colors.gray.$500}
                />
                {service.currentPage.pageNumber}P
              </button>
            }
            SlotRight={
              <SwiperDefaultNextButton
                onClick={() => {
                  handlePageNavigate(service.currentPage?.nextPageNumber)
                }}
                disabled={!service.currentPage.nextPageNumber}
              />
            }
          />
        )}

        <StudentWorkbookDetailProblemList
          problemScoringColl={service.currentPage.problemScoringColl}
          viewOption={service.problemScoringViewOption}
        />
      </div>
      <StudentWorkbookDetailFooter
        problemScoringColl={service.currentPage.problemScoringColl}
        viewOption={service.problemScoringViewOption}
        onSubmit={() => {
          if (studentWorkbookId && revisionId) {
            modalService.openModal(
              <Modal.Confirm.Positive
                confirm={{
                  children: '제출하기',
                  onClick: () => {
                    service.onSubmit({
                      studentWorkbookId,
                      revisionId,
                    })
                    modalService.closeModal()
                  },
                }}
              >
                채점 내용을 제출하면 다시 수정할 수 없습니다.
                <br />
                채점 내용을 선생님께 제출하시겠습니까?
              </Modal.Confirm.Positive>,
              { modalName: '교재 채점 - 제출하기' },
            )
          }
        }}
      />
      <Drawer
        isOpened={showMobilePageList}
        closeDrawer={() => {
          setShowMobilePageList(false)
        }}
        size="596px"
        hasCloseButton
      >
        <Drawer.Content>
          <Drawer.Header>페이지 리스트</Drawer.Header>
          <Drawer.Body>
            <StudentWorkbookDetailPageList {...pageListProps} />
          </Drawer.Body>
        </Drawer.Content>
      </Drawer>
    </>
  )
}

export default observer(StudentWorkbookDetailBody)
