import { Schema } from '@effect/schema'
import * as S from '@effect/schema/Schema'
import type { AxiosInstance, AxiosRequestConfig } from 'axios'
import { Context, Effect, flow } from 'effect'

import { apiSuccessResponse } from ':/@common/http/response/ApiResponse.ts'
import type { ServerSuccessResponseData } from ':/@common/http/response/ServerResponseData.ts'
import { API_RESPONSE_SUCCESS } from ':/@common/http/response/types.ts'
import { parsePathParams } from ':/@common/pathParams'
import { isDevelopMode } from ':/@common/utils/dev/index.ts'
import { catchError } from ':/@modules/ApiSpecFetcher/middleware/basic/catchError.ts'

import type {
  ApiSpecAdapterInput,
  ApiSpecInputServerData,
  ApiSpecOutputData,
  InputApiSpecWithPathParams,
} from '../ApiSpec/types.ts'

const defaultParser = isDevelopMode ? 'parse' : 'parse'

export const AxiosInstanceContext = Context.Tag<{
  axiosInstance: AxiosInstance
}>('ApiSpecFetcher/AxiosInstanceContext')

export const provideAxiosInstance =
  (axiosInstance: AxiosInstance) =>
  <R, E, A>(effect: Effect.Effect<R, E, A>) =>
    Effect.provideService(effect, AxiosInstanceContext, AxiosInstanceContext.of({ axiosInstance }))

export const makeApiSpecProgram =
  <ApiSpecT extends InputApiSpecWithPathParams>(_apiSpec: ApiSpecT) =>
  (
    input: ApiSpecAdapterInput<
      ApiSpecT,
      'data' | 'params' | 'parseParamsMode' | 'parseDataMode' | 'parseResponseDataMode'
    >,
    opt: Omit<AxiosRequestConfig, 'params' | 'method' | 'data'> | null = null,
  ) =>
    Effect.gen(function* (_) {
      const { axiosInstance } = yield* _(AxiosInstanceContext)

      const apiSpec = Object.assign(
        {
          parseParamsMode: defaultParser,
          parseDataMode: defaultParser,
          parseResponseDataMode: defaultParser,
        },
        _apiSpec,
      )

      const path = parsePathParams(apiSpec.path, input['pathParams'])

      // requestParams
      const params = Schema.isSchema(apiSpec.params)
        ? apiSpec.parseParamsMode
          ? yield* _(Schema[apiSpec.parseParamsMode](apiSpec.params)(input['params']))
          : input['params']
        : apiSpec.params

      // RequestBody(JSON)
      const data = Schema.isSchema(apiSpec.data)
        ? apiSpec.parseDataMode
          ? yield* _(Schema[apiSpec.parseDataMode](apiSpec.data)(input['data']))
          : input['data']
        : apiSpec.data

      const axiosRequestConfig: AxiosRequestConfig = Object.assign(
        {
          method: apiSpec.method,
          params,
          data,
        },
        opt,
        {
          headers: Object.assign({}, apiSpec?.headers, opt?.['headers']),
        },
      )

      if (isDevelopMode) {
        console.debug('axiosRequestConfig: ', path, axiosRequestConfig)
      }
      const resData = (yield* _(
        Effect.tryPromise(() => axiosInstance(path, axiosRequestConfig)),
      )) as unknown as ServerSuccessResponseData<ApiSpecInputServerData<ApiSpecT>>

      if (isDevelopMode) console.debug('response data:', resData)

      const decodeResult = (
        Schema.isSchema(apiSpec.responseData)
          ? apiSpec.parseResponseDataMode
            ? yield* _(S[apiSpec.parseResponseDataMode](apiSpec.responseData)(resData.data))
            : resData.data
          : apiSpec.responseData
      ) as ApiSpecOutputData<ApiSpecT>

      return apiSuccessResponse(
        Object.assign(resData, {
          type: API_RESPONSE_SUCCESS,
          data: decodeResult,
        } as const),
      )
    })

export const makeApiSpecFetcher =
  (axiosInstance: AxiosInstance) =>
  <ApiSpecT extends InputApiSpecWithPathParams>(_apiSpec: ApiSpecT) =>
    flow(
      makeApiSpecProgram(_apiSpec),
      Effect.provideService(AxiosInstanceContext, AxiosInstanceContext.of({ axiosInstance })),
      Effect.catchAll((e) => Effect.fail(catchError(e))),
      Effect.runPromise,
    )
