import { css } from '@emotion/react'
import { pipe } from 'effect'
import * as O from 'effect/Option'
import { observer, useLocalObservable } from 'mobx-react'

import { useRepository } from '~/@common/hooks/useRepository.ts'
import { colors, fontWeight, textEllipsis } from '~/@common/styles'
import { mediaQuery } from '~/@common/styles/mediaQuery'
import { ChallengeService } from '~/@pages/student/challenge/@widget/Challenge.service.ts'
import ConceptChipOfLittleChapter from '~/@pages/student/challenge/@widget/ConceptChipOfLittleChapter/ConceptChipOfLittleChapter.tsx'

export const kind = 'ms__ConceptChipOfMiddleChapter'

export type Props = {
  middleChapterId: number
  isDefaultOpen?: boolean
}

const ConceptChipOfMiddleChapter = ({ middleChapterId }: Props) => {
  const service = useRepository(ChallengeService)

  const localStore = useLocalObservable(() => ({
    get firstItem() {
      const middleChapterData = service.getIdFromTag('middleChapterId', middleChapterId)

      return pipe(
        middleChapterData,
        O.flatMap((ids) => service.get(ids.values().next().value)),
      )
    },

    get littleChapterIds() {
      const tagName = `중단원/${middleChapterId}` as const
      return pipe(
        service.getFilteredIdMapFromKindTag(tagName),
        O.flatMap((ids) => {
          if (ids.size === 0) return O.none()
          return O.some([...ids.keys()])
        }),
      )
    },
  }))

  if (O.isNone(localStore.firstItem)) return null
  if (O.isNone(localStore.littleChapterIds)) return null

  return (
    <details
      css={_css}
      {...(closedMiddleChapterIdSet.has(middleChapterId) ? null : { open: true })}
    >
      <summary role="button" onClick={() => toggleClosedMiddleChapterId(middleChapterId)}>
        <b>
          {localStore.firstItem.value.value.bigChapterName} ㅣ&nbsp;
          {localStore.firstItem.value.value.middleChapterName}
        </b>
      </summary>
      <div className="chapter-contents">
        {localStore.littleChapterIds.value.map((id) => (
          <ConceptChipOfLittleChapter
            key={`${id}`}
            middleChapterId={middleChapterId}
            littleChapterId={id}
          />
        ))}
      </div>
    </details>
  )
}

const openedMiddleChapterIdSessionKey = `${kind}/openedMiddleChapterId`

const sessionOpenedMiddleChapter = sessionStorage.getItem(openedMiddleChapterIdSessionKey)

const closedMiddleChapterIdSet = new Set(sessionOpenedMiddleChapter?.split(',').map(Number) ?? [])

const toggleClosedMiddleChapterId = (middleChapterId: number) => {
  if (closedMiddleChapterIdSet.has(middleChapterId)) {
    closedMiddleChapterIdSet.delete(middleChapterId)
  } else {
    closedMiddleChapterIdSet.add(middleChapterId)
  }
  sessionStorage.setItem(openedMiddleChapterIdSessionKey, [...closedMiddleChapterIdSet].join(','))
}

export default observer(ConceptChipOfMiddleChapter)

const _css = css`
  width: 100%;
  border-radius: 14px;
  overflow: hidden;
  margin-top: 10px;

  &:first-of-type {
    margin-top: 0;
  }

  summary {
    padding: 12px 20px;
    border-radius: 14px;
    background: #e1e6eb;

    font-weight: ${fontWeight.bold};
  }

  summary b {
    margin-left: 6px;
    ${textEllipsis(2)};
  }

  &[open] summary {
    border-radius: 14px 14px 0 0;
  }

  .chapter-contents {
    display: flex;
    flex-direction: column;
    padding: 14px;
    gap: 14px;
    content-visibility: auto;
    background: ${colors.white};
  }

  &[open] .chapter-contents {
    content-visibility: visible;
  }

  ${mediaQuery.underTablet} {
    .chapter-contents {
      padding: 0;
      gap: 4px;
      background-color: #f0f2f4;
    }
  }
`
