import { isAfter, isBefore } from 'date-fns'

import strapiApi from '~/@common/api/strapiApi'
import { routeName } from '~/@common/constants'
import { envUtils } from '~/@common/utils/envUtils'

class ServerMaintenanceService {
  static async checkServerMaintenance() {
    const { pathname } = window.location
    const isStagingOrLive = envUtils.isLive || envUtils.isStaging

    const data = await this.fetchServerMaintenanceData()
    const isServerMaintenancePeriod =
      !isBefore(new Date(), data.startDate) && !isAfter(new Date(), data.endDate)

    // 서버 점검 중일때 서버 점검 페이지로 리다이렉트
    if (isServerMaintenancePeriod && pathname !== routeName.serverMaintenance && isStagingOrLive) {
      window.location.href = routeName.serverMaintenance
    }

    // 서버 점검 중이 아닌데 서버 점검 중 페이지일땐 /로 리다이렉트
    if (!isServerMaintenancePeriod && pathname === routeName.serverMaintenance && isStagingOrLive) {
      window.location.href = routeName.login
    }
  }

  static async fetchServerMaintenanceData() {
    return await strapiApi.getServerMaintenance()
  }
}

export default ServerMaintenanceService
