const gray = {
  $100: '#FAFAFA',
  $200: '#F5F5F5',
  $300: '#E8E8E8',
  $400: '#E0E0E0',
  $500: '#C0C0C0',
  $600: '#959595',
  $700: '#707070',
  $800: '#5C5C5C',
  $900: '#333333',
} as const

const black = '#000000'

const blue = {
  $100: '#ECF8FF',
  $110: '#E7EAF8',
  $200: '#94DCFF',
  $300: '#00ABFF',
  $400: '#1A8EF9',
  $500: '#395FE2',
} as const

const red = {
  $100: '#FFEBEE',
  $200: '#FFCDD2',
  $300: '#FF707D',
  $400: '#FD5354',
} as const

const green = '#54C0B1'
const overlay = 'rgba(0, 0, 0, 0.5)'
const purple = '#767DE8'
const yellow = '#FFB155'
const white = '#FFFFFF'

export const allColorPool = {
  gray,
  black,
  overlay,
  blue,
  green,
  yellow,
  purple,
  red,
  white,
}

const blueInHSL = {
  $500: { h: 227, s: 74, l: 55 },
}

const primaryInHSL = blueInHSL.$500

const secondaryInHSL = { h: 229, s: 55, l: 94 }

const negativeInHSL = { h: 355, s: 100, l: 72 }

const grayInHSL = {
  $50: { h: 0, s: 0, l: 99 },
  $100: { h: 0, s: 0, l: 98 },
  $200: { h: 0, s: 0, l: 96 },
  $230: { h: 0, s: 0, l: 94 },
  $260: { h: 0, s: 0, l: 92 },
  $300: { h: 0, s: 0, l: 91 },
  $400: { h: 0, s: 0, l: 88 },
  $470: { h: 0, s: 0, l: 80 },
  $500: { h: 0, s: 0, l: 75 },
  $550: { h: 0, s: 0, l: 67 },
  $600: { h: 0, s: 0, l: 58 },
  $700: { h: 0, s: 0, l: 44 },
  $800: { h: 0, s: 0, l: 36 },
  $900: { h: 0, s: 0, l: 20 },
}

const subGrayInHSL = grayInHSL.$200

export const HSLColors = {
  primary: primaryInHSL,
  secondary: secondaryInHSL,
  negative: negativeInHSL,
  subGray: subGrayInHSL,
}

// const blueInHSL = {
//   $50: { h: 201, s: 100, l: 97 },
//   $100: { h: 202, s: 100, l: 96 },
//   $200: { h: 200, s: 100, l: 79 },
//   $300: { h: 200, s: 100, l: 50 },
//   $350: { h: 200, s: 100, l: 48 },
//   $400: { h: 209, s: 95, l: 54 },
//   $500: { h: 200, s: 100, l: 48 },
// }

// const redInHSL = {
//   $100: { h: 351, s: 100, l: 96 },
//   $150: { h: 354, s: 100, l: 82 },
//   $200: { h: 354, s: 100, l: 90 },
//   $230: { h: 350, s: 100, l: 94 },
//   $300: { h: 355, s: 100, l: 72 },
//   $320: { h: 355, s: 100, l: 70 },
//   $400: { h: 360, s: 98, l: 66 },
// }

// const orangeInHSL = {
//   $100: { h: 41, s: 92, l: 50 },
//   $200: { h: 25, s: 95, l: 59 },
//   $300: { h: 20, s: 100, l: 50 },
// }
// const yellowInHSL = {
//   $100: { h: 41, s: 100, l: 65 },
//   $200: { h: 40, s: 100, l: 59 },
//   $300: { h: 40, s: 50, l: 45 },
// }
// const greenInHSL = {
//   $100: { h: 172, s: 46, l: 54 },
// }

// const deepGreenInHSL = { h: 160, s: 45, l: 55 }

// const positiveInHSL = { h: 145, s: 100, l: 39 }
// const negativeInHSL = redInHSL.$400
// const overlayInHSL = { h: 0, s: 0, l: 0, a: 0.5 }
// const primaryInHSL = blueInHSL.$300
// const borderColorInHSL = grayInHSL.$400
// const guideInHSL = grayInHSL.$700
// const rowHoverColorInHSL = grayInHSL.$100
// const textButtonHoverColorInHSL = { h: 0, s: 0, l: 54 }
// const inputHoverColorInHSL = grayInHSL.$470
// const wheatInHSL = { h: 45, s: 93, l: 89 }
// const purpleInHSL = { h: 249, s: 45, l: 53 }
// const grayHoverInHSL = grayInHSL.$260
// const subgreenInHSL = greenInHSL.$100
// const subPurpleInHSL = { h: 236, s: 71, l: 69 }

// export const HSLColors = {
//   rowHoverColor: rowHoverColorInHSL,
//   textButtonHoverColor: textButtonHoverColorInHSL,
//   inputHoverColor: inputHoverColorInHSL,
//   grayHover: grayHoverInHSL,
//   positive: positiveInHSL,
//   negative: negativeInHSL,
//   overlay: overlayInHSL,
//   primary: primaryInHSL,
//   borderColor: borderColorInHSL,
//   guide: guideInHSL,
//   gray: grayInHSL,
//   red: redInHSL,
//   orange: orangeInHSL,
//   blue: blueInHSL,
//   yellow: yellowInHSL,
//   green: greenInHSL,
//   deepGreen: deepGreenInHSL,
//   wheat: wheatInHSL,
//   purple: purpleInHSL,
//   subPurple: subPurpleInHSL,
//   subgreen: subgreenInHSL,
// }
