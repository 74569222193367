import styled from '@emotion/styled'
import clsx from 'clsx'
import { useEffect, useRef, useState } from 'react'

import { colors, typo } from '~/@common/styles'
import type { StyleProps } from '~/@common/types'

import { Icon } from '../../Icon/Icon'
import type { PlayerConfig } from '../MathflatPlayer'

export type ResolutionInfo = {
  level: number
  resolution: 480 | 720 | 1080 | 'auto'
}
export type PlaybackRate = { rate: number; label: string }

type Props = {
  resolutionInfo: PlayerConfig['resolutionInfo']
  resolutionData: ResolutionInfo[]
  handleResolution: (resolutionInfo: ResolutionInfo) => void
  playbackRate: PlayerConfig['playbackRate']
  defaultPlaybackRate: PlayerConfig['defaultPlaybackRateOptions']
  handlePlaybackRate: (playbackRate: PlayerConfig['playbackRate']) => void
} & StyleProps

const SettingButton = ({
  resolutionInfo,
  resolutionData,
  handleResolution,
  handlePlaybackRate,
  playbackRate,
  defaultPlaybackRate,
  ...props
}: Props) => {
  const [isShow, setIsShow] = useState(false)

  const handleShowSetting = (isShow: boolean) => {
    setIsShow(isShow)
  }

  const ref = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!!ref.current && !ref.current.contains(event.target)) {
        handleShowSetting(false)
      }
    }

    document.addEventListener('click', handleClickOutside)

    return () => {
      document.removeEventListener('click', handleClickOutside)
    }
  }, [])

  return (
    <div ref={ref}>
      {isShow && (
        <Settings
          resolutionInfo={resolutionInfo}
          resolutionData={resolutionData}
          handleShowSetting={handleShowSetting}
          handleResolution={handleResolution}
          handlePlaybackRate={handlePlaybackRate}
          playbackRate={playbackRate}
          defaultPlaybackRate={defaultPlaybackRate}
        />
      )}
      <S.SettingButton
        {...props}
        onClick={() => {
          setIsShow((value) => !value)
        }}
      >
        <Icon name="icon_settings" size={22} color="#fff" />
      </S.SettingButton>
    </div>
  )
}

const Settings = ({
  resolutionInfo,
  resolutionData,
  handleShowSetting,
  handleResolution,
  handlePlaybackRate,
  playbackRate,
  defaultPlaybackRate,
}: {
  resolutionInfo: PlayerConfig['resolutionInfo']
  resolutionData: ResolutionInfo[]
  handleShowSetting: (isShow: boolean) => void
  handleResolution: (resolutionInfo: ResolutionInfo) => void
  handlePlaybackRate: (playbackRate: PlayerConfig['playbackRate']) => void
  playbackRate: PlayerConfig['playbackRate']
  defaultPlaybackRate: PlayerConfig['defaultPlaybackRateOptions']
}) => {
  const [mode, setMode] = useState<'main' | 'resolution' | 'speed'>('main')

  const modeInit = () => {
    setMode('main')
  }
  const modeSelect = (mode: 'resolution' | 'speed') => {
    setMode(mode)
  }
  const isHDResolution = (resolution: ResolutionInfo['resolution']) => {
    return resolution === 'auto' || resolution === 1080 || resolution === 720
  }

  return (
    <S.Settings>
      {mode === 'main' && (
        <>
          <S.Item
            onClick={(e) => {
              e.stopPropagation()
              modeSelect('resolution')
            }}
          >
            <div className="left-layer">
              <p>해상도</p>
            </div>
            <div className="right-layer">
              {resolutionInfo.resolution === 'auto' ? (
                <p>자동 (auto)</p>
              ) : (
                <p>{resolutionInfo.resolution}p</p>
              )}
              {isHDResolution(resolutionInfo.resolution) && <p className="blue-text">HD</p>}
              <Icon name="icon_chevron_right" color={colors.gray.$500} size={18} />
            </div>
          </S.Item>
          <S.Item
            onClick={(e) => {
              e.stopPropagation()
              modeSelect('speed')
            }}
          >
            <div className="left-layer">
              <p>재생 속도</p>
            </div>
            <div className="right-layer">
              <p>
                {playbackRate.label}x {playbackRate.label === '1.0' && '(기본)'}
              </p>
              <Icon name="icon_chevron_right" color={colors.gray.$500} size={18} />
            </div>
          </S.Item>
        </>
      )}

      {mode === 'resolution' && (
        <>
          <S.BackButton
            onClick={(e) => {
              e.stopPropagation()
              modeInit()
            }}
          >
            <Icon name="icon_chevron_left" size={18} color={colors.gray.$500} />
            <p>해상도</p>
          </S.BackButton>
          {resolutionData.map(({ level, resolution }) => (
            <S.OptionButton
              key={level}
              onClick={() => {
                handleResolution({ level, resolution })
                handleShowSetting(false)
              }}
              className={clsx(level === resolutionInfo.level && 'selected')}
            >
              {level === resolutionInfo.level && (
                <Icon name="icon_check" color={colors.gray.$500} size={18} />
              )}
              {resolution === 'auto' ? <p>자동 (auto)</p> : <p>{resolution}p</p>}
              {isHDResolution(resolution) && <p className="blue-text">HD</p>}
            </S.OptionButton>
          ))}
        </>
      )}

      {mode === 'speed' && (
        <>
          <S.SettingSpeed>
            <S.BackButton
              onClick={(e) => {
                e.stopPropagation()
                modeInit()
              }}
            >
              <Icon name="icon_chevron_left" size={18} color={colors.gray.$500} />
              <p>재생속도</p>
            </S.BackButton>
            {defaultPlaybackRate.map(({ label, rate }) => (
              <S.OptionButton
                key={rate}
                className={clsx(rate === playbackRate.rate && 'selected')}
                onClick={(e) => {
                  e.stopPropagation()
                  modeInit()
                  handlePlaybackRate({ label, rate })
                }}
              >
                {rate === playbackRate.rate && (
                  <Icon name="icon_check" color={colors.gray.$500} size={18} />
                )}
                <p>
                  {label}x {label === '1.0' && '(기본)'}
                </p>
              </S.OptionButton>
            ))}
          </S.SettingSpeed>
        </>
      )}
    </S.Settings>
  )
}

const S = {
  SettingButton: styled.button`
    padding: 5px;
  `,
  Settings: styled.div`
    right: 24px;
    bottom: 64px;
    position: absolute;
    width: 246px;
    padding: 4px 0;
    border-radius: 4px;
    background: #000;
    opacity: 0.8;
  `,
  Item: styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 14px;
    cursor: pointer;

    > div {
      display: flex;
      align-items: center;

      p {
        color: #fff;
        ${typo.body02};
      }

      .blue-text {
        margin-left: 2px;
        color: ${colors.blue.$300};
        ${typo.caption01};
        font-weight: 700;
      }
    }
  `,
  BackButton: styled.button`
    display: flex;
    gap: 2px;
    width: 100%;
    color: #fff;
    padding: 10px 14px;
    cursor: pointer;
  `,
  OptionButton: styled.button`
    display: flex;
    gap: 2px;
    align-items: center;
    position: relative;
    width: 100%;
    color: #fff;
    padding: 10px 14px 10px 34px;
    cursor: pointer;

    &.selected {
      background: ${colors.gray.$900};
    }

    .blue-text {
      color: ${colors.blue.$300};
      ${typo.caption01};
      font-weight: 700;
    }

    svg {
      position: absolute;
      left: 14px;
    }
  `,
  SettingSpeed: styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    p {
      text-align: left;
    }
  `,
}

export default SettingButton
