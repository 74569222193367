import { css } from '@emotion/react'
import type { Entity as CurriculumEntity } from '@mathflat/domain/@entities/Curriculum/dto.ts'
import { observer } from 'mobx-react'
import { type ForwardedRef, forwardRef } from 'react'
import WorkbookSearchModalFooter from 'src/@pages/@widgets/WorkbookSearchModal/WorkbookSearchModalFooter.tsx'
import WorkbookSearchModalHeader from 'src/@pages/@widgets/WorkbookSearchModal/WorkbookSearchModalHeader.tsx'
import WorkbookSearchModalMain from 'src/@pages/@widgets/WorkbookSearchModal/WorkbookSearchModalMain.tsx'

import { useRepository } from '~/@common/hooks/useRepository.ts'
import { colors, fontWeight, textEllipsis, typo } from '~/@common/styles'
import { mediaQuery } from '~/@common/styles/mediaQuery'
import { InputModule } from '~/@common/ui/(Input)/Input'
import type {
  RequiredWorkbookInfo,
  SearchWorkbookType,
} from '~/@pages/@widgets/WorkbookSearchModal/WorkbookSearch.service.ts'
import { WorkbookSearchService } from '~/@pages/@widgets/WorkbookSearchModal/WorkbookSearch.service.ts'

export type WorkbookSearchModalOnSubmit = (payload: {
  curriculumKey: CurriculumEntity.CurriculumTree['trieKey']
  workbookList: RequiredWorkbookInfo[]
  searchText: string
  selectedWorkbookType: SearchWorkbookType
}) => Promise<void>

export interface Props {
  onSelectCurriculum: (curriculumKey: CurriculumEntity.CurriculumTree['trieKey']) => void
  onSubmit: WorkbookSearchModalOnSubmit
}

export const kind = 'ms__WorkbookSearchModal'
export const parts = {
  header: `${kind}_header`,
  headerSearch: `${kind}_header_search`,
  main: `${kind}_main`,
  footer: `${kind}_footer`,
}

const WorkbookSearchModal = forwardRef((props: Props, ref: ForwardedRef<HTMLDivElement>) => {
  const service = useRepository(WorkbookSearchService)

  globalThis.workbookSearchService = service

  return (
    <div css={_css} ref={ref} data-component={kind}>
      <WorkbookSearchModalHeader />
      <WorkbookSearchModalMain />
      {service.hasFilteredWorkbooks && <WorkbookSearchModalFooter onSubmit={props.onSubmit} />}
    </div>
  )
})

WorkbookSearchModal.displayName = 'WorkbookSearchModal'

export default observer(WorkbookSearchModal)

const _css = css`
  display: flex;
  flex-direction: column;
  width: 850px;
  height: 420px;

  > .${parts.header} {
    display: flex;
    padding: 14px 24px;
    border-bottom: 1px solid ${colors.gray.$200};

    > .${parts.headerSearch} {
      display: flex;
      flex: 1;
      border: 1px solid ${colors.gray.$400};
      border-radius: 4px;
      margin: 0px 14px 0px 0px;
      overflow: hidden;

      .${InputModule.parts.input} {
        ${typo.body02};
        border: none;
        padding: 10px 13px;
      }

      > div {
        display: flex;
      }
      button {
        padding: 0px 10px;
        background: ${colors.gray.$100};
        border-left: 1px solid ${colors.gray.$400};
      }
    }

    > button {
      margin-left: auto;
      padding: 4px;
    }
  }

  > .${parts.main} {
    display: flex;
    flex: 1;
    width: 100%;
    overflow-y: hidden;

    main {
      overflow-y: auto;
      height: 100%;

      .row:has(input:checked) {
        background-color: ${colors.gray.$200};
      }
    }

    > nav {
      display: flex;
      gap: 4px;
      align-items: center;
      flex-direction: column;
      width: 140px;
      margin: 12px 4px 0px 6px;

      > button {
        justify-content: flex-start;
        width: 140px;
        ${typo.body01};
        font-weight: 700;
        color: ${colors.gray.$500};

        &.active {
          color: ${colors.blue.$500};
        }
      }
    }

    > section {
      display: flex;
      flex: 1;
      flex-direction: column;
      background: ${colors.gray.$100};
      overflow-y: auto;

      .row {
        display: flex;
        align-items: center;
        width: 100%;
        height: 50px;
        padding: 0px 24px;
        border-bottom: 1px solid ${colors.gray.$400};

        > .col1 {
          display: flex;
          flex: 0 0 24px;
        }
        > .col2 {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          flex: 1;

          > p {
            ${textEllipsis(1)};
          }

          > span {
            margin-left: 8px;
          }
        }
      }

      header {
        display: flex;
        width: 100%;
        .row {
          height: 36px;
        }
        .col1 {
          margin-right: 14px;
          color: ${colors.gray.$600};
          ${typo.caption01};
        }
        .col2 {
          color: ${colors.gray.$600};
          ${typo.caption01};
        }
      }

      main {
        display: flex;
        flex: 1;
        flex-direction: column;
        width: 100%;

        > p {
          display: flex;
          align-items: center;
          justify-content: center;
          height: 100%;
          color: ${colors.gray.$600};
          ${typo.body02};
          text-align: center;
        }

        .row {
          &:not(.max-checked):hover {
            cursor: pointer;
            background-color: ${colors.gray.$200};
          }

          &.max-checked,
          &.max-checked .col2 {
            color: ${colors.gray.$500} !important;
          }
        }

        .col1 {
          margin-right: 14px;
          flex: unset;

          > input {
            width: 20px;
            height: 20px;
            margin: 0px;
            border-color: ${colors.gray.$500};
          }
        }
      }
    }
  }

  ${mediaQuery.underTablet} {
    position: fixed;
    inset: 0;
    width: auto;
    height: auto;
    background-color: #fff;

    margin: var(--g-safe-area-top) var(--g-safe-area-right) var(--g-safe-area-bottom)
      var(--g-safe-area-left);
    padding: var(--g-safe-area-top) var(--g-safe-area-right) var(--g-safe-area-bottom)
      var(--g-safe-area-left);

    > .no-data {
      flex-grow: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      color: ${colors.gray.$600};
      text-align: center;
    }

    > .${parts.header} {
      flex-direction: column;
      padding: 0;

      > .${parts.headerSearch} {
        margin: 0 24px 20px;
      }
    }

    > .${parts.main} {
      overflow-y: auto;

      > section {
        overflow-y: unset;

        main {
          height: unset;

          .no-data {
            height: 140px;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: #fff;

            br {
              display: none;
            }
          }

          .row {
            gap: 20px;
            border: 0;
            background-color: #fff;

            &:not(.max-checked):hover {
              cursor: unset;
              background-color: #fff;
            }

            &:not(.max-checked):not(:has(input:checked)):active {
              background-color: ${colors.gray.$100};
            }

            &:not(.max-checked):has(input:checked) {
              background-color: rgba(57, 95, 226, 0.05);
            }

            .col1 {
              order: 1;
              margin-right: 0;
              flex: unset;
            }
            .col2 {
              color: ${colors.gray.$900};
            }
          }
        }
      }

      .summary {
        height: 61px;
        padding: 0 24px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        ${typo.body02};
        color: ${colors.gray.$800};
        font-weight: ${fontWeight.bold};
        background-color: ${colors.gray.$100};

        .number-for-workbook-type {
          margin-left: 6px;
          font-weight: ${fontWeight.normal};
          color: ${colors.gray.$500};

          &.selected {
            font-weight: ${fontWeight.bold};
            color: ${colors.gray.$800};
          }

          &.highlight {
            color: ${colors.blue.$500};
          }
        }
      }
    }
  }
`
