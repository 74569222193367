import { Entity as WorkbookEntity } from '@mathflat/domain/@entities/(Content)/Workbook/dto'
import type { MathflatApi } from '@mathflat/domain/@entities/StudentWorkbook/api.ts'
import type { ValueOf } from '@mathflat/shared/@types/utilityTypes'
import reverse from 'lodash/reverse'
import { makeAutoObservable, runInAction } from 'mobx'

import { studentWorkbookApi } from '~/@common/api/studentWorkbookApi'
import { commonRepo } from '~/@common/services/repo.service'
import { StudentWorkbookListItem } from '~/@entities/StudentWorkbook/StudentWorkbook.dto'
import type { STUDENT_WORKBOOK_LIST_TYPE } from '~/@entities/StudentWorkbook/StudentWorkbook.schema'

type WorkbookListTabType = ValueOf<typeof STUDENT_WORKBOOK_LIST_TYPE>

interface WorkbookListByType {
  studentWorkbookList: Awaited<
    ReturnType<typeof studentWorkbookApi.getStudentWorkbookList>
  >['content']
  requestParams: MathflatApi.Request.WorkbookAndStudentWorkbook
}

const DEFAULT_WORKBOOK_LIST_VALUE: WorkbookListByType = {
  studentWorkbookList: [],
  requestParams: {
    page: 0,
    workbookTypes: [],
    size: 16,
    sort: ['revisionMaxCreatetime', 'DESC'],
  },
}

export class StudentWorkbookListService {
  private _general: WorkbookListByType = {
    ...DEFAULT_WORKBOOK_LIST_VALUE,
    requestParams: {
      ...DEFAULT_WORKBOOK_LIST_VALUE.requestParams,
      workbookTypes: ['PUBLIC', 'SCHOOL', 'CUSTOM'],
    },
  }
  private '_custom-signature': WorkbookListByType = {
    ...DEFAULT_WORKBOOK_LIST_VALUE,
    requestParams: {
      ...DEFAULT_WORKBOOK_LIST_VALUE.requestParams,
      workbookTypes: ['CUSTOM_SIGNATURE'],
    },
  }

  private _isLoading = false

  get isLoading() {
    return this._isLoading
  }

  constructor() {
    makeAutoObservable(this)
  }

  checkIsLastPageByType(type: WorkbookListTabType) {
    return this[`_${type}`].requestParams?.isLast
  }

  getWorkbookListByType(type: WorkbookListTabType) {
    const studentWorkbookList: StudentWorkbookListItem[][] = []

    this[`_${type}`].studentWorkbookList.forEach(
      ({ roundToRevisionRoundMap, workbook, recentRevisionRound }) => {
        const studentWorkbookItems = reverse(
          Object.values(roundToRevisionRoundMap).map(
            ({
              id: roundId,
              percent,
              round,
              recentPageNumber,
              studentWorkbookId,
              createDatetime,
            }) =>
              // 내려주는 정보가 많고, 정작 중요 데이터가 depth가 깊숙히 있는 이슈가 있어서 쓰는 값들 위주로 뽑아서 쓴다.
              new StudentWorkbookListItem({
                roundId,
                round,
                title: workbook.fulltitle,
                createDatetime,
                recentRevisionRound,
                recentPageNumber,
                studentWorkbookId,
                percent,
                autoScored: workbook.autoScored,
                thumbnailImageUrl:
                  workbook instanceof WorkbookEntity.CustomSignatureWorkbook
                    ? workbook.thumbnailImageUrl
                    : undefined,
              }),
          ),
        )
        studentWorkbookList.push(studentWorkbookItems)
      },
    )
    return [...studentWorkbookList]
  }

  setNextPage(type: WorkbookListTabType) {
    const currentPage = this[`_${type}`].requestParams.page
    this[`_${type}`].requestParams.page = (currentPage ?? 0) + 1
  }

  private addStudentWorkbookList(
    type: WorkbookListTabType,
    studentWorkbookList: WorkbookListByType['studentWorkbookList'],
  ) {
    this[`_${type}`].studentWorkbookList = [
      ...this[`_${type}`].studentWorkbookList,
      ...studentWorkbookList,
    ]
  }

  resetStudentWorkbookListByType(type: WorkbookListTabType) {
    this[`_${type}`] = {
      ...DEFAULT_WORKBOOK_LIST_VALUE,
      requestParams: {
        ...DEFAULT_WORKBOOK_LIST_VALUE.requestParams,
        workbookTypes: type === 'general' ? ['PUBLIC', 'SCHOOL', 'CUSTOM'] : ['CUSTOM_SIGNATURE'],
      },
    }
  }

  async loadStudentWorkbookList(type: WorkbookListTabType) {
    if (commonRepo.studentId) {
      const isEmptyWorkbookList = !this[`_${type}`].studentWorkbookList.length
      if (isEmptyWorkbookList) {
        this._isLoading = true
      }

      const { content: _studentWorkbookList, last } =
        await studentWorkbookApi.getStudentWorkbookList(
          commonRepo.studentId,
          this[`_${type}`].requestParams,
        )

      runInAction(() => {
        this.addStudentWorkbookList(type, _studentWorkbookList)
        this[`_${type}`].requestParams.isLast = last
        this._isLoading = false
      })
    }
  }
}
