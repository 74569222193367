import { css } from '@emotion/react'
import { CalendarDate } from '@mathflat/shared/@modules/(dto)/CalendarDate'
import { observer } from 'mobx-react'
import { useState } from 'react'

import { useRepository } from '~/@common/hooks/useRepository'
import { colors } from '~/@common/styles'
import Button from '~/@common/ui/(Button)/Button/Button'
import { Drawer } from '~/@common/ui/(Drawer)/BaseDrawer'
import Calendar from '~/@common/ui/Calendar/Calendar'
import { Icon } from '~/@common/ui/Icon/Icon'
import Switch from '~/@common/ui/Switch/Switch'

import { S } from '../../@common/style/common.style'
import { StudentWorksheetListService } from '../../@service/StudentWorksheetList.service'

export const StudentWorksheetListMobileOptionDrawer = observer(
  ({
    isOpened,
    onChangeDrawer,
  }: {
    isOpened: boolean
    onChangeDrawer: (isOpened: boolean) => void
  }) => {
    const service = useRepository(StudentWorksheetListService)

    const [selectedDateRange, setSelectedDateRange] = useState(service.uiState.dateRange)
    const [isOnlyHomeworkOptionToggled, setIsOnlyHomeworkOptionToggled] = useState(
      service.uiState.isOnlyHomeWorkOptionToggled,
    )
    const [isOnlyExamOptionToggled, setIsOnlyExamOptionToggled] = useState(
      service.uiState.isOnlyShowExamWorksheetOptionToggled,
    )

    const isApplyButtonDisabled =
      service.uiState.isOnlyHomeWorkOptionToggled === isOnlyHomeworkOptionToggled &&
      service.uiState.isOnlyShowExamWorksheetOptionToggled === isOnlyExamOptionToggled &&
      service.uiState.dateRange.start?.date === selectedDateRange.start?.date &&
      service.uiState.dateRange.end?.date === selectedDateRange.end?.date

    const resetFilterOptionsExceptStatus = () => {
      const date = new CalendarDate()
      setSelectedDateRange({
        start: date.getPrevMonth(),
        end: date,
      })
      setIsOnlyHomeworkOptionToggled(false)
      setIsOnlyExamOptionToggled(false)
    }

    const closeDrawer = () => {
      onChangeDrawer(false)
      setSelectedDateRange(service.uiState.dateRange)
      setIsOnlyHomeworkOptionToggled(service.uiState.isOnlyHomeWorkOptionToggled)
      setIsOnlyExamOptionToggled(service.uiState.isOnlyShowExamWorksheetOptionToggled)
    }

    return (
      <Drawer
        isOpened={isOpened}
        openDrawer={() => onChangeDrawer(true)}
        closeDrawer={closeDrawer}
        size="full"
        placement="right"
      >
        <Drawer.Content>
          <Drawer.Header>필터 설정</Drawer.Header>
          <Drawer.Body>
            <S.OptionRow>
              <label htmlFor="filter__only-show-homework" className="label">
                숙제만 보기
              </label>
              <Switch
                id="filter__only-show-homework"
                checked={isOnlyHomeworkOptionToggled}
                onChange={(e) => setIsOnlyHomeworkOptionToggled(e.target.checked)}
              />
            </S.OptionRow>
            <Drawer.Divider />
            <S.OptionRow>
              <label htmlFor="filter__only-show-exam" className="label">
                학력평가만 보기
              </label>
              <Switch
                id="filter__only-show-exam"
                checked={isOnlyExamOptionToggled}
                onChange={(e) => setIsOnlyExamOptionToggled(e.target.checked)}
              />
            </S.OptionRow>
            <Drawer.Divider />
            <S.OptionRow>
              <label className="label">기간</label>
              <label>{service.dateRangeForDisplay}</label>
            </S.OptionRow>
            <Drawer.Divider />
            <div
              css={css`
                display: flex;
                align-items: center;
                justify-content: center;
              `}
            >
              <div>
                <Calendar
                  style={{
                    margin: '20px auto',
                    padding: 0,
                    width: '360px',
                    height: '285px',
                  }}
                  type="range"
                  selectedRange={selectedDateRange}
                  onRangeSelect={setSelectedDateRange}
                />
              </div>
            </div>
          </Drawer.Body>
          <Drawer.Divider />
          <Drawer.Footer>
            <S.FooterButtons>
              <Button
                disabled={isApplyButtonDisabled}
                theme="primary"
                minWidth="100%"
                onClick={() => {
                  service.updateToggleOption(
                    '_isOnlyHomeWorkOptionToggled',
                    isOnlyHomeworkOptionToggled,
                  )
                  service.updateToggleOption(
                    '_isOnlyShowExamWorksheetOptionToggled',
                    isOnlyExamOptionToggled,
                  )
                  service.updateDateRange(selectedDateRange)
                  onChangeDrawer(false)
                }}
              >
                적용하기
              </Button>
              <button
                className="cta-button__reset"
                onClick={() => {
                  resetFilterOptionsExceptStatus()
                }}
              >
                <Icon name="icon_reset" color={colors.gray.$500} size={20} />
                전체 초기화
              </button>
            </S.FooterButtons>
          </Drawer.Footer>
        </Drawer.Content>
      </Drawer>
    )
  },
)
