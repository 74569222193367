import styled from '@emotion/styled'
import type { PropsWithChildren } from 'react'

import Container from '~/@common/ui/(Container)/Container/Container.tsx'

const kind = 'ms__main'
const parts = {
  base: kind,
}

const Main = ({ children }: PropsWithChildren) => {
  return (
    <S.Main data-component={kind} className={parts.base}>
      <Container>{children}</Container>
    </S.Main>
  )
}

export default Main
export { parts as MainModule }

const S = {
  Main: styled.main`
    height: 100%;
    overflow: hidden;
  `,
}
