import 'simplebar-react/dist/simplebar.min.css'

import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { ANSWER_TYPE } from '@mathflat/domain/@entities/Problem/constants'
import type { HandwrittenNoteTypes } from '@mathflat/handwritten-note'
import { ReadOnlyHandwrittenNote } from '@mathflat/handwritten-note'
import { s3URL } from '@mathflat/shared/@common/utils/s3'
import { clsx } from 'clsx'
import MathflatPlayer from 'mathflat-videoplayer'
import { observer } from 'mobx-react'
import { useEffect, useMemo, useState } from 'react'
import SimpleBar from 'simplebar-react'

import { RequestScoring } from '~/@common/api'
import { handwrittenNoteApi } from '~/@common/api/handwrittenNoteApi'
import { useStudentAppMediaQuery } from '~/@common/hooks/useMediaQuery'
import { CustomEventService } from '~/@common/services/event.service'
import modalService from '~/@common/services/modal.service'
import { colors, typo } from '~/@common/styles'
import { mediaQuery } from '~/@common/styles/mediaQuery'
import { Drawer } from '~/@common/ui/(Drawer)/BaseDrawer'
import { Icon } from '~/@common/ui/Icon/Icon'
import ProblemNoteMobileViewer from '~/@pages/student/@widgets/ProblemNote/ProblemNoteMobileViewer'

import { 일반채점입력 } from '../../(Scoring)/(일반채점)/일반채점입력'
import 자동채점입력 from '../../(Scoring)/(자동채점)/자동채점입력'
import type { 자동채점입력_주관식Props } from '../../(Scoring)/(자동채점)/자동채점입력/자동채점입력_주관식'
import { SubmittedAnswer, 채점결과_말풍선 } from '../../(Scoring)/(채점결과)/채점결과'
import type { ProblemScoring } from '../@trait/ProblemScoring.trait'
import type { ProblemScoringViewOption } from '../@trait/ProblemScoringViewOption.trait'
import ProblemScoringStyle from '../ProblemScoringCard/ProblemScoringCard.style'

export type ProblemScoringFooterProps = {
  problemScoring: ProblemScoring<'WORKSHEET' | 'WORKBOOK'>
  viewOption?: ProblemScoringViewOption<'학습모듈' | 'NOT_학습모듈'>
  isSubmittedAnswerAvailable?: boolean
} & Pick<자동채점입력_주관식Props, 'virtualKeybaordShowType'>

const ProblemScoringFooter = observer(
  ({
    problemScoring,
    viewOption,
    virtualKeybaordShowType,
    isSubmittedAnswerAvailable = true,
  }: ProblemScoringFooterProps) => {
    const [openDrawer, setOpenDrawer] = useState(false)
    const [noteData, setNoteData] = useState<HandwrittenNoteTypes.NoteData | null>(null)
    const [isShowProblemImage, setIsShowProblemImage] = useState(false)
    const { isMobile } = useStudentAppMediaQuery()
    const [isHiddenNoteData, setIsHiddenNoteData] = useState(false)

    const toggleHiddenNoteData = () => {
      setIsHiddenNoteData((prev) => !prev)
    }

    const closeDrawer = () => {
      setIsHiddenNoteData(false)
    }

    if (!viewOption || viewOption.채점불가능) return <></>

    const 제출_및_채점후정답해설공개 =
      viewOption.content.type !== 'MAAT' &&
      problemScoring.isSubmitted &&
      (!viewOption.studentAppSetting ||
        (viewOption.studentAppSetting.채점후정답해설공개 &&
          viewOption.studentAppSetting.채점후문풀동공개))

    const 해설내풀이문구_버튼명 = useMemo(() => {
      return (
        <>
          {제출_및_채점후정답해설공개 && (
            <span className="explation-submitted-answer-text">동영상</span>
          )}
          {isSubmittedAnswerAvailable && (
            <span className="explation-submitted-answer-text">필기</span>
          )}
          {제출_및_채점후정답해설공개 && !!problemScoring.문제해설이미지 && (
            <span className="explation-submitted-answer-text">해설</span>
          )}
        </>
      )
    }, [
      problemScoring.isSubmitted,
      !viewOption.studentAppSetting,
      viewOption.studentAppSetting?.채점후정답해설공개,
      problemScoring.문제해설이미지,
      isSubmittedAnswerAvailable,
    ])

    const 해설내풀이문구_상세명 = useMemo(() => {
      return (
        <>
          {제출_및_채점후정답해설공개 && (
            <span className="explation-submitted-answer-text">풀이동영상</span>
          )}
          {isSubmittedAnswerAvailable && (
            <span className="explation-submitted-answer-text">문제&내 풀이</span>
          )}
          {제출_및_채점후정답해설공개 && !!problemScoring.문제해설이미지 && (
            <span className="explation-submitted-answer-text">해설</span>
          )}
        </>
      )
    }, [
      problemScoring.isSubmitted,
      !viewOption.studentAppSetting,
      viewOption.studentAppSetting?.채점후정답해설공개,
      problemScoring.문제해설이미지,
      isSubmittedAnswerAvailable,
    ])

    const emptyCase = useMemo(() => {
      if (!isSubmittedAnswerAvailable) {
        return <></>
      }

      if (!problemScoring.handwrittenNoteUrl && isMobile) {
        return <div className="mobile-text">필기한 기록이 없어요</div>
      }

      return (
        <SimpleBar
          key="without-note-data"
          style={{
            maxHeight: 제출_및_채점후정답해설공개 ? '312px' : '100%',
          }}
        >
          <div className="problem-image-container">
            <img src={problemScoring.문제이미지} alt="문제 이미지" width={344} />
          </div>
        </SimpleBar>
      )
    }, [problemScoring.handwrittenNoteUrl, isSubmittedAnswerAvailable])

    useEffect(() => {
      if (openDrawer && problemScoring.handwrittenNoteUrl) {
        handwrittenNoteApi
          .fetchNoteByUrl(problemScoring.handwrittenNoteUrl)
          .then((data) => {
            setNoteData(data)
          })
          .catch((e) => {
            console.error(e)
          })
      } else {
        setNoteData(null)
      }
    }, [problemScoring.handwrittenNoteUrl, openDrawer])

    // 제출 후
    if (problemScoring.isSubmitted) {
      // 자기주도가 아닐 때
      if (viewOption.studentAppSetting) {
        if (
          (!isSubmittedAnswerAvailable && !viewOption.studentAppSetting.채점후정답해설공개) ||
          !problemScoring.문제해설이미지
        ) {
          return <></>
        }
      }

      const onCloseProblemNoteMobileViewer = () => {
        modalService.closeModal()
      }

      const openNoteViewer = () => {
        if (noteData && problemScoring.문제이미지) {
          modalService.openModal(
            <ProblemNoteMobileViewer
              noteData={noteData}
              problemImgUrl={problemScoring.문제이미지}
              onClose={onCloseProblemNoteMobileViewer}
            />,
            { modalName: '내 풀이 확대 모달' },
          )
        }
      }

      return (
        <>
          <S.ProblemScoringFooter
            data-tooltip-id={`submitted-answer-${problemScoring.id}`}
            onClick={(e) => {
              setOpenDrawer((prev) => !prev)
            }}
            className="has-padding"
          >
            {!!problemScoring.제출한답 && (
              <span
                className="left"
                onClick={(e) => {
                  e.stopPropagation()
                  if (isMobile) {
                    modalService.openModal(
                      <ProblemScoringStyle.MobileModalContainer>
                        <SubmittedAnswer
                          채점결과={problemScoring.채점결과}
                          제출한답={problemScoring.제출한답}
                          문제정답타입={problemScoring.문제정답타입}
                          ellipse={false}
                        />
                      </ProblemScoringStyle.MobileModalContainer>,
                      { modalName: '제출한 답 모달', hasCloseButton: true },
                    )
                  } else {
                    CustomEventService.tooltipOn.dispatch(
                      `submitted-answer-${problemScoring.id}`,
                      e,
                    )
                  }
                }}
              >
                <span className="answer-text">내가 쓴 답 :&nbsp;</span>

                <채점결과_말풍선
                  id={`submitted-answer-${problemScoring.id}`}
                  value={problemScoring.채점결과}
                  문제정답타입={problemScoring.문제정답타입}
                  제출한답={problemScoring.제출한답}
                />
                <SubmittedAnswer
                  채점결과={problemScoring.채점결과}
                  제출한답={problemScoring.제출한답}
                  문제정답타입={problemScoring.문제정답타입}
                />
              </span>
            )}
            {problemScoring.isAutoScoring && problemScoring.제출한답 === null && (
              <div className="left">
                <span className="answer-guide">선생님이 채점함</span>
              </div>
            )}

            {viewOption.content.type !== 'CONCEPTUAL' && (
              <span className="right">
                {해설내풀이문구_버튼명}
                <Icon name="icon_chevron_right" color={colors.gray.$500} size={20} />
              </span>
            )}
            <Drawer
              size="596px"
              isOpened={openDrawer}
              placement={isMobile ? 'bottom' : 'left'}
              backdropOpacity={isMobile ? 1 : 0}
              closeDrawer={closeDrawer}
              {...(!isMobile && { rootSelector: '#app', position: 'absolute' })}
            >
              <Drawer.Content css={S.problemScoringDrawerContentStyle}>
                <Drawer.Header className="problem-scoring-drawer-header">
                  {해설내풀이문구_상세명}
                </Drawer.Header>
                <Drawer.Body className="problem-scoring-drawer-body">
                  <S.DrawerBodyCotent
                    onClick={(e) => {
                      e.stopPropagation()
                    }}
                  >
                    {제출_및_채점후정답해설공개 && (
                      <div className="problem-scoring-drawer-body-content-item">
                        <div className="title">풀이동영상</div>
                        {'video' in problemScoring.problem && problemScoring.problem.video ? (
                          <MathflatPlayer
                            ThumbnailElement={
                              <S.Thumbnail>
                                <div className="img-bg">
                                  {problemScoring.문제이미지 && (
                                    <img
                                      src={problemScoring.문제이미지}
                                      style={{ width: '100%', height: '100%' }}
                                    />
                                  )}
                                </div>
                                <div className="gradation">
                                  <img
                                    className="sub-title"
                                    src={s3URL.common(
                                      'images/video/problem-solving/sub-title_student-app@1x.png',
                                    )}
                                    srcSet={`${s3URL.common(
                                      'images/video/problem-solving/sub-title_student-app@2x.png',
                                    )} 2x, ${s3URL.common('images/video/problem-solving/sub-title_student-app@3x.png')} 3x`}
                                  />
                                  <img
                                    className="title"
                                    src={s3URL.common('images/video/problem-solving/title@1x.png')}
                                    srcSet={`${s3URL.common(
                                      'images/video/problem-solving/title@2x.png',
                                    )} 2x, ${s3URL.common('images/video/problem-solving/title@3x.png')} 3x`}
                                  />
                                </div>
                              </S.Thumbnail>
                            }
                            videoUrl={problemScoring.problem.video?.videoUrl as string}
                          />
                        ) : (
                          <div className="no-video">풀이동영상이 없어요</div>
                        )}
                      </div>
                    )}

                    {problemScoring.문제이미지 && isMobile && (
                      <div className="problem-scoring-drawer-body-content-item">
                        <div className="title-wrapper">
                          <div className="title">문제</div>
                          <button
                            className={clsx('icon-button', isShowProblemImage && 'opened')}
                            onClick={() => setIsShowProblemImage((prev) => !prev)}
                          >
                            <Icon name="icon_chevron_down" size={20} color={colors.gray.$900} />
                          </button>
                        </div>
                        {isShowProblemImage && (
                          <img src={problemScoring.문제이미지} alt="문제 이미지" width={344} />
                        )}
                      </div>
                    )}
                    {isSubmittedAnswerAvailable && (
                      <div className="problem-scoring-drawer-body-content-item">
                        <div className="problem-scoring-drawer-body-content-item-header">
                          <div className="title">{!isMobile && '문제 ・'} 내 풀이</div>
                          {!isMobile ? (
                            noteData ? (
                              <button onClick={toggleHiddenNoteData} className="icon-button">
                                <Icon
                                  name={isHiddenNoteData ? 'icon_eye_closed' : 'icon_eye_opened'}
                                  size={22}
                                  color={colors.gray.$800}
                                />
                              </button>
                            ) : (
                              <p className="no-writing">필기한 기록이 없어요</p>
                            )
                          ) : (
                            <></>
                          )}
                        </div>
                        {noteData &&
                        problemScoring.handwrittenNoteUrl &&
                        problemScoring.문제이미지 &&
                        !isHiddenNoteData ? (
                          <SimpleBar
                            key="with-note-data"
                            style={{
                              maxHeight: 제출_및_채점후정답해설공개 ? '312px' : '100%',
                            }}
                          >
                            <div className="note-container">
                              <ReadOnlyHandwrittenNote
                                noteData={noteData}
                                preventScale
                                preventOverflow
                                isSlideToNoteRect={isMobile}
                              >
                                <img
                                  src={problemScoring.문제이미지}
                                  alt="문제 이미지"
                                  width={344}
                                />
                              </ReadOnlyHandwrittenNote>
                              {isMobile && (
                                <button
                                  type="button"
                                  className="note-zoom-in"
                                  onClick={openNoteViewer}
                                >
                                  <Icon name="icon_zoom_in" size={16} />
                                </button>
                              )}
                            </div>
                          </SimpleBar>
                        ) : (
                          emptyCase
                        )}
                      </div>
                    )}

                    {제출_및_채점후정답해설공개 && (
                      <div className="problem-scoring-drawer-body-content-item">
                        <div className="title">해설</div>
                        <img
                          src={problemScoring.문제해설이미지}
                          alt="문제 해설 이미지"
                          width={344}
                        />
                      </div>
                    )}
                  </S.DrawerBodyCotent>
                </Drawer.Body>
              </Drawer.Content>
            </Drawer>
          </S.ProblemScoringFooter>
        </>
      )
    }

    // 문제 : 제출 전
    // 학습지 or 교재 : 채점 가능 상태
    if (!viewOption.채점불가능) {
      // 1. 자동채점
      // 1-1. 주관식
      if (problemScoring.localUserInput instanceof RequestScoring.자동채점) {
        if (problemScoring.문제정답타입 === 'SHORT_ANSWER') {
          const virtualKeyboardMarginBottom =
            isMobile && viewOption.content.type === 'CONCEPTUAL' ? 10 : undefined

          return (
            <S.ProblemScoringFooter className="has-padding">
              <자동채점입력.주관식
                id={problemScoring.id}
                keypadTypes={problemScoring.keypadTypes}
                answerUnits={problemScoring.answerUnits}
                value={problemScoring.localUserInput.localUserAnswer ?? ''}
                onChange={(v) => {
                  problemScoring.setLocalUserInput(v)
                }}
                virtualKeybaordShowType={virtualKeybaordShowType}
                virtualKeyboardMarginBottom={virtualKeyboardMarginBottom}
              />
            </S.ProblemScoringFooter>
          )
        }

        if (problemScoring.문제n지선다) {
          // 1-2. 객관식
          const data = Array.from({ length: problemScoring.문제n지선다 }).map((_, index) => ({
            label: index + 1,
            value: index + 1,
          }))

          return (
            <S.ProblemScoringFooter className="has-padding">
              <자동채점입력.객관식
                circleClassName={viewOption.content.type === 'CONCEPTUAL' ? 'circle-sm' : undefined}
                data={data}
                values={
                  problemScoring.localUserInput.localUserAnswer === ''
                    ? []
                    : problemScoring.localUserInput.localUserAnswer?.split(',') ?? []
                }
                max={problemScoring.문제정답길이}
                onChange={(v) => {
                  problemScoring.setLocalUserInput(v.join(','))
                }}
              />
            </S.ProblemScoringFooter>
          )
        } else {
          console.error('문제n지선다 값이 없습니다.')
          return null
        }
      }

      // 2. 일반 채점
      return (
        <S.ProblemScoringFooter>
          <일반채점입력
            value={problemScoring.localUserInput?.localResult ?? ANSWER_TYPE.NONE}
            onChange={(v) => {
              problemScoring.setLocalUserInput(v)
            }}
          />
        </S.ProblemScoringFooter>
      )
    }
  },
)

const S = {
  ProblemScoringFooter: styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex: 0 0 70px;
    background-color: white;
    border-bottom-left-radius: 14px;
    border-bottom-right-radius: 14px;
    ${typo.body02};

    > * {
      cursor: pointer;
    }
    &.has-padding {
      padding: 0 20px;
    }

    > .left,
    > .center {
      display: flex;
      align-items: center;
      width: 100%;
      height: 36px;

      font-weight: bold;
      color: ${colors.gray.$700};
      .answer-text {
        flex-shrink: 0;
      }

      .answer-guide {
        color: ${colors.gray.$600};
        font-weight: 400;
        ${typo.body01}
      }
    }

    > .center {
      justify-content: center;
    }

    > .right {
      display: flex;
      align-items: center;
      flex-shrink: 0;
      height: 36px;
      margin: 0 auto;
      cursor: pointer;

      color: ${colors.gray.$600};
    }
    .explation-submitted-answer-text + .explation-submitted-answer-text {
      margin-left: 4px;
      &::before {
        content: ' ・ ';
      }
    }
  `,
  DrawerBodyCotent: styled.div`
    display: flex;
    flex-direction: column;

    height: 100%;
    padding: 0 20px;
    gap: 20px;

    .problem-scoring-drawer-body-content-item {
      display: flex;
      flex-direction: column;
      gap: 20px;
      padding-top: 20px;

      &:last-of-type {
        padding-bottom: 20px;
      }

      .problem-scoring-drawer-body-content-item-header {
        display: flex;
        justify-content: space-between;
      }

      ${mediaQuery.underTablet} {
        &:last-of-type {
          padding-bottom: 40px;
        }
      }

      + .problem-scoring-drawer-body-content-item {
        border-top: 1px solid ${colors.gray.$300};
      }

      > .title-wrapper {
        display: flex;
        justify-content: space-between;
      }

      .icon-button {
        width: 20px;
        height: 20px;
        transition: transform 0.3s ease-in-out;
      }

      .icon-button.opened {
        transform: rotate(-180deg);
      }

      .title {
        color: ${colors.gray.$900};
        font-weight: bold;
      }

      .note-container {
        min-height: 312px;

        > div {
          width: fit-content;
        }
      }

      .no-video {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        padding: 40px 0;
        ${typo.body02};
        color: ${colors.gray.$700};
        font-weight: 400;
      }

      .problem-image-container {
        min-height: 312px;
      }

      ${mediaQuery.underTablet} {
        .note-container {
          height: 180px;
          min-height: unset;
          position: relative;

          > div {
            width: unset;
          }
        }
        .note-zoom-in {
          position: absolute;
          right: 12px;
          bottom: 12px;
          width: 32px;
          height: 32px;
          border-radius: 4px;
          border: 1px solid ${colors.gray.$400};
          background-color: ${colors.gray.$100};
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
    .mobile-text {
      min-height: 180px;
      height: 100%;

      display: flex;
      align-items: center;
      justify-content: center;
      color: ${colors.gray.$700};
    }
  `,
  problemScoringDrawerContentStyle: css`
    height: 100%;
    box-shadow: 10px 4px 20px 0px rgba(0, 0, 0, 0.05);
    border-top: 1px solid ${colors.gray.$300};
    .problem-scoring-drawer-header {
      border-radius: 0;
      background-color: white;
    }

    .explation-submitted-answer-text + .explation-submitted-answer-text {
      margin-left: 4px;
      &::before {
        content: ' ・ ';
      }
    }

    ${mediaQuery.underTablet} {
      height: calc(100dvh - 60px); // 전체 높이 - 헤더 높이
      box-shadow: none;
      border-top: none;
      .problem-scoring-drawer-header {
        border-top-left-radius: 14px;
        border-top-right-radius: 14px;
        background-color: transparent;
      }
    }
  `,
  Thumbnail: styled.div`
    width: 100%;
    height: 100%;
    background: url(${s3URL.common('images/video/problem-solving/background.png')}) #fff;

    > .img-bg {
      position: absolute;
      top: 7%;
      left: 30%;
      padding: 4% 4% 0px 4%;

      width: 64%;
      height: 93%;
      background: #fff;
      box-shadow: 0px 4px 30px 0px #0000001a;
    }

    > .gradation {
      position: absolute;
      top: 50%;

      width: 100%;
      height: 50%;
      background: linear-gradient(180deg, rgba(251, 252, 255, 0) 0%, #fbfcff 72.4%);

      > .title {
        position: absolute;
        top: 40%;
        left: 7%;
      }
      > .sub-title {
        position: absolute;
        top: 20%;
        left: 7%;
      }
    }
  `,
}

export default ProblemScoringFooter
