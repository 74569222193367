import { observer } from 'mobx-react'
import { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router'

import { HandwrittenNoteType } from '~/@common/api/handwrittenNoteApi'
import { routeName } from '~/@common/constants'
import { TOAST_STRING } from '~/@common/constants/strings'
import { useLearningTime } from '~/@common/hooks/useLearningTime'
import { useStudentAppMediaQuery } from '~/@common/hooks/useMediaQuery'
import { useRepository } from '~/@common/hooks/useRepository'
import { errorHandlerService, WorksheetProblemNoteService } from '~/@common/services'
import { learningContentLastScreenService } from '~/@common/services/learningContentLastScreen/LearningContentLastScreen.service'
import modalService from '~/@common/services/modal.service'
import { commonRepo } from '~/@common/services/repo.service'
import WorksheetScoringByOne from '~/@pages/@widgets/(Worksheet)/WorksheetScoring/WorksheetScoringByOne'
import WorksheetScoringByOneMobile from '~/@pages/@widgets/(Worksheet)/WorksheetScoring/WorksheetScoringByOne.mobile'
import { openUnavailableAlertMessageModal } from '~/@pages/@widgets/(Worksheet)/접근권한모달'

import { ScoringSubmitConfirmModal } from '../../@widgets/StudentWorksheetScoring/ScoringSubmitConfirmModal'
import { useLearningProcessReference } from '../../learning-process/@common/hooks/useGetLearningProcessReference'
import { StudentWorksheetDetailPageService } from './@service/StudentWorksheetDetailPage.service'
import { StudentWorksheetHeader } from './@widgets/StudentWorksheetHeader'
import { alertWorksheetExceptionErrorModal } from './scoring/alertWorksheetExceptionErrorModal'

const StudentWorksheetDetailPage = observer(() => {
  useLearningTime()
  const { studentWorksheetId } = useParams<{ studentWorksheetId: string }>()
  const navigate = useNavigate()

  // TODO: /student-worksheet 나갈 때 dynamicRepo.del 시킬 수 있도록, student-worksheet을 감싸는 페이지 컴포넌트 신설 필요
  const service = useRepository([StudentWorksheetDetailPageService, studentWorksheetId!])
  const noteService = useRepository(WorksheetProblemNoteService)

  const { isMobile } = useStudentAppMediaQuery()

  const referenceParam = useLearningProcessReference()

  useEffect(() => {
    if (studentWorksheetId && commonRepo.studentAppSetting) {
      service.loadAndSetStudentWorksheetScorings(+studentWorksheetId)
    }
  }, [studentWorksheetId, commonRepo.studentAppSetting])

  // 최초 진입(reload)시 비공개 학습지일 경우 이전 페이지로 돌아감.
  useEffect(() => {
    if (service.worksheet?.accessModifierToStudent === 'PRIVATE') {
      navigate(-1)
    }
  }, [service.worksheet?.accessModifierToStudent])

  useEffect(() => {
    if (!studentWorksheetId) {
      navigate(routeName.student.studentWorksheet)
    }
  }, [studentWorksheetId])

  const onSubmitProblems = () => {
    if (service.problemScoringColl?.toScoredArr.length) {
      modalService.openModal(
        {
          content: (
            <ScoringSubmitConfirmModal
              onSubmit={async () => {
                if (!studentWorksheetId) {
                  return
                }
                try {
                  modalService.showLoader()
                  modalService.closeModal()
                  const problemIds = service.problemScoringColl?.toScoredArr.map((item) => item.id)

                  try {
                    await noteService.uploadNotes(
                      Number(studentWorksheetId),
                      problemIds,
                      HandwrittenNoteType.STUDENT_WORKSHEET_SCORING,
                    )
                    window.freshpaint?.track('필기 제출', { ...referenceParam })
                  } catch (err) {
                    errorHandlerService.handle(err, {
                      message: TOAST_STRING.saveHandwrittenNoteFailed,
                      useRemoteLogging: true,
                    })
                  }
                  await service.onSubmitWorksheetProblems(studentWorksheetId)
                } catch (error) {
                  if (error instanceof Error) {
                    // CASE: 학습지가 삭제되거나 수정된 경우
                    if (
                      error.message === 'WORKSHEET_ALREADY_DELETED' ||
                      error.message === 'STUDENT_WORKSHEET_NOT_FOUND'
                    ) {
                      alertWorksheetExceptionErrorModal()
                    } else if (error.message === 'WORKSHEET_PERMISSION_DENIED') {
                      openUnavailableAlertMessageModal()
                    } else {
                      // CASE: 학습지에 대한 접근 권한이 없을 때
                      openUnavailableAlertMessageModal()
                    }
                  }
                } finally {
                  modalService.hideLoader()
                }
              }}
            />
          ),
        },
        {
          modalName: 'confirmToSubmitAnswers',
        },
      )
    }
  }

  if (!studentWorksheetId) return null

  const lastScreenProblemIndex = learningContentLastScreenService.getLastScreenProblemIndex([
    'STUDENT_WORKSHEET',
    +studentWorksheetId,
  ])

  const updateLastScreenProblemIndex = (currentProblemIndex: number) => {
    if (studentWorksheetId) {
      learningContentLastScreenService.setLastScreenProblemIndex(
        ['STUDENT_WORKSHEET', +studentWorksheetId],
        currentProblemIndex,
      )
    }
  }

  return (
    <>
      <StudentWorksheetHeader title={service.worksheet?.title} />
      {isMobile ? (
        <WorksheetScoringByOneMobile
          problemScoringColl={service.problemScoringColl}
          viewOption={service.problemScoringViewOption}
          onSubmit={onSubmitProblems}
          studentWorksheet={service.studentWorksheet}
          type={service.worksheet?.type}
          problemIndex={lastScreenProblemIndex}
          onProblemIndexChange={updateLastScreenProblemIndex}
        />
      ) : (
        <WorksheetScoringByOne
          problemScoringColl={service.problemScoringColl}
          viewOption={service.problemScoringViewOption}
          onSubmit={onSubmitProblems}
          studentWorksheet={service.studentWorksheet}
          worksheet={service.worksheet}
          problemIndex={lastScreenProblemIndex}
          onProblemIndexChange={updateLastScreenProblemIndex}
        />
      )}
    </>
  )
})

export default StudentWorksheetDetailPage
