import type { HandwrittenNoteTypes } from '@mathflat/handwritten-note'
import { subMonths } from 'date-fns'
import type { DBSchema, IDBPDatabase } from 'idb'
import { openDB } from 'idb'

type NoteData = HandwrittenNoteTypes.NoteData

const NOTE_VALID_MONTHS = 6

interface StudentWorksheetNoteKey {
  studentWorksheetId: number
  worksheetProblemId: number
}

export interface StudentWorksheetNoteValue extends StudentWorksheetNoteKey {
  noteData: NoteData
  timestamp: number
}

interface ObjectStore extends DBSchema {
  studentWorksheetNote: {
    key: number[]
    value: StudentWorksheetNoteValue
  }
}

const getNoteKeyFromValue = (value: StudentWorksheetNoteValue) => {
  return {
    studentWorksheetId: value.studentWorksheetId,
    worksheetProblemId: value.worksheetProblemId,
  }
}

export class IndexedDbService {
  private db?: IDBPDatabase<ObjectStore>

  async open() {
    if (this.db) {
      return
    }
    const database = await openDB<ObjectStore>('handwritten-note', 1, {
      upgrade(db) {
        db.createObjectStore('studentWorksheetNote', {
          keyPath: ['studentWorksheetId', 'worksheetProblemId'],
        })
      },
      /* 
        Called if there are older versions of the database open on the origin, so this version cannot open. 
        This is similar to the blocked event in plain IndexedDB.
       */
      blocked() {
        database.close()
        window.location.reload()
      },
      /*
        Called if this connection is blocking a future version of the database from opening.
        This is similar to the versionchange event in plain IndexedDB.
       */
      blocking() {
        database.close()
        window.location.reload()
      },
    })
    this.db = database
  }

  private getStudentWorksheetCompositKey(key: StudentWorksheetNoteKey) {
    return [key.studentWorksheetId, key.worksheetProblemId]
  }

  async getStudentWorksheetNote(key: StudentWorksheetNoteKey) {
    if (!this.db) {
      await this.open()
    }
    if (!this.db) {
      throw new Error('db not initialized')
    }
    const tx = this.db.transaction('studentWorksheetNote', 'readonly')
    const data = await tx.store.get(this.getStudentWorksheetCompositKey(key))
    await tx.done
    return data
  }

  async getStudentWorksheetNotes(keys: StudentWorksheetNoteKey[]) {
    if (!this.db) {
      await this.open()
    }
    if (!this.db) {
      throw new Error('db not initialized')
    }
    const tx = this.db.transaction('studentWorksheetNote', 'readonly')
    const tasks = keys.map((key) => {
      return tx.store.get(this.getStudentWorksheetCompositKey(key))
    })
    const data = await Promise.all(tasks)
    await tx.done
    return data.filter((item) => Boolean(item)) as StudentWorksheetNoteValue[]
  }

  async getAllStudentWorksheetNotes() {
    if (!this.db) {
      await this.open()
    }
    if (!this.db) {
      throw new Error('db not initialized')
    }
    const tx = this.db.transaction('studentWorksheetNote', 'readonly')
    const data = await tx.store.getAll()
    await tx.done
    return data
  }

  async putStudentWorksheetNote(value: StudentWorksheetNoteValue) {
    if (!this.db) {
      await this.open()
    }
    if (!this.db) {
      throw new Error('db not initialized')
    }

    try {
      const tx = this.db.transaction('studentWorksheetNote', 'readwrite')
      await tx.store.put(value)
      await tx.done
    } catch (err) {
      if (err instanceof Error && err.name === 'QuotaExceededError') {
        const expiredTimestamp = subMonths(new Date(), NOTE_VALID_MONTHS).getTime()
        const allNotes = await this.getAllStudentWorksheetNotes()

        const nonCurrentWorksheetNotes = allNotes.filter(
          (note) => note.studentWorksheetId !== value.studentWorksheetId,
        )

        let targetNotes = nonCurrentWorksheetNotes.filter(
          (note) => !note.timestamp || note.timestamp <= expiredTimestamp,
        )

        if (!targetNotes.length) {
          // 삭제할 노트 없으면 현재 풀이중인 학습지 필기 외에 전부 삭제
          targetNotes = [...nonCurrentWorksheetNotes]
        }

        if (targetNotes.length) {
          await this.deleteStudentWorksheetNotes(targetNotes.map(getNoteKeyFromValue))
          const retryTx = this.db.transaction('studentWorksheetNote', 'readwrite')
          await retryTx.store.put(value)
          await retryTx.done
          return
        }
      }
      throw err
    }
  }

  async deleteStudentWorksheetNotes(keys: StudentWorksheetNoteKey[]) {
    if (!this.db) {
      await this.open()
    }
    if (!this.db) {
      throw new Error('db not initialized')
    }
    const tx = this.db.transaction('studentWorksheetNote', 'readwrite')
    const tasks = keys.map((key) => {
      return tx.store.delete(this.getStudentWorksheetCompositKey(key))
    })
    await Promise.all(tasks)
    await tx.done
  }

  close() {
    if (this.db) {
      this.db.close()
    }
  }
}
