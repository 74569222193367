import { type PropsWithChildren, useEffect, useId, useState } from 'react'

import {
  type RadioGroupContextValue,
  type RadioGroupInputValue,
  RadioGroupProvider,
} from './RadioGroup.context'

export type RadioGroupProps = RadioGroupContextValue & PropsWithChildren

const RadioGroup = ({ children, name, value, onChange, ...props }: RadioGroupProps) => {
  const _name = useId()
  const [_value, _setValue] = useState(value)

  const handleChange = (v: RadioGroupInputValue, e?: React.ChangeEvent<HTMLInputElement>) => {
    onChange?.(v, e)

    if (!value) {
      _setValue(v)
    }
  }

  useEffect(() => {
    if (value) {
      _setValue(value)
    }
  }, [value])

  return (
    <RadioGroupProvider name={name ?? _name} value={_value} onChange={handleChange} {...props}>
      {children}
    </RadioGroupProvider>
  )
}

export default RadioGroup
