import { css } from '@emotion/react'
import { observer } from 'mobx-react'
import type { FC, ReactNode } from 'react'

export const TooltipContent: FC<{ children: ReactNode }> = ({ children }) => (
  <div css={Style.content}>{children}</div>
)

export const TooltipTitle: FC<{ align?: 'left' | 'center'; children: ReactNode }> = observer(
  ({ align = 'center', children }) => {
    return (
      <div
        css={Style.title}
        style={{
          textAlign: align,
        }}
      >
        {children}
      </div>
    )
  },
)

export const TooltipItem: FC<{ children: ReactNode; withBullet?: boolean }> = observer(
  ({ children, withBullet = true }) => {
    return (
      <div css={Style.item}>
        {withBullet && <span css={Style.bullet}></span>}
        {children}
      </div>
    )
  },
)

const Style = {
  title: css({
    fontWeight: 'bold',
  }),
  content: css({
    display: 'flex',
    flexDirection: 'column',
    gap: 10,
    padding: 14,
    color: 'white',
    borderRadius: 10,
    backgroundColor: 'rgba(0, 0, 0, 0.7)',
  }),
  item: css({
    display: 'flex',
    alignItems: 'start',
    gap: 4,
  }),
  bullet: css({
    display: 'inline-block',
    width: 3,
    height: 3,
    borderRadius: 3,
    backgroundColor: 'white',
    marginTop: 8,
  }),
}
