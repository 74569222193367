import { css } from '@emotion/react'

import { colors } from './colors'

export { colors, colorTheme } from './colors'
export { elevation } from './elevation'
export { fontFamily } from './fontFamily'
export { fontWeight } from './fontWeight'
export { textEllipsis } from './textEllipsis'
export { typo } from './typo'

export const whiteAreaCss = css`
  background-color: white;
  border-radius: 14px;
  box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.05);
  overflow: hidden;
`

export const tooltipContainerCss = css`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 360px;
  height: 300px;
  padding: 20px;

  border-radius: 14px;
  border: 1px solid ${colors.gray.$400};

  background: ${colors.white};
  box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.1);
  color: ${colors.gray.$900};
`

export const activeCSS = css`
  &:hover {
    background-color: ${colors.gray.$100};
  }
  &:active {
    background-color: ${colors.gray.$200};
  }
`
export const zIndex = {
  QR스캔: 1,
  채점툴팁: 10,
  툴팁: 10,
  모달: 1000,
  로더: 900,
  네비게이션_센터: 200,
  drawer: 200,
  drawer_backdrop: 99,
}
export { maxWidth } from '~/@common/styles/maxWidth.ts'
