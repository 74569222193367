import { css } from '@emotion/react'
import { observer } from 'mobx-react'
import type { FC } from 'react'
import { useEffect, useState } from 'react'

import { useStudentAppMediaQuery } from '~/@common/hooks/useMediaQuery'
// import SimpleBar from 'simplebar-react'
import { useRepository } from '~/@common/hooks/useRepository'
import { commonRepo } from '~/@common/services/repo.service'
import { colors } from '~/@common/styles'
import { mediaQuery } from '~/@common/styles/mediaQuery'
import GroupBox from '~/@pages/@common/(LectureMaterial)/GroupBox'
import LectureList from '~/@pages/@common/(LectureMaterial)/LectureList'
import type { Lecture } from '~/@pages/@common/(LectureMaterial)/LectureVideoItem'
import LectureVideoPlayer from '~/@pages/@common/(LectureMaterial)/LectureVideoPlayer'
import NoData from '~/@pages/@common/(LectureMaterial)/NoData'
import { LearningProcessService } from '~/@pages/student/learning-process/@widgets/service/LearningProcess.service'
import ConceptsItem from '~/@pages/student/learning-process/@widgets/ui/(step)/개념학습/ConceptsItem'

import { ConceptualLearningService } from '../service/ConceptualLearning.service'
import ConceptualLearningScoringSection from './ConceptualLearningScoringSection'

const ConceptualLearningEduMaterialSection: FC = () => {
  const learningProcessService = useRepository(LearningProcessService)
  const service = useRepository(ConceptualLearningService)
  const [selectedLecture, setSelectedLecture] = useState<Lecture>()
  const { isMobile } = useStudentAppMediaQuery()
  const { conceptualEduMaterial, conceptualEduMaterialStatus } = service
  const { hasLectures, hasPrevLectures, hasConcepts, hasPrevConcepts } = conceptualEduMaterialStatus

  const handleProgressSubmit = async () => {
    if (selectedLecture && commonRepo.studentId) {
      learningProcessService.postVideo({
        lectureId: selectedLecture.id,
        studentId: commonRepo.studentId,
      })
    }
  }

  const handleLectureClick = (item: Lecture) => {
    if (item.id === selectedLecture?.id) {
      return
    }
    setSelectedLecture(item)
  }

  useEffect(() => {
    if (!conceptualEduMaterial) {
      return
    }
    if (!hasLectures && !hasPrevLectures) {
      setSelectedLecture(undefined)
      return
    }

    setSelectedLecture(conceptualEduMaterial.lectures[0] ?? conceptualEduMaterial.prevLectures[0])
  }, [conceptualEduMaterial, hasLectures, hasPrevLectures])

  const videoPlayer = (
    <div className="video">
      {selectedLecture ? (
        <LectureVideoPlayer
          selectedLecture={selectedLecture}
          onProgressSubmit={handleProgressSubmit}
        />
      ) : (
        <div className="no-video">
          <NoData
            iconSize={isMobile ? 'small' : 'normal'}
            content={
              <>
                개념 영상이 아직 준비되지 않았어요.
                <br />
                문제를 먼저 풀어보세요.
              </>
            }
          />
        </div>
      )}
    </div>
  )

  if (!conceptualEduMaterial) {
    return null
  }

  return (
    <div
      css={_css}
      onClick={() => {
        service.setIsShowAnswerTooltip(false)
      }}
    >
      {isMobile && videoPlayer}
      {/* TO-DO: SimpleBar 적용 */}
      <div className="edu-material-scroll-container">
        <div className="edu-material-section">
          {!isMobile && videoPlayer}
          <div className="edu-material-content">
            <GroupBox type="mixed" isPreLearned={false} isDefaultOpened={false}>
              {hasLectures || hasConcepts ? (
                <>
                  {hasLectures && (
                    <LectureList
                      items={conceptualEduMaterial.lectures}
                      selectedItem={selectedLecture}
                      isMobile={isMobile}
                      onLectureClick={handleLectureClick}
                    />
                  )}

                  {hasLectures && hasConcepts && <div className="line"></div>}

                  {hasConcepts &&
                    conceptualEduMaterial?.concepts.map((item, index) => (
                      <ConceptsItem
                        key={item.id}
                        index={index}
                        item={item}
                        isPreLearned={false}
                        isDefaultOpened={hasConcepts && index === 0}
                        labelPrefix="소단원 개념"
                      />
                    ))}
                </>
              ) : (
                <NoData
                  iconSize={isMobile ? 'small' : 'normal'}
                  content="내용이 아직 준비되지 않았어요."
                />
              )}
            </GroupBox>

            <GroupBox type="mixed" isPreLearned={true} isDefaultOpened={false}>
              {hasPrevLectures || hasPrevConcepts ? (
                <>
                  {hasPrevLectures && (
                    <LectureList
                      items={conceptualEduMaterial.prevLectures}
                      selectedItem={selectedLecture}
                      isMobile={isMobile}
                      onLectureClick={handleLectureClick}
                    />
                  )}
                  {hasPrevLectures && hasPrevConcepts && <div className="line"></div>}
                  {hasPrevConcepts &&
                    conceptualEduMaterial?.prevConcepts.map((item, index) => (
                      <ConceptsItem
                        key={item.id}
                        index={index}
                        item={item}
                        isPreLearned={true}
                        isDefaultOpened={hasConcepts && index === 0}
                        labelPrefix="사전개념"
                      />
                    ))}
                </>
              ) : (
                <NoData
                  iconSize={isMobile ? 'small' : 'normal'}
                  content="내용이 아직 준비되지 않았어요."
                />
              )}
            </GroupBox>
          </div>
        </div>
        {isMobile && <ConceptualLearningScoringSection />}
      </div>

      {!isMobile && <ConceptualLearningScoringSection />}
    </div>
  )
}

export default observer(ConceptualLearningEduMaterialSection)

const _css = css`
  display: flex;
  gap: 24px;
  height: 100%;

  ${mediaQuery.underTablet} {
    flex-direction: column;
    gap: unset;
    height: 100%;
  }

  .edu-material-scroll-container {
    display: flex;
    min-width: 408px;
    max-width: 50%;
    flex: 1 0;

    ${mediaQuery.underTablet} {
      flex-direction: column;
      min-width: unset;
      max-width: unset;
      padding: 20px 0 40px;
      overflow: auto;
    }

    ${mediaQuery.underTablet} and (orientation: landscape) {
      display: unset;
      height: unset;
      overflow: unset;
    }
  }

  .edu-material-section {
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow: auto;

    ${mediaQuery.underTablet} {
      height: unset;
      overflow: visible;
    }
  }

  .edu-material-content {
    overflow: auto;
    display: flex;
    flex-direction: column;
    gap: 12px;
    margin-top: 12px;
    flex-shrink: 0;

    ${mediaQuery.underTablet} {
      padding: 0 16px;
      margin-top: 0;
    }
  }

  .line {
    height: 1px;
    background-color: ${colors.gray.$300};
  }

  .video {
    width: 100%;

    .no-video {
      aspect-ratio: calc(588 / 331);
      background-color: ${colors.gray.$900};
      display: flex;
      align-items: center;
      justify-content: center;

      .content {
        color: ${colors.white};
      }
    }
  }
`
