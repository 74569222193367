import { css } from '@emotion/react'
import type { ComponentProps } from 'react'
import { useMatches } from 'react-router'

import type { RouteHandle } from '~/@app/appRouter'
import { useStudentAppMediaQuery } from '~/@common/hooks/useMediaQuery'
import { maxWidth } from '~/@common/styles'
import { mediaQuery } from '~/@common/styles/mediaQuery'

export const kind = `ms__Container` as const

export type Props = ComponentProps<'div'>

export default function Container(props: Props) {
  const { isMobile } = useStudentAppMediaQuery()
  const matches = useMatches()
  const ignoreMobileContainerPadding = Boolean(
    matches.find((match) => {
      if (!match.handle) {
        return
      }
      return (match.handle as RouteHandle).ignoreMobileContainerPadding
    }),
  )

  return (
    <div
      data-component={kind}
      css={_css}
      {...props}
      style={{
        padding: isMobile && ignoreMobileContainerPadding ? 0 : undefined,
      }}
    />
  )
}

const _css = css`
  display: flex;
  flex-direction: column;

  width: 100%;
  height: 100%;
  overflow: hidden;

  margin: 0 auto;
  ${maxWidth.tablet.maxContentWidth};
  padding: 20px 40px 25px;

  ${mediaQuery.underDesktop} {
    padding: 20px 40px;
  }

  ${mediaQuery.underTablet} {
    padding: 10px 16px 40px;
  }
`
