type Validation = {
  [validationName: string]: (value: string) => true | string
}

class ValidationService {
  requiredMessage = '필수 항목을 입력해주세요'

  // loginUserId: Validation = {
  //   length: (value) =>
  //     (value.length >= 6 && value.length <= 16) || '6자 이상 16자 이하로 입력해주세요.',
  // }

  // loginUserPw: Validation = {
  //   minLength: (value) => value.length >= 6 || '6자 이상으로 입력해주세요.',
  //   maxLength: (value) => value.length <= 20 || '20자 이하로 입력해주세요.',
  // }

  password: Validation = {
    minLength: (value) => value.length >= 6 || '6자 이상으로 입력해주세요',
    textValid: (value) => {
      let textKindCount = 0
      if (/[a-zA-Z]/.test(value)) textKindCount += 1
      if (/[0-9]/.test(value)) textKindCount += 1
      if (/[!@#$%^&*(),.?":{}|<>\-_=]/.test(value)) textKindCount += 1
      return textKindCount >= 2 || '영문 대/소문자, 숫자, 특수 문자 중 2가지로 조합해주세요'
    },
    maxLength: (value) => value.length <= 20 || '20자 이하로 입력해주세요',
  }
}

const validationService = new ValidationService()
export default validationService
