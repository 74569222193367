import { Schema } from '@effect/schema'

export module ConceptChipDomain {
  export const LEVEL_OF_ACHIEVEMENT = {
    풀지않음: 'WHITE',
    문제수부족: 'GRAY', // 문제 1개 풀었을때
    LV1: 'SAD',
    LV2: 'RED',
    LV3: 'YELLOW',
    LV4: 'GREEN',
    LV5: 'SMILE',
  } as const

  export const LEVEL_OF_DIFFICULTY = {
    개념: '개념',
    기본: '기본',
    심화: '심화',
  } as const

  export const ORDER_LEVEL_OF_DIFFICULTY = ['개념', '기본', '심화'] as const
  export const ORDER_LEVEL_OF_ACHIEVEMENT = [
    'WHITE',
    'GRAY',
    'SAD',
    'RED',
    'YELLOW',
    'GREEN',
    'SMILE',
  ] as const

  export type ORDER_LEVEL_OF_DIFFICULTY = typeof ORDER_LEVEL_OF_DIFFICULTY
  export type ORDER_LEVEL_OF_ACHIEVEMENT = typeof ORDER_LEVEL_OF_ACHIEVEMENT

  export const LevelOfAchievementTo = Schema.enums(LEVEL_OF_ACHIEVEMENT)
  export const LevelOfAchievement = Schema.transform(
    Schema.nullable(Schema.literal(0, 1, 2, 3, 4, 5)),
    Schema.enums(LEVEL_OF_ACHIEVEMENT),
    (v) => {
      switch (v) {
        case null:
          return LEVEL_OF_ACHIEVEMENT.풀지않음
        case 0:
          return LEVEL_OF_ACHIEVEMENT.문제수부족
        case 1:
          return LEVEL_OF_ACHIEVEMENT.LV1
        case 2:
          return LEVEL_OF_ACHIEVEMENT.LV2
        case 3:
          return LEVEL_OF_ACHIEVEMENT.LV3
        case 4:
          return LEVEL_OF_ACHIEVEMENT.LV4
        case 5:
          return LEVEL_OF_ACHIEVEMENT.LV5
      }
    },
    (v) => {
      switch (v) {
        case LEVEL_OF_ACHIEVEMENT.풀지않음:
          return null
        case LEVEL_OF_ACHIEVEMENT.문제수부족:
          return 0
        case LEVEL_OF_ACHIEVEMENT.LV1:
          return 1
        case LEVEL_OF_ACHIEVEMENT.LV2:
          return 2
        case LEVEL_OF_ACHIEVEMENT.LV3:
          return 3
        case LEVEL_OF_ACHIEVEMENT.LV4:
          return 4
        case LEVEL_OF_ACHIEVEMENT.LV5:
          return 5
      }
    },
  )

  export const LevelOfDifficultyTo = Schema.enums(LEVEL_OF_DIFFICULTY)
  export const LevelOfDifficulty = Schema.transform(
    Schema.literal('A', 'B', 'C'),
    LevelOfDifficultyTo,
    (v) => {
      switch (v) {
        case 'A':
          return '개념'
        case 'B':
          return '기본'
        case 'C':
          return '심화'
      }
    },
    (v) => {
      switch (v) {
        case '개념':
          return 'A'
        case '기본':
          return 'B'
        case '심화':
          return 'C'
      }
    },
  )

  // 성취도 등급
  export const Type = Schema.enums(LEVEL_OF_DIFFICULTY)

  export type LevelOfAchievement = Schema.Schema.To<typeof LevelOfAchievement>
  export type LevelOfDifficulty = Schema.Schema.To<typeof LevelOfDifficulty>
  export type Type = Schema.Schema.To<typeof Type>
}
