import { createContext } from '@radix-ui/react-context'

import type { Service } from '~/@common/services/(LastLocationManager)/service.ts'

import { name } from './meta.ts'

const [_Provider, _useLastLocationManager] = createContext<{ value: Service }>(
  'LastLocationManager',
)

export const Provider = _Provider

export const useLastLocationManager = () => _useLastLocationManager(name).value
