export function toHSL({ h, s, l }: HSLColor) {
  return `hsl(${h}, ${s}%, ${l}%)`
}

export const darken = ({ h, s, l }: HSLColor, amount: number) => {
  return toHSL({ h, s, l: l - amount })
}

export const lighten = ({ h, s, l }: HSLColor, amount: number) => {
  return toHSL({ h, s, l: l + amount })
}

export type HSLColor = { h: number; s: number; l: number }
