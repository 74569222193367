import type { ProcessInfo } from '~/@pages/student/learning-process/@common/types/LearningProcess.type.ts'
import type { Step } from '~/@pages/student/learning-process/@common/types/LearningProcess.type.ts'

export class ProcessController {
  private _processInfo: ProcessInfo

  constructor(processInfo: ProcessInfo) {
    this._processInfo = processInfo
  }

  updateProcessInfo(processInfo: ProcessInfo) {
    this._processInfo = processInfo
  }

  updateStep(stepId: number) {
    this.updateProcessInfo({ ...this._processInfo, lastStep: stepId })
    return this
  }

  updateScreen(stepId: number, screenId: number) {
    this.updateProcessInfo({
      ...this.processInfo,
      process: this.processInfo.process.map((eachStep, index) => {
        if (index === stepId) {
          return { ...eachStep, lastScreen: screenId }
        }
        return eachStep
      }),
    })
    return this
  }

  updateElement(stepId: number, screenId: number, elementId: number) {
    this.updateProcessInfo({
      ...this.processInfo,
      process: this.processInfo.process.map((eachStep, index) => {
        if (index === stepId) {
          return {
            ...eachStep,
            screen: eachStep.screen.map((eachScreen, idx) => {
              if (idx === screenId) {
                return { ...eachScreen, lastElement: elementId }
              }
              return eachScreen
            }),
          }
        }
        return eachStep
      }),
    })
    return this
  }

  updateProcess(StepData: Step) {
    this.updateProcessInfo({
      ...this.processInfo,
      process: this.processInfo.process.map((eachStep) => {
        if (eachStep.step === StepData.step) {
          return StepData
        }
        return eachStep
      }),
    })
    return this
  }

  get processInfo() {
    return this._processInfo
  }
}
