const kind = 'ms__switch' as const

const parts = {
  label: `${kind}_label`,
  input: `${kind}_input`,
  switch: `${kind}_switch`,
} as const

export default {
  kind,
  parts,
}
