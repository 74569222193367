import { css } from '@emotion/react'
import { observer } from 'mobx-react'
import { type FC, useCallback, useMemo, useRef, useState } from 'react'

import useInfiniteScroll from '~/@common/hooks/useInfiniteScroll'
import { useStudentAppMediaQuery } from '~/@common/hooks/useMediaQuery'
import { useRepository } from '~/@common/hooks/useRepository'
import { errorHandlerService } from '~/@common/services'

import RecentStudyService from '../../@service/RecentStudy.service'
import StudyCard from './StudyCard'
import MoreButton from '../MoreButton'

const MOBILE_ITEMS_PER_PAGE = 10
const MOBILE_ITEMS_DEFAULT_LENGTH = 5

const RecentStudyList: FC = () => {
  const service = useRepository(RecentStudyService)
  const { recentStudies, moreRecentStudyParams, isLastPage } = service
  const lastListItemRef = useRef<HTMLLIElement>(null)
  const [page, setPage] = useState(1)
  const { isMobile } = useStudentAppMediaQuery()

  const recentStudySize = recentStudies?.length ?? 0

  const mobileTotalPage =
    Math.ceil((recentStudySize - MOBILE_ITEMS_DEFAULT_LENGTH) / MOBILE_ITEMS_PER_PAGE) + 1

  const loadMoreRecentStudies = useCallback(async () => {
    if (!moreRecentStudyParams || isLastPage) {
      return
    }
    try {
      await service.loadMoreRecentStudies()
    } catch (err) {
      errorHandlerService.handle(err)
    }
  }, [moreRecentStudyParams, isLastPage, service])

  // infinite loading
  const handleScroll = useCallback(() => {
    if (isMobile) {
      return
    }
    void loadMoreRecentStudies()
  }, [isMobile, loadMoreRecentStudies])

  useInfiniteScroll(lastListItemRef, handleScroll)

  const recentStudyItems = useMemo(() => {
    if (!recentStudies) {
      return
    }
    if (!isMobile) {
      return recentStudies
    }
    return recentStudies.slice(0, (page - 1) * MOBILE_ITEMS_PER_PAGE + MOBILE_ITEMS_DEFAULT_LENGTH)
  }, [isMobile, page, recentStudies])

  const availableMobileItemSize = recentStudySize - (recentStudyItems?.length ?? 0)

  let hasMore = false

  if (isLastPage) {
    hasMore = mobileTotalPage > page
  } else {
    hasMore = mobileTotalPage >= page
  }

  const handleMore = async () => {
    if (hasMore) {
      if (availableMobileItemSize < MOBILE_ITEMS_PER_PAGE) {
        await loadMoreRecentStudies()
      }
      setPage((page) => page + 1)
    } else {
      setPage(1)
    }
  }

  return (
    <>
      <ul css={_Style}>
        {recentStudyItems?.map((item) => <StudyCard key={`${item.type}:${item.id}`} item={item} />)}
        <li ref={lastListItemRef} className="last-item-placeholder"></li>
      </ul>
      {isMobile && recentStudySize >= MOBILE_ITEMS_DEFAULT_LENGTH && (
        <MoreButton hasMore={hasMore} onClick={handleMore} />
      )}
    </>
  )
}

export default observer(RecentStudyList)

const _Style = css`
  display: flex;
  flex-direction: column;
  gap: 10px;
  position: relative;

  .last-item-placeholder {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 10px;
    visibility: hidden;
    pointer-events: none;
  }
`
