import styled from '@emotion/styled'
import { observer } from 'mobx-react'

import { handwrittenNoteTooltipService } from '~/@common/services/HandwrittenTooltip.service'
import { colors } from '~/@common/styles'
import type { DefaultProps } from '~/@common/types'
import { Icon } from '~/@common/ui/Icon/Icon'

export const HandwrittenNoteInfoTooltip = observer(({ className, style }: DefaultProps) => {
  return (
    <>
      {handwrittenNoteTooltipService.isShowHandwrittenNoteInfoTooltip && (
        <StyledHandwrittenNoteInfoTooltip className={className} style={style}>
          <span>
            <Icon name="icon_info_circled" color={colors.red.$400} size={16} />
          </span>
          <p className="paragraph">
            <strong>세로 모드에서는 필기 기능이 제한될 수 있습니다.</strong>
            <br />
            <span>필기 기능을 사용 시 디바이스를 가로 모드로 전환해 주세요.</span>
          </p>
          <span>
            <button onClick={() => handwrittenNoteTooltipService.hideHandwrittenInfoTooltip()}>
              <Icon name="icon_close" color={colors.red.$400} size={20} />
            </button>
          </span>
        </StyledHandwrittenNoteInfoTooltip>
      )}
    </>
  )
})

const StyledHandwrittenNoteInfoTooltip = styled.div`
  position: fixed;
  bottom: 40px;
  left: 16px;
  display: flex;
  gap: 8px;
  width: calc(100% - 32px);
  padding: 12px;
  border-radius: 6px;
  background-color: ${colors.red.$100};
  z-index: 1;

  .paragraph {
    color: ${colors.red.$400};
    flex: 1;
  }
`
