import type { SerializedStyles } from '@emotion/react'
import clsx from 'clsx'
import React, { type ComponentPropsWithoutRef, useId } from 'react'

import Checkbox from '~/@common/ui/checkbox'
import CheckboxItem from '~/@common/ui/checkbox/CheckboxItem'

import type { CheckboxGroupInputProps } from './CheckboxGroupInput'

export const checkboxGroupItemFactory = (
  inputProps: CheckboxGroupInputProps,
  displayName: string,
  _css?: SerializedStyles | SerializedStyles[],
  labelProps?: ComponentPropsWithoutRef<'label'>,
) => {
  const Component = React.forwardRef<HTMLInputElement, CheckboxGroupInputProps>(
    ({ id: _id, children, className, ...props }, ref) => {
      const _uid = useId()
      const id = _id ?? _uid
      return (
        <CheckboxItem
          className={clsx(displayName, className)}
          css={_css}
          data-component="ms__checkbox-group-item"
        >
          <Checkbox.GroupInput ref={ref} id={id} hidden {...props} />
          <label {...labelProps} htmlFor={id}>
            {children}
          </label>
        </CheckboxItem>
      )
    },
  )
  Component.displayName = displayName
  return <Component {...inputProps} />
}

//checkboxGroupItemFactory 사용법은 SubTabItem 컴포넌트 참고
