import { ConceptApi } from '@mathflat/domain/@entities/Concept/api.ts'
import { ConceptChipDomain } from '@mathflat/domain/@entities/ConceptChip/domain.ts'
import { StudentHistoryApi } from '@mathflat/domain/@entities/StudentHistory/api.ts'
import {
  HashtagMap,
  type TagTuples,
} from '@mathflat/shared/@common/dataStruct/HashtagMap/HashTagMap.ts'
import { intersection } from '@mathflat/shared/@common/utils/set'
import { pipe } from 'effect'
import * as O from 'effect/Option'
import { computed, makeAutoObservable, observable, toJS } from 'mobx'
import { startTransition } from 'react'

import { apiSpecFetcher } from '~/@common/utils/apiSpecFetcher'
import type ConceptChipDetail from '~/@pages/@common/(ConceptChip)/ConceptChipDetail/ConceptChipDetail'

type ConceptChipDetail = ConceptApi.유형칩조회.Output[number]
type ConceptChipForAchievement = StudentHistoryApi.성취도_등급_조회.Output[number]

export type ChallengeStudentConceptChipData = {
  conceptChipId: number
  conceptId: number
  conceptName: string
  recommended: boolean
  bigChapterId: number
  bigChapterName: string
  middleChapterId: number
  middleChapterName: string
  littleChapterId: number
  littleChapterName: string

  levelOfConceptChip: ConceptChipDomain.Type
  levelOfAchievement: ConceptChipDomain.LevelOfAchievement
  orderNumber: number
  totalOrderScore: number
}

type Tags = {
  levelOfAchievement: ConceptChipDomain.LevelOfAchievement
  levelOfConceptChip: ConceptChipDomain.Type
  littleChapterId: number
  middleChapterId: number
  recommended: '추천'
} & {
  [K in `중단원/${number}`]: number
}

type ConceptChipId = ChallengeStudentConceptChipData['conceptChipId']

export type HashtagItem = {
  id: ChallengeStudentConceptChipData['conceptChipId']
  tags: Tags
  value: ChallengeStudentConceptChipData
}

type FetchParams = StudentHistoryApi.성취도_등급_조회.PathParams & ConceptApi.유형칩조회.Params

const defaultFilterHashtags = {
  levelOfAchievement: new Set<ConceptChipDomain.LevelOfAchievement>(),
  recommended: new Set<'추천'>(),
}

const filterKeys = ['levelOfAchievement', 'recommended'] as const

export class ChallengeService {
  private lastFetchKey: string | null = null
  private 유형칩ListData: ReadonlyArray<ConceptChipDetail> = []
  private 학생유형칩HashtagMap = new HashtagMap<
    Tags,
    ConceptChipDetail['conceptChipId'],
    HashtagItem
  >()

  static fetch유형칩 = apiSpecFetcher(ConceptApi.유형칩조회.spec)
  static fetch성취도 = apiSpecFetcher(StudentHistoryApi.성취도_등급_조회.spec)

  get isEmpty() {
    return this.size === 0
  }

  fetch = async (fetchParams: FetchParams) => {
    const { studentId, workbookIds, curriculumKey } = fetchParams

    // 마지막 fetch한 키 값을 기억해서 같은 키 값이면 유형칩은 가져오지 않음
    const fetchKey = ChallengeService.makeFetchKey(fetchParams)
    const isDifferentFetchParams: boolean =
      this.lastFetchKey === null ? true : this.lastFetchKey !== fetchKey

    this.lastFetchKey = fetchKey

    const [유형칩Res, 성취도Res] = await Promise.all([
      isDifferentFetchParams
        ? ChallengeService.fetch유형칩({
            params: Object.assign(
              {
                curriculumKey,
              },
              workbookIds && { workbookIds },
            ),
          })
        : null,
      ChallengeService.fetch성취도({
        params: {
          curriculumKey,
        },
        pathParams: {
          studentId,
        },
      }),
    ])

    startTransition(() => {
      const 유형칩List = 유형칩Res?.data ?? this.유형칩ListData
      const 성취도List = 성취도Res.data

      // 유형칩 기억
      this.set유형칩ListData(유형칩List)
      this.set학생유형칩HashtagMap(
        ChallengeService.make학생유형칩HashtagMap(유형칩List, 성취도List),
      )

      // 유형칩을 다시 가져왔으면 필터를 초기화
      if (유형칩Res) {
        this.resetFilterHashtags()
      }
    })
  }

  static makeFetchKey = (params: FetchParams) => {
    return JSON.stringify(params)
  }

  set유형칩ListData = (유형칩ListData: ReadonlyArray<ConceptChipDetail>) => {
    this.유형칩ListData = 유형칩ListData
  }

  public filterHashtags = toJS(defaultFilterHashtags)

  resetFilterHashtags = () => {
    this.filterHashtags.recommended.clear()
    this.filterHashtags.levelOfAchievement.clear()
  }

  addLevelOfAchievementFilter = (levelOfAchievement: Tags['levelOfAchievement']) => {
    this.filterHashtags.levelOfAchievement.add(levelOfAchievement)
  }
  deleteLevelOfAchievementFilter = (levelOfAchievement: Tags['levelOfAchievement']) => {
    this.filterHashtags.levelOfAchievement.delete(levelOfAchievement)
  }

  hasLevelOfAchievementFilter = (levelOfAchievement: Tags['levelOfAchievement']) => {
    return this.filterHashtags.levelOfAchievement.has(levelOfAchievement)
  }

  addRecommendedFilter = (recommended: Tags['recommended']) => {
    this.filterHashtags.recommended.add(recommended)
  }
  deleteRecommendedFilter = (recommended: Tags['recommended']) => {
    this.filterHashtags.recommended.delete(recommended)
  }

  get filterIdSets() {
    return filterKeys.map((key) =>
      this.학생유형칩HashtagMap.getIdFromUnionTags(
        [...this.filterHashtags[key]].map((v) => [key, v] as const),
      ),
    ) as [Set<ConceptChipId>, Set<ConceptChipId>]
  }

  private filtering = (set: Set<ConceptChipId>): Set<ConceptChipId> => {
    const filters = this.filterIdSets.filter((set) => set.size > 0)
    if (filters.length === 0) return set
    return filters.reduce(intersection, set)
  }

  private set학생유형칩HashtagMap = (학생유형칩HashtagMap: typeof this.학생유형칩HashtagMap) => {
    this.학생유형칩HashtagMap = 학생유형칩HashtagMap
  }

  get size() {
    return this.학생유형칩HashtagMap.size
  }

  static make성취도Map = (성취도List: ReadonlyArray<ConceptChipForAchievement>) => {
    return new Map(성취도List.map((v) => [v.conceptChipId, v]))
  }

  private static make학생유형칩HashtagMap(
    유형칩List: ChallengeService['유형칩ListData'],
    성취도List: StudentHistoryApi.성취도_등급_조회.Output,
  ): ChallengeService['학생유형칩HashtagMap'] {
    const _성취도map = ChallengeService.make성취도Map(성취도List)

    return new HashtagMap(
      유형칩List.map((유형칩) => {
        const 성취도 = _성취도map.get(유형칩.conceptChipId)
        const value = ChallengeService.make학생유형칩HashtagMapValue(유형칩, 성취도)

        return {
          id: 유형칩.conceptChipId,
          tags: ChallengeService.extractValueToTags(value),
          value,
        }
      }),
    )
  }

  // 유형칩 데이터에 학생의 성취도 데이터를 결합
  private static make학생유형칩HashtagMapValue(
    conceptChipDetail: ConceptChipDetail,
    conceptChipForAchievement?: ConceptChipForAchievement,
  ): ChallengeStudentConceptChipData {
    const orderValue = {
      levelOfAchievement: conceptChipForAchievement?.achievementLevel ?? 'WHITE',
      levelOfConceptChip: conceptChipDetail.conceptChipType,
      orderNumber: conceptChipDetail.orderNumber,
    }

    return Object.assign(orderValue, conceptChipDetail, {
      totalOrderScore: ChallengeService.getOrderScore(orderValue),
    })
  }

  static extractValueToTags({
    littleChapterId,
    middleChapterId,
    recommended,
    levelOfAchievement,
    levelOfConceptChip,
  }: ChallengeStudentConceptChipData) {
    const tags = Object.assign(
      {
        levelOfConceptChip,
        levelOfAchievement,
        littleChapterId,
        middleChapterId,
        [`중단원/${middleChapterId}`]: littleChapterId,
      },
      recommended && { recommended: '추천' },
    ) as Tags
    return tags
  }

  /*
   * [소단원 추천학습] - 소단원 내에서 5개 선택
   * 정렬 우선순위
   * 1. 성취도 오름차순 (레벨이 낮은 유형칩 우선순위 white → gray → sad → …)
   * 2. 난이도 오름차순 (개념 → 기본 → 심화)
   * 3. 오더링 오름차순 (orderingNumber)
   */
  static getOrderScore = ({
    levelOfAchievement,
    levelOfConceptChip,
    orderNumber,
  }: Pick<
    ChallengeStudentConceptChipData,
    'levelOfConceptChip' | 'levelOfAchievement' | 'orderNumber'
  >) => {
    let orderScore = 0
    // 성취도 order score 추가
    orderScore +=
      (ConceptChipDomain.ORDER_LEVEL_OF_ACHIEVEMENT.indexOf(levelOfAchievement) + 1) *
      성취도OrderScoreBase
    // 난이도 order score 추가
    orderScore +=
      (ConceptChipDomain.ORDER_LEVEL_OF_DIFFICULTY.indexOf(levelOfConceptChip) + 1) *
      난이도OrderScoreBase
    // orderNumber order score 추가
    orderScore += orderNumber

    return orderScore
  }

  get = (id: ConceptChipId) => this.학생유형칩HashtagMap.get(id)

  getFilteredIdMapFromKindTag = <K extends keyof Tags>(k: K) =>
    pipe(
      this.학생유형칩HashtagMap.getIdMapFromTagKind(k),
      O.map((map) => {
        const result = new Map<Tags[K], Set<ConceptChipId>>()

        for (const [k, v] of map) {
          const filtered = this.filtering(v)
          if (filtered.size === 0) continue
          result.set(k, filtered)
        }

        return result
      }),
    )
  getIdFromTag = <K extends keyof Tags>(tagName: K, tagValue: Tags[K]) =>
    this.학생유형칩HashtagMap.getIdFromTag(tagName, tagValue)

  getFromTag = <K extends keyof Tags>(tagName: K, tagValue: Tags[K]) =>
    this.학생유형칩HashtagMap.getFromTag(tagName, tagValue)

  getFilteredIdFromIntersectionTags = (tags: TagTuples<Tags>) =>
    pipe(this.학생유형칩HashtagMap.getIdFromIntersectionTags(tags), this.filtering)

  getFilteredFromIntersectionTagArr = (tags: TagTuples<Tags>) =>
    pipe(this.학생유형칩HashtagMap.getIdFromIntersectionTags(tags), this.filtering, (set) => {
      const result: ChallengeStudentConceptChipData[] = []
      for (const id of set) {
        const item = this.학생유형칩HashtagMap.get(id)
        if (O.isSome(item)) result.push(item.value.value)
      }
      return result
    })

  constructor() {
    makeAutoObservable<this, '학생유형칩HashtagMap' | '유형칩ListData'>(this, {
      학생유형칩HashtagMap: observable.ref,
      유형칩ListData: observable.shallow,
      filterIdSets: computed.struct,
    })
  }
}

const 성취도OrderScoreBase = 10e14
const 난이도OrderScoreBase = 10e13
