import type { StudentWorksheetApi } from '@mathflat/domain/@entities/StudentWorksheet/api'
import { useSearchParams } from 'react-router-dom'

import type { REFERENCE_TYPE_STUDENT_WORKSHEET } from '../../@widgets/service/LearningProcess.service'

type ReferenceType =
  | StudentWorksheetApi.챌린지학습지조회.ReferenceType
  | typeof REFERENCE_TYPE_STUDENT_WORKSHEET

type ReferenceId = number | string

export type ConstructedReference = `${ReferenceType}.${ReferenceId}`

type DeconstructedReference = {
  referenceType: ReferenceType
  referenceId: ReferenceId
}

export class LearningProcessReference {
  referenceType: ReferenceType
  referenceId: ReferenceId

  constructor({
    referenceType,
    referenceId,
  }: {
    referenceType: ReferenceType
    referenceId: ReferenceId
  }) {
    this.referenceType = referenceType
    this.referenceId = referenceId
  }

  static contructReference({
    referenceType,
    referenceId,
  }: {
    referenceType: ReferenceType
    referenceId: ReferenceId
  }) {
    return [referenceType, referenceId].join('.') as ConstructedReference
  }

  static decontructReference(reference: string) {
    const [referenceType, referenceId] = reference.split('.')
    return { referenceType, referenceId } as DeconstructedReference
  }

  get constructedReference() {
    return LearningProcessReference.contructReference(this)
  }
}

export const useLearningProcessReference = () => {
  const [searchParams] = useSearchParams()
  const referenceParam = searchParams.get('reference')

  if (referenceParam) {
    return LearningProcessReference.decontructReference(referenceParam)
  }
}
