export const STUDENT_WORKSHEET_STATUS_FILTER_OPTIONS = [
  {
    label: '전체',
    value: 'ALL',
  },
  {
    label: '학습가능',
    value: 'INCOMPLETE',
  },
  {
    label: '풀이중',
    value: 'PROGRESS',
  },
  {
    label: '학습완료',
    value: 'COMPLETE',
  },
]
