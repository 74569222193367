import styled from '@emotion/styled'

import { colors, fontWeight, textEllipsis, typo } from '~/@common/styles'
import { mediaQuery } from '~/@common/styles/mediaQuery'

const S = {
  StudentWorkList: styled.div`
    display: flex;
    height: 100%;
    overflow: hidden;
    .student-workbook-list {
      width: 100%;
      overflow: hidden;
    }
    .student-workbook-list-row {
      padding: 14px;
      min-height: 65px;
      cursor: pointer;
    }
    .col1 {
      display: flex;
      justify-content: flex-end;
      align-items: center;
    }
    .col2,
    .col4 {
      display: flex;
      justify-content: center;
      align-items: center;
      color: ${colors.gray.$600};
    }
    .col3 {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      padding-right: 112px;
      padding-left: 20px;
      gap: 20px;
    }
    .date {
      color: ${colors.gray.$900};
    }
    .image-wrapper {
      flex: 0 0 60px;
      height: 80px;
      background-color: rgba(0, 0, 0, 0.1);
      border-radius: 10px;
    }
    img {
      width: 100%;
      max-width: 97px;
      height: 100%;
      border-radius: 10px;
    }
    .text-wrapper {
      width: 100%;
      ${textEllipsis(1)};
      height: 21px;
    }
    .title-wrapper {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 2px;
    }
    .title {
      display: flex;
      align-items: center;
    }
    .sub-title {
      ${typo.caption01};
      color: ${colors.gray.$700};
    }
    .progress-bar {
      width: 176px;
    }
    ${mediaQuery.underTablet} {
      .student-workbook-list {
        overflow: auto;
        display: flex;
        flex-direction: column;
        gap: 8px;
        padding-bottom: 40px;
      }
      .student-workbook-main-row-wrapper {
        flex-shrink: 0;
      }
      .student-workbook-main-row {
        cursor: pointer;
        min-height: 97px;
        display: flex;
        align-items: center;
        flex-shrink: 0;
      }
      .student-workbook-main-row-info-head {
        display: flex;
        align-items: center;
        ${typo.caption01}
      }
      .student-workbook-main-row-info {
        flex: 1;
        padding: 14px 20px;
      }
      .student-workbook-sub-row {
        padding: 20px;
      }
      .student-workbook-sub-row-info {
        display: flex;
        align-items: center;
        gap: 10px;
        margin-top: 4px;
      }
      .title-wrapper {
        padding: 6px 0;
        ${typo.body02};
        color: ${colors.gray.$900};
      }

      .image-wrapper {
        flex: 0 0 73px;
        height: 97px;
        margin-right: -8px;
        background-color: rgba(0, 0, 0, 0.1);
        border-radius: 10px;
      }
      img {
        border-radius: 0;
      }
      .workbook-round {
        flex-shrink: 0;
        ${typo.caption01};
        font-weight: ${fontWeight.bold};
        &.primary {
          color: ${colors.blue.$500};
        }
      }
      .workbook-type {
        padding: 0 10px 0 4px;
        color: ${colors.blue.$500};
        font-weight: ${fontWeight.bold};
      }
      .date {
        color: ${colors.gray.$700};
      }
      .progress-bar {
        flex-direction: row-reverse;
        width: 100%;
        .progress-text {
          ${typo.caption01};
          color: ${colors.gray.$700};
          font-weight: ${fontWeight.bold};
        }
      }
    }
  `,
  NoData: styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    width: 100%;
    height: 100%;
    .purple-icon {
      display: flex;
      justify-content: center;
      align-items: center;

      width: 50px;
      height: 50px;
      border-radius: 50%;
      background-color: rgba(57, 95, 226, 0.5);
    }
    .guide-text {
      text-align: center;
      margin-top: 15px;
    }
    p {
      ${typo.body02};
      color: ${colors.gray.$800};
    }
  `,
}

export default S
