import { action, computed, makeObservable, observable } from 'mobx'

import { studentHomeApi } from '../@common/api'
import RecentChallenge from '../@common/model/RecentChallenge'

// const delay = () => new Promise((resolve) => setTimeout(resolve, 3000))

export default class RecentChallengeService {
  private _recentChallenges?: RecentChallenge[]
  private _recentChallengeIndex?: number

  constructor() {
    makeObservable<this, '_recentChallenges' | '_recentChallengeIndex'>(this, {
      _recentChallenges: observable.ref,
      _recentChallengeIndex: observable,
      recentChallenge: computed,
      isReady: computed,
      setRecentChallengeIndex: action,
      setRecentChallenges: action,
    })
  }

  get recentChallenges() {
    return this._recentChallenges
  }

  get recentChallengeIndex() {
    return this._recentChallengeIndex
  }

  get recentChallenge() {
    if (this._recentChallenges && this._recentChallengeIndex !== undefined) {
      return this._recentChallenges[this._recentChallengeIndex]
    }
    return
  }

  get isReady() {
    return this._recentChallenges !== undefined
  }

  async loadRecentChallenges() {
    const data = await studentHomeApi.fetchRecentChallenges()
    if (!data) {
      this.setRecentChallenges([])
      this.setRecentChallengeIndex(undefined)
      return
    }

    let challengeIndex: number | undefined = data.contents.findIndex(
      (item) => item.littleChapterId === data.default.littleChapterId,
    )
    if (challengeIndex === -1) {
      challengeIndex = data.contents.length ? 0 : undefined
    }
    this.setRecentChallengeIndex(challengeIndex)
    this.setRecentChallenges(data.contents.map((dto) => new RecentChallenge(dto)))
  }

  setRecentChallenges(data: RecentChallenge[]) {
    this._recentChallenges = data
  }

  setRecentChallengeIndex(value: number | undefined) {
    this._recentChallengeIndex = value
  }
}
