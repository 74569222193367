import clsx from 'clsx'
import type { DataHTMLAttributes } from 'react'

import { colors } from '~/@common/styles'

import { Icon } from '../Icon/Icon'
import { S } from './GroupArrow.style'

type Props = {
  isOpen?: boolean
  startDeg?: number
  endDeg?: number
  size?: number
  isAnimate?: boolean
  color?: string
  openedColor?: string
} & DataHTMLAttributes<HTMLDivElement>

const GroupArrow = ({
  isOpen,
  startDeg = 90,
  endDeg = 180,
  size = 24,
  isAnimate = true,
  color = colors.gray.$600,
  openedColor = colors.gray.$500,
  ...props
}: Props) => {
  return (
    <S.GroupArrow._IconWrap {...props}>
      <Icon
        name="icon_dropdown_arrow_up"
        className={clsx(isAnimate && 'is-animate', `degree-${isOpen ? endDeg : startDeg}`)}
        css={S.GroupArrow.Icon}
        color={isOpen ? color : openedColor}
        size={size}
        rotate={isOpen ? 0 : 180}
      />
    </S.GroupArrow._IconWrap>
  )
}

export default GroupArrow
