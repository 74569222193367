import type { MathflatApi } from '@mathflat/domain/@entities/Student/api'
import type { StudentDomain } from '@mathflat/domain/@entities/Student/domain.ts'
import type { Entity } from '@mathflat/domain/@entities/Student/dto'

import maxios from '../utils/maxios'

export const studentApi = {
  getStudentAppSettings: async (studentId: StudentDomain.Id) => {
    const res = await maxios.get<MathflatApi.Response.StudentAppSetting>(
      `/students/app-setting/${studentId}`,
    )
    return {
      일반채점_채점전정답공개: res.data.showSolutionBeforeScoring,
      채점후정답해설공개: res.data.showSolutionAfterScoring,
      일반채점_채점전문풀동공개: res.data.showProblemVideoBeforeScoring,
      채점후문풀동공개: res.data.showProblemVideoAfterScoring,
      studentId: res.data.studentId,
      lectureService: res.data.lectureService,
      개념힌트_사용: res.data.useAiForHintConceptual,
      풀이분석_사용: res.data.useAiForSolveTheStuckUp,
      풀이확인_사용: res.data.useAiForReviewTheSolution,
    }
  },

  getStudentById: (studentId: Entity.Student['id']) => {
    return maxios.get<MathflatApi.Response.Student>(`/students/${studentId}`)
  },

  updateLearningTime: async (minutes: number) => {
    await maxios.post<null>('/student-learning/time', null, {
      params: {
        minute: minutes,
      },
    })
  },
}
