import { type PropsWithChildren, useEffect, useState } from 'react'
import type SwiperCore from 'swiper'

import { SwiperControllerProvider } from './SwiperController.context'

const SwiperProvider = ({ children }: PropsWithChildren) => {
  const [_swiper, _setSwiper] = useState<SwiperCore | null>(null)
  const [_activeIndex, setActiveIndex] = useState(-1)
  const [_isBeginingSlide, setIsBeginingSlide] = useState(false)
  const [_isEndSlide, setIsEndSlide] = useState(false)
  //   const [searchParams, setSearchParams] = useSearchParams()

  // const slideNext = (opt?: { useSearchParam?: { searchParamKey: string } }) => {
  //   if (_swiper) {
  //     _swiper?.slideNext()
  //     console.log(_swiper.activeIndex)
  //     // setActiveIndex(_swiper?.activeIndex)
  //     // TODO searchParam 동기화
  //     //   if (opt?.useSearchParam) {
  //     //     setSearchParams((prev) => {
  //     //       prev.set(opt.useSearchParam!.searchParamKey, `${_swiper.activeIndex}`)
  //     //       return prev
  //     //     })
  //     //   }
  //   }
  // }

  // const slidePrev = () => {
  //   if (_swiper) {
  //     _swiper?.slidePrev()
  //     // setActiveIndex(_swiper?.activeIndex)
  //   }
  // }
  useEffect(() => {
    const handleChangeIndex = (swiper) => {
      setActiveIndex(swiper.activeIndex)
      setIsBeginingSlide(swiper.isBeginning)
      setIsEndSlide(swiper.isEnd)
    }
    _swiper?.on('slideChange', handleChangeIndex)
    return () => {
      _swiper?.off('slideChange', handleChangeIndex)
    }
  }, [_swiper])

  // useEffect(() => {
  //   if (_swiper) {
  //     setActiveIndex(_swiper.activeIndex)
  //   }
  // }, [_swiper?.activeIndex])

  return (
    <SwiperControllerProvider
      control={_swiper}
      setSwiper={(swiper: SwiperCore) => {
        if (_swiper && !_swiper.destroyed) {
          _setSwiper(_swiper)
          setActiveIndex(_swiper.activeIndex)
          return
        }

        _setSwiper(swiper)
        setActiveIndex(swiper.activeIndex)

        const handleSwiperEvent = () => {
          setIsBeginingSlide(swiper.isBeginning)
          setIsEndSlide(swiper.isEnd)
        }

        // swiper instance 생성 후 snapIndexChange 또는 update event가 발생하는 시점에서
        // isBeginning, isEnd가 정확한 값으로 설정됨
        swiper.once('snapIndexChange', handleSwiperEvent)
        swiper.once('update', handleSwiperEvent)
      }}
      initialized={!!_swiper}
      // slideNext={slideNext}
      // slidePrev={slidePrev}
      activeIndex={_activeIndex}
      isBeginningSlide={_isBeginingSlide}
      isEndSlide={_isEndSlide}
    >
      {children}
    </SwiperControllerProvider>
  )
}

// TODO: initilized 값을 쓰기 위해 Swiper를 감싼 다음의 Swiper를 사용한다.
// const Swiper = () => {
//   const swiperController = useSwiperControllerContext(SwiperControllerConsumerName)
//   useEffect(() => {
//     return () => {
//       swiperController.destroy()
//     }
//   }, [])
//   // return <Swiper
//   //         modules={[Navigation, Controller]}
//   //         slidesPerView={studentWorksheetScoring.problemScoringColl.toArr.length <= 2 ? 1 : 'auto'}
//   //         spaceBetween={100}
//   //         ></Swiper>
// }

export default SwiperProvider
