import type { ComponentProps } from 'react'

import type { IconNames } from '~/@common/ui/Icon/iconNames.type.ts'

export type Props = {
  name: IconNames
  size?: number
  rotate?: number
} & ComponentProps<'svg'>

export const kind = 'ms__Icon'

export const Icon = ({ name, size = 16, rotate = 0, style, ...props }: Props) => {
  return (
    <svg
      data-component={kind}
      data-track={name}
      width={size}
      height={size}
      style={{ flexShrink: 0, transform: `rotate(${rotate}deg)`, ...style }}
      {...props}
    >
      <use xlinkHref={`/sprite.svg#${name}`} />;
    </svg>
  )
}
