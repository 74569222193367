import type { Entity } from '@mathflat/domain/@entities/Lecture/dto'
import type { StudentDomain } from '@mathflat/domain/@entities/Student/domain.ts'
import { forEach, merge } from 'lodash'

import type { ProblemScoring } from '~/@pages/@common/(ProblemScoring)/@trait/ProblemScoring.trait'

import type { PlayerTime } from '../ui/MathflatPlayer/MathflatPlayer'
import { cryptoUtils } from '../utils/cryptoUtils'

type DeepPartial<T> = {
  [P in keyof T]?: DeepPartial<T[P]>
}

type ScoringData<T extends 'WORKSHEET' | 'WORKBOOK' = 'WORKSHEET' | 'WORKBOOK'> = {
  scoringId: ProblemScoring<T>['id']
  localAnswer: ProblemScoring<T>['_localAutoScoredAnswer'] | ProblemScoring<T>['_localScoring']
}

interface StorageService {
  update(target: Omit<DeepPartial<this>, 'update'>): void
  clear(): void
}

type VideoDataKey = 'lecture' | 'problem-solving-video'
type VideoDataValue = Pick<PlayerTime, 'current'>
// type VideoDataValue = Pick<PlayerConfig, 'resolutionInfo' | 'playbackRate'> & Pick<PlayerTime, 'current'>

export class LocalStorageService implements StorageService {
  user = {
    studentId: localStorage.getItem('studentId') as StudentDomain.Id | null,
    parentId: localStorage.getItem('parentId'),
    beforeLoginId: localStorage.getItem('beforeLoginId'),
    academyId: localStorage.getItem('academyId'),
    worksheetId: localStorage.getItem('worksheetId'),
    dateTime: localStorage.getItem('dateTime'),
    _isInitialPassword: localStorage.getItem('isInitialPassword'),
  } as const

  // 현재 기기에 한번이라도 로그인했던 유저Id Set([]) : 사용 가이드 유도 팝업을 위함
  userIdsOnDevice = localStorage.getItem('userIdsOnDevice')
    ? new Set(JSON.parse(localStorage.getItem('userIdsOnDevice')!))
    : new Set([])

  scoring = localStorage.getItem('scoring')
    ? new Map<
        ProblemScoring<'WORKSHEET' | 'WORKBOOK'>['id'],
        | ProblemScoring<'WORKSHEET' | 'WORKBOOK'>['_localAutoScoredAnswer']
        | ProblemScoring<'WORKSHEET' | 'WORKBOOK'>['_localScoring']
      >(JSON.parse(localStorage.getItem('scoring')!))
    : new Map<
        ProblemScoring<'WORKSHEET' | 'WORKBOOK'>['id'],
        | ProblemScoring<'WORKSHEET' | 'WORKBOOK'>['_localAutoScoredAnswer']
        | ProblemScoring<'WORKSHEET' | 'WORKBOOK'>['_localScoring']
      >()

  // auth = {
  //   token: localStorage.getItem('token'),
  // } as const

  // userIdsOnDevice : 현재 기기에 처음으로 로그인 한 기기인지 확인

  isFirstUserOnDevice() {
    // 현재 사용자의 id와 스토리지의 사용자id 중 일치하는게 있는지 확인
    const base64UserId = cryptoUtils.encodeBase64(this.user.studentId as string)
    return !this.userIdsOnDevice.has(base64UserId)
  }
  // userIdsOnDevice : 동영상 유도팝업이 다시보여지지 않기 위해 storage에 현재사용자의 id를 set
  setUserIdsOnDevice() {
    const userIds = this.userIdsOnDevice.add(
      cryptoUtils.encodeBase64(this.user.studentId as string),
    )
    this.userIdsOnDevice = userIds
    localStorage.setItem('userIdsOnDevice', JSON.stringify([...userIds]))
  }
  private _videoData: Record<VideoDataKey, Map<Entity.Lecture['id'], VideoDataValue>> = {
    lecture: localStorage.getItem('VIDEO_INFO-lecture')
      ? new Map(JSON.parse(localStorage.getItem('VIDEO_INFO-lecture')!))
      : new Map(),
    'problem-solving-video': localStorage.getItem('VIDEO_INFO-problem-solving-video')
      ? new Map(JSON.parse(localStorage.getItem('VIDEO_INFO-problem-solving-video')!))
      : new Map(),
  }

  get videoData() {
    return this._videoData
  }

  setVideoData(key: VideoDataKey, videoId: Entity.Lecture['id'], value: VideoDataValue) {
    this._videoData[key].set(videoId, value)
    localStorage.setItem(`VIDEO_INFO-${key}`, JSON.stringify([...this._videoData[key].entries()]))
  }

  removeVideoData(key: VideoDataKey, videoId: Entity.Lecture['id']) {
    this._videoData[key].delete(videoId)
    localStorage.setItem(`VIDEO_INFO-${key}`, JSON.stringify([...this._videoData[key].entries()]))
  }

  updateInitialData = {
    qr: localStorage.getItem('qr') ?? 'FALSE',
  }

  device = {
    ver: localStorage.getItem('ver'),
  }

  lecture = {
    lectureId: localStorage.getItem('lectureId'),
  }

  signatureWorkbookQR = {
    curriculumKey: localStorage.getItem('curriculumKey'),
    signatureType: localStorage.getItem('signatureType'),
  }

  learningProcess = {
    isVisitedConceptLearning: localStorage.getItem('isVisitedConceptLearning') || 'FALSE',
  }

  update(target: Omit<DeepPartial<this>, 'update'>) {
    merge(this, target)
    forEach(target, (value, key) => {
      forEach(value, (_value, _key) => {
        localStorage.setItem(_key, _value)
      })
    })
  }

  clear() {
    // 로컬스토리지를 clear해도 남아있어야하는 스토리지 keys
    const PERSIST_KEYS = ['noticePopupExpiresAt', 'userIdsOnDevice']

    const persistItems = Object.entries(localStorage)
      .filter(([key]) => PERSIST_KEYS.some((epkKey) => key.includes(epkKey)))
      .map(([key, value]) => [key, value])

    localStorage.clear()
    persistItems.forEach(([key, value]) => {
      localStorage.setItem(key, value)
    })

    localStorage.setItem('qr', this.updateInitialData.qr)
    this.user = {
      studentId: null,
      parentId: null,
      beforeLoginId: null,
      academyId: null,
      worksheetId: null,
      dateTime: null,
      _isInitialPassword: null,
    }
    // this.auth = { token: null }
    this.device = { ver: null }
    this.scoring = new Map<
      ProblemScoring<'WORKSHEET' | 'WORKBOOK'>['id'],
      | ProblemScoring<'WORKSHEET' | 'WORKBOOK'>['_localAutoScoredAnswer']
      | ProblemScoring<'WORKSHEET' | 'WORKBOOK'>['_localScoring']
    >()
  }

  get isInitialPassword() {
    return this.user._isInitialPassword === 'true'
  }

  isInitialPasswordClear() {
    localStorage.removeItem('isInitialPassword')
    this.user = {
      ...this.user,
      _isInitialPassword: null,
    }
  }

  qrDataClear() {
    localStorage.removeItem('worksheetId')
    localStorage.removeItem('dateTime')
    this.user = {
      ...this.user,
      worksheetId: null,
      dateTime: null,
    }
  }

  lectureDataClear() {
    localStorage.removeItem('lectureId')
    this.lecture = {
      lectureId: null,
    }
  }

  signatureWorkbookQRDataClear() {
    localStorage.removeItem('curriculumKey')
    localStorage.removeItem('signatureType')
    this.signatureWorkbookQR = {
      curriculumKey: null,
      signatureType: null,
    }
  }

  setScoringData<T extends 'WORKSHEET' | 'WORKBOOK'>(params: ScoringData<T>) {
    this.scoring.set(params.scoringId, params.localAnswer)
    localStorage.setItem('scoring', JSON.stringify([...this.scoring.entries()]))
  }

  removeScoringData<T extends 'WORKSHEET' | 'WORKBOOK'>(params: Pick<ScoringData<T>, 'scoringId'>) {
    this.scoring.delete(params.scoringId)
    localStorage.setItem('scoring', JSON.stringify([...this.scoring.entries()]))
  }
}

// class SessionStorageService implements StorageService {
//   auth = {
//     token: sessionStorage.getItem('token'),
//   } as const

//   update(target: Omit<DeepPartial<this>, 'update'>) {
//     merge(this, target)
//     forEach(target, (value, key) => {
//       forEach(value, (_value, _key) => {
//         sessionStorage.setItem(_key, _value)
//       })
//     })
//   }

//   clear() {
//     sessionStorage.clear()
//     this.auth = { token: null }
//   }
// }

class SessionStorageService {
  get keywords() {
    return { MAAT응시팝업_미노출여부: 'MAAT응시팝업_미노출여부' }
  }

  clearOnAppReload() {
    sessionStorage.removeItem(this.keywords.MAAT응시팝업_미노출여부)
  }
}

export const localStorageService = new LocalStorageService()
export const sessionStorageService = new SessionStorageService()
