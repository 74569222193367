import type { HandwrittenNoteControllerService } from '@mathflat/handwritten-note'
import { useDraggable } from '@mathflat/handwritten-note'
import { observer } from 'mobx-react'
import type { FC } from 'react'

import ImageViewerToolbar from '../../student-workbook/viewer/@widgets/image-viewer/image-viewer-toolbar/ImageViewerToolbar.tsx'

interface Props {
  containerEl: HTMLDivElement
  containerPadding: number
  right?: number
  top?: number
  controllerService: HandwrittenNoteControllerService
}

const HandwrittenNoteMinimalToolbar: FC<Props> = ({
  containerEl,
  containerPadding,
  right,
  top,
  controllerService,
}) => {
  const { isDragging, dragDelta, draggableRef } = useDraggable({
    containerEl,
    containerPadding,
  })

  return (
    <div
      className="ms__HandwrittenNoteMinimalToolbar"
      ref={draggableRef}
      style={{
        position: 'absolute',
        right: `${dragDelta.x ? 0 : right ?? containerPadding}px`,
        top: `${dragDelta.y ? 0 : top ?? containerPadding}px`,
        transform: `translate(${dragDelta.x}px, ${dragDelta.y}px)`,
        touchAction: 'none',
      }}
    >
      <ImageViewerToolbar
        isDragging={isDragging}
        isWritingModeOnly={true}
        controllerService={controllerService}
      />
    </div>
  )
}

export default observer(HandwrittenNoteMinimalToolbar)
