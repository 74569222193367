import { useEffect, useState } from 'react'

import { webviewService } from '../services'
import { localStorageService } from '../services/storage.service'

const useQRScan = () => {
  const [initialQrUpdate, setInitialQrUpdate] = useState('FALSE')

  const isAppUpdated = initialQrUpdate === 'TRUE'

  useEffect(() => {
    if (!localStorageService.updateInitialData.qr) {
      localStorageService.update({
        updateInitialData: { qr: 'TRUE' },
      })
    }
    setInitialQrUpdate(localStorageService.updateInitialData.qr)
  }, [])

  const turnOffQrGuide = () => {
    localStorageService.update({ updateInitialData: { qr: 'FALSE' } })
    setInitialQrUpdate(localStorageService.updateInitialData.qr)
  }

  const performQrScan = () => {
    window.localStorage.setItem('no-refresh', 'true')
    webviewService.scanQRCode()
    turnOffQrGuide()
  }

  return { isAppUpdated, performQrScan, turnOffQrGuide }
}

export default useQRScan
