import { colors } from '~/@common/styles'
import IconButton from '~/@common/ui/(Button)/IconButton/IconButton'

interface Props {
  onClick: () => void
}

export const ClearSelectedFiltersIcon = ({ onClick }: Props) => (
  <IconButton
    name="icon_reset"
    gap="0.5px"
    iconSize={20}
    iconStyle={{ color: colors.gray.$500 }}
    RightSlot={
      <span
        style={{
          color: colors.gray.$600,
        }}
      >
        전체 초기화
      </span>
    }
    onClick={onClick}
  />
)
