import { ONE_DAY } from '@mathflat/shared/@common/constants/date.ts'
import type { CalenderDateStr } from '@mathflat/shared/@common/types/string.ts'
import { CalendarDate } from '@mathflat/shared/@modules/(dto)/CalendarDate.ts'
import { format, isSameDay, isToday, isWithinInterval, lastDayOfMonth } from 'date-fns'
import { nanoid } from 'nanoid'
import { useMemo, useState } from 'react'

import { colors } from '~/@common/styles'
import type { StyleProps } from '~/@common/types/props'
import IconButton from '~/@common/ui/(Button)/IconButton/IconButton.tsx'
import _internal from '~/@common/ui/Calendar/_internal.ts'

import _css from './Calendar.css.ts'

export type CalendarDateRange = { start: CalendarDate | null; end: CalendarDate | null }

export const datePickerType = 'pick' as const
export const dateRangePickerType = 'range' as const

interface PickerProps {
  onPreviousClick?: (startDateOfMonth: CalendarDate) => void
  onNextClick?: (startDateOfMonth: CalendarDate) => void
  specialDates?: Iterable<CalenderDateStr>
}

export interface DatePickerProps extends PickerProps, StyleProps {
  type: typeof datePickerType
  onDateSelect: (date: CalendarDate) => void
  selectedDate: CalendarDate
}

export interface DateRangePickerProps extends PickerProps, StyleProps {
  type: typeof dateRangePickerType
  onRangeSelect: (selectedRange: CalendarDateRange) => void
  selectedRange: CalendarDateRange
}

export type Props = DatePickerProps | DateRangePickerProps

const DAYS_IN_FIVE_WEEKS = 7 * 5

export const { parts, kind } = _internal

// TODO: 추후 Calendar하고 DatePicker 분리
export default function Calendar(props: Props) {
  const specialDateSet = useMemo(
    () => (props.specialDates ? new Set([...props.specialDates]) : null),
    [props.specialDates],
  )
  const [calendarDate, setCalendarDate] = useState(() => {
    return props.type === datePickerType
      ? props.selectedDate
      : props.selectedRange.end !== null
        ? props.selectedRange.end
        : new CalendarDate()
  })

  const renderDateArr = useMemo(() => {
    const prevMonthLastDate = lastDayOfMonth(calendarDate.getPrevMonth().dateObj)
    const currentMonthLastDate = lastDayOfMonth(calendarDate.dateObj)

    const dateArr: { date: Date; isDateOfCurrentMonth: boolean }[] = []

    /** 지난 달 추가 **/

    // 지난 달 마지막날 요일
    const lastWeekDayOfPrevMonth = prevMonthLastDate.getDay()
    const timeOfPrevMonth = prevMonthLastDate.getTime()

    for (let i = lastWeekDayOfPrevMonth; i > 0; i--) {
      dateArr.push({
        date: new Date(timeOfPrevMonth - ONE_DAY * i),
        isDateOfCurrentMonth: false,
      })
    }

    /** 현재 달 추가 **/
    // 현재 달 마지막 날 일을 구함
    const dayOfCurrentMonth = currentMonthLastDate.getDate()

    for (let i = 1; i <= dayOfCurrentMonth; i++) {
      dateArr.push({
        date: new Date(timeOfPrevMonth + ONE_DAY * i),
        isDateOfCurrentMonth: true,
      })
    }

    /** 다음 달 추가 **/

    // 현재 달의 마지막 날 시간 구함
    const dateTimeOfCurrentMonth = currentMonthLastDate.getTime()

    // 달력에 마지막이 어디까지 채워졌는지 (0이면 끝까지 채워짐)
    const lastWeekDay = dateArr.length % 7

    // 끝까지 안채워졌다면 채울 일 수
    const addDays = 7 - (lastWeekDay % 7)

    // 총 채워진 것이 5주가 아닐시 추가로 7일 더 채워줌
    const additionalDays = dateArr.length + addDays <= DAYS_IN_FIVE_WEEKS ? 7 : 0

    for (let i = 1; i <= addDays + additionalDays; i++) {
      dateArr.push({
        date: new Date(dateTimeOfCurrentMonth + ONE_DAY * i),
        isDateOfCurrentMonth: false,
      })
    }

    return dateArr
  }, [calendarDate])

  const isSpecialDay = (calendarDateStr: CalenderDateStr) => {
    if (!specialDateSet) return false

    return specialDateSet.has(calendarDateStr)
  }

  const isInRange = (date: Date) => {
    if (props.type !== dateRangePickerType) return false
    const { start, end } = props.selectedRange
    if (start === null || end === null) return false

    return isWithinInterval(date, {
      start: start.dateObj,
      end: end.dateObj,
    })
  }

  const onClickDay = (calendarDate: CalendarDate) => {
    // DatePicker
    if (props.type === datePickerType) {
      props.onDateSelect(calendarDate)
      return
    }

    // RangePicker
    const { start, end } = props.selectedRange

    // Range 선택되어 있는 것이 없는 경우
    if (start === null && end === null) {
      return props.onRangeSelect({
        start: calendarDate,
        end: null,
      })
    }

    // 시작 날짜만 있는 경우
    if (start && end === null) {
      if (calendarDate.dateObj < start.dateObj) {
        return props.onRangeSelect({
          start: calendarDate,
          end: start,
        })
      }
      return props.onRangeSelect({ start, end: calendarDate })
    }

    // 둘 다 있을 경우
    return props.onRangeSelect({ start: calendarDate, end: null })
  }

  return (
    <div
      css={_css}
      className={props.className}
      style={props.style}
      onClick={(e) => e.stopPropagation()}
      data-component={kind}
    >
      <div className={parts.header}>
        <IconButton
          name="icon_chevron_left"
          iconSize={24}
          style={{ color: colors.gray.$500 }}
          size="small"
          onClick={(e) => {
            e.stopPropagation()
            const prevCalendarDate = calendarDate.getPrevMonth()
            setCalendarDate(prevCalendarDate)
            props.onPreviousClick?.(prevCalendarDate)
          }}
          aria-label="previous"
        />
        {calendarDate.year}년 {calendarDate.month}월
        <IconButton
          name="icon_chevron_right"
          iconSize={24}
          style={{ color: colors.gray.$500 }}
          size="small"
          onClick={(e) => {
            e.stopPropagation()
            const nextCalendarDate = calendarDate.getNextMonth()
            setCalendarDate(nextCalendarDate)
            props.onNextClick?.(nextCalendarDate)
          }}
          aria-label="next"
        />
      </div>
      <div className={parts.weekDays}>
        <div>일</div>
        <div>월</div>
        <div>화</div>
        <div>수</div>
        <div>목</div>
        <div>금</div>
        <div>토</div>
      </div>
      <div className={parts.dateGrid}>
        {renderDateArr.map(({ date, isDateOfCurrentMonth }) => (
          <button
            key={nanoid()}
            className={parts.date}
            data-outside={!isDateOfCurrentMonth}
            onClick={(e) => {
              e.stopPropagation()
              onClickDay(new CalendarDate(date))
            }}
            data-selected={
              props.type === datePickerType
                ? isSameDay(date, props.selectedDate.dateObj)
                : (props.selectedRange.start &&
                    isSameDay(date, props.selectedRange.start.dateObj)) ||
                  (props.selectedRange.end && isSameDay(date, props.selectedRange.end.dateObj))
            }
            data-in-range={isInRange(date)}
            data-today={isToday(date)}
            data-special={isSpecialDay(format(date, 'yyyy-MM-dd') as CalenderDateStr)}
          >
            {date.getDate()}
          </button>
        ))}
      </div>
    </div>
  )
}
