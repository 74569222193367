type ReqPostMessageKeyT = 'PAGE_RENDERED_AFTER_LOGIN'
type ResPostMessageKeyT = 'LOG_OUT'

type CustomRecord<T extends ReqPostMessageKeyT | ResPostMessageKeyT> = Record<
  T,
  { type: T; [key: string]: any }
>

export const ReqPostMessageRecord: CustomRecord<ReqPostMessageKeyT> = {
  PAGE_RENDERED_AFTER_LOGIN: {
    type: 'PAGE_RENDERED_AFTER_LOGIN',
  },
}

export const ResPostMessageRecord: CustomRecord<ResPostMessageKeyT> = {
  LOG_OUT: {
    type: 'LOG_OUT',
  },
}
