import { css } from '@emotion/react'
import { observer } from 'mobx-react'
import type { FC } from 'react'

import { useRepository } from '~/@common/hooks/useRepository'
import { colors } from '~/@common/styles'

import { StudentWorkbookViewerService } from '../@service/StudentWorkbookViewer.service'
import ImageViewerToolbarButton from './image-viewer/image-viewer-toolbar/ImageViewerToolbarButton'
import type { ImageViewerToolbarPagingProps } from './image-viewer/image-viewer-toolbar/ImageViewerToolbarPaging'
import ImageViewerToolbarPaging from './image-viewer/image-viewer-toolbar/ImageViewerToolbarPaging'

interface Props {
  pagingProps?: ImageViewerToolbarPagingProps
  isDefaultNoteScale: boolean
  onResetNoteScale: () => void
}

const StudentWorkbookViewerMobileToolbar: FC<Props> = ({
  pagingProps,
  isDefaultNoteScale,
  onResetNoteScale,
}) => {
  const viewerService = useRepository(StudentWorkbookViewerService)
  const { isScoringMode } = viewerService

  return (
    <div css={_css}>
      <div className="paging">{pagingProps && <ImageViewerToolbarPaging {...pagingProps} />}</div>
      <div className="toolbar">
        <div className="item">
          {viewerService.fitMode === 'fit-width' ? (
            <ImageViewerToolbarButton
              iconName="icon_screen_fit"
              iconSize={24}
              size="lg"
              label="길이맞춤"
              onClick={() => viewerService.setFitMode('fit-height')}
            />
          ) : (
            <ImageViewerToolbarButton
              iconName="icon_width_fit"
              iconSize={24}
              size="lg"
              label="너비맞춤"
              onClick={() => viewerService.setFitMode('fit-width')}
            />
          )}
        </div>
        <div className="bar"></div>
        <div className="item">
          <ImageViewerToolbarButton
            iconName="icon_reset-size"
            iconSize={24}
            label="비율초기화"
            onClick={onResetNoteScale}
            disabled={isDefaultNoteScale}
          />
        </div>
        <div className="bar"></div>
        <div className="item">
          <ImageViewerToolbarButton
            iconName="icon_list"
            iconSize={24}
            size="lg"
            label="답안입력"
            isOn={isScoringMode}
            onClick={() => viewerService.setIsScoringMode(!viewerService.isScoringMode)}
          />
        </div>
      </div>
    </div>
  )
}

export default observer(StudentWorkbookViewerMobileToolbar)

const _css = css`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: ${colors.gray.$100};

  .toolbar {
    display: grid;
    grid-template-columns: 1fr 1px 1fr 1px 1fr;
    padding-bottom: 14px;
    align-items: center;
    background-color: ${colors.white};

    .item {
      display: grid;
      justify-content: center;
    }
    .bar {
      width: 1px;
      height: 20px;
      background-color: ${colors.gray.$600};
    }
  }
`
