import { css } from '@emotion/react'
import clsx from 'clsx'
import { observer, useLocalObservable } from 'mobx-react'
import { parts as WorkbookSearchModalParts } from 'src/@pages/@widgets/WorkbookSearchModal/index.ts'

import { useStudentAppMediaQuery } from '~/@common/hooks/useMediaQuery'
import { useRepository } from '~/@common/hooks/useRepository.ts'
import { colors, fontWeight, typo } from '~/@common/styles'
import { mediaQuery } from '~/@common/styles/mediaQuery'
import type { WorkbookSearchModalOnSubmit } from '~/@pages/@widgets/WorkbookSearchModal'
import { WorkbookSearchService } from '~/@pages/@widgets/WorkbookSearchModal/WorkbookSearch.service.ts'
import { ClearSelectedFiltersIcon } from '~/@pages/student/challenge/@common/ClearSelectedWorkbookButton'

import WorkbookSearchApplyButton from './WorkbookSearchApplyButton'

type Props = {
  onSubmit: WorkbookSearchModalOnSubmit
}

const TOTAL_MAX = Object.values(WorkbookSearchService.MAX_SIZE_BY_WORKBOOK_TYPE).reduce(
  (prev, cur) => prev + cur,
  0,
)

export const kind = 'ms__WorkbookSearchModalFooter' as const

export const parts = {
  right: `${kind}_right`,
  left: `${kind}_left`,
}

const WorkbookSearchModalFooter = ({ onSubmit }: Props) => {
  const service = useRepository(WorkbookSearchService)
  const { isMobile } = useStudentAppMediaQuery()

  const _store = useLocalObservable(() => ({
    get totalCheckedWorkbookSize() {
      if (!service) return 0
      return service.checkedWorkbookToArray.reduce((prev, cur) => prev + cur.size, 0)
    },
  }))

  const handleWorkbookClear = () => {
    service.clear()
  }

  if (!service) return null

  return (
    <footer className={WorkbookSearchModalParts.footer} css={_css}>
      {!isMobile && (
        <>
          <div className={parts.left}>
            <ClearSelectedFiltersIcon onClick={handleWorkbookClear} />
          </div>
          <div className={parts.right}>
            {WorkbookSearchService.WORKBOOK_TYPES.map((workbookType) => {
              const checkedWorkbookSize = service.getCheckedWorkbookSizeBy(workbookType)
              const isCheckedMaxSize = service.checkIsMaxSizeReached(workbookType)

              return (
                <p className="workbook__detail-amount" key={workbookType}>
                  <span>{WorkbookSearchService.WORKBOOK_TYPE_KR[workbookType]}</span>
                  <span
                    className={clsx(
                      'number-for-workbook-type',
                      isCheckedMaxSize && 'highlight',
                      checkedWorkbookSize && 'selected',
                    )}
                  >
                    {checkedWorkbookSize}
                  </span>
                  <span>권</span>
                </p>
              )
            })}

            <p className="workbook__total-amount">
              총{' '}
              <span
                className={clsx(
                  _store.totalCheckedWorkbookSize >= TOTAL_MAX && 'highlight',
                  _store.totalCheckedWorkbookSize === 0 || 'selected',
                )}
              >
                {_store.totalCheckedWorkbookSize}
              </span>
              권
            </p>
          </div>
        </>
      )}
      <div className="apply">
        <WorkbookSearchApplyButton onSubmit={onSubmit} />
      </div>
      {isMobile && (
        <div className="reset">
          <ClearSelectedFiltersIcon onClick={handleWorkbookClear} />
        </div>
      )}
    </footer>
  )
}

export default observer(WorkbookSearchModalFooter)

const _css = css`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  border-top: 1px solid ${colors.gray.$200};
  padding: 14px 24px;

  ${mediaQuery.underTablet} {
    padding-bottom: 40px;
  }

  .${parts.left} {
    color: ${colors.gray.$500};
  }

  .${parts.right} {
    margin-left: auto;
    display: flex;
    gap: 22px;
    margin-right: 10px;

    .workbook__total-amount {
      ${typo.body01}
      color: ${colors.gray.$900};
    }

    .workbook__detail-amount {
      position: relative;
      color: ${colors.gray.$900};
      ${typo.body01};
      font-weight: 400;

      &:not(:last-of-type):after {
        content: '';
        display: flex;
        position: absolute;
        width: 2px;
        height: 2px;
        top: 50%;
        transform: translateY(-50%);
        right: -11px;
        border-radius: 50%;
        background: #d9d9d9;
      }

      .highlight {
        color: ${colors.blue.$500};
      }

      .selected {
        font-weight: ${fontWeight.bold};
      }

      .number-for-workbook-type {
        margin-left: 2px;

        :not(.selected) {
          color: ${colors.gray.$500};
        }
      }
    }
  }

  .apply > button {
    width: 180px;
  }

  ${mediaQuery.underTablet} {
    flex-direction: column;

    .apply {
      width: 100%;
    }

    .apply > button {
      display: block;
      width: 100%;
    }
  }

  .reset {
    margin-top: 20px;
  }
`
