import { useParams } from 'react-router'

import { handwrittenNoteApi, HandwrittenNoteType } from '~/@common/api/handwrittenNoteApi'
import { TOAST_STRING } from '~/@common/constants/strings'
import { useRepository } from '~/@common/hooks/useRepository'
import { errorHandlerService } from '~/@common/services'
import {
  IndexedDbService,
  type StudentWorksheetNoteValue,
} from '~/@common/services/indexedDb.service'
import type { ProblemScoring } from '~/@pages/@common/(ProblemScoring)/@trait/ProblemScoring.trait'
import { useLearningProcessReference } from '~/@pages/student/learning-process/@common/hooks/useGetLearningProcessReference'
import { alertWorksheetExceptionErrorModal } from '~/@pages/student/student-worksheet/$studentWorksheetId/scoring/alertWorksheetExceptionErrorModal'

import { WorksheetScoringService } from '../../@service/WorksheetScoring.service'

export const useSubmitWorksheetScoring = () => {
  const { worksheetId, dateTime } = useParams<{ worksheetId: string; dateTime: string }>()
  const service = useRepository(WorksheetScoringService)
  const indexedDbService = useRepository(IndexedDbService)
  const referenceParam = useLearningProcessReference()

  if (!worksheetId || !dateTime) return null

  const onSubmit = async () => {
    try {
      /**
       * API 호출 순서가 중요하므로 순서를 지켜야 합니다.
       * 1. 학습지를 학생에게 할당합니다.
       * 2. 학습지의 유효성을 체크합니다.
       * 3. 학습지의 문제를 제출합니다.
       */
      await service.assignWorksheetToStudent(worksheetId)
      await service.checkWorksheetValidation(worksheetId, dateTime)

      const mapStudentWorksheetIdByWorksheetId = (problem: ProblemScoring<'WORKSHEET'>) => ({
        studentWorksheetId: Number(worksheetId),
        worksheetProblemId: problem.id,
      })

      const mapWorksheetProblemsByStudentWorksheetId = (
        noteFromWorksheet: StudentWorksheetNoteValue,
      ) => ({
        ...noteFromWorksheet,
        id: service.validation?.studentWorksheetId
          ? +service.validation.studentWorksheetId // 학생애게 출제된 학습지: studentWorksheetId
          : noteFromWorksheet.studentWorksheetId, // 출제되지 않은 학습지: worksheetId
        subId: noteFromWorksheet.worksheetProblemId,
        studentWorksheetId: service.validation?.studentWorksheetId
          ? +service.validation.studentWorksheetId
          : noteFromWorksheet.studentWorksheetId,
      })

      const worksheetProblemsByWorksheetId =
        service.problemScoringColl?.toArr.map(mapStudentWorksheetIdByWorksheetId) || []

      const worksheetProblemsToBeScored =
        service?.problemScoringColl?.toScoredArr.map(mapStudentWorksheetIdByWorksheetId) || []

      const worksheetProblemsNotToBeScored =
        service.problemScoringColl?.toArr
          .filter(
            (problem) =>
              !worksheetProblemsToBeScored.find((s) => s.worksheetProblemId === problem.id),
          )
          .map(mapStudentWorksheetIdByWorksheetId) || []

      // 채점이 중요하므로 먼저 submit
      await service.submitScoringStudentWorksheetProblems(
        worksheetId,
        service.validation?.studentWorksheetId || '',
      )

      try {
        const noteDataItemsToBeSubmitted = await indexedDbService.getStudentWorksheetNotes(
          worksheetProblemsToBeScored,
        )
        const noteDataItemsNotToBeSubmitted = await indexedDbService.getStudentWorksheetNotes(
          worksheetProblemsNotToBeScored,
        )

        const studentWorksheetNoteDataItemsToBeSubmitted: StudentWorksheetNoteValue[] =
          noteDataItemsToBeSubmitted.map(mapWorksheetProblemsByStudentWorksheetId)
        const studentWorksheetNoteDataItemsNotToBeSubmitted: StudentWorksheetNoteValue[] =
          noteDataItemsNotToBeSubmitted.map(mapWorksheetProblemsByStudentWorksheetId)

        await handwrittenNoteApi.uploadStudentWorksheetNotes(
          studentWorksheetNoteDataItemsToBeSubmitted,
          HandwrittenNoteType.STUDENT_WORKSHEET_SCORING,
        )
        window.freshpaint?.track('필기 제출', { ...referenceParam })

        for (const noteFromWorksheet of studentWorksheetNoteDataItemsNotToBeSubmitted) {
          await indexedDbService.putStudentWorksheetNote(noteFromWorksheet)
        }

        await indexedDbService.deleteStudentWorksheetNotes(worksheetProblemsByWorksheetId)
      } catch (err) {
        // 필기 업로드 오류가 발생하면 toast 메시지 노출하고 throw 하지 않음
        errorHandlerService.handle(err, {
          message: TOAST_STRING.saveHandwrittenNoteFailed,
        })
      }
    } catch (error) {
      if (error instanceof Error) {
        // CASE: 학습지가 삭제되거나 수정된 경우
        if (error.message === 'WORKSHEET_ALREADY_DELETED') {
          alertWorksheetExceptionErrorModal()
          return
        }
      }
      // onSubmit 호출하는 곳에서 예외 처리하도록 throw
      throw error
    }
  }

  return {
    onSubmit,
  }
}
