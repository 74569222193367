import { useSearchParams } from 'react-router-dom'

import { useLastLocationManager } from './context'
import { Service } from './service'

export type Priority = 'route' | 'history'
/**
 * 뒤로가기에 해당하는 react-router Link에 들어갈 to 값을 생성<br/>
 * 우선순위 {@link Service}
 * 1. UrlSearchParams에서 Service.prevPathName에 해당하는 필드 값
 * 2. (priority="history" 일시) Service의 prevState에 해당하는 자체 히스토리 기억 값
 * 3. ".."  - react-router에서 상위 라우터를 칭함 {@link https://reactrouter.com/en/main/components/link#link}
 */
export const usePrevLinkTo = (priority: Priority = 'history') => {
  const lastLocationManager = useLastLocationManager()
  const [searchParams] = useSearchParams()

  const urlPrevPathName = searchParams.get(Service.prevPathName)
  if (urlPrevPathName) return urlPrevPathName

  if (priority === 'history') lastLocationManager.prevState?.location ?? '..'
  return '..'
}

export const useSetPrevSearchParams = () => {
  const [searchParams, setSearchParams] = useSearchParams()

  function setPrevSearchParams(value: string) {
    setSearchParams({ ...Object.fromEntries(searchParams), [Service.prevPathName]: value })
  }

  return setPrevSearchParams
}
