import { css } from '@emotion/react'
import { observer } from 'mobx-react'
import React from 'react'

import { useStudentAppMediaQuery } from '~/@common/hooks/useMediaQuery'
import modalService from '~/@common/services/modal.service.tsx'
import { colors, fontFamily, typo } from '~/@common/styles'
import { mediaQuery } from '~/@common/styles/mediaQuery'
import Button from '~/@common/ui/(Button)/Button/Button.tsx'
import IconButton from '~/@common/ui/(Button)/IconButton/IconButton.tsx'
import Modal from '~/@common/ui/modal/Modal.tsx'
import type WeeklyInfo from '~/@pages/student/student-home/@common/model/WeeklyInfo.ts'

type Props = {
  weeklyInfo: WeeklyInfo
}

const LastWeeklyInfoModal = ({ weeklyInfo }: Props) => {
  const { isMobile } = useStudentAppMediaQuery()

  const mobileContainerStyle: React.CSSProperties = {
    position: 'fixed',
    inset: 0,
    width: '100%',
    height: 'auto',
    paddingTop: 'env(safe-area-inset-top)',
    paddingBottom: 'env(safe-area-inset-bottom)',
  }

  return (
    <Modal.Confirm.Default
      style={{ width: '500px', padding: '0', ...(isMobile ? mobileContainerStyle : {}) }}
    >
      <div css={_css}>
        <div className="top">
          <header>
            <h3>지난 주의 학습 기록 결과</h3>
            <IconButton name="icon_close" iconSize={24} onClick={() => modalService.closeModal()} />
          </header>
          <div className="visual">
            <img src="/images/icons/icon_last_weekly_info.svg" width={500} height={177} />
          </div>
        </div>
        <div className="info-container">
          <WeeklyInfoSingleBox
            title="총학습시간(시간:분)"
            iconName="icon_timer_fill"
            value={weeklyInfo.totalLearningTimeText}
            type="learningTime"
          />
          <WeeklyInfoSingleBox
            title="푼 문제 수"
            iconName="icon_pen_fill"
            value={weeklyInfo.problemsSolvedCount}
            type="solvedCount"
          />
          <WeeklyInfoSingleBox
            title="등급이 올라간 유형"
            iconName="icon_plus_green"
            value={weeklyInfo.bestIncreasedGrade?.length}
            type="conceptChip"
          />
          <WeeklyInfoSingleBox
            title="최고등급을 달성한 유형"
            iconName="icon_star_fill"
            value={weeklyInfo.bestGradeAchieved?.length}
            type="conceptChip"
          />
        </div>
        <div className="button">
          <Button
            theme="primary"
            size="small"
            style={{ padding: '11px 22.5px' }}
            onClick={() => modalService.closeModal()}
          >
            이번주 학습하러 가기
          </Button>
        </div>
      </div>
    </Modal.Confirm.Default>
  )
}

export default observer(LastWeeklyInfoModal)

type WeeklyInfoSingleBoxProps = {
  title: string
  iconName: string
  value: string | number | undefined
  type: 'learningTime' | 'solvedCount' | 'conceptChip'
}

const WeeklyInfoSingleBox = ({ title, value, type, iconName }: WeeklyInfoSingleBoxProps) => {
  return (
    <div className="info-single">
      <img src={`/images/icons/${iconName}.svg`} width="32px" height="32px" alt="" />
      <dl className="info-content">
        <dt>{title}</dt>
        <dd>
          <strong>{value}</strong>
          {type === 'solvedCount' && <span>문제</span>}
          {type === 'conceptChip' && <span>개</span>}
        </dd>
      </dl>
    </div>
  )
}

const _css = css`
  display: flex;
  flex-direction: column;
  height: 100%;

  header {
    display: flex;
    justify-content: space-between;
    padding: 20px;
  }

  h3 {
    ${typo.body01};
    font-weight: 700;
    color: ${colors.gray.$900};
  }

  .top {
    height: 244px;
    background-color: hsla(0, 0%, 96%, 1);
    border-radius: 14px 14px 0 0;

    .visual {
      text-align: center;
    }
  }

  .info-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 36px;
    padding: 24px 48px 30px 48px;

    .info-single {
      display: flex;
      align-items: center;
      gap: 16px;
    }

    .info-content {
      display: flex;
      flex-direction: column;
      gap: 4px;
    }

    strong {
      ${fontFamily.montserrat}
      font-weight: 600;
      font-size: 24px;
      color: ${colors.black};
    }

    span {
      margin-left: 4px;
      ${typo.body02};
      color: ${colors.gray.$800};
    }
  }
  .button {
    display: flex;
    justify-content: center;
    padding-bottom: 20px;
  }

  ${mediaQuery.underTablet} {
    width: 100%;
    background-color: ${colors.white};

    header {
      padding-top: 14px;
      padding-bottom: 14px;
      display: flex;
      justify-content: center;
      position: relative;

      button {
        position: absolute;
        top: 14px;
        right: 24px;
      }
    }

    .top {
      height: auto;
      border-radius: 0;
      .visual {
        padding: 21px 0;
        img {
          max-width: 340px;
        }
      }
    }
    .info-container {
      grid-template-columns: 1fr;
      padding: 42px 30px;
      gap: 32px;

      .info-content {
        flex-direction: row;
        flex-grow: 1;

        dd {
          margin-left: auto;
        }
      }

      span {
        display: none;
      }
    }
    .button {
      margin-top: auto;
      padding-bottom: 40px;
      padding-left: 16px;
      padding-right: 16px;
      > button {
        width: 100%;
      }
    }
  }
`
