import { WorksheetDomain } from '@mathflat/domain/@entities'
import { CONTENT_STATUS } from '@mathflat/domain/@entities/(Content)/module'
import type {
  LearningContentStatus,
  MathflatApi,
  SelfLearningType,
  WorksheetI,
} from '@mathflat/domain/@entities/StudentWorksheet/api2'
import { format } from 'date-fns'
import _ from 'lodash'

import type { IconNames } from '~/@common/ui/Icon/iconNames.type'

import { Worksheet } from '../Worksheet'
import { StudentSelfLearningWorksheetList } from '.'
import { StudentSelfLearningWorksheetGroupList, type StudentWorksheetList } from '.'
import { StudentHomeworkWorksheetList } from './StudentHomeworkWorksheetList'

export class StudentCustomWorksheetList implements StudentWorksheetList {
  id: number
  studentHomeworkId: number | null
  assignDatetime: Date
  openDatetime: Date | null
  scoreDatetime: Date | null
  correctCount: number
  wrongCount: number
  score: number
  status: LearningContentStatus
  worksheet: WorksheetI
  homeworks: StudentHomeworkWorksheetList[]
  selfLearningStudentWorksheets: StudentSelfLearningWorksheetList[]

  constructor(studentWorksheet: MathflatApi.Response.StudentWorksheet) {
    this.id = studentWorksheet.id
    this.studentHomeworkId = studentWorksheet.studentHomeworkId
    this.assignDatetime = new Date(studentWorksheet.assignDatetime)
    this.openDatetime = studentWorksheet.openDatetime
      ? new Date(studentWorksheet.openDatetime)
      : null
    this.scoreDatetime = studentWorksheet.scoreDatetime
      ? new Date(studentWorksheet.scoreDatetime)
      : null
    this.correctCount = studentWorksheet.correctCount
    this.wrongCount = studentWorksheet.wrongCount
    this.score = studentWorksheet.score
    this.status = studentWorksheet.status
    this.worksheet = new Worksheet(studentWorksheet.worksheet)
    this.homeworks = studentWorksheet.homeworks.map(
      (homework) => new StudentHomeworkWorksheetList(homework),
    )
    this.selfLearningStudentWorksheets = studentWorksheet.selfLearningStudentWorksheets?.map(
      (selfLearning) => new StudentSelfLearningWorksheetList(selfLearning),
    )
  }

  checkIsSelfLearningCtaVisible() {
    if (
      this.status !== CONTENT_STATUS.전체채점 ||
      this.worksheet.accessModifierToStudent === 'PRIVATE'
    )
      return null

    const checkIsCtaDisabled = (selfLearningType: SelfLearningType) => {
      // 자식 학습지 중 자기주도 학습지 타입이 출제되어 있을 때 + 학습 완료가 아닌 상태
      if (
        this.selfLearningStudentWorksheets.filter(
          (selfLearning) =>
            selfLearning.worksheet.selfLearningType === selfLearningType &&
            selfLearning.getStatus() !== 'COMPLETE',
        ).length
      ) {
        return true
      }

      if (selfLearningType === WorksheetDomain.SELF_LEARNING_TYPE.오답유형학습) {
        // CASE_01. 오답학습 자기주도 완료 조건
        if (this.checkIsSelfLearningCtaVisible()?.isWrongTypeSelfLearningCompleted) return true

        if (this.getStatus() === 'COMPLETE' && this.getWrongCount() === 0) return true
      }

      if (selfLearningType === WorksheetDomain.SELF_LEARNING_TYPE.심화학습) {
        // CASE_01. 심화 학습지 자기주도 완료 조건
        if (this.checkIsSelfLearningCtaVisible()?.isLevelUpTypeSelfLearningCompleted) return true

        if (this.getStatus() === 'COMPLETE' && this.getCorrectCount() === 0) return true
      }

      return false
    }

    const isWrongTypeSelfLearningCompleted = (() => {
      if (this.getStatus() === 'COMPLETE' && this.getWrongCount() === 0) return false

      const hasNotCompletedSelfLearnings = !!this.selfLearningStudentWorksheets
        .filter(
          (self) =>
            self.worksheet.selfLearningType === WorksheetDomain.SELF_LEARNING_TYPE.오답유형학습,
        )
        .find((selfLearning) => selfLearning.getStatus() !== 'COMPLETE')

      if (hasNotCompletedSelfLearnings) return false

      if (
        this.getSelfLearning()
          ?.find(
            (selfLearning) =>
              selfLearning.labelSelfLearning.worksheet.selfLearningType ===
              WorksheetDomain.SELF_LEARNING_TYPE.오답유형학습,
          )
          ?.getWrongCount() === 0
      )
        return true

      return false
    })()

    const isLevelUpTypeSelfLearningCompleted = (() => {
      return (
        this.selfLearningStudentWorksheets
          .filter(
            (selfLearning) =>
              selfLearning.worksheet.selfLearningType ===
              WorksheetDomain.SELF_LEARNING_TYPE.심화학습,
          )
          .every((selfLearning) => selfLearning.status === 'COMPLETE') &&
        this.selfLearningStudentWorksheets
          .filter(
            (selfLearning) =>
              selfLearning.worksheet.selfLearningType ===
              WorksheetDomain.SELF_LEARNING_TYPE.심화학습,
          )
          .some((selfLearning) => selfLearning.wrongCount === 0)
      )
    })()

    return {
      checkIsCtaDisabled,
      isWrongTypeSelfLearningCompleted,
      isLevelUpTypeSelfLearningCompleted,
    }
  }

  getIconName(): IconNames {
    return 'icon_description_paper'
  }

  getLabels(): ('숙제' | '비공개' | '자동채점')[] {
    const tags: ('숙제' | '비공개' | '자동채점')[] = []

    if (this.worksheet.accessModifierToStudent === 'PRIVATE') tags.push('비공개')
    if (this.worksheet.autoScorable) tags.push('자동채점')
    if (this.homeworks.length) tags.push('숙제')

    return tags
  }

  getProblemCount(): number {
    return this.worksheet.problemCount
  }

  getScore(): number {
    return this.score
  }

  getWrongCount(): number {
    return this.wrongCount
  }

  getCorrectCount(): number {
    return this.correctCount
  }

  getTitle(): string {
    return this.worksheet.title
  }
  getFormattedAssignDatetime(): string {
    return format(this.assignDatetime, 'yy.MM.dd')
  }

  getAssignDatetime(): Date {
    return this.assignDatetime
  }

  getSelfLearning(): StudentSelfLearningWorksheetGroupList[] {
    const result = _.chain(this.selfLearningStudentWorksheets)
      .groupBy((self) => self.worksheet.selfLearningType + '-' + self.worksheet.titleTag)
      .map((content) => new StudentSelfLearningWorksheetGroupList(content))
      .value()

    return result
  }

  checkIsExam(): boolean {
    return false
  }

  getStatus(): LearningContentStatus {
    return this.status
  }

  checkIsHomework(): boolean {
    return this.homeworks.length > 0
  }

  getRoute(): string {
    if (this.status === CONTENT_STATUS.채점전) return `/student/student-worksheet/${this.id}`
    return `/student/student-worksheet/${this.id}/scoring`
  }
}
