import styled from '@emotion/styled'

import { colors } from '~/@common/styles'
import { Icon } from '~/@common/ui/Icon/Icon'

export const EmptyCaseIcon = () => {
  return (
    <EmptyCaseIconWrapper>
      <Icon name="icon_description_paper" size={24} color={colors.white} />
    </EmptyCaseIconWrapper>
  )
}

const NoDataBlue = '#395fe280' as const

const EmptyCaseIconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  background: ${NoDataBlue};
  border-radius: 50%;
`
