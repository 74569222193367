import { useEffect } from 'react'
import { useNavigate } from 'react-router'
import { useSearchParams } from 'react-router-dom'

import { routeName } from '~/@common/constants'

const QRPage = () => {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()

  const dateTime = searchParams.get('dateTime')
  const worksheetId = searchParams.get('worksheetId')

  useEffect(() => {
    if (worksheetId && dateTime) {
      navigate(
        {
          pathname: '/',
          search: `?worksheetId=${worksheetId}&dateTime=${dateTime}`,
        },
        {
          replace: true,
        },
      )

      return
    }

    navigate(routeName.student.defaultPath, { replace: true })
  }, [dateTime, worksheetId])

  return <div />
}

export default QRPage
