import { css } from '@emotion/react'

import { colors, colorTheme, typo } from '~/@common/styles'

import type { RadioGroupInputProps } from '../radio/group/RadioGroupInput'
import { radioGroupItemFactory } from '../radio/group/RadioGroupItem'

export const SubTabTextRadioGroupItem = (props: RadioGroupInputProps) =>
  radioGroupItemFactory(
    props,
    'SubTabTextRadioItem',
    css`
      label {
        cursor: pointer;
        ${typo.heading05};
        font-weight: bold;
        cursor: pointer;
        color: ${colors.gray.$700};
      }

      input:checked + label {
        color: ${colorTheme.primary};
      }
    `,
  )

export const SubTabButtonGroupItem = ({
  minWidth,
  ...props
}: RadioGroupInputProps & { minWidth?: number }) =>
  radioGroupItemFactory(
    props,
    'SubTabButtonRadioItem',
    css`
      label {
        cursor: pointer;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        min-width: ${minWidth ? `${minWidth}px` : '90px'};
        padding: 10px 16px;
        border-radius: 8px;
        background-color: white;
        ${typo.caption01};
      }

      input:checked + label {
        color: ${colors.white};
        background-color: ${colorTheme.primary};
      }
    `,
  )

// const _TextTabRadioItem = React.forwardRef<HTMLInputElement, RadioGroupInputProps>(
//   ({ id: _id, children, className, ...props }, ref) => {
//     const _uid = useId()
//     const id = _id ?? _uid
//     return (
//       <RadioItem
//         className={clsx(TextTabRadioItemClassName, className)}
//         css={css`
//           label {
//             ${typo.heading05};
//             font-weight: bold;
//             cursor: pointer;
//             color: ${colors.gray.$700};
//           }

//           input:checked + label {
//             color: ${colorTheme.primary};
//           }
//         `}
//       >
//         <Radio.GroupInput ref={ref} id={id} {...props} hidden />
//         <label htmlFor={id}>{children}</label>
//       </RadioItem>
//     )
//   },
// )

// _TextTabRadioItem.displayName = 'SubTabTextRadioItem'

// export const SubTabTextRadioGroupItem = _TextTabRadioItem
