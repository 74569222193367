import clsx from 'clsx'
import { useMemo } from 'react'

import Radio from '~/@common/ui/radio'
import type { RadioGroupProps } from '~/@common/ui/radio/group/RadioGroup'
import type { RadioGroupInputProps } from '~/@common/ui/radio/group/RadioGroupInput'
import { radioGroupItemFactory } from '~/@common/ui/radio/group/RadioGroupItem'

import S from './일반채점입력.style'

export const AnswerType = {
  CORRECT: 'CORRECT',
  WRONG: 'WRONG',
  UNKNOWN: 'UNKNOWN',
  NONE: 'NONE',
} as const

type 일반채점Props = Omit<RadioGroupProps, 'value' | 'onChange'> & {
  onChange?: (v: (typeof AnswerType)[keyof typeof AnswerType]) => void
} & {
  value: (typeof AnswerType)[keyof typeof AnswerType]
}

export const 일반채점입력 = ({ onChange, ...props }: 일반채점Props) => {
  return (
    <S.일반채점>
      <Radio.Group
        value={props.value}
        onChange={(v) => {
          onChange?.(v as (typeof AnswerType)[keyof typeof AnswerType])
        }}
      >
        <일반채점입력Item
          {...props}
          value={AnswerType.CORRECT}
          onClick={(e) => {
            if (e.currentTarget.checked) {
              onChange?.(AnswerType['NONE'])
            }
          }}
        />
        <div className="divider" />
        <일반채점입력Item
          {...props}
          value={AnswerType.WRONG}
          onClick={(e) => {
            if (e.currentTarget.checked) {
              onChange?.(AnswerType['NONE'])
            }
          }}
        />
        <div className="divider" />
        <일반채점입력Item
          {...props}
          value={AnswerType.UNKNOWN}
          onClick={(e) => {
            if (e.currentTarget.checked) {
              onChange?.(AnswerType['NONE'])
            }
          }}
        />
      </Radio.Group>
    </S.일반채점>
  )
}

export const 일반채점입력Item = ({ className, children, ...props }: RadioGroupInputProps) => {
  const _props = useMemo(() => {
    switch (props.value) {
      case AnswerType.CORRECT: {
        return {
          icon: <CorrectIcon />,
          css: S.일반채점_정답,
        } as const
      }
      case AnswerType.WRONG: {
        return {
          icon: <WrongIcon />,
          css: S.일반채점_오답,
        } as const
      }
      default: {
        return {
          icon: <QuestionIcon />,
          css: S.일반채점_모름,
        } as const
      }
    }
  }, [props.value])

  return radioGroupItemFactory(
    {
      children: (
        <>
          {_props.icon}
          {children}
        </>
      ),
      className: clsx('marking-button-item', className),
      ...props,
    },
    '일반채점입력Item',
    [S.일반채점입력Item, _props.css],
  )
}

const CorrectIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="21" height="20" viewBox="0 0 21 20" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.8346 2.20801C6.52605 2.20801 3.04297 5.69109 3.04297 9.99967C3.04297 14.3083 6.52605 17.7913 10.8346 17.7913C15.1432 17.7913 18.6263 14.3083 18.6263 9.99967C18.6263 5.69109 15.1432 2.20801 10.8346 2.20801ZM5.1263 9.99967C5.1263 6.85326 7.68822 4.29134 10.8346 4.29134C13.9811 4.29134 16.543 6.85326 16.543 9.99967C16.543 13.1461 13.9811 15.708 10.8346 15.708C7.68822 15.708 5.1263 13.1461 5.1263 9.99967Z"
        fill="#00ABFF"
      />
    </svg>
  )
}

const WrongIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="21" height="20" viewBox="0 0 21 20" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.4991 8.5304L5.84072 3.87207L4.37109 5.3417L9.02943 10L4.37109 14.6584L5.84072 16.128L10.4991 11.4697L15.1574 16.128L16.627 14.6584L11.9687 10L16.627 5.3417L15.1574 3.87207L10.4991 8.5304Z"
        fill="#FF707D"
      />
    </svg>
  )
}

const QuestionIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="21" height="20" viewBox="0 0 21 20" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.06409 18.6525V16.127H11.4391V18.6525H9.06409ZM10.2516 4.35847C8.71526 4.35847 7.4637 5.63724 7.38095 7.21727H5.00391C5.08725 4.21947 7.41174 1.83301 10.2516 1.83301C13.1439 1.83301 15.5016 4.30841 15.5016 7.38393C15.5016 9.09208 14.5398 10.0307 13.5239 10.969L13.4311 11.0547C12.4862 11.9258 11.5087 12.8269 11.4426 14.3643H9.06591C9.08896 13.3146 9.32996 12.5443 9.67679 11.9393C10.0429 11.3005 10.5327 10.8355 11.0355 10.4153C11.1547 10.3157 11.2756 10.2182 11.3961 10.121L11.3961 10.121C12.2733 9.41355 13.1266 8.72538 13.1266 7.38393C13.1266 5.72815 11.8416 4.35847 10.2516 4.35847Z"
        fill="#FFB155"
      />
    </svg>
  )
}
