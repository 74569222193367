const kind = `ms__Calendar` as const

const parts = {
  header: `${kind}_header`,
  yearMonth: `${kind}_yearMonth`,
  weekDays: `${kind}_weekDays`,
  dateGrid: `${kind}_dateGrid`,
  date: `${kind}_date`,
} as const

/** @internal */
export default {
  kind,
  parts,
}
