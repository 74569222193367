import { createContext, useContext } from 'react'

import type { Drawer } from './Drawer.type.ts'

const defaultDrawerContext: Drawer = {
  isOpened: false,
  closeDrawer: () => {},
  openDrawer: () => {},
  onOpened: () => {},
  onClosed: () => {},
  size: 'auto',
  placement: 'bottom',
  hasCloseButton: true,
  hasBorderRadius: true,
  showBackdrop: true,
  bodyPadding: 0,
}

export const DrawerContext = createContext<Drawer>(defaultDrawerContext)

export const useDrawerContext = () => {
  const context = useContext(DrawerContext)

  if (!context) {
    throw new Error(
      'Drawer 하위 컴포넌트(ex. Drawer.Content)를 Drawer 컴포넌트 내부에서 사용해주세요.',
    )
  }

  return context
}
