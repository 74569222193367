import styled from '@emotion/styled'
import { observer } from 'mobx-react'

import { colors, typo } from '~/@common/styles'
import { mediaQuery } from '~/@common/styles/mediaQuery'
import Switch from '~/@common/ui/Switch/Switch'

import { GradingResultNotification } from '../GradingResultNotification'
import type { ProblemScoringViewOption } from './@trait/ProblemScoringViewOption.trait'

const ProblemScoringViewGuideOption = ({
  viewOption,
  guideDisabled = false,
}: {
  viewOption?: ProblemScoringViewOption<'학습모듈' | 'NOT_학습모듈'>
  guideDisabled?: boolean
}) => {
  if (!viewOption) {
    return <S.ProblemScoringViewGuideOption />
  }
  return (
    <S.ProblemScoringViewGuideOption>
      {guideDisabled ? null : viewOption.가이드_노출 === undefined ? (
        <></>
      ) : viewOption.가이드_노출 === '가이드_일반채점' ? (
        <div className="left">
          <GradingResultNotification />
        </div>
      ) : (
        <div className="left">
          채점 후 답과 해설이 비공개되어 있습니다. 선생님에게 문의해주세요.
        </div>
      )}
      {(viewOption.오답_모르는문제만보기_노출여부 ||
        viewOption.안푼문제만보기_노출여부 ||
        viewOption.문제같이보기_노출여부) && (
        <div className="right">
          {viewOption.오답_모르는문제만보기_노출여부 && (
            <Switch
              checked={viewOption.오답_모르는문제만보기}
              onChange={(e) => {
                viewOption.onToggle({
                  오답_모르는문제만보기: e.target.checked,
                })
              }}
            >
              오답/모르는 문제만 보기
            </Switch>
          )}
          {viewOption.안푼문제만보기_노출여부 && (
            <Switch
              checked={viewOption.안푼문제만보기}
              onChange={(e) => {
                viewOption.onToggle({ 안푼문제만보기: e.target.checked })
              }}
            >
              안 푼 문제만 보기
            </Switch>
          )}
          {viewOption.문제같이보기_노출여부 && (
            <Switch
              className="문제같이보기_토글"
              checked={viewOption.문제같이보기}
              onChange={(e) => {
                viewOption.onToggle({
                  문제같이보기: e.target.checked,
                })
              }}
            >
              문제 같이 보기
            </Switch>
          )}
        </div>
      )}
    </S.ProblemScoringViewGuideOption>
  )
}

export default observer(ProblemScoringViewGuideOption)

const S = {
  ProblemScoringViewGuideOption: styled.div`
    display: flex;
    align-items: center;
    ${typo.caption01};
    height: 22px;

    > .left {
      display: flex;
      align-items: center;
      margin-right: auto;
    }
    > .right {
      display: flex;
      align-items: center;
      gap: 28px;
      margin-left: auto;
    }

    .문제같이보기_토글 {
      margin-left: auto;
    }

    ${mediaQuery.underTablet} {
      width: 100%;
      height: auto;
      flex-direction: column;
      > .left,
      > .right {
        width: 100%;
        padding: 10px 20px;
        border-bottom: 1px solid ${colors.gray.$300};
      }
      > .right {
        display: flex;
        justify-content: space-between;
      }
    }
  `,
}
