import { createContext } from '@radix-ui/react-context'
import type { InputHTMLAttributes } from 'react'

export const CheckboxGroupConsumerName = 'ui/checkbox-group'

export const [CheckboxGroupProvider, useCheckboxGroupContext] =
  createContext<CheckboxGroupContextValue | null>(CheckboxGroupConsumerName)

export type CheckboxGroupInputValue = string | number
export type CheckboxGroupInputValues = CheckboxGroupInputValue[]

export type CheckboxGroupContextValue = {
  name?: InputHTMLAttributes<HTMLInputElement>['name']
  values: CheckboxGroupInputValues
  onChange?: (v: CheckboxGroupInputValues, e?: React.ChangeEvent<HTMLInputElement>) => void
  min?: number
  max?: number
}
