import { css } from '@emotion/react'
import type { LearningProcessApi } from '@mathflat/domain/@entities/StudentLearningProcess/api'
import type { FC } from 'react'

import { colors, fontWeight, typo } from '~/@common/styles'
import { mediaQuery } from '~/@common/styles/mediaQuery'
import { Icon } from '~/@common/ui/Icon/Icon'

export type RawLecture = LearningProcessApi.개념학습하기.Output['lectures'][number]
export type Lecture = RawLecture['lecture'] & {
  studentLectureCreateDatatime?: string | null
}

interface Props {
  item: Lecture
  isSelected: boolean
  onLectureClick: (item: Lecture) => void
}

const LectureVideoItem: FC<Props> = ({ item, isSelected, onLectureClick }) => {
  const handleClick = () => {
    onLectureClick(item)
  }

  return (
    <div css={_css} className={isSelected ? 'selected' : ''} onClick={handleClick}>
      <Icon
        name="icon_circle_play"
        color={
          item.studentLectureCreateDatatime || isSelected ? colors.blue.$500 : colors.gray.$700
        }
      />
      {item.title.replace(/(\[([^>]+)\])/gi, '')}
    </div>
  )
}

export default LectureVideoItem

const _css = css`
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 20px;
  cursor: pointer;
  color: ${colors.gray.$900};
  ${typo.body02};

  &:hover {
    background-color: ${colors.gray.$200};
  }

  &.selected {
    font-weight: ${fontWeight.bold};
    color: ${colors.blue.$500};

    background-color: rgba(57, 95, 226, 0.1);
  }

  ${mediaQuery.underTablet} {
    padding: 16px;
    ${typo.caption01};
  }
`
