import { observer } from 'mobx-react'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router'

import { HandwrittenNoteType } from '~/@common/api/handwrittenNoteApi'
import { TOAST_STRING } from '~/@common/constants/strings'
import { useStudentAppMediaQuery } from '~/@common/hooks/useMediaQuery'
import { useRepository } from '~/@common/hooks/useRepository'
import { errorHandlerService, WorksheetProblemNoteService } from '~/@common/services'
import modalService from '~/@common/services/modal.service'
import Modal from '~/@common/ui/modal/Modal'
import {
  SwiperControllerConsumerName,
  useSwiperControllerContext,
} from '~/@common/ui/SwiperController/SwiperController.context'
import WorksheetScoringByOne from '~/@pages/@widgets/(Worksheet)/WorksheetScoring/WorksheetScoringByOne'
import WorksheetScoringByOneMobile from '~/@pages/@widgets/(Worksheet)/WorksheetScoring/WorksheetScoringByOne.mobile'
import { useLearningProcessReference } from '~/@pages/student/learning-process/@common/hooks/useGetLearningProcessReference'

import { LearningProcessService } from '../../../service/LearningProcess.service'
import S from './문제풀이.style'
import { AiTutorService } from '~/@common/services/AiTutor.service.ts'

const 문제풀이 = () => {
  const { studentWorksheetId } = useParams<{ studentWorksheetId: string }>()

  const service = useRepository(LearningProcessService)
  const noteService = useRepository(WorksheetProblemNoteService)
  const swiperController = useSwiperControllerContext(SwiperControllerConsumerName)

  const { isMobile } = useStudentAppMediaQuery()
  const referenceParams = useLearningProcessReference()
  const [isAiTutorOpened, setIsAiTutorOpened] = useState(false)
  const aiTutorService = useRepository(AiTutorService)

  useEffect(() => {
    service.changeElement(
      service.currentStatus.step,
      service.currentStatus.screen,
      swiperController.activeIndex,
    )
  }, [service, swiperController.activeIndex])

  useEffect(() => {
    return () => aiTutorService.close()
  }, [])

  const onSubmitProblems = async () => {
    if (service.학습모듈_문제풀이.studentWorksheet?.status === 'COMPLETE') {
      service.goToNextStep()
    }

    if (service.학습모듈_문제풀이.problemScoringColl?.toScoredArr.length) {
      if (!service.학습모듈_문제풀이.problemScoringColl?.isAllLocallyScored) {
        modalService.openModal(
          <Modal.Alert
            confirm={{
              onClick: () => {
                modalService.closeModal()
                if (
                  service.학습모듈_문제풀이.problemScoringColl &&
                  service.학습모듈_문제풀이.problemScoringColl.notMarkingProblemFirstIndex >= 0
                )
                  swiperController.control?.slideTo(
                    service.학습모듈_문제풀이.problemScoringColl.notMarkingProblemFirstIndex,
                  )
              },
            }}
          >
            <>
              다 풀지 않은 문제가 있어요.
              <br />
              모든 문제를 풀고 제출해주세요.
            </>
          </Modal.Alert>,
          { modalName: '자기주도학습_비활성화_단계' },
        )
      } else {
        modalService.openModal(
          <Modal.Alert
            confirm={{
              onClick: async () => {
                if (!studentWorksheetId) {
                  return
                }
                try {
                  modalService.showLoader()
                  modalService.closeModal()
                  const problemIds = service.학습모듈_문제풀이.problemScoringColl?.toScoredArr.map(
                    (item) => item.id,
                  )
                  try {
                    await noteService.uploadNotes(
                      Number(studentWorksheetId),
                      problemIds,
                      HandwrittenNoteType.STUDENT_WORKSHEET_SCORING,
                    )
                    window.freshpaint?.track('필기 제출', { ...referenceParams })
                  } catch (err) {
                    errorHandlerService.handle(err, {
                      message: TOAST_STRING.saveHandwrittenNoteFailed,
                      useRemoteLogging: true,
                    })
                  }
                  await service.onSubmitWorksheetProblems(studentWorksheetId)
                  service.결과확인_활성화()
                } catch (error) {
                  errorHandlerService.handle(error)
                } finally {
                  modalService.hideLoader()
                }
              },
              children: '제출하기',
            }}
          >
            <>
              제출한 후에는 답을 수정할 수 없어요.
              <br />
              그래도 제출할까요?
            </>
          </Modal.Alert>,
          { modalName: '자기주도학습_문제풀이_제출하기' },
        )
      }
    }
  }

  if (service.currentStatus.element === null) return

  return (
    <>
      {isMobile ? (
        <S.Container>
          <WorksheetScoringByOneMobile
            problemScoringColl={service.학습모듈_문제풀이.problemScoringColl}
            viewOption={service.학습모듈_문제풀이.problemScoringViewOption}
            onSubmit={onSubmitProblems}
            studentWorksheet={service.학습모듈_문제풀이.studentWorksheet}
            type={service.학습모듈_문제풀이.worksheet?.type}
            problemIndex={service.currentStatus.element}
          />
        </S.Container>
      ) : (
        <WorksheetScoringByOne
          problemScoringColl={service.학습모듈_문제풀이.problemScoringColl}
          viewOption={service.학습모듈_문제풀이.problemScoringViewOption}
          onSubmit={onSubmitProblems}
          studentWorksheet={service.학습모듈_문제풀이.studentWorksheet}
          worksheet={service.학습모듈_문제풀이.worksheet}
          problemIndex={service.currentStatus.element}
          isAiTutorOpened={isAiTutorOpened}
          setIsAiTutorOpened={setIsAiTutorOpened}
        />
      )}
    </>
  )
}

export default observer(문제풀이)
