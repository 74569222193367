import styled from '@emotion/styled'
import { ProblemDomain } from '@mathflat/domain/@entities'
import type { ReactNode } from 'react'

import { colors, typo } from '~/@common/styles'
import { Icon } from '~/@common/ui/Icon/Icon'
import type { ProblemScoring } from '~/@pages/@common/(ProblemScoring)/@trait/ProblemScoring.trait'

export const ScoringResultBubble = ({
  scoringResult,
  leftOffset = 0,
  bottomOffset = 0,
}: {
  scoringResult: ProblemScoring<'WORKSHEET'>['채점결과']
  leftOffset?: number
  bottomOffset?: number
}) => {
  return (
    <Style.Bubble
      color={bubbleItem[scoringResult].color}
      borderColor={bubbleItem[scoringResult].color}
      backgroundColor={bubbleItem[scoringResult].backgroundColor}
      leftOffset={leftOffset}
      bottomOffset={bottomOffset}
    >
      {bubbleItem[scoringResult].Icon}
      {bubbleItem[scoringResult].text}
    </Style.Bubble>
  )
}

const bubbleItem: Record<
  Exclude<ProblemDomain.ScoringResult, typeof ProblemDomain.SCORING_RESULT.안품>,
  { color: string; text: string; backgroundColor: string; Icon: ReactNode }
> = {
  [ProblemDomain.SCORING_RESULT.정답]: {
    color: colors.blue.$300,
    backgroundColor: colors.blue.$100,
    text: '정답입니다!',
    Icon: <Icon name="icon_answer_correct" color={colors.blue.$300} />,
  },
  [ProblemDomain.SCORING_RESULT.오답]: {
    color: colors.red.$300,
    backgroundColor: colors.red.$100,
    text: '오답입니다!',
    Icon: <Icon name="icon_answer_wrong" color={colors.red.$300} />,
  },
  [ProblemDomain.SCORING_RESULT.모름]: {
    backgroundColor: '#FFF7EE',
    color: colors.yellow,
    text: '모르겠어요!',
    Icon: <Icon name="icon_answer_question" color={colors.yellow} />,
  },
}

const Style = {
  Bubble: styled.div<{
    borderColor: string
    backgroundColor: string
    leftOffset: number
    bottomOffset: number
  }>`
    position: absolute;
    left: calc(-130px + 18px + ${({ leftOffset }) => leftOffset}px);
    bottom: calc(23px + 4px + ${({ bottomOffset }) => bottomOffset}px);
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4px;
    height: 35px;
    width: 130px;
    border-radius: 14px;
    font-weight: 700;
    color: ${({ color }) => color};
    border: 1px solid ${({ borderColor }) => borderColor};
    background-color: ${({ backgroundColor }) => backgroundColor};
    ${typo.caption01};

    ::after {
      position: absolute;
      z-index: -1;
      content: '';
      width: 10px;
      height: 10px;
      bottom: -5.5px;
      right: 28px;
      border-bottom: 1px solid;
      border-right: 1px solid;
      transform: rotate(45deg);
      border-radius: 2px;
      background-color: ${({ backgroundColor }) => backgroundColor};
      border-color: ${({ borderColor }) => borderColor};
    }
  `,
}
