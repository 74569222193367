import { type PropsWithChildren, useEffect, useId, useState } from 'react'

import {
  type CheckboxGroupContextValue,
  type CheckboxGroupInputValues,
  CheckboxGroupProvider,
} from './CheckboxGroup.context'

export type Props = Omit<CheckboxGroupContextValue, 'values'> &
  PropsWithChildren & { values?: CheckboxGroupContextValue['values'] }

export const kind = 'ms__CheckboxGroup' as const

const CheckboxGroup = ({ children, name, values, onChange, ...props }: Props) => {
  const _name = useId()
  const [_values, _setValues] = useState(values ?? [])

  const handleChange = (v: CheckboxGroupInputValues, e?: React.ChangeEvent<HTMLInputElement>) => {
    onChange?.(v, e)

    if (!values) {
      _setValues(v)
    }
  }

  useEffect(() => {
    if (values) {
      _setValues(values)
    }
  }, [values])

  useEffect(() => {
    if (
      (props.min !== undefined && props.min <= 0) ||
      (props.max !== undefined && props.max <= 0)
    ) {
      throw Error('out of range')
    }
  }, [props.min, props.max])

  return (
    <CheckboxGroupProvider
      data-component={kind}
      name={name ?? _name}
      values={_values}
      onChange={handleChange}
      {...props}
    >
      {children}
    </CheckboxGroupProvider>
  )
}

export default CheckboxGroup
