import * as Sentry from '@sentry/react'
import { isAxiosError } from 'axios'

import { TOAST_STRING } from '../constants/strings'
import { toastService } from './toast.service'
import { Logger } from '../utils/logger'

interface ErrorHandlerOptions {
  message?: string
  preventToast?: boolean
  useRemoteLogging?: boolean
  logType?: 'error' | 'warn'
}

class ErrorHandlerService {
  handle(err: unknown, options: ErrorHandlerOptions = {}) {
    const {
      message = TOAST_STRING.error,
      preventToast,
      useRemoteLogging,
      logType = 'error',
    } = options

    switch (logType) {
      case 'error':
        console.error(err)
        break
      case 'warn':
        console.warn(err)
        break
    }

    if (!preventToast) {
      toastService.error(message, { isSystemError: true })
    }

    if (isAxiosError(err)) {
      Logger.onAxiosInterceptorResError(err)
      return
    }

    if (useRemoteLogging) {
      Sentry.captureException(err)
    }

    // else if (err instanceof SyntaxError) {
    //   // handle SyntaxError
    // } else if (err instanceof Error) {
    //   // handle Error
    // }
  }
}

export const errorHandlerService = new ErrorHandlerService()
