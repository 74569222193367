import * as S from '@effect/schema/Schema'

import * as SchoolSystem from '~/@entities/(SchoolSystem)/schema.ts'
import { CurriculumDomain } from '..'

export module ConceptDomain {
  export const Summary = S.struct({
    conceptId: S.number,
    totalUsed: S.number,
    correctTimes: S.number,
    wrongTimes: S.number,
    answerRate: S.number,
  })

  export const Concept = S.struct({
    id: S.number,
    curriculumId: S.number,
    school: SchoolSystem.SchoolType,
    grade: SchoolSystem.AllGrade,
    semester: SchoolSystem.Semester,
    name: S.string,
    url: S.string,
    // TODO: 백엔드한테 이게 뭔지 물어봐야 함
    outOfCurriculumType: CurriculumDomain.OutOfCurriculumType,
    hidden: S.boolean,
    conceptSummary: ConceptDomain.Summary,
  })
}
