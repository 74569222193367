import axios, { HttpStatusCode } from 'axios'
import qs from 'qs'

import ServerMaintenanceService from '~/@pages/serverMaintenance/@service/ServerMaintenance.service'

import { TOAST_STRING } from '../constants/strings'
import { errorHandlerService, toastService } from '../services'
import { localStorageService } from '../services/storage.service'
import { envUtils } from './envUtils'
import { Logger } from './logger'

const PREVENT_TOAST_ERROR_CODES = [
  'STUDENT_WORKSHEET_NOT_FOUND',
  'WORKSHEET_NOT_FOUND',
  'WORKSHEET_ALREADY_DELETED',
  'WORKSHEET_PERMISSION_DENIED',
  'WORKSHEET_PROBLEM_EMPTY',
  'SELF_LEARNING_NOT_COMPLETE',
  'STUDENT_WORKSHEET_PROBLEM_DUPLICATE_SUBMIT',
  'STUDENT_WORKBOOK_PROBLEM_DUPLICATE_SUBMIT',
  'STUDENT_EXAM_PROBLEM_DUPLICATE_SUBMIT',
  'EXAM_NOT_STARTED',
  'EXAM_NOT_VALID',
  'SELF_LEARNING_ALREADY_EXHAUSTED',
  'SELF_LEARNING_ALREADY_CREATED',
]

const BASE_URL = (() => {
  if (envUtils.isStaging) {
    return 'https://api-staging.mathflat.com'
  }
  if (envUtils.isLive) {
    return 'https://api.mathflat.com'
  }
  return localStorage.getItem('api') ?? 'https://api-dev.mathflat.com'
})()

const maxios = axios.create({
  baseURL: BASE_URL,
  headers: {
    common: { 'x-platform': 'STUDENT' },
    post: { 'Content-Type': 'application/json' },
  },
  paramsSerializer: {
    serialize: (params) => {
      return qs.stringify(params, { arrayFormat: 'comma' })
    },
  },
})

maxios.interceptors.response.use(
  ({ data, status }) => Object.assign(data, { statusCode: status }),
  async (error) => {
    // Sentry.captureException(error)

    // 인터넷 연결을 보장하지 못함 (https://developer.mozilla.org/en-US/docs/Web/API/Navigator/onLine)
    // navigator.onLine이 undefined일 수 있어서 false와 비교
    if (navigator.onLine === false) {
      toastService.error(TOAST_STRING.networkOffline)
      return Promise.reject(error)
    }

    const status = error?.response?.status
    const errorCode = error?.response?.data?.code

    if (!status) {
      errorHandlerService.handle(error)
      return Promise.reject(error) // TO-DO: check usage
    }

    if (status === HttpStatusCode.Unauthorized) {
      Logger.setUnauthorizedUser()
      sessionStorage.clear()
      localStorageService.clear()
      envUtils.redirectToBasicStudentApp()
      return Promise.resolve() // ignore error
    }
    if (status === HttpStatusCode.NotFound) {
      return error
    }
    if (status >= 501) {
      await ServerMaintenanceService.checkServerMaintenance()
      return error
    }
    errorHandlerService.handle(error, {
      logType: 'error',
      useRemoteLogging: false,
      preventToast:
        status === HttpStatusCode.BadRequest && PREVENT_TOAST_ERROR_CODES.includes(errorCode),
    })

    return Promise.reject(error)
  },
)

export default maxios
