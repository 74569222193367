import styled from '@emotion/styled'

import { colors } from '~/@common/styles'
import type { DefaultProps } from '~/@common/types'

import { EmptyCaseIcon } from './EmptyCaseIcon'

export const EmptyCaseIconWithDescription = ({ children }: DefaultProps) => {
  return (
    <EmptyCaseIconWithDescriptionWrapper>
      <EmptyCaseIcon />
      {children}
    </EmptyCaseIconWithDescriptionWrapper>
  )
}

const EmptyCaseIconWithDescriptionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 15px;
  height: 100%;
  text-align: center;
  ${colors.gray.$800}
`
