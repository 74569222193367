import styled from '@emotion/styled'
import { uniqueId } from 'lodash'
import { type ComponentProps, type PropsWithChildren, useEffect, useState } from 'react'
import { Tooltip } from 'react-tooltip'

import { CustomEventService } from '~/@common/services/event.service'
import { tooltipContainerCss, zIndex } from '~/@common/styles'

export const kind = 'ms__tooltip_white_area'

// 사용방법
// 트리거 엘리먼트에 data-tooltip-id 달고, 단 element에 onClick에 CustomEventService.tooltipOn.dispath(id, e) 넣기
// TODO: 위의 내용 강제화 시키기
const DefaultTooltip = ({
  children,
  id: _id,
  onOpen,
  onClose,
  style,
  ...props
}: PropsWithChildren<
  ComponentProps<typeof Tooltip> & { onOpen?: () => void; onClose?: () => void }
>) => {
  const [isOpen, setIsOpen] = useState(props.isOpen ?? false)
  const id = _id ?? uniqueId()

  useEffect(() => {
    if (isOpen) {
      // 내가 열리면, 다른 툴팁은 닫히게 하는 이벤트를 보낸다
      CustomEventService.tooltipOff.dispatch(id)

      onOpen?.()
    } else {
      onClose?.()
    }
  }, [id, isOpen])

  useEffect(() => {
    const closeTooltip = (e: ReturnType<(typeof CustomEventService)['tooltipOff']['event']>) => {
      // 닫는 이벤트를 보낸 것이 자기 자신이면 제외
      if (e.detail.id !== id) {
        setIsOpen(false)
      }
    }

    const openTooltip = (e: ReturnType<(typeof CustomEventService)['tooltipOn']['event']>) => {
      setIsOpen(e.detail.id === id)
    }

    // 다른 툴팁들의 신호를 받으면 닫는 이벤트를 리스닝한다.
    // 단, 보낸 이가 자기자신일 경우는 제외한다.
    const { disposer } = CustomEventService.tooltipOff.listen(closeTooltip)
    const { disposer: disposer2 } = CustomEventService.tooltipOn.listen(openTooltip)

    return () => {
      disposer()
      disposer2()
    }
  }, [id])

  return (
    <Tooltip
      isOpen={isOpen}
      place="right-start"
      openOnClick
      clickable
      noArrow
      globalCloseEvents={{ escape: true, resize: true, clickOutsideAnchor: true }}
      border="none"
      positionStrategy="absolute"
      opacity={1}
      disableStyleInjection={true}
      style={{
        display: isOpen ? 'block' : 'none',
        background: 'none',
        color: 'none',
        padding: 0,
        zIndex: zIndex.툴팁,
        top: -10,
        ...style,
      }}
      {...props}
      id={id}
    >
      {children}
    </Tooltip>
  )
}

const WhiteAreaTooltip = ({
  children,
  ...props
}: PropsWithChildren<ComponentProps<typeof Tooltip>>) => {
  return (
    <DefaultTooltip {...props}>
      <S.TooltipContainer data-component={kind}>{children}</S.TooltipContainer>
    </DefaultTooltip>
  )
}

const Tooltip2 = {
  Default: DefaultTooltip,
  WhiteArea: WhiteAreaTooltip,
}

export default Tooltip2

const S = {
  TooltipContainer: styled.div`
    ${tooltipContainerCss};
  `,
}
