import styled from '@emotion/styled'
import { observer } from 'mobx-react'

import { colors } from '~/@common/styles'
import { mediaQuery } from '~/@common/styles/mediaQuery'
import type { StyleProps } from '~/@common/types'
import { ButtonModule } from '~/@common/ui/(Button)/Button'
import Button from '~/@common/ui/(Button)/Button/Button'
import ButtonSegmentedControl from '~/@common/ui/(Button)/ButtonSegmentedControl/ButtonSegmentedControl'

type Props = {
  submitTitle?: string
  onAllCorrectClick?: () => void
  onAllClearClick?: () => void
  onSubmitClick?: () => void
} & StyleProps

export const kind = 'ms__전체정답_전체삭제_제출'

const 전체정답_전체삭제_제출 = ({
  submitTitle = '제출하기',
  onAllCorrectClick,
  onAllClearClick,
  onSubmitClick,
  className,
}: Props) => {
  return (
    <S.Container data-component={kind} className={className}>
      <div>
        <ButtonSegmentedControl>
          {onAllCorrectClick && (
            <Button
              theme={ButtonModule.attr.theme.white}
              onClick={onAllCorrectClick}
              className="answer"
              minWidth="96px"
            >
              전체 정답
            </Button>
          )}
          <Button
            theme={ButtonModule.attr.theme.white}
            onClick={onAllClearClick}
            className="cancel"
            minWidth="96px"
          >
            전체 삭제
          </Button>
        </ButtonSegmentedControl>
      </div>
      <div className="submit-button">
        <Button
          theme={ButtonModule.attr.theme.primary}
          onClick={onSubmitClick}
          style={{ flexGrow: 1 }}
          minWidth="100%"
        >
          {submitTitle}
        </Button>
      </div>
    </S.Container>
  )
}
export default observer(전체정답_전체삭제_제출)
const S = {
  Container: styled.div`
    display: flex;
    gap: 12px;

    .answer,
    .cancel {
      &:active {
        background-color: ${colors.gray.$200};
      }
    }

    .answer {
      color: ${colors.blue.$300};
    }

    .cancel {
      color: ${colors.red.$300};
    }

    &.flex-end {
      justify-content: flex-end;
    }
    .submit-button {
      min-width: 180px;
    }

    ${mediaQuery.underTablet} {
      width: 100%;
      padding: 20px 16px 40px;

      .submit-button {
        min-width: auto;
        width: 100%;
      }
    }
  `,
}
