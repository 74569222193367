import { allColorPool, HSLColors } from '@mathflat/design-system/@common/styles/colors'
import type { ColorTheme, HSLColorTheme } from '@mathflat/design-system/@common/styles/colorTheme'

const mono = {
  white: allColorPool.white,
  black: allColorPool.black,
} as const

const gray = {
  $100: allColorPool.gray.$100,
  $200: allColorPool.gray.$200,
  $300: allColorPool.gray.$300,
  $400: allColorPool.gray.$400,
  $500: allColorPool.gray.$500,
  $600: allColorPool.gray.$600,
  $700: allColorPool.gray.$700,
  $800: allColorPool.gray.$800,
  $900: allColorPool.gray.$900,
} as const

const red = {
  $100: allColorPool.red.$100,
  $200: allColorPool.red.$200,
  $300: allColorPool.red.$300,
  $400: allColorPool.red.$400,
} as const

const blue = {
  $100: allColorPool.blue.$100,
  $110: allColorPool.blue.$110,
  $200: allColorPool.blue.$200,
  $300: allColorPool.blue.$300,
  $400: allColorPool.blue.$400,
  $500: allColorPool.blue.$500,
} as const

const green = {
  $100: allColorPool.green,
  'S-GREEN7': '#E1F5EE',
} as const

export const colorTheme: ColorTheme = {
  primary: blue.$500,
} as const

export const hslColorTheme: HSLColorTheme = {
  primary: HSLColors.primary,
  secondary: HSLColors.secondary,
  negative: HSLColors.negative,
  subGray: HSLColors.subGray,
}

export const colors = {
  mono,
  gray,
  blue,
  red,
  green,
  white: allColorPool.white,
  black: allColorPool.black,
  yellow: allColorPool.yellow,
  purple: allColorPool.purple,
  overlay: allColorPool.overlay,
  colorTheme,
  hslColorTheme,
}
