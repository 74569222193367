import type { ISODate } from '@mathflat/shared/@types/date'
import type { EmptyString } from '@mathflat/shared/@types/string'
import type { ValueOf } from '@mathflat/shared/@types/utilityTypes'

import { LEVEL_KOR } from '~/@common/(Content)/Level/constants'
import type { WorkbookDomain } from '~/@entities/(Content)/Workbook/domain'
import type { Entity as WorkbookProblemEntity } from '~/@entities/(Content)/Workbook/WorkbookProblem/dto'
import { ANSWER_TYPE } from '~/@entities/Problem/constants'
import type { Entity as ProblemEntity } from '~/@entities/Problem/dto'
import type { Entity as StudentWorkbookEntity } from '~/@entities/StudentWorkbook/dto'

export interface StudentWorkbookScoringParams<T extends WorkbookDomain.Type = WorkbookDomain.Type> {
  workbook: {
    autoScorable: boolean
    label: string // 문항 정보
  }

  // 내 교재, 학습지의 경우 WorkbookProblem의 Problem
  // 시중교재, 교과서, 시그니처의 경우 WorkbookProblem
  problem: T extends WorkbookDomain.TYPE['내교재'] | WorkbookDomain.TYPE['시그니처교재']
    ? Partial<
        Pick<ProblemEntity.Problem, 'problemImageUrl' | 'answerImageUrl' | 'solutionImageUrl'>
      > &
        Pick<
          | WorkbookProblemEntity.CustomWorkbookProblem
          | WorkbookProblemEntity.CustomSignatureWorkbookProblem,
          'answer' | 'type' | 'keypadTypes' | 'answerUnits' | 'level' | 'optionCount'
        >
    : Pick<
        WorkbookProblemEntity.SchoolWorkbookProblem | WorkbookProblemEntity.PublicWorkbookProblem,
        | 'answer'
        | 'type'
        | 'keypadTypes'
        | 'answerUnits'
        | 'level'
        | 'optionCount'
        | 'answerImageUrl'
      >
  scoring: {
    workbookProblemId: WorkbookProblemEntity.WorkbookProblem['id']
    studentWorkbookProgressId: StudentWorkbookEntity.Progress['id']
    result: ValueOf<typeof ANSWER_TYPE>
    updateDatetime?: ISODate
    userAnswer: null | string | EmptyString
  }
}

type StudentWorkbookScoringId =
  `${StudentWorkbookScoringParams['scoring']['workbookProblemId']}-${StudentWorkbookScoringParams['scoring']['studentWorkbookProgressId']}`

type DeconstructedStudentWorkbookScoringId = Pick<
  StudentWorkbookScoringParams['scoring'],
  'workbookProblemId' | 'studentWorkbookProgressId'
>

export namespace Entity {
  export class StudentWorkbookScoring<T extends WorkbookDomain.Type = WorkbookDomain.Type> {
    userAnswer: null | string = null
    result: ValueOf<typeof ANSWER_TYPE>
    updateDatetime?: Date

    private _workbookProblemId: StudentWorkbookScoringParams<T>['scoring']['workbookProblemId']
    private _studentWorkbookProgressId: StudentWorkbookScoringParams<T>['scoring']['studentWorkbookProgressId']
    private _workbook: StudentWorkbookScoringParams<T>['workbook']
    private _problem: StudentWorkbookScoringParams<T>['problem']

    constructor({ workbook, problem, scoring }: StudentWorkbookScoringParams<T>) {
      this._workbook = workbook
      this._problem = problem

      // scoring
      this._workbookProblemId = scoring.workbookProblemId
      this._studentWorkbookProgressId = scoring.studentWorkbookProgressId
      this.result = scoring.result

      // userAnswer 이슈 : 빈 스트링일 경우 데이터적으로는 유저가 빈 스트링을 입력(자동채점)했을 가능성 혹은 선생님이 채점했을 경우의 상태를 공유한다.
      // 따라서 프론트/백엔드에서 유저가 빈 스트링으로 채점했을 경우를 막아야하는데, 백엔드에서는 막고 있지 않아 프론트에서 잘 막아야 한다.
      this.userAnswer = scoring.userAnswer === '' ? null : scoring.userAnswer

      if (scoring.updateDatetime) {
        this.updateDatetime = new Date(scoring.updateDatetime)
      }
    }

    // API 단에서 WORKBOOK의 ScoringId 제거, 대신 조합키 재료를 내려줌 -> WORKBOOK의 경우 조합해서 id를 만들어서 사용
    // id를 조합해서 생성하는 함수
    static constructScoringId(
      params: Pick<
        StudentWorkbookScoringParams['scoring'],
        'workbookProblemId' | 'studentWorkbookProgressId'
      >,
    ): StudentWorkbookScoringId {
      return `${params.workbookProblemId}-${params.studentWorkbookProgressId}`
    }

    // API 단에서 WORKBOOK의 ScoringId 제거, 대신 조합키 재료를 내려줌 -> WORKBOOK의 경우 조합해서 id를 만들어서 사용
    // API를 보낼 때에도 id가 아닌 조합키형태로 다시 컨버팅 해줘서 보내야 함
    static deconstructScoringId(
      scoringId: StudentWorkbookScoringId,
    ): DeconstructedStudentWorkbookScoringId {
      const [workbookProblemId, progressId] = scoringId.split('-')
      return {
        workbookProblemId: parseInt(workbookProblemId),
        studentWorkbookProgressId: parseInt(progressId),
      }
    }

    get id() {
      return StudentWorkbookScoring.constructScoringId({
        studentWorkbookProgressId: this._studentWorkbookProgressId,
        workbookProblemId: this._workbookProblemId,
      })
    }

    get deconstructedId() {
      return StudentWorkbookScoring.deconstructScoringId(this.id)
    }

    get problem() {
      return this._problem
    }

    get 채점결과() {
      return this.result
    }

    get 문제번호() {
      return this._workbook.label
    }

    get 문제정답() {
      return this._problem.answer
    }

    get 문제이미지() {
      if ('problemImageUrl' in this._problem) {
        return this._problem.problemImageUrl
      }
    }

    get 문제정답이미지() {
      if ('answerImageUrl' in this._problem) {
        return this._problem.answerImageUrl
      }
    }

    get 문제정답타입() {
      return this._problem.type
    }

    get 문제해설이미지() {
      if ('solutionImageUrl' in this._problem) {
        return this._problem.solutionImageUrl
      }
    }

    get 문제n지선다() {
      return this._problem.optionCount
    }

    get 제출한답() {
      return this.userAnswer
    }

    get isSubmitted() {
      return this.채점결과 !== ANSWER_TYPE.NONE
    }

    get isAutoScorable() {
      return this._workbook.autoScorable
    }

    get 문제정답길이() {
      return this.문제정답.split(',').length
    }

    get 오답_모르는문제여부() {
      return this.채점결과 === ANSWER_TYPE.WRONG || this.채점결과 === ANSWER_TYPE.UNKNOWN
    }

    get keypadTypes() {
      return this._problem.keypadTypes
    }

    get answerUnits() {
      return this._problem.answerUnits
    }

    get 난이도() {
      return LEVEL_KOR[this._problem.level as keyof typeof LEVEL_KOR]
    }
  }
}
