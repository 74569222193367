import { css } from '@emotion/react'
import { observer } from 'mobx-react'
import type { ReactNode } from 'react'
import React from 'react'
import { useNavigate } from 'react-router'

import { colors, maxWidth, textEllipsis, typo } from '~/@common/styles'
import { mediaQuery } from '~/@common/styles/mediaQuery'
import type { PrevLinkModule } from '~/@common/ui/(Link)/PrevLink'
import PrevLink from '~/@common/ui/(Link)/PrevLink/PrevLink.tsx'
import { Icon } from '~/@common/ui/Icon/Icon'

export const kind = `ms__GlobalHeader` as const

type Props = {
  prevLinkProps?: PrevLinkModule.Props
  SlotLeft?: ReactNode
  SlotCenter?: ReactNode
  SlotRight?: ReactNode
} & Pick<React.ComponentProps<'nav'>, 'children' | 'className' | 'style'>

export const parts = {
  slotLeft: `${kind}_slotLeft`,
  slotLeftPrevLink: `${kind}_slotLeft_prev_link`,
  slotCenter: `${kind}_slotCenter`,
  slotRight: `${kind}_slotRight`,
  contents: `${kind}_contents`,
} as const

export const GlobalHeader = ({
  prevLinkProps,
  SlotLeft,
  SlotCenter,
  SlotRight,
  ...props
}: Props) => {
  const navigate = useNavigate()
  return (
    <nav data-component={kind} css={_css} {...props}>
      <div className={parts.contents}>
        <div className={parts.slotLeft}>
          {SlotLeft || (
            <PrevLink
              className={parts.slotLeftPrevLink}
              onClick={(e) => {
                e.preventDefault()
                navigate(-1)
              }}
              {...prevLinkProps}
            >
              <Icon name="icon_chevron_left" size={20} />
            </PrevLink>
          )}
        </div>
        <div className={parts.slotCenter}>{SlotCenter}</div>
        <div className={parts.slotRight}>{SlotRight}</div>
      </div>
    </nav>
  )
}

const _css = css`
  display: flex;
  align-items: center;
  background: ${colors.white};
  width: 100%;
  height: 68px;

  ${mediaQuery.underTablet} {
    height: 61px;
  }

  .${parts.contents} {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 30px;

    margin: 0 auto;
    padding: 0 40px;

    grid-template-columns: 1fr 1fr 1fr;
    ${maxWidth.tablet.maxContentWidth};
  }
  ${mediaQuery.underTablet} {
    .${parts.contents} {
      padding: 0 24px;
    }
  }
  .${parts.slotLeft}, .${parts.slotCenter}, .${parts.slotRight} {
    display: flex;
    align-items: center;
  }

  .${parts.slotLeft} {
    justify-content: start;
    padding-right: 18px;
  }

  .${parts.slotCenter} {
    position: absolute;
    left: 50%;
    justify-content: center;
    flex: 0 0 590px;
    color: ${colors.gray.$900};
    ${typo.heading05}
    font-weight: bold;
    text-align: center;
    transform: translateX(-50%);
    ${textEllipsis(1)};
  }
  .${parts.slotRight} {
    width: 932px;
    display: flex;
    justify-content: flex-end;
  }
`

export default observer(GlobalHeader)
