import '~/@common/styles/reset.css'
import '~/@common/styles/font.css'

import { css } from '@emotion/react'

import { colors, fontFamily, typo } from '~/@common/styles'

export const globalCss = css`
  * {
    -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
    -webkit-touch-callout: none;
    -webkit-user-drag: none;
    -webkit-user-select: none;
    user-select: none;
    box-sizing: border-box;

    &::before,
    &::after {
      box-sizing: border-box;
    }
  }

  html {
    height: 100vh;
    height: 100dvh;
    overflow: hidden;
  }

  body {
    margin: 0;
    padding: 0;
    border: 0;
    font-weight: 400;
    height: 100%;
    ${typo.body02};
    color: ${colors.gray.$800};

    ${fontFamily.default};
    overflow-x: hidden;
    overflow-y: auto; // 태블릿 브라우저 주소창 가릴려면 필요
  }

  #root {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100vw;
    min-width: 360px;
    padding: var(--g-safe-area-top) var(--g-safe-area-right) var(--g-safe-area-bottom)
      var(--g-safe-area-left);
    overflow: hidden;
  }

  #app {
    display: flex;
    position: relative;
    flex-direction: column;
    overflow: hidden;
    height: 100%;
    background: #f0f2f4;
  }

  main {
    flex: 1;
  }

  #layout-header > .app-layout-header {
    display: none;

    &:last-of-type {
      display: block;
    }
  }

  button {
    margin: 0;
    padding: 0;
    border: none;
    outline: none;
    background: transparent;
    ${fontFamily.default};
    font-size: inherit;
    color: inherit;
    cursor: pointer;
  }

  a {
    color: inherit;
    text-decoration: none;
  }

  input {
    user-select: auto;
  }

  input[type='password'] {
    font-family: 'pass', 'Roboto', Helvetica, Arial, sans-serif;
  }

  [role='button'] {
    cursor: pointer;
  }

  details {
    summary {
      display: flex;
      align-items: center;
      position: relative;

      list-style: none;

      &:focus-within {
        outline: none;
      }
    }
    summary::-webkit-details-marker {
      content: '';
      display: none;
    }
    summary::marker {
      content: '';
    }

    summary::before {
      flex-shrink: 0;
      content: '';
      transition: transform 0.05s ease-out;
      display: inline-block;
      align-self: center;
      position: relative;
      width: 13px;
      height: 8px;
      top: -1px;
      transform: rotateZ(-90deg);
      transform-origin: center center;
      background: url('/images/icons/icon_html-marker-default.svg') no-repeat center center;
    }
  }
  details[open] summary::before {
    transform: rotateZ(0);
  }
`
