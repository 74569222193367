import { css } from '@emotion/react'
import { kindSelector } from '@mathflat/design-system/@common/utils/dataSelector.ts'
import { ConceptChipDomain } from '@mathflat/domain/@entities/ConceptChip/domain.ts'

import { useStudentAppMediaQuery } from '~/@common/hooks/useMediaQuery'
import modalService from '~/@common/services/modal.service.tsx'
import { colors, fontWeight, typo } from '~/@common/styles'
import { mediaQuery } from '~/@common/styles/mediaQuery'
import { Drawer } from '~/@common/ui/(Drawer)/BaseDrawer'
import { ConceptChipAchievementLevelModule } from '~/@pages/@common/(ConceptChip)/ConceptChipAchievmentLevel'
import ConceptChipAchievementLevel from '~/@pages/@common/(ConceptChip)/ConceptChipAchievmentLevel/ConceptChipAchievementLevel.tsx'

const LEVEL_OF_ACHIEVEMENT = ConceptChipDomain.LEVEL_OF_ACHIEVEMENT

export const CONCEPT_CHIP_DATA = [
  [
    LEVEL_OF_ACHIEVEMENT.풀지않음,
    {
      name: '화이트',
      description: (
        <>
          아직 학습을 <br />
          시작하지 않았어요.
        </>
      ),
    },
  ],
  [
    LEVEL_OF_ACHIEVEMENT.문제수부족,
    {
      name: '그레이',
      description: (
        <>
          학습량이 부족해요. <br />
          2문제 이상 풀어보세요!
        </>
      ),
    },
  ],
  [
    LEVEL_OF_ACHIEVEMENT.LV1,
    {
      name: '새드',
      description: (
        <>
          전혀 이해하지 <br />
          못하고 있어요.
        </>
      ),
    },
  ],
  [
    LEVEL_OF_ACHIEVEMENT.LV2,
    {
      name: '레드',
      description: (
        <>
          이해도가 <br />
          낮은 상태예요.
        </>
      ),
    },
  ],
  [
    LEVEL_OF_ACHIEVEMENT.LV3,
    {
      name: '옐로우',
      description: (
        <>
          개념을 이해하고 있지만 <br />
          보충이 필요해요.
        </>
      ),
    },
  ],
  [
    LEVEL_OF_ACHIEVEMENT.LV4,
    {
      name: '그린',
      description: (
        <>
          개념을 충분히 이해하여, <br />
          문제를 풀 수 있어요.
        </>
      ),
    },
  ],
  [
    LEVEL_OF_ACHIEVEMENT.LV5,
    {
      name: '스마일',
      description: (
        <>
          완전히
          <br />
          이해하고 있어요.
        </>
      ),
    },
  ],
] as const

const title = '성취도 컬러에 대해 알려드려요'

export const kind = 'ms__ConceptChipSummaryHelpModal' as const

export const parts = {
  achievementChip: `${kind}_achievementLevel`,
}

interface Props {
  isShowDrawer?: boolean
  onCloseDrawer?: () => void
}

const InfoContent = (props: { isShow: boolean }) => {
  return (
    props.isShow && (
      <p>
        학습한 학습지, 교과서, 시중교재, 자기주도학습을 통한 <br />
        모든 채점 데이터를 다음과 같은 컬러로 표시해요
      </p>
    )
  )
}

const ConceptChipSummaryHelpModal = (props: Props) => {
  const { isMobile } = useStudentAppMediaQuery()

  const content = (
    <div css={_css}>
      <InfoContent isShow={!isMobile} />
      <ul>
        {CONCEPT_CHIP_DATA.map(([level, { name, description }]) => (
          <li key={level}>
            <div className={parts.achievementChip}>
              <ConceptChipAchievementLevel level={level} size={28} isCircle={true} disabled />
            </div>
            <b>{name}</b>
            <span>{description}</span>
          </li>
        ))}
      </ul>
    </div>
  )

  if (isMobile) {
    return (
      <Drawer
        isOpened={Boolean(props.isShowDrawer)}
        hasCloseButton={false}
        hasPrevButton={true}
        onClickPrev={() => {
          if (props.onCloseDrawer) {
            props.onCloseDrawer()
          }
        }}
        size="596px"
      >
        <Drawer.Content>
          <Drawer.Header>{title}</Drawer.Header>
          <Drawer.Info>
            <InfoContent isShow={true} />
          </Drawer.Info>
          <Drawer.Body>{content}</Drawer.Body>
        </Drawer.Content>
      </Drawer>
    )
  }

  return content
}

ConceptChipSummaryHelpModal.open = () => {
  return modalService.openModal(
    {
      header: title,
      content: <ConceptChipSummaryHelpModal />,
    },
    {
      modalName: 'ConceptChipSummaryHelpModal',
      modalStyle: {
        padding: '0',
      },
    },
  )
}

const _css = css`
  display: flex;
  flex-direction: column;
  width: 400px;
  padding-top: 16px;
  overflow-y: auto;

  > p {
    text-align: center;
  }

  > ul {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 236px;
    min-height: 380px;

    margin: 28px auto 30px;

    .${parts.achievementChip} {
      margin-right: 18px;
    }

    b {
      display: block;
      width: 44px;
      margin-right: 20px;
      align-self: center;
      ${typo.body01};
      font-weight: ${fontWeight.bold};
    }

    span {
      display: block;
      width: 120px;
      ${typo.caption01};
    }

    ${kindSelector(ConceptChipAchievementLevelModule)} label {
      border-radius: 50%;
    }
    ${kindSelector(
  ConceptChipAchievementLevelModule,
)}:not([data-level='${LEVEL_OF_ACHIEVEMENT.풀지않음}']) {
      label {
        border: none;
      }
    }
  }

  > ul > li {
    display: flex;
  }

  ${mediaQuery.underTablet} {
    width: auto;
    padding: 0;

    > ul {
      width: 100%;
      margin: 0;
      min-height: unset;

      > li {
        height: 68px;
        border-bottom: 1px solid ${colors.gray.$200};
        align-items: center;
        padding: 0 24px;
        gap: 10px;

        &:last-of-type {
          border-bottom: 0;
        }
      }

      .${parts.achievementChip} {
        margin-right: 0;
      }

      b {
        color: ${colors.gray.$800};
      }

      span {
        margin-right: 0;
        margin-left: auto;
        text-align: right;
      }
    }
  }
`
export default ConceptChipSummaryHelpModal
