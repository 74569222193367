import { useEffect } from 'react'

import learningTimeService from '../services/learningTime.service'

/**
 *
 * @description 총 학습시간 저장하는 hook, 내부에서 예외 처리하므로 try...catch 불필요
 */
export const useLearningTime = () => {
  const start = async () => {
    await learningTimeService.start()
  }
  const stop = async () => {
    await learningTimeService.stop()
  }

  useEffect(() => {
    const onVisibilityChange = () => {
      if (document.hidden) {
        void stop()
      } else {
        void start()
      }
    }
    void start()
    document.addEventListener('visibilitychange', onVisibilityChange)

    return () => {
      void stop()
      document.removeEventListener('visibilitychange', onVisibilityChange)
    }
  }, [])

  return {
    start,
    stop,
  }
}
