import styled from '@emotion/styled'

import { colors, typo } from '~/@common/styles'

const S = {
  Container: styled.section`
    position: relative;
    display: flex;
    flex-direction: column;
    flex: 1;
    justify-content: center;
    align-items: center;

    .logo {
      position: absolute;
      top: calc(50% - 105px);
      margin-bottom: 10px;
      transition: transform 0.28s;

      &.trans {
        transform: translateY(-370px);
      }
    }
    .form {
      position: absolute;
      top: 50%;
      width: 300px;
      text-align: center;

      &.phone-mode {
        .id-input {
          position: absolute;
          top: 0;
          left: 0;
        }

        .transform-wrap {
          transform: translateY(64px);
        }
      }
    }

    .transform-wrap {
      transition: transform 0.3s ease-out;
    }
    .id-input {
      width: 100%;

      input {
        text-transform: capitalize;
      }
    }
    .password-input {
      width: 100%;
    }
    .start-button {
      margin: 40px auto 16px auto;
      border-radius: 8px;
      width: 100%;
    }

    input {
      text-align: center;
    }
    .keep-sign-in {
      display: flex;
      justify-content: center;
      align-items: center;
      input[type='checkbox'] {
        margin-right: 6px;
      }
    }
  `,
  Guide: styled.section`
    ${typo.caption01}
    margin: 0 auto calc(var(--safe-area-bottom) + 16px);
    color: ${colors.gray.$500};
  `,
}

export default S
