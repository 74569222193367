import type { WorkbookDomain } from '@mathflat/domain/@entities/(Content)/Workbook/domain.ts'
import { observer } from 'mobx-react'
import { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router'

import { routeName, type studentWorkbookDetailPathParams } from '~/@common/constants/route'
import { useLearningTime } from '~/@common/hooks/useLearningTime'
import { useSetRepository } from '~/@common/hooks/useRepository'
import { useLastLocationManager } from '~/@common/services/(LastLocationManager)'
import modalService from '~/@common/services/modal.service'
import { commonRepo } from '~/@common/services/repo.service'
import { PortalRootLayoutHeader } from '~/@common/ui/PortalRootLayoutHeader'
import GlobalHeader from '~/@pages/@widgets/(Navigation)/GlobalHeader/GlobalHeader'

import { StudentLearningContentBackConfirmModal } from '../../@widgets/learningContent/StudentLearningContentBackConfirmModal'
import { StudentWorkbookDetailService } from './@service/StudentWorkbookDetail.service'
import StudentWorkbookDetailBody from './@widgets/StudentWorkbookDetailBody'
import S from './StudentWorkbookDetail.style'

const StudentWorkbookDetailPage = () => {
  useLearningTime()

  const { studentWorkbookId, revisionId, pageNumber } =
    useParams<typeof studentWorkbookDetailPathParams>()
  const navigate = useNavigate()
  const locationManager = useLastLocationManager()

  const service = useSetRepository(
    StudentWorkbookDetailService<Exclude<WorkbookDomain.Type, WorkbookDomain.TYPE['시그니처교재']>>,
    pageNumber,
  )

  const defaultPrevRouteName = routeName.student.generalStudentWorkbookList

  const navigateBack = () => {
    if (!locationManager.prevState?.location.pathname) {
      navigate(defaultPrevRouteName)
      return
    }
    navigate(-1)
  }

  useEffect(() => {
    if (pageNumber) {
      service.setCurrentPageNumber(pageNumber)
    } else {
      navigate(routeName.student.generalStudentWorkbookList)
    }
  }, [pageNumber])

  useEffect(() => {
    if (service && commonRepo.studentId && studentWorkbookId && revisionId) {
      service.fetchSetWorkbookDetail(commonRepo.studentId, {
        studentWorkbookId,
        revisionId,
      })
    }
  }, [service, studentWorkbookId, revisionId, commonRepo.studentId])

  useEffect(() => {
    if (
      service &&
      service.workbookDetail &&
      commonRepo.studentId &&
      studentWorkbookId &&
      revisionId &&
      pageNumber
    ) {
      const _pageNumber = parseInt(pageNumber)
      if (!isNaN(_pageNumber)) {
        service.fetchSetProblemScoringOfCurrentPage({
          studentId: commonRepo.studentId,
          studentWorkbookId,
          revisionId,
          pageNumber: _pageNumber,
        })
      }
    }
  }, [service, service.workbookDetail, studentWorkbookId, revisionId, pageNumber])

  return (
    <S.StudentWorkbookDetailPage>
      <PortalRootLayoutHeader>
        <GlobalHeader
          prevLinkProps={{
            to: defaultPrevRouteName,
            onClick: (e) => {
              e.preventDefault()
              if (
                service.problemScoringViewOption &&
                !service.problemScoringViewOption.채점불가능 &&
                service.currentPage?.problemScoringColl &&
                service.currentPage.problemScoringColl.toScoredArr.length > 0
              ) {
                modalService.openModal(
                  <StudentLearningContentBackConfirmModal
                    defaultPrevRouteName={defaultPrevRouteName}
                    onSubmit={() => {
                      if (studentWorkbookId && revisionId) {
                        service.onSubmit({
                          studentWorkbookId,
                          revisionId,
                        })
                      }
                    }}
                  />,
                  {
                    modalName: '교재 - 채점 - 입력 내용 남았을 때 답안제출 확인',
                  },
                )
                return
              }
              navigateBack()
            },
          }}
          SlotCenter={
            service.workbookDetail
              ? `${service.workbookDetail?.round}회차 | ${service.workbookDetail?.workbook.title}`
              : ''
          }
        />
      </PortalRootLayoutHeader>
      <StudentWorkbookDetailBody />
    </S.StudentWorkbookDetailPage>
  )
}

export default observer(StudentWorkbookDetailPage)
