import { css } from '@emotion/react'
import { observer } from 'mobx-react'
import type { FC } from 'react'

import { colors } from '~/@common/styles'
import type { DefaultProps } from '~/@common/types'

type Props = {
  tagName?: 'div' | 'ul' | 'dl'
  borderRadiusType?: 'full' | 'flat-bottom' | 'flat-right' | 'flat-left' | 'none'
  isReady?: boolean
} & DefaultProps

const ContentBox: FC<Props> = ({
  tagName = 'div',
  children,
  className,
  borderRadiusType = 'full',
  isReady,
}) => {
  const Comp = tagName
  let borderRadius: string | number = 0

  switch (borderRadiusType) {
    case 'full':
      borderRadius = 14
      break
    case 'flat-bottom':
      borderRadius = '14px 14px 0 0'
      break
    case 'flat-left':
      borderRadius = '0 14px 14px 0'
      break
    case 'flat-right':
      borderRadius = ' 14px 0 0 14px'
      break
  }

  return (
    <Comp
      className={className}
      css={_Style}
      style={{
        borderRadius,
        backgroundColor: isReady ? colors.white : '#E6EAEE',
      }}
    >
      {children}
    </Comp>
  )
}

export default observer(ContentBox)

const _Style = css`
  background-color: ${colors.white};
  padding: 20px;
`
