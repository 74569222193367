import React from 'react'

import { StudentWorksheetTable } from './StudentWorksheetTable'
import { StudentWorksheetFilterOptions } from './StudentWorksheetTableFilterOptions'
import { StudentWorksheetSubTabGroup } from './StudentWorksheetTableSubTabGroup'

export const StudentWorksheetTableTabletView = () => {
  return (
    <>
      <div>
        <StudentWorksheetFilterOptions />
        <StudentWorksheetSubTabGroup />
      </div>
      <StudentWorksheetTable />
    </>
  )
}
