import { makeAutoObservable, toJS } from 'mobx'

import type { Point, RawPoint } from '../../types'
import { pointToRawPoint } from '../handwrittenNote.utils'
import type { HandwrittenNoteItem } from './HandwrittenNoteData'

export enum PathType {
  SubPixel = 0,
  NoSubPixel = 1,
}

export interface HandwrittenNotePathData {
  readonly id: number
  readonly points: RawPoint[]
  readonly width: number
  readonly color: string
  readonly pathType: PathType
  readonly useSubPixel?: boolean
  readonly createdAt?: number
  readonly updatedAt?: number
  readonly pathRect?: PathRect
  readonly pixelRatio?: number
}

export interface PathRect {
  x: number
  y: number
  width: number
  height: number
}

export default class HandwrittenNotePathItem
  implements HandwrittenNotePathData, HandwrittenNoteItem
{
  readonly id: HandwrittenNotePathData['id']
  readonly points: HandwrittenNotePathData['points'] = []
  readonly width: HandwrittenNotePathData['width']
  readonly color: HandwrittenNotePathData['color']
  readonly pathType: HandwrittenNotePathData['pathType']
  readonly useSubPixel?: HandwrittenNotePathData['useSubPixel']
  createdAt?: number
  updatedAt?: number
  pathRect?: PathRect
  isDeleted = false
  pixelRatio: number

  constructor(
    id: number,
    points: RawPoint[],
    width: number,
    color: string,
    pathType: PathType,
    useSubPixel?: boolean,
    pathRect?: PathRect,
    createdAt?: number,
    updatedAt?: number,
    pixelRatio?: number,
  ) {
    this.id = id
    this.points = points
    this.width = width
    this.color = color
    this.pathType = pathType
    this.useSubPixel = useSubPixel
    this.createdAt = createdAt
    this.updatedAt = updatedAt
    this.pathRect = pathRect
    this.pixelRatio = pixelRatio ?? 1

    makeAutoObservable(this)
  }

  setIsDeleted(value: boolean) {
    this.isDeleted = value
  }

  setCreatedAt(value: number) {
    this.createdAt = value
  }

  addPoint(point: Point) {
    this.points.push(pointToRawPoint(point))
    this.updatedAt = new Date().getTime()
  }

  undo() {
    this.setIsDeleted(true)
  }

  redo() {
    this.setIsDeleted(false)
  }

  toData() {
    return {
      id: this.id,
      color: this.color,
      width: this.width,
      points: toJS(this.points),
      pathType: this.pathType,
      useSubPixel: this.useSubPixel,
      createdAt: this.createdAt,
      updatedAt: this.updatedAt,
      pathRect: toJS(this.pathRect),
      pixelRatio: this.pixelRatio,
    }
  }

  setPathRect() {
    if (this.isDeleted) {
      return
    }
    let minX = Number.MAX_SAFE_INTEGER
    let maxX = Number.MIN_SAFE_INTEGER
    let minY = Number.MAX_SAFE_INTEGER
    let maxY = Number.MIN_SAFE_INTEGER

    for (const [x, y] of this.points) {
      minX = Math.min(minX, x / this.pixelRatio)
      maxX = Math.max(maxX, x / this.pixelRatio)
      minY = Math.min(minY, y / this.pixelRatio)
      maxY = Math.max(maxY, y / this.pixelRatio)
    }
    this.pathRect = {
      x: minX,
      y: minY,
      width: maxX - minX,
      height: maxY - minY,
    }
  }

  static fromData(data: HandwrittenNotePathData) {
    const pathItem = new HandwrittenNotePathItem(
      data.id,
      data.points,
      data.width,
      data.color,
      data.pathType,
      data.useSubPixel,
      data.pathRect,
      data.createdAt,
      data.updatedAt,
      data.pixelRatio,
    )
    return pathItem
  }
}
