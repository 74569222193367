import type { MathflatApi } from '@mathflat/domain/@entities/Login/api'
import * as Sentry from '@sentry/react'
import { isAxiosError } from 'axios'

const UnauthorizedUserID = 'Unauthorized User'

class _Logger {
  setUnauthorizedUser() {
    Sentry.setUser({ id: UnauthorizedUserID })
  }
  setAuthorizedUser({
    relationId,
    academyId,
    userType,
  }: Pick<MathflatApi.Response.Login, 'academyId' | 'relationId' | 'userType'>) {
    Sentry.setUser({ id: relationId, academyId, userType })
  }

  onAxiosInterceptorResError(error: any) {
    if (!isAxiosError(error)) return

    const { method, baseURL, url, params, data: configData, headers } = error?.config ?? {}
    const { data: responseData, status } = error?.response ?? {}

    const headerData = { 'x-platform': headers?.['x-platform'], token: headers?.['x-auth-token'] }

    Sentry.setContext('API Request Detail', {
      method,
      baseURL,
      url,
      params,
      data: configData,
      headers,
      ...headerData,
    })

    Sentry.setContext('API Response Detail', {
      data: responseData,
      status,
    })

    Sentry.withScope((scope) => {
      const method = error.config?.method
      const url = error.config?.url
      const status = error.response?.status.toString()

      if (method && url && status) {
        scope.setFingerprint([method, url, status])
      }

      if (!status) {
        scope.setLevel('fatal')
        scope.setTags({ url, status: 'NETWORK_ERROR' })
      } else {
        scope.setTags({ url, status })
      }

      Sentry.captureException(error)
    })
  }
}

export const Logger = new _Logger()
