import {
  STUDENT_WORKBOOK_LIST_TYPE,
  type StudentWorkbookDetailPathParamT,
  studentWorkbookListPathParams,
} from '~/@entities/StudentWorkbook/StudentWorkbook.schema'

export const studentWorkbookDetailPathParams: Record<
  keyof StudentWorkbookDetailPathParamT,
  keyof StudentWorkbookDetailPathParamT
> = {
  studentWorkbookId: 'studentWorkbookId',
  revisionId: 'revisionId',
  pageNumber: 'pageNumber',
} as const

// 학생 id로 사용할 수 있는 route는 /student로, 학부모 id로 사용할 수 있는 route는 /parent로 시작
export const routeName = {
  login: '/', // 로그인

  profile: '/profile',

  // 학습지
  worksheet: {
    defaultPath: `/worksheet`,
    // 미출제된 학습지를 출제하기 위한 페이지
    get scoring() {
      return `${this.defaultPath}/:worksheetId/:dateTime/scoring` as const
    },
  },

  // 학생
  student: {
    defaultPath: `/student`,

    get challenge() {
      return `${this.defaultPath}/challenge`
    },
    get studentWorkbook() {
      return `${this.defaultPath}/student-workbook`
    },
    get studentWorkbookList() {
      return `${this.defaultPath}/student-workbook/list/:${studentWorkbookListPathParams.studentWorkbookListType}`
    },
    get generalStudentWorkbookList() {
      return `${this.defaultPath}/student-workbook/list/${STUDENT_WORKBOOK_LIST_TYPE.일반교재}`
    },
    get customSignatureStudentWorkbookList() {
      return `${this.defaultPath}/student-workbook/list/${STUDENT_WORKBOOK_LIST_TYPE.시그니처교재}`
    },
    get studentWorkbookDetail() {
      return `${this.defaultPath}/student-workbook/detail/:${studentWorkbookDetailPathParams.studentWorkbookId}/:${studentWorkbookDetailPathParams.revisionId}/:${studentWorkbookDetailPathParams.pageNumber}`
    },
    get studentWorkbookViewer() {
      return `${this.defaultPath}/student-workbook/viewer/:${studentWorkbookDetailPathParams.studentWorkbookId}/:${studentWorkbookDetailPathParams.revisionId}/:${studentWorkbookDetailPathParams.pageNumber}`
    },
    get studentWorksheet() {
      return `${this.defaultPath}/student-worksheet` as const
    },
    get studentWorksheetById() {
      return `${this.defaultPath}/student-worksheet/:studentWorksheetId`
    },
    get studentWorksheetScoringById() {
      return `${this.defaultPath}/student-worksheet/:studentWorksheetId/scoring`
    },
    get studentExamScoringById() {
      return `${this.defaultPath}/student-exam/:studentExamId/scoring`
    },
    get lecture() {
      return `${this.defaultPath}/lecture`
    },
    get lectureList() {
      return `${this.defaultPath}/lecture/list`
    },
    get lectureDetail() {
      return `${this.defaultPath}/lecture/:lectureId`
    },
    get signatureWorkbookLectureList() {
      return `${this.defaultPath}/lecture/signature-workbook/list` // 시그니처 교재 전용 강의 리스트
    },
    get signatureWorkbookLectureDetail() {
      return `${this.defaultPath}/lecture/signature-workbook/:lectureId` // 시그니처 교재 전용 강의 리스트
    },
    get conceptualLearning() {
      return `${this.defaultPath}/conceptual-learning/:studentWorksheetId`
    },
    get conceptLearning() {
      // conceptualLearning으로 redirect, 삭제 예정
      return `${this.defaultPath}/concept-learning/:studentWorksheetId`
    },
  },

  // 학생-학습지
  // studentWorksheetList: '/student/student-worksheet', // 학습지 리스트
  // studentWorksheet: '/student/worksheet/:studentWorksheetId', // 학습지
  // studentWorksheetScoring: '/student/worksheet/:studentWorksheetId/scoring', // 학습지 채점 결과&학습지 빠른 채점
  // worksheetScoringByQR: '/worksheet/:worksheetId/:dateTime/scoring', // qr을 통한 학습지 빠른 채점
  // studentExam: '/student-exam/:studentExamId', // 시험지
  // studentExamScoring: '/student-exam/:studentExamId/scoring', // 시험지 채점 결과&시험지 빠른 채점

  // 학생-자기주도학습
  studentLearningProcess: '/student/learning-process/:studentWorksheetId',

  // 학부모
  parent: '/parent',

  // passwordChange: '/profile/password-change', // 내 정보 - 비밀번호 변경
  // parentNotice: '/profile/parent-notice', // (학부모) 내 정보 - 학원 공지

  report: '/report', // (학부모) 보고서

  qr: '/qr', // qr 접근용 페이지
  guideVideo: '/guide-video', // 가이드 동영상 보기 페이지
  serverMaintenance: '/server-maintenance', // 서버 점검 페이지
} as const

export const fullPageRoutes = {
  has(pathname: string) {
    return new Set<string>([routeName.login]).has(pathname)
  },
}
