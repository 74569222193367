import { css } from '@emotion/react'
import type { ConceptChipDomain } from '@mathflat/domain/@entities/ConceptChip/domain.ts'
import { observer } from 'mobx-react'

import { useRepository } from '~/@common/hooks/useRepository'
import { fontWeight } from '~/@common/styles'
import { mediaQuery } from '~/@common/styles/mediaQuery'
import ConceptChipAchievementLevel from '~/@pages/@common/(ConceptChip)/ConceptChipAchievmentLevel/ConceptChipAchievementLevel.tsx'
import { ChallengeStore } from '~/@pages/student/@common/store/Challenge.store.ts'

export type ItemProps = {
  conceptChipId: number
  conceptName: string
  recommended: boolean
  levelOfAchievement: ConceptChipDomain.LevelOfAchievement
}

export type Props = {
  difficultyLevel: ConceptChipDomain.LevelOfDifficulty
  items: ItemProps[]
  handleChange: (conceptId: number) => void
}

export const kind = 'ms__ConceptChipOfDifficultyLevel' as const
export const parts = {
  title: `${kind}_title`,
  contents: `${kind}_contents`,
} as const

const ConceptChipOfDifficultyLevel = ({ handleChange, difficultyLevel, items }: Props) => {
  const store = useRepository(ChallengeStore)

  return (
    <div css={_css} data-component={kind}>
      <div className={parts.title}>
        <b>{difficultyLevel}</b>
        <span>{items.length}개</span>
      </div>
      <div className={parts.contents}>
        {items.map(({ conceptChipId, recommended, levelOfAchievement }) => {
          const isChecked =
            store.selectedChallenge?.type === 'conceptChip' &&
            store.selectedChallenge.id === conceptChipId
          return (
            <ConceptChipAchievementLevel
              key={conceptChipId}
              type="checkbox"
              level={levelOfAchievement}
              checked={isChecked}
              onChange={() => handleChange(conceptChipId)}
              recommended={recommended}
              size={30}
            />
          )
        })}
      </div>
    </div>
  )
}

export default observer(ConceptChipOfDifficultyLevel)

const _css = css`
  .${parts.title} {
    padding: 16px 12px;
  }
  .${parts.title} b {
    font-weight: ${fontWeight.bold};
    margin-right: 4px;
  }
  .${parts.contents} {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(30px, 30px));
    grid-gap: 6px;
    grid-auto-flow: row;
    padding: 0 12px 12px 12px;
  }

  ${mediaQuery.underTablet} {
    .${parts.title} {
      padding: 10px 16px 4px;
    }
    .${parts.contents} {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(30px, 30px));
      grid-gap: 6px 10px;
      grid-auto-flow: row;
      padding: 10px 16px 14px;
    }
  }
`
