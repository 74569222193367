import { css } from '@emotion/react'
import { useComposedRefs } from '@mathflat/design-system/@common/utils/composeRefs.tsx'
import clsx from 'clsx'
import type { ComponentProps } from 'react'
import React, { useRef } from 'react'

import { colors, typo } from '~/@common/styles'
import { Icon } from '~/@common/ui/Icon/Icon.tsx'

import IconButton from '../../(Button)/IconButton/IconButton'

export const kind = 'ms__Input' as const
export const parts = {
  input: `${kind}_input`,
  eyeButton: `${kind}_eyeButton`,
  initButton: `${kind}_initButton`,
} as const

export interface Props extends ComponentProps<'input'> {
  height?: React.CSSProperties['height']
  hasInitIconButton?: boolean
  hasError?: boolean
}

const changeEvent = new Event('change', { bubbles: true })

const Input = React.forwardRef<HTMLInputElement, Props>(
  ({ height = '40px', hasInitIconButton = false, style, type, hasError, ...props }, ref) => {
    const [isPasswordVisible, setPasswordVisible] = React.useState(false)
    const togglePasswordVisible = () => {
      setPasswordVisible(!isPasswordVisible)
    }
    const _ref = useRef<HTMLInputElement | null>(null)

    const composeRefs = useComposedRefs(ref, _ref)
    return (
      <div css={_css} style={style}>
        <input
          className={clsx(parts.input, hasError && 'error')}
          type={type === 'password' ? (isPasswordVisible ? 'text' : 'password') : type}
          style={{ height }}
          ref={composeRefs}
          {...props}
        />
        {type === 'password' && (
          <button className={parts.eyeButton} type="button" onClick={togglePasswordVisible}>
            {isPasswordVisible ? (
              <Icon name="icon_eye_opened" size={20} color={colors.gray.$900} />
            ) : (
              <Icon name="icon_eye_closed" size={20} color={colors.gray.$500} />
            )}
          </button>
        )}
        {hasInitIconButton && (
          <IconButton
            name="icon_close"
            className={parts.initButton}
            style={{
              border: 'none',
              background: 'transparent',
            }}
            iconSize={20}
            iconStyle={{
              color: colors.gray.$400,
            }}
            onClick={() => {
              if (!_ref.current) return
              _ref.current.value = ''
              _ref.current?.dispatchEvent(changeEvent)
              _ref.current.focus()
            }}
          />
        )}
      </div>
    )
  },
)
Input.displayName = 'Input'

export default Input

const _css = css`
  flex: auto;
  position: relative;

  .${parts.input} {
    position: relative;
    width: 100%;
    padding: 11px 13px;
    border-radius: 4px;
    border: 1px solid ${colors.gray.$400};
    outline: none;
    color: ${colors.gray.$900};
    appearance: none; //아이패드 기본 input 스타일 삭제
    ${typo.caption01}

    &::placeholder {
      ${typo.caption01};
      color: ${colors.gray.$500};
    }

    &:focus {
      border-color: ${colors.gray.$500};
    }

    &[type='number'] {
      padding-right: 0;
    }

    &.error {
      border-color: ${colors.red.$400};
      &:focus {
        border-color: ${colors.red.$400};
      }
    }
  }

  .${parts.eyeButton} {
    position: absolute;
    top: 50%;
    right: 12px;
    display: flex;
    transform: translateY(-50%);
  }

  input:placeholder-shown ~ .${parts.initButton} {
    display: none;
  }
`
