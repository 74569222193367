import styled from '@emotion/styled'

import { colors, typo } from '~/@common/styles'
import { mediaQuery } from '~/@common/styles/mediaQuery.tsx'

export const S = {
  Card: styled.div`
    min-width: 384px;
    height: fit-content;
    background-color: ${colors.white};
    border-radius: 14px;

    ${mediaQuery.underTablet} {
      min-width: unset;
    }

    .card__top {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 8px;
      padding: 26px 32px;
      border-bottom: 1px solid ${colors.gray.$200};
      > p {
        ${typo.body02}
        color: ${colors.gray.$800};
      }
      > h2 {
        ${typo.heading04}
        color: ${colors.gray.$900};
        font-weight: 700;
      }
    }

    .card__middle {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 5px;
      padding: 25px 0;
      border-bottom: 1px solid ${colors.gray.$200};

      .middle__title {
        display: flex;
        justify-content: center;
        width: 57px;
        color: ${colors.gray.$700};
        ${typo.caption01}
      }
      .middle__totalScore {
        ${typo.heading05}
        color: ${colors.gray.$900};
      }
    }
    .card__bottom {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 10px;
      padding: 20px 16px;
    }
    .top__font-blue {
      color: ${colors.blue.$500};
      margin: 0 4px;
    }
    .active-blue {
      background-color: #eef2ff;
    }

    &.card-conceptual-learning {
      .card__top {
        padding-left: 0;
        padding-right: 0;
      }
      .card__bottom {
        padding-left: 0;
        padding-right: 0;

        ${mediaQuery.underTablet} {
          padding-left: 16px;
          padding-right: 16px;
        }
      }
    }
  `,
  Brick: styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 12px 0;
    background-color: ${colors.gray.$100};
    border-radius: 12px;
    .title {
      margin-bottom: 4px;
      color: ${colors.gray.$700};
      ${typo.caption01}
    }
    .value {
      ${typo.heading05}
    }
  `,
}
