import { makeAutoObservable } from 'mobx'

import { remoteStorageService } from '~/@common/services/remoteStorage.service.ts'
import { commonRepo } from '~/@common/services/repo.service.ts'
import type { BaseExternalData } from '~/@common/types'
import type {
  RequiredWorkbookInfo,
  SearchWorkbookType,
} from '~/@pages/@widgets/WorkbookSearchModal/WorkbookSearch.service.ts'

import type {
  ChallengeFilter,
  ChallengeFilterV2,
  ChallengeType,
  ChallengeWorkbookFilterOptions,
  WorkbooksByCurriculumKey,
} from './ChallengeStore.type'

type ChallengeFilterExternal = BaseExternalData<typeof ChallengeStore.REMOTE_STORAGE_KIND> &
  ChallengeFilter
type ChallengeFilterExternalV2 = BaseExternalData<typeof ChallengeStore.REMOTE_STORAGE_KIND> &
  ChallengeFilterV2

export const DEFAULT_WORKBOOK_SEARCH_TAB_TYPE = 'CUSTOM_SIGNATURE' as const

export class ChallengeStore {
  static readonly REMOTE_STORAGE_VERSION = '0.0.4'
  static readonly REMOTE_STORAGE_KIND = 'ms__ChallengeStore'
  private static _latestTimestamp = 0

  private _latestExternalData: ChallengeFilterExternalV2 | null = null
  private _curriculumKey: string | null = null
  private _workbooksByCurriculumKey: WorkbooksByCurriculumKey = {}
  private _searchText = ''

  private _selectedChallenge: ChallengeType | null = null
  private _onlyRecommended = false

  get curriculumKey() {
    return this._curriculumKey
  }

  get workbooksByCurriculumKey() {
    return { ...this._workbooksByCurriculumKey }
  }

  get selectedWorkbooksByCurriculumKey(): ChallengeWorkbookFilterOptions | undefined {
    const curriculumKey = this._curriculumKey
    if (!curriculumKey) return

    return { ...this._workbooksByCurriculumKey[curriculumKey] }
  }

  get selectedWorkbookType(): SearchWorkbookType {
    return this.selectedWorkbooksByCurriculumKey?.workbookType ?? DEFAULT_WORKBOOK_SEARCH_TAB_TYPE
  }

  get workbooks() {
    return this.selectedWorkbooksByCurriculumKey?.workbooks
      ? [...this.selectedWorkbooksByCurriculumKey.workbooks]
      : []
  }

  get searchText() {
    return this._searchText
  }

  get selectedChallenge() {
    return this._selectedChallenge
  }

  get onlyRecommended() {
    return this._onlyRecommended
  }

  constructor() {
    this.clearSelectedChallenge = this.clearSelectedChallenge.bind(this)
    makeAutoObservable(this)
  }

  setSelectedChallenge(challenge: ChallengeType) {
    const isSameType = this._selectedChallenge?.type === challenge.type
    if (isSameType) {
      if (this._selectedChallenge?.id === challenge.id) {
        this._selectedChallenge = null
        return
      }
    }
    this._selectedChallenge = challenge
  }

  setCurriculumWorkbooks({
    curriculumKey,
    workbookType,
    workbooks,
    searchText,
  }: {
    curriculumKey: string
    workbookType: SearchWorkbookType
    workbooks: RequiredWorkbookInfo[]
    searchText: string
  }) {
    this._curriculumKey = curriculumKey
    this._workbooksByCurriculumKey[this._curriculumKey] = {
      workbookType,
      workbooks,
    }
    this._searchText = searchText

    this._onlyRecommended = false
    this._selectedChallenge = null
  }

  toggleRecommended() {
    this._onlyRecommended = !this._onlyRecommended
  }

  clearSelectedChallenge() {
    this._selectedChallenge = null
  }

  private _toExternal() {
    if (!this._curriculumKey) {
      if (commonRepo.studentDefaultCurriculumKey) {
        this._curriculumKey = commonRepo.studentDefaultCurriculumKey
      } else {
        throw Error('toExternal(): curriculumKey is null')
      }
    }

    ChallengeStore._latestTimestamp = Date.now()

    this._latestExternalData = {
      __version: ChallengeStore.REMOTE_STORAGE_VERSION,
      __timestamp: ChallengeStore._latestTimestamp,
      __kind: ChallengeStore.REMOTE_STORAGE_KIND,
      curriculumKey: this._curriculumKey,
      workbooksByCurriculumKey: this._workbooksByCurriculumKey,
      searchText: this._searchText,
    }

    // TODO: 다른 모든 External 데이터 형식 통일했으면 좋겠다.
    return this._latestExternalData
  }

  private _fromExternal(input: ChallengeFilterExternalV2) {
    // 받은 데이터가 현재 데이터보다 오래된 데이터면 무시
    if (input.__timestamp <= ChallengeStore._latestTimestamp) {
      return
    }
    if (input.__version !== ChallengeStore.REMOTE_STORAGE_VERSION) {
      console.error('version mismatch', input.__version, ChallengeStore.REMOTE_STORAGE_VERSION)
    }

    this._curriculumKey = input.curriculumKey
    this._workbooksByCurriculumKey = input.workbooksByCurriculumKey
    this._searchText = input.searchText
  }

  private async migrateExternalData(data: ChallengeFilterExternal | ChallengeFilterExternalV2) {
    if (data.__version === ChallengeStore.REMOTE_STORAGE_VERSION) {
      return data as ChallengeFilterExternalV2
    }

    if (data.__version === '0.0.3') {
      console.warn(
        `지원하지 않는 버전입니다.\n마이그레이션을 진행합니다.\n\n현재 버전: ${data.__version}\n업데이트 버전: ${ChallengeStore.REMOTE_STORAGE_VERSION}`,
      )

      const oldData = { ...data } as ChallengeFilterExternal
      const newData: ChallengeFilterExternalV2 = {
        __version: ChallengeStore.REMOTE_STORAGE_VERSION,
        __timestamp: Date.now(),
        __kind: ChallengeStore.REMOTE_STORAGE_KIND,
        curriculumKey: data.curriculumKey,
        searchText: data.searchText,
        workbooksByCurriculumKey: {},
      }

      if (data.curriculumKey) {
        newData.workbooksByCurriculumKey[data.curriculumKey] = {
          workbookType: oldData.selectedWorkbookType ?? DEFAULT_WORKBOOK_SEARCH_TAB_TYPE,
          workbooks: oldData.workbooks,
        }

        await remoteStorageService.patch(ChallengeStore.REMOTE_STORAGE_KIND, newData)
        return newData
      }
    }

    return data as ChallengeFilterExternalV2
  }

  saveToChallengeRemoteStorage() {
    remoteStorageService.patch(ChallengeStore.REMOTE_STORAGE_KIND, this._toExternal())
  }

  async loadFromChallengeRemoteStorage() {
    const _data = await remoteStorageService.get(ChallengeStore.REMOTE_STORAGE_KIND)
    const storeData = _data[ChallengeStore.REMOTE_STORAGE_KIND]

    // 챌린지 최초 진입시 리모트 스토리지에 저장한 데이터가 없으면 기본값으로 설정
    if (!storeData) {
      if (commonRepo.studentDefaultCurriculumKey) {
        this._curriculumKey = commonRepo.studentDefaultCurriculumKey
      }
      return
    }

    const externalData = await this.migrateExternalData(storeData)
    this._fromExternal(externalData)
  }
}
