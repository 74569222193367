import type { MathflatApi } from '@mathflat/domain/@entities/StudentWorksheet/api2'

import maxios from '../utils/maxios'

export const studentWorksheetApi = (() => {
  return {
    getStudentWorksheetWithHomeworks: (
      studentId: string,
      params: {
        startDate: string
        endDate: string
      },
    ) =>
      maxios.get<MathflatApi.Response.StudentWorksheet[]>(
        `/student-worksheet/${studentId}/homeworks`,
        {
          params,
        },
      ),
  }
})()
