import { css } from '@emotion/react'

import { colors, colorTheme } from '~/@common/styles'
import type { RadioGroupInputProps } from '../group/RadioGroupInput'
import { radioGroupItemFactory } from '../group/RadioGroupItem'

export const ChipRadioGroupItem = (props: RadioGroupInputProps) =>
  radioGroupItemFactory(
    props,
    'ChipRadioGroupItem',
    css`
      label {
        cursor: pointer;
        width: 37px;
        height: 39px;
        border-radius: 8px;
        padding: 9px 12px;
        background-color: ${colors.white};
        border: 1px solid ${colors.gray.$400};
      }

      input:checked + label {
        background-color: ${colors.blue.$110};
        border-color: ${colorTheme.primary};
      }
    `,
  )
