import { css } from '@emotion/react'
import type { FC, MouseEvent } from 'react'
import { useEffect, useRef, useState } from 'react'

import { useDraggable } from '~/@common/hooks/useDraggable'

const containerPadding = 20

const DraggableTestPage: FC = () => {
  const [isReady, setIsReady] = useState(false)
  const [isShowContent, setIsShowContent] = useState(true)
  const containerRef = useRef<HTMLDivElement>(null)
  const { isDragging, dragDelta, draggableRef } = useDraggable<HTMLDivElement>({
    containerEl: containerRef.current,
    containerPadding,
  })
  const timerRef = useRef(0)

  const handleContentToggle = () => {
    setIsShowContent((value) => !value)
  }

  const handleClick = (e: MouseEvent<HTMLButtonElement>) => {
    const targetEl = e.currentTarget
    targetEl.style.backgroundColor = 'blue'
    window.clearTimeout(timerRef.current)
    timerRef.current = window.setTimeout(() => {
      targetEl.style.backgroundColor = 'white'
    }, 50)
  }

  useEffect(() => {
    setIsReady(true)
  }, [])

  return (
    <div css={_Style}>
      <div>
        <button type="button" onClick={handleContentToggle}>
          toggle content
        </button>
      </div>
      <div ref={containerRef} className="container">
        {isShowContent && isReady && (
          <div
            ref={draggableRef}
            className="content"
            style={{
              position: 'absolute',
              left: `${containerPadding}px`,
              top: `${containerPadding}px`,
              transform: `translate(${dragDelta.x}px, ${dragDelta.y}px)`,
              outline: isDragging ? '2px solid blue' : 'none',
            }}
          >
            <button type="button" onClick={handleClick}>
              inner button
            </button>
          </div>
        )}
      </div>
    </div>
  )
}

export default DraggableTestPage

const _Style = css`
  .container {
    width: 800px;
    height: 600px;
    background-color: #888;
    position: relative;
  }

  .content {
    width: 200px;
    height: 100px;
    background: red;
    display: flex;
    align-items: center;
    justify-content: center;
    user-select: none;
    touch-action: none;
  }

  button {
    padding: 8px;
    border: 1px solid #333;
    background-color: white;
  }
`
