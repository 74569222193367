import { css } from '@emotion/react'
import styled from '@emotion/styled'
import clsx from 'clsx'
import type { ComponentProps } from 'react'

import { activeCSS, colors } from '~/@common/styles'
import type { DefaultProps } from '~/@common/types/props'
import SimpleBar from 'simplebar-react'
import 'simplebar-react/dist/simplebar.min.css'

type RowProps = {
  ratio?: string
} & DefaultProps

/**
 * @example
 * ratio는 grid의 비율단위입니다.
 *
 * const TableExample = () => {
 *   return (
 *    <Table>
 *      <Table.Header ratio="1fr 2fr 1fr auto">
 *        {headerRows.map((row) => (
 *         <Table.Item>{row}</Table.Item>
 *        ))}
 *      </Table.Header>
 *      <Table.Body>
 *        {dataRows.map((row) => (
 *          <Table.Row ratio="100px 100px 200px 100px">
 *            <Table.Item>{row.data1}</Table.Item>
 *            <Table.Item>{row.data2}</Table.Item>
 *            <Table.Item>{row.data3}</Table.Item>
 *          </Table.Row>
 *        ))}
 *      </Table.Body>
 *    </Table>
 *  )
 * }
 */

export const tableRowStyle = css`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(50px, 1fr));
  padding: 14px;
  border-bottom: 1px solid ${colors.gray.$200};
  cursor: pointer;
  ${activeCSS};
`

const Table = ({
  children,
  className,
  style,
  ...props
}: DefaultProps & ComponentProps<'table'>) => {
  return (
    <S.Table className={className} style={style} {...props}>
      {children}
    </S.Table>
  )
}

const Header = ({ children, className, ratio, ...props }: RowProps & ComponentProps<'thead'>) => {
  return (
    <S.Header className={clsx('ms-component-table-header', className)} {...props}>
      <S.HeaderRow style={{ gridTemplateColumns: ratio }}>{children}</S.HeaderRow>
    </S.Header>
  )
}

const Body = ({ children, className, style, ...props }: DefaultProps & ComponentProps<'tbody'>) => {
  return (
    <S.Body className={className} style={style} {...props}>
      <SimpleBar style={{ maxHeight: '100%', height: '100%' }}>{children}</SimpleBar>
    </S.Body>
  )
}

const Row = ({ children, className, style, ratio, ...props }: RowProps & ComponentProps<'tr'>) => {
  return (
    <S.Row className={className} style={{ ...style, gridTemplateColumns: ratio }} {...props}>
      {children}
    </S.Row>
  )
}

const Item = ({ children, className, style, ...props }: DefaultProps & ComponentProps<'td'>) => {
  return (
    <S.Item className={className} style={style} {...props}>
      {children}
    </S.Item>
  )
}
const HeaderItem = ({
  children,
  className,
  alignStart,
  ...props
}: ComponentProps<'th'> & { alignStart?: boolean }) => {
  return (
    <S.HeaderItem className={clsx(alignStart && 'align-start', className)} {...props}>
      {children}
    </S.HeaderItem>
  )
}

Table.Header = Header
Table.Body = Body
Table.Row = Row
Table.Item = Item
Table.HeaderItem = HeaderItem

export default Table

const S = {
  Table: styled.table`
    display: flex;
    flex-direction: column;
    border-radius: 14px;
    background-color: ${colors.white};
  `,
  Header: styled.thead`
    background-color: ${colors.gray.$100};
    border-top-left-radius: 14px;
    border-top-right-radius: 14px;
  `,
  HeaderRow: styled.tr`
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(50px, 1fr));
    padding: 16px 14px;
    font-weight: 700;
    color: ${colors.gray.$600};
  `,
  Body: styled.tbody`
    height: 100%;
    overflow: hidden;
    color: ${colors.gray.$900};
  `,
  Row: styled.tr`
    ${tableRowStyle};
  `,
  Item: styled.td`
    display: inline-flex;
    justify-content: center;
    align-items: center;
  `,
  HeaderItem: styled.th`
    display: inline-flex;
    justify-content: center;
    align-items: center;
    &.align-start {
      justify-content: flex-start;
    }
  `,
}
