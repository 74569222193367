import { makeAutoObservable } from 'mobx'

import { PIXEL_RATIO } from '../constants'
import { getZeroPoint, type Point, type Size } from '../types'
import type { FitMode } from './scalableContent.types'

interface PinchScale {
  current: number
  global: number
}

interface Translation {
  current: Point
  global: Point
}

const defaultPinchScale = { current: 1, global: 1 }
const defaultTranslation = { current: getZeroPoint(), global: getZeroPoint() }

export default class ScalableContentService {
  fitMode: FitMode = 'exact'
  containerSize?: Size = undefined
  contentSize?: Size = undefined
  private containerPadding = 0
  zoomLevel = 1
  private pinchCenter?: Point = undefined
  private pinchScale: PinchScale
  private translation: Translation
  transitionDelay = 0

  constructor(fitMode?: FitMode, containerPadding?: number) {
    if (fitMode) {
      this.fitMode = fitMode
    }
    if (containerPadding !== undefined) {
      this.containerPadding = containerPadding
    }
    this.pinchScale = { ...defaultPinchScale }
    this.translation = { ...defaultTranslation }

    makeAutoObservable(this)
  }

  get contentOrientation() {
    let orientation: 'landscape' | 'portrait' = 'landscape'

    if (!this.containerSize || !this.contentSize) {
      return orientation
    }
    const containerSizeRatio = this.containerSize.width / this.containerSize.height
    const contentSizeRatio = this.contentSize.width / this.contentSize.height

    if (containerSizeRatio > contentSizeRatio) {
      orientation = 'portrait'
    }
    return orientation
  }

  get isDefaultPinchScale() {
    return this.pinchScale.global === defaultPinchScale.global
  }

  get isDefaultTranslation() {
    return (
      this.translation.global.x === defaultTranslation.global.x &&
      this.translation.global.y === defaultTranslation.global.y
    )
  }

  get defaultScale() {
    const scale = 1

    if (this.fitMode === 'exact') {
      return scale
    }

    if (!this.containerSize || !this.contentSize) {
      return scale
    }

    switch (this.fitMode) {
      case 'center': {
        if (this.contentOrientation === 'portrait') {
          return (this.containerSize.height - this.containerPadding * 2) / this.contentSize.height
        } else {
          return (this.containerSize.width - this.containerPadding * 2) / this.contentSize.width
        }
      }
      case 'fit-height':
        return this.containerSize.height / this.contentSize.height
      case 'fit-width':
        return this.containerSize.width / this.contentSize.width
      default:
        return scale
    }
  }

  get scaledContentSize() {
    if (!this.contentSize) {
      return { width: 0, height: 0 }
    }
    const width = this.contentSize.width * this.defaultScale * this.zoomLevel
    const height = (width * this.contentSize.height) / this.contentSize.width
    return {
      width,
      height,
    }
  }

  get renderedContentSize() {
    if (!this.contentSize) {
      return { width: 0, height: 0 }
    }
    return {
      width: Math.round(this.scaledContentSize.width * this.transformScale),
      height: Math.round(this.scaledContentSize.height * this.transformScale),
    }
  }

  get transformScale() {
    return this.pinchScale.current * this.pinchScale.global
  }

  get translatePoint() {
    return {
      x: this.translation.global.x + this.translation.current.x,
      y: this.translation.global.y + this.translation.current.y,
    }
  }

  get transformString() {
    const isTransforming =
      this.pinchScale.current !== 1 || this.translation.current.x || this.translation.current.y

    if (isTransforming) {
      return `translate3d(${this.translatePoint.x}px, ${this.translatePoint.y}px, 0) scale(${this.transformScale})`
    } else {
      return `translate(${this.translatePoint.x}px, ${this.translatePoint.y}px) scale(${this.transformScale})`
    }
  }

  setFitMode(value: FitMode) {
    this.fitMode = value

    if (!this.contentSize || !this.containerSize) {
      return
    }

    let global = getZeroPoint()
    const current = getZeroPoint()

    if (['fit-width', 'fit-height'].includes(value)) {
      if (value === 'fit-height') {
        const contentWidth = this.contentSize.width * this.defaultScale
        if (contentWidth < this.containerSize.width) {
          global = {
            x: (this.containerSize.width - contentWidth) / 2,
            y: 0,
          }
        }
      }
      this.setTranslation({
        global,
        current,
      })
      this.setPinchScale({
        global: 1,
        current: 1,
      })
      this.setContentCenter(0)
    }
  }

  setContainerSize(value: Size) {
    this.containerSize = value
  }

  setContainerPadding(value: number) {
    this.containerPadding = value
  }

  setContentSize(value: Size | undefined) {
    this.contentSize = value
  }

  setZoomLevel(value: number) {
    this.zoomLevel = value
    this.setContentCenter(0)
  }

  setPinchCenter(value: Point | undefined) {
    this.pinchCenter = value
  }

  setPinchScale(value: Partial<PinchScale>) {
    this.pinchScale = {
      ...this.pinchScale,
      ...value,
    }
  }

  setTranslation(value: Partial<Translation>, delay = 0) {
    if (delay) {
      this.setTransitionDelay(delay)
    }
    this.translation = {
      ...this.translation,
      ...value,
    }
  }

  setTransitionDelay(value: number) {
    this.transitionDelay = value
  }

  resetScaleAndTranslation() {
    this.zoomLevel = 1
    this.pinchScale = { ...defaultPinchScale }
    this.translation = { ...defaultTranslation }
    this.setContentCenter(0)
  }

  setContentCenter(delay = 200) {
    if (!this.containerSize || !this.contentSize) {
      return
    }

    const lastTranslation = {
      ...this.translation.global,
    }
    const translation = {
      ...this.translation.global,
    }

    if (this.renderedContentSize.width <= this.containerSize.width) {
      translation.x = (this.containerSize.width - this.renderedContentSize.width) / 2
    } else if (translation.x > 0) {
      translation.x = 0
    } else if (translation.x < this.containerSize.width - this.renderedContentSize.width) {
      translation.x = this.containerSize.width - this.renderedContentSize.width
    }

    if (this.renderedContentSize.height <= this.containerSize.height) {
      translation.y = (this.containerSize.height - this.renderedContentSize.height) / 2
    } else if (translation.y > 0) {
      translation.y = 0
    } else if (translation.y < this.containerSize.height - this.renderedContentSize.height) {
      translation.y = this.containerSize.height - this.renderedContentSize.height
    }

    if (lastTranslation.x !== translation.x || lastTranslation.y !== translation.y) {
      this.setTranslation(
        {
          global: translation,
        },
        delay,
      )
    }
  }

  handlePan(deltaX: number, deltaY: number) {
    this.setTranslation({ current: { x: deltaX, y: deltaY } })
  }

  handlePanEnd() {
    const global = {
      x: this.translation.global.x + this.translation.current.x,
      y: this.translation.global.y + this.translation.current.y,
    }
    this.setTranslation({
      current: getZeroPoint(),
      global,
    })
    this.setContentCenter()
  }

  handlePinchStart(center: Point) {
    this.setPinchCenter(center)
  }

  handlePinch(scale: number, deltaX: number, deltaY: number) {
    const { pinchCenter, containerSize, contentSize, pinchScale, scaledContentSize } = this

    if (!pinchCenter || !containerSize || !contentSize) {
      return
    }

    const currentScale = pinchScale.global * scale

    if (
      this.contentOrientation === 'landscape' &&
      scaledContentSize.width * currentScale + this.containerPadding * 2 <= containerSize.width
    ) {
      scale =
        containerSize.width /
        (scaledContentSize.width + this.containerPadding * 2) /
        pinchScale.global
    }
    if (
      this.contentOrientation === 'portrait' &&
      scaledContentSize.height * currentScale + this.containerPadding * 2 <= containerSize.height
    ) {
      scale =
        containerSize.height /
        (scaledContentSize.height + this.containerPadding * 2) /
        pinchScale.global
    }

    const translateScale = ((scale - 1) * scaledContentSize.width) / contentSize.width / PIXEL_RATIO

    this.setPinchScale({ current: scale })
    this.setTranslation({
      current: {
        x: -pinchCenter.x * translateScale * pinchScale.global + deltaX,
        y: -pinchCenter.y * translateScale * pinchScale.global + deltaY,
      },
    })
  }

  handlePinchEnd() {
    if (!this.pinchCenter) {
      return
    }

    this.setPinchScale({
      global: this.pinchScale.global * this.pinchScale.current,
      current: 1,
    })
    this.handlePanEnd()
  }
}
