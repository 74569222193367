import { Entity as WorksheetEntity } from '@mathflat/domain/@entities/(Content)/Worksheet/dto'
import { Entity as StudentWorksheetEntity } from '@mathflat/domain/@entities/StudentWorksheet/dto'
import { Entity as StudentWorksheetScoringEntity } from '@mathflat/domain/@entities/StudentWorksheet/StudentWorksheetScoring/dto'
import { makeAutoObservable, runInAction } from 'mobx'

import { RequestScoring, studentWorksheetApi } from '~/@common/api'
import { toastService } from '~/@common/services'
import { commonRepo } from '~/@common/services/repo.service'
import { localStorageService } from '~/@common/services/storage.service'
import {
  ProblemScoring,
  ProblemScoringColl,
} from '~/@pages/@common/(ProblemScoring)/@trait/ProblemScoring.trait'
import { ProblemScoringViewOption } from '~/@pages/@common/(ProblemScoring)/@trait/ProblemScoringViewOption.trait'

// idea
// interface WorksheetScoring<T extends '출제'|'미출제', U extends T extends '출제' ? '오답유형학습'|'오답유형학습아님':never> {
// problemScoringColl?: U extends '오답유형학습' ? ProblemScoringColl<'WORKSHEET'>[]:ProblemScoringColl<'WORKSHEET'>
// problemScoringViewOption?: ProblemScoringViewOption<'학습모듈' | 'NOT_학습모듈'>
// studentWorksheet?: T extends '출제' ? StudentWorksheetEntity.StudentWorksheet:undefined
// worksheet?: WorksheetEntity.CustomWorksheet
// }
//
// 출제의 경우
// StudentWorksheetDetailPageService extends WorksheetScoring<'출제'> {}
// 미출제의 경우
// WorksheetScoringPageService extends WorksheetScoring<'미출제'> {}
// 자기주도의 경우
// 자기주도Service {
// 문제풀이결과확인: WorksheetScoring<'출제'>의 구현체
// 오답유형학습: WorksheetScoring<'출제', '오답유형학습'>의 구현체
// }

// idea2
//

export class StudentWorksheetDetailPageService {
  problemScoringColl?: ProblemScoringColl<'WORKSHEET'>
  problemScoringViewOption?: ProblemScoringViewOption<'NOT_학습모듈'>
  studentWorksheet?: StudentWorksheetEntity.StudentWorksheet
  worksheet?: WorksheetEntity.CustomWorksheet

  constructor() {
    makeAutoObservable(this)
  }

  async loadAndSetStudentWorksheetScorings(
    studentWorksheetId: StudentWorksheetEntity.StudentWorksheet['id'],
  ) {
    return await Promise.all([
      studentWorksheetApi.getAssignedStudentWorksheetById(studentWorksheetId),
      studentWorksheetApi.getAssignedStudentWorksheetWithProblems(studentWorksheetId),
    ]).then(([{ worksheet, studentWorksheet }, problemScorings]) => {
      runInAction(() => {
        this.worksheet = new WorksheetEntity.CustomWorksheet(worksheet)
        this.studentWorksheet = new StudentWorksheetEntity.StudentWorksheet(studentWorksheet)
        this.problemScoringColl = new ProblemScoringColl<'WORKSHEET'>(
          problemScorings.map(
            ({ problem, worksheetProblemId, scoring, handwrittenNoteUrl }, index) =>
              new ProblemScoring<'WORKSHEET'>(
                {
                  scoring: new StudentWorksheetScoringEntity.StudentWorksheetScoring({
                    scoring: { ...scoring, worksheetProblemId },
                    problem: {
                      ...problem,
                      answerUnits: problem.answerUnits,
                      keypadTypes: problem.keypadTypes,
                    },
                    worksheet: {
                      autoScorable: worksheet?.autoScorable ?? false,
                      label: `${index + 1}번`, // label을 밖에서 주입해줘야 하는 경우에는 받을 수 있도록 (ex. 1번 유사문제)}
                    },
                  }),
                },
                worksheetProblemId,
                handwrittenNoteUrl,
              ),
          ),
        )

        if (commonRepo.studentAppSetting) {
          this.problemScoringViewOption = new ProblemScoringViewOption({
            content: {
              status: studentWorksheet.status,
              autoScored: worksheet.autoScorable,
              kind: 'WORKSHEET',
              type: worksheet.type,
              worksheetId: worksheet.id,
            },
            studentAppSetting: commonRepo.studentAppSetting,
          })

          return
        }
      })
    })
  }

  // async loadAndSetStudentWorksheetProblems(studentWorksheetId: string) {
  //   const problemScorings = await studentWorksheetApi.getAssignedStudentWorksheetWithProblems(
  //     parseInt(studentWorksheetId),
  //   )

  //   runInAction(() => {
  //     this.studentWorksheetScoring?.setStudentWorksheetProblems(problemScorings)
  //   })
  // }

  async onSubmitWorksheetProblems(studentWorksheetId: string) {
    try {
      const payload = this.problemScoringColl?.toScoredArr

      if (payload && payload.length > 0) {
        if (payload[0].localUserInput instanceof RequestScoring.자동채점) {
          await studentWorksheetApi.patchAutoScoringAssignedStudentWorksheet(
            studentWorksheetId,
            payload.map(({ localUserInput }) =>
              RequestScoring.자동채점.toRequestParams<'WORKSHEET', '일반'>(
                localUserInput as RequestScoring.자동채점,
              ),
            ),
          )
        } else {
          // 일반채점이라면
          await studentWorksheetApi.patchScoringAssignedStudentWorksheet(
            studentWorksheetId,
            payload.map(({ localUserInput }) =>
              RequestScoring.일반채점.toRequestParams<'WORKSHEET', '일반'>(
                localUserInput as RequestScoring.일반채점,
              ),
            ),
          )
        }

        this.problemScoringColl?.toScoredArr?.forEach((item) => {
          localStorageService.removeScoringData({ scoringId: item.id })
        })

        await this.loadAndSetStudentWorksheetScorings(+studentWorksheetId)

        // patchAutoScoringAssignedStudentWorksheet 성공 여부 확인을 위해 return
        return payload?.map((item) => item.id)
      }
    } catch (err: any) {
      console.log(err)
      if (err?.response?.data?.code === 'STUDENT_WORKSHEET_PROBLEM_DUPLICATE_SUBMIT') {
        toastService.error('이미 제출한 답안은 수정할 수 없습니다.', { allowMultipleToast: true })
      }
    }
  }
}
