import type { StrapiArrayResponse, StrapiDataT } from '@mathflat/shared/@types/strapiApi'
import type { StrapiSingleResponse } from '@mathflat/shared/@types/strapiApi'
import axios from 'axios'
import qs from 'qs'

import type { StrapiApi } from '../types/api/strapi'
import { envUtils } from '../utils/envUtils'

const getBaseURL = () => {
  if (envUtils.localStrapiApiUrl) {
    return envUtils.localStrapiApiUrl
  }

  if (envUtils.isLive) {
    return 'https://hcms.mathflat.com/api'
  }
  return 'https://hcms-staging.mathflat.com/api'
}

const strapi = axios.create({
  baseURL: getBaseURL(),
  params: {
    populate: '*',
  },
  paramsSerializer: (params) => qs.stringify(params),
})

strapi.interceptors.request.use((config) => {
  if (config.url?.match(/\?/g)) {
    throw Error('url에 parameter string넣지 말아주세요.')
  }

  return config
})

const strapiApi = {
  async getNotice(params): Promise<StrapiDataT<StrapiApi.Notice>[]> {
    const {
      data: { data },
    } = await strapi.get<StrapiArrayResponse<StrapiApi.Notice>>('/mathflat-student-notices', {
      params,
    })
    return data.map(({ id, attributes }) => ({ id, ...attributes }))
  },
  async getServerMaintenance(): Promise<StrapiDataT<StrapiApi.ServerMaintenance>> {
    const {
      data: { data },
    } = await strapi.get<StrapiSingleResponse<StrapiApi.ServerMaintenance>>('/server-maintenance')
    return {
      id: data.id,
      ...data.attributes,
      startDate: new Date(new Date(data.attributes.startDate).getTime()),
      endDate: new Date(new Date(data.attributes.endDate).getTime()),
    }
  },
}

export default strapiApi
