import type { SerializedStyles } from '@emotion/react'
import clsx from 'clsx'
import { type ComponentPropsWithoutRef, useId } from 'react'
import React from 'react'

import Radio from '~/@common/ui/radio'
import RadioItem from '~/@common/ui/radio/RadioItem'

import type { RadioGroupInputProps } from './RadioGroupInput'

export const RadioGroupItemClassName = 'ms-component-radio-group-item'

export const radioGroupItemFactory = (
  inputProps: RadioGroupInputProps,
  displayName: string,
  _css?: SerializedStyles | SerializedStyles[],
  labelProps?: ComponentPropsWithoutRef<'label'>,
) => {
  const Component = React.forwardRef<HTMLInputElement, RadioGroupInputProps>(
    ({ id: _id, children, className, ...props }, ref) => {
      const _uid = useId()
      const id = _id ?? _uid

      return (
        <RadioItem
          className={clsx(RadioGroupItemClassName, displayName, className)}
          css={_css}
          data-component="ms__radio-group-item"
        >
          <Radio.GroupInput ref={ref} id={id} hidden {...props} />
          <label {...labelProps} htmlFor={id}>
            {children}
          </label>
        </RadioItem>
      )
    },
  )
  Component.displayName = displayName
  return <Component {...inputProps} />
}
