import { EventSourcePolyfill, NativeEventSource } from 'event-source-polyfill'

import chatAxios, { fetchSSE } from '~/@common/utils/chatAxios.ts'

const EventSource = EventSourcePolyfill || NativeEventSource

type AiTutorParams = {
  sessionId: string
  userHandwrittenNoteUrl: string
}

export const chatApi = (() => {
  return {
    getSessionId: async () => {
      const { data } = await chatAxios.post('/ai/session')
      return data
    },

    getHintConceptual: (
      problemId: number,
      params: Omit<AiTutorParams, 'userHandwrittenNoteUrl'>,
    ) => {
      return new EventSource(
        chatAxios.defaults.baseURL +
          `/ai/hint-conceptual/${problemId}?sessionId=${params.sessionId}`,
        {
          headers: {
            'x-platform': 'STUDENT',
            'x-auth-token': chatAxios.defaults.headers.common['x-auth-token'],
          },
        },
      )
      // return fetchSSE(`/ai/hint-conceptual/${problemId}?sessionId=${params.sessionId}`)
    },
    getSolveTheStuckUp: (problemId: number, params: AiTutorParams) => {
      return new EventSource(
        chatAxios.defaults.baseURL +
          `/ai/solve-the-stuck-up/${problemId}?sessionId=${params.sessionId}&userHandwrittenNoteUrl=${params.userHandwrittenNoteUrl}`,
        {
          headers: {
            'x-platform': 'STUDENT',
            'x-auth-token': chatAxios.defaults.headers.common['x-auth-token'],
          },
        },
      )
    },

    getReviewTheSolution: (problemId: number, params: AiTutorParams) => {
      return new EventSource(
        chatAxios.defaults.baseURL +
          `/ai/review-the-solution/${problemId}?sessionId=${params.sessionId}&userHandwrittenNoteUrl=${params.userHandwrittenNoteUrl}`,
        {
          headers: {
            'x-platform': 'STUDENT',
            'x-auth-token': chatAxios.defaults.headers.common['x-auth-token'],
          },
        },
      )
    },
  }
})()
