import { css } from '@emotion/react'

const fontSize = {
  heading01: '48px',
  heading02: '32px',
  heading03: '24px',
  heading04: '20px',
  heading05: '18px',
  body01: '16px',
  body02: '14px',
  caption01: '12px',
  caption02: '9px',
}

export const typo = {
  heading01: css`
    font-size: ${fontSize.heading01};
    line-height: 62px;
    letter-spacing: -0.01em;
  `,
  heading02: css`
    font-size: ${fontSize.heading02};
    line-height: 40px;
    letter-spacing: -0.0098em;
  `,
  heading03: css`
    font-size: ${fontSize.heading03};
    line-height: 36px;
    letter-spacing: -0.015em;
  `,
  heading04: css`
    font-size: ${fontSize.heading04};
    line-height: 28px;
    letter-spacing: -0.01em;
  `,
  heading05: css`
    font-size: ${fontSize.heading05};
    line-height: 24px;
    letter-spacing: -0.01em;
  `,
  body01: css`
    font-size: ${fontSize.body01};
    line-height: 24px;
    letter-spacing: -0.01em;
  `,
  body02: css`
    font-size: ${fontSize.body02};
    line-height: 21px;
    letter-spacing: -0.002em;
  `,
  caption01: css`
    font-size: ${fontSize.caption01};
    line-height: 18px;
  `,
  caption02: css`
    font-size: ${fontSize.caption02};
    line-height: 12px;
  `,
}
