import { encodeSync } from '@effect/schema/Parser'
import { css } from '@emotion/react'
import { kindSelector } from '@mathflat/design-system/@common/utils/dataSelector.ts'
import { ConceptChipDomain } from '@mathflat/domain/@entities/ConceptChip/domain.ts'
import type { ComponentProps } from 'react'

import { useStudentAppMediaQuery } from '~/@common/hooks/useMediaQuery'
import { colors, typo } from '~/@common/styles'
import { mediaQuery } from '~/@common/styles/mediaQuery'
import Button from '~/@common/ui/(Button)/Button/Button.tsx'
import ConceptChipAchievementLevel from '~/@pages/@common/(ConceptChip)/ConceptChipAchievmentLevel/ConceptChipAchievementLevel.tsx'
import { ConceptChipDifficultyLevelModule } from '~/@pages/@common/(ConceptChip)/ConceptChipDifficultyLevel'
import ConceptChipDifficultyLevel from '~/@pages/@common/(ConceptChip)/ConceptChipDifficultyLevel/ConceptChipDifficultyLevel.tsx'
import ConceptChip from '~/@pages/student/student-home/@widgets/ConceptChip/ConceptChip'

export const kind = 'ms__ConceptChipDetail' as const

export const parts = {
  levelInfo: `${kind}_levelInfo`,
  description: `${kind}_description`,
}

const { LevelOfAchievement, LevelOfDifficulty } = ConceptChipDomain

const SIZE_FOR_ACHIEVEMENT_LEVEL = {
  small: 28,
  medium: 40,
} as const
export type Props = {
  description?: string
  isEmpty?: boolean
  achievementLevel?: ConceptChipDomain.LevelOfAchievement
  difficultyLevel?: ConceptChipDomain.LevelOfDifficulty

  onLearningStart?: ComponentProps<'button'>['onClick']
  size?: 'small' | 'medium'
  dataCategory?: string
  isShowArrow?: boolean
  textColor?: string
}

const ConceptChipDetail = ({
  description,
  achievementLevel,
  difficultyLevel,
  isEmpty,
  size = 'medium',
  onLearningStart,
  dataCategory,
  isShowArrow,
  textColor,
}: Props) => {
  const { isMobile } = useStudentAppMediaQuery()

  const ConceptChipContent = () => {
    return (
      <>
        <div className={parts.levelInfo}>
          <ConceptChipAchievementLevel
            level={achievementLevel ?? 'WHITE'}
            size={SIZE_FOR_ACHIEVEMENT_LEVEL[size]}
            disabled
          />
          {difficultyLevel && <ConceptChipDifficultyLevel level={difficultyLevel} size={size} />}
        </div>
        <p className={parts.description}>{description}</p>
      </>
    )
  }

  const defaultConceptChipContent = (
    <>
      <ConceptChipContent />
      {onLearningStart && (
        <Button theme="secondary" size="small" onClick={onLearningStart}>
          학습하러 가기
        </Button>
      )}
    </>
  )

  const conceptChipItem = {
    levelOfAchievement: encodeSync(LevelOfAchievement)(achievementLevel ?? 'WHITE'),
    levelOfConceptChip: encodeSync(LevelOfDifficulty)(difficultyLevel ?? '개념'),
    conceptName: description ?? '',
  }

  const mobileConceptChipContent = (
    <ConceptChip
      size="small"
      item={conceptChipItem}
      isShowArrow={isShowArrow}
      textColor={textColor}
      onClick={onLearningStart}
    />
  )

  return (
    <article
      css={_css}
      data-kind={kind}
      data-size={size}
      data-category={dataCategory}
      data-is-empty={isEmpty}
    >
      {!isEmpty && (isMobile ? mobileConceptChipContent : defaultConceptChipContent)}
    </article>
  )
}

export default ConceptChipDetail

const _css = css`
  display: flex;
  flex-direction: column;
  background: ${colors.white};
  border-radius: 14px;
  padding: 14px;
  gap: 14px;

  &[data-size='small'] {
    min-height: 100px;
  }
  &[data-size='medium'] {
    min-height: 174px;
  }

  .${parts.levelInfo} {
    display: flex;
    justify-content: space-between;
    align-content: end;
  }

  .${parts.description} {
    max-width: 120px;
    color: ${colors.gray.$900};
    overflow: hidden;
    display: -webkit-box;
    text-align: left;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    max-height: 3em;
    * {
      display: inline;
    }
  }

  &[data-size='small'] {
    .${parts.description} {
      ${typo.caption01};
    }
  }

  button {
    margin-top: auto;
    width: 100%;
  }

  ${kindSelector(ConceptChipDifficultyLevelModule)} {
    align-self: flex-end;
  }

  &[data-is-empty='true']::after {
    content: '추천할 수 있는 \A유형칩이 없어요';
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
    text-align: center;
    white-space: pre;
    color: ${colors.gray.$600};

    &[data-size='small'] {
      ${typo.caption01};
    }
  }

  ${mediaQuery.underTablet} {
    padding: 0;

    &[data-size='medium'] {
      min-height: 58px;
    }

    &[data-size='small'] {
      min-height: 51px;
    }

    .mobile-button {
      display: flex;
      width: 100%;
      align-items: center;
    }

    &[data-is-empty='true']::after {
      content: '추천할 수 있는 유형칩이 없어요';
    }
  }
`
