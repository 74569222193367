import { WorksheetDomain } from '@mathflat/domain/@entities'
import type { Entity as WorksheetEntity } from '@mathflat/domain/@entities/(Content)/Worksheet/dto'
import type { Entity as HomeworkEntity } from '@mathflat/domain/@entities/Homework/dto'
import type { MathflatApi } from '@mathflat/domain/@entities/StudentWorksheet/api'
import type { Entity as StudentWorksheetEntity } from '@mathflat/domain/@entities/StudentWorksheet/dto'

import { SelfLearning, SelfLearningColl } from './SelfLearning.dto'

export class AssignedStudentWorksheetHomeworksSelfLearnings {
  protected _studentWorksheet: StudentWorksheetEntity.StudentWorksheet
  protected _worksheet: WorksheetEntity.Worksheet
  private _group: {
    studentWorksheet: Omit<
      MathflatApi.Response.SelfLearningWorksheetAndStudentWorksheet,
      'worksheet'
    >
    worksheet: MathflatApi.Response.SelfLearningWorksheetAndStudentWorksheet['worksheet']
  }[] = []
  private _selfLearningColl: SelfLearningColl
  private _originAssignedStudentWorksheet: AssignedStudentWorksheetHomeworksSelfLearnings | null
  homeworks: HomeworkEntity.Homework[] = []

  constructor(assignedStudentWorksheetWithHomeworks: {
    worksheet: WorksheetEntity.Worksheet
    selfLearningStudentWorksheets?: {
      studentWorksheet: Omit<
        MathflatApi.Response.SelfLearningWorksheetAndStudentWorksheet,
        'worksheet'
      >
      worksheet: MathflatApi.Response.SelfLearningWorksheetAndStudentWorksheet['worksheet']
      group: {
        studentWorksheet: Omit<
          MathflatApi.Response.SelfLearningWorksheetAndStudentWorksheet,
          'worksheet'
        >
        worksheet: MathflatApi.Response.SelfLearningWorksheetAndStudentWorksheet['worksheet']
      }[]
    }[]
    homeworks?: HomeworkEntity.Homework[]
    studentWorksheet: StudentWorksheetEntity.StudentWorksheet
    group?: {
      studentWorksheet: Omit<
        MathflatApi.Response.SelfLearningWorksheetAndStudentWorksheet,
        'worksheet'
      >
      worksheet: MathflatApi.Response.SelfLearningWorksheetAndStudentWorksheet['worksheet']
    }[]
    originAssignedStudentWorksheet?: AssignedStudentWorksheetHomeworksSelfLearnings
  }) {
    this._studentWorksheet = assignedStudentWorksheetWithHomeworks.studentWorksheet
    this._worksheet = assignedStudentWorksheetWithHomeworks.worksheet
    this.homeworks = assignedStudentWorksheetWithHomeworks.homeworks ?? []
    this._selfLearningColl = new SelfLearningColl(
      (assignedStudentWorksheetWithHomeworks.selfLearningStudentWorksheets ?? []).map(
        (selfLearning) => new SelfLearning(selfLearning),
      ),
    )
    this._group = assignedStudentWorksheetWithHomeworks.group ?? []
    this._originAssignedStudentWorksheet =
      assignedStudentWorksheetWithHomeworks.originAssignedStudentWorksheet ?? null
  }

  get worksheet() {
    return this._worksheet
  }
  get studentWorksheet() {
    return this._studentWorksheet
  }

  get group() {
    return this._group
  }

  get correctCount() {
    if (this.group.length > 1) {
      return this.group.reduce((result, curr) => {
        return curr.studentWorksheet.correctCount
          ? result + curr.studentWorksheet.correctCount
          : result
      }, 0)
    }

    return this._studentWorksheet.correctCount
  }

  get wrongCount() {
    if (this.group.length > 1) {
      return this.group.reduce((result, curr) => {
        return curr.studentWorksheet.wrongCount ? result + curr.studentWorksheet.wrongCount : result
      }, 0)
    }

    return this._studentWorksheet.wrongCount
  }

  get score() {
    if (this.group.length > 1) {
      return Math.floor(
        this.group.reduce((result, curr) => {
          return curr.studentWorksheet.score ? result + curr.studentWorksheet.score : result
        }, 0) / this.group.length,
      )
    }

    return this._studentWorksheet.score
  }

  get status() {
    if (this.group.length > 1) {
      return this.group.every((item) => item.studentWorksheet.status === 'COMPLETE')
        ? 'COMPLETE'
        : this.group.every((item) => item.studentWorksheet.status === 'INCOMPLETE')
          ? 'INCOMPLETE'
          : 'PROGRESS'
    }

    return this._studentWorksheet.status
  }

  get selfLearningColl() {
    return this._selfLearningColl
  }

  get hasSelfLearningWorksheet() {
    return this._selfLearningColl.hasLevelUp || this._selfLearningColl.hasWrong
  }
  get type() {
    return this._worksheet.type
  }

  get isSelfLearningWorksheet() {
    try {
      AssignedStudentWorksheetHomeworksSelfLearnings.assertSelfLearningWorksheet(this._worksheet)
      return true
    } catch (e) {
      return false
    }
  }

  get originAssignedStudentWorksheet() {
    return this._originAssignedStudentWorksheet
  }

  get isParentWorksheet() {
    return !this.isSelfLearningWorksheet
  }

  static assertSelfLearningWorksheet(
    worksheet: WorksheetEntity.Worksheet,
  ): asserts worksheet is WorksheetEntity.LevelUpWorksheet | WorksheetEntity.WrongConceptWorksheet {
    if (worksheet.type !== WorksheetDomain.TYPE.자기주도학습) {
      throw Error('자기주도학습지가 아닙니다.')
    }
  }
}
