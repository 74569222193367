import type { ExamI } from '@mathflat/domain/@entities/StudentExam/api2'

export class Exam {
  id: ExamI['id']
  schoolType: ExamI['schoolType']
  grade: ExamI['grade']
  title: ExamI['title']
  chapter: ExamI['chapter']
  problemCount: ExamI['problemCount']
  deleted: ExamI['deleted']
  progress: ExamI['progress']
  examType: ExamI['examType']
  constructor(exam: ExamI) {
    this.id = exam.id
    this.schoolType = exam.schoolType
    this.grade = exam.grade
    this.title = exam.title
    this.chapter = exam.chapter
    this.problemCount = exam.problemCount
    this.deleted = exam.deleted
    this.progress = exam.progress
    this.examType = exam.examType
  }
}
