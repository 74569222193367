import type { PropsWithChildren } from 'react'
import { createPortal } from 'react-dom'

import authService from '../services/auth.service'
import MAATTimer from '~/@pages/student/student-exam/$studentExamId/scoring/@widgets/MAATTimer'

// layout 최상위에 있는 헤더
export const PortalRootLayoutHeader = ({
  children,
  key,
  showMAATTImer,
}: PropsWithChildren & { key?: string | null; showMAATTImer?: boolean }) => {
  if (!authService.isLoggedIn) return null

  return createPortal(
    <div className="app-layout-header">
      {children}
      {showMAATTImer && <MAATTimer />}
    </div>,
    document.getElementById('layout-header')!,
    key,
  )
}
