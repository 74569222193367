import { css } from '@emotion/react'
import { isAxiosError } from 'axios'
import { observer } from 'mobx-react'
import type { ChangeEvent, FC } from 'react'
import { useMemo, useRef } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router'

import { useStudentAppMediaQuery } from '~/@common/hooks/useMediaQuery'
import { useRepository } from '~/@common/hooks/useRepository'
import { toastService } from '~/@common/services'
import { learningContentLastScreenService } from '~/@common/services/learningContentLastScreen/LearningContentLastScreen.service'
import modalService from '~/@common/services/modal.service'
import { colors, fontWeight, typo } from '~/@common/styles'
import { mediaQuery } from '~/@common/styles/mediaQuery'
import Button from '~/@common/ui/(Button)/Button/Button'
import {
  SwiperControllerConsumerName,
  useSwiperControllerContext,
} from '~/@common/ui/SwiperController/SwiperController.context'
import { WorksheetScoringCardMobile } from '~/@pages/@widgets/(Worksheet)/WorksheetScoring/(Body)/WorksheetScoringCard.mobile'
import WorksheetScoringByOne from '~/@pages/@widgets/(Worksheet)/WorksheetScoring/WorksheetScoringByOne'

import { ConceptualLearningService } from '../service/ConceptualLearning.service'
import { openNotAllLocallyScoredAlert, openSubmitConfirmModal } from './ConceptualLearningModal'
import ConceptualLearningResult from './ConceptualLearningResult'
import ConceptualLearningSwiperController from './ConceptualLearningSwiperController'

const SHOW_WITH_ANSWER_HASH = 'with-answer'

const ConceptualLearningScoringSection: FC = () => {
  const service = useRepository(ConceptualLearningService)
  const containerRef = useRef<HTMLDivElement>(null)
  const { worksheetProblemScoring, conceptualEduMaterial } = service
  const { studentWorksheetId } = useParams<{ studentWorksheetId: string }>()
  const { isMobile } = useStudentAppMediaQuery()
  const location = useLocation()
  const navigate = useNavigate()

  const swiperController = useSwiperControllerContext(SwiperControllerConsumerName)

  const isShowWithAnswer = useMemo(() => {
    return location.hash === `#${SHOW_WITH_ANSWER_HASH}`
  }, [location.hash])

  if (!conceptualEduMaterial || !worksheetProblemScoring) {
    return null
  }

  const { problemScoringColl, problemScoringViewOption, studentWorksheet, worksheet } =
    worksheetProblemScoring

  if (
    !problemScoringColl ||
    !problemScoringViewOption ||
    !studentWorksheet ||
    !worksheet ||
    !studentWorksheetId
  ) {
    return null
  }

  const handleSubmitProblems = async () => {
    if (studentWorksheet.status === 'COMPLETE') {
      return
    }

    if (!problemScoringColl.isAllLocallyScored) {
      try {
        const isConfirmed = await openNotAllLocallyScoredAlert()
        const { notMarkingProblemFirstIndex } = problemScoringColl

        if (isConfirmed && notMarkingProblemFirstIndex >= 0) {
          if (isMobile) {
            if (containerRef?.current) {
              const scoringEls = containerRef.current.querySelectorAll(
                ':scope .conceptual-learning-scoring-card',
              )
              scoringEls[notMarkingProblemFirstIndex]?.scrollIntoView({
                behavior: 'smooth',
                block: 'start',
              })
            }
          } else if (swiperController.control) {
            swiperController.control.slideTo(notMarkingProblemFirstIndex)
          }
        }
      } finally {
        modalService.closeModal()
      }
      return
    }

    try {
      const isConfirmed = await openSubmitConfirmModal()

      if (isConfirmed) {
        modalService.showLoader()
        const isSuccess = await service.onSubmitWorksheetProblems(Number(studentWorksheetId))
        if (isSuccess) {
          await service.fetchAssignedStudentWorksheetWithProblems(Number(studentWorksheetId))
        }
        if (isMobile && containerRef.current) {
          containerRef.current.scrollIntoView({
            behavior: 'instant',
            block: 'start',
          })
        }
      }
    } catch (err: unknown) {
      if (
        isAxiosError(err) &&
        err.response?.data?.code === 'STUDENT_WORKSHEET_PROBLEM_DUPLICATE_SUBMIT'
      ) {
        toastService.error('이미 제출한 답안은 수정할 수 없습니다.', { allowMultipleToast: true })
      }
    } finally {
      modalService.closeModal()
      modalService.hideLoader()
    }
  }

  const handleIsShowWithAnswerChange = (e: ChangeEvent<HTMLInputElement>) => {
    const isShowWithAnswer = e.currentTarget.checked
    const hash = isShowWithAnswer ? `#${SHOW_WITH_ANSWER_HASH}` : ''
    const newUrl = `${location.pathname}${hash}`
    navigate(newUrl, { replace: !isShowWithAnswer })
  }

  const lastScreenProblemIndex = learningContentLastScreenService.getLastScreenProblemIndex([
    'STUDENT_WORKSHEET',
    Number(studentWorksheetId),
  ])

  const updateLastScreenProblemIndex = (currentProblemIndex: number) => {
    if (studentWorksheetId) {
      learningContentLastScreenService.setLastScreenProblemIndex(
        ['STUDENT_WORKSHEET', Number(studentWorksheetId)],
        currentProblemIndex,
      )
    }
  }

  const isCompleted = studentWorksheet.status === 'COMPLETE'

  return (
    <div css={_css} ref={containerRef}>
      {isMobile && (
        <div css={_scoringTitleCss}>
          예제 풀기 <span>총{problemScoringColl.toArr.length}문제</span>
        </div>
      )}
      {isCompleted && (isMobile || !isShowWithAnswer) ? (
        <>
          <ConceptualLearningResult
            worksheetWithProblems={worksheetProblemScoring}
            cardTitleNode={
              !isMobile && (
                <ConceptualLearningSwiperController
                  problemSize={problemScoringColl.toArr.length}
                  isCompleted={isCompleted}
                  isShowWithAnswer={isShowWithAnswer}
                  onIsShowWithAnswerChange={handleIsShowWithAnswerChange}
                />
              )
            }
          />
        </>
      ) : isMobile ? (
        <>
          {problemScoringColl.toArr.map((problemScoring) => {
            return (
              <WorksheetScoringCardMobile
                key={problemScoring.id}
                problemScoring={problemScoring}
                type={'CONCEPTUAL'}
                viewOption={problemScoringViewOption}
              />
            )
          })}

          <Button onClick={handleSubmitProblems} theme="primary" type="submit">
            제출하기
          </Button>
        </>
      ) : (
        <>
          <WorksheetScoringByOne
            problemScoringColl={problemScoringColl}
            viewOption={problemScoringViewOption}
            onSubmit={handleSubmitProblems}
            studentWorksheet={studentWorksheet}
            worksheet={worksheet}
            problemIndex={lastScreenProblemIndex}
            onProblemIndexChange={updateLastScreenProblemIndex}
            containerTopNode={
              <ConceptualLearningSwiperController
                problemSize={problemScoringColl.toArr.length}
                isCompleted={isCompleted}
                isShowWithAnswer={isShowWithAnswer}
                onIsShowWithAnswerChange={handleIsShowWithAnswerChange}
              />
            }
          />
        </>
      )}
    </div>
  )
}

export default observer(ConceptualLearningScoringSection)

const _css = css`
  display: flex;
  min-width: 512px;
  max-width: 50%;
  flex: 1 0;
  overflow: auto;

  ${mediaQuery.underTablet} {
    min-width: unset;
    max-width: unset;
    flex-direction: column;
    gap: 20px;
    padding: 0 16px;
    overflow: unset;
  }
`

const _scoringTitleCss = css`
  ${mediaQuery.underTablet} {
    margin-top: 20px;
    margin-bottom: -6px;
    ${typo.heading05};
    color: ${colors.gray.$900};
    font-weight: ${fontWeight.bold};
    > span {
      ${typo.body02};
      font-weight: ${fontWeight.normal};
    }
  }
`
