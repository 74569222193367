import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import { routeName } from '~/@common/constants'
import authService from '~/@common/services/auth.service'
import type { DefaultProps } from '~/@common/types'
import { envUtils } from '~/@common/utils/envUtils'

const CBTRoute = ({ children }: DefaultProps) => {
  const navigate = useNavigate()

  useEffect(() => {
    // 로그인이 된 상태면 default화면으로 보냄
    if (authService.isLoggedIn) {
      navigate(routeName.student.defaultPath)
      // 로그아웃 등의 이유로 여기 떨어지면, 실제 환경이라면 old로 보내고, 아니면 로그인 화면보여주면 되니 아무처리하지 않음.
    } else if (envUtils.isRealEnv) {
      envUtils.redirectToBasicStudentApp()
    }
  }, [])

  if (authService.isLoggedIn || envUtils.isRealEnv) {
    return <></>
  }

  // preview, local 환경인 경우만 자체 로그인 ui를 보여준다.
  return <>{children}</>
}

export default CBTRoute
