import styled from '@emotion/styled'

import { colors } from '~/@common/styles'
import type { DefaultProps } from '~/@common/types'

import { Icon } from '../../Icon/Icon'
import { Card } from '..'

interface Props extends DefaultProps {
  wrapperStyle?: React.CSSProperties
  wrapperClassName?: string
}

export const LearningContentCardAsChild = ({
  children,
  className,
  style,
  wrapperClassName,
  wrapperStyle,
}: Props) => {
  return (
    <LearningContentCardWrapper className={wrapperClassName} style={wrapperStyle}>
      <Icon name="icon_inside_point" color={colors.gray.$500} size={20} />
      <Card className={className} style={style}>
        {children}
      </Card>
    </LearningContentCardWrapper>
  )
}

const LearningContentCardWrapper = styled.div`
  display: flex;
  align-items: center;
`
