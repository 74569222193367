import { debounce } from 'lodash'
import type { MathfieldElement } from 'mathlive'

import type { ProblemScoring } from '~/@pages/@common/(ProblemScoring)/@trait/ProblemScoring.trait'

const CUSTOM_EVENT_TYPE = {
  TOOLTIP_OFF: 'tooltip-off',
  TOOLTIP_ON: 'tooltip-on',
  VIRTUAL_KEYBOARD_ON: 'virtual-keyboard-on',
  VIRTUAL_KEYBOARD_OFF: 'virtual-keyboard-off',
  MATHFIELD_FOCUS: 'virtual-keyboard-off',
  MAAT_TIMER_ON: 'MAAT-timer-on',
}

export type TooltipEvent = { id: number | string | null }
export type VirtualKeyboardEvent = {
  mathFieldRef: MathfieldElement
  value: string
  keypadTypes: ProblemScoring<'WORKSHEET' | 'WORKBOOK'>['keypadTypes']
  answerUnits: ProblemScoring<'WORKSHEET' | 'WORKBOOK'>['answerUnits']
}

// 기존 - 커스텀 이벤트가 전역에 산재해있어서 트래킹하기 힘들어 만든 서비스
// 딱히 인스턴스화 할 일은 없을듯. 그대로 객체로 바꿔도 됨.
export class CustomEventService {
  static tooltipOn = {
    event: (id: TooltipEvent['id']) =>
      new CustomEvent<TooltipEvent>(CUSTOM_EVENT_TYPE.TOOLTIP_ON, {
        detail: { id },
      }),
    listen: (
      listener: (e: ReturnType<(typeof CustomEventService)['tooltipOn']['event']>) => void,
    ) => {
      window.addEventListener(CUSTOM_EVENT_TYPE.TOOLTIP_ON, listener)

      return {
        disposer: () => {
          window.removeEventListener(CUSTOM_EVENT_TYPE.TOOLTIP_ON, listener)
        },
      }
    },
    dispatch: (id: TooltipEvent['id'], e: React.MouseEvent<HTMLElement, MouseEvent>) => {
      e.stopPropagation()
      window.dispatchEvent(CustomEventService.tooltipOn.event(id))
    },
  }
  static tooltipOff = {
    event: (id: TooltipEvent['id']) =>
      new CustomEvent<TooltipEvent>(CUSTOM_EVENT_TYPE.TOOLTIP_OFF, {
        detail: { id },
      }),
    listen: (
      listener: (e: ReturnType<(typeof CustomEventService)['tooltipOff']['event']>) => void,
    ) => {
      window.addEventListener(CUSTOM_EVENT_TYPE.TOOLTIP_OFF, listener)

      return {
        disposer: () => {
          window.removeEventListener(CUSTOM_EVENT_TYPE.TOOLTIP_OFF, listener)
        },
      }
    },
    dispatch: (id: TooltipEvent['id']) => {
      window.dispatchEvent(CustomEventService.tooltipOff.event(id))
    },
  }

  static alltooltipOff = {
    listen: (el: Element | null, cb?: () => void) => {
      const handleClose = () => {
        CustomEventService.alltooltipOff.dispatch()
        cb?.()
      }

      const handleScroll = debounce(handleClose, 300, { leading: true })

      if (el) {
        el.addEventListener('scroll', handleScroll)
      }
      document.addEventListener('click', handleClose)

      return {
        disposer: () => {
          el?.removeEventListener('scroll', handleScroll)
          document.removeEventListener('click', handleClose)
        },
      }
    },
    dispatch: () => {
      // tooltip이벤트에 id === null을 날리면, id와 일치하는 것이 없으므로, 모든 툴팁히 닫힌다.
      CustomEventService.tooltipOff.dispatch(null)
    },
  }
  static virtualKeyboardOn: {
    event: (detail: VirtualKeyboardEvent) => CustomEvent<VirtualKeyboardEvent>
    listen: (listener: (e: any) => void) => {
      disposer: () => void
    }
    dispatch: (params: VirtualKeyboardEvent, e: React.MouseEvent<HTMLElement, MouseEvent>) => void
  } = {
    event: (detail: VirtualKeyboardEvent) => {
      return new CustomEvent<VirtualKeyboardEvent>(CUSTOM_EVENT_TYPE.VIRTUAL_KEYBOARD_ON, {
        detail,
      })
    },
    listen: (
      listener: (e: ReturnType<(typeof CustomEventService)['virtualKeyboardOn']['event']>) => void,
    ) => {
      //@ts-ignore
      window.addEventListener(CUSTOM_EVENT_TYPE.VIRTUAL_KEYBOARD_ON, listener)

      return {
        disposer: () => {
          //@ts-ignore
          window.removeEventListener(CUSTOM_EVENT_TYPE.VIRTUAL_KEYBOARD_ON, listener)
        },
      }
    },
    dispatch: (params: VirtualKeyboardEvent, e: React.MouseEvent<HTMLElement, MouseEvent>) => {
      e.stopPropagation()
      window.dispatchEvent(CustomEventService.virtualKeyboardOn.event(params))
    },
  }
  static virtualKeyboardOff = {
    event: new CustomEvent<TooltipEvent>(CUSTOM_EVENT_TYPE.VIRTUAL_KEYBOARD_OFF),
    listen: (listener: (e: (typeof CustomEventService)['virtualKeyboardOff']['event']) => void) => {
      window.addEventListener(CUSTOM_EVENT_TYPE.VIRTUAL_KEYBOARD_OFF, listener)

      return {
        disposer: () => {
          window.removeEventListener(CUSTOM_EVENT_TYPE.VIRTUAL_KEYBOARD_OFF, listener)
        },
      }
    },
    dispatch: () => {
      window.dispatchEvent(CustomEventService.virtualKeyboardOff.event)
    },
  }

  static MAATTimerOn = {
    event: new CustomEvent(CUSTOM_EVENT_TYPE.MAAT_TIMER_ON),
    listen: (listener: (e: (typeof CustomEventService)['MAATTimerOn']['event']) => void) => {
      window.addEventListener(CUSTOM_EVENT_TYPE.MAAT_TIMER_ON, listener)

      return {
        disposer: () => {
          window.removeEventListener(CUSTOM_EVENT_TYPE.MAAT_TIMER_ON, listener)
        },
      }
    },
    dispatch: () => {
      window.dispatchEvent(CustomEventService.MAATTimerOn.event)
    },
  }
}
