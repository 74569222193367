import clsx from 'clsx'
import { throttle } from 'lodash'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { isMobile } from 'react-device-detect'
import { deviceType, isIOS } from 'react-device-detect'
import { useHotkeys } from 'react-hotkeys-hook'
import type { OnProgressProps } from 'react-player/base'
import ReactPlayer from 'react-player/file'
import screenfull from 'screenfull'

import { colors } from '~/@common/styles/index.ts'

import { Icon } from '../Icon/Icon.tsx'
import {
  DEFAULT_PLAYBACK_RATE,
  DEFAULT_PLAYBACK_RATE_INFO,
  DEFAULT_RESOLUTION,
  DEFAULT_SKIP_TIME_STEP,
  DEFAULT_VOLUME,
  DEFAULT_VOLUME_STEP,
} from './Constant.ts'
import {
  FullScreenButton,
  Loading,
  PlayingButton,
  SeekBar,
  SettingButton,
  TimeDisplay,
  VolumeDisplay,
} from './Display'
import type { PlaybackRate, ResolutionInfo } from './Display/SettingButton.tsx'
import S from './MathflatPlayer.style.ts'

export type PlayerTime = {
  current: number
  total: number
  loaded: number
  elapsed: number
}
export type PlayerConfig = {
  volume: number
  playing: boolean
  buffering: boolean
  showThumbnail: boolean
  showMobileControlLayer: boolean
  defaultPlaybackRateOptions: PlaybackRate[]
  playbackRate: PlaybackRate
  resolutionInfo: ResolutionInfo
  isError: boolean
}
type Props = {
  videoUrl: string
  ThumbnailElement?: React.ReactNode
  skipTimeStep?: number
  initPlayingTime?: number
  volumeStep?: PlayerConfig['volume']
  defaultVolume?: PlayerConfig['volume']
  defaultPlaybackRateOptions?: PlayerConfig['defaultPlaybackRateOptions']
  defaultPlaybackRateInfo?: PlayerConfig['playbackRate']
  defaultResolution?: PlayerConfig['resolutionInfo']
  setVideoActualPlayTime?: (time: number) => void
  onProgressCallback?: (time: number) => void
  onStartCallback?: () => void
  onEndedCallback?: () => void
}

const MathflatPlayer = ({
  videoUrl,
  ThumbnailElement,
  volumeStep = DEFAULT_VOLUME_STEP,
  skipTimeStep = DEFAULT_SKIP_TIME_STEP,
  initPlayingTime = 0,
  defaultVolume = DEFAULT_VOLUME,
  defaultPlaybackRateOptions = DEFAULT_PLAYBACK_RATE,
  defaultResolution = DEFAULT_RESOLUTION,
  defaultPlaybackRateInfo = DEFAULT_PLAYBACK_RATE_INFO,
  setVideoActualPlayTime,
  onProgressCallback,
  onStartCallback,
  onEndedCallback,
}: Props) => {
  const videoRef = useRef<ReactPlayer>(null)
  const containerRef = useRef<HTMLDivElement>(null)
  const elapsedTimeRef = useRef<Date | null>(null)

  const [url, setUrl] = useState(videoUrl)
  const [playerTime, setPlayerTime] = useState<PlayerTime>({
    current: initPlayingTime,
    loaded: 0,
    total: 0,
    elapsed: 0,
  })
  const [config, setConfig] = useState<PlayerConfig>({
    isError: false,
    playing: false,
    buffering: false,
    showThumbnail: true,
    showMobileControlLayer: false,
    volume: defaultVolume,
    playbackRate: defaultPlaybackRateInfo,
    defaultPlaybackRateOptions: defaultPlaybackRateOptions,
    resolutionInfo: defaultResolution,
  })

  const [videoHeight, setVideoHeight] = useState(0)
  const [state, setState] = useState({
    middleIcon: 'icon_pause' as 'icon_play' | 'icon_pause' | 'icon_skip_forward' | 'icon_skip_back',
    skip_forward: null as 'first' | 'second' | null,
    skip_back: null as 'first' | 'second' | null,
    skip_forward_double_tab: null as 'first' | 'second' | null,
    skip_back_double_tab: null as 'first' | 'second' | null,
  })

  const [resolutionData, setResolutionData] = useState<ResolutionInfo[]>([])

  const handlePlaying = () => {
    setState((state) => ({ ...state, middleIcon: config.playing ? 'icon_pause' : 'icon_play' }))

    if (config.showThumbnail) {
      videoRef.current?.seekTo(playerTime.current)
      setConfig((state) => ({
        ...state,
        showThumbnail: false,
        playing: true,
        buffering: true,
      }))
    } else {
      setConfig((state) => ({ ...state, playing: !state.playing }))
    }
  }

  const handleVolumeChange = (volume: PlayerConfig['volume']) => {
    setConfig((state) => ({ ...state, volume }))
  }

  const handleSeeking = (time: number) => {
    videoRef.current?.seekTo(time)
  }

  const handleSkipForward = () => {
    videoRef.current?.seekTo(videoRef.current?.getCurrentTime() + skipTimeStep)

    setState((state) => ({
      ...state,
      middleIcon: 'icon_skip_forward',
      skip_forward: state.skip_forward === 'first' ? 'second' : 'first',
    }))
  }
  const handleSkipBack = () => {
    videoRef.current?.seekTo(videoRef.current?.getCurrentTime() - skipTimeStep)

    setState((state) => ({
      ...state,
      middleIcon: 'icon_skip_back',
      skip_back: state.skip_back === 'first' ? 'second' : 'first',
    }))
  }

  const handleResolution = (resoutionInfo: ResolutionInfo) => {
    if (videoRef.current && videoRef.current.getInternalPlayer('hls')) {
      videoRef.current.getInternalPlayer('hls').nextLevel = resoutionInfo.level

      setConfig((state) => ({ ...state, resolutionInfo: resoutionInfo }))
    }
  }

  const handlePlaybackRate = (playbackRate: PlayerConfig['playbackRate']) => {
    setConfig((state) => ({ ...state, playbackRate }))
  }

  const handleOnDuration = (duration: number) => {
    setPlayerTime((state) => ({ ...state, total: duration }))
  }

  const handleOnProgress = ({ loadedSeconds, playedSeconds }: OnProgressProps) => {
    setPlayerTime((state) => ({ ...state, loaded: loadedSeconds, current: playedSeconds }))
    setVideoActualPlayTime?.(playedSeconds)
  }

  const handleOnBuffer = () => {
    setConfig((state) => ({ ...state, buffering: true }))
  }

  const handleOnBufferEnd = () => {
    setConfig((state) => ({ ...state, buffering: false }))
  }

  const handleOnReady = () => {
    if (videoRef.current && videoRef.current.getInternalPlayer('hls')) {
      const parsedResolutionData = videoRef.current
        .getInternalPlayer('hls')
        .levelController._levels.map(({ height }, index) => ({
          resolution: height,
          level: index,
        }))
        .sort((x, y) => y.level - x.level)

      setResolutionData([{ level: -1, resolution: 'auto' }, ...parsedResolutionData])
    }
  }

  // const handleOnError = () => {
  //   setConfig((state) => ({ ...state, isError: true }))
  // }
  // if (config.isError) {
  //   return <ErrorViewer />
  // }

  useHotkeys('space', handlePlaying)
  useHotkeys('left', handleSkipBack)
  useHotkeys('right', handleSkipForward)

  useEffect(() => {
    onProgressCallback?.(playerTime.elapsed)
  }, [playerTime.elapsed])

  // videoUrl이 변경되면(강의가 선택되면) 재생시간 초기화, 영상 정지, 재생품질 확인 동작을 진행함
  useEffect(() => {
    setConfig((state) => ({ ...state, playing: false, showThumbnail: true }))
    setPlayerTime({ current: 0, loaded: 0, total: 0, elapsed: 0 })
    handleResolution(config.resolutionInfo)

    setTimeout(() => {
      setUrl(videoUrl)
    }, 100)
  }, [videoUrl])

  const resizeObserver = useMemo(() => {
    const ASPECT_RATIO = 1.77

    return new ResizeObserver(
      throttle((entries) => {
        if (entries.length) {
          const containerWidth = (entries[0].target as HTMLElement).offsetWidth
          const height = containerWidth / ASPECT_RATIO
          setVideoHeight(height)
        }
      }, 200),
    )
  }, [])

  useEffect(() => {
    const containerEl = containerRef.current

    if (containerEl) {
      resizeObserver.observe(containerEl)
    }
    return () => {
      if (containerEl) {
        resizeObserver.unobserve(containerEl)
      }
    }
  }, [resizeObserver])

  const leftOutSideDoubleTab = useCallback(() => {
    handleSkipBack()
    setState((state) => ({
      ...state,
      skip_back_double_tab: state.skip_back_double_tab === 'first' ? 'second' : 'first',
    }))
  }, [])
  const rightOutSideDoubleTab = useCallback(() => {
    handleSkipForward()
    setState((state) => ({
      ...state,
      skip_forward_double_tab: state.skip_forward_double_tab === 'first' ? 'second' : 'first',
    }))
  }, [])
  const leftInsideDoubleTab = useCallback(() => {
    handleSkipBack()
    setState((state) => ({
      ...state,
      skip_back_double_tab: state.skip_back_double_tab === 'first' ? 'second' : 'first',
    }))
  }, [])
  const rightInsideDoubleTab = useCallback(() => {
    handleSkipForward()
    setState((state) => ({
      ...state,
      skip_forward_double_tab: state.skip_forward_double_tab === 'first' ? 'second' : 'first',
    }))
  }, [])

  useDoubleTap([
    {
      callback: leftOutSideDoubleTab,
      element: document.querySelector('#left-outside-control-layer'),
    },
    {
      callback: rightOutSideDoubleTab,
      element: document.querySelector('#right-outside-control-layer'),
    },
    {
      callback: leftInsideDoubleTab,
      element: document.querySelector('#left-inside-control-layer'),
    },
    {
      callback: rightInsideDoubleTab,
      element: document.querySelector('#right-inside-control-layer'),
    },
  ])

  useEffect(() => {
    const toggleShowMobileControlLayer = () => {
      setState((state) => ({
        ...state,
        skip_back_double_tab: null,
        skip_forward_double_tab: null,
      }))

      if (isMobile) {
        setConfig((state) => ({ ...state, showMobileControlLayer: !state.showMobileControlLayer }))
      }
    }

    window.addEventListener('single-touch', toggleShowMobileControlLayer)

    return () => {
      window.removeEventListener('single-touch', toggleShowMobileControlLayer)
    }
  }, [])

  const [isVideoOnReady, setIsVideoOnReady] = useState(false) // safari에서 동영상의 바를 조절하면 onReady가 또 실행 되는 현상이 있으므로 이미 onReady가 실행되었는지 확인하는 상태값
  useEffect(() => {
    return () => {
      // videoUrl이 바뀌면 onReady를 다시 트리거 해야하므로 초기화
      setIsVideoOnReady(false)
    }
  }, [videoUrl])

  return (
    <S.Wrapper ref={containerRef} className="mathflat-player">
      {isMobile ? (
        <>
          <S.MobileControlLayer>
            {config.showThumbnail && ThumbnailElement && (
              <S.ThumbnailContainer style={{ height: videoHeight }}>
                {ThumbnailElement}
              </S.ThumbnailContainer>
            )}
            {config.showThumbnail && (
              <div className="thumbnail-play-button-wrap" onClick={handlePlaying}>
                <button>
                  <Icon name="icon_play" size={32} color="#fff" />
                </button>
              </div>
            )}

            <div
              className={clsx(
                'layer',
                !config.showThumbnail && config.showMobileControlLayer && 'show-layer',
              )}
            >
              {config.buffering && (
                <div className="loader-wrap">
                  <Loading />
                </div>
              )}

              <div className="icon-button-wrap">
                <button
                  onClick={(e) => {
                    e.stopPropagation()
                    handleSkipBack()
                  }}
                >
                  <Icon name="icon_skip_back" size={32} color="#fff" />
                  <p>10</p>
                </button>
                <button
                  onClick={(e) => {
                    e.stopPropagation()
                    handlePlaying()
                  }}
                >
                  <Icon name={config.playing ? 'icon_pause' : 'icon_play'} size={32} color="#fff" />
                </button>
                <button
                  onClick={(e) => {
                    e.stopPropagation()
                    handleSkipForward()
                  }}
                >
                  <Icon name="icon_skip_forward" size={32} color="#fff" />
                  <p>10</p>
                </button>
              </div>

              <div id="left-inside-control-layer" className="left">
                <div
                  className={clsx(
                    state.skip_back_double_tab === 'first' && 'first',
                    state.skip_back_double_tab === 'second' && 'second',
                  )}
                >
                  <div>
                    <Icon name="icon_play" size={16} color="#fff" className="a" />
                    <Icon name="icon_play" size={16} color="#fff" className="b" />
                    <Icon name="icon_play" size={16} color="#fff" className="c" />
                  </div>
                  <p className="animation-text">{skipTimeStep}초 이동</p>
                </div>
              </div>
              <div id="right-inside-control-layer" className="right">
                <div
                  className={clsx(
                    state.skip_forward_double_tab === 'first' && 'first',
                    state.skip_forward_double_tab === 'second' && 'second',
                  )}
                >
                  <div>
                    <Icon name="icon_play" size={16} color="#fff" className="a" />
                    <Icon name="icon_play" size={16} color="#fff" className="b" />
                    <Icon name="icon_play" size={16} color="#fff" className="c" />
                  </div>
                  <p className="animation-text">{skipTimeStep}초 이동</p>
                </div>
              </div>

              <S.MobileProgressBar
                onClick={(e) => {
                  e.stopPropagation()
                }}
              >
                <TimeDisplay.Current playerTime={playerTime} />
                <div className="seek-bar-wrapper">
                  <SeekBar handleSeeking={handleSeeking} playerTime={playerTime} />
                </div>
                <TimeDisplay.Total playerTime={playerTime} />
                <SettingButton
                  className="setting-button"
                  resolutionData={resolutionData}
                  resolutionInfo={config.resolutionInfo}
                  handleResolution={handleResolution}
                  playbackRate={config.playbackRate}
                  defaultPlaybackRate={config.defaultPlaybackRateOptions}
                  handlePlaybackRate={handlePlaybackRate}
                />
                <FullScreenButton />
              </S.MobileProgressBar>
            </div>
            <div id="left-outside-control-layer" className="left">
              <div
                className={clsx(
                  state.skip_back_double_tab === 'first' && 'first',
                  state.skip_back_double_tab === 'second' && 'second',
                )}
              >
                <div>
                  <Icon name="icon_play" size={16} color="#fff" className="a" />
                  <Icon name="icon_play" size={16} color="#fff" className="b" />
                  <Icon name="icon_play" size={16} color="#fff" className="c" />
                </div>
                <p className="animation-text">10초 이동</p>
              </div>
            </div>
            <div id="right-outside-control-layer" className="right">
              <div
                className={clsx(
                  state.skip_forward_double_tab === 'first' && 'first',
                  state.skip_forward_double_tab === 'second' && 'second',
                )}
              >
                <div>
                  <Icon name="icon_play" size={16} color="#fff" className="a" />
                  <Icon name="icon_play" size={16} color="#fff" className="b" />
                  <Icon name="icon_play" size={16} color="#fff" className="c" />
                </div>
                <p className="animation-text">{skipTimeStep}초 이동</p>
              </div>
            </div>
          </S.MobileControlLayer>
        </>
      ) : (
        <>
          {config.showThumbnail && ThumbnailElement && (
            <S.ThumbnailContainer>{ThumbnailElement}</S.ThumbnailContainer>
          )}
          <div
            className={clsx(config.showThumbnail && 'thumbnail', 'start')}
            onClick={handlePlaying}
          >
            <div className={clsx(state.middleIcon, state.skip_forward, state.skip_back, 'wrap')}>
              {(state.middleIcon === 'icon_skip_back' ||
                state.middleIcon === 'icon_skip_forward') && <p className="text">{skipTimeStep}</p>}
              <Icon name={state.middleIcon} size={54} color="#fff" />
              <span className="circle" />
            </div>
            {config.buffering && <Loading />}
          </div>
          <S.ProgressBar>
            <SeekBar handleSeeking={handleSeeking} playerTime={playerTime} />
            <div className="button-wrapper">
              <div className="left">
                <PlayingButton
                  className="play-button"
                  data-component={config.playing ? 'pause-button' : 'play-button'}
                  playing={config.playing}
                  handlePlaying={handlePlaying}
                />
                <VolumeDisplay
                  volume={config.volume}
                  volumeStep={volumeStep}
                  onChange={handleVolumeChange}
                />
                <TimeDisplay playerTime={playerTime} />
              </div>
              <div className="right">
                <SettingButton
                  className="setting-button"
                  resolutionData={resolutionData}
                  resolutionInfo={config.resolutionInfo}
                  handleResolution={handleResolution}
                  playbackRate={config.playbackRate}
                  defaultPlaybackRate={config.defaultPlaybackRateOptions}
                  handlePlaybackRate={handlePlaybackRate}
                />
                <FullScreenButton />
              </div>
            </div>
          </S.ProgressBar>
        </>
      )}
      <ReactPlayer
        url={url}
        ref={videoRef}
        muted={false}
        volume={config.volume / 100}
        controls={false}
        playing={config.playing}
        playsinline
        config={{
          forceHLS: !(isIOS && deviceType === 'mobile'),
        }}
        playbackRate={config.playbackRate.rate}
        width="100%"
        height={videoHeight}
        style={{
          display: 'flex',
          background: colors.gray.$300,
        }}
        attributes={{
          poster: 'https://colorate.azurewebsites.net/SwatchColor/E8E8E8',
          playsInline: true,
        }}
        onBuffer={handleOnBuffer}
        onBufferEnd={handleOnBufferEnd}
        onDuration={handleOnDuration}
        // onError={handleOnError}
        onProgress={(state) => {
          handleOnProgress(state)
        }}
        onReady={() => {
          if (isVideoOnReady) return
          handleOnReady()
          if (videoRef.current && initPlayingTime > 0) {
            setConfig((v) => ({ ...v, showThumbnail: false }))
            videoRef.current.seekTo(initPlayingTime, 'seconds')
          }
          setIsVideoOnReady(true)
        }}
        onStart={() => {
          onStartCallback?.()
          setConfig((state) => ({ ...state, showThumbnail: false, buffering: false }))

          setInterval(() => {
            if (elapsedTimeRef.current) {
              const lapseTime = parseInt(
                (
                  (new Date().getTime() - new Date(elapsedTimeRef.current).getTime()) /
                  1000
                ).toString(),
              )

              setPlayerTime((state) => ({ ...state, elapsed: state.elapsed + lapseTime }))

              elapsedTimeRef.current = new Date()
            }
          }, 5000)
        }}
        onEnded={() => {
          onEndedCallback?.()
        }}
        onPlay={() => {
          elapsedTimeRef.current = new Date()

          if (screenfull.isFullscreen) {
            setConfig((state) => ({ ...state, playing: true }))
          }
        }}
        onPause={() => {
          elapsedTimeRef.current = null

          if (screenfull.isFullscreen) {
            setConfig((state) => ({ ...state, playing: false }))
          }
        }}
      />
    </S.Wrapper>
  )
}

export default MathflatPlayer

function useDoubleTap(params: { callback: () => void; element?: Element | null }[]) {
  const touchTimeoutRef = useRef<NodeJS.Timeout | null>(null)

  useEffect(() => {
    const _params = params.map(({ callback, element }) => ({
      callback: () => {
        if (!touchTimeoutRef.current) {
          touchTimeoutRef.current = setTimeout(() => {
            touchTimeoutRef.current = null
            dispatchEvent(new CustomEvent('single-touch'))
          }, 300) // 더블탭 이벤트 감지 시간 간격(여기서는 300ms로 설정)
        } else {
          clearTimeout(touchTimeoutRef.current)
          touchTimeoutRef.current = null
          callback()
        }
      },
      element,
    }))

    _params.forEach(({ callback, element }) => {
      element?.addEventListener('touchstart', callback)
    })
    return () => {
      _params.forEach(({ callback, element }) => {
        element?.removeEventListener('touchstart', callback)
      })
    }
  }, [params])

  return touchTimeoutRef
}
