import { css } from '@emotion/react'

import { mediaQuery } from '~/@common/styles/mediaQuery'

export const mobileSizeDrawerContentStyles = css`
  ${mediaQuery.underTablet} {
    overflow: hidden;
    div.modal-wrapper {
      display: flex;
      flex-direction: column;
      flex: 1 1 0;
      width: 100%;
      height: 100%;
      padding: 20px 20px 0;
    }
    ul.self-learning-list {
      height: 100%;
      padding-bottom: 20px;
      overflow: auto;
    }
  }
`
