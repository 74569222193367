interface ErrorHandlerOptions {
  logType?: 'error' | 'warn'
}

class ErrorHandlerService {
  handle(
    err: unknown,
    options: ErrorHandlerOptions = {
      logType: 'error',
    },
  ) {
    const { logType } = options

    switch (logType) {
      case 'error':
        console.error(err)
        break
      case 'warn':
        console.warn(err)
        break
    }

    throw err
  }
}

export const errorHandlerService = new ErrorHandlerService()
