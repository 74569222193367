import { observer } from 'mobx-react'
import { type PropsWithChildren, useLayoutEffect } from 'react'
import { Navigate, useLocation } from 'react-router'

import { studentApi } from '~/@common/api/studentApi'
import { routeName } from '~/@common/constants'
import authService from '~/@common/services/auth.service'
import { commonRepo } from '~/@common/services/repo.service'

const ProtectedRoute = ({ children }: PropsWithChildren) => {
  const { pathname } = useLocation()
  useLayoutEffect(() => {
    if (commonRepo.studentId && authService.isLoggedIn) {
      studentApi
        .getStudentAppSettings(commonRepo.studentId)
        .then((appSetting) => {
          commonRepo.setStudent({ id: commonRepo.studentId!, appSetting })
        })
        .catch((e) => {
          console.error(e)
        })
    }
  }, [commonRepo.studentId, authService.isLoggedIn, pathname])

  if (!authService.isLoggedIn) {
    return <Navigate to={routeName.login} replace />
  }

  return children
}
export default observer(ProtectedRoute)
