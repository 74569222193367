import styled from '@emotion/styled'

import { Icon } from '../../Icon/Icon'

export const handleFullScreen = () => {
  const parentOfPlayer = document.querySelector('.mathflat-player')
  const video = parentOfPlayer?.querySelector('video')
  if (video) {
    if (video['webkitEnterFullScreen']) {
      video['webkitEnterFullScreen']()
    } else {
      video.requestFullscreen()
    }
  }
}

const FullScreenButton = () => {
  return (
    <S.FullScreenButton onClick={handleFullScreen}>
      <Icon name="icon_full_screen" size={22} color="#fff" />
    </S.FullScreenButton>
  )
}
const S = {
  FullScreenButton: styled.button`
    padding: 5px;
  `,
}
export default FullScreenButton
