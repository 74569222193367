import maxios from '~/@common/utils/maxios'

import type { RecentStudiesRequestDto } from './request'
import type {
  RecentChallengeDto,
  RecentStudyDto,
  SmileChallengeDto,
  WeeklyInfoDto,
} from './response'

// const delay = async (ms: number) => new Promise((resolve) => setTimeout(resolve, ms))

export const studentHomeApi = {
  fetchWeeklyInfo: async (date?: string) => {
    const { data } = await maxios.get<WeeklyInfoDto>('/student-home/weekly', { params: { date } })
    return data
  },

  fetchRecentStudies: async (params: RecentStudiesRequestDto) => {
    const { data } = await maxios.get<RecentStudyDto>(`/student-home/materialized-view`, {
      params,
    })
    return data
  },

  fetchRecentChallenges: async () => {
    const { data } = await maxios.get<RecentChallengeDto | null>('/student-home/recently-challenge')
    return data
  },

  fetchUserOpinion: async (opinion: string) => {
    return maxios.post(`/student-home/opinion`, {
      opinion,
    })
  },

  fetchSmileChallenge: async (studentId: string, curriculumKey?: string) => {
    const { data } = await maxios.get<SmileChallengeDto>(
      `/student-history/achievement/student/${studentId}/strengths-and-weaknesses-chips`,
      {
        params: {
          curriculumKey,
        },
      },
    )
    return data
  },
}
