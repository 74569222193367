import { css } from '@emotion/react'
import type { FC } from 'react'

import { colors, typo } from '~/@common/styles'
import IconButton from '~/@common/ui/(Button)/IconButton/IconButton'

interface Props {
  hasMore?: boolean
  onClick: () => void
}

export const kind = `ms__MoreButton` as const

const MoreButton: FC<Props> = ({ hasMore, onClick }) => {
  return (
    <div css={_Style} data-component={kind}>
      <IconButton
        name="icon_chevron_down"
        rotate={hasMore ? 0 : 180}
        LeftSlot={hasMore ? '더보기' : '접기'}
        onClick={onClick}
      />
    </div>
  )
}

export default MoreButton

const _Style = css`
  ${typo.body02};
  color: ${colors.gray.$600};
  height: 41px;
  display: flex;
  align-items: center;
  justify-content: center;
`
