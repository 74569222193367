import * as S from '@effect/schema/Schema'
import { CalendarDateString, ISODateString } from '@mathflat/shared/@common/schema/schema'
import { apiSpec } from '@mathflat/shared/@modules/ApiSpec/apiSpec.ts'
import type { ApiSpecOutputData, ApiSpecRouteParams } from '@mathflat/shared/@modules/ApiSpec/types'

import { ConceptChipDomain } from '~/@entities/ConceptChip/domain.ts'
import { StudentDomain } from '~/@entities/Student/domain.ts'

import { ProblemDomain } from '..'

export module StudentHistoryApi {
  /**
   * 성취도 등급 조회 <br/>
   * link: https://freewheelin.postman.co/workspace/ef6c4fe1-201a-4de6-8b0c-2a7de439d3ff/request/12065882-f181e817-4695-4204-be82-2491251459b1?ctx=documentation
   */
  export module 성취도_등급_조회 {
    export const ConceptChip = S.struct({
      conceptChipId: S.number,
      achievementLevel: ConceptChipDomain.LevelOfAchievementTo,
      studentId: StudentDomain.Id,
      studentName: S.string,
      latestStudyLog: S.struct({
        datetime: ISODateString,
        // tagTop? (근데 왜 title이라 지었지?)
        title: S.string,
      }),
    })
    export type ConceptChip = S.Schema.To<typeof ConceptChip>

    export const spec = apiSpec({
      method: 'GET',
      path: 'student-history/achievement/student/:studentId',
      params: S.struct({
        curriculumKey: S.string,
        requestDate: S.optional(CalendarDateString),
      }),
      responseData: S.array(ConceptChip),
    })

    export type PathParams = ApiSpecRouteParams<typeof spec>
    export type Params = ApiSpecRouteParams<typeof spec>
    export type Output = ApiSpecOutputData<typeof spec>
  }
  /**
   * 최고 등급, 취약 유형 칩 1개씩 가져오기 <br/>
   * 매번 호출할때마다 랜덤으로 가져옴 <br/>
   * link: https://freewheelin.postman.co/workspace/ef6c4fe1-201a-4de6-8b0c-2a7de439d3ff/request/12065882-ef0b842c-0539-46cc-9114-04733069748c?ctx=documentation
   */
  export module 최고_취약_유형칩 {
    export const Chip = S.struct({
      conceptChipId: S.number,
      conceptId: S.number,
      conceptName: S.string,
      levelOfAchievement: ConceptChipDomain.LevelOfAchievement,
      levelOfConceptChip: ConceptChipDomain.Type, //  난이도 3단계?
    })
    export const spec = apiSpec({
      method: 'GET',
      path: 'student-history/achievement/student/:studentId/strengths-and-weaknesses-chips',
      responseData: S.struct({
        strengths: Chip,
        weaknesses: Chip,
      }),
    })

    export type PathParams = ApiSpecRouteParams<typeof spec>
    export type Output = ApiSpecOutputData<typeof spec>
  }
  /**
   * 성취도 등급 상세보기 <br/>
   * link: https://freewheelin.postman.co/workspace/ef6c4fe1-201a-4de6-8b0c-2a7de439d3ff/request/12065882-ef0b842c-0539-46cc-9114-04733069748c?ctx=documentation
   */
  export module 성취도_등급_상세 {
    export const ChipProblem = S.struct({
      problemId: ProblemDomain.IdInTheWorkbook,
      answer: S.string,
      type: ProblemDomain.Type,
      problemImageUrl: S.nullable(S.string),
      answerImageUrl: S.nullable(S.string),
      solutionImageUrl: S.nullable(S.string),
    })

    export const spec = apiSpec({
      method: 'GET',
      path: 'student-history/achievement/student/:studentId/concept-chips/:conceptChipId',
      params: S.optional(
        S.struct({
          requestDate: ISODateString,
        }),
      ),
      responseData: S.array(
        S.struct({
          // 콘텐츠 타입
          type: S.literal('WORKSHEET', 'WORKBOOK', 'CHALLENGE'),
          contentTitle: S.string,
          contentProblemNumber: S.string,
          contentPageNumber: S.nullable(S.string),
          result: ProblemDomain.ScoringResult,
          userAnswer: S.nullable(S.string),
          datetime: ISODateString,
          problem: ChipProblem,
          orderNumber: S.number,
        }),
      ),
    })

    export type PathParams = ApiSpecRouteParams<typeof spec>
    export type Output = ApiSpecOutputData<typeof spec>
  }
}
