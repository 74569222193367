import styled from '@emotion/styled'
import { s3URL } from '@mathflat/shared/@common/utils/s3'
import { useRepository } from '~/@common/hooks/useRepository'
import modalService from '~/@common/services/modal.service'
import { colors, typo } from '~/@common/styles'
import Modal from '~/@common/ui/modal/Modal'
import { StudentExamService } from '../@service/StudentExamPage.service'
import { generatePath, useNavigate } from 'react-router'
import { routeName } from '~/@common/constants'
import { format } from 'date-fns'
import { MAAT_ROUND1_DATE } from '~/@common/constants/MAAT'
import { ko } from 'date-fns/locale'
import qs from 'qs'
import { CustomEventService } from '~/@common/services/event.service'
import RecentStudyService from '~/@pages/student/student-home/@service/RecentStudy.service'

type Props = {
  title: string
  studentExamId: number
}

const StudentMAATExamModal = ({ title, studentExamId }: Props) => {
  const service = useRepository(StudentExamService)
  const recentStudyService = useRepository(RecentStudyService)
  const navigate = useNavigate()

  return (
    <Modal.Confirm.Positive
      confirm={{
        children: '응시하기',
        onClick: async () => {
          try {
            await service.startMAATExam(studentExamId)
            modalService.closeModal()
            navigate({
              pathname: generatePath(routeName.student.studentExamScoringById, { studentExamId }),
              search: qs.stringify({ title, examType: 'MAAT' }),
            })
            CustomEventService.MAATTimerOn.dispatch()
          } catch (error) {
            modalService.closeModal()
            modalService.openModal(
              <Modal.Alert
                confirm={{
                  children: '확인하기',
                  onClick: () => {
                    recentStudyService.loadRecentStudies('ALL')
                    navigate(routeName.student.defaultPath)
                    modalService.closeModal()
                  },
                }}
                children={<>응시 기간이 종료되었습니다</>}
              />,
              { modalName: 'MAATExamEnd' },
            )
          }
        },
      }}
      cancel={{
        children: '다음에 하기',
        onClick: () => {
          modalService.closeModal()
        },
      }}
    >
      <S.Container>
        <S.Header>
          <img
            height="60px"
            src={s3URL.common('images/maat/MAAT_LOGO.png')}
            srcSet={`${s3URL.common('images/maat/MAAT_LOGO@2x.png')} 2x, ${s3URL.common('images/maat/MAAT_LOGO@3x.png')} 3x`}
          />
        </S.Header>
        <S.Main>
          <article>
            <h6>MAAT 수학경시대회란?</h6>
            MAAT 수학경시대회는 권위 있는 한국수학교육학회와 협력하여 매쓰플랫에서 제공하는
            서비스입니다.
          </article>
          <article>
            <h6>시험 일정 안내</h6>
            <p>시험은 매년 2회 진행되며, 1학기 시험 일정은 다음과 같습니다.</p>
            <S.TextWithDot>
              응시 기간:{' '}
              <strong>
                {format(MAAT_ROUND1_DATE.응시시작, 'MM/dd(E)', { locale: ko })} ~{' '}
                {format(MAAT_ROUND1_DATE.응시종료, 'MM/dd(E) HH시 mm분', { locale: ko })}
              </strong>
              까지
            </S.TextWithDot>
            <S.TextWithDot>
              결과 확인:{' '}
              <strong>{format(MAAT_ROUND1_DATE.결과확인, 'MM/dd(E)', { locale: ko })}</strong>
            </S.TextWithDot>
          </article>
          <article>
            <h6>응시 방법 및 유의사항</h6>
            <p>
              응시시간은 <strong className="red">90분</strong>입니다.
            </p>
            <p>시험 시작 후 90분이 지나면 자동으로 제출됩니다.</p>
            <S.TextWithDot>풀지 않은 문제는 오답으로 처리됩니다.</S.TextWithDot>
            <S.TextWithDot>한번 제출하고 나면 수정이 불가합니다.</S.TextWithDot>
            <S.TextWithDot className="red">
              제출 버튼을 누르지 않고 앱 종료시 제출이 되지 않습니다.
            </S.TextWithDot>
            <p>응시 전 배터리를 확인하고 앱 강제 종료 등 예외적인 상황에 대비해 주세요.</p>
            <S.TextWithDot>앱이 종료되더라도 시험 시간은 계속 흐릅니다.</S.TextWithDot>
            <S.TextWithDot>안전한 네트워크 환경에서 시험 응시를 준비하세요.</S.TextWithDot>
            <S.TextWithDot>로그아웃시 입력한 정답은 삭제됩니다.</S.TextWithDot>
          </article>
        </S.Main>
      </S.Container>
    </Modal.Confirm.Positive>
  )
}

const S = {
  Container: styled.div``,
  Header: styled.header`
    display: flex;
    align-items: center;
    justify-content: center;

    height: 60px;

    margin-bottom: 16px;
  `,
  Main: styled.main`
    display: flex;
    flex-direction: column;
    gap: 16px;

    .red {
      color: ${colors.red.$400};
      font-weight: bold;
    }

    > article {
      text-align: left;

      > h6 {
        font-weight: 700;
        ${typo.body02};
      }

      > p,
      span {
        ${typo.caption01};
      }
    }
  `,
  TextWithDot: styled.p`
    position: relative;
    ${typo.caption01};
    margin-left: 16px;

    ::before {
      content: '';
      position: absolute;
      left: -9px;
      top: 50%;
      transform: translateY(-50%);
      width: 4px;
      height: 4px;
      background-color: ${colors.gray.$900};
      border-radius: 50%;
    }
  `,
}

export default StudentMAATExamModal
