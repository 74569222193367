import type { StudentDomain } from '@mathflat/domain/@entities/Student/domain'

import { academyApi } from '~/@common/api/academyApi'
import { curriculumApi } from '~/@common/api/curriculumApi'
import { studentApi } from '~/@common/api/studentApi'
import { remoteStorageService } from '~/@common/services/remoteStorage.service'
import { commonRepo } from '~/@common/services/repo.service'

export const loadInitialData = async (studentId = localStorage.getItem('studentId')) => {
  if (!studentId) {
    throw Error('studentId가 없습니다.')
  }

  remoteStorageService.initRelationId(studentId)
  remoteStorageService.get('isVisitedSelfLearning')
  remoteStorageService.get('isCompletedSelfLearning')

  Promise.all([
    academyApi.getAcademies(),
    studentApi.getStudentAppSettings(studentId as StudentDomain.Id),
    studentApi.getStudentById(studentId as StudentDomain.Id),
    curriculumApi.getCurriculumTree(),
  ])
    .then(([academy, appSetting, student, curriculumTrees]) => {
      commonRepo.setAcademy(academy)
      commonRepo.setStudent({ ...student.data, appSetting })
      commonRepo.setCurriculumTree(curriculumTrees)
    })
    .catch((e) => {
      console.error(e)
    })
}
