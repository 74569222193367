import { css } from '@emotion/react'
import { observer } from 'mobx-react'
import { useEffect, useMemo } from 'react'
import SimpleBar from 'simplebar-react'

import { useStudentAppMediaQuery } from '~/@common/hooks/useMediaQuery.ts'
import { useRepository } from '~/@common/hooks/useRepository'
import { errorHandlerService } from '~/@common/services'
import { localStorageService } from '~/@common/services/storage.service.ts'
import { mediaQuery } from '~/@common/styles/mediaQuery'
import Button from '~/@common/ui/(Button)/Button/Button.tsx'
import ConceptsItem from '~/@pages/@common/(LectureMaterial)/ConceptImageItem'
import ItemGroup from '~/@pages/@common/(LectureMaterial)/GroupBox'
import type { Lecture, RawLecture } from '~/@pages/@common/(LectureMaterial)/LectureVideoItem'
import NoData from '~/@pages/@common/(LectureMaterial)/NoData'
import { LearningProcessService } from '~/@pages/student/learning-process/@widgets/service/LearningProcess.service'

import LectureMaterialSection from './LectureMaterialSection'

const 개념학습 = () => {
  const service = useRepository(LearningProcessService)
  const 개념학습정보 = service.개념학습정보
  const isVisitedConceptLearning =
    localStorageService.learningProcess.isVisitedConceptLearning === 'TRUE'

  const { isMobile } = useStudentAppMediaQuery()

  const lectureMapper = (src: RawLecture): Lecture => {
    return {
      ...src.lecture,
      studentLectureCreateDatatime: src.studentLecture?.createDatetime,
    }
  }

  const lectures = useMemo(() => {
    return 개념학습정보?.lectures.map(lectureMapper) ?? []
  }, [개념학습정보?.lectures])

  const prevLectures = useMemo(() => {
    return 개념학습정보?.prevLectures.map(lectureMapper) ?? []
  }, [개념학습정보?.prevLectures])

  const concepts = 개념학습정보?.concepts ?? []
  const prevConcepts = 개념학습정보?.prevConcepts ?? []

  const dataStatus = {
    hasLectures: lectures.length > 0,
    hasPrevLectures: prevLectures.length > 0,
    hasConcepts: concepts.length > 0,
    hasPrevConcepts: prevConcepts.length > 0,
  } as const

  useEffect(() => {
    service.개념학습정보_조회().catch(errorHandlerService.handle)
  }, [service])

  useEffect(() => {
    if (!isVisitedConceptLearning) {
      localStorageService.update({
        learningProcess: {
          isVisitedConceptLearning: 'TRUE',
        },
      })
    }
  }, [isVisitedConceptLearning])

  if (!개념학습정보) return null

  const isAllEmpty = Object.values(dataStatus).every((v) => !v)

  if (isAllEmpty) {
    return (
      <div css={_css} className="empty">
        <NoData
          iconSize="normal"
          content={
            <>
              개념학습을 준비중이에요.
              <br />
              문제를 먼저 풀고 오답 유형을 학습해보세요.
            </>
          }
        >
          <Button minWidth="180px" theme="primary" onClick={() => service.goToNextStep()}>
            문제 풀어보기
          </Button>
        </NoData>
      </div>
    )
  }

  return (
    <div css={_css}>
      <div className="video-section-container">
        <LectureMaterialSection lectures={lectures} prevLectures={prevLectures} />
      </div>

      {dataStatus.hasConcepts || dataStatus.hasPrevConcepts ? (
        <div css={{ width: isMobile ? 'unset' : '50%', overflow: isMobile ? 'unset' : 'hidden' }}>
          <SimpleBar style={{ maxHeight: '100%', overflow: isMobile ? 'hidden' : 'unset' }}>
            <div className="concepts-section">
              <div className="concepts-list">
                <ItemGroup type="concepts" isPreLearned={false}>
                  {dataStatus.hasConcepts ? (
                    concepts.map((item, index) => (
                      <ConceptsItem
                        key={item.id}
                        index={index}
                        item={item}
                        isPreLearned={false}
                        isDefaultOpened={dataStatus.hasConcepts && index === 0}
                      />
                    ))
                  ) : (
                    <NoData
                      iconSize={isMobile ? 'small' : 'normal'}
                      content="개념 이미지가 아직 준비되지 않았어요."
                    />
                  )}
                </ItemGroup>
              </div>
              <div className="concepts-list">
                <ItemGroup type="concepts" isPreLearned={true}>
                  {dataStatus.hasPrevConcepts ? (
                    prevConcepts.map((item, index) => (
                      <ConceptsItem
                        key={item.id}
                        index={index}
                        item={item}
                        isPreLearned={true}
                        isDefaultOpened={
                          !dataStatus.hasConcepts && dataStatus.hasPrevConcepts && index === 0
                        }
                      />
                    ))
                  ) : (
                    <NoData
                      iconSize={isMobile ? 'small' : 'normal'}
                      content="개념 이미지가 아직 준비되지 않았어요."
                    />
                  )}
                </ItemGroup>
              </div>
            </div>
          </SimpleBar>
        </div>
      ) : (
        <div className="empty-video-section">
          <div className="empty-notice">
            <NoData
              iconSize="normal"
              content={
                <>
                  개념 이미지가 아직 준비되지 않았어요.
                  <br />
                  개념영상을 보고 문제를 풀어보세요.
                </>
              }
            >
              {isMobile && (
                <Button
                  theme="primary"
                  style={{ margin: '0 16px' }}
                  onClick={() => service.goToNextStep()}
                >
                  다음 단계
                </Button>
              )}
            </NoData>
          </div>
        </div>
      )}
      {isMobile && (
        <div className="action-next-step">
          <Button
            theme="primary"
            style={{
              width: '100%',
            }}
            onClick={() => service.goToNextStep()}
          >
            다음 단계
          </Button>
        </div>
      )}
    </div>
  )
}

export default observer(개념학습)

const _css = css`
  display: flex;
  gap: 24px;
  height: 100%;

  ${mediaQuery.underTablet} {
    flex-direction: column;
    gap: 20px;
    height: max-content;
    overflow: auto;
    padding: 0 0 40px;
    height: 100%;
  }

  &.empty {
    display: flex;
    flex-direction: column;
    justify-content: center;

    ${mediaQuery.underTablet} {
      padding-top: 20px;
    }
  }

  .video-section-container {
    display: flex;
    width: 50%;

    ${mediaQuery.underTablet} {
      width: 100%;
    }
  }

  .concepts-section {
    height: 100%;
    overflow: auto;
    display: flex;
    flex-direction: column;
    gap: 12px;

    ${mediaQuery.underTablet} {
      gap: 10px;
      width: 100%;
      height: fit-content;
      overflow: visible;
      padding: 0 16px;
    }
  }

  .concepts-list {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  .empty-video-section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 50%;
    ${mediaQuery.underTablet} {
      padding: 0 16px;
    }

    ${mediaQuery.underTablet} {
      width: 100%;
    }

    .empty-notice {
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 100%;
    }
  }
  ${mediaQuery.underTablet} {
    .action-next-step {
      padding: 0 16px;
    }
  }
`
