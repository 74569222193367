import styled from '@emotion/styled'
import React, { type ComponentPropsWithoutRef, type PropsWithChildren } from 'react'

import Button from '~/@common/ui/(Button)/Button/Button'

export type 자동채점폼TProps = {
  onSubmitClick?: ComponentPropsWithoutRef<'button'>['onClick']
  buttonText?: ComponentPropsWithoutRef<'button'>['children']
  opt?: { buttonProps: Omit<ComponentPropsWithoutRef<'button'>, 'onClick' | 'children'> }
  className?: ComponentPropsWithoutRef<'button'>['className']
} & PropsWithChildren

const 자동채점폼 = React.forwardRef<HTMLButtonElement, 자동채점폼TProps>(
  ({ onSubmitClick, buttonText, children, opt, className }, ref) => {
    return (
      <S.자동채점폼 onSubmit={(e) => e.preventDefault()} className={className}>
        <div className="auto-marking-area">{children}</div>
        <Button
          theme="primary"
          type="submit"
          size="small"
          minWidth={136}
          ref={ref}
          onClick={onSubmitClick}
          {...opt?.buttonProps}
        >
          {buttonText}
        </Button>
      </S.자동채점폼>
    )
  },
)

자동채점폼.displayName = '자동채점폼'

export default 자동채점폼

const S = {
  자동채점폼: styled.form`
    display: flex;
    align-items: center;
    gap: 20px;
    /* width: 496px; */
    margin-left: auto;

    &.conceptual-form {
      width: 100%;

      .auto-marking-area {
        width: 316px;
      }
    }

    .auto-marking-area {
      flex: 1 1 100%;
      margin-left: auto;
    }
  `,
}
