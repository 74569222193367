import Modal from '~/@common/ui/modal/Modal'

export const ScoringSubmitConfirmModal = ({
  onSubmit,
  onClick,
  content,
}: {
  onSubmit?: () => void
  onClick?: () => void
  content?: React.ReactElement
}) => {
  return (
    <Modal.Confirm.Positive
      confirm={{
        onClick: () => {
          onClick?.()
          onSubmit?.()
        },
        children: '제출하기',
      }}
      cancel={{
        children: '취소하기',
      }}
    >
      <p
        style={{
          textAlign: 'center',
        }}
      >
        {content ?? (
          <>
            채점 내용을 제출하면 다시 수정할 수 없습니다.
            <br />
            채점 내용을 선생님께 제출하시겠습니까?
          </>
        )}
      </p>
    </Modal.Confirm.Positive>
  )
}
