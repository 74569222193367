import styled from '@emotion/styled'

import { observer } from 'mobx-react'
import { useEffect } from 'react'
import { useNavigate } from 'react-router'

import { routeName } from '~/@common/constants'
import { useCallbackOnVisibilityChange } from '~/@common/hooks/useCallbackOnVisibilityChange'
import { useRepository } from '~/@common/hooks/useRepository'
import { commonRepo } from '~/@common/services/repo.service'

import { LectureService } from './@service/Lecture.service'
import LectureGroupList from './@widgets/LectureGroupList.widget'
import LectureGroupHeader from './@widgets/LectureGroupHeader.widget'
import { mediaQuery } from '~/@common/styles/mediaQuery'

let init = false

const LectureListPage = () => {
  useCallbackOnVisibilityChange()

  const navigate = useNavigate()
  const service = useRepository(LectureService)

  useEffect(() => {
    if (commonRepo.studentAppSetting && !commonRepo.studentAppSetting.lectureService) {
      navigate(routeName.student.defaultPath, { replace: true })
    }
  }, [commonRepo.studentAppSetting])

  useEffect(() => {
    if (
      !commonRepo.curriculumTreeColl ||
      !commonRepo.studentId ||
      !service.selectedCurriculumInfo
    ) {
      return
    }
    // 타 탭 이동했다가 왔을 때 드롭다운 유지하기 위해서
    if (init) return
    service.setCurriculumAndFetchLectureGroups({
      curriculumInfo: service.selectedCurriculumInfo,
      studentId: commonRepo.studentId,
    })
    init = true
  }, [commonRepo.studentId, service.selectedCurriculumInfo, service.selectedCurriculumInfo])

  if (!service.selectedCurriculumInfo) {
    return <></>
  }

  return (
    <S.LectureListPage>
      <LectureGroupHeader />
      <LectureGroupList lectureGroups={service.lectureGroups} isFetching={service.isFetching} />
    </S.LectureListPage>
  )
}

export default observer(LectureListPage)

const S = {
  LectureListPage: styled.div`
    display: flex;
    gap: 20px;
    flex-direction: column;
    height: 100%;

    ${mediaQuery.underTablet} {
      margin-top: 10px;
    }
  `,
}
