import { differenceInMinutes, differenceInSeconds } from 'date-fns'
import { useEffect, useState } from 'react'

type Props = {
  isUsingZeroPadding?: boolean
}

const zeroPadded = (num: number) => num.toString().padStart(2, '0')

const useTimer = ({ isUsingZeroPadding }: Props) => {
  const [isRunning, setIsRunning] = useState(false)
  const [isTimeUp, setIsTimeUp] = useState(false)

  const [leftTime, setLeftTime] = useState({
    minute: 0,
    second: 0,
  })

  useEffect(() => {
    const intervalTimer = setInterval(() => {
      setLeftTime(({ minute, second }) => {
        if (minute < 1 && second < 1) {
          if (isRunning) {
            setIsTimeUp(true)
          }

          return { minute: 0, second: 0 }
        }
        if (second < 1) {
          return { minute: minute - 1, second: 59 }
        }
        return { minute, second: second - 1 }
      })
    }, 1000)

    return () => {
      clearInterval(intervalTimer)
    }
  }, [isRunning])

  const setTimer = ({
    currentDatetime,
    startDatetime,
    timeLimit,
  }: {
    startDatetime: string
    timeLimit: number
    currentDatetime: string
  }) => {
    const _minutesLeft = differenceInMinutes(
      new Date(new Date(startDatetime).getTime() + timeLimit),
      new Date(currentDatetime),
    )
    const _secondsLeft =
      differenceInSeconds(
        new Date(new Date(startDatetime).getTime() + timeLimit),
        new Date(currentDatetime),
      ) % 60

    if (_minutesLeft > 0 || (_minutesLeft === 0 && _secondsLeft > 0)) {
      setIsRunning(true)
    }

    setLeftTime({
      minute: _minutesLeft < 0 ? 0 : _minutesLeft,
      second: _secondsLeft < 0 ? 0 : _secondsLeft,
    })
  }

  return {
    minute: isUsingZeroPadding ? zeroPadded(leftTime.minute) : leftTime.minute,
    second: isUsingZeroPadding ? zeroPadded(leftTime.second) : leftTime.second,
    secondsLeft: leftTime.minute * 60 + leftTime.second,
    isRunning,
    isTimeUp,
    setTimer,
  }
}

export default useTimer
