import { css } from '@emotion/react'
import React from 'react'

import modalService from '~/@common/services/modal.service.tsx'
import Modal from '~/@common/ui/modal/Modal.tsx'

const 챌린지학습지완료모달 = () => {
  return (
    <Modal.Alert
      confirm={{
        children: '닫기',
        onClick: () => {
          modalService.closeModal()
        },
      }}
    >
      <div css={_css}>
        <img src={'/images/icons/icon_pen_and_star.svg'} width={200} height={54} alt="" />
        <p>
          축하합니다! 첫 챌린지를 완료했어요!
          <br />
          다른 유형도 학습해보세요
        </p>
      </div>
    </Modal.Alert>
  )
}

export default 챌린지학습지완료모달

const _css = css`
  display: flex;
  flex-direction: column;
  gap: 24px;
`
