import { action, computed, makeObservable, observable } from 'mobx'

import { studentHomeApi } from '../@common/api'
import WeeklyInfo from '../@common/model/WeeklyInfo'
import type { WeeklyInfoDto } from '../@common/response'

// const delay = () => new Promise((resolve) => setTimeout(resolve, 3000))

export default class WeeklyInfoService {
  private _weeklyInfo?: WeeklyInfo
  private _lastWeeklyInfo?: WeeklyInfo

  constructor() {
    makeObservable<this, '_weeklyInfo' | '_lastWeeklyInfo'>(this, {
      _weeklyInfo: observable,
      _lastWeeklyInfo: observable,
      isReady: computed,
      setWeeklyInfo: action,
      setLastWeeklyInfo: action,
    })
  }

  get weeklyInfo() {
    return this._weeklyInfo
  }

  get lastWeeklyInfo() {
    return this._lastWeeklyInfo
  }

  get isReady() {
    return this._weeklyInfo !== undefined
  }

  async loadWeeklyInfo() {
    const data = await studentHomeApi.fetchWeeklyInfo()
    this.setWeeklyInfo(data)
  }

  async loadLastWeeklyInfo(date: string) {
    const data = await studentHomeApi.fetchWeeklyInfo(date)
    this.setLastWeeklyInfo(data)
  }

  setWeeklyInfo(data: WeeklyInfoDto) {
    if (!this._weeklyInfo) {
      this._weeklyInfo = new WeeklyInfo()
    }
    this._weeklyInfo.setData(data)
  }

  setLastWeeklyInfo(data: WeeklyInfoDto) {
    if (!this._lastWeeklyInfo) {
      this._lastWeeklyInfo = new WeeklyInfo()
    }
    this._lastWeeklyInfo.setData(data)
  }
}
