import { css } from '@emotion/react'

export const fontFamily = {
  default: css`
    font-family:
      'Spoqa Han Sans Neo',
      system-ui,
      -apple-system,
      BlinkMacSystemFont,
      'Segoe UI',
      Roboto,
      Oxygen,
      Ubuntu,
      Cantarell,
      'Open Sans',
      'Helvetica Neue',
      sans-serif;
  `,
  montserrat: css`
    font-family:
      Montserrat,
      'Spoqa Han Sans Neo',
      system-ui,
      -apple-system,
      BlinkMacSystemFont,
      'Segoe UI',
      Roboto,
      Oxygen,
      Ubuntu,
      Cantarell,
      'Open Sans',
      'Helvetica Neue',
      sans-serif;
  `,
}
