import type {
  HomeworkI,
  LearningContentStatus,
} from '@mathflat/domain/@entities/StudentWorksheet/api2'

import type { IconNames } from '~/@common/ui/Icon/iconNames.type'

import type { StudentSelfLearningWorksheetGroupList, StudentWorksheetList } from '.'

export class StudentHomeworkWorksheetList implements StudentWorksheetList {
  id: number
  studentId: string
  assignedDatetime: Date
  completeDatetime: Date | null
  completed: boolean
  status: LearningContentStatus
  page: number | null
  constructor(homework: HomeworkI) {
    this.id = homework.id
    this.studentId = homework.studentId
    this.assignedDatetime = new Date(homework.assignedDatetime)
    this.completeDatetime = homework.completeDatetime ? new Date(homework.completeDatetime) : null
    this.completed = homework.completed
    this.status = homework.status
    this.page = homework.page
  }
  checkIsSelfLearningCtaVisible() {
    return null
  }

  getIconName(): IconNames {
    throw new Error('Method not implemented.')
  }
  getLabels(): ('숙제' | '비공개' | '자동채점')[] {
    throw new Error('Method not implemented.')
  }
  getTitle(): string {
    throw new Error('Method not implemented.')
  }
  getFormattedAssignDatetime(): string {
    throw new Error('Method not implemented.')
  }
  getProblemCount(): number {
    throw new Error('Method not implemented.')
  }
  getScore(): number {
    throw new Error('Method not implemented.')
  }
  getWrongCount(): number {
    throw new Error('Method not implemented.')
  }
  getCorrectCount(): number {
    throw new Error('Method not implemented.')
  }
  getAssignDatetime(): Date {
    return this.assignedDatetime
  }
  getSelfLearning(): StudentSelfLearningWorksheetGroupList[] | null {
    return null
  }
  checkIsExam(): boolean {
    return false
  }

  getStatus(): LearningContentStatus {
    return this.status
  }
  checkIsHomework(): boolean {
    return true
  }
  getRoute(): string {
    throw new Error('Method not implemented.')
  }
}
