import styled from '@emotion/styled'

import { colors, typo } from '~/@common/styles'
import { mediaQuery } from '~/@common/styles/mediaQuery'

const S = {
  Container: styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;

    &.is-ai-tutor-opened {
      align-items: flex-end;
    }
  `,
  ContentsTab: styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;

    &.is-ai-tutor-opened {
      max-width: calc(50vw + 48px);
      min-width: calc(50vw + 28px);

      ${mediaQuery.underDesktop} {
        min-width: 669px;
      }
    }

    .problem-tab {
      display: flex;
      margin-left: 16px;
      cursor: pointer;

      .disabled-tab {
        background-color: ${colors.gray.$200};
      }
      > div {
        display: flex;
        align-items: center;
        padding: 20px;
        font-weight: 700;
        background-color: ${colors.white};
        border-radius: 14px 14px 0 0;

        > p {
          margin-left: 4px;
        }

        .title {
          font-size: 16px;
          font-weight: 700;
          margin-bottom: 27px;
        }
      }
    }
  `,
  ContentsTabMobile: styled.div`
    display: flex;
    width: 100%;
    border-top: 1px solid ${colors.gray.$300};
    background-color: ${colors.white};
    height: 50px;

    .tab {
      display: flex;
      width: 50%;
      padding: 15px 0;
      justify-content: center;
      align-items: center;
      font-size: 12px;
      gap: 4px;
      font-weight: 700;
      color: ${colors.gray.$700};
    }

    .active-tab {
      color: ${colors.blue.$500};
      background-color: ${colors.blue.$110};
    }
  `,
  Contents: styled.div`
    position: relative;
    display: flex;
    gap: 24px;
    height: 100%;
    width: 100%;
    overflow: hidden;

    .problem {
      display: flex;
      flex-direction: column;
      flex: 1;
      height: 100%;
      background-color: ${colors.white};
      border-radius: 14px 0 14px 14px;
      width: 100%;
      min-width: 690px;
      ${mediaQuery.underDesktop} {
        min-width: 484px;
      }

      &.is-ai-tutor-opened {
        background-color: transparent;
      }
    }

    .problem-video {
      display: flex;
      flex-direction: column;
      padding: 20px;
      flex: 1;
      height: 100%;
      position: relative;
    }
    .problem-infos {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 20px;
      background-color: ${colors.gray.$100};
      padding: 10px 14px;
      border-radius: 10px;
      > p {
        font-weight: 700;
      }
    }
    .title-container {
      display: flex;
      align-items: center;
      > p {
        font-size: 12px;
      }
    }
    .title {
      margin-right: 6px;
      font-weight: 700;
    }
    .problem-image-container {
      display: flex;
      flex: 1;
      justify-content: space-between;
      gap: 2px;
      overflow: auto;
    }
    .problem-image {
      max-height: calc(100% - 36px);
      overflow: scroll;
      > img {
        max-width: 344px;
      }
    }
    .problem-button {
      display: flex;
      align-items: flex-end;
      justify-content: flex-end;
    }
    .problem-swiper {
      position: absolute;
      width: 119px;
      left: 50%;
      transform: translate(-50%, 0);
    }
    .info-container {
      display: flex;
      align-items: center;
    }
    .info {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 10px;
      padding-right: 10px;
      border-right: 1px solid ${colors.gray.$500};
      height: 14px;
      cursor: pointer;
      > p {
        color: ${colors.gray.$600};
        margin-right: 4px;
      }
      .bold {
        font-weight: 700;
        color: ${colors.gray.$900};
      }
      .circle {
        border: 1px solid ${colors.gray.$900};
        border-radius: 50%;
        text-align: center;
        line-height: 16px;
        width: 16px;
        height: 16px;
        font-size: 12px;
      }
      &:last-of-type {
        border: none;
        margin-right: 0px;
        padding-right: 0px;
      }
    }
    .info__right-switch {
      gap: 6px;
      ${typo.caption01}
      color: ${colors.black};
    }
    .answer-check {
      cursor: pointer;
    }
    .similar-problem-infos {
      display: flex;
      justify-content: space-between;
      margin-bottom: 27px;
      > div {
        display: flex;
        align-items: center;
      }
      .info-1 {
        font-size: 16px;
        color: ${colors.gray.$900};
        font-weight: 700;
      }
      .info-2 {
        background-color: ${colors.gray.$200};
        border-radius: 8px;
        padding: 4px 12px;
        margin: 0 10px;
        font-weight: 700;
      }
      .info-3 {
        display: flex;
        align-items: center;
        justify-content: center;
        color: ${colors.gray.$700};
        font-weight: 700;
        border-right: 1px solid ${colors.gray.$500};
        height: 14px;
        padding-right: 4px;
        margin-right: 4px;
        &:last-of-type {
          border-right: none;
        }
      }
    }
    .similar-problem-image-container {
      display: flex;
      justify-content: space-between;
      height: 100%;
    }

    .scoring-form {
      display: flex;
      height: 100%;
      align-items: flex-end;
    }

    .solution-image-container {
      overflow: auto;
      width: 100%;
      height: 100%;
      .answer-image {
        width: 100%;
        height: auto;
      }
    }
  `,
  ContentsMobile: styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    overflow-y: auto;

    .video-tab-contents {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
    .card-container {
      display: flex;
      flex-direction: column;
      gap: 10px;
      height: 100%;
      overflow-y: auto;
      padding: 20px 16px 0;
    }
    .card-title {
      font-size: 18px;
      color: ${colors.gray.$900};
      font-weight: 700;
    }
    .problem-swiper-mobile {
      width: 100%;
    }
    .scoring-swiper-container {
      height: 100%;
      padding: 20px 16px 40px;
    }
    .handwritten-info-tooltip {
      left: 40px;
      bottom: calc(40px + 20px + 48px); // 버튼 margin-bottom + 버튼 height + 버튼 margin-top
      width: calc(100% - 80px); // left * 2
    }
  `,
  AnswerCircle: styled.div`
    display: flex;
    align-items: flex-end;
    > div {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 24px;
      height: 24px;
      padding-top: 1px;
      border: 1px solid ${colors.gray.$700};
      border-radius: 50%;
      font-weight: normal;
      ${typo.body02};
    }
    > p {
      margin-right: 5px;
    }
  `,
  EmptyView: styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    flex-direction: column;
    position: relative;
    > img {
      margin-bottom: 15px;
    }
    > button {
      position: absolute;
      bottom: 20px;
      right: 20px;
    }
  `,
}

export default S
