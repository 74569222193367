import { keyframes as _keyframes } from '@emotion/react'

export const keyframes = {
  rotation: _keyframes`
    from {
      -webkit-transform: rotate(0deg);
              transform: rotate(0deg);
    } 
    to {
      -webkit-transform: rotate(360deg);
              transform: rotate(360deg);
    }
`,
}
