import { css } from '@emotion/react'
import { observer } from 'mobx-react'
import type { MouseEvent } from 'react'
import type { FC } from 'react'

import { useRepository } from '~/@common/hooks/useRepository'
import { colors, zIndex } from '~/@common/styles'
import { ButtonModule } from '~/@common/ui/(Button)/Button'
import Button from '~/@common/ui/(Button)/Button/Button'
import ButtonSegmentedControl from '~/@common/ui/(Button)/ButtonSegmentedControl/ButtonSegmentedControl'
import {
  SwiperControllerConsumerName,
  useSwiperControllerContext,
} from '~/@common/ui/SwiperController/SwiperController.context'
import type { ProblemScoring } from '~/@pages/@common/(ProblemScoring)/@trait/ProblemScoring.trait'
import type { ProblemScoringViewOption } from '~/@pages/@common/(ProblemScoring)/@trait/ProblemScoringViewOption.trait'
import { Answer } from '~/@pages/@common/(ProblemScoring)/ProblemScoringCard/ProblemScoringHeader'
import { UserSubmittedAnswer } from '~/@pages/@widgets/(Worksheet)/WorksheetScoring/(Body)/(WorksheetScoringByOneSlideFooter)/WorksheetScoringByOneBodyFooter'

import { ConceptualLearningService } from '../service/ConceptualLearning.service'

interface Props {
  problemScoring: ProblemScoring<'WORKSHEET'>
  viewOption: ProblemScoringViewOption<'학습모듈' | 'NOT_학습모듈'> | undefined
}

const ConceptualLearningScoringFooter: FC<Props> = ({ problemScoring, viewOption }) => {
  const service = useRepository(ConceptualLearningService)
  const swiperController = useSwiperControllerContext(SwiperControllerConsumerName)

  const handleViewAnswer = (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation()
    service.setIsShowAnswerTooltip(true)
    document.body.addEventListener(
      'click',
      () => {
        service.setIsShowAnswerTooltip(false)
      },
      { once: true },
    )
  }

  return (
    <div css={_css}>
      <UserSubmittedAnswer problemScoring={problemScoring} viewOption={viewOption} />
      <div style={{ position: 'relative', marginRight: 10, marginLeft: 20 }}>
        <Button
          theme="primary"
          type="button"
          size="small"
          minWidth={136}
          onClick={handleViewAnswer}
        >
          정답보기
        </Button>
      </div>

      <ButtonSegmentedControl>
        <Button
          theme={ButtonModule.attr.theme.white}
          type="button"
          size="small"
          minWidth={68}
          disabled={swiperController.isBeginningSlide}
          onClick={() => {
            swiperController.control?.slidePrev()
          }}
        >
          이전
        </Button>
        <Button
          theme={ButtonModule.attr.theme.white}
          type="button"
          size="small"
          minWidth={68}
          disabled={swiperController.isEndSlide}
          onClick={() => {
            swiperController.control?.slideNext()
          }}
        >
          다음
        </Button>
      </ButtonSegmentedControl>

      {problemScoring.isSubmitted && service.isShowAnswerTooltip && (
        <div css={_conceptualLearningAnswerCss}>
          <Answer problemScoring={problemScoring} />
        </div>
      )}
    </div>
  )
}

export default observer(ConceptualLearningScoringFooter)

const _css = css`
  display: flex;
`

const _conceptualLearningAnswerCss = css`
  position: absolute;
  bottom: 68px;
  right: 20px;
  display: flex;
  width: 360px;
  height: 300px;
  padding: 20px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  border-radius: 14px;
  border: 1px solid ${colors.gray.$400};
  background-color: ${colors.white};
  box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.1);
  z-index: ${zIndex.채점툴팁};
`
