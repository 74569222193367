import styled from '@emotion/styled'
import { WorksheetDomain } from '@mathflat/domain/@entities'
import { observer } from 'mobx-react'
import type { Dispatch } from 'react'
import { useNavigate, useParams } from 'react-router'

import { useStudentAppMediaQuery } from '~/@common/hooks/useMediaQuery'
import { useRepository } from '~/@common/hooks/useRepository'
import { drawerService } from '~/@common/services'
import modalService from '~/@common/services/modal.service'
import { remoteStorageService } from '~/@common/services/remoteStorage.service'
import { textEllipsis, typo } from '~/@common/styles'
import { mediaQuery } from '~/@common/styles/mediaQuery'
import Button from '~/@common/ui/(Button)/Button/Button'
import { Drawer } from '~/@common/ui/(Drawer)/BaseDrawer'
import { StudentEduMaterialScoringDashboard } from '~/@pages/@common/StudentEduMaterialScoringDashboard'
import SelfLearningWorksheetListModal from '~/@pages/@widgets/(Worksheet)/(SelfLearning)/SelfLearningWorksheetListModal'
import { WorksheetScoringCardMobile } from '~/@pages/@widgets/(Worksheet)/WorksheetScoring/(Body)/WorksheetScoringCard.mobile'
import { SelfLearningGroupInnerDrawerContent } from '~/@pages/student/@widgets/SelfLearningGroupInnerDrawerContent/SelfLearningGroupInnerDrawerContent'
import ProblemScoringResultTable from '~/@pages/student/learning-process/@common/ProblemScoringResultTable'
import 챌린지학습지완료모달 from '~/@pages/student/learning-process/@common/챌린지학습지완료모달'

import { LearningProcessService } from '../../../service/LearningProcess.service'

type Props = {
  setIsOpenResultTable: Dispatch<React.SetStateAction<boolean>>
}

const ResultView = observer(({ setIsOpenResultTable }: Props) => {
  const service = useRepository(LearningProcessService)
  const { isMobile } = useStudentAppMediaQuery()
  const { studentWorksheet, worksheet, problemScoringViewOption } =
    service.오답유형학습_쌍둥이유사_문제풀이_현재탭_리스트

  return (
    <>
      <Drawer {...drawerService.first.options}>{drawerService.first.children}</Drawer>
      <S.Result>
        <S.Scoring>
          <StudentEduMaterialScoringDashboard
            style={{ ...(isMobile && { minWidth: '100%' }) }}
            eduMaterialType="WORKSHEET"
            scoring={{
              total: service.모든_오답유형학습_쌍둥이유사_문제_리스트.length || 0,
              correct: service.모든_오답유형학습_쌍둥이유사_문제_리스트.filter(
                (el) => el?.채점결과 === 'CORRECT',
              ).length,
              incorrect: service.모든_오답유형학습_쌍둥이유사_문제_리스트.filter(
                (el) => el?.채점결과 === 'WRONG',
              ).length,
              unknown: service.모든_오답유형학습_쌍둥이유사_문제_리스트.filter(
                (el) => el?.채점결과 === 'UNKNOWN',
              ).length,
              none: service.모든_오답유형학습_쌍둥이유사_문제_리스트.filter(
                (el) => el?.채점결과 === 'NONE',
              ).length,
            }}
            score={studentWorksheet?.score ?? 0}
            status="COMPLETE"
          />
          {!isMobile && <SubmitButton setIsOpenResultTable={setIsOpenResultTable} />}
        </S.Scoring>
        {isMobile ? (
          <S.ScoringCard>
            <div className="scoring-card-container">
              {service.모든_오답유형학습_쌍둥이유사_문제_리스트.map((problemScoring) => {
                if (!problemScoring) return
                return (
                  <WorksheetScoringCardMobile
                    key={problemScoring.problem.id}
                    problemScoring={problemScoring}
                    type={worksheet.type}
                    viewOption={problemScoringViewOption}
                    isVisibleConcept
                  />
                )
              })}
            </div>
          </S.ScoringCard>
        ) : (
          <S.Table>
            <ProblemScoringResultTable
              problemScoringList={service.모든_오답유형학습_쌍둥이유사_문제_리스트}
              type={worksheet.type}
            />
          </S.Table>
        )}
      </S.Result>
      {isMobile && <SubmitButton setIsOpenResultTable={setIsOpenResultTable} />}
    </>
  )
})

const SubmitButton = observer(({ setIsOpenResultTable }: Props) => {
  const service = useRepository(LearningProcessService)
  const { studentWorksheetId } = useParams<{ studentWorksheetId: string }>()
  const navigate = useNavigate()
  const { isMobile } = useStudentAppMediaQuery()

  const handleSelfLearningListClick = async (
    selfLearningType: string,
    studentWorksheetId: number,
  ) => {
    service.resetProcess()

    const url =
      selfLearningType === WorksheetDomain.SELF_LEARNING_TYPE.심화학습
        ? await service.보충_심화학습지_학습하기({
            studentWorksheetId: `${studentWorksheetId}`,
          })
        : await service.보충_오답학습지_학습하기({
            studentWorksheetId: `${studentWorksheetId}`,
          })
    navigate(url, { replace: true })
    modalService.closeModal()
    drawerService.close()
  }

  return (
    <S.SubmitButton className="button-container">
      <Button
        theme="secondary"
        onClick={() => {
          setIsOpenResultTable(false)
        }}
      >
        뒤로가기
      </Button>
      <Button
        theme="primary"
        onClick={async () => {
          if (!studentWorksheetId) return

          if (
            service.보충_오답학습_리스트 &&
            service.보충_오답학습_리스트?.group.length > 1 &&
            service.보충_오답학습_리스트.status !== 'COMPLETE'
          ) {
            if (isMobile) {
              drawerService.open(
                <SelfLearningGroupInnerDrawerContent>
                  <SelfLearningWorksheetListModal
                    studentLearningContent={service.보충_오답학습_리스트}
                    onClick={handleSelfLearningListClick}
                  />
                </SelfLearningGroupInnerDrawerContent>,
                {
                  isOpened: true,
                  closeDrawer: () => drawerService.close(),
                  size: '596px',
                },
              )
              return
            }
            modalService.openModal(
              {
                content: (
                  <SelfLearningWorksheetListModal
                    studentLearningContent={service.보충_오답학습_리스트}
                    onClick={handleSelfLearningListClick}
                  />
                ),
                header: service.보충_오답학습_리스트.worksheet.title,
              },
              {
                modalName: '보충_오답_팝업',
                hasCloseButton: true,
                headerOption: { position: 'left', hasBottomBorder: false },
              },
            )
            return
          }

          service.챌린지학습_완료하기(+studentWorksheetId)

          if (!remoteStorageService.isCompletedSelfLearning) {
            modalService.openModal(<챌린지학습지완료모달 />, {
              modalName: '챌린지_학습지_완료',
            })
            remoteStorageService.patch('isCompletedSelfLearning', true)
          }

          navigate(-1)
        }}
      >
        {service.보충_오답학습_리스트 &&
        service.보충_오답학습_리스트?.group.length > 1 &&
        service.보충_오답학습_리스트.status !== 'COMPLETE'
          ? '다음 오답 보충 학습지'
          : '학습 종료하기'}
      </Button>
    </S.SubmitButton>
  )
})

export default ResultView

const S = {
  Result: styled.div`
    display: flex;
    height: 100%;
    overflow-y: auto;
    width: 100%;

    ${mediaQuery.underTablet} {
      flex-direction: column;
      gap: 9.5px;
      padding: 10px 16px 0;
      height: calc(100% - 108px);
    }
  `,
  Scoring: styled.div`
    display: flex;
    flex-direction: column;
    padding-right: 24px;

    ${mediaQuery.underTablet} {
      padding-right: 0;
      padding-top: 10px;
    }
  `,
  ScoringCard: styled.div`
    width: 100%;

    .scoring-card-container {
      display: flex;
      flex-direction: column;
      gap: 9.5px;
    }
  `,
  Table: styled.div`
    .problem-answer-img {
      max-width: 100%;
      margin: auto 0;
    }
    .ellipse {
      width: 66px;
      height: 100%;
      ${textEllipsis(3)}

      .katex .base {
        display: contents;
        white-space: unset;
        ${typo.body02};
        line-height: 1;
      }
    }
  `,
  SubmitButton: styled.div`
    display: flex;
    align-items: center;
    width: 100%;
    margin-top: 24px;
    gap: 10px;

    ${mediaQuery.underTablet} {
      width: auto;
      margin: 20px 16px 0;
    }

    > button {
      width: 50%;
    }
  `,
}
