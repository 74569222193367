import { makeAutoObservable } from 'mobx'

import type { HandwrittenNoteItem } from './HandwrittenNoteData'
import type HandwrittenNotePathItem from './HandwrittenNotePathItem'

export default class HandwrittenNoteEraserItem implements HandwrittenNoteItem {
  readonly id: number
  readonly pathItems: HandwrittenNotePathItem[] = []

  constructor(id: number) {
    this.id = id

    makeAutoObservable(this)
  }

  addPathItems(value: HandwrittenNotePathItem[]) {
    this.pathItems.push(...value)
  }

  undo() {
    this.pathItems.forEach((item) => {
      item.setIsDeleted(false)
    })
  }

  redo() {
    this.pathItems.forEach((item) => {
      item.setIsDeleted(true)
    })
  }
}
