import { action, makeObservable, observable } from 'mobx'

import type { ClassT } from ':/@types/utilityTypes'

type Separator<T> = [Class: T, name: string]

class DynamicRepo {
  private rootStore = new WeakMap<ClassT, Map<string, InstanceType<ClassT>>>()

  constructor() {
    makeObservable<DynamicRepo, 'rootStore'>(this, {
      rootStore: observable,
      reg: action,
      set: action,
      del: action,
      deleteRoot: action,
    })
  }

  reg<T extends ClassT>(
    separator: Separator<T>,
    ...params: ConstructorParameters<T>
  ): InstanceType<T> {
    return this.has(separator) ? this.get(separator)! : this.set(separator, ...params)
  }

  set<T extends ClassT>(
    [Service, name]: Separator<T>,
    ...params: ConstructorParameters<T>
  ): InstanceType<T> {
    const separator: Separator<T> = [Service, name]
    if (!this.rootStore.has(Service)) this.rootStore.set(Service, new Map())
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    this.rootStore.get(Service)!.set(name, new Service(...params))
    return this.get(separator)!
  }

  get<T extends ClassT>([Service, name]: Separator<T>): InstanceType<T> | null {
    const repo = this.rootStore.get(Service)?.get(name)
    // if (!repo) throw Error('해당하는 dynamic 레포지토리가 존재하지 않습니다.')
    if (!repo) return null
    return repo
  }

  has<T extends ClassT>([Service, name]: Separator<T>) {
    return !!this.rootStore.get(Service)?.has(name)
  }

  del<T extends ClassT>([Service, name]: Separator<T>) {
    return !!this.rootStore.get(Service)?.delete(name)
  }

  deleteRoot<T extends ClassT>(Service: T) {
    return this.rootStore.delete(Service)
  }

  clear() {
    this.rootStore = new WeakMap<ClassT, Map<string, InstanceType<ClassT>>>()
  }
}

export default DynamicRepo
