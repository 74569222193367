import { makeAutoObservable, runInAction } from 'mobx'

import type { Command, HandwrittenNoteToolbarMode, PathStyle, Tool } from '../handwrittenNote.types'
import type HandwrittenNoteService from './HandwrittenNote.service'
import HandwrittenNoteModeService from './HandwrittenNoteMode.service'

export default class HandwrittenNoteControllerService {
  noteService: HandwrittenNoteService | undefined
  modeService: HandwrittenNoteModeService

  constructor(modeService?: HandwrittenNoteModeService) {
    this.modeService = modeService ?? new HandwrittenNoteModeService()
    makeAutoObservable(this)
  }

  get isWritingMode() {
    return this.modeService.isWritingMode
  }

  get toolbarMode() {
    return this.modeService.toolbarMode
  }

  get activeTool() {
    return this.modeService.activeTool
  }

  get isHiddenNote() {
    return this.modeService.isHiddenNote
  }

  get toolWidth() {
    return this.modeService.toolWidth
  }

  get toolColor() {
    return this.modeService.toolColor
  }

  get needExtraFinger() {
    return this.modeService.needExtraFinger
  }

  get pathStyle() {
    return this.modeService.pathStyle
  }

  setToolWidth(value: number) {
    this.modeService.setToolWidth(value)
  }

  setToolColor(value: string) {
    this.modeService.setToolColor(value)
  }

  setToolbarMode(value: HandwrittenNoteToolbarMode) {
    this.modeService.setToolbarMode(value)
  }

  setIsWritingMode(value: boolean) {
    this.modeService.setIsWritingMode(value)
  }

  setActiveTool(value: Tool, isToggle = false) {
    this.modeService.setActiveTool(value, isToggle)
  }

  setNoteService(value: HandwrittenNoteService) {
    runInAction(() => {
      this.noteService = value
    })
  }

  setIsHiddenNote(value: boolean, isToggle = false) {
    this.modeService.setIsHiddenNote(value, isToggle)
  }

  setNeedExtraFinger(value: boolean) {
    this.modeService.setNeedExtraFinger(value)
  }

  setPathStyle(value: PathStyle) {
    this.modeService.setPathStyle(value)
  }

  command(data: Command) {
    switch (data.type) {
      case 'undo':
        this.noteService?.noteData.undo()
        break
      case 'redo':
        this.noteService?.noteData.redo()
        break
      case 'erase-all':
        this.noteService?.noteData.eraseAll()
        break
      case 'tool-width':
        this.setToolWidth(data.payload)
        break
      case 'tool-color':
        this.setToolColor(data.payload)
        break
      case 'tool':
        this.setActiveTool(data.payload, data.isToggle)
        break
      case 'hidden':
        this.setIsHiddenNote(!this.modeService.isHiddenNote, data.isToggle)
        break
    }
  }

  reset() {
    this.noteService?.reset()
  }
}
