import type { ValueOf } from '@mathflat/shared/@types/utilityTypes'

export type CoverDataType = ValueOf<typeof COVER_DATA_TYPE>

export const COVER_DATA_TYPE = {
  TEXT: 'TEXT',
  IMAGE: 'IMAGE',
  RECT: 'RECT',
  LINE: 'LINE',
} as const
