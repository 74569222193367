import styled from '@emotion/styled'
import { observer } from 'mobx-react'
import { useEffect, useLayoutEffect } from 'react'
import { useNavigate, useParams } from 'react-router'
import { useSearchParams } from 'react-router-dom'

import { routeName } from '~/@common/constants'
import { useStudentAppMediaQuery } from '~/@common/hooks/useMediaQuery'
import { useSetRepository } from '~/@common/hooks/useRepository'
import modalService from '~/@common/services/modal.service'
import { mediaQuery } from '~/@common/styles/mediaQuery'
import WorksheetScoringByOne from '~/@pages/@widgets/(Worksheet)/WorksheetScoring/WorksheetScoringByOne'
import WorksheetScoringByOneMobile from '~/@pages/@widgets/(Worksheet)/WorksheetScoring/WorksheetScoringByOne.mobile'
import { openUnavailableAlertMessageModal } from '~/@pages/@widgets/(Worksheet)/접근권한모달'
import { alertWorksheetExceptionErrorModal } from '~/@pages/student/student-worksheet/$studentWorksheetId/scoring/alertWorksheetExceptionErrorModal'

import { WorksheetScoringService } from './@service/WorksheetScoring.service'
import { WorksheetQuickScoring } from './@widgets/WorksheetQuickScoring'
import { WorksheetScoringPageHeader } from './@widgets/WorksheetScoringPageHeader'
import { WorksheetSwiperFilter } from './@widgets/WorksheetSwiperFilter'
import { 선생님께제출하기모달 } from './@widgets/선생님께제출하기모달'

const WorksheetScoringPage = observer(() => {
  const { worksheetId, dateTime } = useParams<{ worksheetId: string; dateTime: string }>()
  const navigate = useNavigate()
  const service = useSetRepository(WorksheetScoringService)
  const { isMobile } = useStudentAppMediaQuery()
  const [searchParams, setSearchParams] = useSearchParams()
  const scoringSize = searchParams.get('size')

  useLayoutEffect(() => {
    if (!worksheetId || !dateTime) {
      navigate(routeName.student.defaultPath, { replace: true })
      return
    }
    ;(async () => {
      try {
        await service.checkWorksheetValidation(worksheetId, dateTime)
        // // CASE: 학습지에 대한 접근 권한이 없을 때
        if (service.validation?.worksheetState === 'UNAVAILABLE') {
          openUnavailableAlertMessageModal()
          return
        }
        // CASE: 학습지가 학생에게 제출된 경우 => 학습지 상세 페이지로 이동
        else if (service.validation?.worksheetState === 'AVAILABLE') {
          navigate(`/student/student-worksheet/${service.validation.studentWorksheetId}/scoring`, {
            replace: true,
          })
          return
        }
        await service.loadWorksheetWithProblems(worksheetId)
      } catch (errCode) {
        if (errCode instanceof Error) {
          // CASE: 학습지가 삭제되거나 수정된 경우
          if (errCode.message === 'WORKSHEET_ALREADY_DELETED') {
            alertWorksheetExceptionErrorModal()
          } else if (errCode.message === 'WORKSHEET_PERMISSION_DENIED') {
            openUnavailableAlertMessageModal()
          } else {
            // CASE: 학습지에 대한 접근 권한이 없을 때
            openUnavailableAlertMessageModal()
          }
        }
      }
    })()
  }, [dateTime, service, worksheetId])

  useEffect(() => {
    if (!scoringSize) {
      setSearchParams('size=all')
    }
  }, [scoringSize])

  const openConfirmToSubmitAnswersModal = () => {
    modalService.closeModal()
    modalService.openModal(<선생님께제출하기모달 />, {
      modalName: 'confirmToSubmitAnswers',
    })
  }

  return (
    <>
      <WorksheetScoringPageHeader
        // TODO: 학습지 제목으로 만들어야함. 안되면 종대쌤께 공유
        title={`총 ${service.problemScoringColl?.toArr.length || 0}문제`}
      />
      <>
        {service.worksheet &&
          service.problemScoringColl &&
          service.problemScoringViewOption &&
          (scoringSize === 'all' ? (
            <WorksheetQuickScoring
              problemScoringColl={service.problemScoringColl}
              gradingCount={service.problemScoringColl.gradingCount}
              problemScoringViewOption={service.problemScoringViewOption}
              clearAllAnswer={() => service.clearAllAnswer()}
              openConfirmToSubmitAnswersModal={openConfirmToSubmitAnswersModal}
            />
          ) : (
            <S.WorksheetByOneWrapper>
              {isMobile ? (
                <>
                  <WorksheetSwiperFilter />
                  <WorksheetScoringByOneMobile<'미출제'>
                    problemScoringColl={service.problemScoringColl}
                    viewOption={service.problemScoringViewOption}
                    onSubmit={openConfirmToSubmitAnswersModal}
                    type={service.worksheet.type}
                    studentWorksheet={null}
                  />
                </>
              ) : (
                <WorksheetScoringByOne<'미출제'>
                  problemScoringColl={service.problemScoringColl}
                  viewOption={service.problemScoringViewOption}
                  onSubmit={openConfirmToSubmitAnswersModal}
                  worksheet={service.worksheet}
                  studentWorksheet={null}
                />
              )}
            </S.WorksheetByOneWrapper>
          ))}
      </>
    </>
  )
})

export default WorksheetScoringPage

const S = {
  WorksheetByOneWrapper: styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    gap: 10px;
    .worksheet-swiper-controller {
      display: flex;
      flex: 0 0 48px;
    }
    .worksheet-contents {
      display: flex;
      gap: 25px;
      overflow: hidden;
      flex: 1 1 100%;
    }

    ${mediaQuery.underTablet} {
      padding: 20px 16px 40px;
    }
  `,
}
