import styled from '@emotion/styled'
import type { Entity as WorksheetEntity } from '@mathflat/domain/@entities/(Content)/Worksheet/dto'
import clsx from 'clsx'
import { observer } from 'mobx-react'
import { useRef } from 'react'

import { useIntersectionObserver } from '~/@common/hooks/useIntersectionObserver'
import { colors } from '~/@common/styles'
import type { ProblemScoring } from '~/@pages/@common/(ProblemScoring)/@trait/ProblemScoring.trait'
import type { ProblemScoringViewOption } from '~/@pages/@common/(ProblemScoring)/@trait/ProblemScoringViewOption.trait'
import ProblemScoringFooter from '~/@pages/@common/(ProblemScoring)/ProblemScoringCard/ProblemScoringFooter'
import ProblemScoringHeader from '~/@pages/@common/(ProblemScoring)/ProblemScoringCard/ProblemScoringHeader'

type Props = {
  problemScoring: ProblemScoring<'WORKSHEET'>
  type: WorksheetEntity.Worksheet['type']
  viewOption: ProblemScoringViewOption<'학습모듈' | 'NOT_학습모듈'>
  isVisibleConcept?: boolean
}

export const WorksheetScoringCardMobile = observer(
  ({ problemScoring, type, viewOption, isVisibleConcept }: Props) => {
    const containerRef = useRef<HTMLDivElement>(null)
    const entry = useIntersectionObserver(containerRef, {
      freezeOnceVisible: true,
    })
    const isVisible = !!entry?.isIntersecting

    return (
      <S.Container
        ref={containerRef}
        className={clsx(type === 'CONCEPTUAL' && 'conceptual-learning-scoring-card')}
      >
        {isVisible && viewOption && (
          <>
            <ProblemScoringHeader problemScoring={problemScoring} viewOption={viewOption} />

            <S.Body>
              {problemScoring.problem.conceptName && isVisibleConcept && (
                <div className="problem-concept">
                  <div>유형명</div>
                  <div className="divider" />
                  <div className="concept">{problemScoring.problem.conceptName}</div>
                </div>
              )}
              <img className="problem-image" src={problemScoring.문제이미지} alt="문제 이미지" />
            </S.Body>
            {(problemScoring.isSubmitted || !viewOption.채점불가능) && (
              <ProblemScoringFooter
                problemScoring={problemScoring}
                viewOption={viewOption}
                virtualKeybaordShowType="fixed"
              />
            )}
          </>
        )}
      </S.Container>
    )
  },
)

const S = {
  Container: styled.div`
    display: flex;
    background-color: white;
    border-radius: 14px;
    overflow: auto;
    flex-direction: column;
    height: calc(100% - 60px);

    &.conceptual-learning-scoring-card {
      height: unset;
      overflow: unset;
    }
  `,

  Body: styled.div`
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid ${colors.gray.$300};
    width: 100%;
    height: 100%;
    overflow: auto;
    border-top: 1px solid ${colors.gray.$300};

    .problem-concept {
      display: flex;
      align-items: center;
      border-bottom: 1px solid ${colors.gray.$300};
      gap: 6px;
      width: 100%;
      height: 63px;
      padding: 0 20px;

      .divider {
        width: 1px;
        height: 8px;
        background-color: #d9d9d9;
      }
      .concept {
        flex: 1;
      }
    }

    .problem-image {
      padding: 20px;
    }
  `,
}
