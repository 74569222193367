import { Entity as WorksheetEntity } from '@mathflat/domain/@entities/(Content)/Worksheet/dto.ts'
import { LearningProcessApi } from '@mathflat/domain/@entities/StudentLearningProcess/api'
import { Entity as StudentWorksheetEntity } from '@mathflat/domain/@entities/StudentWorksheet/dto'
import { Entity as StudentWorksheetScoringEntity } from '@mathflat/domain/@entities/StudentWorksheet/StudentWorksheetScoring/dto'
import { isAxiosError } from 'axios'
import { makeAutoObservable, runInAction, toJS } from 'mobx'

import { RequestScoring, studentWorksheetApi } from '~/@common/api'
import { toastService } from '~/@common/services'
import { localStorageService } from '~/@common/services/storage.service'
import { apiSpecFetcher } from '~/@common/utils/apiSpecFetcher'
import {
  ProblemScoring,
  ProblemScoringColl,
} from '~/@pages/@common/(ProblemScoring)/@trait/ProblemScoring.trait'
import { ProblemScoringViewOption } from '~/@pages/@common/(ProblemScoring)/@trait/ProblemScoringViewOption.trait'

import { conceptualLearningApi, type ConceptualWorksheet } from '../../@common/api'

type LectureVideoMaterial =
  LearningProcessApi.개념학습하기.Output['lectures'][number]['lecture'] & {
    studentLectureCreateDatatime?: string | null
  }

export interface ConceptualEduMaterial {
  lectures: LectureVideoMaterial[]
  prevLectures: LectureVideoMaterial[]
  concepts: LearningProcessApi.개념학습하기.Output['concepts']
  prevConcepts: LearningProcessApi.개념학습하기.Output['concepts']
}

export interface WorksheetWithProblems {
  problemScoringColl: ProblemScoringColl<'WORKSHEET'> | undefined
  problemScoringViewOption: ProblemScoringViewOption<'학습모듈'> | undefined
  studentWorksheet: StudentWorksheetEntity.StudentWorksheet | undefined
  worksheet: WorksheetEntity.ConceptualWorksheet | undefined
}

export class ConceptualLearningService {
  worksheetProblemScoring?: WorksheetWithProblems
  conceptualEduMaterial?: ConceptualEduMaterial
  isShowAnswerTooltip: boolean = false
  readonly worksheetData: Map<number, ConceptualWorksheet> = new Map()

  constructor() {
    makeAutoObservable(this)
  }

  get conceptualEduMaterialStatus() {
    return {
      hasLectures: Boolean(this.conceptualEduMaterial?.lectures.length),
      hasPrevLectures: Boolean(this.conceptualEduMaterial?.prevLectures.length),
      hasConcepts: Boolean(this.conceptualEduMaterial?.concepts.length),
      hasPrevConcepts: Boolean(this.conceptualEduMaterial?.prevConcepts.length),
    }
  }

  private getProblermScoringViewOption(
    worksheet: WorksheetEntity.Worksheet,
    studentWorksheet: StudentWorksheetEntity.StudentWorksheet,
  ) {
    return new ProblemScoringViewOption({
      content: {
        status: studentWorksheet.status,
        autoScored: worksheet.autoScorable,
        kind: 'WORKSHEET',
        type: 'CONCEPTUAL',
        worksheetId: worksheet.id,
      },
      studentAppSetting: null,
    })
  }

  private getProblemScoringColl(
    problemScorings: Awaited<
      ReturnType<typeof studentWorksheetApi.getAssignedStudentWorksheetWithProblems>
    >,
    autoScorable?: boolean,
  ) {
    return new ProblemScoringColl<'WORKSHEET'>(
      problemScorings.map(
        ({ problem, worksheetProblemId, scoring, handwrittenNoteUrl }, index) =>
          new ProblemScoring<'WORKSHEET'>(
            {
              scoring: new StudentWorksheetScoringEntity.StudentWorksheetScoring({
                scoring: { ...scoring, worksheetProblemId },

                problem: {
                  ...problem,
                  answerUnits: problem.answerUnits,
                  keypadTypes: problem.keypadTypes,
                },
                worksheet: {
                  autoScorable: Boolean(autoScorable),
                  label: `${index + 1}번`, // label을 밖에서 주입해줘야 하는 경우에는 받을 수 있도록 (ex. 1번 유사문제)}
                },
              }),
            },
            worksheetProblemId,
            handwrittenNoteUrl,
          ),
      ),
    )
  }

  async fetchAssignedStudentWorksheetWithProblems(studentWorksheetId: number) {
    const [{ studentWorksheet, worksheet }, problemScorings] = await Promise.all([
      studentWorksheetApi.getAssignedStudentWorksheetById(studentWorksheetId),
      studentWorksheetApi.getAssignedStudentWorksheetWithProblems(studentWorksheetId),
    ])

    runInAction(() => {
      this.worksheetProblemScoring = {
        worksheet: new WorksheetEntity.ConceptualWorksheet(worksheet),
        studentWorksheet: new StudentWorksheetEntity.StudentWorksheet(studentWorksheet),
        problemScoringViewOption: this.getProblermScoringViewOption(worksheet, studentWorksheet),
        problemScoringColl: this.getProblemScoringColl(problemScorings, worksheet.autoScorable),
      }
    })
  }

  async onSubmitWorksheetProblems(studentWorksheetId: number) {
    if (!this.worksheetProblemScoring) {
      return false
    }
    const { problemScoringColl } = this.worksheetProblemScoring

    if (!problemScoringColl) {
      return false
    }

    const scoredArr = problemScoringColl.toScoredArr

    if (!scoredArr.length || !(scoredArr[0].localUserInput instanceof RequestScoring.자동채점)) {
      return false
    }

    const payload = scoredArr.map(({ localUserInput }) =>
      RequestScoring.자동채점.toRequestParams<'WORKSHEET', '일반'>(
        localUserInput as RequestScoring.자동채점,
      ),
    )

    await studentWorksheetApi.patchAutoScoringAssignedStudentWorksheet(
      String(studentWorksheetId),
      payload,
    )

    scoredArr.forEach((item) => {
      localStorageService.removeScoringData({ scoringId: item.id })
    })
    await conceptualLearningApi.patchConceptualWorksheetLearningStep(studentWorksheetId, 'RESOLVED')
    return true
  }

  async loadConceptualWorksheets() {
    const items = await conceptualLearningApi.fetchConceptualWorksheets()

    runInAction(() => {
      items.forEach((item) => {
        if (item.referenceType === 'LITTLE_CHAPTER') {
          this.worksheetData.set(item.referenceId, item)
        }
      })
    })
  }

  async createStudentWorksheet(littleChapterId: number) {
    const worksheetId = await conceptualLearningApi.createConceptualWorksheet(littleChapterId)
    return worksheetId
  }

  async featchConceptualEduMaterial(studentWorksheetId: string) {
    const { data } = await apiSpecFetcher(LearningProcessApi.개념학습하기.spec)({
      params: {
        studentWorksheetId,
      },
    })

    const lectureMapper = (
      src: LearningProcessApi.개념학습하기.Output['lectures'][number],
    ): LectureVideoMaterial => {
      return {
        ...src.lecture,
        studentLectureCreateDatatime: src.studentLecture?.createDatetime,
      }
    }

    runInAction(() => {
      this.conceptualEduMaterial = {
        lectures: data.lectures.map(lectureMapper),
        prevLectures: data.prevLectures.map(lectureMapper),
        concepts: data.concepts,
        prevConcepts: data.prevConcepts,
      }
    })
  }

  setIsShowAnswerTooltip(value) {
    this.isShowAnswerTooltip = value
  }

  cleanup() {
    this.worksheetProblemScoring = undefined
    this.conceptualEduMaterial = undefined
    this.isShowAnswerTooltip = false
    this.worksheetData.clear()
  }
}
