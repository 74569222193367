import styled from '@emotion/styled'
import { Entity as CurriculumEntity } from '@mathflat/domain/@entities/Curriculum/dto'
import { observer } from 'mobx-react'
import qs from 'qs'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useNavigate, useParams } from 'react-router'
import { useSearchParams } from 'react-router-dom'
import SimpleBar from 'simplebar-react'

import { routeName } from '~/@common/constants'
import { useLearningTime } from '~/@common/hooks/useLearningTime'
import { useRepository } from '~/@common/hooks/useRepository'
import { webviewService } from '~/@common/services'
import { commonRepo } from '~/@common/services/repo.service'
import { colors, typo } from '~/@common/styles'
import { mediaQuery } from '~/@common/styles/mediaQuery'
import { handleFullScreen } from '~/@common/ui/MathflatPlayer/Display/FullScreenButton'
import { PortalRootLayoutHeader } from '~/@common/ui/PortalRootLayoutHeader'
import LectureVideoPlayer from '~/@pages/@common/(LectureMaterial)/LectureVideoPlayer'
import GlobalHeader from '~/@pages/@widgets/(Navigation)/GlobalHeader/GlobalHeader'

import type { LectureGroup as LectureGroupT } from '../@service/Lecture.service'
import { LectureService } from '../@service/Lecture.service'
import { LECTURE_GROUP_CARD_SIZE } from '../@widgets/LectureGroup.style'
import LectureGroup from '../@widgets/LectureGroup.widget'

const LectureDetailPage = () => {
  useLearningTime()
  const navigate = useNavigate()
  const params = useParams<{ lectureId: string }>()
  const [searchParams] = useSearchParams()

  const [lectureGroup, setLectureGroup] = useState<LectureGroupT>()

  const service = useRepository(LectureService)

  const searchParam = qs.parse(searchParams.toString())
  const isListHidden = searchParam && searchParam.hideList === 'true'

  const lecture = useMemo(() => {
    if (params.lectureId) {
      return lectureGroup?.middleChapterLectures.find(
        (lecture) => lecture.id === parseInt(params.lectureId!),
      )
    }
  }, [lectureGroup, params.lectureId])

  const handleProgressSubmit = useCallback(async () => {
    if (lecture && commonRepo.studentId) {
      await service.setProgress({
        lecture,
        studentId: commonRepo.studentId,
      })
    }
  }, [lecture, service])

  useEffect(() => {
    if (params.lectureId && commonRepo.studentId) {
      service
        .getLectureGroupById(parseInt(params.lectureId), commonRepo.studentId)
        .then((lectureGroup) => {
          setLectureGroup(lectureGroup)
        })
        .catch((e) => {
          console.error(e)
        })
    }
  }, [params.lectureId, service])

  // 앱의 화면 가로 전환에 대한 락을 풀고, 가로 전환되었을때 이벤트를 감지하여 풀스크린 이벤트 실행.
  useEffect(() => {
    webviewService.unlockDeviceOrientation()
    window.addEventListener('onOrientationLandscape', handleFullScreen)
    return () => {
      webviewService.lockDeviceOrientation()
      window.removeEventListener('onOrientationLandscape', handleFullScreen)
    }
  }, [])

  if (!lectureGroup || !commonRepo.studentAppSetting) return <></>

  return (
    <>
      <PortalRootLayoutHeader>
        <GlobalHeader
          SlotCenter={lecture?.title}
          prevLinkProps={{
            onClick: (e) => {
              e.preventDefault()
              if (!commonRepo.studentAppSetting!.lectureService) {
                navigate(routeName.student.defaultPath)
                return
              }
              navigate(-1)
            },
          }}
        ></GlobalHeader>
      </PortalRootLayoutHeader>
      <SimpleBar style={{ maxHeight: '100%' }}>
        <S.LectureDetailPage>
          <div className="lecture-video-area">
            {lecture && (
              <>
                <LectureVideoPlayer
                  selectedLecture={lecture}
                  onProgressSubmit={handleProgressSubmit}
                />
                <div className="lecture-desc">
                  <div className="title">{lecture.title}</div>
                  <div className="sub-title">
                    {
                      commonRepo.curriculumTreeColl?.getCurriculumInfoByCurriculumKey(
                        CurriculumEntity.CurriculumTreeColl.makeCurriculumKeyForGradeSemester(
                          lecture.trieKey,
                        ),
                      )?.korSchoolGradeSemester
                    }
                    &nbsp; |&nbsp;{lecture.bigChapterName}&nbsp;|&nbsp;{lecture.middleChapterName}
                  </div>
                </div>
              </>
            )}
          </div>
          {!isListHidden && (
            <div className="lecture-group">
              <LectureGroup lectureGroup={lectureGroup} />
            </div>
          )}
        </S.LectureDetailPage>
      </SimpleBar>
    </>
  )
}

export default observer(LectureDetailPage)

const S = {
  LectureDetailPage: styled.div`
    width: 100%;
    height: 100%;

    display: flex;
    gap: 24px;

    ${mediaQuery.underTablet} {
      flex-direction: column;
      gap: 20px;
      overflow: auto;
    }

    .lecture-video-area {
      flex: 1 0 0;
      ${mediaQuery.underTablet} {
        flex: 0 1 auto;
      }

      .lecture-desc {
        display: flex;
        flex-direction: column;
        gap: 8px;

        margin: 20px 16px 0px 16px;

        .title {
          color: ${colors.gray.$900};
          ${typo.heading05};
          font-weight: bold;
        }
        .sub-title {
          ${typo.body02};
          color: ${colors.gray.$700};
          font-weight: bold;
        }
      }
    }

    .lecture-group {
      width: ${LECTURE_GROUP_CARD_SIZE}px;

      ${mediaQuery.underTablet} {
        width: auto;
        overflow: scroll;

        margin: 0px 16px;
      }
    }
  `,
}
