import styled from '@emotion/styled'
import type { TimeStamp } from '@mathflat/shared/@types/date'
import type { StrapiDataT } from '@mathflat/shared/@types/strapiApi'
import { addDays } from 'date-fns'
import { id } from 'date-fns/locale'
import { useEffect, useRef } from 'react'
import { useNavigate } from 'react-router'

import modalService from '~/@common/services/modal.service'
import { commonRepo } from '~/@common/services/repo.service'
import { colors, typo } from '~/@common/styles'
import { mediaQuery } from '~/@common/styles/mediaQuery'
import type { StrapiApi } from '~/@common/types/api/strapi'
import Button from '~/@common/ui/(Button)/Button/Button'
import CheckboxInputLabel from '~/@common/ui/checkbox/CheckboxInputLabel'

export type PopupLocalStorageData = {
  id: StrapiDataT['id']
  expiredDate: TimeStamp | 'infinite'
}

const NoticePopup = (props: StrapiDataT<StrapiApi.Notice>) => {
  const ref = useRef<HTMLInputElement>(null)
  const navigate = useNavigate()

  useEffect(() => {
    const checkbox = ref.current
    const studentId = commonRepo.student?.id

    return () => {
      if (studentId) {
        const storageKey = `noticePopupExpiresAt_${studentId}`

        if (checkbox && checkbox.checked) {
          const updatePopups = JSON.parse(
            localStorage.getItem(storageKey) ?? '[]',
          ) as PopupLocalStorageData[]

          // 현재 떠 있는 팝업 제외한 나머지 팝업
          const filteredPopups = updatePopups.filter((popup) => popup.id !== id)

          // 현재 팝업
          const currentPopup = {
            id: props.id,
            expiredDate:
              props.doNotShowAgainOption === 'DO_NOT_SHOW_AGAIN_FOR_THREE_DAYS'
                ? addDays(new Date(), 3).getTime()
                : 'infinite',
          }

          // 나머지팝업 + 현재팝업 로컬스토리지 세팅
          localStorage.setItem(storageKey, JSON.stringify([...filteredPopups, currentPopup]))
        }
      }
    }
  }, [commonRepo.student, ref, props])

  return (
    <>
      <S.Modal data-testid="popup-modal">
        <div className="title">{props.title}</div>
        {props.imageUrl && (
          <div className="image-wrapper">
            <img src={props.imageUrl} alt={props.title + '안내'} />
          </div>
        )}
        <div className="content ql-editor" dangerouslySetInnerHTML={{ __html: props.content }} />
        {props.popupLinkButton && (
          <Button
            theme="primary"
            size="small"
            minWidth={130}
            onClick={() => {
              if (
                // eslint-disable-next-line
                /[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g.test(
                  props.popupLinkButton!.link,
                )
              ) {
                //full URL일 경우
                window.open(
                  props.popupLinkButton!.link.startsWith('http')
                    ? props.popupLinkButton!.link
                    : props.popupLinkButton!.link.replace(/(?!http)(.*)/, 'http://$1'),
                  '_blank',
                )
              } else {
                //pathname일 경우
                navigate(props.popupLinkButton!.link)
              }
              modalService.closeModal()
            }}
          >
            {props.popupLinkButton.title}
          </Button>
        )}
      </S.Modal>
      <S.Bottom>
        <div className="option">
          {props.doNotShowAgainOption !== 'NONE' && (
            <CheckboxInputLabel ref={ref} className="option">
              {POPUP_OPTION[props.doNotShowAgainOption]}
            </CheckboxInputLabel>
          )}
        </div>
        <button
          className="close"
          onClick={() => {
            modalService.closeModal()
          }}
        >
          닫기
        </button>
      </S.Bottom>
    </>
  )
}

export default NoticePopup

const POPUP_OPTION = {
  DO_NOT_SHOW_AGAIN: '다시 보지 않기',
  DO_NOT_SHOW_AGAIN_FOR_THREE_DAYS: '3일 동안 보지 않기',
}

const S = {
  Modal: styled.div`
    position: relative;
    width: 462px;

    display: flex;
    flex-direction: column;
    align-items: center;

    gap: 24px;

    color: ${colors.gray.$900};

    ${mediaQuery.underTablet} {
      width: 280px;
    }

    .title {
      ${typo.heading05};
      font-weight: bold;
    }
    .content {
      width: 100%;

      ${colors.gray.$800};
      text-align: center;
    }
    .image-wrapper {
      width: 100%;

      display: flex;
      align-items: center;

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
  `,
  Bottom: styled.div`
    position: absolute;
    left: 0;
    bottom: -10px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    transform: translateY(100%);
    z-index: 10000;

    .option {
      margin-right: auto;
      color: #fff;
    }
    .close {
      ${typo.body02};
      color: #fff;
      margin-left: auto;
    }
  `,
}
