import styled from '@emotion/styled'
import clsx from 'clsx'
import React, { type ComponentPropsWithoutRef, useId } from 'react'

const CheckboxInput = React.forwardRef<
  HTMLInputElement,
  Omit<ComponentPropsWithoutRef<'input'>, 'children'>
>((props, ref) => {
  const _id = useId()
  const id = props.id ?? _id
  return (
    <S.Input
      {...props}
      className={clsx(`ms__component-checkbox-input`, props.className)}
      data-component="ms__component-checkbox-input"
      type="checkbox"
      id={id}
      ref={ref}
    />
  )
})
CheckboxInput.displayName = 'CheckboxInput'

export default CheckboxInput

const S = {
  Input: styled.input`
    margin: 0;
  `,
}
