import { css } from '@emotion/react'
import type { StudentWorksheetApi } from '@mathflat/domain/@entities/StudentWorksheet/api.ts'
import { observer } from 'mobx-react'
import { type FC, useCallback, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import SmileChallengeService from 'src/@pages/student/@widgets/SmileChallenge/SmileChallenge.service.ts'

import { useRepository } from '~/@common/hooks/useRepository'
import { errorHandlerService } from '~/@common/services'
import modalService from '~/@common/services/modal.service.tsx'
import { colors, fontWeight, typo } from '~/@common/styles'
import { Icon } from '~/@common/ui/Icon/Icon'
import Modal from '~/@common/ui/modal/Modal.tsx'
import { ChallengeStore } from '~/@pages/student/@common/store/Challenge.store.ts'
import { LearningProcessService } from '~/@pages/student/learning-process/@widgets/service/LearningProcess.service.ts'

import ConceptChip from '../ConceptChip/ConceptChip'
import ContentBox from '../ContentBox'
import NoData from '../NoData'
import SectionHeader from '../SectionHeader'

const SmileChallengeSection: FC = () => {
  const learningProcessService = useRepository(LearningProcessService)
  const navigate = useNavigate()
  const service = useRepository(SmileChallengeService)
  const store = useRepository(ChallengeStore)
  const { smileChallenge, isReady } = service

  const loadSmileChallenges = useCallback(async () => {
    try {
      await store.loadFromChallengeRemoteStorage()
      await service.loadSmileChallenge(store.curriculumKey ?? undefined)
    } catch (err) {
      errorHandlerService.handle(err)
    }
  }, [service, store])

  useEffect(() => {
    void loadSmileChallenges()
  }, [loadSmileChallenges])

  const handleRefresh = async () => {
    await loadSmileChallenges()
  }

  const onClickConceptChip = async (params: StudentWorksheetApi.챌린지학습지조회.Params) => {
    try {
      const 챌린지학습 = await learningProcessService.챌린지학습_시작하기(params)

      if (챌린지학습.이어하기_여부) {
        modalService.openModal(
          <Modal.Confirm.Positive
            css={{ width: 266 }}
            cancel={{
              children: '새로하기',
              onClick: async () => {
                const url = await 챌린지학습.새로하기()
                navigate(url)
              },
            }}
            confirm={{
              children: '이어하기',
              onClick: async () => {
                const url = await 챌린지학습.이어하기()
                navigate(url)
                modalService.closeModal()
              },
            }}
          >
            <p>
              학습중이던 유형 챌린지가 있습니다.
              <br />
              이어서 하시겠습니까?
            </p>
          </Modal.Confirm.Positive>,
          {
            modalName: '유형 챌린지 이어하기',
          },
        )
        return
      }

      const url = await 챌린지학습.새로하기()
      navigate(url)
    } catch (e) {
      errorHandlerService.handle(e)
    }
  }

  return (
    <section css={_Style} data-component="ms__SmileChallengeSection">
      <SectionHeader title="스마일 챌린지">
        {isReady && (
          <button
            type="button"
            onClick={handleRefresh}
            css={{ lineHeight: 0 }}
            data-component="스마일 챌린지 리프레시"
          >
            <Icon name="icon_refresh" size={20} />
          </button>
        )}
      </SectionHeader>
      <ContentBox className="content-box" isReady={isReady}>
        {isReady && (
          <>
            {!smileChallenge?.strengths && !smileChallenge?.weaknesses ? (
              <NoData type="SMILE_CHALLENGE" className="no-data" />
            ) : (
              <>
                <div data-track="최고등급도전">
                  <h4 className="sub-heading">최고 등급 도전</h4>
                  {smileChallenge.strengths ? (
                    <ConceptChip
                      size="small"
                      tagName="div"
                      item={smileChallenge.strengths}
                      onClick={() =>
                        onClickConceptChip({
                          conceptChipIds: [smileChallenge.strengths!.conceptChipId],
                          littleChapterId: smileChallenge.strengths!.littleChapterId,
                          referenceId: smileChallenge.strengths!.conceptChipId,
                          referenceType: 'CONCEPT_CHIP',
                        })
                      }
                    />
                  ) : (
                    <NoData type="SMILE_CHALLENGE_STRENGTHS" className="no-data-single" />
                  )}
                </div>
                <div className="devider"></div>
                <div data-track="취약유형탈출">
                  <h4 className="sub-heading">취약 유형 탈출</h4>
                  {smileChallenge.weaknesses ? (
                    <ConceptChip
                      size="small"
                      tagName="div"
                      item={smileChallenge.weaknesses}
                      onClick={() =>
                        onClickConceptChip({
                          conceptChipIds: [smileChallenge.weaknesses!.conceptChipId],
                          littleChapterId: smileChallenge.weaknesses!.littleChapterId,
                          referenceId: smileChallenge.weaknesses!.conceptChipId,
                          referenceType: 'CONCEPT_CHIP',
                        })
                      }
                    />
                  ) : (
                    <NoData type="SMILE_CHALLENGE_WEAKNESSES" className="no-data-single" />
                  )}
                </div>
              </>
            )}
          </>
        )}
      </ContentBox>
    </section>
  )
}

export default observer(SmileChallengeSection)

const _Style = css`
  .content-box {
    padding: 0;
    overflow: hidden;
    height: 203px;
  }
  .sub-heading {
    font-weight: ${fontWeight.bold};
    color: ${colors.black};
    height: 42px;
    display: flex;
    align-items: center;
    padding: 0 20px;
    ${typo.body02};
  }
  .devider {
    height: 1px;
    background-color: ${colors.gray.$200};
    margin: 0 20px;
  }
  .no-data {
    height: 100%;
  }
  .no-data-single {
    height: 58px;
  }
`
