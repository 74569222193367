import { makeAutoObservable } from 'mobx'
import React, { type CSSProperties } from 'react'

export type ModalOption = {
  hasCloseButton?: boolean
  onClose?: (...args: any[]) => boolean
  onAfterClose?: () => void
  dataAtribute?: Record<string, string>
  modalName: string
  headerOption?: {
    position?: 'center' | 'left' | 'right'
    hasBottomBorder?: boolean
    style?: CSSProperties
  }
  modalStyle?: {
    padding?: string
  }
}

export type ModalContent = { header?: React.ReactNode; content: React.ReactNode }

class ModalState {
  modalContent: ModalContent
  opt: ModalOption

  constructor(modalContent: ModalContent, opt: ModalOption) {
    this.modalContent = modalContent
    this.opt = opt
  }
}

export class ModalService {
  modalList: ModalState[] = []
  isShowLoader = false

  constructor() {
    makeAutoObservable(this)
  }

  openModal = (modalContent: ModalContent | React.ReactNode, opt: ModalOption) => {
    window.freshpaint?.track('[공통] 모달 OPEN', { name: opt.modalName })
    if (React.isValidElement(modalContent)) {
      this.modalList.push(new ModalState({ content: modalContent }, opt))
      return
    }
    this.modalList.push(new ModalState(modalContent as ModalContent, opt))
  }

  closeModal = () => {
    const lastModalState = this.modalList.pop()
    if (lastModalState) {
      lastModalState.opt.onAfterClose?.()
    }
  }

  closeAllModal = () => {
    this.modalList = []
  }

  showLoader = () => {
    this.isShowLoader = true
  }

  hideLoader = () => {
    this.isShowLoader = false
  }
}

const modalService = new ModalService()
export default modalService
