import { Icon } from '~/@common/ui/Icon/Icon.tsx'

import type { KeypadButton } from './_Keypad'
import type { KeypadType } from './hook'

export const LATEX_NODE_TYPE = {
  숫자: 'digit',
  LEFT_ARROW: 'left',
  RIGHT_ARROW: 'right',
  삭제: 'delete',
  대분수: 'mfrac',
  분수: 'frac',
} as const

const KEYPAD_BUTTON = {
  '-': { value: '-', latexNodeType: LATEX_NODE_TYPE.숫자 },
  '1': { value: '1', latexNodeType: LATEX_NODE_TYPE.숫자 },
  '2': { value: '2', latexNodeType: LATEX_NODE_TYPE.숫자 },
  '3': { value: '3', latexNodeType: LATEX_NODE_TYPE.숫자 },
  대분수: {
    value: '\\mfrac',
    children: <Icon name="icon_keypad_mixed_fraction" width={43} height={43} color="transparent" />,
    latexNodeType: LATEX_NODE_TYPE.대분수,
  },
  '4': { value: '4', latexNodeType: LATEX_NODE_TYPE.숫자 },
  '5': { value: '5', latexNodeType: LATEX_NODE_TYPE.숫자 },
  '6': { value: '6', latexNodeType: LATEX_NODE_TYPE.숫자 },
  분수: {
    value: '\\frac',
    children: <Icon name="icon_keypad_fraction" width={43} height={43} color="transparent" />,
    latexNodeType: LATEX_NODE_TYPE.분수,
  },
  '+': { value: '+', latexNodeType: LATEX_NODE_TYPE.숫자 },
  '7': { value: '7', latexNodeType: LATEX_NODE_TYPE.숫자 },
  '8': { value: '8', latexNodeType: LATEX_NODE_TYPE.숫자 },
  '9': { value: '9', latexNodeType: LATEX_NODE_TYPE.숫자 },
  LEFT_ARROW: {
    value: '\\left',
    children: <Icon name="icon_arrow_left" width={30} height={30} color="transparent" />,
    latexNodeType: LATEX_NODE_TYPE.LEFT_ARROW,
  },
  '.': {
    value: '.',
    children: <span className="dot-button" />,
    latexNodeType: LATEX_NODE_TYPE.숫자,
  },
  '0': { value: '0', latexNodeType: LATEX_NODE_TYPE.숫자 },
  삭제: {
    value: '\\delete',
    children: <Icon name="icon_delete" width={46} height={46} color="transparent" />,
    latexNodeType: LATEX_NODE_TYPE.삭제,
  },
  RIGHT_ARROW: {
    value: '\\right',
    children: <Icon name="icon_arrow_right" width={30} height={30} color="transparent" />,
    latexNodeType: LATEX_NODE_TYPE.RIGHT_ARROW,
  },
}

export const KEYPAD_BUTTONS: Record<KeypadType, readonly KeypadButton[]> = {
  통합키패드: [
    KEYPAD_BUTTON['-'],
    KEYPAD_BUTTON['1'],
    KEYPAD_BUTTON['2'],
    KEYPAD_BUTTON['3'],
    KEYPAD_BUTTON.대분수,
    KEYPAD_BUTTON['4'],
    KEYPAD_BUTTON['5'],
    KEYPAD_BUTTON['6'],
    KEYPAD_BUTTON.분수,
    KEYPAD_BUTTON['+'],
    KEYPAD_BUTTON['7'],
    KEYPAD_BUTTON['8'],
    KEYPAD_BUTTON['9'],
    KEYPAD_BUTTON.LEFT_ARROW,
    KEYPAD_BUTTON['.'],
    KEYPAD_BUTTON['0'],
    KEYPAD_BUTTON.삭제,
    KEYPAD_BUTTON.RIGHT_ARROW,
  ],
  초등학생용키패드: [
    KEYPAD_BUTTON.대분수,
    KEYPAD_BUTTON.분수,
    KEYPAD_BUTTON.LEFT_ARROW,
    KEYPAD_BUTTON.RIGHT_ARROW,
    KEYPAD_BUTTON.삭제,
    KEYPAD_BUTTON['1'],
    KEYPAD_BUTTON['2'],
    KEYPAD_BUTTON['3'],
    KEYPAD_BUTTON['4'],
    KEYPAD_BUTTON['5'],
    KEYPAD_BUTTON['6'],
    KEYPAD_BUTTON['7'],
    KEYPAD_BUTTON['8'],
    KEYPAD_BUTTON['9'],
    KEYPAD_BUTTON['-'],
    KEYPAD_BUTTON['0'],
    KEYPAD_BUTTON['+'],
  ],
  소수키패드: [
    KEYPAD_BUTTON['1'],
    KEYPAD_BUTTON['2'],
    KEYPAD_BUTTON['3'],
    KEYPAD_BUTTON['.'],
    KEYPAD_BUTTON['4'],
    KEYPAD_BUTTON['5'],
    KEYPAD_BUTTON['6'],
    KEYPAD_BUTTON['7'],
    KEYPAD_BUTTON['8'],
    KEYPAD_BUTTON['9'],
    KEYPAD_BUTTON.삭제,
    KEYPAD_BUTTON['-'],
    KEYPAD_BUTTON['0'],
    KEYPAD_BUTTON['+'],
  ],
} as const
