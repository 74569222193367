import type { MathflatApi } from '@mathflat/domain/@entities/StudentExam/api2'

import maxios from '../utils/maxios'
import type { RecentStudyItem } from '~/@pages/student/student-home/@common/response'

export const studentExamApi = (() => {
  return {
    getStudentExams: ({
      studentId,
      dateTime: { startDate, endDate },
      types = ['MAAT', 'MIDDLE_SCHOOL_ACHIEVEMENT_EVALUATION'],
    }: {
      studentId: string
      dateTime: {
        startDate: string
        endDate: string
      }
      types?: RecentStudyItem['examType'][]
    }) => {
      return maxios.get<MathflatApi.Response.StudentExam[]>(`/student-exam`, {
        params: {
          studentId,
          startDate,
          endDate,
          types,
        },
      })
    },
  }
})()
