import React from 'react'

import modalService from '~/@common/services/modal.service'
import Modal from '~/@common/ui/modal/Modal'

interface Props {
  onAllClear: () => void
}

export const alertClearAllAnswerModal = ({ onAllClear }: Props) => {
  modalService.openModal(
    <Modal.Confirm.Negative
      cancel={{
        children: '취소',
      }}
      confirm={{
        children: '삭제',
        onClick: () => {
          onAllClear()
          modalService.closeModal()
        },
      }}
    >
      입력한 내용을 전체 삭제하시겠습니까?
    </Modal.Confirm.Negative>,
    {
      modalName: '전체삭제_안내',
    },
  )
}
