import { WorksheetDomain } from '@mathflat/domain/@entities'
import type {
  LearningContentStatus,
  SelfLearningI,
  SelfLearningType,
  WorksheetI,
} from '@mathflat/domain/@entities/StudentWorksheet/api2'
import { format } from 'date-fns'

import type { IconNames } from '~/@common/ui/Icon/iconNames.type'
import type { LearningProcessService } from '~/@pages/student/learning-process/@widgets/service/LearningProcess.service'

import type { StudentSelfLearningWorksheetGroupList, StudentWorksheetList } from '.'

export class StudentSelfLearningWorksheetList implements StudentWorksheetList {
  id: number
  studentHomeworkId: number | null
  assignDatetime: Date
  openDatetime: Date | null
  scoreDatetime: Date | null
  correctCount: number
  wrongCount: number
  score: number
  status: LearningContentStatus
  worksheet: WorksheetI & { selfLearningType: SelfLearningType }

  constructor(selfLearning: SelfLearningI) {
    this.id = selfLearning.id
    this.studentHomeworkId = selfLearning.studentHomeworkId
    this.assignDatetime = new Date(selfLearning.assignDatetime)
    this.openDatetime = selfLearning.openDatetime ? new Date(selfLearning.openDatetime) : null
    this.scoreDatetime = selfLearning.scoreDatetime ? new Date(selfLearning.scoreDatetime) : null
    this.correctCount = selfLearning.correctCount
    this.wrongCount = selfLearning.wrongCount
    this.score = selfLearning.score
    this.status = selfLearning.status
    this.worksheet = {
      ...selfLearning.worksheet,
      selfLearningType:
        selfLearning.worksheet.selfLearningType === 'WRONG'
          ? WorksheetDomain.SELF_LEARNING_TYPE.오답유형학습
          : WorksheetDomain.SELF_LEARNING_TYPE.심화학습,
    }
  }

  checkIsSelfLearningCtaVisible() {
    return null
  }

  getIconName(): IconNames {
    return 'icon_inside_point'
  }
  getLabels(): ('숙제' | '비공개' | '자동채점')[] {
    const tags: ('숙제' | '비공개' | '자동채점')[] = []

    if (this.worksheet.accessModifierToStudent === 'PRIVATE') tags.push('비공개')
    if (this.worksheet.autoScorable) tags.push('자동채점')

    return tags
  }

  get titleTags() {
    return this.worksheet.titleTag
  }

  getProblemCount(): number {
    return this.worksheet.problemCount
  }

  getScore(): number {
    return this.score
  }

  getWrongCount(): number {
    return this.wrongCount
  }

  getCorrectCount(): number {
    return this.correctCount
  }

  getTitle(): string {
    return this.worksheet.title
  }

  getFormattedAssignDatetime(): string {
    return format(this.assignDatetime, 'yy.MM.dd')
  }

  getAssignDatetime(): Date {
    return this.assignDatetime
  }

  getSelfLearning(): StudentSelfLearningWorksheetGroupList[] | null {
    return null
  }

  checkIsExam(): boolean {
    return false
  }

  getStatus(): LearningContentStatus {
    return this.status
  }

  checkIsHomework(): boolean {
    return false
  }

  getRoute(): string {
    return ''
  }

  async getSelfLearningRoutePath(service: LearningProcessService, studentWorksheetId: string) {
    const url =
      this.worksheet.selfLearningType === WorksheetDomain.SELF_LEARNING_TYPE.심화학습
        ? await service.보충_심화학습지_학습하기({
            studentWorksheetId,
          })
        : await service.보충_오답학습지_학습하기({
            studentWorksheetId,
          })

    return url
  }
}
