import { css } from '@emotion/react'
import type { Entity } from '@mathflat/domain/@entities/ConceptChip/dto'
import { observer } from 'mobx-react'
import type { FC } from 'react'
import SimpleBar from 'simplebar-react'

import { useStudentAppMediaQuery } from '~/@common/hooks/useMediaQuery'
import { colors } from '~/@common/styles'
import { mediaQuery } from '~/@common/styles/mediaQuery'
import { Drawer } from '~/@common/ui/(Drawer)/BaseDrawer'

import ConceptChip from '../ConceptChip/ConceptChip'

interface Props {
  title: string
  conceptChips: Entity.ConceptChip[]
  isShowDrawer?: boolean
  onCloseDrawer?: () => void
}

const AchievementModal: FC<Props> = ({
  title,
  conceptChips,
  isShowDrawer = false,
  // eslint-disable-next-line @typescript-eslint/no-empty-function, prettier/prettier
  onCloseDrawer = () => { },
}) => {
  const { isMobile } = useStudentAppMediaQuery()

  const content = (
    <div css={_Style}>
      <ul className="list">
        <SimpleBar style={{ maxHeight: '100%' }}>
          {conceptChips.map((item) => (
            <ConceptChip
              key={item.conceptChipId}
              tagName="li"
              className="item"
              item={item}
              size={62}
              css={{
                padding: '0',
              }}
              isShowArrow={false}
            />
          ))}
        </SimpleBar>
      </ul>
    </div>
  )

  if (isMobile) {
    return (
      <Drawer isOpened={isShowDrawer} closeDrawer={onCloseDrawer} bodyPadding={0} size="340px">
        <Drawer.Content>
          <Drawer.Header>{title}</Drawer.Header>
          <Drawer.Body>{content}</Drawer.Body>
        </Drawer.Content>
      </Drawer>
    )
  }

  return content
}

export default observer(AchievementModal)

const _Style = css`
  width: 392px;
  margin: -5px;
  padding: 0 24px 24px;
  .list {
    height: 350px;
    margin-bottom: -24px;
  }

  ${mediaQuery.underTablet} {
    padding: 0;
    margin: 0;
    width: 100%;

    .list {
      height: 100%;
      width: auto;
      margin: 0;
      overflow: hidden;
      .item {
        flex-grow: 1;
        position: relative;
        padding: 0 16px;
        &::after {
          content: '';
          position: absolute;
          left: 0;
          right: 0;
          bottom: 0;
          height: 1px;
          background-color: ${colors.gray.$200};
        }

        &:last-of-type {
          &::after {
            display: none;
          }
        }
      }
    }
  }
`
