import type { PlayerConfig } from './MathflatPlayer'

export const DEFAULT_PLAYBACK_RATE = [
  {
    label: '0.5',
    rate: 0.5,
  },
  {
    label: '0.75',
    rate: 0.75,
  },
  {
    label: '1.0',
    rate: 1,
  },
  {
    label: '1.5',
    rate: 1.5,
  },
  {
    label: '2.0',
    rate: 2,
  },
] as PlayerConfig['defaultPlaybackRateOptions']

export const DEFAULT_RESOLUTION = {
  level: -1,
  resolution: 'auto',
} as PlayerConfig['resolutionInfo']

export const DEFAULT_SKIP_TIME_STEP = 10
export const DEFAULT_VOLUME_STEP = 20
export const DEFAULT_VOLUME = 70
export const DEFAULT_PLAYBACK_RATE_INFO = { label: '1.0', rate: 1 } as PlayerConfig['playbackRate']
