import { observer } from 'mobx-react'
import { useEffect } from 'react'

import { useStudentAppMediaQuery } from '~/@common/hooks/useMediaQuery'
import { useCallbackOnVisibilityChange } from '~/@common/hooks/useCallbackOnVisibilityChange'
import { useRepository } from '~/@common/hooks/useRepository'

import { StudentWorksheetListService } from './@service'
import { StudentWorksheetListMobileView } from './@widgets/list/StudentWorksheetListMobileView'
import { StudentWorksheetTableTabletView } from './@widgets/table/StudentWorksheetTableTabletView'

export const StudentWorksheetListPage = observer(() => {
  useCallbackOnVisibilityChange()
  const { isMobile } = useStudentAppMediaQuery()
  const service = useRepository(StudentWorksheetListService)

  useEffect(() => {
    service.loadStudentWorksheetList()
  }, [])

  if (isMobile) {
    return <StudentWorksheetListMobileView />
  }
  // Desktop Size
  return <StudentWorksheetTableTabletView />
})
