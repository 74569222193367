import { CONTENT_STATUS } from '@mathflat/domain/@entities/(Content)/module'
import type { MathflatApi as ExamApi } from '@mathflat/domain/@entities/StudentExam/api2'
import type { LearningContentStatus } from '@mathflat/domain/@entities/StudentWorksheet/api2'
import { format } from 'date-fns'

import type { IconNames } from '~/@common/ui/Icon/iconNames.type'

import { Exam } from '../Exam'
import type { StudentSelfLearningWorksheetGroupList, StudentWorksheetList } from '.'
import qs from 'qs'

export class StudentExamWorksheetList implements StudentWorksheetList {
  id: number
  examId: number
  studentId: string
  score: number | null
  status: LearningContentStatus
  assignDatetime: Date
  openDatetime: string | null
  exam: Exam
  correctCount: number
  wrongCount: number

  constructor(studentExam: ExamApi.Response.StudentExam) {
    this.id = studentExam.id
    this.examId = studentExam.examId
    this.studentId = studentExam.studentId
    this.score = studentExam.score
    this.status = studentExam.status
    this.correctCount = studentExam.correctCount
    this.wrongCount = studentExam.wrongCount
    this.assignDatetime = new Date(studentExam.assignDatetime)
    this.openDatetime = studentExam.openDatetime
    this.exam = new Exam(studentExam.exam)
  }

  checkIsSelfLearningCtaVisible() {
    return null
  }

  getIconName(): IconNames {
    return 'icon_description_paper'
  }

  getLabels(): ('숙제' | '비공개' | '자동채점')[] {
    const tags: ('숙제' | '비공개' | '자동채점')[] = ['자동채점']

    return tags
  }

  getProblemCount(): number {
    return this.exam.problemCount
  }

  getScore(): number {
    return this.score || 0
  }

  getWrongCount(): number {
    return this.wrongCount
  }

  getCorrectCount(): number {
    return this.correctCount
  }

  getTitle(): string {
    return this.exam.title
  }

  getFormattedAssignDatetime(): string {
    return format(this.assignDatetime, 'yy.MM.dd')
  }

  getAssignDatetime(): Date {
    return this.assignDatetime
  }

  getSelfLearning(): StudentSelfLearningWorksheetGroupList[] | null {
    return null
  }

  checkIsExam(): boolean {
    return true
  }

  updateSelfLearning(): void {
    throw new Error('Method not implemented.')
  }

  getStatus(): LearningContentStatus {
    return this.status
  }
  checkIsHomework(): boolean {
    return false
  }
  getRoute(): string {
    const params = qs.stringify({
      title: this.exam.title,
      size: this.status === CONTENT_STATUS.채점전 ? 'one' : 'all',
      examType: this.exam.examType === 'MAAT' ? 'MAAT' : undefined,
    })
    return `/student/student-exam/${this.id}/scoring?${params}`
  }
}
