import { ONE_MINUTE, ONE_SECOND } from '@mathflat/shared/@common/constants/date'
import { differenceInSeconds } from 'date-fns'

import { studentApi } from '~/@common/api/studentApi'

import { errorHandlerService } from '.'

const INTERVAL_MINUTES = 1

class LearningTimeService {
  private _startCount = 0
  private _learningSeconds = 0
  private _startDate = new Date()
  private _updateTimer = 0

  private _secondsToDuration(seconds: number) {
    return {
      minutes: Math.floor(seconds / 60),
      seconds: seconds % 60,
    }
  }

  private async _updateLearningTime() {
    const { minutes, seconds } = this._secondsToDuration(this._learningSeconds)

    if (minutes <= 0) {
      return
    }

    try {
      await studentApi.updateLearningTime(minutes)
      this._learningSeconds = seconds
      this._startDate = new Date()
    } catch (err) {
      errorHandlerService.handle(err, { preventToast: true })
    }
  }

  async start() {
    this._startCount++

    if (this._startCount > 1) {
      return
    }

    this._startDate = new Date()
    const delaySeconds = 60 - (this._learningSeconds % 60)

    // 처음 1회는 1분 이내에 요청하고 이후로 INTERVAL_MINUTES 간격으로 요청
    this._updateTimer = window.setTimeout(async () => {
      this._learningSeconds += delaySeconds
      await this._updateLearningTime()

      this._updateTimer = window.setInterval(async () => {
        this._learningSeconds += INTERVAL_MINUTES * 60
        await this._updateLearningTime()
      }, INTERVAL_MINUTES * ONE_MINUTE)
    }, delaySeconds * ONE_SECOND)
  }
  async stop() {
    this._startCount--

    if (this._startCount > 0) {
      return
    }

    window.clearTimeout(this._updateTimer) // setTimeout, setInterval 상관없이 clear
    this._learningSeconds += differenceInSeconds(new Date(), this._startDate)

    await this._updateLearningTime()
  }
}

export default new LearningTimeService()
