import React from 'react'

import type { StyleProps } from '~/@common/types'

import Input from '../(Input)/Input/Input'
import S from './ValidationInput.style'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type Props<T extends Record<string, any>> = {
  name: keyof T
  placeholder?: string
  height?: string
  guideMessage?: string
  errorMessage: string | undefined
  type?: HTMLInputElement['type']
  onKeyDown?: React.KeyboardEventHandler<HTMLInputElement>
  onChange?: React.ChangeEventHandler<HTMLInputElement>
  onFocus?: React.FocusEventHandler<HTMLInputElement>
  onBlur?: React.FocusEventHandler<HTMLInputElement>
} & StyleProps

const ValidationInput = React.forwardRef(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  <T extends Record<string, any>>(
    {
      name,
      placeholder,
      guideMessage,
      errorMessage,
      height = '40px',
      type = 'text',
      onKeyDown,
      onChange,
      onFocus,
      onBlur,
      className,
      style,
    }: Props<T>,
    ref: React.Ref<HTMLInputElement>,
  ) => {
    return (
      <div className={className} css={S.inputContainerStyle} style={style}>
        <Input
          type={type}
          name={name as string}
          onKeyDown={onKeyDown}
          onChange={onChange}
          onFocus={onFocus}
          onBlur={onBlur}
          height={height}
          placeholder={placeholder}
          hasError={Boolean(errorMessage)}
          ref={ref}
        />
        <p css={[S.guideMsgStyle, errorMessage && S.errorMsgStyle]}>
          {errorMessage || guideMessage}
        </p>
      </div>
    )
  },
)

ValidationInput.displayName = 'ValidationInput'

export default ValidationInput
