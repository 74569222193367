import { css } from '@emotion/react'
import type { FC } from 'react'
import type { ReactNode } from 'react'

import { colors, typo } from '~/@common/styles'

type IconSize = 'small' | 'normal'

interface Props {
  content: ReactNode
  iconSize: IconSize
  children?: ReactNode
}

const NoData: FC<Props> = ({ content, iconSize, children }) => {
  let iconWidth = 80
  let iconHeight = 80

  switch (iconSize) {
    case 'small':
      iconWidth = 35
      iconHeight = 31
      break
  }

  return (
    <div css={_css(iconSize)}>
      <img
        src={`/images/icons/icon_balloon_dot.svg`}
        width={iconWidth}
        height={iconHeight}
        alt=""
      />
      <div className="content">{content}</div>
      {children}
    </div>
  )
}

export default NoData

const _css = (iconSize: IconSize) => css`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  gap: 14px;
  padding: 40px 0;

  .content {
    color: ${colors.gray.$900};
    ${iconSize === 'normal' && typo.body02};
    ${iconSize === 'small' && typo.caption01};
  }
`
