import { useCallback, useMemo, useRef } from 'react'

import type { Point } from '../utils/domUtils'

export const POINTER_TYPE = {
  mouse: 'mouse',
  touch: 'touch',
  pen: 'pen',
}

type Timestamp = number
type PointerType = keyof typeof POINTER_TYPE
type GenericEventHandler = (e: Event) => void

export type GestureType = 'pan' | 'pinch'
type PanEventType = 'panstart' | 'pan' | 'panend'
type PinchEventType = 'pinchstart' | 'pinch' | 'pinchend'
type GestureEventType = PanEventType | PinchEventType
type PointerEventType = 'pointerdown' | 'pointermove' | 'pointerup' | 'pointercancel'

interface PanEventDetail {
  startPoint: Point
  deltaX: number
  deltaY: number
}

interface PinchEventDetail {
  centerPoint: Point
  scale: number
}

abstract class BaseGestureEvent extends Event {
  readonly rawEvent: PointerEvent
  readonly clientX: number
  readonly clientY: number
  readonly offsetX: number
  readonly offsetY: number
  readonly pointerType: PointerType

  constructor(type: GestureEventType, rawEvent: PointerEvent) {
    super(type)
    this.clientX = rawEvent.clientX
    this.clientY = rawEvent.clientY
    this.offsetX = rawEvent.offsetX
    this.offsetY = rawEvent.offsetY
    this.pointerType = rawEvent.pointerType as PointerType
    this.rawEvent = rawEvent
  }
}

export class PanEvent extends BaseGestureEvent {
  detail?: PanEventDetail

  constructor(type: PanEventType, rawEvent: PointerEvent, detail?: PanEventDetail) {
    super(type, rawEvent)
    this.detail = detail
  }
}

export class PinchEvent extends BaseGestureEvent {
  detail?: PinchEventDetail

  constructor(type: PinchEventType, rawEvent: PointerEvent, detail?: PinchEventDetail) {
    super(type, rawEvent)
    this.detail = detail
  }
}

interface EventHandler {
  panstart: (e: PanEvent) => void
  pan: (e: PanEvent) => void
  panend: (e: PanEvent) => void
  pinchstart: (e: PinchEvent) => void
  pinch: (e: PinchEvent) => void
  pinchend: (e: PinchEvent) => void
}

export interface Options {
  usePointerCapture?: boolean
  moveDelay?: number
  minMoveDistance?: number
  onPanStart?: EventHandler['panstart']
  onPan?: EventHandler['pan']
  onPanEnd?: EventHandler['panend']
  onPinchStart?: EventHandler['pinchstart']
  onPinch?: EventHandler['pinch']
  onPinchEnd?: EventHandler['pinchend']
}

interface PointerData {
  id: number
  type: PointerType
  startPoint: Point
  lastPoint: Point
  startOffset: Point
  lastOffset: Point
  isPrimary: boolean
  timeStamp: Timestamp
}

const getDistance = (p1: Point, p2: Point) => {
  const xs = (p2.x - p1.x) ** 2
  const ys = (p2.y - p1.y) ** 2
  return Math.sqrt(xs + ys)
}

const getCenter = (p1: Point, p2: Point) => {
  return {
    x: p1.x + (p2.x - p1.x) / 2,
    y: p1.y + (p2.y - p1.y) / 2,
  }
}

export const useGestureEvent = <T extends HTMLElement | SVGElement>(options: Options) => {
  const optionsRef = useRef<Options>(options)
  optionsRef.current = {
    moveDelay: 100,
    minMoveDistance: 0.25,
    ...options,
  }
  const pointerDataMap = useRef<Map<number, PointerData>>(new Map())
  const getPointerData = useCallback((id: number) => pointerDataMap.current.get(id), [])
  const targetRef = useRef<T | null>(null)
  const gestureType = useRef<GestureType>()
  const eventHandlerMap = useRef<Map<GestureEventType, EventHandler[GestureEventType] | undefined>>(
    new Map(),
  )

  eventHandlerMap.current.set('panstart', optionsRef.current.onPanStart)
  eventHandlerMap.current.set('pan', optionsRef.current.onPan)
  eventHandlerMap.current.set('panend', optionsRef.current.onPanEnd)
  eventHandlerMap.current.set('pinchstart', optionsRef.current.onPinchStart)
  eventHandlerMap.current.set('pinch', optionsRef.current.onPinch)
  eventHandlerMap.current.set('pinchend', optionsRef.current.onPinchEnd)

  const handleEvent = useCallback((e: PanEvent | PinchEvent) => {
    const handler = eventHandlerMap.current.get(e.type as GestureEventType)
    if (!handler) {
      return
    }

    if (e instanceof PanEvent) {
      const type = e.type as PanEventType
      ;(handler as EventHandler[typeof type])(e)
    } else if (e instanceof PinchEvent) {
      const type = e.type as PinchEventType
      ;(handler as EventHandler[typeof type])(e)
    } else {
      throw new Error('??')
    }
  }, [])

  const setGestureType = (type: GestureType | undefined) => {
    gestureType.current = type
  }

  const handleSinglePointer = useCallback(
    (e: PointerEvent, pointerData: PointerData) => {
      const { clientX, clientY } = e
      const clientPoint = { x: clientX, y: clientY }

      if (gestureType.current !== 'pan') {
        if (gestureType.current === 'pinch') {
          handleEvent(new PinchEvent('pinchend', e))
        }
        handleEvent(
          new PanEvent('panstart', e, {
            startPoint: clientPoint,
            deltaX: 0,
            deltaY: 0,
          }),
        )
        setGestureType('pan')
      } else {
        handleEvent(
          new PanEvent('pan', e, {
            startPoint: pointerData.startPoint,
            deltaX: clientPoint.x - pointerData.startPoint.x,
            deltaY: clientPoint.y - pointerData.startPoint.y,
          }),
        )
      }
      pointerData.lastPoint = clientPoint
    },
    [handleEvent],
  )

  const handleMultiplePointer = useCallback(
    (e: PointerEvent, pointerData: PointerData) => {
      const { pointerId, clientX, clientY } = e
      const touchPointers = [...pointerDataMap.current.values()].filter(
        (data) => data.type === 'touch',
      )
      const currentPointer = touchPointers.find((data) => data.id === pointerId)
      const otherPointer = touchPointers.find((data) => data.id !== pointerId)

      if (!currentPointer || !otherPointer) {
        return
      }

      const clientPoint = { x: clientX, y: clientY }
      const centerPoint = getCenter(currentPointer.startOffset, otherPointer.startOffset)
      const initialDistance = getDistance(currentPointer.startPoint, otherPointer.startPoint)
      let scale = 1

      if (initialDistance !== 0) {
        const distance = getDistance(clientPoint, otherPointer.lastPoint)
        scale = Number((distance / initialDistance).toFixed(4))
      }

      if (gestureType.current !== 'pinch') {
        if (gestureType.current === 'pan') {
          handleEvent(new PanEvent('panend', e))
        }
        handleEvent(
          new PinchEvent('pinchstart', e, {
            centerPoint,
            scale,
          }),
        )
        setGestureType('pinch')
      }
      handleEvent(
        new PinchEvent('pinch', e, {
          centerPoint,
          scale,
        }),
      )
      pointerData.lastPoint = clientPoint
    },
    [handleEvent],
  )

  const handlePointerDown = useCallback((e: PointerEvent) => {
    const { pointerId, pointerType, isPrimary, clientX, clientY, offsetX, offsetY, timeStamp } = e
    const startPoint = { x: clientX, y: clientY }
    const startOffset = { x: offsetX, y: offsetY }

    const pointerData: PointerData = {
      id: pointerId,
      type: pointerType as PointerType,
      startPoint,
      lastPoint: startPoint,
      startOffset,
      lastOffset: startOffset,
      isPrimary,
      timeStamp,
    }

    for (const data of pointerDataMap.current.values()) {
      data.startPoint = data.lastPoint
      data.startOffset = data.lastOffset
    }

    pointerDataMap.current.set(pointerId, pointerData)
  }, [])

  const handlePointerMove = useCallback(
    (e: PointerEvent) => {
      if (!pointerDataMap.current.size) {
        return
      }

      const { pointerId, clientX, clientY, timeStamp } = e
      const pointerData = getPointerData(pointerId)
      if (!pointerData) {
        return
      }

      const delay = timeStamp - pointerData.timeStamp
      const moveDelay = optionsRef.current?.moveDelay ?? 0
      if (delay < moveDelay) {
        return
      }

      const dx = clientX - pointerData.lastPoint.x
      const dy = clientY - pointerData.lastPoint.y
      const minMoveDistance = optionsRef.current?.minMoveDistance ?? 0

      if (Math.abs(dx) < minMoveDistance && Math.abs(dy) < minMoveDistance) {
        return
      }

      if (optionsRef.current.usePointerCapture) {
        targetRef.current?.setPointerCapture(pointerId)
      }

      const pointerDataSize = pointerDataMap.current.size

      if (pointerDataSize === 1) {
        handleSinglePointer(e, pointerData)
      } else {
        handleMultiplePointer(e, pointerData)
      }
    },
    [getPointerData, handleSinglePointer, handleMultiplePointer],
  )

  const handlePointerUp = useCallback(
    (e: PointerEvent) => {
      const { pointerId } = e
      const pointerData = getPointerData(pointerId)

      if (!pointerData) {
        return
      }

      if (targetRef.current?.hasPointerCapture(pointerId)) {
        targetRef.current.releasePointerCapture(pointerId)
      }

      if (gestureType.current === 'pan') {
        handleEvent(new PanEvent('panend', e))
        setGestureType(undefined)
      } else if (gestureType.current === 'pinch') {
        const pointerIndex = [...pointerDataMap.current.entries()].findIndex(
          ([key, value]) => key === pointerId && value.type === 'touch',
        )
        if (pointerIndex < 2) {
          handleEvent(new PinchEvent('pinchend', e))
          setGestureType(undefined)
        }
      }
      pointerDataMap.current.delete(pointerId)
    },
    [getPointerData, handleEvent],
  )

  const handlePointerCancel = useCallback(
    (e: PointerEvent) => {
      const { pointerId } = e
      const pointerData = getPointerData(pointerId)

      if (pointerData) {
        handlePointerUp(e)
      }
    },
    [getPointerData, handlePointerUp],
  )

  const handleTouchMove = useCallback((e: Event) => {
    e.preventDefault()
  }, [])

  const rawEventData = useMemo(() => {
    return new Map<PointerEventType, (e: PointerEvent) => void>([
      ['pointerdown', handlePointerDown],
      ['pointermove', handlePointerMove],
      ['pointerup', handlePointerUp],
      ['pointercancel', handlePointerCancel],
    ])
  }, [handlePointerCancel, handlePointerDown, handlePointerMove, handlePointerUp])

  const gestureRef = useCallback(
    (node: T | null) => {
      console.log('removeEventListener')
      if (targetRef.current) {
        for (const [type, handler] of rawEventData.entries()) {
          targetRef.current.removeEventListener(type, handler as GenericEventHandler)
        }
        targetRef.current.removeEventListener('mousemove', handleTouchMove)
      }
      setGestureType(undefined)

      if (node) {
        console.log('addEventListener')
        for (const [type, handler] of rawEventData.entries()) {
          node.addEventListener(type, handler as GenericEventHandler)
        }
        node.removeEventListener('mousemove', handleTouchMove)
      }
      targetRef.current = node
    },
    [handleTouchMove, rawEventData],
  )

  return { gestureRef, gestureElRef: targetRef }
}
