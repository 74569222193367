import type { StudentEduMaterialStatus } from '~/@common/types'
import maxios from '~/@common/utils/maxios'

export interface ConceptualWorksheet {
  id: number
  learningStep: 'BACKLOG' | 'RESOLVED'
  score: number
  correctCount: number
  wrongCount: number
  worksheet: {
    problemCount: number
  }
  referenceId: number
  referenceType: 'LITTLE_CHAPTER'
  status: StudentEduMaterialStatus
}

interface CreateConceptualWorksheet {
  assignList: Array<{
    studentWorksheetId: number
  }>
}

export const conceptualLearningApi = {
  fetchConceptualWorksheets: async () => {
    const { data } = await maxios.get<ConceptualWorksheet[]>(
      '/student-learning/self-learning/conceptual',
    )
    return data
  },
  createConceptualWorksheet: async (littleChapterId: number) => {
    const { data } = await maxios.post<CreateConceptualWorksheet>(
      '/student-learning/self-learning/conceptual',
      null,
      {
        params: {
          referenceType: 'LITTLE_CHAPTER',
          referenceId: littleChapterId,
          littleChapterId,
        },
      },
    )
    return data.assignList[0].studentWorksheetId
  },
  patchConceptualWorksheetLearningStep: async (
    studentWorksheetId: number,
    learningStep: 'RESOLVED',
  ) => {
    await maxios.patch('/student-learning/self-learning/conceptual', null, {
      params: {
        studentWorksheetId,
        learningStep,
      },
    })
  },
}
