import { css } from '@emotion/react'

import { colors, colorTheme, typo } from '~/@common/styles'

import _internal from './_internal.ts'

const { parts } = _internal

export default css`
  display: flex;
  align-items: center;
  gap: 8px;

  .${parts.label} {
    color: ${colors.gray.$900};
    ${typo.caption01}
  }

  .${parts.switch} {
    position: relative;
    width: 40px;
    height: 22px;
    border-radius: 14px;

    ::after {
      position: absolute;
      content: '';
      background-color: #ffffff;
      width: 12px;
      height: 12px;
      left: 5px;
      top: 5px;
      border-radius: 50%;
    }
  }

  .${parts.input}:checked ~ .${parts.switch} {
    background-color: ${colorTheme.primary};

    &::after {
      transform: translateX(calc(100% + 5px));
      transition: all 0.12s cubic-bezier(0.78, 0.14, 0.15, 0.86);
    }
  }

  .${parts.input}:not(:checked) ~ .${parts.switch} {
    background-color: ${colors.gray.$600};

    &::after {
      transform: translateX(0);
      transition: all 0.12s cubic-bezier(0.78, 0.14, 0.15, 0.86);
    }
  }

  .${parts.input}:not(:disabled) ~ .${parts.switch} {
    cursor: pointer;
  }
  .${parts.input}:not(:disabled) ~ .${parts.label} {
    cursor: pointer;
  }
`
