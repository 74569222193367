import type { MathflatApi } from '@mathflat/domain/@entities/Curriculum/api'

import maxios from '../utils/maxios'

export const curriculumApi = {
  getCurriculumTree: async () => {
    const { data } = await maxios.get<MathflatApi.Response.CurriculumTree[]>(
      '/curriculums/tree/group-simple',
    )
    return data
  },

  getCurriculum: async (ids: number[], type: 'CONCEPT' | 'LITTLE_CHAPTER') => {
    const { data } = await maxios.get<MathflatApi.Response.CurriculumInfo[]>(`/curriculums`, {
      params: {
        idSet: ids,
        type,
      },
    })
    return data
  },
}
