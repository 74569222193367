import { css } from '@emotion/react'
import type { StudentWorksheetApi } from '@mathflat/domain/@entities/StudentWorksheet/api.ts'
import { pipe } from 'effect'
import * as O from 'effect/Option'
import { AnimatePresence, m } from 'framer-motion'
import { observer, useLocalObservable } from 'mobx-react'
import type { CSSProperties, ReactNode } from 'react'
import { isMobileOnly } from 'react-device-detect'
import { useNavigate } from 'react-router-dom'

import { useStudentAppMediaQuery } from '~/@common/hooks/useMediaQuery'
import { useOrientation } from '~/@common/hooks/useOrientation'
import { useRepository } from '~/@common/hooks/useRepository.ts'
import modalService from '~/@common/services/modal.service.tsx'
import { colors, colorTheme } from '~/@common/styles'
import Button from '~/@common/ui/(Button)/Button/Button.tsx'
import IconButton from '~/@common/ui/(Button)/IconButton/IconButton.tsx'
import { Drawer } from '~/@common/ui/(Drawer)/BaseDrawer'
import Modal from '~/@common/ui/modal/Modal.tsx'
import ConceptChipDetail from '~/@pages/@common/(ConceptChip)/ConceptChipDetail/ConceptChipDetail.tsx'
import { LearningUnavailableAlertModal } from '~/@pages/@widgets/LearningUnavailableAlertModal'
import { ChallengeStore } from '~/@pages/student/@common/store/Challenge.store.ts'
import type { ChallengeType } from '~/@pages/student/@common/store/ChallengeStore.type'
import { ChallengeService } from '~/@pages/student/challenge/@widget/Challenge.service.ts'
import { LearningProcessService } from '~/@pages/student/learning-process/@widgets/service/LearningProcess.service.ts'

const ChallengeStartDrawer = () => {
  const navigate = useNavigate()
  const learningProcessService = useRepository(LearningProcessService)
  const { isMobile } = useStudentAppMediaQuery()

  const challengeService = useRepository(ChallengeService)
  const challengeStore = useRepository(ChallengeStore)
  const { type: orientationType } = useOrientation()

  const isMobileLandscape = isMobileOnly && orientationType === 'landscape'

  const getInfoText = () => {
    const selectedChallenge = localStore.selectedChallenge
    if (selectedChallenge?.type === 'conceptChip') {
      return isMobile
        ? '선택한 유형칩 하나만 학습을 시작합니다.'
        : selectedChallenge.conceptChip.value.conceptName
    } else if (localStore.selectedChallenge?.type === 'littleChapter') {
      return '학습 성취도가 낮은 유형들 먼저 학습을 시작합니다.'
    }
  }

  const localStore = useLocalObservable(() => ({
    get selectedChallenge() {
      if (challengeStore.selectedChallenge === null) return null

      const challenge = challengeStore.selectedChallenge
      const challengeType = challenge.type
      switch (challengeType) {
        case 'conceptChip': {
          return pipe(
            O.Do,
            O.bind('conceptChip', () => challengeService.get(challenge.id)),
            O.bind('type', () => O.some(challengeType)),
            O.getOrNull,
          )
        }
        case 'littleChapter':
          return pipe(
            O.Do,
            O.bind('conceptChips', () =>
              pipe(
                challengeService.getFromTag('littleChapterId', challenge.id),
                O.map((item) =>
                  item
                    .sort((a, b) => a.value.totalOrderScore - b.value.totalOrderScore)
                    .slice(0, 5)
                    .map((v) => v.value),
                ),
              ),
            ),
            O.bind('type', () => O.some(challengeType)),
            O.getOrNull,
          )
      }
    },
    async onStartConceptChip(params: StudentWorksheetApi.챌린지학습지조회.Params) {
      try {
        const data = await learningProcessService.챌린지학습_시작하기(params)

        if (data.이어하기_여부) {
          modalService.openModal(
            <Modal.Confirm.Positive
              css={{ width: 266 }}
              cancel={{
                children: '새로하기',
                onClick: async () => {
                  const url = await data.새로하기()
                  navigate(url)
                },
              }}
              confirm={{
                children: '이어하기',
                onClick: async () => {
                  const url = await data.이어하기()
                  challengeStore.clearSelectedChallenge()
                  modalService.closeModal()
                  navigate(url)
                },
              }}
            >
              <p>
                학습중이던 유형 챌린지가 있습니다.
                <br />
                이어서 하시겠습니까?
              </p>
            </Modal.Confirm.Positive>,
            {
              modalName: '유형 챌린지 이어하기',
            },
          )
          return
        }

        const url = await data.새로하기()
        challengeStore.clearSelectedChallenge()
        navigate(url)
      } catch (e) {
        modalService.openModal(<LearningUnavailableAlertModal />, {
          modalName: '학습 불가',
        })
      }
    },
  }))

  const MobileContainer = (props: { children: ReactNode }) => {
    let title = ''

    switch (localStore.selectedChallenge?.type) {
      case 'conceptChip':
        title = '선택한 유형칩'
        break
      case 'littleChapter':
        title = '취약유형 추천 학습'
        break
    }

    return (
      <Drawer
        size={isMobileLandscape ? '596px' : undefined}
        isOpened={Boolean(localStore.selectedChallenge?.type)}
        closeDrawer={() => {
          challengeStore.clearSelectedChallenge()
        }}
      >
        <Drawer.Content>
          <Drawer.Header>{title}</Drawer.Header>
          <Drawer.Info>{getInfoText()}</Drawer.Info>
          <Drawer.Body>{props.children}</Drawer.Body>
        </Drawer.Content>
      </Drawer>
    )
  }

  const DefaultContainer = (props: { children: ReactNode }) => {
    return (
      <AnimatePresence initial={true}>
        {localStore.selectedChallenge?.type === 'conceptChip' && (
          <m.div
            key="challenge-to-concept-chip"
            className="challenge-to-concept-chip"
            initial={{ y: 'calc(100% + 14px)' }}
            animate={{ y: '0' }}
            exit={{ y: 'calc(100% + 14px)' }}
            transition={{ type: 'spring', damping: 44, stiffness: 1000 }}
          >
            {props.children}
          </m.div>
        )}

        {localStore.selectedChallenge?.type === 'littleChapter' && (
          <m.div
            key="challenge-to-littlechapter"
            className="challenge-to-littlechapter"
            initial={{ y: 'calc(100% + 14px)' }}
            animate={{ y: '0' }}
            exit={{ y: 'calc(100% + 14px)' }}
            transition={{ type: 'spring', damping: 38, stiffness: 800 }}
          >
            {props.children}
          </m.div>
        )}
      </AnimatePresence>
    )
  }

  const ButtonContainer = (props: { type: ChallengeType['type']; children: ReactNode }) => {
    const mobileStyle: CSSProperties = {
      padding: '0 24px 20px',
      width: '100%',
    }
    const defaultStyle: CSSProperties = {}
    const style = isMobile ? mobileStyle : defaultStyle
    return <div style={style}>{props.children}</div>
  }

  const Container = isMobile ? MobileContainer : DefaultContainer
  const conceptChip =
    localStore.selectedChallenge?.type === 'conceptChip'
      ? localStore.selectedChallenge.conceptChip.value
      : undefined

  const conceptChipSize = isMobile ? 'medium' : 'small'

  return (
    <Container>
      {localStore.selectedChallenge?.type === 'conceptChip' && (
        <div className="challenge-to-concept-chip-contents">
          {isMobile ? (
            <ConceptChipDetail
              key={localStore.selectedChallenge.conceptChip.id}
              size={conceptChipSize}
              description={conceptChip?.conceptName}
              achievementLevel={conceptChip?.levelOfAchievement}
              difficultyLevel={conceptChip?.levelOfConceptChip}
              isShowArrow={false}
              textColor={colors.gray.$800}
            />
          ) : (
            <IconButton
              name="icon_check_circle"
              iconStyle={{ color: colorTheme.primary }}
              iconSize={20}
              size={24}
              RightSlot={<b>{getInfoText()}</b>}
              onClick={challengeStore.clearSelectedChallenge}
            />
          )}

          <div className="action">
            <ButtonContainer type={localStore.selectedChallenge?.type}>
              <Button
                theme="primary"
                className="challenge-start"
                size="small"
                minWidth={152}
                style={{
                  display: isMobile ? 'block' : undefined,
                  width: isMobile ? '100%' : undefined,
                }}
                onClick={() => {
                  if (localStore.selectedChallenge?.type !== 'conceptChip') return

                  const { conceptChipId, littleChapterId } =
                    localStore.selectedChallenge.conceptChip.value
                  localStore.onStartConceptChip({
                    conceptChipIds: [conceptChipId],
                    littleChapterId: littleChapterId,
                    referenceId: conceptChipId,
                    referenceType: 'CONCEPT_CHIP',
                  })
                }}
              >
                선택한 유형 챌린지 시작하기
              </Button>
            </ButtonContainer>

            {!isMobile && (
              <IconButton
                name="icon_close"
                size={28}
                iconSize={20}
                onClick={challengeStore.clearSelectedChallenge}
                style={{ marginLeft: '16px' }}
              />
            )}
          </div>
        </div>
      )}
      {localStore.selectedChallenge?.type === 'littleChapter' && (
        <div className="challenge-to-littlechapter-contents">
          {!isMobile && (
            <p>
              <IconButton
                name="icon_check_circle"
                iconStyle={{ color: colorTheme.primary }}
                iconSize={20}
                size={24}
                RightSlot={<b>{getInfoText()}</b>}
                onClick={challengeStore.clearSelectedChallenge}
              />

              <IconButton
                name="icon_close"
                size={28}
                iconSize={20}
                onClick={challengeStore.clearSelectedChallenge}
                style={{ marginLeft: 'auto' }}
              />
            </p>
          )}
          <div className="challenge-to-littlechapter-concepts">
            <div className="items">
              {localStore.selectedChallenge.conceptChips.map(
                ({ conceptChipId, levelOfAchievement, conceptName, levelOfConceptChip }, index) => (
                  <>
                    {isMobile && index !== 0 && (
                      <div
                        css={css`
                          height: 1px;
                          background-color: ${colors.gray.$200};
                        `}
                        key={`delimiter-${conceptChipId}`}
                      ></div>
                    )}
                    <ConceptChipDetail
                      key={conceptChipId}
                      size={conceptChipSize}
                      description={conceptName}
                      achievementLevel={levelOfAchievement}
                      difficultyLevel={levelOfConceptChip}
                      isShowArrow={false}
                      textColor={colors.gray.$800}
                    />
                  </>
                ),
              )}
            </div>

            <div className="action">
              <ButtonContainer type={localStore.selectedChallenge?.type}>
                <Button
                  theme="primary"
                  className="challenge-start"
                  size="small"
                  minWidth={152}
                  style={{
                    display: isMobile ? 'block' : undefined,
                    width: isMobile ? '100%' : undefined,
                  }}
                  onClick={() => {
                    if (localStore.selectedChallenge?.type !== 'littleChapter') return null
                    const littleChapterId =
                      localStore.selectedChallenge.conceptChips[0].littleChapterId
                    localStore.onStartConceptChip({
                      conceptChipIds: localStore.selectedChallenge.conceptChips.map(
                        ({ conceptChipId }) => conceptChipId,
                      ),
                      littleChapterId,
                      referenceId: littleChapterId,
                      referenceType: 'LITTLE_CHAPTER',
                    })
                  }}
                >
                  소단원 챌린지 시작하기
                </Button>
              </ButtonContainer>
            </div>
          </div>
        </div>
      )}
    </Container>
  )
}
export default observer(ChallengeStartDrawer)
