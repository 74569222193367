import type { Entity } from '@mathflat/domain/@entities/ConceptChip/dto'

export default class SmileChallenge {
  readonly strengths: Entity.ConceptChip | null
  readonly weaknesses: Entity.ConceptChip | null

  constructor(data: { strengths: Entity.ConceptChip; weaknesses: Entity.ConceptChip }) {
    this.strengths = data.strengths
    this.weaknesses = data.weaknesses
  }
}
