import styled from '@emotion/styled'
import React, { type ComponentPropsWithoutRef, type PropsWithChildren, useId } from 'react'

import { colors, colorTheme, typo } from '~/@common/styles'
import type { StyleProps } from '~/@common/types'

import CheckboxInput from './CheckboxInput'

const CheckboxInputLabel = React.forwardRef<
  HTMLInputElement,
  PropsWithChildren &
    StyleProps &
    Pick<ComponentPropsWithoutRef<'input'>, 'onChange' | 'id'> & {
      labelProps?: ComponentPropsWithoutRef<'label'>
    }
>(({ children, id: propId, labelProps, onChange, ...props }, ref) => {
  const _id = useId()
  const id = propId ?? _id
  return (
    <S.CheckboxInputLabel {...props}>
      <StyledCheckboxInput id={id} onChange={onChange} ref={ref} />
      <S.Label htmlFor={id} {...labelProps}>
        {children}
      </S.Label>
    </S.CheckboxInputLabel>
  )
})

CheckboxInputLabel.displayName = 'CheckboxInputLabel'

// TODO: 급하게 만든 컴포넌트.. 죄송합니다.
const StyledCheckboxInput = React.forwardRef<
  HTMLInputElement,
  StyleProps & Pick<ComponentPropsWithoutRef<'input'>, 'onChange' | 'id'>
>(({ id, onChange, ...props }, ref) => {
  return (
    <S.CheckboxWrapper {...props}>
      <CheckboxInput id={id} ref={ref} onChange={onChange} />
      <label className="ms__styledCheckboxInputLabel" htmlFor={id} />
    </S.CheckboxWrapper>
  )
})

StyledCheckboxInput.displayName = 'StyledCheckboxInput'

export default CheckboxInputLabel

const S = {
  CheckboxInputLabel: styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
  `,
  Label: styled.label`
    color: ${colors.white};
    ${typo.body02};
    cursor: pointer;
  `,
  CheckboxWrapper: styled.div`
    position: relative;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    align-self: center;

    input[type='checkbox'][data-indeterminate='true'] + .ms__styledCheckboxInputLabel {
      border-color: ${colorTheme.primary};
    }

    input[type='checkbox'] {
      background-color: ${colorTheme.primary};
      &:checked + .ms__styledCheckboxInputLabel {
        border: 1px solid ${colorTheme.primary};
        background-color: ${colorTheme.primary};
      }
    }
    input {
      width: 20px;
      height: 20px;
      box-sizing: border-box;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      margin: 0;
      opacity: 0;
      z-index: 2;
      cursor: pointer;
    }
    .ms__styledCheckboxInputLabel {
      position: relative;
      display: block;
      width: 20px;
      height: 20px;
      border: 1px solid #9f9f9f;
      border-radius: 3px;
      background-color: white;
      cursor: pointer;

      &:after {
        position: absolute;
        display: block;
        width: 12px;
        height: 6px;
        border-left: 2px solid;
        border-bottom: 2px solid;
        border-color: #fff;
        left: 3px;
        top: 5px;
        content: '';
        transform: rotate(-45deg);
      }
    }
  `,
}
