import { css } from '@emotion/react'
import { ConceptChipDomain } from '@mathflat/domain/@entities/ConceptChip/domain.ts'
import type { ComponentProps, CSSProperties, ForwardedRef } from 'react'
import { forwardRef, useId } from 'react'

import { colors } from '~/@common/styles'

const { LEVEL_OF_ACHIEVEMENT } = ConceptChipDomain

export interface Props
  extends Pick<
    ComponentProps<'input'>,
    'checked' | 'defaultChecked' | 'onChange' | 'onClick' | 'disabled' | 'name' | 'value'
  > {
  level: ConceptChipDomain.LevelOfAchievement
  size?: CSSProperties['width']
  type?: 'checkbox' | 'radio'
  recommended?: boolean
  isCircle?: boolean
  'data-id'?: string | number
}

export const kind = 'ms__ConceptChipAchievementLevel'

const ConceptChipAchievementLevel = forwardRef(
  (
    {
      level,
      size,
      recommended,
      isCircle,
      type = 'checkbox',
      name = 'ConceptChipAchievementLevel',
      ...props
    }: Props,
    ref: ForwardedRef<HTMLInputElement>,
  ) => {
    const id = useId()
    const sizeStyle = size ? { width: size, height: size } : {}
    const circleStyle = {
      overflow: 'hidden',
      minWidth: 0,
      minHeight: 0,
    }

    return (
      <div
        css={_css}
        data-component={kind}
        data-level={level}
        data-recommended={recommended}
        style={{
          ...sizeStyle,
          ...(isCircle ? circleStyle : undefined),
        }}
      >
        <input ref={ref} id={id} type={type} tabIndex={-1} name={name} {...props} />
        <label
          role={type}
          htmlFor={id}
          tabIndex={0}
          style={{
            borderRadius: isCircle ? '999px' : undefined,
          }}
        />
      </div>
    )
  },
)

ConceptChipAchievementLevel.displayName = 'ConceptChipAchievementLevel'

export default ConceptChipAchievementLevel

const _css = css`
  position: relative;
  min-width: 30px;
  aspect-ratio: 1 / 1;

  input {
    display: none;
    transform: translateX(-100%);
  }

  label {
    display: flex;
    width: 100%;
    height: 100%;
    place-content: center;
    position: relative;
    overflow: hidden;
    border-radius: 25%;
    border: 1px solid ${colors.gray.$300};
  }

  input:checked + label {
    &::after {
      content: '';
      position: absolute;
      inset: 0;
      background: rgba(0, 0, 0, 0.5) url('/images/icons/icon_check-white.svg') no-repeat center;
    }
  }

  &[data-level='${LEVEL_OF_ACHIEVEMENT.풀지않음}'] label {
    background-color: ${colors.white};
  }

  &[data-level='${LEVEL_OF_ACHIEVEMENT.문제수부족}'] label {
    background-color: ${colors.gray.$400};
  }

  &[data-level='${LEVEL_OF_ACHIEVEMENT.LV1}'] label,
  &[data-level='${LEVEL_OF_ACHIEVEMENT.LV2}'] label {
    background-color: #ff705c;
  }

  &[data-level='${LEVEL_OF_ACHIEVEMENT.LV1}'] label {
    &::before {
      content: '';
      background: url('/images/icons/icon_chip_sad.svg') no-repeat center;
      place-self: center;
      width: 80%;
      height: 80%;
      background-size: contain;
    }
  }

  &[data-level='${LEVEL_OF_ACHIEVEMENT.LV3}'] label {
    background-color: #ffc34f;
  }

  &[data-level='${LEVEL_OF_ACHIEVEMENT.LV4}'] label,
  &[data-level='${LEVEL_OF_ACHIEVEMENT.LV5}'] label {
    background-color: #7fe166;
  }

  &[data-level='${LEVEL_OF_ACHIEVEMENT.LV5}'] label {
    &::before {
      content: '';
      background: url('/images/icons/icon_chip_smile.svg') no-repeat center;
      place-self: center;
      width: 80%;
      height: 80%;
      background-size: contain;
    }
  }

  &[data-recommended='true'] {
    ::after {
      content: '';
      display: block;
      position: absolute;
      width: 10px;
      height: 10px;
      top: -4px;
      right: -4px;
      background: url('/images/icons/icon_star.svg') no-repeat center;
    }
  }
`
