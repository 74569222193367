import styled from '@emotion/styled'

import { colors } from '~/@common/styles'
import Radio from '~/@common/ui/radio'
import type { RadioGroupProps } from '../group/RadioGroup'

export const ChipRadioGroup = ({ children, ...props }: RadioGroupProps) => {
  return (
    <Radio.Group {...props}>
      <S.ChipRadioGroup>{children}</S.ChipRadioGroup>
    </Radio.Group>
  )
}

const S = {
  ChipRadioGroup: styled.div`
    display: flex;
    gap: 8px;
    position: relative;
  `,
}
