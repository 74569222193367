import { action, makeObservable, observable } from 'mobx'

import type { ClassT } from ':/@types/utilityTypes'

class ServiceRepo {
  private store = new WeakMap<ClassT, InstanceType<ClassT>>()

  constructor() {
    makeObservable<ServiceRepo, 'store'>(this, {
      store: observable,
      reg: action,
      set: action,
      del: action,
    })
  }

  reg<T extends ClassT>(Service: T, ...params: ConstructorParameters<T>): InstanceType<T> {
    if (this.has(Service)) return this.get<T>(Service)!
    return this.set(Service, ...params)
  }

  set<T extends ClassT>(Service: T, ...params: ConstructorParameters<T>): InstanceType<T> {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    this.store.set(Service, new Service(...params))
    return this.get(Service)!
  }

  get<T extends ClassT>(Service: T): InstanceType<T> | null {
    const repo = this.store.get(Service)
    // if (!repo) throw Error('해당하는 service 레포지토리가 존재하지 않습니다.')
    if (!repo) return null
    return repo
  }

  has<T extends ClassT>(Service: T) {
    return this.store.has(Service)
  }

  del<T extends ClassT>(Service: T) {
    return this.store.delete(Service)
  }
  clear() {
    this.store = new WeakMap<ClassT, InstanceType<ClassT>>()
  }
}

export default ServiceRepo
