import { observer } from 'mobx-react'

import { useRepository } from '~/@common/hooks/useRepository'
import { SwiperDefaultBackButton, SwiperDefaultNextButton } from '~/@common/ui/(StyledButton)'
import { SwiperController } from '~/@common/ui/SwiperController/SwiperController'
import {
  SwiperControllerConsumerName,
  useSwiperControllerContext,
} from '~/@common/ui/SwiperController/SwiperController.context'

import { WorksheetScoringService } from '../@service/WorksheetScoring.service'

// LeftComponent: SwiperBackButton2, SwiperDefaultBackButton
// RightComponent: SwiperDefaultNextButton, SwiperNextButton2
export const WorksheetSwiperFilter = observer(() => {
  const swiperController = useSwiperControllerContext(SwiperControllerConsumerName)
  const service = useRepository(WorksheetScoringService)

  return (
    <SwiperController
      style={{ maxWidth: '100%', padding: 0 }}
      SlotLeft={
        <SwiperDefaultBackButton
          disabled={swiperController.control?.isBeginning}
          onClick={() => {
            swiperController.control?.slidePrev()
          }}
        />
      }
      SlotCenter={
        <>
          {swiperController.initialized && (
            <>
              <strong>{(swiperController.activeIndex ?? 0) + 1}번 문제</strong>
              <strong style={{ margin: '0 4px' }}>/</strong>
              <p>총 {service.problemScoringColl?.toArr.length} 문제</p>
            </>
          )}
        </>
      }
      SlotRight={
        <SwiperDefaultNextButton
          disabled={swiperController.control?.isEnd}
          onClick={() => {
            swiperController.control?.slideNext()
          }}
        />
      }
    />
  )
})
