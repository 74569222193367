import { css } from '@emotion/react'
import clsx from 'clsx'
import { observer } from 'mobx-react'
import { type FC, useState } from 'react'

import { useStudentAppMediaQuery } from '~/@common/hooks/useMediaQuery'
import { useRepository } from '~/@common/hooks/useRepository'
import modalService from '~/@common/services/modal.service'
import { colors, fontFamily, fontWeight, typo } from '~/@common/styles'
import { mediaQuery } from '~/@common/styles/mediaQuery'

import WeeklyInfoService from '../../@service/WeeklyInfo.service'
import ContentBox from '../ContentBox'
import AchievementModal from './AchievementModal'

type ChallengeType = 'bestIncreasedGrade' | 'bestGradeAchieved'

interface ChallengeInfo {
  type: ChallengeType
  iconName: string
  title: string
  value: number | undefined
  disabled: boolean
}

const WeeklyInfoDoubleBox: FC = () => {
  const { isMobile } = useStudentAppMediaQuery()
  const { weeklyInfo, isReady } = useRepository(WeeklyInfoService)
  const [drawerType, setDrawerType] = useState<ChallengeType>()

  const infoData: ChallengeInfo[] = [
    {
      type: 'bestIncreasedGrade',
      iconName: 'icon_plus_green',
      title: '등급이 올라간 유형',
      value: weeklyInfo?.bestIncreasedGrade?.length,
      disabled: !weeklyInfo?.bestIncreasedGrade?.length,
    },
    {
      type: 'bestGradeAchieved',
      iconName: 'icon_star_fill',
      title: '최고 등급을 달성한 유형',
      value: weeklyInfo?.bestGradeAchieved?.length,
      disabled: !weeklyInfo?.bestGradeAchieved?.length,
    },
  ]

  const getInfoData = (type: ChallengeType) => {
    const info = infoData.find((item) => item.type === type)
    if (!info) {
      throw new Error('invalid type')
    }
    return info
  }

  const getConceptChips = (type: ChallengeType) => {
    return type === 'bestGradeAchieved'
      ? weeklyInfo?.bestGradeAchieved
      : weeklyInfo?.bestIncreasedGrade
  }

  const handleClick = (type: ChallengeType) => {
    const info = getInfoData(type)
    const title = info.title
    const conceptChips = getConceptChips(type)

    if (!conceptChips) {
      return
    }

    if (isMobile) {
      setDrawerType(type)
      return
    }

    const achievementModal = <AchievementModal title={title} conceptChips={conceptChips} />

    modalService.openModal(
      { content: achievementModal, header: title },

      {
        modalName: title,
        hasCloseButton: true,
        headerOption: { position: 'left', hasBottomBorder: false },
      },
    )
  }

  const infoItems = infoData.map((item, index) => {
    if (!isReady) {
      return null
    }
    const title = item.title
    const conceptChips = getConceptChips(item.type)

    if (!conceptChips) {
      return
    }

    const achievementModal = (
      <AchievementModal
        title={title}
        conceptChips={conceptChips}
        isShowDrawer={item.type === drawerType}
        onCloseDrawer={() => setDrawerType(undefined)}
      />
    )

    return (
      <>
        <button
          type="button"
          key={index}
          className={clsx('button', {
            disabled: item.disabled,
          })}
          onClick={() => handleClick(item.type)}
        >
          <dl key={index} className="list">
            <dt className="title">
              <img src={`/images/icons/${item.iconName}.svg`} width={14} height={14} alt="" />
              {item.title}
            </dt>
            <dd className="value">
              <strong>{item.value}</strong>개
            </dd>
          </dl>
        </button>
        {isMobile && achievementModal}
      </>
    )
  })

  const MobileContent = (
    <div css={_Style}>
      {infoItems.map((item, index) => (
        <ContentBox key={index} isReady={isReady} className="item-content-box">
          {item}
        </ContentBox>
      ))}
    </div>
  )

  const DesktopContent = (
    <ContentBox css={_Style} isReady={isReady} borderRadiusType={isMobile ? 'none' : 'full'}>
      {isReady && infoItems}
    </ContentBox>
  )

  return isMobile ? MobileContent : DesktopContent
}

export default observer(WeeklyInfoDoubleBox)

const _Style = css`
  width: 100%;
  height: 100px;
  display: flex;
  justify-content: space-between;
  padding: 0;

  .button {
    display: flex;
    flex: 1 1 0;
    align-items: center;
    justify-content: center;
    &.disabled {
      pointer-events: none;
      dd > strong {
        text-decoration: none;
      }
    }
  }
  .list {
    display: flex;
    flex-direction: column;
    gap: 4px;
  }
  .title {
    display: flex;
    align-items: center;
    ${typo.caption01};
    @media (min-width: 1180px) {
      ${typo.body02};
    }
    > img {
      margin-right: 4px;
    }
  }
  .value {
    display: flex;
    align-items: center;
    justify-content: center;
    > strong {
      ${fontFamily.montserrat};
      font-size: 30px;
      font-weight: ${fontWeight.bold};
      color: ${colors.blue.$500};
      line-height: 1.2;
      margin-right: 4px;
      text-decoration: underline;
    }
  }

  ${mediaQuery.underTablet} {
    flex-direction: column;
    gap: 10px;
    height: auto;

    .item-content-box {
      display: flex;
      align-items: center;
      height: 46px;
    }

    .button {
      display: block;
    }

    .list {
      flex-direction: row;
      justify-content: start;
    }
    .title {
      ${typo.body02};
    }
    .value {
      ${typo.body02};
      margin-left: auto;
      > strong {
        font-size: 20px;
      }
    }
  }
`
