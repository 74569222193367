import { css } from '@emotion/react'
import clsx from 'clsx'
import type { ComponentPropsWithoutRef, ReactNode } from 'react'

import { colors, typo } from '~/@common/styles'
import { mediaQuery } from '~/@common/styles/mediaQuery'

export const kind = 'ms__SwiperController' as const

export const parts = {
  slotCenter: `${kind}_slotCenter`,
}
export type Props = {
  SlotLeft?: ReactNode
  SlotRight?: ReactNode
  SlotCenter?: ReactNode
  selected?: boolean
} & Omit<ComponentPropsWithoutRef<'div'>, 'children'>

export const SwiperController = ({
  SlotLeft,
  SlotRight,
  SlotCenter,
  selected,
  ...props
}: Props) => {
  return (
    <div css={_css} data-component={kind} {...props}>
      {SlotLeft}
      {SlotCenter && (
        <div className={clsx(parts.slotCenter, selected && 'selected')}>{SlotCenter}</div>
      )}
      {SlotRight}
    </div>
  )
}

const _css = css`
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 282px;

  .${parts.slotCenter} {
    display: flex;
    justify-content: center;
    align-items: center;

    min-width: 30px;
    ${typo.body02};
    color: ${colors.gray.$900};
  }

  .${parts.slotCenter} button {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 48px;
    padding: 13.5px;
    ${typo.body02};
    color: ${colors.gray.$900};
    font-weight: bold;
    border-radius: 10px;
    :active {
      background: ${colors.gray.$200};
    }
  }

  ${mediaQuery.underTablet} {
    max-width: unset;
    padding: 0 16px;
  }
`
