import { css } from '@emotion/react'
import React, { type ComponentProps } from 'react'

import { colors, colorTheme, typo } from '~/@common/styles'

export const kind = `ms__Badge` as const
export type Props = {
  fixedWidth?: number
  theme?: keyof typeof attr.theme
  border?: boolean
} & ComponentProps<'span'>

export default function Badge({ fixedWidth, theme = 'secondaryGray', style, ...props }: Props) {
  return (
    <span
      css={_css}
      data-component={kind}
      data-theme={theme}
      style={{ ...(fixedWidth && { width: fixedWidth }), ...style }}
      {...props}
    />
  )
}

export const attr = {
  theme: {
    primaryBlue: 'primaryBlue',
    primaryGray: 'primaryGray',
    secondaryBlue: 'secondaryBlue',
    secondaryGray: 'secondaryGray',
  },
} as const

const { theme } = attr

const _css = css`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  padding: 4px;
  white-space: nowrap;

  ${typo.caption02};

  &[data-theme='${theme.primaryBlue}'] {
    color: ${colors.white};
    background-color: ${colorTheme.primary};
  }
  &[data-theme='${theme.primaryGray}'] {
    color: ${colors.white};
    background-color: ${colors.gray.$700};
  }
  &[data-theme='${theme.secondaryBlue}'] {
    color: ${colorTheme.primary};
    background-color: ${colors.blue.$110};
  }
  &[data-theme='${theme.secondaryGray}'] {
    color: ${colors.gray.$800};
    background-color: ${colors.gray.$200};
  }
`
