/**
 * **학생교육자료 진행 상태**
 * - INCOMPLETE: 학습가능
 * - COMPLETE: 학습완료
 * - PROGRESS: 풀이중
 */
export type StudentEduMaterialStatus = 'INCOMPLETE' | 'COMPLETE' | 'PROGRESS'

export const studentEduMaterialStatusText: Record<StudentEduMaterialStatus, string> = {
  INCOMPLETE: '학습가능',
  PROGRESS: '풀이중',
  COMPLETE: '학습완료',
}

export const studentMAATEduMaterialStatusText: Record<StudentEduMaterialStatus, string> = {
  INCOMPLETE: '응시전',
  PROGRESS: '응시중',
  COMPLETE: '응시완료',
}
