import { css } from '@emotion/react'
import type { PropsWithChildren } from 'react'
import React, { useState } from 'react'
import type { PlacesType } from 'react-tooltip'
import { Tooltip } from 'react-tooltip'

import { tooltipContainerCss, zIndex } from '~/@common/styles'

type Props = {
  id: string | null
  visible: boolean
  place?: PlacesType
  offset?: number
} & PropsWithChildren

const ProblemScoringAnswerTooltip = ({
  id,
  visible,
  offset,
  place = 'right-start',
  children,
}: Props) => {
  return (
    <Tooltip
      id={`${id}`}
      isOpen={visible}
      offset={offset}
      clickable
      globalCloseEvents={{ escape: true, resize: true, clickOutsideAnchor: true }}
      place={place}
      border="none"
      disableStyleInjection={true}
      style={{
        display: visible ? 'block' : 'none',
        background: 'none',
        color: 'none',
        padding: 0,
        zIndex: 10,
        top: -10,
      }}
      noArrow
      opacity={1}
    >
      <div css={_css}>{children}</div>
    </Tooltip>
  )
}

export default ProblemScoringAnswerTooltip

const _css = css`
  ${tooltipContainerCss};
`
