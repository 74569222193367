import type { HandwrittenNoteControllerService } from '@mathflat/handwritten-note'
import { useDraggable } from '@mathflat/handwritten-note'
import { observer } from 'mobx-react'
import type { FC } from 'react'

import ImageViewerToolbar from './ImageViewerToolbar'
import type { ImageViewerToolbarPagingProps } from './ImageViewerToolbarPaging'

interface Props {
  containerEl: HTMLDivElement
  containerPadding: number
  pagingProps?: ImageViewerToolbarPagingProps
  controllerService: HandwrittenNoteControllerService
  isDefaultNoteScale?: boolean
  onResetNoteScale: () => void
}

const ImageViewerToolbarContainer: FC<Props> = ({
  containerEl,
  containerPadding,
  pagingProps,
  controllerService,
  isDefaultNoteScale,
  onResetNoteScale,
}) => {
  const { isDragging, dragDelta, draggableRef } = useDraggable({
    containerEl,
    containerPadding,
  })

  return (
    <div
      ref={draggableRef}
      style={{
        position: 'absolute',
        left: `${containerPadding}px`,
        top: `${containerPadding}px`,
        transform: `translate(${dragDelta.x}px, ${dragDelta.y}px)`,
        touchAction: 'none',
      }}
    >
      <ImageViewerToolbar
        isDragging={isDragging}
        pagingProps={pagingProps}
        controllerService={controllerService}
        isDefaultNoteScale={isDefaultNoteScale}
        onResetNoteScale={onResetNoteScale}
      />
    </div>
  )
}

export default observer(ImageViewerToolbarContainer)
