import styled from '@emotion/styled'
import * as Sentry from '@sentry/react'
import { HttpStatusCode, isAxiosError } from 'axios'
import type { FC } from 'react'
import { isBrowser } from 'react-device-detect'
import { useNavigate } from 'react-router-dom'

import { routeName } from '~/@common/constants'
import { errorHandlerService } from '~/@common/services'
import authService from '~/@common/services/auth.service'
import { colors } from '~/@common/styles'

import type { FallbackProps } from './ErrorBoundary'

// isExpetedError
const ErrorFallback: FC<FallbackProps> = ({ error }) => {
  const navigate = useNavigate()

  errorHandlerService.handle(error)

  const genericErrorMessage = (
    <S.Container>일시적인 오류가 발생했습니다. 나중에 다시 시도해주세요.</S.Container>
  )

  if (error instanceof TypeError) {
    return genericErrorMessage
  }

  switch (error.message) {
    case 'INTERNAL_SERVER_ERROR':
      return genericErrorMessage
    case 'STUDENT_WORKBOOK_NOT_FOUND':
    case 'WORKSHEET_NOT_FOUND':
      return <S.Container>더 이상 이 학습지 또는 교재가 존재하지 않습니다.</S.Container>
    case 'SELF_LEARNING_ALREADY_EXHAUSTED':
      return (
        <S.Container>더 이상 풀 수 있는 문제가 없어요. 다른 보충학습을 시도해주세요!</S.Container>
      )
    default: {
      if (isAxiosError(error) && error.response?.status === HttpStatusCode.Unauthorized) {
        authService.logout()

        if (isBrowser) {
          navigate(routeName.login, { replace: true })
        }
        return null
      }
      return genericErrorMessage
    }
  }
}

const S = {
  Container: styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: auto;
    color: ${colors.red.$400};
  `,
}

export default ErrorFallback
