import {
  makeGetRepoHook,
  makeRepoHook,
  makeSetRepoHook,
} from '@mathflat/shared/@modules/mobx/hooks'
import type { ClassT } from '@mathflat/shared/@types/utilityTypes'
import { useMemo } from 'react'

import { dynamicRepo, serviceRepo } from '../services/repo.service'

export const useRepository = makeRepoHook(serviceRepo, dynamicRepo)
export const useSetRepository = <T extends ClassT>(
  identifier: [T, string] | T,
  ...param: ConstructorParameters<T>
) => {
  return useMemo(() => makeSetRepoHook(serviceRepo, dynamicRepo)(identifier, ...param), [])
}
export const useGetRepository = <T extends ClassT>(identifier: [T, string] | T) => {
  return useMemo(() => makeGetRepoHook(serviceRepo, dynamicRepo)(identifier), [])
}
