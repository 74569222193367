import type { ReadonlyArray } from 'effect'
import { flow, pipe } from 'effect'
import * as O from 'effect/Option'
import { hasProperty, isNullable } from 'effect/Predicate'
import type { ValueOf } from 'type-fest'

import { intersection, union } from ':/@common/utils/set'

const HashKeywordTable = 'shared/HashtagMap'

export const HashtagMapTypeId = Symbol.for(HashKeywordTable)
export type HashtagMapTypeId = typeof HashtagMapTypeId

type TagName = string
type TagValue = number | string
type InputTag = { [tagNames in TagName]: TagValue }

export type TagTuple<Tags extends InputTag> = [tag: keyof Tags, value: Tags[keyof Tags]]
export type TagTuples<Tags extends InputTag> = TagTuple<Tags>[]

// #여주
export class HashtagMap<
  InputTags extends InputTag,
  Id extends PropertyKey,
  Item extends { id: Id; tags: InputTags; value: object },
> {
  readonly [HashtagMapTypeId] = HashtagMapTypeId

  private _itemMap: Map<Id, Item> = new Map()
  private _tagMap: { [K in keyof InputTags]?: Map<InputTags[K], Set<Id>> } = {}

  constructor(init?: ReadonlyArray<Item>) {
    if (init) {
      this.adds(init)
    }
  }

  get size() {
    return this._itemMap.size
  }

  get tagMap() {
    return this._tagMap
  }

  private reduceOnion = (v: O.Option<Set<Id>>[]) => pipe(v, O.reduceCompact(new Set<Id>(), union))
  private reduceIntersection = (v: O.Option<Set<Id>>[]) =>
    pipe(v, ([first, ...last]) => {
      if (O.isNone(first)) return new Set<Id>()
      return pipe(last, O.reduceCompact(first.value, intersection))
    })

  private upsertTag = <K extends keyof InputTags>(name: K, value: InputTags[K], id: Id) => {
    const tagItemMap = this._tagMap[name]
    if (tagItemMap) {
      // update
      const tagItemSet = tagItemMap.get(value)
      if (tagItemSet) {
        tagItemSet.add(id)
      } else {
        tagItemMap.set(value, new Set([id]))
      }
    } else {
      // set
      this._tagMap[name] = new Map([[value, new Set([id])]])
    }
  }

  private addValueForTags = (id: Id, tags: InputTags) => {
    for (const [tagName, tagValue] of Object.entries(tags)) {
      if (isNullable(tagValue)) continue
      this.upsertTag(tagName, tagValue as ValueOf<InputTags>, id)
    }
  }

  add = (item: Item) => {
    const has = this._itemMap.has(item.id)

    if (!has) {
      this._itemMap.set(item.id, item)
      this.addValueForTags(item.id, item.tags)
    }
  }

  adds = (items: ReadonlyArray<Item>) => {
    for (const item of items) {
      this.add(item)
    }
  }

  get = (id: Id): O.Option<Item> => {
    const has = this._itemMap.has(id)
    return has ? O.some(this._itemMap.get(id)!) : O.none()
  }

  getIdFromTag = <K extends keyof InputTags>(
    tagName: K,
    tagValue: InputTags[K],
  ): O.Option<Set<Id>> => O.fromNullable(tagValue ? this._tagMap[tagName]?.get(tagValue) : null)

  getFromTag = <K extends keyof InputTags>(tagName: K, tagValue: InputTags[K]) =>
    pipe(
      this.getIdFromTag(tagName, tagValue),
      O.map((set) => {
        const arr: Item[] = []
        for (const id of set) {
          const item = this._itemMap.get(id)
          if (!item) continue
          arr.push(item)
        }
        return arr
      }),
    )

  getIdFromTags = (tags: TagTuples<InputTags>) =>
    tags.map(([tagName, tagValue]) => this.getIdFromTag(tagName, tagValue))

  getIdFromIntersectionTags = flow(this.getIdFromTags, this.reduceIntersection)
  getIdFromUnionTags = flow(this.getIdFromTags, this.reduceOnion)

  getIdMapFromTagKind = <K extends keyof InputTags>(
    tagName: K,
  ): O.Option<NonNullable<Map<InputTags[K], Set<Id>>>> => O.fromNullable(this._tagMap[tagName])

  //
  // getFromIntersectionTags = flow(this.getIdFromIntersectionTags, (idSet) => {
  //   const arr: Item[] = []
  //   for (const id of idSet) {
  //     const value = this.get(id)
  //     if (O.isNone(value)) continue
  //     arr.push(value.value)
  //   }
  //   return arr
  // })
}

export const isKeywordHashTable = (x: unknown): x is HashtagMap<any, any, any> =>
  hasProperty(x, HashtagMapTypeId)
