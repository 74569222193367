import { observer } from 'mobx-react'
import { useEffect } from 'react'
import _Modal from 'react-modal'
import { FloatingLoader } from 'src/@common/ui/(Loader)/FloatingLoader.tsx'

import modalService from '~/@common/services/modal.service'
import { colors, zIndex } from '~/@common/styles'

import { Icon } from '../Icon/Icon'

const ModalContainer = () => {
  useEffect(() => {
    return () => modalService.closeAllModal()
  }, [])

  return (
    <>
      {modalService.isShowLoader && <FloatingLoader />}
      {modalService.modalList.map((modalState, i) => (
        <_Modal
          key={`modal-${i}`}
          isOpen
          ariaHideApp={false}
          onRequestClose={modalService.closeModal}
          style={{
            overlay: {
              background: 'rgba(0,0,0,.5)',
              position: 'fixed',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              zIndex: zIndex.모달,
            },
            content: {
              display: 'flex',
              flexDirection: 'column',
              position: 'relative',
              padding:
                modalState.opt.modalStyle?.padding ?? modalState.modalContent.header ? 0 : 24,
              top: 'auto',
              left: 'auto',
              right: 'auto',
              bottom: 'auto',
              borderRadius: 14,
              boxShadow: '0 0 50px 0 rgba(0, 0, 0, 0.1)',
              border: '0 none',
              maxHeight: '90vh',
              maxWidth: '90vw',
              overflow: 'visible',
            },
          }}
          testId="ms-component-modal"
          data={{
            ...(modalState.opt?.modalName && { component: modalState.opt.modalName }),
            ...modalState.opt?.dataAtribute,
          }}
        >
          {modalState.modalContent.header && (
            <header
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: modalState.opt.headerOption?.position || 'center',
                padding: 20,
                fontSize: 16,
                fontWeight: 700,
                lineHeight: '24px',
                color: colors.gray.$900,
                borderBottom:
                  modalState.opt.headerOption?.hasBottomBorder === undefined
                    ? '1px solid #d9d9d9'
                    : modalState.opt.headerOption?.hasBottomBorder
                      ? '1px solid #d9d9d9'
                      : 'none',

                ...modalState.opt.headerOption?.style,
              }}
            >
              {modalState.modalContent.header}
            </header>
          )}
          {modalState.opt.hasCloseButton && (
            <button
              style={{ position: 'absolute', top: '20px', right: '20px', zIndex: 99 }}
              type="button"
              onClick={() => {
                if (modalState.opt.onClose) {
                  const useClose = modalState.opt.onClose()
                  if (!useClose) return
                }
                modalService.closeModal()
              }}
            >
              <Icon name="icon_close" color={colors.gray.$900} size={24} />
            </button>
          )}
          <div className="content-body" style={{ overflowY: 'auto', flex: '1 1 auto' }}>
            {modalState.modalContent.content}
          </div>
        </_Modal>
      ))}
    </>
  )
}

export default observer(ModalContainer)
