import * as S from '@effect/schema/Schema'

export const STUDENT_WORKBOOK_LIST_TYPE = {
  일반교재: 'general',
  시그니처교재: 'custom-signature',
} as const

export const studentWorkbookListPathParams = {
  studentWorkbookListType: 'studentWorkbookListType',
} as const
export const StudentWorkbookListTypeSchema = S.enums(STUDENT_WORKBOOK_LIST_TYPE)
export const StudentWorkbookListPathParamSchema = S.struct({
  [studentWorkbookListPathParams.studentWorkbookListType]: StudentWorkbookListTypeSchema,
})

export const StudentWorkbookDetailPathParamSchema = S.struct({
  studentWorkbookId: S.string,
  revisionId: S.string,
  pageNumber: S.string,
})

export type StudentWorkbookDetailPathParamT = S.Schema.From<
  typeof StudentWorkbookDetailPathParamSchema
>
export type StudentWorkbookPathParamT = S.Schema.From<typeof StudentWorkbookListPathParamSchema>
