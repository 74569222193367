import { formatErrors } from '@effect/schema/ArrayFormatter'
import { AxiosError } from 'axios'

import { type ApiErrorResponse, apiErrorResponse } from ':/@common/http/response/ApiResponse.ts'
import type { ServerErrorResponseData } from ':/@common/http/response/ServerResponseData.ts'
import { API_RESPONSE_ERROR } from ':/@common/http/response/types.ts'
import type { MathflatResultError } from ':/@common/result'
import { UNKNOWN_ERROR_TYPE } from ':/@common/result/resultTypes.ts'
import { mathflatResultError } from ':/@common/result/utils.ts'
import { isParseError } from ':/@common/schema/parseResult'
import { isDevelopMode } from ':/@common/utils/dev'

export const catchError = (e: unknown): ApiErrorResponse | MathflatResultError => {
  let result: any
  console.debug('catchError: ', e)
  if (e instanceof AxiosError) {
    const error = e as AxiosError<ServerErrorResponseData>

    // 서버측에서의 response가 있을 시
    if (error.response) {
      result = apiErrorResponse({
        data: null,
        code: error.response.data.code,
        message: error.response.data.message,
        type: API_RESPONSE_ERROR,
        statusCode: error.status ?? null,
        path: e.config?.url,
        err: e,
      } as const)
    }
    result = apiErrorResponse({
      data: null,
      code: error.code ?? null,
      message: '서버측에서 응답을 받지 못한 에러입니다.',
      type: API_RESPONSE_ERROR,
      statusCode: error.status ?? null,
      err: e,
    } as const)
  }

  if (isParseError(e)) {
    result = mathflatResultError({
      type: UNKNOWN_ERROR_TYPE,
      code: null,
      message: null,
      err: e,
    } as const)
  }

  result = mathflatResultError({
    type: UNKNOWN_ERROR_TYPE,
    code: null,
    message: null,
    err: e,
  })

  if (isDevelopMode) {
    if (isParseError(result?.err)) {
      console.error('ParseError: ', formatErrors(result.err?.errors))
    } else {
      console.error('apiFetcher: ', result)
    }
  }

  return result
}
