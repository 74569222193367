import type {
  Items,
  SelectedItem,
} from '~/@pages/student/learning-process/@widgets/ui/LearingProcessNavigation/types/LearningProcessNavigation.type.ts'

type RendererProps = {
  allItems: Items
  currentSelectedItem: SelectedItem
  lastSelectedItems?: SelectedItem[]
}

export const addCustomLabel = ({
  allItems,
  lastSelectedItems,
  currentSelectedItem,
}: RendererProps) => {
  return allItems.map((parentItem, i) => {
    let label = `step${i + 1}. ${parentItem.label} `

    if (parentItem.children) {
      const selectedItem =
        currentSelectedItem.parent.value === parentItem.value
          ? currentSelectedItem
          : lastSelectedItems?.find((each) => each.parent.value === parentItem.value)

      if (selectedItem) {
        const lastSelectedIndex = parentItem.children.findIndex(
          ({ value }) => value === selectedItem.children?.value,
        )
        label += lastSelectedIndex + 1
      } else {
        label += 1
      }

      label += `/${parentItem.children.length}`
    }

    return Object.assign(
      {
        renderLabel: label,
      },
      parentItem,
    )
  })
}
