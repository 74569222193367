import { observer } from 'mobx-react'
import { useNavigate } from 'react-router'
import { useSearchParams } from 'react-router-dom'

import { useRepository } from '~/@common/hooks/useRepository'
import { errorHandlerService } from '~/@common/services'
import modalService from '~/@common/services/modal.service'
import { colors } from '~/@common/styles'
import Modal from '~/@common/ui/modal/Modal'

import { useSubmitWorksheetScoring } from '../@common/hooks/useSubmitWorksheetScoring'
import { WorksheetScoringService } from '../@service/WorksheetScoring.service'

export const 선생님께제출하기모달 = observer(() => {
  const [searchParams] = useSearchParams()
  const handle = useSubmitWorksheetScoring()
  const service = useRepository(WorksheetScoringService)
  const navigate = useNavigate()

  const scoringSize = searchParams.get('size')

  return (
    <Modal.Confirm.Positive
      confirm={{
        children: '제출하기',
        style: {
          backgroundColor: colors.blue.$500,
          padding: '10px',
        },
        onClick: async () => {
          modalService.closeModal()

          try {
            await handle?.onSubmit()

            const studentWorksheetUrl =
              scoringSize === 'one'
                ? `/student/student-worksheet/${service.validation?.studentWorksheetId}`
                : `/student/student-worksheet/${service.validation?.studentWorksheetId}/scoring`

            navigate(studentWorksheetUrl, {
              replace: true,
            })
          } catch (error) {
            errorHandlerService.handle(error)
          }
        },
      }}
      cancel={{
        children: '취소하기',
      }}
    >
      <p>
        채점 내용을 제출하면 다시 수정할 수 없습니다.
        <br />
        채점 내용을 선생님께 제출하시겠습니까?
      </p>
    </Modal.Confirm.Positive>
  )
})
