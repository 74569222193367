import { observer } from 'mobx-react'
import { type FC, useCallback } from 'react'

import { useRepository } from '~/@common/hooks/useRepository'
import type { IterableItem } from '~/@common/types'
import { SubTabText } from '~/@common/ui/SubTab'

import { type RecentStudyType, recentStudyTypeText } from '../../@common/types'
import RecentStudyService from '../../@service/RecentStudy.service'

const studyTypes: RecentStudyType[] = ['ALL', 'HOMEWORK', 'WORKSHEET', 'WORKBOOK', 'EXAM']
const tabItems: IterableItem<string, RecentStudyType>[] = studyTypes.map((v) => {
  return {
    label: recentStudyTypeText[v],
    value: v,
  }
})

const RecentStudyTab: FC = () => {
  const service = useRepository(RecentStudyService)
  const { recentStudyType: recentStudyTab, isReady } = service

  const handleTabClick = useCallback(
    async (newType: RecentStudyType) => {
      if (!isReady) {
        return
      }
      service.setRecentStudyType(newType)
    },
    [isReady, service],
  )

  return (
    <SubTabText.Group value={recentStudyTab} onChange={(v) => handleTabClick(v as RecentStudyType)}>
      {tabItems.map((item) => (
        <SubTabText.GroupItem key={item.value} value={item.value}>
          {item.label}
        </SubTabText.GroupItem>
      ))}
    </SubTabText.Group>
  )
}

export default observer(RecentStudyTab)
