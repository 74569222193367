import React, { type ComponentPropsWithoutRef } from 'react'

import RadioInput from '../RadioInput'
import { RadioGroupConsumerName, useRadioGroupContext } from './RadioGroup.context'

export type RadioGroupInputProps = Omit<ComponentPropsWithoutRef<'input'>, 'onChange' | 'value'> & {
  value: string | number
}
export const RadioGroupInput = React.forwardRef<HTMLInputElement, RadioGroupInputProps>(
  (props, ref) => {
    const context = useRadioGroupContext(RadioGroupConsumerName)
    return (
      <RadioInput
        {...props}
        className={`${RadioGroupConsumerName}-input`}
        name={context?.name}
        checked={props.checked || props.value === context?.value}
        ref={ref}
        onChange={(e) => {
          context?.onChange?.(e.currentTarget.value, e)
        }}
      />
    )
  },
)
RadioGroupInput.displayName = 'RadioGroupInput'
