import { makeAutoObservable } from 'mobx'
import { isAndroid, isChrome, isIOS, isSafari } from 'react-device-detect'

import { handwrittenNoteApi, type HandwrittenNoteType } from '../api/handwrittenNoteApi'
import { IndexedDbService, type StudentWorksheetNoteValue } from './indexedDb.service'

const isApp = (isAndroid || isIOS) && !isSafari && !isChrome

export class WorksheetProblemNoteService {
  private indexedDbService: IndexedDbService
  isShowMobileOrientationGuide = true

  constructor() {
    this.indexedDbService = new IndexedDbService()
    this.isShowMobileOrientationGuide = !isApp

    makeAutoObservable<this, 'indexedDbService'>(this, {
      indexedDbService: false,
    })
  }

  setIsShowMobileOrientationGuide(value: boolean) {
    this.isShowMobileOrientationGuide = value
  }

  async uploadNotes(
    studentWorksheetId: number | undefined,
    worksheetProblemIds: number[] | undefined,
    noteType: Exclude<HandwrittenNoteType, 'SIGNATURE_WORKBOOK_PAGE'>,
  ) {
    if (!studentWorksheetId || !worksheetProblemIds?.length) {
      return
    }
    const noteDataItems = await this.indexedDbService.getStudentWorksheetNotes(
      worksheetProblemIds.map((problemId) => {
        return {
          studentWorksheetId: Number(studentWorksheetId),
          worksheetProblemId: problemId,
        }
      }),
    )
    const noteDataItemsToBeSubmitted: StudentWorksheetNoteValue[] = noteDataItems.map(
      (noteFromWorksheet) => {
        return {
          ...noteFromWorksheet,
          id: noteFromWorksheet.studentWorksheetId,
          subId: noteFromWorksheet.worksheetProblemId,
        }
      },
    )

    if (noteDataItems.length) {
      await handwrittenNoteApi.uploadStudentWorksheetNotes(noteDataItemsToBeSubmitted, noteType)
      await this.indexedDbService.deleteStudentWorksheetNotes(noteDataItems)
    }
  }
}
