import type { FC } from 'react'

const HandwrittenToolbarEraseAllIcon: FC = () => {
  return (
    <svg
      width="34"
      height="34"
      viewBox="0 0 34 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{
        color: 'inherit',
      }}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23.0685 14.0534L16.015 7L8.44514 14.5699L15.4986 21.6233L23.0685 14.0534ZM6.78878 16.3516L13.6466 23.2095L11.9436 24.9125C11.1626 25.6935 9.89626 25.6935 9.11521 24.9125L5.08579 20.883C4.30474 20.102 4.30474 18.8357 5.08579 18.0546L6.78878 16.3516Z"
        fill="currentColor"
      />
      <rect x="11" y="17.252" width="19.618" height="10.526" rx="2.5" fill="currentColor" />
      <rect x="11" y="17.252" width="19.618" height="10.526" rx="2.5" stroke="#FAFAFA" />
      <path
        d="M19.358 21.18V19.752H20.15V23.784H19.358V21.822H18.494V21.18H19.358ZM18.476 23.07C18.172 22.97 17.916 22.826 17.708 22.638C17.5 22.45 17.338 22.23 17.222 21.978C17.098 22.254 16.926 22.496 16.706 22.704C16.49 22.912 16.22 23.07 15.896 23.178L15.5 22.548C15.728 22.476 15.924 22.378 16.088 22.254C16.252 22.13 16.386 21.992 16.49 21.84C16.598 21.688 16.676 21.526 16.724 21.354C16.776 21.182 16.802 21.008 16.802 20.832V20.754H15.716V20.124H18.686V20.754H17.612V20.826C17.612 20.99 17.636 21.154 17.684 21.318C17.732 21.478 17.806 21.63 17.906 21.774C18.01 21.918 18.14 22.05 18.296 22.17C18.456 22.286 18.644 22.38 18.86 22.452L18.476 23.07ZM20.276 24.552V25.182H16.508V23.448H17.3V24.552H20.276Z"
        fill="white"
      />
      <path
        d="M23.094 21.432C23.094 21.64 23.112 21.848 23.148 22.056C23.188 22.26 23.25 22.454 23.334 22.638C23.418 22.822 23.526 22.99 23.658 23.142C23.794 23.294 23.96 23.422 24.156 23.526L23.73 24.114C23.486 23.986 23.282 23.816 23.118 23.604C22.958 23.392 22.828 23.154 22.728 22.89C22.636 23.178 22.508 23.44 22.344 23.676C22.18 23.912 21.972 24.102 21.72 24.246L21.276 23.652C21.472 23.536 21.638 23.396 21.774 23.232C21.91 23.064 22.02 22.884 22.104 22.692C22.188 22.496 22.248 22.29 22.284 22.074C22.324 21.858 22.344 21.644 22.344 21.432V21.318H21.48V20.682H22.344V19.938H23.094V20.682H23.94V21.318H23.094V21.432ZM24.252 19.848H24.99V25.032H24.252V22.488H23.622V21.846H24.252V19.848ZM26.118 19.752V25.278H25.368V19.752H26.118Z"
        fill="white"
      />
    </svg>
  )
}

export default HandwrittenToolbarEraseAllIcon
