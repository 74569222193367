import { css } from '@emotion/react'
import { observer } from 'mobx-react'
import type { FC } from 'react'
import { isDesktop } from 'react-device-detect'

import { commonRepo } from '~/@common/services/repo.service'
import { colors, fontWeight, typo } from '~/@common/styles'

const ScreenSizeGuide: FC = () => {
  if (!isDesktop) {
    return null
  }

  return (
    <div css={_ScreenSizeGuide}>
      <span className="icon-box">
        <img src="/images/icons/icon_small_screen_info.svg" width={88} height={61} alt="" />
      </span>
      <p className="heading">{commonRepo.appKorName} 학생앱 화면 크기 안내</p>
      <p className="description">
        {commonRepo.appKorName} 학생앱은 아이패드 미니(1024px) 이상부터 이용이 가능합니다.
        <br />
        최적화된 서비스 이용을 위해서 더 큰 화면의 태블릿 또는 PC로 접속해주세요.
      </p>
    </div>
  )
}

export default observer(ScreenSizeGuide)

const _ScreenSizeGuide = css`
  position: fixed;
  display: none;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 99999999;
  background: ${colors.white};
  text-align: center;
  .icon-box {
    img {
      vertical-align: top;
    }
  }
  .heading {
    ${typo.heading05};
    font-weight: ${fontWeight.bold};
    color: ${colors.gray.$800};
    padding: 24px 0 6px 0;
  }
  .description {
    ${typo.body02};
    color: ${colors.gray.$800};
  }

  @media (max-width: 1023px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`
