import { useCallback, useMemo, useRef, useState } from 'react'

import type { Point } from '../types'
import type { PanEvent } from './useGestureEvent'
import { useGestureEvent } from './useGestureEvent'

interface Params<T extends HTMLElement | SVGElement> {
  containerEl?: T | null
  containerPadding?: number
}

export const useDraggable = <T extends HTMLElement | SVGElement>(params?: Params<T>) => {
  const [point, setPoint] = useState<Point>({ x: 0, y: 0 })
  const lastPoint = useRef<Point>({ x: 0, y: 0 })
  const [isDragging, setIsDragging] = useState(false)
  const deltaRange = useRef({
    x: [0, 0],
    y: [0, 0],
  })

  const containerPadding = params?.containerPadding ?? 0

  const updateDeltaRange = useCallback(
    (targetEl: T | null) => {
      const containerEl = params?.containerEl ?? document.documentElement
      if (!targetEl) {
        return
      }

      const containerRect = containerEl.getBoundingClientRect()
      const dragableContentRect = targetEl.getBoundingClientRect()
      deltaRange.current = {
        x: [
          -(dragableContentRect.left - containerRect.left) + containerPadding,
          containerRect.right - dragableContentRect.right - containerPadding,
        ],
        y: [
          -(dragableContentRect.top - containerRect.top) + containerPadding,
          containerRect.bottom - dragableContentRect.bottom - containerPadding,
        ],
      }
    },
    [containerPadding, params?.containerEl],
  )

  const getInRangeDeltaValue = useCallback(
    (point: Point) => {
      if (!params) {
        return point
      }
      let x = Math.max(point.x, deltaRange.current.x[0])
      x = Math.min(x, deltaRange.current.x[1])
      let y = Math.max(point.y, deltaRange.current.y[0])
      y = Math.min(y, deltaRange.current.y[1])
      return {
        x,
        y,
      }
    },
    [params],
  )

  const handlePanStart = useCallback(
    (e: PanEvent) => {
      updateDeltaRange(e.rawEvent.currentTarget as T | null)
    },
    [updateDeltaRange],
  )

  const handlePan = useCallback(
    (e: PanEvent) => {
      if (!e.detail) {
        return
      }
      setIsDragging(true)
      const { deltaX, deltaY } = e.detail
      const inRangeDeltaValue = getInRangeDeltaValue({
        x: deltaX,
        y: deltaY,
      })
      setPoint(inRangeDeltaValue)
    },
    [getInRangeDeltaValue],
  )

  const handlePanEnd = useCallback(() => {
    setIsDragging(false)
    lastPoint.current = {
      x: lastPoint.current.x + point.x,
      y: lastPoint.current.y + point.y,
    }
    setPoint({ x: 0, y: 0 })
  }, [point])

  const gestureEventOptions = useMemo(() => {
    return {
      usePointerCapture: true,
      minMoveDistance: 4,
      moveDelay: 100,
      onPanStart: handlePanStart,
      onPan: handlePan,
      onPanEnd: handlePanEnd,
    }
  }, [handlePan, handlePanEnd, handlePanStart])

  const { gestureRef: draggableRef } = useGestureEvent<T>(gestureEventOptions)

  return {
    dragDelta: {
      x: lastPoint.current.x + point.x,
      y: lastPoint.current.y + point.y,
    },
    isDragging,
    draggableRef,
  }
}
