import { css } from '@emotion/react'
import clsx from 'clsx'
import { observer } from 'mobx-react'
import { type ComponentProps, useRef } from 'react'

import { mediaQuery } from '~/@common/styles/mediaQuery'

export const kind = 'ms__CardGridContainer' as const

export interface Props extends ComponentProps<'div'> {
  ratio?: 'fr1' | 'fr3'
}

export const parts = {
  gridContainer: `${kind}_grid_container`,
  inner: `${kind}_inner`,
  fr1: 'fr1',
  fr3: 'fr3',
}

export const paddingBottom = 20

const CardGridContainer = ({ children, className, ratio, ...props }: Props) => {
  const gridContainerRef = useRef<HTMLDivElement>(null)
  return (
    <div
      css={_css}
      ref={gridContainerRef}
      data-component={kind}
      className={clsx(parts.gridContainer, className)}
      {...props}
    >
      <div className={clsx(parts.inner, ratio)}>{children}</div>
    </div>
  )
}

export default observer(CardGridContainer)

const _css = css`
  height: 100%;
  padding: 10px 0 20px;
  display: flex;
  align-items: center;

  // over laptop
  .${parts.inner} {
    display: grid;
    align-content: stretch;
    grid-auto-flow: column;
    row-gap: 15px;
    column-gap: 24px;

    width: 100%;
    height: 100%;

    /* overflow: auto; */
    padding-right: 24px;
    grid-template-columns: 384px;

    &.fr3 {
      grid-template-rows: repeat(3, 140px);
      max-height: 450px;
    }
    &.fr1 {
      grid-template-rows: minmax(295px, 1fr);
    }
  }

  // under laptop
  ${mediaQuery.underTablet} {
    width: 100%;
    align-items: flex-start;
    padding: 0;

    .${parts.inner} {
      height: auto;
      padding: 0;
      grid-auto-flow: row;
      row-gap: 10px;
      grid-template-columns: minmax(343px, 1fr);

      &.fr3 {
        max-height: none;
        grid-template-columns: minmax(343px, 1fr);
        grid-template-rows: minmax(140px, auto);
      }
      &.fr1 {
        grid-template-rows: none;
      }
    }
  }
`
