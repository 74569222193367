import styled from '@emotion/styled'
import { observer } from 'mobx-react'
import type { ButtonHTMLAttributes, PropsWithChildren } from 'react'

import modalService from '~/@common/services/modal.service'
import { colors } from '~/@common/styles'

import Button from '../(Button)/Button/Button'
import ModalContainer from './ModalContainer'

const DefaultPopup = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 310px;
  flex: 1 1 auto;
  height: 100%;

  .popup-content {
    color: ${colors.gray.$900};
    text-align: center;
  }

  .button-wrapper {
    display: flex;
    gap: 12px;
    margin-top: 24px;
  }
`

type ConfirmPopupProps = PropsWithChildren & {
  type: 'positive' | 'negative'
  cancel?: ButtonHTMLAttributes<HTMLButtonElement>
  confirm: ButtonHTMLAttributes<HTMLButtonElement>
}

const ConfirmPopup = observer(
  ({
    type,
    children,
    cancel,
    confirm: { children: confirmChildren, onClick: confirmOnClick, ...confirmAttributes },
  }: ConfirmPopupProps) => {
    return (
      <DefaultPopup>
        <div className="popup-content">{children}</div>
        <div className="button-wrapper">
          <Button
            theme="primary"
            size="small"
            minWidth={100}
            {...cancel}
            className="subGray"
            style={{ ...cancel?.style }}
            onClick={(e) => {
              cancel?.onClick?.(e)
              modalService.closeModal()
            }}
          >
            {cancel?.children ?? '취소하기'}
          </Button>
          <Button
            theme="primary"
            size="small"
            minWidth={100}
            className={type}
            onClick={(e) => {
              confirmOnClick?.(e)
            }}
            {...confirmAttributes}
          >
            {confirmChildren ?? '확인'}
          </Button>
        </div>
      </DefaultPopup>
    )
  },
)

const AlertPopup = ({
  children,
  confirm: { children: confirmChildren, onClick: confirmOnClick, ...confirmAttributes },
  theme = 'primary',
}: PropsWithChildren & {
  confirm: ButtonHTMLAttributes<HTMLButtonElement>
  theme?: 'primary' | 'warn'
}) => {
  let buttonBackgroundColor: string
  switch (theme) {
    case 'primary':
      buttonBackgroundColor = colors.blue.$500
      break
    case 'warn':
      buttonBackgroundColor = colors.red.$300
      break
    default:
      buttonBackgroundColor = colors.blue.$500
      break
  }

  return (
    <DefaultPopup>
      <div className="popup-content">{children}</div>
      <div className="button-wrapper">
        <Button
          theme="primary"
          size="small"
          minWidth={100}
          style={{ color: colors.white, backgroundColor: buttonBackgroundColor }} //TODO: 버튼 컴포넌트 완성되면 삭제
          onClick={(e) => {
            confirmOnClick?.(e)
          }}
          {...confirmAttributes}
        >
          {confirmChildren ?? '확인'}
        </Button>
      </div>
    </DefaultPopup>
  )
}
export const PositiveConfirmPopup = ({ children, ...props }: Omit<ConfirmPopupProps, 'type'>) => {
  return (
    <ConfirmPopup type="positive" {...props}>
      {children}
    </ConfirmPopup>
  )
}
export const NegativeConfirmPopup = ({ children, ...props }: Omit<ConfirmPopupProps, 'type'>) => {
  return (
    <ConfirmPopup type="negative" {...props}>
      {children}
    </ConfirmPopup>
  )
}

const Modal = {
  Confirm: {
    Default: observer(DefaultPopup),
    Positive: observer(PositiveConfirmPopup),
    Negative: observer(NegativeConfirmPopup),
  },
  Alert: observer(AlertPopup),
  Container: ModalContainer,
}

export default Modal
