import styled from '@emotion/styled'
import React from 'react'

import { colors } from '~/@common/styles'
import Table from '~/@common/ui/Table/Table'

const SKELETON_COL3_WIDTH_LIST = [377, 630, 472, 542, 294, 458, 524, 428, 531, 200]

export const StudentWorksheetTableSkeleton = () => {
  return (
    <S.SkeletonTable>
      <Table.Header ratio="80px 80px auto 176px 176px" className="header-row">
        <Table.HeaderItem>
          <div className="skeleton-item col1" />
        </Table.HeaderItem>
        <Table.HeaderItem>
          <div className="skeleton-item col2" />
        </Table.HeaderItem>
        <Table.HeaderItem style={{ display: 'flex', justifyContent: 'flex-start' }}>
          <div className="skeleton-item col3" />
        </Table.HeaderItem>
        <Table.HeaderItem>
          <div className="skeleton-item col4" />
        </Table.HeaderItem>
        <Table.HeaderItem>
          <div className="skeleton-item col5" />
        </Table.HeaderItem>
      </Table.Header>
      <Table.Body>
        {SKELETON_COL3_WIDTH_LIST.map((width) => (
          <Table.Row
            key={width}
            ratio="80px 80px auto 176px 176px"
            style={{ border: 'none', height: 70 }}
          >
            <Table.Item>
              <div className="skeleton-item col1" />
            </Table.Item>
            <Table.Item>
              <div className="skeleton-item col2" />
            </Table.Item>
            <Table.Item style={{ display: 'flex', justifyContent: 'flex-start' }}>
              <div className="skeleton-item" style={{ width: width }} />
            </Table.Item>
            <Table.Item>
              <div className="skeleton-item col4" />
            </Table.Item>
            <Table.Item style={{ display: 'flex', justifyContent: 'space-between' }}>
              <div className="skeleton-item col6" />
              <div className="skeleton-item col6" />
            </Table.Item>
          </Table.Row>
        ))}
      </Table.Body>
    </S.SkeletonTable>
  )
}

const S = {
  SkeletonTable: styled(Table)`
    display: flex;
    overflow: hidden;
    background-color: white;
    width: 100%;
    height: 100%;
    .skeleton-item {
      background-color: ${colors.gray.$400};
      height: 16px;
    }
    .col1,
    .col2,
    .col4 {
      width: 50px;
    }
    .col3 {
      width: 80px;
      margin-left: 15px;
    }
    .col5 {
      width: 94px;
    }
    .col6 {
      width: 86px;
    }
  `,
}
