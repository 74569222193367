import styled from '@emotion/styled'
import { observer } from 'mobx-react'

import modalService from '~/@common/services/modal.service'
import Modal from '~/@common/ui/modal/Modal'
import type { ProblemScoringColl } from '~/@pages/@common/(ProblemScoring)/@trait/ProblemScoring.trait'
import type { ProblemScoringViewOption } from '~/@pages/@common/(ProblemScoring)/@trait/ProblemScoringViewOption.trait'
import 전체정답_전체삭제_제출 from '~/@pages/@common/(ProblemScoring)/전체정답_전체삭제_제출'

type Props = {
  viewOption?: ProblemScoringViewOption<'NOT_학습모듈'>
  problemScoringColl?: ProblemScoringColl<'WORKBOOK'>
  onSubmit(): void
}

const StudentWorkbookDetailFooter = ({ problemScoringColl, viewOption, onSubmit }: Props) => {
  if (!viewOption || viewOption.채점완료_혹은_채점불가능) {
    return null
  }

  return (
    <전체정답_전체삭제_제출
      className="problem-card-grid-footer"
      {...(!(viewOption && viewOption.content.autoScored) && {
        onAllCorrectClick: () => {
          problemScoringColl?.onAllCheckCorrect()
        },
      })}
      onAllClearClick={() => {
        modalService.openModal(
          <Modal.Confirm.Negative
            confirm={{
              children: '삭제하기',
              onClick: () => {
                problemScoringColl?.onAllClear()
                modalService.closeModal()
              },
            }}
          >
            입력한 내용을 전체 삭제하시겠습니까?
          </Modal.Confirm.Negative>,
          { modalName: '교재 채점 - 전체삭제' },
        )
      }}
      onSubmitClick={onSubmit}
      submitTitle={`${problemScoringColl?.toScoredArr.length ?? '-'}문제 제출`}
    />
  )
}

export default observer(StudentWorkbookDetailFooter)

const S = {
  StudentWorkbookDetailFooter: styled.div`
    flex: 0 0 48px;
  `,
}
