import type { Entity as WorkbookEntity } from '@mathflat/domain/@entities/(Content)/Workbook/dto.ts'
import type { SearchWorkbookType } from 'src/@pages/@widgets/WorkbookSearchModal/WorkbookSearch.service.ts'

type Props = {
  workbookList?: WorkbookEntity.Workbook[]
  filteredWorkbookList?: WorkbookEntity.Workbook[]
  workbookType: SearchWorkbookType
}

export const NO_SEARCH_RESULT = (
  <p className="no-data">
    조건에 맞는 결과가 없습니다.
    <br />
    다시 입력해주세요.
  </p>
)

const WorkbookSearchModalEmptyList = ({
  workbookList,
  filteredWorkbookList,
  workbookType,
}: Props) => {
  if (!workbookList) return <></>

  if (workbookList.length === 0) {
    if (workbookType === 'CUSTOM_SIGNATURE')
      return <p className="no-data">선생님께서 시그니처 교재를 출제하지 않았습니다.</p>

    return <p className="no-data">해당 학년 학기의 교재가 없습니다.</p>
  }

  if (!filteredWorkbookList || filteredWorkbookList.length === 0) return NO_SEARCH_RESULT

  return <></>
}

export default WorkbookSearchModalEmptyList
