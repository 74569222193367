import styled from '@emotion/styled'
import { useEffect, useRef } from 'react'
import { useNavigate } from 'react-router'

import { routeName } from '~/@common/constants'
import { useStudentAppMediaQuery } from '~/@common/hooks/useMediaQuery'
import modalService from '~/@common/services/modal.service'
import { commonRepo } from '~/@common/services/repo.service'
import { localStorageService } from '~/@common/services/storage.service'
import { colors, typo } from '~/@common/styles'
import Button from '~/@common/ui/(Button)/Button/Button'
import CheckboxInputLabel from '~/@common/ui/checkbox/CheckboxInputLabel'

export const GuideVideoModal = () => {
  const ref = useRef<HTMLInputElement>(null)
  const navigate = useNavigate()

  const { isMobile } = useStudentAppMediaQuery()

  // 가이드동영상 페이지로 이동
  const moveGuidePage = () => {
    modalService.closeModal()
    navigate(routeName.guideVideo)
    setDoNotShowAgain()
  }
  // 가이드동영상 다시 보지 않음 set
  const setDoNotShowAgain = () => {
    localStorageService.setUserIdsOnDevice()
  }

  useEffect(() => {
    const checkbox = ref.current
    const studentId = commonRepo.student?.id
    return () => {
      if (studentId && checkbox && checkbox.checked) {
        setDoNotShowAgain()
      }
    }
  }, [commonRepo.student?.id])

  return (
    <>
      <S.GuideVideoModal>
        <h2 className="title">업데이트 알림</h2>
        <p className="content">
          사용가이드 영상이 추가되었어요.
          <br />
          {isMobile
            ? '더보기안의 메뉴에서도 다시볼 수 있어요.'
            : '우측상단의 ? 버튼을 눌러서 다시볼 수 있어요.'}
        </p>
        <Button onClick={moveGuidePage} theme="primary" minWidth={130} size="small">
          보러가기
        </Button>
      </S.GuideVideoModal>
      <S.Bottom>
        <div className="option">
          <CheckboxInputLabel ref={ref} className="option">
            다시 보지 않기
          </CheckboxInputLabel>
        </div>
        <button
          className="close"
          onClick={() => {
            modalService.closeModal()
          }}
        >
          닫기
        </button>
      </S.Bottom>
    </>
  )
}

const S = {
  GuideVideoModal: styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 24px;
    width: 280px;
    text-align: center;
    .title {
      ${typo.heading05};
      font-weight: bold;
      color: ${colors.gray.$900};
    }
    .content {
      color: ${colors.gray.$900};
    }
  `,
  Bottom: styled.div`
    position: absolute;
    left: 0;
    bottom: -10px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    transform: translateY(100%);
    z-index: 10000;

    .option {
      margin-right: auto;
      color: ${colors.mono.white};
    }
    .close {
      ${typo.body02};
      color: ${colors.mono.white};
      margin-left: auto;
    }
  `,
}
