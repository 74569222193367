import clsx from 'clsx'
import React, { type ComponentPropsWithoutRef, useId } from 'react'

const RadioInput = React.forwardRef<
  HTMLInputElement,
  Omit<ComponentPropsWithoutRef<'input'>, 'children'>
>((props, ref) => {
  const _id = useId()
  const id = props.id ?? _id
  return (
    <>
      <input
        {...props}
        className={clsx(`ms__component-radio-input`, props.className)}
        data-component="ms__component-radio-input"
        type="radio"
        id={id}
        ref={ref}
      />
    </>
  )
})
RadioInput.displayName = 'RadioInput'
export default RadioInput
