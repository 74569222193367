import { format } from 'date-fns'
import _ from 'lodash'
import { action, computed, makeObservable, observable, runInAction } from 'mobx'

import type { CalendarDateRange } from '~/@common/ui/Calendar/Calendar'
import type { RadioGroupInputValue } from '~/@common/ui/radio/group/RadioGroup.context'

import { StudentWorksheetListStore } from './StudentWorksheetList.store'
import type { ValueOf } from '@mathflat/shared/@types/utilityTypes'
import type { WorksheetDomain } from '@mathflat/domain/@entities'

export class StudentWorksheetListService {
  private _studentWorksheetListStore = new StudentWorksheetListStore()

  get uiState() {
    return this._studentWorksheetListStore.uiState
  }

  get dateRangeForDisplay() {
    const startDate = this._studentWorksheetListStore.uiState.dateRange.start?.dateObj
    const endDate = this._studentWorksheetListStore.uiState.dateRange.end?.dateObj

    if (startDate && endDate) {
      return `${format(startDate, 'yyyy.MM.dd')}~${format(endDate, 'yyyy.MM.dd')}`
    }
    return `${format(new Date(), 'yyyy.MM.dd')}~${format(new Date(), 'yyyy.MM.dd')}`
  }

  get beautifiedStudentWorksheetList() {
    const result = _.chain([...this._studentWorksheetListStore.studentWorksheetList])
      .filter((studentWorksheet) =>
        this._studentWorksheetListStore.uiState.isOnlyHomeWorkOptionToggled
          ? studentWorksheet.checkIsHomework()
          : true,
      )
      .map((studentWorksheet) => {
        const currentStatusOption = this.uiState.statusFilterOption

        // TODO: 메서드는 rest로 커버가 안 되어서 우선 이렇게 재할당 하였습니다... (종원)
        const result = {
          ...studentWorksheet,
          checkIsExam: studentWorksheet.checkIsExam,
          checkIsHomework: studentWorksheet.checkIsHomework,
          checkIsSelfLearningCtaVisible: studentWorksheet.checkIsSelfLearningCtaVisible,
          getCorrectCount: studentWorksheet.getCorrectCount,
          getFormattedAssignDatetime: studentWorksheet.getFormattedAssignDatetime,
          getIconName: studentWorksheet.getIconName,
          getStatus: studentWorksheet.getStatus,
          getTitle: studentWorksheet.getTitle,
          getLabels: studentWorksheet.getLabels,
          getProblemCount: studentWorksheet.getProblemCount,
          getScore: studentWorksheet.getScore,
          getWrongCount: studentWorksheet.getWrongCount,
          getRoute: studentWorksheet.getRoute,

          id: studentWorksheet.id,
          getAssignDatetime: studentWorksheet.getAssignDatetime,
          getSelfLearning() {
            if (currentStatusOption !== 'ALL') {
              return (
                studentWorksheet
                  .getSelfLearning()
                  ?.filter((selfLearning) => selfLearning.getStatus() === currentStatusOption) || []
              )
            }
            return studentWorksheet.getSelfLearning()
          },
        }
        return result
      })
      .filter((studentWorksheet) =>
        this.uiState.statusFilterOption === 'ALL'
          ? true
          : (studentWorksheet
              .getSelfLearning()
              ?.find((selfLearning) => selfLearning.getStatus() === this.uiState.statusFilterOption)
              ?.getStatus() || studentWorksheet.getStatus()) === this.uiState.statusFilterOption,
      )

      .filter((studentWorksheet) =>
        this._studentWorksheetListStore.uiState.isOnlyShowExamWorksheetOptionToggled
          ? studentWorksheet.checkIsExam()
          : true,
      )
    return _.orderBy(
      result.value(),
      [
        (studentWorksheet) => studentWorksheet.getAssignDatetime(),
        (studentWorksheet) => studentWorksheet.id,
      ],
      ['desc', 'desc'],
    )
  }

  constructor() {
    makeObservable<this, '_studentWorksheetListStore'>(this, {
      _studentWorksheetListStore: observable,
      uiState: computed,
      dateRangeForDisplay: computed,
      updateDateRange: action.bound,
      beautifiedStudentWorksheetList: computed,
      updateStatusFilterOption: action.bound,
      loadStudentWorksheetList: action.bound,
      updateToggleOption: action.bound,
    })
  }

  updateDateRange(newDateRange: CalendarDateRange) {
    const nextDate = !newDateRange.end
      ? {
          start: newDateRange.start,
          end: newDateRange.start,
        }
      : newDateRange

    this._studentWorksheetListStore.uiState.updateDateRange(nextDate)
    this.loadStudentWorksheetList()
  }

  updateStatusFilterOption(v: RadioGroupInputValue) {
    if (v === 'ALL' || v === 'INCOMPLETE' || v === 'PROGRESS' || v === 'COMPLETE') {
      this._studentWorksheetListStore.uiState.updateStatusFilterOption(v)
    }
  }

  updateToggleOption(
    toggleOption: '_isOnlyHomeWorkOptionToggled' | '_isOnlyShowExamWorksheetOptionToggled',
    value: boolean,
  ) {
    this._studentWorksheetListStore.uiState.updateOption(toggleOption, value)
  }

  async loadStudentWorksheetList() {
    this.uiState.updateIsFetching(true)

    const [fetchedStudentWorksheetList, fetchedStudentExamList] = await Promise.all([
      await this._studentWorksheetListStore.fetchStudentWorksheets(),
      await this._studentWorksheetListStore.fetchStudentExams(),
    ])

    this.uiState.updateIsFetching(false)

    runInAction(async () => {
      this._studentWorksheetListStore.updateStudentWorksheetList([
        ...fetchedStudentWorksheetList,
        ...fetchedStudentExamList,
      ])
    })
  }
}
