import { css } from '@emotion/react'
import { observer } from 'mobx-react'
import type { FC } from 'react'
import { useCallback, useEffect, useRef, useState } from 'react'

import { useStudentAppMediaQuery } from '~/@common/hooks/useMediaQuery'
import usePullToRefresh from '~/@common/hooks/usePullToRefresh'
import { useRepository } from '~/@common/hooks/useRepository'
import { errorHandlerService } from '~/@common/services'
import { mediaQuery } from '~/@common/styles/mediaQuery'

import RecentStudyService from '../../@service/RecentStudy.service'
import ContentBox from '../ContentBox'
import NoData from '../NoData'
import SectionHeader from '../SectionHeader'
import RecentStudyList from './RecentStudyList'
import SectionTab from './RecentStudyTab'
import SimpleBar from 'simplebar-react'
import modalService from '~/@common/services/modal.service'
import Modal from '~/@common/ui/modal/Modal'
import StudentMAATExamModal from '~/@pages/student/student-exam/$studentExamId/scoring/@widgets/StudentMAATExamModal'
import { sessionStorageService } from '~/@common/services/storage.service'
import { format } from 'date-fns'
import { MAAT_ROUND1_DATE } from '~/@common/constants/MAAT'

const SKELETON_LENGTH = 20
const SKELETON_DELAY = 500
const MOBILE_MIN_HEIGHT = 205

const RecentStudySection: FC = () => {
  const service = useRepository(RecentStudyService)
  const { isMobile } = useStudentAppMediaQuery()
  const { recentStudies, recentStudyType, isReady } = service
  const [isShowSkeleton, setIsShowSkeleton] = useState(true)
  const timerRef = useRef(0)

  const loadRecentStudies = useCallback(async () => {
    try {
      await service.loadRecentStudies(recentStudyType)
    } catch (err) {
      errorHandlerService.handle(err)
    }
  }, [service, recentStudyType])

  useEffect(() => {
    const isSkipping =
      sessionStorage.getItem(sessionStorageService.keywords.MAAT응시팝업_미노출여부) === 'true'

    if (isSkipping) {
      return
    }

    const hasIncompleteMAAT = recentStudies?.find(
      ({ examType, status }) => examType === 'MAAT' && status === 'INCOMPLETE',
    )

    if (hasIncompleteMAAT) {
      modalService.openModal(
        <Modal.Confirm.Positive
          confirm={{
            children: '응시하기',
            onClick: () => {
              modalService.closeModal()
              if (hasIncompleteMAAT.studentExamId) {
                modalService.openModal(
                  <StudentMAATExamModal
                    studentExamId={hasIncompleteMAAT.studentExamId}
                    title={hasIncompleteMAAT.title}
                  />,
                  {
                    modalName: 'MAAT응시확인팝업',
                  },
                )
              }
            },
          }}
          cancel={{ children: '다음에 하기' }}
        >
          <p>
            수학경시대회를 응시할 수 있습니다.
            <br />
            지금 바로 응시하겠습니까?
            <br />
            (응시 종료는 {format(MAAT_ROUND1_DATE.응시종료, 'M월 dd일 HH시 mm분')}입니다.)
          </p>
        </Modal.Confirm.Positive>,

        { modalName: 'MAAT응시팝업' },
      )
      sessionStorage.setItem('MAAT응시팝업_미노출여부', 'true')
    }
  }, [recentStudies])

  useEffect(() => {
    void loadRecentStudies()
  }, [loadRecentStudies])

  useEffect(() => {
    window.clearTimeout(timerRef.current)

    if (!isReady) {
      timerRef.current = window.setTimeout(() => {
        setIsShowSkeleton(true)
      }, SKELETON_DELAY)
    } else {
      setIsShowSkeleton(false)
    }
  }, [isReady])

  // pull to refresh
  usePullToRefresh({
    targetElement: '.list-container',
    deps: [isReady],
    onRefresh: async () => {
      await loadRecentStudies()
    },
    shouldPullToRefreshFunc: (domElement) => {
      if (!domElement || isMobile) {
        return false
      }
      return domElement.scrollTop === 0
    },
  })

  const hasData = Boolean(recentStudies?.length)

  return (
    <section
      css={css(_Style, {
        overflow: isShowSkeleton ? 'auto' : 'hidden',
      })}
    >
      <SectionHeader>
        <SectionTab />
      </SectionHeader>
      {isShowSkeleton && (
        <div
          className="content-box-wrapper"
          css={{
            backgroundColor: isMobile ? '#E6EAEE' : undefined,
            borderRadius: isMobile ? '14px' : undefined,
          }}
        >
          {!isMobile &&
            Array.from({ length: SKELETON_LENGTH }).map((v, index) => (
              <ContentBox key={index} isReady={false} css={{ height: 106 }}></ContentBox>
            ))}
        </div>
      )}
      {!isShowSkeleton && (
        <div
          className="list-container"
          style={{
            minHeight: hasData ? undefined : 122,
          }}
        >
          {!hasData && isReady ? (
            <NoData type={recentStudyType} css={{ flexGrow: 1 }} />
          ) : (
            <SimpleBar style={{ maxHeight: '100%', overflow: isMobile ? 'hidden' : 'unset' }}>
              <RecentStudyList />
            </SimpleBar>
          )}
        </div>
      )}
    </section>
  )
}

const _Style = css`
  display: flex;
  flex-direction: column;
  overflow: auto;

  .content-box-wrapper {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  .list-container {
    overflow: hidden;
    height: 100%;
    z-index: 0; /* 스크롤바가 리스트 아래로 보이는 현상 방지 */
  }

  ${mediaQuery.underTablet} {
    overflow: unset;

    .list-container {
      overflow: unset;
      height: auto;
      min-height: ${MOBILE_MIN_HEIGHT}px;
    }
    .content-box-wrapper {
      min-height: ${MOBILE_MIN_HEIGHT}px;
    }
  }
`

export default observer(RecentStudySection)
