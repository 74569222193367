import { SubTabButtonRadioGroup, SubTabTextRadioGroup } from './SubTabGroup'
import { SubTabButtonGroupItem, SubTabTextRadioGroupItem } from './SubTabItem'

export const SubTabText = {
  Group: SubTabTextRadioGroup,
  GroupItem: SubTabTextRadioGroupItem,
}

export const SubTabButton = {
  Group: SubTabButtonRadioGroup,
  GroupItem: SubTabButtonGroupItem,
}
