import type { ReactNode } from 'react'

import modalService from '~/@common/services/modal.service'
import Modal from '~/@common/ui/modal/Modal'

const makeAlert = (content: ReactNode, modalName: string, children?: ReactNode) => {
  return () =>
    new Promise<boolean>((resolve) => {
      modalService.openModal(
        <Modal.Alert
          confirm={{
            onClick: () => {
              resolve(true)
            },
            children,
          }}
        >
          {content}
        </Modal.Alert>,
        {
          modalName,
          onAfterClose: () => {
            resolve(false)
          },
        },
      )
    })
}

export const openNotAllLocallyScoredAlert = makeAlert(
  <>
    다 풀지 않은 문제가 있어요.
    <br />
    모든 문제를 풀고 제출해주세요.
  </>,
  '소단원개념학습_일부제출불가',
)

export const openSubmitConfirmModal = makeAlert(
  <>
    제출한 후에는 답을 수정할 수 없어요.
    <br />
    그래도 제출할까요?
  </>,
  '소단원개념학습_제출하기',
)
