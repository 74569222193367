import styled from '@emotion/styled'

import type { StyleProps } from '~/@common/types'

import { Icon } from '../../Icon/Icon'

type Props = {
  playing: boolean
  handlePlaying: () => void
} & StyleProps

const PlayingButton = ({ playing = false, handlePlaying, ...props }: Props) => {
  return (
    <S.PlayingButton onClick={handlePlaying} {...props}>
      <Icon name={playing ? 'icon_pause' : 'icon_play'} size={22} color="#fff" />
    </S.PlayingButton>
  )
}

const S = {
  PlayingButton: styled.button`
    padding: 5px;
    cursor: pointer;
  `,
}

export default PlayingButton
