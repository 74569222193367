import PullToRefresh from 'pulltorefreshjs'
import { type DependencyList, useEffect } from 'react'

type Props = {
  targetElement?: string
  deps?: DependencyList
  onRefresh?: (() => PromiseLike<void>) | (() => void)
  shouldPullToRefreshFunc?: (domElement: Element | null) => boolean
}

const usePullToRefresh = ({
  targetElement = 'body',
  deps = [],
  onRefresh = window.location.reload,
  shouldPullToRefreshFunc,
}: Props) => {
  useEffect(() => {
    if (!document.querySelector(targetElement)) return

    PullToRefresh.init({
      mainElement: targetElement,
      triggerElement: targetElement,
      classPrefix: 'g-ptr--',
      getMarkup() {
        return '<div class="g-ptr--loading"></div>'
      },
      getStyles() {
        return `
              .g-ptr--ptr {
                height: 0;
                text-align: center;
                transition: height 0.3s, min-height 0.3s;
                overflow: hidden;
              }
              .g-ptr--loading {
                width: 24px;
                height: 24px;
                margin: 4px auto 0 auto;
                border-radius: 50%;
                position: relative;
                animation: g-ptr--rotate 1s linear infinite
              }
              .g-ptr--loading::before {
                content: "";
                box-sizing: border-box;
                position: absolute;
                inset: 0px;
                border-radius: 50%;
                border: 3px solid #888;
                animation: g-ptr--prixClipFix 2s linear infinite ;
              }

              @keyframes g-ptr--rotate {
                100% { transform: rotate(360deg) }
              }

              @keyframes g-ptr--prixClipFix {
                0%   {clip-path:polygon(50% 50%,0 0,0 0,0 0,0 0,0 0)}
                25%  {clip-path:polygon(50% 50%,0 0,100% 0,100% 0,100% 0,100% 0)}
                50%  {clip-path:polygon(50% 50%,0 0,100% 0,100% 100%,100% 100%,100% 100%)}
                75%  {clip-path:polygon(50% 50%,0 0,100% 0,100% 100%,0 100%,0 100%)}
                100% {clip-path:polygon(50% 50%,0 0,100% 0,100% 100%,0 100%,0 0)}
              }

            `
      },
      shouldPullToRefresh() {
        const element = document.querySelector(targetElement)

        if (shouldPullToRefreshFunc) {
          return shouldPullToRefreshFunc(element)
        }

        const HEADER_HEIGHT = 56

        if (element) {
          return element.getBoundingClientRect().top > HEADER_HEIGHT
        } else {
          return !window.scrollY
        }
      },
      onRefresh() {
        return onRefresh()
      },
    })

    return () => {
      PullToRefresh.destroyAll()
    }
  }, [targetElement, ...deps])
}

export default usePullToRefresh
