import * as Sentry from '@sentry/react'
import React from 'react'

export interface FallbackProps {
  error: Error
  resetErrorBoundary?: (...args: Array<unknown>) => void
}

interface Props {
  resetKeys?: Array<unknown>
  // class component에서 function을 리턴하면 hooks를 못 쓴다..
  FallbackComponent: React.ComponentType<FallbackProps>
  children?: React.ReactNode
}

interface State {
  error: Error | null
}

const initialState = { error: null }

class ErrorBoundary extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = initialState
  }

  static getDerivedStateFromError(error: Error) {
    Sentry.captureException(error)
    return { error }
  }

  // componentDidUpdate(prevProps: Props) {
  //   if (this.state.hasError == false) {
  //     return;
  //   }

  //   if (isDifferentArray(prevProps.resetKeys, this.props.resetKeys)) {
  //     // Trigger Reset
  //   }
  // }

  // componentDidCatch(error, errorInfo) {
  //   logErrorToMyService(error, errorInfo)
  // }

  resetErrorBoundary() {
    this.setState(initialState)
  }

  override render() {
    const { children, FallbackComponent } = this.props
    const { error } = this.state

    if (error !== null) {
      const props = {
        error,
        resetErrorBoundary: this.resetErrorBoundary,
      }

      return <FallbackComponent {...props} />
    }

    return children
  }
}

export default ErrorBoundary
