import styled from '@emotion/styled'

import { colors, textEllipsis, typo } from '~/@common/styles'
import { mediaQuery } from '~/@common/styles/mediaQuery'

export const S = {
  Header: styled.section`
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 12px;
    max-width: 1280px;
    height: 80px;
    margin: 0 auto;
    padding: 19px 40px;
    background-color: white;

    .gnb__nav {
      display: flex;
      gap: 10px;
      max-width: 1280px;
      ${mediaQuery.underTablet} {
        width: 100%;
        justify-content: space-between;

        &.gnb__nav-bottom {
          height: 55px;
          padding: 14px 16px;
        }
      }
    }
    .gnb__nav-link {
      display: flex;
      align-items: center;
      gap: 4px;
      padding: 8px 12px;
      color: ${colors.gray.$700};
      white-space: nowrap;
      ${typo.body01};

      svg {
        display: none;
      }

      &.active {
        background-color: rgba(57, 95, 226, 0.05);
        border-radius: 10px;
        color: ${colors.blue.$500};
        font-weight: 700;
        svg {
          display: block;
        }
      }
    }

    .gnb__nav-logo {
      display: flex;
      align-self: center;
      height: 100%;
      width: 120px;
      ${mediaQuery.underTablet} {
        align-items: center;
        width: 96px;
      }
    }

    .gnb__nav-link-mobile {
      display: flex;
      align-items: center;
      gap: 20px;
      font-weight: 700;
      color: ${colors.gray.$700};
      .title-container {
        display: flex;
        flex-direction: column;
        position: relative;
      }
      &.active {
        color: ${colors.blue.$500};
      }
      .underline {
        position: absolute;
        width: 100%;
        height: 2px;
        background-color: ${colors.blue.$500};
        bottom: -4px;
      }
    }
    .gnb__userService {
      display: flex;
      align-items: center;
      gap: 12px;
    }
    .gnb__userService-opinion {
      display: flex;
      gap: 6px;
      border-radius: 8px;
      padding: 10px 12px;
      border: 1px solid ${colors.gray.$300};
      color: ${colors.gray.$700};
      ${typo.body01}
      white-space: nowrap;

      > svg {
        width: 22px;
        height: 22px;
        color: #d9d9d9;
      }
    }
    .gnb__userService-opinion__desktop-only {
      ${mediaQuery.underDesktop} {
        display: none;
      }
    }

    .gnb__userService-profile {
      display: flex;
      gap: 6px;
      border-radius: 8px;
      padding: 10px 12px;
      border: 1px solid ${colors.gray.$300};
      color: ${colors.gray.$700};
      ${typo.body01}
      min-width: 130px;
      .name {
        min-width: 78px;
        text-align: right;
        ${textEllipsis(1)}
      }
    }
    .gnb__userService-qrCode {
      border-radius: 8px;
      padding: 8px;
      border: 1px solid ${colors.gray.$300};
      width: 42px;
      height: 42px;
    }
    .qr__button-guide-wrapper {
      display: flex;
      position: absolute;
      right: 60px;
      top: -7px;
    }
    .qr__button-guide {
      padding: 8px 12px;
      width: 194px;
      border-radius: 8px;
      background-color: ${colors.white};
      color: ${colors.gray.$900};
      text-align: right;
      ${typo.body02}
    }
    .qr__button-wrapper {
      position: relative;
    }
    .qr__button {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 42px;
      height: 42px;
      background-color: white;
      border-radius: 8px;
      border: 1px solid ${colors.gray.$300};
      padding: 8px;
      z-index: 2;

      ${mediaQuery.underTablet} {
        width: 32px;
        height: 32px;
        padding: 6px;
        > svg {
          width: 100%;
          height: 100%;
        }
      }
    }
    .qr__icon-wrapper {
      position: absolute;
      top: 50%;
      right: 50%;
      transform: translate(50%, -50%);
      display: flex;
      justify-content: center;
      align-items: center;
      width: 70px;
      height: 70px;
      border-radius: 50%;
      background-color: white;
    }

    ${mediaQuery.underTablet} {
      flex-direction: column;
      justify-content: center;
      border-bottom: 1px solid ${colors.gray.$400};
      padding: 0;
      height: auto;

      .gnb__nav-top {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        height: 60px;
        padding-left: 16px;
      }
    }
  `,
}
