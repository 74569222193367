import { AxiosError } from 'axios'
import { observer } from 'mobx-react'
import React, { useEffect, useId, useRef } from 'react'
import { useParams } from 'react-router'
import { useNavigate } from 'react-router-dom'
import { Tooltip } from 'react-tooltip'

import { routeName } from '~/@common/constants'
import { useStudentAppMediaQuery } from '~/@common/hooks/useMediaQuery.ts'
import { useRepository } from '~/@common/hooks/useRepository'
import modalService from '~/@common/services/modal.service.tsx'
import { remoteStorageService } from '~/@common/services/remoteStorage.service.ts'
import { colorTheme } from '~/@common/styles'
import Button from '~/@common/ui/(Button)/Button/Button'
import { Icon } from '~/@common/ui/Icon/Icon'
import Modal from '~/@common/ui/modal/Modal'
import { StudentEduMaterialScoringDashboard } from '~/@pages/@common/StudentEduMaterialScoringDashboard'
import { WorksheetScoringCardMobile } from '~/@pages/@widgets/(Worksheet)/WorksheetScoring/(Body)/WorksheetScoringCard.mobile'
import { LearningUnavailableAlertModal } from '~/@pages/@widgets/LearningUnavailableAlertModal'
import ProblemScoringResultTable, {
  TEMP_TOOLTIP_OFF_SCROLL_CLASSNAME,
} from '~/@pages/student/learning-process/@common/ProblemScoringResultTable.tsx'
import 챌린지학습지완료모달 from '~/@pages/student/learning-process/@common/챌린지학습지완료모달.tsx'

import { LearningProcessService } from '../../../service/LearningProcess.service'
import S from './결과확인.style'

export type Result = 'CORRECT' | 'WRONG' | 'UNKNOWN' | 'NONE'

const 결과확인 = () => {
  const { studentWorksheetId } = useParams<{ studentWorksheetId: string }>()
  const navigate = useNavigate()

  const service = useRepository(LearningProcessService)
  const isVisitedSelfLearningRef = useRef(remoteStorageService.isVisitedSelfLearning)
  const hasWrongLearning = !!service.process.find((eachStep) => eachStep.step === '오답 유형학습')

  const { isMobile } = useStudentAppMediaQuery()
  const tooltipId = useId()

  useEffect(() => {
    if (isVisitedSelfLearningRef.current) return

    remoteStorageService.patch('isVisitedSelfLearning', true)
  }, [])

  useEffect(() => {
    if (studentWorksheetId) {
      service.getChallengAndUpdateProblemScoring(parseInt(studentWorksheetId))
    }
  }, [service, studentWorksheetId])

  if (
    !service.학습모듈_문제풀이.studentWorksheet ||
    !service.학습모듈_문제풀이.worksheet ||
    !service.학습모듈_문제풀이.problemScoringColl ||
    !service.학습모듈_문제풀이.problemScoringViewOption
  )
    return null

  const { problemScoringColl, studentWorksheet, worksheet, problemScoringViewOption } =
    service.학습모듈_문제풀이

  return (
    <>
      <S.Container className={TEMP_TOOLTIP_OFF_SCROLL_CLASSNAME}>
        <S.Scoring>
          <StudentEduMaterialScoringDashboard
            style={{ ...(isMobile && { minWidth: '100%' }) }}
            eduMaterialType="WORKSHEET"
            scoring={{
              ...problemScoringColl.gradingCount,
              total: problemScoringColl.toArr.length || 0,
            }}
            score={studentWorksheet.score ?? 0}
            status={studentWorksheet.status}
          />
          <Tooltip
            id={tooltipId}
            isOpen={
              !isVisitedSelfLearningRef.current &&
              studentWorksheet.score !== 100 &&
              hasWrongLearning
            }
            place="bottom-end"
            className="tooltip"
            offset={-9}
            opacity={1}
            clickable
            disableStyleInjection={true}
            style={{ display: isVisitedSelfLearningRef.current ? 'none' : 'block' }}
          >
            <div className="tooltip-content">
              <Icon name="icon_check_circle" size={26} color="#B7F08A" />
              오답 유형을 학습하고 챌린지를
              <br /> 마무리해보세요!
            </div>
          </Tooltip>
          {!isMobile && <SubmitButton />}
        </S.Scoring>
        {isMobile ? (
          <S.ScoringCard>
            <div className="scoring-card-container">
              {problemScoringColl.toArr.map((problemScoring) => (
                <WorksheetScoringCardMobile
                  key={problemScoring.problem.id}
                  problemScoring={problemScoring}
                  type={worksheet.type}
                  viewOption={problemScoringViewOption}
                  isVisibleConcept
                />
              ))}
            </div>
          </S.ScoringCard>
        ) : (
          <S.Table>
            {problemScoringColl?.toArr && (
              <ProblemScoringResultTable
                problemScoringList={problemScoringColl.toArr}
                type={worksheet.type}
              />
            )}
          </S.Table>
        )}
      </S.Container>
      {isMobile && <SubmitButton />}
    </>
  )
}

const SubmitButton = observer(() => {
  const { studentWorksheetId } = useParams<{ studentWorksheetId: string }>()
  const service = useRepository(LearningProcessService)
  const tooltipId = useId()
  const navigate = useNavigate()
  const hasWrongLearning = !!service.process.find((eachStep) => eachStep.step === '오답 유형학습')

  return (
    <S.SubmitButton>
      {service.오답유형학습_활성화_여부 ? (
        <Button theme="primary" style={{ width: '100%' }} onClick={() => service.goToNextStep()}>
          다음
        </Button>
      ) : (
        <Button
          data-tooltip-id={tooltipId}
          theme="primary"
          style={{ width: '100%' }}
          onClick={async () => {
            if (service.학습모듈_문제풀이.studentWorksheet?.score !== 100 && hasWrongLearning) {
              try {
                await service.오답_유형학습_시작하기()
              } catch (error) {
                if (error instanceof AxiosError) {
                  const NOT_FOUND_ERROR_MESSAGE = 'STUDENT_WORKSHEET_NOT_FOUND'

                  if (error.response?.data.code === NOT_FOUND_ERROR_MESSAGE) {
                    modalService.openModal(
                      <Modal.Alert
                        css={{ width: 307 }}
                        confirm={{
                          onClick: () => {
                            navigate(routeName.student.challenge)
                            modalService.closeModal()
                          },
                          style: {
                            background: colorTheme.primary,
                          },
                          children: '종료하기',
                        }}
                      >
                        <p>
                          모든 문제를 다 풀었어요.
                          <br />더 많은 문제를 준비중이니 조금만 기다려주세요.
                        </p>
                      </Modal.Alert>,
                      {
                        modalName: `error-modal__${NOT_FOUND_ERROR_MESSAGE}`,
                      },
                    )
                  }
                }
              }
            } else if (studentWorksheetId) {
              await service.챌린지학습_완료하기(+studentWorksheetId)

              if (!remoteStorageService.isCompletedSelfLearning) {
                modalService.openModal(<챌린지학습지완료모달 />, {
                  modalName: '챌린지_학습지_완료',
                })
                remoteStorageService.patch('isCompletedSelfLearning', true)
              }

              navigate(-1)
            }
          }}
        >
          {service.학습모듈_문제풀이.studentWorksheet?.score !== 100 && hasWrongLearning
            ? '오답 유형학습 시작하기'
            : '학습 종료하기'}
        </Button>
      )}
    </S.SubmitButton>
  )
})

export default observer(결과확인)
