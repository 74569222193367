import clsx from 'clsx'
import { observer } from 'mobx-react'
import React from 'react'

import { colors } from '~/@common/styles'
import type { StyleProps } from '~/@common/types'

import GroupArrow from '../GroupArrow/GroupArrow'
import S from './Dropdown.style'
import DropdownRender, {
  type DropdownCustomButtonProps,
  type DropdownRenderProps,
} from './DropdownRender'

type Props = Omit<DropdownRenderProps, 'CustomButton'> & {
  theme?: 'default' | 'rounded'
  buttonClassName?: StyleProps['className']
  width?: React.CSSProperties['width']
  height?: React.CSSProperties['height']
  listWidth?: React.CSSProperties['width']
  listHeight?: React.CSSProperties['height']
  placeholder?: string
  guide?: React.ReactNode
} & StyleProps

const {
  theme: { rounded, defaultTheme },
} = S

// 출결에서 사용되는 둥그런 드랍다운 버튼
const RoundedButton = ({
  value,
  placeholder,
  contents,
  toggleFn,
  isOpen,
  ...props
}: DropdownCustomButtonProps) => (
  <button css={rounded.button} onClick={toggleFn} data-testid="dropdown-rounded-button" {...props}>
    {value !== undefined ? (
      <strong>{contents}</strong>
    ) : (
      <span style={{ color: colors.gray.$500 }}>{placeholder}</span>
    )}
    <GroupArrow isOpen={isOpen} size={20} startDeg={180} endDeg={0} />
  </button>
)

// 통상적으로 사용되는 드랍다운 버튼
const DefaultButton = ({
  value,
  placeholder,
  contents,
  toggleFn,
  isOpen,
  className,
  ...props
}: DropdownCustomButtonProps) => (
  <button
    css={defaultTheme.button}
    onClick={toggleFn}
    data-testid="dropdown-default-button"
    {...props}
    className={clsx(isOpen && 'dropdown-open', className)}
  >
    {value !== undefined ? (
      <span>{contents}</span>
    ) : (
      <span style={{ color: colors.gray.$500 }}>{placeholder}</span>
    )}
    <GroupArrow className="group-arrow" isOpen={isOpen} size={24} startDeg={180} endDeg={0} />
  </button>
)

const Dropdown = ({
  theme = 'default',
  children,
  height = 40,
  listWidth = '100%',
  listHeight = 314,
  guide,
  className,
  style,
  ...props
}: Props) => {
  switch (theme) {
    case 'rounded':
      return (
        <DropdownRender
          className={className}
          CustomButton={RoundedButton}
          css={rounded.wrap}
          {...props}
        >
          <div css={rounded.list}>{children}</div>
        </DropdownRender>
      )
    case 'default':
    default:
      return (
        <DropdownRender
          className={className}
          CustomButton={DefaultButton}
          css={[defaultTheme.wrap]}
          style={{ height: `${height}px`, ...style }}
          {...props}
        >
          <div
            css={[defaultTheme.list]}
            className="dropdown-scroll-wrap"
            style={{
              width: listWidth,
              maxHeight: listHeight,
            }}
          >
            {guide && guide}
            {children}
          </div>
        </DropdownRender>
      )
  }
}

Dropdown.displayName = 'Dropdown'

export default observer(Dropdown)
