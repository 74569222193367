import { observer } from 'mobx-react'
import type { CSSProperties, FC, ReactElement } from 'react'
import { useCallback, useEffect, useLayoutEffect, useMemo, useState } from 'react'

import type { TransformChangeEventFunc } from '../handwrittenNote/handwrittenNote.types'
import type { PanEvent, PinchEvent } from '../hooks'
import { POINTER_TYPE, useGestureEvent } from '../hooks'
import type { Size } from '../types'
import ScalableContentService from './ScalableContent.service'
import type { FitMode } from './scalableContent.types'

const kind = `mn__ScalableContent`

interface TargetRect {
  x: number
  y: number
  width: number
  height: number
}

interface Props {
  containerEl: HTMLElement | SVGElement
  contentSize?: Size
  children: ReactElement
  isWritingMode?: boolean
  preventScale?: boolean
  fitMode?: FitMode
  containerPadding?: number
  zoomLevel: number
  noteScaleResetKey?: number
  onTransformChange?: TransformChangeEventFunc
  targetRect?: TargetRect
  allowDefaultTouchAction?: boolean
  preventResizeObserver?: boolean
  needExtraFinger?: boolean
  background?: CSSProperties['background']
  scaleCSSType?: 'transform' | 'zoom'
}

const ScalableContent: FC<Props> = ({
  containerEl,
  children,
  contentSize,
  isWritingMode,
  preventScale,
  fitMode,
  containerPadding = 0,
  zoomLevel = 1,
  noteScaleResetKey,
  onTransformChange,
  targetRect,
  allowDefaultTouchAction,
  preventResizeObserver,
  needExtraFinger,
  background,
  scaleCSSType = 'transform',
}) => {
  const [service] = useState(new ScalableContentService(fitMode, containerPadding))

  useEffect(() => {
    if (contentSize) {
      service.setContentSize(contentSize)
    }
  }, [contentSize, service])

  useEffect(() => {
    if (containerPadding !== undefined) {
      service.setContainerPadding(containerPadding)
    }
  }, [containerPadding, service])

  useEffect(() => {
    if (fitMode !== undefined) {
      service.setFitMode(fitMode)
    }
  }, [fitMode, service])

  useEffect(() => {
    service.setZoomLevel(zoomLevel)
  }, [service, zoomLevel])

  useEffect(() => {
    if (noteScaleResetKey) {
      service.resetScaleAndTranslation()
    }
  }, [service, noteScaleResetKey])

  useEffect(() => {
    if (preventResizeObserver) {
      return
    }
    const resizeObserver = new ResizeObserver((entries) => {
      for (const entry of entries) {
        window.requestAnimationFrame(() => {
          const rect = entry.target.getBoundingClientRect()
          service.setContainerSize({
            width: rect.width,
            height: rect.height,
          })
          service.setContentCenter(0)
        })
      }
    })
    resizeObserver.observe(containerEl)
    return () => {
      resizeObserver.unobserve(containerEl)
    }
  }, [containerEl, service, preventResizeObserver])

  const handlePan = useCallback(
    (e: PanEvent) => {
      if (isWritingMode && e.pointerType !== POINTER_TYPE.touch) {
        return
      }
      if (!e.detail) {
        return
      }
      service.handlePan(e.detail.deltaX, e.detail.deltaY)
    },
    [isWritingMode, service],
  )

  const handlePanEnd = useCallback(
    (e: PanEvent) => {
      if (isWritingMode && e.pointerType !== POINTER_TYPE.touch) {
        return
      }
      service.handlePanEnd()
      onTransformChange?.({ changed: service.isDefaultTranslation })
    },
    [service, isWritingMode, onTransformChange],
  )

  const handlePinchStart = useCallback(
    (e: PinchEvent) => {
      if (!e.detail) {
        return
      }
      service.handlePinchStart(e.detail.centerPoint)
    },
    [service],
  )

  const handlePinch = useCallback(
    (e: PinchEvent) => {
      if (!e.detail) {
        return
      }
      service.handlePinch(
        e.detail.scale,
        e.detail.panEventDetail.deltaX,
        e.detail.panEventDetail.deltaY,
      )
    },
    [service],
  )

  const handlePinchEnd = useCallback(() => {
    service.handlePinchEnd()
    onTransformChange?.({ changed: service.isDefaultPinchScale })
  }, [service, onTransformChange])

  const gestureEventOptions = useMemo(() => {
    return {
      minMoveDistance: 0,
      moveDelay: 0,
      needExtraFinger,
      onPan: handlePan,
      onPanEnd: handlePanEnd,
      onPinchStart: handlePinchStart,
      onPinch: handlePinch,
      onPinchEnd: handlePinchEnd,
    }
  }, [needExtraFinger, handlePan, handlePanEnd, handlePinch, handlePinchEnd, handlePinchStart])

  const { gestureRef } = useGestureEvent<HTMLDivElement>(gestureEventOptions, preventScale)

  useEffect(() => {
    const containerRect = containerEl.getBoundingClientRect()
    service.setContainerSize({
      width: containerRect.width,
      height: containerRect.height,
    })
  }, [containerEl, service])

  useLayoutEffect(() => {
    if (!service.containerSize || !service.contentSize || service.fitMode !== 'center') {
      return
    }
    service.setContentCenter(0)
  }, [service, service.containerSize, service.contentSize])

  useLayoutEffect(() => {
    if (!service.containerSize || !service.contentSize || !targetRect) {
      return
    }
    const targetRectCenter = {
      x: targetRect.x + targetRect.width / 2,
      y: targetRect.y + targetRect.height / 2,
    }
    const containerCenter = {
      x: service.containerSize.width / 2,
      y: service.containerSize.height / 2,
    }
    service.setTranslation({
      global: {
        x: containerCenter.x - targetRectCenter.x,
        y: containerCenter.y - targetRectCenter.y,
      },
    })
  }, [service, service.containerSize, service.contentSize, targetRect])

  const scale = service.contentSize
    ? service.scaledContentSize.width / service.contentSize.width
    : 1

  const contentWidth = service.contentSize?.width ?? 0
  const contentHeight = service.contentSize?.height ?? 0
  const scaleStyle =
    scaleCSSType === 'transform'
      ? { transform: `scale(${scale})`, transformOrigin: '0 0' }
      : { zoom: scale }

  return (
    <div
      data-component={kind}
      ref={gestureRef}
      style={{
        width: `${service.scaledContentSize.width}px`,
        height: `${service.scaledContentSize.height}px`,
        transform: service.transformString,
        touchAction: allowDefaultTouchAction ? 'auto' : 'none',
        transformOrigin: '0 0',
        overflow: 'hidden',
        background: background ?? '#fff',
        transition: `transform ${service.transitionDelay}ms`,
        // willChange: 'transform',
      }}
      onTransitionEnd={() => service.setTransitionDelay(0)}
    >
      <div
        style={{
          ...scaleStyle,
          overflow: 'hidden',
          width: `${contentWidth}px`,
          height: `${contentHeight}px`,
          // willChange: 'transform',
        }}
      >
        {children}
      </div>
    </div>
  )
}

export default observer(ScalableContent)
