import { observer } from 'mobx-react'
import { type FC, useEffect } from 'react'
import { generatePath, useNavigate, useParams } from 'react-router'

import { routeName } from '~/@common/constants'

// Conc
const ConceptLearningPage: FC = () => {
  const { studentWorksheetId } = useParams<{ studentWorksheetId: string }>()
  const navigate = useNavigate()

  useEffect(() => {
    if (!studentWorksheetId) {
      navigate(routeName.student.defaultPath)
      return
    }
    const newPath = generatePath(routeName.student.conceptualLearning, {
      studentWorksheetId,
    })
    navigate(newPath, { replace: true })
  })

  return <></>
}

export default observer(ConceptLearningPage)
