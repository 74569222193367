import type { MathflatApi } from '@mathflat/domain/@entities/Login/api'

import maxios from '../utils/maxios'

export const authApi = {
  login: (payload: MathflatApi.Request.Login) => {
    return maxios.post<MathflatApi.Response.Login>('/login', payload)
  },

  reissue: () => {
    return maxios.post<MathflatApi.Response.Login>('/reissue')
  },

  passwordChange: (payload: MathflatApi.Request.Password) => {
    return maxios.post('/password', payload)
  },

  logout: () => {
    return maxios.post('/logout')
  },
}
