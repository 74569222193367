const unitRegex = /(\[(.+)])/

export const getRefinedUnit = (unit, includeBracket) => {
  const match = unit.match(unitRegex)

  if (match?.length < 1) {
    return ''
  }

  return (includeBracket ? match[1] : match[2]) || ''
}

export const keypadConfig = ({ answerUnits, keypadTypes }) => {
  const prefixUnit = (() => {
    const unit = answerUnits?.find((item) => item.index === 0)?.unit
    if (!unit) return ''
    return getRefinedUnit(unit, false)
  })()
  const postfixUnit = (() => {
    const unit = answerUnits?.find((item) => item.index > 0)?.unit
    if (!unit) return ''
    return getRefinedUnit(unit, false)
  })()

  const newKeypadConfig = {
    prefixUnit,
    postfixUnit,
    keypadType: keypadTypes?.some((item) => item === 'MIXED_FRACTION')
      ? 'MIXED_FRACTION'
      : keypadTypes?.some((item) => item === 'FRACTION')
        ? 'FRACTION'
        : 'DECIMAL',
    autoScoredType: 'POSSIBLE_UNIT',
  }

  return newKeypadConfig
}

export const preRefineAnswer = (katexString: string) => {
  const refined = katexString?.replaceAll('UNKNOWN', '')
  return refined
}

// 입력 완료 후 노출값 정제
export const postRefineAnswer = (katexString: string) => {
  if (!katexString) {
    return ''
  }
  const refined = katexString
    ?.replaceAll('\\placeholder{}', '')
    .replaceAll('\\frac{}{}', '')
    .replaceAll('UNKNOWN', '')
  return refined
}
