import { css } from '@emotion/react'
import { Slot } from '@radix-ui/react-slot'
import clsx from 'clsx'
import type { ComponentPropsWithoutRef, CSSProperties, MouseEvent } from 'react'
import React from 'react'

import { colors } from '~/@common/styles'
import { Icon, type Props as IconProps } from '~/@common/ui/Icon/Icon.tsx'

export const kind = `ms__IconButton` as const
export const parts = {
  icon: `${kind}_icon`,
} as const

export type Props = {
  theme?: keyof typeof theme
  size?: CSSProperties['minWidth']
  gap?: CSSProperties['gap']
  asChild?: boolean
  iconSize?: IconProps['size']
  iconStyle?: IconProps['style']
  name: IconProps['name']
  rotate?: React.ComponentProps<typeof Icon>['rotate']
  LeftSlot?: React.ReactNode
  RightSlot?: React.ReactNode
  onIconClick?: (e: MouseEvent<SVGSVGElement>) => void
} & ComponentPropsWithoutRef<'button'>

const IconButton = React.forwardRef<React.ElementRef<'button'>, Props>(
  (
    {
      theme = attr.theme.none,
      asChild = false,
      name,
      style,
      iconSize,
      gap = '6px',
      size = iconSize ? iconSize : '48px',
      className,
      children,
      rotate,
      LeftSlot = children,
      RightSlot,
      iconStyle,
      onIconClick,
      ...props
    },
    ref,
  ) => {
    const Comp = asChild ? Slot : 'button'
    return (
      <Comp
        data-component={kind}
        css={_css}
        className={clsx(kind, className)}
        ref={ref}
        style={{
          ...style,
          minWidth: style?.minWidth ?? size,
          minHeight: style?.minHeight ?? size,
          gap,
        }}
        data-size={size}
        data-theme={theme}
        data-track={name}
        {...props}
      >
        {LeftSlot}
        <Icon
          className={parts.icon}
          name={name}
          size={iconSize}
          rotate={rotate}
          style={iconStyle}
          onClick={onIconClick}
        />
        {RightSlot}
      </Comp>
    )
  },
)
IconButton.displayName = kind

export const attr = {
  size: {
    small: 'small',
    large: 'large',
  },
  theme: {
    gray: 'gray',
    white: 'white',
    none: 'none',
  },
} as const

const { size, theme } = attr

const _css = css`
  display: flex;
  justify-content: center;
  align-items: center;

  transition: 200ms;

  svg path {
    fill: ${colors.gray.$700};
  }

  &:disabled {
    opacity: 0.3;

    svg path {
      fill: ${colors.gray.$200};
    }

    box-shadow: none;
  }

  /* theme */

  :not(&[data-theme=${theme.none}]) {
    border-radius: 12px;

    // TODO: 네이밍 지정되면 변경
    box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.05);
  }

  &[data-theme='${theme.gray}'],
  &[data-theme='${theme.white}'] {
    border-radius: 12px;
  }

  &[data-theme='${theme.gray}'] {
    background-color: ${colors.gray.$200};
  }

  &[data-theme='${theme.white}'] {
    background-color: ${colors.white};
  }
`

export default IconButton
