import styled from '@emotion/styled'
import { CONTENT_STATUS } from '@mathflat/domain/@entities/(Content)/module'
import { observer } from 'mobx-react'
import { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router'
import { useSearchParams } from 'react-router-dom'

import { HandwrittenNoteType } from '~/@common/api/handwrittenNoteApi'
import { routeName } from '~/@common/constants'
import { TOAST_STRING } from '~/@common/constants/strings'
import { useCallbackOnVisibilityChange } from '~/@common/hooks/useCallbackOnVisibilityChange'
import { useLearningTime } from '~/@common/hooks/useLearningTime'
import { useStudentAppMediaQuery } from '~/@common/hooks/useMediaQuery'
import { useRepository } from '~/@common/hooks/useRepository'
import { errorHandlerService, WorksheetProblemNoteService } from '~/@common/services'
import { learningContentLastScreenService } from '~/@common/services/learningContentLastScreen/LearningContentLastScreen.service'
import modalService from '~/@common/services/modal.service'
import { colors, typo } from '~/@common/styles'
import { mediaQuery } from '~/@common/styles/mediaQuery'
import WorksheetScoringByOne from '~/@pages/@widgets/(Worksheet)/WorksheetScoring/WorksheetScoringByOne'
import WorksheetScoringByOneMobile from '~/@pages/@widgets/(Worksheet)/WorksheetScoring/WorksheetScoringByOne.mobile'
import { ScoringSubmitConfirmModal } from '~/@pages/student/@widgets/StudentWorksheetScoring/ScoringSubmitConfirmModal'
import { useLearningProcessReference } from '~/@pages/student/learning-process/@common/hooks/useGetLearningProcessReference'
import { WorksheetQuickScoring } from '~/@pages/worksheet/$worksheetId/$dateTime/scoring/@widgets/WorksheetQuickScoring'

import { StudentExamService } from './@service/StudentExamPage.service'
import MAATAlreadyCompleteModal from './@widgets/MAATAlreadyCompleteModal'
import { StudentExamPageHeader } from './@widgets/StudentExamPageHeader'

const StudentExamByIdScoringPage = observer(() => {
  useLearningTime()
  const studentExamService = useRepository(StudentExamService)
  const { studentExamId } = useParams<{ studentExamId: string }>()
  const [searchParams, setSearchParams] = useSearchParams()
  const { isMobile } = useStudentAppMediaQuery()

  const navigate = useNavigate()

  const noteService = useRepository(WorksheetProblemNoteService)
  const referenceParam = useLearningProcessReference()

  const scoringSize = searchParams.get('size') as 'all' | 'one'

  const checkIsAllScoredInMAAT = () => {
    if (
      studentExamService.problemScoringViewOption?.content.type === 'MAAT' &&
      studentExamService.studentExamWorksheet?.status === CONTENT_STATUS.전체채점
    ) {
      modalService.openModal(
        <MAATAlreadyCompleteModal
          onConfirm={() => {
            navigate(routeName.student.defaultPath)
          }}
        />,
        {
          modalName: 'MAAT_timeUpModal',
        },
      )
    }
  }

  const confirmToSubmitAnswers = async () => {
    modalService.openModal(
      <ScoringSubmitConfirmModal
        content={
          studentExamService.problemScoringViewOption?.content.type === 'MAAT' ? (
            <>
              시험을 제출하시면
              <br />
              다시 수정하거나 변경할 수 없습니다.
              <br />
              그래도 제출하시겠습니까?
              <br />
              <br />
              결과지와 보고서는 <br />
              8월 2일 이후 선생님께 요청하세요.
              <br />
            </>
          ) : undefined
        }
        onClick={async () => {
          if (!studentExamId) {
            return
          }
          try {
            modalService.showLoader()
            modalService.closeModal()

            const examProblemIds = studentExamService.problemScoringColl?.toScoredArr.map(
              (item) => item.id,
            )

            try {
              await noteService.uploadNotes(
                Number(studentExamId),
                examProblemIds,
                HandwrittenNoteType.STUDENT_EXAM_SCORING,
              )
              window.freshpaint?.track('필기 제출', { ...referenceParam })
            } catch (err) {
              errorHandlerService.handle(err, {
                message: TOAST_STRING.saveHandwrittenNoteFailed,
                useRemoteLogging: true,
              })
            }

            await studentExamService.submitAutoScoringExamProblems(studentExamId)

            if (studentExamService.problemScoringViewOption?.content.type === 'MAAT') {
              window.location.replace(routeName.student.defaultPath)
            }
          } catch (error) {
            errorHandlerService.handle(error)
          } finally {
            modalService.hideLoader()
          }
        }}
      />,
      {
        modalName: 'ScoringSubmitConfirmModal',
      },
    )
  }

  useCallbackOnVisibilityChange(checkIsAllScoredInMAAT)
  useEffect(() => {
    checkIsAllScoredInMAAT()
  }, [])

  useEffect(() => {
    if (!studentExamId) {
      return
    }
    ;(async () => {
      try {
        await studentExamService.loadStudentExamScoring(studentExamId)
      } catch (error) {
        errorHandlerService.handle(error)
      }
    })()
  }, [studentExamId])

  useEffect(() => {
    if (!scoringSize) {
      setSearchParams(
        (prevParams) => {
          if (!prevParams.has('size')) {
            prevParams.append('size', 'all')
          }
          return prevParams
        },
        {
          replace: true,
        },
      )
    }
  }, [scoringSize])

  if (!studentExamId) return null
  if (
    studentExamService.problemScoringViewOption?.content.type === 'MAAT' &&
    studentExamService.studentExamWorksheet?.status === CONTENT_STATUS.전체채점
  )
    return null

  const lastScreenProblemIndex = learningContentLastScreenService.getLastScreenProblemIndex([
    'STUDENT_EXAM',
    +studentExamId,
  ])

  const updateLastScreenProblemIndex = (currentProblemIndex: number) => {
    if (studentExamId) {
      learningContentLastScreenService.setLastScreenProblemIndex(
        ['STUDENT_EXAM', +studentExamId],
        currentProblemIndex,
      )
    }
  }

  return (
    <S.StudentWorksheetScoringPage>
      <StudentExamPageHeader />
      {studentExamService.exam &&
        studentExamService.problemScoringColl &&
        studentExamService.problemScoringViewOption && (
          <>
            {scoringSize === 'all' ? (
              <WorksheetQuickScoring
                problemScoringColl={studentExamService.problemScoringColl}
                problemScoringViewOption={studentExamService.problemScoringViewOption}
                gradingCount={studentExamService.problemScoringColl.gradingCount}
                clearAllAnswer={studentExamService.problemScoringColl.onAllClear}
                openConfirmToSubmitAnswersModal={confirmToSubmitAnswers}
              />
            ) : (
              <S.ScoringByOneWrapper>
                {isMobile ? (
                  <WorksheetScoringByOneMobile
                    problemScoringColl={studentExamService.problemScoringColl}
                    viewOption={studentExamService.problemScoringViewOption}
                    onSubmit={confirmToSubmitAnswers}
                    studentWorksheet={studentExamService.studentExamWorksheet}
                    type={studentExamService.exam.type}
                    problemIndex={lastScreenProblemIndex}
                    onProblemIndexChange={updateLastScreenProblemIndex}
                  />
                ) : (
                  <WorksheetScoringByOne
                    problemScoringColl={studentExamService.problemScoringColl}
                    viewOption={studentExamService.problemScoringViewOption}
                    onSubmit={confirmToSubmitAnswers}
                    studentWorksheet={studentExamService.studentExamWorksheet}
                    worksheet={studentExamService.exam}
                    problemIndex={lastScreenProblemIndex}
                    onProblemIndexChange={updateLastScreenProblemIndex}
                  />
                )}
              </S.ScoringByOneWrapper>
            )}
          </>
        )}
    </S.StudentWorksheetScoringPage>
  )
})

export default StudentExamByIdScoringPage

const S = {
  ScoringByOneWrapper: styled.section`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;

    ${mediaQuery.underTablet} {
      padding: 10px 16px 40px;
    }
  `,

  StudentWorksheetScoringPage: styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
  `,
  StudentWorksheetScoringEmptyFooter: styled.div`
    flex: 0 0 48px;
  `,
  RightLink: styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 2px;
    ${typo.caption01};
    color: ${colors.gray.$900};
    font-weight: bold;
    cursor: pointer;

    ${mediaQuery.underDesktop} {
      ${typo.body02};
      font-weight: normal;
      color: ${colors.gray.$600};
      svg {
        color: ${colors.gray.$600};
      }
    }
  `,
}
