import { css } from '@emotion/react'
import { observer } from 'mobx-react'
import type { FC, ReactNode } from 'react'

import { colors, fontWeight, typo } from '~/@common/styles'
import { mediaQuery } from '~/@common/styles/mediaQuery'

interface Props {
  title?: string
  className?: string
  children?: ReactNode
}

const SectionHeader: FC<Props> = ({ title, children, className }) => {
  return (
    <div css={_Style} className={className}>
      {title && <h3 className="heading">{title}</h3>}
      {children}
    </div>
  )
}

export default observer(SectionHeader)

const _Style = css`
  display: flex;
  align-items: center;
  gap: 6px;
  height: 26px;
  margin-bottom: 12px;

  .heading {
    ${typo.heading05};
    white-space: nowrap;
    color: ${colors.gray.$900};
    font-weight: ${fontWeight.bold};
  }

  ${mediaQuery.underTablet} {
    .heading {
      ${typo.body01};
    }
  }
`
