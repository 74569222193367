import type { ValueOf } from '@mathflat/shared/@types/utilityTypes'
import type { MathfieldElement } from 'mathlive'
import { makeAutoObservable } from 'mobx'
import type { PlacesType } from 'react-tooltip'

import type { ProblemScoring } from '~/@pages/@common/(ProblemScoring)/@trait/ProblemScoring.trait'

import { LATEX_NODE_TYPE } from './_constants'
import type { KeypadType } from './hook'

export class VirtualKeyboardService {
  show = false
  mathFieldEl: MathfieldElement | null = null
  type: KeypadType = '소수키패드'
  showType: 'drawer' | 'floater' | 'fixed' = 'floater'
  fixedPlace: PlacesType = 'top'
  activeProblemScoringId?: string

  constructor() {
    makeAutoObservable(this)
  }

  get id() {
    return this.mathFieldEl?.dataset.virtualKeyboardId
  }

  handleClose = (e) => {
    if (e.target.dataset.virtualKeyboard === 'true') return
    if (this.mathFieldEl?.dataset?.virtualKeyboardId) {
      this.close()
    }
  }

  clearEventListener = () => {
    document.removeEventListener('click', this.handleClose)
  }

  register(el: MathfieldElement) {
    this.unRegister()

    // 키패드를 뜨게 하는 클릭이 잔상(?)처럼 남아서 viewport 하단에 위치한 mathfield input을 누를 경우 키패드 4,5버튼 위치에 있는 버튼이 뜨자마자 눌려버림
    // 키패드 뜨고 일정시간 후 키패드 입력이 불가능하도록 함
    return new Promise((resolve) => {
      return setTimeout(() => {
        this.mathFieldEl = el
        document.addEventListener('click', this.handleClose)
        resolve(this.mathFieldEl)
      }, 0)
    })
  }

  unRegister() {
    this.mathFieldEl?.blur()
    this.mathFieldEl = null
    this.clearEventListener()
  }

  async open({
    el,
    keypadTypes,
    showType,
    opt,
    problemScoringId,
  }:
    | {
        el: MathfieldElement
        keypadTypes: ProblemScoring<'WORKSHEET' | 'WORKBOOK'>['keypadTypes']
        showType: 'drawer' | 'floater'
        opt?: undefined
        problemScoringId?: string
      }
    | {
        el: MathfieldElement
        keypadTypes: ProblemScoring<'WORKSHEET' | 'WORKBOOK'>['keypadTypes']
        showType: 'fixed'
        opt?: { place: PlacesType }
        problemScoringId?: string
      }) {
    this.type = keypadTypes?.some((item) => item === 'MIXED_FRACTION')
      ? '통합키패드'
      : keypadTypes?.some((item) => item === 'FRACTION')
        ? '초등학생용키패드'
        : '소수키패드'

    this.activeProblemScoringId = problemScoringId
    this.showType = showType
    this.show = true
    if (opt) {
      this.fixedPlace = opt.place
    }

    await this.register(el)

    setTimeout(() => {
      el?.focus()
      el?.executeCommand('selectAll')
      el.scrollIntoView({ behavior: 'smooth', block: 'center' })
    }, 0)
  }

  close() {
    this.show = false
    this.activeProblemScoringId = undefined
    this.unRegister()
  }

  onKeyButtonClick(
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    latexNodeType: ValueOf<typeof LATEX_NODE_TYPE>,
  ) {
    if (!this.mathFieldEl) return

    switch (latexNodeType) {
      case LATEX_NODE_TYPE.숫자:
        this.mathFieldEl.insert(`${e.currentTarget.value}`, { focus: true })
        break
      case LATEX_NODE_TYPE.분수:
        this.mathFieldEl.insert(`\\frac`, {
          focus: true,
        })
        break
      case LATEX_NODE_TYPE.대분수:
        this.mathFieldEl.insert(`\\frac`, {
          focus: true,
        })
        this.mathFieldEl.executeCommand('moveToPreviousChar')
        break
      case LATEX_NODE_TYPE.LEFT_ARROW:
        this.mathFieldEl.executeCommand('moveToPreviousWord')
        this.mathFieldEl.focus()
        break
      case LATEX_NODE_TYPE.RIGHT_ARROW:
        this.mathFieldEl.executeCommand('moveToNextWord')
        this.mathFieldEl.focus()
        break
      case LATEX_NODE_TYPE.삭제:
        this.mathFieldEl.executeCommand('deleteBackward')
        this.mathFieldEl.focus()
        break
    }
  }
}

export const virtualKeyboardService = new VirtualKeyboardService()

// class Mathfield {
//   private _el: MathfieldElement | null = null
//   private _value: string = ''

//   constructor({
//     mathFieldEl,
//     defaultValue,
//   }: {
//     mathFieldEl: MathfieldElement
//     defaultValue: string
//   }) {
//     makeAutoObservable(this)

//     this._el = mathFieldEl

//     // math-field element를 module에 등록하고, 구체적인 로직은 module에서 처리합니다.
//     const shadowChild = mathFieldEl.shadowRoot?.querySelector('div')
//     if (shadowChild) {
//       // mathlive 기본 스타일링 shadowRoot > div의 display 값을 바꿔야해서. (css로 안되었음)
//       shadowChild.style.display = 'flex'
//     }
//     mathFieldEl.setOptions({
//       defaultMode: 'math',
//       fontsDirectory: `${import.meta.env.VITE_VERCEL_ASSET_SERVER}/font`,
//       keypressSound: null,
//       plonkSound: null,
//       virtualKeyboardMode: 'off',
//       placeholderSymbol: '■',
//       fractionNavigationOrder: 'denominator-numerator', // 커서 분모-분자 순서로 이동하는 옵션
//       // mathlive 내 스타일은 이렇게밖에 overwrite이 안 됨.
//       createHTML: (html) => `<style>
//         .ML__mathlive, .ML__cmr, ML__base{font-family: 'Spoqa Han Sans Neo', 'Sans-serif' !important; font-weight:400; }
//         .mfrac{font-size:10px;}</style>${html}`,
//     })

//     this.value = defaultValue
//   }

//   set value(v: string) {
//     if (this._el?.getValue() === '') {
//       if (v === ANSWER_TYPE.UNKNOWN) {
//         this._el?.setValue('')
//       } else {
//         this._el?.setValue(v)
//       }
//     } else if (v === '') {
//       this._el?.setValue('')
//     }
//   }

//   get value() {
//     return postRefineAnswer(this._value)
//   }
// }

// // 입력 완료 후 노출값 정제
// export const postRefineAnswer = (katexString: string) => {
//   if (!katexString) {
//     return ''
//   }
//   const refined = katexString?.replaceAll('\\placeholder{}', '').replaceAll('\\frac{}{}', '')
//   return refined
// }
