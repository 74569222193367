import { css } from '@emotion/react'
import type { FC } from 'react'

import { useLoadMoreList } from '~/@common/hooks/useLoadMoreList'
import { colors, typo } from '~/@common/styles'
import { Icon } from '~/@common/ui/Icon/Icon'

import type { Lecture } from './LectureVideoItem'
import LectureVideoItem from './LectureVideoItem'

const DEFAULT_LIST_COUNT = 4

interface Props {
  items: Lecture[]
  selectedItem?: Lecture
  isMobile: boolean
  onLectureClick: (item: Lecture) => void
}

const LectureList: FC<Props> = ({ items, selectedItem, isMobile, onLectureClick }) => {
  const moreList = useLoadMoreList<Lecture>({
    list: items,
    defaultListCount: DEFAULT_LIST_COUNT,
    countPerPage: 10,
  })

  const lectures = isMobile ? moreList.pagingList : items

  return (
    <div css={_css}>
      {lectures.map((item) => (
        <LectureVideoItem
          key={item.id}
          item={item}
          isSelected={item.id === selectedItem?.id}
          onLectureClick={onLectureClick}
        />
      ))}
      {isMobile && moreList.enableMore && (
        <button
          className="pagination-button"
          onClick={() => (moreList.isLastPage ? moreList.onResetList() : moreList.onClickMore())}
        >
          {moreList.isLastPage ? '접기' : '더보기'}
          <Icon name="icon_chevron_down" size={20} rotate={moreList.isLastPage ? 180 : 0} />
        </button>
      )}
    </div>
  )
}

export default LectureList

const _css = css`
  .pagination-button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 8px 24px;
    color: ${colors.gray.$600};
    ${typo.caption01};
    line-height: 20px;

    svg {
      transition: transform 0.3s;
    }
  }
`
