import { makeAutoObservable } from 'mobx'

type LearningContentType = 'STUDENT_EXAM' | 'STUDENT_WORKSHEET' | 'STUDENT_WORKBOOK'

type LastScreenKeys = [LearningContentType, number]

class LearningContentLastScreenService {
  private learningContentsLastScreen = new Map<string, number>()

  constructor() {
    makeAutoObservable<this, 'learningContentsLastScreen'>(this)
  }

  getLastScreenProblemIndex(lastScreenKeys: LastScreenKeys): number | undefined {
    const key = this.createKey(lastScreenKeys)
    return this.learningContentsLastScreen.get(key)
  }

  setLastScreenProblemIndex(lastScreenKeys: LastScreenKeys, problemIndex: number): void {
    const key = this.createKey(lastScreenKeys)
    this.learningContentsLastScreen.set(key, problemIndex)
  }

  hasLastScreenProblemIndex(lastScreenKeys: LastScreenKeys): boolean {
    const key = this.createKey(lastScreenKeys)
    return this.learningContentsLastScreen.has(key)
  }

  toArray() {
    return [...this.learningContentsLastScreen.entries()]
  }

  private createKey(lastScreenKeys: LastScreenKeys) {
    return lastScreenKeys.join('__')
  }
}

export const learningContentLastScreenService = new LearningContentLastScreenService()
