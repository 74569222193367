import { css } from '@emotion/react'
import { s3URL } from '@mathflat/shared/@common/utils/s3'

import { colors, typo } from '~/@common/styles'
import { mediaQuery } from '~/@common/styles/mediaQuery'
import { Icon } from '~/@common/ui/Icon/Icon'

export const CautionPopupContent = () => {
  return (
    <div
      css={css`
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 28px;
        padding: 40px 80px;
        ${mediaQuery.underTablet} {
          padding: 24px 20px 40px;
        }
      `}
    >
      <div
        css={css`
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          gap: 40px;
          width: 100%;
        `}
      >
        <div css={headerStyles}>
          <Icon name="icon_balloon_x" color={colors.green.$100} size={30} />
          <div css={descriptionsStyles}>
            <p className="title">오답학습 ?</p>
            <p>틀린문제의 유형을 틀리지 않을 때까지 반복해서 공부할 수 있는 학습이에요.</p>
            <p className="description description__green">
              틀린 유형의 문제를 모두 맞으면 추가학습을 생성할 수 없습니다.
            </p>
          </div>
        </div>
        <img
          src={s3URL.student('images/student-worksheet__popup-wrong.svg')}
          alt="오답학습__desktop"
          width={440}
          css={desktopDisplayStyles}
        />
        <img
          src={s3URL.student('images/mobile__student-worksheet__popup-wrong.png')}
          alt="오답학습__mobile"
          width={440}
          css={[mobileDisplayStyles]}
        />
        <div css={dividerStyles} />

        <div css={headerStyles}>
          <Icon name="icon_graph" color={colors.blue.$500} size={30} />
          <div css={descriptionsStyles}>
            <p className="title">심화학습 ?</p>
            <p>
              정답문제마다 한 단계씩 높은 난이도의 문제를 활용해서
              <br />
              나의 수준을 올릴 수 있는 학습이에요.
            </p>
            <p className="description description__blue">
              한 단계씩 높은 난이도의 문제를 모두 맞으면 추가학습을 완료할 수 있어요!
            </p>
          </div>
        </div>
        <div css={dividerStyles} />
        <img
          src={s3URL.student('images/student-worksheet__popup-level-up.svg')}
          alt="심화학습__desktop"
          width={440}
          css={desktopDisplayStyles}
        />
        <img
          src={s3URL.student('images/mobile__student-worksheet__popup-level-up.png')}
          alt="심화학습__mobile"
          width={440}
          css={mobileDisplayStyles}
        />
        <div css={dividerStyles} />
        <div css={headerStyles}>
          <Icon name="icon_info_fill" color={colors.gray.$800} size={30} />
          <p>
            오답학습과 심화학습은 동시에 진행할 수 있지만,
            <br />
            하나의 학습은 끝나기 전까지 새로운 학습을 생성할 수 없어요!
          </p>
        </div>
      </div>
    </div>
  )
}

const mobileDisplayStyles = css`
  display: none;
  ${mediaQuery.underTablet} {
    display: block;
    width: 360px;
    height: auto;
  }
  @media (max-width: 400px) {
    max-width: 100%;
    height: auto;
  }
`

const desktopDisplayStyles = css`
  ${mediaQuery.underTablet} {
    display: none;
  }
`

const headerStyles = css`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  text-align: center;
`

const descriptionsStyles = css`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 4px;
  color: #000;
  .title {
    ${typo.body01}
    font-weight: 700;
  }
  .description {
    ${typo.caption01}
    font-weight: 700;
  }
  .description__green {
    color: ${colors.green.$100};
  }
  .description__blue {
    color: ${colors.blue.$500};
  }
`

const dividerStyles = css`
  width: 500px;
  height: 1px;
  background-color: ${colors.gray.$200};
`
