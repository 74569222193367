import { observer } from 'mobx-react'
import type { ComponentPropsWithRef, FC } from 'react'

import { useStudentAppMediaQuery } from '~/@common/hooks/useMediaQuery'
import { colors } from '~/@common/styles'
import IconButton from '~/@common/ui/(Button)/IconButton/IconButton'

export type FilterType = 'concept-chip' | 'workbook'

type IconButtonProps = ComponentPropsWithRef<typeof IconButton>

interface Props {
  type: FilterType
  hasItems?: boolean
  isActive?: boolean
  LeftSlot?: IconButtonProps['LeftSlot']
  onClick?: IconButtonProps['onClick']
  onIconClick?: IconButtonProps['onIconClick']
}

const ChallengeFilterButton: FC<Props> = ({
  type,
  hasItems,
  isActive,
  LeftSlot,
  onClick,
  onIconClick,
}) => {
  const { isMobile } = useStudentAppMediaQuery()
  const buttonStyle = {
    height: '40px',
    minHeight: 0,
    borderRadius: '100px',
    color: hasItems ? colors.gray.$900 : undefined,
    fontSize: '14px',
    fontWeight: 'normal',
    padding: '0 16px',
    border: '1px solid transparent',
    borderColor: hasItems ? colors.blue.$500 : 'transparent',
  }

  const mobileButtonStyle = {
    height: '38px',
    width: '38px',
    minWidth: 0,
    minHeight: 0,
    borderRadius: '12px',
    color: hasItems ? colors.blue.$500 : colors.gray.$500,
  }

  const iconStyle = {
    color: hasItems ? colors.blue.$500 : colors.gray.$500,
  }

  const getIconName = () => {
    if (!isMobile) {
      return hasItems ? 'icon_close' : 'icon_chevron_down'
    }
    switch (type) {
      case 'concept-chip':
        return 'icon_smile'
      case 'workbook':
        return 'icon_book_opened'
    }
  }

  return (
    <IconButton
      type="button"
      theme="white"
      style={isMobile ? mobileButtonStyle : buttonStyle}
      name={getIconName()}
      LeftSlot={!isMobile ? LeftSlot : undefined}
      rotate={isActive && !isMobile ? 180 : 0}
      iconStyle={!isMobile ? iconStyle : undefined}
      onClick={onClick}
      onIconClick={!isMobile ? onIconClick : undefined}
    />
  )
}

export default observer(ChallengeFilterButton)
