import clsx from 'clsx'

import FireCrackerIcon from '/__external__/icons/icon_firecracker.svg'
import { colors } from '~/@common/styles'
import type { StudentEduMaterialStatus, StyleProps } from '~/@common/types'

import { S } from '.'

export type Props = {
  status: StudentEduMaterialStatus
  eduMaterialType: 'WORKSHEET' | 'WORKBOOK' | 'MAAT' | 'CONCEPTUAL'
  score?: number | null
  showScore?: boolean
  scoring: {
    total: number
    correct: number
    incorrect: number
    unknown: number
    none: number
  }
} & StyleProps

// TODO: 혜은) ProblemScoring 받게 해서 구현. 위치도 그 디렉토리 안으로
export const StudentEduMaterialScoringDashboard = ({
  status = 'INCOMPLETE',
  eduMaterialType,
  score = 0,
  showScore = true,
  scoring,

  ...props
}: Props) => {
  const isConceptualLearning = eduMaterialType === 'CONCEPTUAL'

  return (
    <S.Card {...props} className={clsx(isConceptualLearning && 'card-conceptual-learning')}>
      <div className="card__top">
        {!isConceptualLearning && (
          <p>
            {eduMaterialType === 'MAAT'
              ? 'MAAT 경시대회 채점 결과'
              : status === 'COMPLETE'
                ? `${eduMaterialType === 'WORKSHEET' ? '학습지' : '교재'} 풀이결과`
                : '나머지 문제도 풀어보세요!'}
          </p>
        )}
        {eduMaterialType === 'MAAT' ? (
          <h2>
            <span className="top__font-blue">{scoring.correct}문제</span> 맞혔어요
          </h2>
        ) : status === 'COMPLETE' ? (
          <h2
            style={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <div>
              총 {scoring.total}문제 중<span className="top__font-blue">{scoring.correct}문제</span>
              맞혔어요!
            </div>
            <img src={FireCrackerIcon} width={28} height={28} />
          </h2>
        ) : (
          <h2>
            지금까지 <span className="top__font-blue">{scoring.correct}문제</span> 맞혔어요
          </h2>
        )}
      </div>
      {showScore && (
        <div className={clsx('card__middle', status === 'COMPLETE' && 'active-blue')}>
          <p className="middle__title">총점</p>
          <strong className="middle__totalScore">{score}점</strong>
        </div>
      )}
      <div className="card__bottom">
        <S.Brick>
          <p className="title">틀린 문제</p>
          <strong
            className="value"
            style={{
              color: colors.red.$300,
            }}
          >
            {scoring.incorrect + scoring.unknown}
          </strong>
        </S.Brick>
        <S.Brick>
          <p className="title">맞은 문제</p>
          <strong className="value">{scoring.correct}</strong>
        </S.Brick>
        {status !== 'COMPLETE' && (
          <S.Brick>
            <p className="title">안 푼 문제</p>
            <strong className="value">{scoring.none}</strong>
          </S.Brick>
        )}
      </div>
    </S.Card>
  )
}
