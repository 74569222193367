import { s3URL } from '@mathflat/shared/@common/utils/s3'
import clsx from 'clsx'
import { m } from 'framer-motion'
import { observer } from 'mobx-react'
import { NavLink } from 'react-router-dom'

import { routeName } from '~/@common/constants'
import { useStudentAppMediaQuery } from '~/@common/hooks/useMediaQuery'
import modalService from '~/@common/services/modal.service'
import { commonRepo } from '~/@common/services/repo.service'
import { colors } from '~/@common/styles'
import { Icon } from '~/@common/ui/Icon/Icon'

import { S } from '.'
import { GuideVideoButton } from './GuideVideoButton'
import OpinionModal from './OpinionModal'
import { QRScanButton } from './QRScanButton'

export const GNB = observer(() => {
  const { isMobile } = useStudentAppMediaQuery()

  return (
    <S.Header>
      {isMobile ? (
        <>
          <nav className={clsx('gnb__nav-top')}>
            <NavLink to={routeName.student.defaultPath} className="gnb__nav-logo">
              <img src={commonRepo.academyLogoUrl} alt="학원 로고" height={26} />
            </NavLink>
            <QRScanButton
              contentsStyle={{
                padding: '16px',
              }}
              qrButton={(isAppUpdated) => (
                <div className="qr__button-wrapper">
                  <div className="qr__button">
                    <Icon name="icon_qr_scan" color={colors.gray.$600} size={26} />
                  </div>
                  {isAppUpdated && <div className="qr__icon-wrapper" />}
                </div>
              )}
            />
          </nav>
          <nav className={clsx('gnb__nav', 'gnb__nav-bottom')}>
            <NavLink
              to={routeName.student.defaultPath}
              className={({ isActive }) => clsx('gnb__nav-link-mobile', isActive && 'active')}
              end
            >
              {({ isActive }) => (
                <div className="title-container">
                  학습 홈
                  {isActive && (
                    <m.div
                      className="underline"
                      layoutId="underline"
                      transition={{ layout: { duration: 0.2 } }}
                    />
                  )}
                </div>
              )}
            </NavLink>
            <NavLink
              to={routeName.student.challenge}
              className={({ isActive }) => clsx('gnb__nav-link-mobile', isActive && 'active')}
            >
              {({ isActive }) => (
                <div className="title-container">
                  챌린지
                  {isActive && (
                    <m.div
                      className="underline"
                      layoutId="underline"
                      transition={{ layout: { duration: 0.2 } }}
                    />
                  )}
                </div>
              )}
            </NavLink>
            <NavLink
              to={routeName.student.studentWorkbook}
              className={({ isActive }) => clsx('gnb__nav-link-mobile', isActive && 'active')}
            >
              {({ isActive }) => (
                <div className="title-container">
                  교재
                  {isActive && (
                    <m.div
                      className="underline"
                      layoutId="underline"
                      transition={{ layout: { duration: 0.2 } }}
                    />
                  )}
                </div>
              )}
            </NavLink>
            <NavLink
              to="/student/student-worksheet"
              className={({ isActive }) => clsx('gnb__nav-link-mobile', isActive && 'active')}
            >
              {({ isActive }) => (
                <div className="title-container">
                  학습지
                  {isActive && (
                    <m.div
                      className="underline"
                      layoutId="underline"
                      transition={{ layout: { duration: 0.2 } }}
                    />
                  )}
                </div>
              )}
            </NavLink>
            {commonRepo.studentAppSetting?.lectureService && (
              <NavLink
                to={routeName.student.lectureList}
                className={({ isActive }) => clsx('gnb__nav-link-mobile', isActive && 'active')}
              >
                {({ isActive }) => (
                  <div className="title-container">
                    강의
                    {isActive && (
                      <m.div
                        className="underline"
                        layoutId="underline"
                        transition={{ layout: { duration: 0.2 } }}
                      />
                    )}
                  </div>
                )}
              </NavLink>
            )}
            <NavLink
              to="/profile"
              className={({ isActive }) => clsx('gnb__nav-link-mobile', isActive && 'active')}
            >
              {({ isActive }) => (
                <div className="title-container">
                  더보기
                  {isActive && (
                    <m.div
                      className="underline"
                      layoutId="underline"
                      transition={{ layout: { duration: 0.2 } }}
                    />
                  )}
                </div>
              )}
            </NavLink>
          </nav>
        </>
      ) : (
        <nav className="gnb__nav">
          <NavLink to={routeName.student.defaultPath} className="gnb__nav-logo">
            <img src={commonRepo.academyLogoUrl} alt="기관 로고" height={32} />
          </NavLink>
          <NavLink
            to={routeName.student.defaultPath}
            className={({ isActive }) => clsx('gnb__nav-link', isActive && 'active')}
            end
          >
            <Icon name="icon_home" color={colors.blue.$500} size={20} />
            학습 홈
          </NavLink>
          <NavLink
            to={routeName.student.challenge}
            className={({ isActive }) => clsx('gnb__nav-link', isActive && 'active')}
          >
            <Icon name="icon_smile" size={20} />
            챌린지
          </NavLink>
          <NavLink
            to={routeName.student.studentWorkbook}
            className={({ isActive }) => clsx('gnb__nav-link', isActive && 'active')}
          >
            <Icon name="icon_book_opened" color={colors.blue.$500} size={20} />
            교재
          </NavLink>
          <NavLink
            to="/student/student-worksheet"
            className={({ isActive }) => clsx('gnb__nav-link', isActive && 'active')}
          >
            <Icon name="icon_description_paper" color={colors.blue.$500} size={20} />
            학습지
          </NavLink>
          {commonRepo.studentAppSetting?.lectureService && (
            <NavLink
              to={routeName.student.lectureList}
              className={({ isActive }) => clsx('gnb__nav-link', isActive && 'active')}
            >
              <Icon name="icon_video" color={colors.blue.$500} size={20} />
              강의
            </NavLink>
          )}
        </nav>
      )}
      {!isMobile && (
        <div className="gnb__userService">
          <button
            className="gnb__userService-opinion"
            onClick={() =>
              modalService.openModal(<OpinionModal />, {
                hasCloseButton: true,
                modalName: 'opinion-modal',
                modalStyle: {
                  padding: '0px',
                },
              })
            }
          >
            <Icon name="icon_heart" />
            <span>의견</span>
            <span className="gnb__userService-opinion__desktop-only">남기기</span>
          </button>

          <NavLink to="/profile" className="gnb__userService-profile">
            <img src={s3URL.student('images/img__profile.svg')} />
            <div className="name">
              <span>{commonRepo.student?.name}&nbsp;</span>
              <span>학생</span>
            </div>
          </NavLink>
          <QRScanButton
            qrButton={(isAppUpdated) => (
              <div className="qr__button-wrapper">
                <div className="qr__button">
                  <Icon name="icon_qr_scan" color={colors.gray.$600} size={26} />
                </div>
                {isAppUpdated && <div className="qr__icon-wrapper" />}
              </div>
            )}
            tooltip={
              <div className="qr__button-guide-wrapper">
                <div className="qr__button-guide">
                  QR 코드를 찍어 채점화면으로
                  <br />
                  빠르게 이동해 보세요!
                </div>
                <Icon
                  name="icon_tooltip_arrow-right"
                  color={colors.white}
                  size={13}
                  style={{
                    position: 'relative',
                    top: '22px',
                    right: '3px',
                  }}
                />
              </div>
            }
          />
          <GuideVideoButton />
        </div>
      )}
    </S.Header>
  )
})
