import { css } from '@emotion/react'
import { darken, toHSL } from '@mathflat/design-system/@common/utils/colorUtil.ts'
import { Slot } from '@radix-ui/react-slot'
import type { CSSProperties } from 'react'
import React, { type ComponentPropsWithoutRef, forwardRef } from 'react'

import { colors, colorTheme } from '~/@common/styles'
import { typo } from '~/@common/styles'
import { hslColorTheme } from '~/@common/styles/colors.ts'
import { fontWeight } from '~/@common/styles/fontWeight.ts'
import { keyframes } from '~/@common/styles/keyframes.ts'

export const kind = 'ms__Button' as const

export const attr = {
  theme: {
    primary: 'primary',
    secondary: 'secondary',
    white: 'white',
    none: 'none',
  },
  size: {
    small: 'small',
    large: 'large',
  },
} as const

export type Props = {
  theme?: keyof typeof attr.theme
  size?: keyof typeof attr.size
  asChild?: boolean
  minWidth?: CSSProperties['minWidth']
  isLoading?: boolean
} & ComponentPropsWithoutRef<'button'>

const Button = forwardRef<React.ElementRef<'button'>, Props>(
  (
    {
      children,
      theme = 'none',
      size = 'large',
      minWidth = size === 'large' ? '48px' : '36px',
      asChild = false,
      style,
      className,
      isLoading,
      disabled,
      ...props
    },
    ref,
  ) => {
    const AsChild = asChild ? Slot : 'button'
    return (
      <AsChild
        className={className}
        css={_css}
        ref={ref}
        style={{ minWidth, ...style }}
        data-theme={theme}
        data-size={size}
        data-component={kind}
        data-is-child={true}
        data-is-loading={isLoading}
        disabled={disabled || isLoading}
        {...(typeof children === 'string' && { 'data-track': children })}
        {...props}
      >
        {!isLoading && children}
      </AsChild>
    )
  },
)
Button.displayName = kind

const { small, large } = attr.size
const { primary, secondary, white, none } = attr.theme

const _css = css`
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: ${fontWeight.bold};

  ${typo.caption01};

  &[data-theme='${primary}'] {
    background-color: ${toHSL(hslColorTheme.primary)};
    color: ${colors.white};

    &:disabled {
      opacity: 0.3;
    }

    &:hover {
      background-color: ${darken(hslColorTheme.primary, 10)};
    }

    &:active {
      background-color: ${darken(hslColorTheme.primary, 20)};
    }

    &.negative {
      background-color: ${toHSL(hslColorTheme.negative)};

      &:hover {
        background-color: ${darken(hslColorTheme.negative, 4)};
      }

      &:active {
        background-color: ${darken(hslColorTheme.negative, 8)};
      }
    }

    &.subGray {
      color: ${colors.gray.$800};
      background-color: ${toHSL(hslColorTheme.subGray)};

      &:hover {
        background-color: ${darken(hslColorTheme.subGray, 4)};
      }

      &:active {
        background-color: ${darken(hslColorTheme.subGray, 8)};
      }
    }
  }

  &[data-theme='${secondary}'] {
    background-color: ${toHSL(hslColorTheme.secondary)};
    color: ${colorTheme.primary};

    &:hover {
      background-color: ${darken(hslColorTheme.secondary, 6)};
    }

    &:active {
      background-color: ${darken(hslColorTheme.secondary, 12)};
    }
  }

  &[data-theme='${white}'] {
    background-color: ${colors.white};
    border: 1px solid ${colors.gray.$300};

    &:disabled {
      background-color: ${colors.gray.$200};
    }
  }

  &[data-size='${large}'] {
    padding: 15px;
    min-height: 48px;
    border-radius: 14px;
  }

  &[data-size='${small}'] {
    padding: 9px;
    min-height: 36px;
    border-radius: 10px;
  }

  &[data-is-loading='true'] {
    ::after {
      display: inline-block;
      content: '';
      width: 20px;
      height: 20px;
      border: 2px solid white;
      border-top-color: transparent;
      border-radius: 50%;
      animation: ${keyframes.rotation} 0.5s ease-in-out infinite;
    }
  }
`

export default Button
