import styled from '@emotion/styled'

const S = {
  Container: styled.div`
    display: flex;
    flex-direction: column;
    padding: 20px 16px 40px;
    height: 100%;
  `,
}

export default S
