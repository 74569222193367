import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { WorkbookDomain } from '@mathflat/domain/@entities/(Content)/Workbook/domain'
import { observer } from 'mobx-react'
import { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router'

import { routeName, type studentWorkbookDetailPathParams } from '~/@common/constants/route'
import { useLearningTime } from '~/@common/hooks/useLearningTime'
import { useRepository, useSetRepository } from '~/@common/hooks/useRepository'
import { errorHandlerService } from '~/@common/services'
import { useLastLocationManager } from '~/@common/services/(LastLocationManager)'
import modalService from '~/@common/services/modal.service'
import { commonRepo } from '~/@common/services/repo.service'
import { PortalRootLayoutHeader } from '~/@common/ui/PortalRootLayoutHeader'
import GlobalHeader from '~/@pages/@widgets/(Navigation)/GlobalHeader/GlobalHeader'

import { StudentLearningContentBackConfirmModal } from '../../@widgets/learningContent/StudentLearningContentBackConfirmModal'
import { StudentWorkbookDetailService } from '../detail/@service/StudentWorkbookDetail.service'
import { StudentWorkbookViewerService } from './@service/StudentWorkbookViewer.service'
import StudentWorkbookViewerBody from './@widgets/StudentWorkbookViewerBody'

const StudentWorkbookViewerPage = () => {
  useLearningTime()
  const { studentWorkbookId, revisionId, pageNumber } =
    useParams<typeof studentWorkbookDetailPathParams>()
  const locationManager = useLastLocationManager()
  const navigate = useNavigate()

  const service = useSetRepository(
    StudentWorkbookDetailService<WorkbookDomain.TYPE['커스텀시그니처교재']>,
    pageNumber,
  )
  const viewerService = useRepository(StudentWorkbookViewerService)

  const isSignature =
    service.workbookDetail?.workbook.type === WorkbookDomain.TYPE.커스텀시그니처교재
  const defaultPrevRouteName = isSignature
    ? routeName.student.customSignatureStudentWorkbookList
    : routeName.student.generalStudentWorkbookList

  const navigateBack = () => {
    if (!locationManager.prevState?.location.pathname) {
      navigate(defaultPrevRouteName)
      return
    }
    navigate(-1)
  }

  useEffect(() => {
    if (pageNumber) {
      service.setCurrentPageNumber(pageNumber)
    } else {
      navigate(
        isSignature
          ? routeName.student.customSignatureStudentWorkbookList
          : routeName.student.generalStudentWorkbookList,
      )
    }
  }, [pageNumber, isSignature])

  useEffect(() => {
    if (service && commonRepo.studentId && studentWorkbookId && revisionId) {
      service.fetchSetWorkbookDetail(commonRepo.studentId, {
        studentWorkbookId,
        revisionId,
      })
    }
  }, [service, studentWorkbookId, revisionId, commonRepo.studentId])

  useEffect(() => {
    if (
      service &&
      service.workbookDetail &&
      commonRepo.studentId &&
      studentWorkbookId &&
      revisionId &&
      pageNumber
    ) {
      const _pageNumber = parseInt(pageNumber)
      if (!isNaN(_pageNumber)) {
        service.fetchSetProblemScoringOfCurrentPage({
          studentId: commonRepo.studentId,
          studentWorkbookId,
          revisionId,
          pageNumber: _pageNumber,
        })
      }
    }
  }, [service, service.workbookDetail, studentWorkbookId, revisionId, pageNumber])

  useEffect(() => {
    const workbookId = service.workbookDetail?.workbook.id
    if (!workbookId) {
      return
    }
    viewerService.fetchWorkbookPages(workbookId).catch(errorHandlerService.handle)
  }, [service.workbookDetail?.workbook.id, viewerService])

  if (!service.workbookDetail) return

  const headerTitle = service.workbookDetail
    ? `${service.workbookDetail?.round}회차 | ${service.workbookDetail?.workbook.title}`
    : ''

  return (
    <S.Container>
      <PortalRootLayoutHeader>
        {!viewerService.isFullscreenMode && (
          <GlobalHeader
            prevLinkProps={{
              to: defaultPrevRouteName,
              onClick: (e) => {
                e.preventDefault()
                if (
                  service.problemScoringViewOption &&
                  !service.problemScoringViewOption.채점불가능 &&
                  service.currentPage?.problemScoringColl &&
                  service.currentPage.problemScoringColl.toScoredArr.length > 0
                ) {
                  modalService.openModal(
                    <StudentLearningContentBackConfirmModal
                      defaultPrevRouteName={defaultPrevRouteName}
                      onSubmit={() => {
                        if (!(studentWorkbookId && revisionId)) {
                          navigateBack()
                          return
                        }

                        service.onSubmit({
                          studentWorkbookId,
                          revisionId,
                        })
                      }}
                    />,
                    {
                      modalName: '교재 - 채점 - 입력 내용 남았을 때 답안제출 확인',
                    },
                  )
                  return
                }
                navigateBack()
                return
              },
            }}
            SlotCenter={
              <div
                css={css`
                  display: -webkit-box;
                  overflow: hidden;
                  word-break: break-all;
                  -webkit-line-clamp: 1;
                  -webkit-box-orient: vertical;
                  text-overflow: ellipsis;
                `}
              >
                {headerTitle}
              </div>
            }
          />
        )}
      </PortalRootLayoutHeader>
      <StudentWorkbookViewerBody />
    </S.Container>
  )
}

export default observer(StudentWorkbookViewerPage)

const S = {
  Container: styled.div`
    height: 100%;
  `,
}
