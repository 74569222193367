import { observer } from 'mobx-react'
import type { CSSProperties, FC, ReactNode } from 'react'
import { useEffect, useRef, useState } from 'react'

import type HandwrittenNoteControllerService from '../handwrittenNote/service/HandwrittenNoteController.service'
import ScalableContent from '../scalableContent/ScalableContent'
import type { FitMode } from '../scalableContent/scalableContent.types'
import type { Size } from '../types'
import HandwrittenNote from './HandwrittenNote'
import type {
  HandwrittenNoteReadFunc,
  HandwrittenNoteWriteFunc,
  NoteData,
  TransformChangeEventFunc,
} from './handwrittenNote.types'

const kind = `mn__WritableHandwrittenNote`

interface Props {
  contentSize: Size
  readFunc: HandwrittenNoteReadFunc
  writeFunc: HandwrittenNoteWriteFunc
  controllerService?: HandwrittenNoteControllerService
  saveDelay?: number
  fitMode?: FitMode
  containerPadding?: number
  zoomLevel?: number
  noteScaleResetKey?: number
  onTransformChange?: TransformChangeEventFunc
  preventResizeObserver?: boolean
  preventOverflow?: boolean
  backgroundNoteData?: NoteData
  style?: CSSProperties
  contentWrapperStyle?: CSSProperties
  contentStyle?: CSSProperties
  children?: ReactNode
}

const WritableHandwrittenNote: FC<Props> = ({
  contentSize,
  readFunc,
  writeFunc,
  controllerService,
  saveDelay,
  fitMode = 'exact',
  containerPadding = 0,
  zoomLevel = 1,
  noteScaleResetKey,
  onTransformChange,
  preventResizeObserver,
  preventOverflow,
  backgroundNoteData,
  style,
  contentWrapperStyle,
  contentStyle,
  children,
}) => {
  const containerRef = useRef<HTMLDivElement>(null)
  const [isReady, setIsReady] = useState(false)

  useEffect(() => {
    setIsReady(true)
  }, [])

  return (
    <div
      ref={containerRef}
      data-component={kind}
      style={{
        overflow: preventOverflow ? 'hidden' : 'auto',
        flexGrow: 1,
        display: 'flex',
        height: '100%',
        position: 'relative',
        ...style,
      }}
      onTouchMove={(e) => {
        if (!controllerService?.isHiddenNote) {
          e.stopPropagation()
        }
      }}
      onPointerMove={(e) => {
        if (!controllerService?.isHiddenNote) {
          e.stopPropagation()
        }
      }}
    >
      {isReady && containerRef.current && (
        <div
          style={{
            display: 'flex',
            flexGrow: 1,
            ...contentWrapperStyle,
          }}
        >
          <ScalableContent
            containerEl={containerRef.current}
            contentSize={contentSize}
            isWritingMode={controllerService?.isWritingMode}
            preventScale={controllerService?.isHiddenNote}
            fitMode={fitMode}
            containerPadding={containerPadding}
            zoomLevel={zoomLevel}
            preventResizeObserver={preventResizeObserver}
            needExtraFinger={controllerService?.needExtraFinger}
            noteScaleResetKey={noteScaleResetKey}
            onTransformChange={onTransformChange}
          >
            <div
              style={{
                ...contentStyle,
              }}
            >
              {children}

              <HandwrittenNote
                viewBoxSize={contentSize}
                readFunc={readFunc}
                writeFunc={writeFunc}
                controllerService={controllerService}
                saveDelay={saveDelay}
                backgroundNoteData={backgroundNoteData}
              />
            </div>
          </ScalableContent>
        </div>
      )}
    </div>
  )
}

export default observer(WritableHandwrittenNote)
