import * as S from '@effect/schema/Schema'

export module AcademyDomain {
  /**
   * 학원 고유 식별 ID
   * @example 'D34345'
   */
  export const Id = S.templateLiteral(S.literal('D'), S.string)
  export type Id = S.Schema.To<typeof Id>
}
