import 'simplebar-react/dist/simplebar.min.css'

import type { Entity as WorksheetEntity } from '@mathflat/domain/@entities/(Content)/Worksheet/dto'
import { useEffect, useState } from 'react'

import { colors } from '~/@common/styles'
import { Icon } from '~/@common/ui/Icon/Icon'
import Table from '~/@common/ui/Table/Table'
import type { ProblemScoring } from '~/@pages/@common/(ProblemScoring)/@trait/ProblemScoring.trait'
import { SubmittedAnswer } from '~/@pages/@common/(Scoring)/(채점결과)/채점결과'

import ProblemScoringAnswerTooltip from './ProblemScoringAnswerTooltip'
import ProblemScoringResultTableDrawer from './ProblemScoringResultTableDrawer'

type Props = {
  problemScoringList: (ProblemScoring<'WORKSHEET'> | undefined)[]
  type: WorksheetEntity.Worksheet['type']
  onCorrentAnswerClick?: (correctAnswerImageUrl: string) => void
}

const ResultIcon = ({ result }: { result: 'CORRECT' | 'WRONG' | 'UNKNOWN' | 'NONE' }) => {
  if (result === 'CORRECT') return <Icon name="icon_answer_correct" color={colors.blue.$300} />
  if (result === 'WRONG') return <Icon name="icon_answer_wrong" color={colors.red.$300} />
  if (result === 'UNKNOWN') return <Icon name="icon_answer_question" color={colors.yellow} />
}

// 임시 - 리팩토링하면서 사라질 코드
export const TEMP_TOOLTIP_OFF_SCROLL_CLASSNAME = 'tooltip-off-scroll'

const ProblemScoringResultTable = ({ problemScoringList, type, onCorrentAnswerClick }: Props) => {
  const [isOpenedGuideDrawer, setIsOpenedGuideDrawer] = useState(false)
  const [openedGuideIndex, setOpenedGuideIndex] = useState(0)
  const [openedTooltipId, setOpenedTooltipId] = useState<string | null>(null)

  useEffect(() => {
    const closeTooltip = () => {
      setOpenedTooltipId(null)
    }

    const scrollEl = document.querySelector(`.${TEMP_TOOLTIP_OFF_SCROLL_CLASSNAME}`)

    if (openedTooltipId) {
      scrollEl?.addEventListener('scroll', closeTooltip)
      document.addEventListener('click', closeTooltip)
    }

    return () => {
      scrollEl?.removeEventListener('scroll', closeTooltip)
      document.removeEventListener('click', closeTooltip)
    }
  }, [openedTooltipId])

  const handleCloseDrawer = () => {
    setIsOpenedGuideDrawer(false)
  }

  const isConceptualLearning = type === 'CONCEPTUAL'
  const ratio = isConceptualLearning ? '54px 66px 242px auto' : '54px 66px 242px 100px 160px auto'
  const headers = ['번호', '결과', '정답', '내가 쓴 답']

  if (!isConceptualLearning) {
    headers.push(...['동영상 ・ 필기 ・ 해설 ', '유형명'])
  }

  const minWidth = isConceptualLearning ? undefined : 790

  const problemScoring = problemScoringList[openedGuideIndex]

  if (!problemScoringList) {
    return null
  }

  return (
    <Table style={{ minWidth, height: '100%' }}>
      <Table.Header ratio={ratio} style={{ textAlign: 'center' }}>
        {headers.map((row) => (
          <Table.HeaderItem key={row}>{row}</Table.HeaderItem>
        ))}
      </Table.Header>
      <Table.Body>
        {problemScoringList.map((problemScoring, i) => {
          if (!problemScoring) return
          const { 채점결과, 문제정답이미지, problem, 문제정답타입, 제출한답 } = problemScoring
          return (
            <Table.Row
              key={problemScoring.id}
              ratio={ratio}
              style={{
                textAlign: 'center',
                lineHeight: '100%',
                alignItems: 'center',
                padding: '6.5px 14px',
                maxHeight: 76,
                overflow: 'hidden',
              }}
              onClick={() => {
                setOpenedGuideIndex(i)
                setIsOpenedGuideDrawer(true)
              }}
            >
              <Table.Item>{i + 1}</Table.Item>
              <Table.Item>
                <ResultIcon result={채점결과} />
              </Table.Item>
              {문제정답이미지 && (
                <Table.Item
                  style={{
                    maxWidth: 242,
                    overflow: 'hidden',
                    cursor: 'pointer',
                    height: 48,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                  data-tooltip-id={`${problem.id}`}
                  onClick={(e) => {
                    e.stopPropagation()

                    if (onCorrentAnswerClick) {
                      onCorrentAnswerClick(문제정답이미지)
                      return
                    }
                    setOpenedTooltipId((prev) => {
                      if (prev === `${problem.id}`) {
                        return null
                      }
                      return `${problem.id}`
                    })
                  }}
                >
                  <img src={문제정답이미지} alt="answer-image" />
                  <ProblemScoringAnswerTooltip
                    id={`${problem.id}`}
                    visible={openedTooltipId === `${problem.id}`}
                  >
                    <img src={문제정답이미지} alt="answer-image" className="problem-answer-img" />
                  </ProblemScoringAnswerTooltip>
                </Table.Item>
              )}
              <Table.Item
                style={{
                  maxWidth: 100,
                  maxHeight: 63,
                  textAlign: 'center',
                  overflow: 'hidden',
                  overflowWrap: 'break-word',
                }}
              >
                <SubmittedAnswer
                  문제정답타입={문제정답타입}
                  제출한답={제출한답}
                  채점결과={채점결과}
                />
              </Table.Item>
              {!isConceptualLearning && (
                <>
                  <Table.Item>
                    <Icon name="icon_search" size={20} color={colors.gray.$600} />
                  </Table.Item>
                  <Table.Item>
                    <div className="concept-name">{problem.conceptName}</div>
                  </Table.Item>
                </>
              )}
            </Table.Row>
          )
        })}
      </Table.Body>
      {problemScoring && isOpenedGuideDrawer && (
        <ProblemScoringResultTableDrawer
          isOpened={true}
          videoUrl={problemScoring.problem.video?.videoUrl}
          problemImageUrl={problemScoring.문제이미지}
          problemExplanationImageUrl={problemScoring.문제해설이미지}
          handwrittenNoteUrl={problemScoring.handwrittenNoteUrl}
          onCloseDrawer={handleCloseDrawer}
        />
      )}
    </Table>
  )
}

export default ProblemScoringResultTable
