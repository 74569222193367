import styled from '@emotion/styled'
import { useNavigate, useSearchParams } from 'react-router-dom'

import { routeName } from '~/@common/constants'
import { useRepository } from '~/@common/hooks/useRepository'
import { errorHandlerService } from '~/@common/services'
import modalService from '~/@common/services/modal.service'
import { colors, typo } from '~/@common/styles'
import { mediaQuery } from '~/@common/styles/mediaQuery'
import { Icon } from '~/@common/ui/Icon/Icon'
import { PortalRootLayoutHeader } from '~/@common/ui/PortalRootLayoutHeader'
import GlobalHeader from '~/@pages/@widgets/(Navigation)/GlobalHeader/GlobalHeader'
import { StudentLearningContentBackConfirmModal } from '~/@pages/student/@widgets/learningContent/StudentLearningContentBackConfirmModal'

import { useSubmitWorksheetScoring } from '../@common/hooks/useSubmitWorksheetScoring'
import { WorksheetScoringService } from '../@service/WorksheetScoring.service'

export const WorksheetScoringPageHeader = ({ title }: { title: string }) => {
  const [searchParams, setSearchParams] = useSearchParams()
  const service = useRepository(WorksheetScoringService)
  const handle = useSubmitWorksheetScoring()

  const navigate = useNavigate()

  const scoringSize = searchParams.get('size')

  const defaultPrevRouteName = routeName.student.studentWorksheet

  const navigateBack = () => {
    const studentWorksheetUrl =
      scoringSize === 'one'
        ? `/student/student-worksheet/${service.validation?.studentWorksheetId}`
        : `/student/student-worksheet/${service.validation?.studentWorksheetId}/scoring`

    navigate(studentWorksheetUrl, {
      replace: true,
    })
  }

  return (
    <PortalRootLayoutHeader>
      <GlobalHeader
        SlotCenter={title}
        SlotRight={
          scoringSize === 'all' ? (
            <RightLink onClick={() => setSearchParams('size=one')}>
              <Icon name="icon_description_paper" size={20} color={colors.gray.$900} />
              <span>한문제씩</span>
            </RightLink>
          ) : (
            <RightLink onClick={() => setSearchParams('size=all')}>
              <Icon name="icon_list" size={20} color={colors.gray.$900} />
              <span>빠른채점</span>
            </RightLink>
          )
        }
        prevLinkProps={{
          to: defaultPrevRouteName,
          onClick: (e) => {
            e.preventDefault()
            if (service.problemScoringColl?.toScoredArr.length) {
              modalService.openModal(
                <StudentLearningContentBackConfirmModal
                  defaultPrevRouteName={defaultPrevRouteName}
                  onSubmit={async () => {
                    try {
                      await handle?.onSubmit()
                      navigateBack()
                    } catch (error) {
                      errorHandlerService.handle(error)
                    }
                  }}
                />,
                {
                  modalName: '답안제출_안내',
                },
              )
              return
            }
            navigateBack()
          },
        }}
      />
    </PortalRootLayoutHeader>
  )
}

const RightLink = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2px;
  ${typo.caption01};
  color: ${colors.gray.$900};
  font-weight: bold;

  cursor: pointer;

  ${mediaQuery.underDesktop} {
    ${typo.body02};
    font-weight: normal;
    color: ${colors.gray.$600};
    svg {
      color: ${colors.gray.$600};
    }
  }
`
