import { useState } from 'react'

import { colors } from '~/@common/styles'
import { Drawer } from '~/@common/ui/(Drawer)/BaseDrawer'
import { Icon } from '~/@common/ui/Icon/Icon'

import { S } from '../../@common/style/common.style'
import { CautionPopupContent } from '../../@common/ui/CautionPopupContent'

export const StudentWorksheetListMobileHeader = () => {
  const [drawerOpen, setDrawerOpen] = useState(false)

  return (
    <>
      <Drawer
        isOpened={drawerOpen}
        openDrawer={() => setDrawerOpen(true)}
        closeDrawer={() => setDrawerOpen(false)}
        size="596px"
      >
        <Drawer.Content>
          <Drawer.Header>오답학습과 심화학습에 대해 알려드려요</Drawer.Header>
          <Drawer.Body>
            <CautionPopupContent />
          </Drawer.Body>
        </Drawer.Content>
      </Drawer>

      <S.Header>
        <h4>오답학습과 심화학습에 대해 알려드려요</h4>
        <button
          className="description"
          onClick={() => {
            setDrawerOpen(true)
          }}
        >
          <Icon name="icon_info_fill" color={colors.gray.$500} size={16} />
          <span>설명보기</span>
        </button>
        <div css={S.headerDivideLine} />
      </S.Header>
    </>
  )
}
