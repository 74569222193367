import type { ISODate } from '@mathflat/shared/@types/date'
import type { ValueOf } from 'type-fest'

import { type CONTENT_STATUS, CONTENT_STATUS_KO } from '../(Content)/module'

export namespace Entity {
  export class StudentWorksheet {
    id: number
    assignDatetime: Date
    openDatetime: Date | null
    score: number
    scoreDatetime: Date | null
    status: ValueOf<typeof CONTENT_STATUS>
    correctCount: number | null
    wrongCount: number | null

    constructor(
      params: Omit<
        StudentWorksheet,
        'assignDatetime' | 'openDatetime' | 'scoreDatetime' | 'koStatus'
      > & {
        assignDatetime: ISODate | Date
        openDatetime: ISODate | Date | null
        scoreDatetime: ISODate | Date | null
      },
    ) {
      this.id = params.id
      this.score = params.score
      this.status = params.status
      this.correctCount = params.correctCount
      this.wrongCount = params.wrongCount
      this.assignDatetime = new Date(params.assignDatetime)
      this.openDatetime = params.openDatetime === null ? null : new Date(params.openDatetime)
      this.scoreDatetime = params.scoreDatetime === null ? null : new Date(params.scoreDatetime)
    }

    get koStatus() {
      return CONTENT_STATUS_KO[this.status]
    }
  }
}
