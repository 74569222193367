import styled from '@emotion/styled'
import { observer } from 'mobx-react'

import { useRepository } from '~/@common/hooks/useRepository'
import type { RadioGroupInputValue } from '~/@common/ui/radio/group/RadioGroup.context'
import { SubTabButtonRadioGroup } from '~/@common/ui/SubTab/SubTabGroup'
import { SubTabButtonGroupItem } from '~/@common/ui/SubTab/SubTabItem'

import { STUDENT_WORKSHEET_STATUS_FILTER_OPTIONS } from '../../@common/constants'
import { StudentWorksheetListService } from '../../@service/StudentWorksheetList.service'

export const StudentWorksheetSubTabGroup = observer(() => {
  const service = useRepository(StudentWorksheetListService)

  const changeFilterOption = (v: RadioGroupInputValue) => {
    service.updateStatusFilterOption(v)
  }

  return (
    <TabHolder
      style={{
        marginTop: '10px',
        marginBottom: '8px',
      }}
    >
      <SubTabButtonRadioGroup
        name="statusOption"
        value={service.uiState.statusFilterOption}
        onChange={changeFilterOption}
      >
        {STUDENT_WORKSHEET_STATUS_FILTER_OPTIONS.map((option) => (
          <SubTabButtonGroupItem key={option.value} value={option.value}>
            {option.label}
          </SubTabButtonGroupItem>
        ))}
      </SubTabButtonRadioGroup>
    </TabHolder>
  )
})

const TabHolder = styled.section`
  display: flex;
  justify-content: center;
`
