import type { MathflatApi } from '@mathflat/domain/@entities/Academy/api'

import maxios from '../utils/maxios'

export const academyApi = (() => {
  return {
    getAcademies: async () => {
      const { data } = await maxios.get<MathflatApi.Response.Academy>('/academies')
      return data
    },
  }
})()
