import { type ComponentProps, type CSSProperties, forwardRef, useId } from 'react'

import _internal from './_internal.ts'
import _css from './Switch.css.ts'

interface SwitchProps extends ComponentProps<'input'> {
  wrapperStyle?: CSSProperties
}

export const { kind, parts } = _internal
const Switch = forwardRef<HTMLInputElement, SwitchProps>(
  ({ children, id, className, wrapperStyle, ...props }: SwitchProps, ref) => {
    const _id = useId()
    const switchId = id || _id

    return (
      <div data-component={kind} css={_css} className={className} style={wrapperStyle}>
        <input className={parts.input} id={switchId} type="checkbox" ref={ref} hidden {...props} />
        <label className={parts.label} htmlFor={switchId}>
          {children}
        </label>
        <label className={parts.switch} htmlFor={switchId} />
      </div>
    )
  },
)

Switch.displayName = 'Switch'

export default Switch
