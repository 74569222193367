import { Equal, Hash } from 'effect'

import { ONE_DAY } from ':/@common/constants/date'

interface CalendarDateData extends Pick<CalendarDate, 'year' | 'month' | 'date'> {}

export class CalendarDate implements Equal.Equal {
  readonly year: number
  readonly month: number
  readonly date: number

  #dateObj: Date

  public isEqual(that: CalendarDate) {
    return this[Equal.symbol](that)
  }

  get dateObj() {
    return this.#dateObj
  }

  getPrevMonth() {
    const isPrevMonthZero = this.month === 1
    return new CalendarDate({
      year: isPrevMonthZero ? this.year - 1 : this.year,
      month: isPrevMonthZero ? 12 : this.month - 1,
      date: this.date,
    })
  }

  getNextMonth() {
    const isNextMonth12 = this.month === 12
    return new CalendarDate({
      year: isNextMonth12 ? this.year + 1 : this.year,
      month: isNextMonth12 ? 1 : this.month + 1,
      date: this.date,
    })
  }

  getNextWeek() {
    return new CalendarDate(this.dateObj.getTime() + ONE_DAY * 7)
  }

  getPrevWeek() {
    return new CalendarDate(this.dateObj.getTime() - ONE_DAY * 7)
  }

  getNextDay() {
    return new CalendarDate(this.dateObj.getTime() + ONE_DAY)
  }
  getPrevDay() {
    return new CalendarDate(this.dateObj.getTime() - ONE_DAY)
  }

  constructor(props?: CalendarDateData | CalendarDate | Date | string | number) {
    if (typeof props === 'object' && !(props instanceof Date)) {
      this.year = props.year
      this.month = props.month
      this.date = props.date
      this.#dateObj = new Date(props.year, props.month - 1, props.date)
    } else {
      const date = props ? new Date(props) : new Date()
      this.#dateObj = date
      this.year = date.getFullYear()
      this.month = date.getMonth() + 1
      this.date = date.getDate()
    }
  }

  [Equal.symbol](that: Equal.Equal): boolean {
    if (that instanceof CalendarDate) {
      return this.year === that.year && this.month === that.month && this.date === that.date
    }
    return false
  }

  [Hash.symbol](): number {
    return this.#dateObj.getTime()
  }

  toString(separator = '-') {
    return `${this.year}${separator}${this.month}${separator}${this.date}`
  }
}

// const Cal1 = new CalendarDate({
//   year: 2023,
//   month: 1,
//   date: 1,
// }) /*?*/
//
// const Cal2 = new CalendarDate({
//   year: 2023,
//   month: 1,
//   date: 1,
// }) /*?*/
//
// Cal1.isEqual(Cal2) /*?*/
// // -> true
