import * as PR from '@effect/schema/ParseResult'
import * as S from '@effect/schema/Schema'
import { pipe } from 'effect'
import * as RA from 'effect/ReadonlyArray'

import { nonZeroNullable } from './combinators'

export const toNullable = <A>(schema: S.Schema<A>) => S.union(S.undefined, S.null, schema)

const toNull = () => null
const nonEmptyNullString = <A>(v: A) => (v === '' ? null : v)
/**
 * 빈 문자열 스키마
 * @category primitives
 */
export const EmptyString = S.literal('')

export const NullableString = S.nullable(S.string)
export const NullableNumber = S.nullable(S.number)

export const NullUndefString = toNullable(S.string)
export const NullUndefNumber = toNullable(S.number)

export const NullableZero = S.union(S.null, S.undefined, S.literal(0))

/**
 * null 또는 0이 아닌 수. 예외 처리 단순화 <br/>
 * undefined, null, 0 -> null
 */
export const NonZeroNullableNumber = nonZeroNullable(S.number)

const A = S.struct({ name: S.void })
/**
 * YYYY-MM-DD 형식 String 스키마
 * @example
 * "2023-01-30"
 */
export const CalendarDateString = S.string

/**
 * ISO 8601 날짜 형식 String 스키마
 * @example
 * "2023-01-30T15:28:28"
 */
export const ISODateString = S.string
export const ISODate = S.dateFromString(ISODateString)

const truthyValues = ['1', 'true', 'yes', 'on', 'ok'] as const
const falsyValues = ['0', 'false', 'no', 'off'] as const

const FuzzyString = S.literal(...[...truthyValues, ...falsyValues])

/**
 * 참 및 거짓에 해당하는 string을 받아 boolean을 반환하는 스키마.
 * <pre>
 *   "1", "true", "yes", "on", "ok" => true
 *   "0", "false", "no", "off" => false
 * </pre>
 */
export const FuzzyBoolean = pipe(
  FuzzyString,
  S.transformOrFail(
    S.boolean,
    (s) => {
      const lowerStr = s.toLowerCase()
      if (RA.contains(truthyValues, lowerStr)) {
        return PR.succeed(true)
      }
      if (RA.contains(falsyValues, lowerStr)) {
        return PR.succeed(false)
      }

      return PR.fail(PR.type(FuzzyString.ast, s))
    },
    (n) => PR.succeed<'true' | 'false'>(n ? 'true' : 'false'),
  ),
)
