import type { WorkbookApi } from '@mathflat/domain/@entities/(Content)/Workbook/api'
import type { Pagination } from '@mathflat/shared/@types/mathflatApi'

import type { WorkbookPageData } from '../types'
import maxios from '../utils/maxios'

export const workbookApi = (() => {
  return {
    getWorkbook: async (params: WorkbookApi.Request.GetWorkbook) => {
      const {
        data: { content },
      } = await maxios.get<Pagination<WorkbookApi.Response.GetWorkbook>>('/workbook', {
        params,
      })

      return content
    },
    getWorkbookPages: async (workbookId: number) => {
      const { data } = await maxios.get<WorkbookPageData[]>(
        `/workbook/custom-signature/${workbookId}/page`,
      )
      return data
    },
  }
})()
