import { WorksheetDomain } from '@mathflat/domain/@entities'
import type { Entity as WorksheetEntity } from '@mathflat/domain/@entities/(Content)/Worksheet/dto'
import type { Entity as StudentWorksheetEntity } from '@mathflat/domain/@entities/StudentWorksheet/dto'

export class SelfLearning {
  studentWorksheet: StudentWorksheetEntity.StudentWorksheet
  worksheet: WorksheetEntity.LevelUpWorksheet | WorksheetEntity.WrongConceptWorksheet
  group: {
    studentWorksheet: StudentWorksheetEntity.StudentWorksheet
    worksheet: WorksheetEntity.LevelUpWorksheet | WorksheetEntity.WrongConceptWorksheet
  }[]
  constructor({
    studentWorksheet,
    worksheet,
    group,
  }: {
    studentWorksheet: StudentWorksheetEntity.StudentWorksheet
    worksheet: WorksheetEntity.LevelUpWorksheet | WorksheetEntity.WrongConceptWorksheet
    group: {
      studentWorksheet: StudentWorksheetEntity.StudentWorksheet
      worksheet: WorksheetEntity.LevelUpWorksheet | WorksheetEntity.WrongConceptWorksheet
    }[]
  }) {
    this.studentWorksheet = studentWorksheet
    this.worksheet = worksheet
    this.group = group
  }
  get worksheetType() {
    return this.worksheet.selfLearningType
  }

  get status() {
    if (this.group.length > 1) {
      return this.group.every((item) => item.studentWorksheet.status === 'COMPLETE')
        ? 'COMPLETE'
        : this.group.every((item) => item.studentWorksheet.status === 'INCOMPLETE')
          ? 'INCOMPLETE'
          : 'PROGRESS'
    }

    return this.studentWorksheet.status
  }

  get wrongCount() {
    if (this.group.length > 1) {
      return this.group.reduce((result, curr) => {
        return curr.studentWorksheet.wrongCount ? result + curr.studentWorksheet.wrongCount : result
      }, 0)
    }
    return this.studentWorksheet.wrongCount
  }

  get correctCount() {
    if (this.group.length > 1) {
      return this.group.reduce((result, curr) => {
        return curr.studentWorksheet.correctCount
          ? result + curr.studentWorksheet.correctCount
          : result
      }, 0)
    }
    return this.studentWorksheet.correctCount
  }
}

export class SelfLearningColl {
  private _selfLearnings: SelfLearning[]

  constructor(selfLearnings: SelfLearning[]) {
    this._selfLearnings = selfLearnings
  }

  get hasLevelUp() {
    return this._selfLearnings.some(
      (selfLearning) => selfLearning.worksheetType === WorksheetDomain.SELF_LEARNING_TYPE.심화학습,
    )
  }
  get hasWrong() {
    return this._selfLearnings.some(
      (selfLearning) =>
        selfLearning.worksheetType === WorksheetDomain.SELF_LEARNING_TYPE.오답유형학습,
    )
  }
  get disabledLevelUp() {
    return this._selfLearnings.some(
      (selfLearning) =>
        selfLearning.worksheetType === WorksheetDomain.SELF_LEARNING_TYPE.심화학습 &&
        (selfLearning.status !== 'COMPLETE' || selfLearning.wrongCount === 0),
    )
  }

  get disabledWrong() {
    return this._selfLearnings.some(
      (selfLearning) =>
        selfLearning.worksheetType === WorksheetDomain.SELF_LEARNING_TYPE.오답유형학습 &&
        (selfLearning.status !== 'COMPLETE' || selfLearning.wrongCount === 0),
    )
  }

  get completedLevelUp() {
    return (
      this._selfLearnings
        .filter(
          (selfLearning) =>
            selfLearning.worksheetType === WorksheetDomain.SELF_LEARNING_TYPE.심화학습,
        )
        .every((selfLearning) => selfLearning.status === 'COMPLETE') &&
      this._selfLearnings
        .filter(
          (selfLearning) =>
            selfLearning.worksheetType === WorksheetDomain.SELF_LEARNING_TYPE.심화학습,
        )
        .some((selfLearning) => selfLearning.wrongCount === 0)
    )
  }

  get completedWrong() {
    return (
      this._selfLearnings
        .filter(
          (selfLearning) =>
            selfLearning.worksheetType === WorksheetDomain.SELF_LEARNING_TYPE.오답유형학습,
        )
        .every((selfLearning) => selfLearning.status === 'COMPLETE') &&
      this._selfLearnings
        .filter(
          (selfLearning) =>
            selfLearning.worksheetType === WorksheetDomain.SELF_LEARNING_TYPE.오답유형학습,
        )
        .some((selfLearning) => selfLearning.wrongCount === 0)
    )
  }

  get size() {
    return this._selfLearnings.length
  }
  get toArr() {
    return this._selfLearnings
  }
}
