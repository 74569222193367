import { makeAutoObservable } from 'mobx'

import {
  ERASER_WIDTHS,
  HIGHLIGHT_PEN_COLORS,
  HIGHLIGHT_PEN_WIDTHS,
  PEN_COLORS,
  PEN_WIDTHS,
} from '../handwrittenNote.const'
import type { HandwrittenNoteToolbarMode, PathStyle, Tool } from '../handwrittenNote.types'

export default class HandwrittenNoteModeService {
  isWritingMode = false
  toolbarMode: HandwrittenNoteToolbarMode = 'full'
  activeTool: Tool = 'pen'
  isHiddenNote = false
  needExtraFinger = false
  pathStyle: PathStyle = 'raw'

  private penWidth = PEN_WIDTHS[1]
  private highlightPenWidth = HIGHLIGHT_PEN_WIDTHS[1]
  private eraserWidth = ERASER_WIDTHS[0]
  private penColor = PEN_COLORS[0]
  private highlightPenColor = HIGHLIGHT_PEN_COLORS[0]

  constructor() {
    makeAutoObservable(this)
  }
  get toolWidth() {
    switch (this.activeTool) {
      case 'pen':
        return this.penWidth
      case 'highlight-pen':
        return this.highlightPenWidth
      case 'eraser':
        return this.eraserWidth
    }
  }

  get toolColor() {
    switch (this.activeTool) {
      case 'pen':
        return this.penColor
      case 'highlight-pen':
        return this.highlightPenColor
    }
  }

  setToolWidth(value: number) {
    switch (this.activeTool) {
      case 'pen':
        this.penWidth = value
        break
      case 'highlight-pen':
        this.highlightPenWidth = value
        break
      case 'eraser':
        this.eraserWidth = value
        break
    }
  }

  setToolColor(value: string) {
    switch (this.activeTool) {
      case 'pen':
        this.penColor = value
        break
      case 'highlight-pen':
        this.highlightPenColor = value
        break
    }
  }

  setToolbarMode(value: HandwrittenNoteToolbarMode) {
    this.toolbarMode = value
  }

  setIsWritingMode(value: boolean) {
    this.isWritingMode = value
    if (value) {
      this.setToolbarMode('full')
    }
  }

  setActiveTool(value: Tool, isToggle = false) {
    if (isToggle) {
      if (this.activeTool === value) {
        this.isWritingMode = !this.isWritingMode
      } else {
        this.isWritingMode = true
      }
      if (this.isWritingMode) {
        this.isHiddenNote = false
        this.toolbarMode = 'full'
      }
    }
    this.activeTool = value
  }

  setIsHiddenNote(value: boolean, isToggle = false) {
    this.isHiddenNote = value

    if (isToggle) {
      this.isWritingMode = !value
    }
  }

  setNeedExtraFinger(value: boolean) {
    this.needExtraFinger = value
  }

  setPathStyle(value: PathStyle) {
    this.pathStyle = value
  }
}
