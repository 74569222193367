import type { ApiSpec, InputApiSpecWithPathParams, Tag } from ':/@modules/ApiSpec/types.js'
import type { Tagged } from ':/@types/kind'

export const apiSpec = <const InputApiSpecT extends InputApiSpecWithPathParams>(
  route: InputApiSpecT,
) =>
  Object.assign(route, {
    _tag: 'apiSpec' as Tag,
  }) as ApiSpec<InputApiSpecT>

export const isApSpec = <A extends Tagged>(a: A): a is ApiSpec<any> => a._tag === 'ApiSpec'
