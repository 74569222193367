import React from 'react'
import { useNavigate } from 'react-router'

import modalService from '~/@common/services/modal.service'
import Modal from '~/@common/ui/modal/Modal'

export const alertWorksheetExceptionErrorModal = () => {
  modalService.closeAllModal()
  modalService.openModal(<ExceptionModal />, {
    modalName: '학습지_삭제_안내',
  })
}

export const ExceptionModal = () => {
  const navigate = useNavigate()
  return (
    <Modal.Alert
      confirm={{
        onClick: () => {
          modalService.closeModal()
          navigate('/student')
        },
      }}
    >
      제출한 학습지가 삭제되거나 수정되었습니다.
      <br />
      선생님께 문의해 주세요.
    </Modal.Alert>
  )
}
