import type { ScalableContentTypes } from '@mathflat/handwritten-note'
import { action, computed, makeObservable, observable, runInAction } from 'mobx'

import { workbookApi } from '~/@common/api/workbookApi'
import type { WorkbookPageData } from '~/@common/types'

interface PageData {
  pageNumber: number
  prevPageNumber?: number
  nextPageNumber?: number
  imageUrl: string
}

export class StudentWorkbookViewerService {
  private _pages?: WorkbookPageData[] = undefined
  isScoringMode = false
  isFullscreenMode = false
  zoomLevel = 1
  fitMode: ScalableContentTypes.FitMode = 'center'

  constructor() {
    makeObservable<this, '_pages' | 'isScoringMode' | 'isFullscreenMode' | 'fitMode' | 'zoomLevel'>(
      this,
      {
        _pages: observable,
        isScoringMode: observable,
        isFullscreenMode: observable,
        fitMode: observable,
        zoomLevel: observable,
        pageData: computed,
        firstPageNumber: computed,
        lastPageNumber: computed,
        containerPadding: computed,
        setPages: action,
        setFitMode: action,
        setZoomLevel: action,
        reset: action,
      },
    )
  }

  get pages() {
    return this._pages
  }

  get pageData() {
    const data = new Map<number, PageData>()
    if (!this._pages) {
      return data
    }
    this._pages.forEach((item, index, list) => {
      data.set(item.page, {
        pageNumber: item.page,
        imageUrl: item.imageUrl,
        prevPageNumber: list[index - 1] ? list[index - 1].page : undefined,
        nextPageNumber: list[index + 1] ? list[index + 1].page : undefined,
      })
    })
    return data
  }

  get firstPageNumber() {
    if (!this._pages?.length) {
      return
    }
    return this._pages[0].page
  }

  get lastPageNumber() {
    if (!this._pages?.length) {
      return
    }
    return this._pages[this._pages.length - 1].page
  }

  get containerPadding() {
    return this.isFullscreenMode ? 25 : 42
  }

  setPages(value?: WorkbookPageData[]) {
    this._pages = value
  }

  setIsScoringMode(value: boolean) {
    this.isScoringMode = value
  }

  setIsFullscreenMode(value: boolean) {
    this.isFullscreenMode = value
  }

  setZoomLevel(value: number) {
    this.zoomLevel = value
  }

  setFitMode(value: ScalableContentTypes.FitMode) {
    this.fitMode = 'center'
    window.requestAnimationFrame(() => {
      runInAction(() => {
        this.fitMode = value
        this.zoomLevel = 1
      })
    })
  }

  setContainerPadding(value: number) {
    this.setContainerPadding(value)
  }

  reset() {
    this._pages = undefined
  }

  async fetchWorkbookPages(workbookId: number) {
    const data = await workbookApi.getWorkbookPages(workbookId)
    this.setPages(data)
  }
}
