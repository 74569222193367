import { css } from '@emotion/react'
import type { LearningProcessApi } from '@mathflat/domain/@entities/StudentLearningProcess/api'
import { clsx } from 'clsx'
import { useState } from 'react'

import { colors, typo } from '~/@common/styles'
import { mediaQuery } from '~/@common/styles/mediaQuery.tsx'
import { Icon } from '~/@common/ui/Icon/Icon'

export type Concept = LearningProcessApi.개념학습하기.Output['concepts'][number]

type Props = {
  index: number
  isPreLearned?: boolean
  isDefaultOpened?: boolean
  item: Concept
}

const ConceptImageItem = ({ index, isPreLearned, isDefaultOpened, item }: Props) => {
  const [isOpened, setIsOpened] = useState(isDefaultOpened)

  return (
    <>
      {index > 0 && <div css={_lineCss}></div>}
      <div css={_css}>
        <div
          className={clsx('accordion-head', isOpened ? 'opened' : 'closed')}
          onClick={() => setIsOpened(!isOpened)}
          data-component={'ms__ConceptsItem'}
        >
          <div className="concepts-text-wrapper">
            <div className="concepts-number">
              {isPreLearned ? '사전개념 ' : '유형 '}
              {index + 1}
            </div>
            <div className="concepts-title">{item.name}</div>
          </div>
          <div
            className="icon-wrapper"
            style={{
              rotate: `${isOpened ? 180 : 0}deg`,
            }}
          >
            <Icon name="icon_chevron_down" size={16} color={colors.gray.$900} />
          </div>
        </div>
        {isOpened && (
          <div className="accordion-content">
            <div className="image-wrapper">
              <img src={item.url} />
            </div>
          </div>
        )}
      </div>
    </>
  )
}

export default ConceptImageItem

const _css = css`
  display: flex;
  flex-direction: column;
  background-color: ${colors.white};
  border-radius: 14px;

  .accordion-head {
    display: flex;
    align-items: center;
    gap: 9px;
    cursor: pointer;
    padding: 20px;

    .icon-wrapper {
      display: flex;
      align-items: center;
      transition: rotate 100ms;
    }
  }

  .accordion-content {
    padding: 0 20px 20px 20px;

    ${mediaQuery.underTablet} {
      padding: 0 16px 16px;
    }
  }

  .concepts-text-wrapper {
    display: flex;
    gap: 4px;
    width: 100%;
    ${typo.body02};
    color: ${colors.gray.$900};

    ${mediaQuery.underTablet} {
      ${typo.caption01};
    }
  }

  .concepts-number {
    min-width: fit-content;
    font-weight: 700;
  }

  .concepts-title {
    max-width: fit-content;
    color: ${colors.black};
  }

  img {
    width: 100%;
  }
`

const _lineCss = css`
  height: 1px;
  background-color: ${colors.gray.$300};
  margin: 0 20px;
`
