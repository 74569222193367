import * as S from '@effect/schema/Schema'
import { apiSpec } from '@mathflat/shared/@modules/ApiSpec/apiSpec.ts'
import type {
  ApiSpecInputParams,
  ApiSpecOutputData,
  ApiSpecRouteParams,
} from '@mathflat/shared/@modules/ApiSpec/types'

import { ConceptChipDomain } from '../ConceptChip/domain'

export module ConceptApi {
  /**
   * 유형칩의 정보 조회 <br/>
   * link: https://freewheelin.postman.co/workspace/ef6c4fe1-201a-4de6-8b0c-2a7de439d3ff/request/12065882-96b88532-3fe9-4e82-9c4e-b2f11c1d3481?ctx=documentation
   */
  export module 유형칩조회 {
    export const ChipDetail = S.struct({
      orderNumber: S.number,
      conceptChipId: S.number,
      conceptChipType: ConceptChipDomain.Type,
      recommended: S.boolean,
      bigChapterId: S.number,
      bigChapterName: S.string,
      middleChapterId: S.number,
      middleChapterName: S.string,
      littleChapterId: S.number,
      littleChapterName: S.string,
      conceptId: S.number,
      conceptName: S.string,
      repProblem: S.struct({
        id: S.number,
        problemImageUrl: S.string,
      }).pipe(S.nullable),
    })

    export type ChipDetail = S.Schema.To<typeof ChipDetail>
    export const spec = apiSpec({
      method: 'GET',
      path: 'concept/chips',
      params: S.struct({
        curriculumKey: S.string,
        workbookIds: S.optional(S.array(S.number)),
        selection: S.optional(S.boolean),
      }),
      responseData: S.array(ChipDetail),
    })

    export type PathParams = ApiSpecRouteParams<typeof spec>
    export type Params = ApiSpecInputParams<typeof spec>
    export type Output = ApiSpecOutputData<typeof spec>
  }
}
