import type { MediaQueryParams } from '@mathflat/design-system/@common/utils/mediaQuery'
import { MediaQuery } from '@mathflat/design-system/@common/utils/mediaQuery'

class StudentAppMediaQuery<LG extends number, MD extends number> extends MediaQuery<never, LG, MD> {
  constructor(breakPoint: MediaQueryParams<never, LG, MD>) {
    super(breakPoint)
  }

  get breakPoint() {
    return { desktop: this._lg, tablet: this._md }
  }

  get underDesktop() {
    return MediaQuery.makeDeskTopFirstMediaQuery(this._lg)
  }

  get underTablet() {
    return MediaQuery.makeDeskTopFirstMediaQuery(this._md)
  }
}

export const mediaQuery = new StudentAppMediaQuery({
  lg: 1280,
  md: 1024,
})
