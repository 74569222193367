import styled from '@emotion/styled'

import { mediaQuery } from '~/@common/styles/mediaQuery'

export const S = {
  GuideVideoPage: styled.div`
    width: 100%;
    max-width: 792px;
    margin: 0 auto;
    overflow: auto;
    ${mediaQuery.underTablet} {
      width: 100vw;
      max-width: none;
      margin: -10px -16px;
    }
  `,
  Thumbnail: styled.div`
    width: 100%;
    height: 100%;
  `,
}
