import { css } from '@emotion/react'
import { observer } from 'mobx-react'
import type { ComponentProps, FC } from 'react'

import { useRepository } from '~/@common/hooks/useRepository'
import { colors, fontFamily, fontWeight, typo } from '~/@common/styles'
import { mediaQuery } from '~/@common/styles/mediaQuery'

import WeeklyInfoService from '../../@service/WeeklyInfo.service'
import ContentBox from '../ContentBox'

type BorderRadiusType = ComponentProps<typeof ContentBox>['borderRadiusType']

interface Props {
  type: 'learningTime' | 'solvedCount'
  title: string
  value: string | number | undefined
  borderRadiusType?: BorderRadiusType
}

const WeeklyInfoSingleBox: FC<Props> = ({ type, title, value, borderRadiusType }) => {
  const service = useRepository(WeeklyInfoService)
  const { isReady } = service

  return (
    <ContentBox tagName="dl" css={_Style} isReady={isReady} borderRadiusType={borderRadiusType}>
      {isReady && (
        <>
          <dt className="title">{title}</dt>
          <dd className="value">
            <strong>{value}</strong>
            {type === 'solvedCount' && <span css={{ marginLeft: 4 }}>문제</span>}
          </dd>
        </>
      )}
    </ContentBox>
  )
}

export default observer(WeeklyInfoSingleBox)

const _Style = css`
  display: flex;
  flex: 1 1 0;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 4px;
  height: 155px;
  padding: 0;

  .title {
    color: ${colors.gray.$800};
    padding-bottom: 2px;
    align-items: center;
    ${typo.body02};
    > img {
      margin-right: 4px;
    }
  }
  .value {
    display: flex;
    align-items: center;
    > strong {
      ${fontFamily.montserrat};
      font-size: 36px;
      font-weight: ${fontWeight.bold};
      color: ${colors.black};
      line-height: 1.2;
    }
  }

  ${mediaQuery.underTablet} {
    border-radius: 0;
    height: 97px;

    .title {
      padding-bottom: 4px;
    }
  }
`
