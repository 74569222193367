import styled from '@emotion/styled'
import clsx from 'clsx'
import * as React from 'react'
import { useEffect, useRef } from 'react'

import { colors } from '~/@common/styles'
import type { StyleProps } from '~/@common/types'

import { Icon } from '../Icon/Icon'
import { DropdownContext } from './context'

type Props = {
  value: any
  disabled?: boolean
  children?: React.ReactNode
  isChecked?: boolean
} & StyleProps

const DropdownItem = ({ value, disabled = false, children, className, style }: Props) => {
  const ref = useRef<HTMLButtonElement>(null)
  const context = React.useContext(DropdownContext)
  const isActive = value === context?.value

  useEffect(() => {
    if (isActive) {
      context?.onSelect(value, children)
    }
  }, [context?.value])

  useEffect(() => {
    if (isActive) {
      context?.onSelect(value, children)
    }
  }, [children])

  return (
    <button
      disabled={disabled}
      className={clsx(isActive && 'active', className)}
      style={style}
      onClick={() => context?.onSelect(value, children, true)}
      ref={ref}
    >
      {children}
      {isActive && (
        <Icon name="icon_check" className="check-icon" size={20} color={colors.gray.$900} />
      )}
    </button>
  )
}

export default styled(DropdownItem)`
  display: flex;
  align-items: center;
  text-align: left;
  .check-icon {
    margin-left: auto;
    display: none;
  }
`
