import type { WorkbookDomain } from '@mathflat/domain/@entities/(Content)/Workbook/domain'
import type { Entity as WorkbookEntity } from '@mathflat/domain/@entities/(Content)/Workbook/dto'
import type { MathflatApi } from '@mathflat/domain/@entities/StudentWorkbook/api.ts'
import type { Entity as StudentWorkbookEntity } from '@mathflat/domain/@entities/StudentWorkbook/dto'
import type { ISODate } from '@mathflat/shared/@types/date'
import { makeAutoObservable } from 'mobx'

import type { ProblemScoringColl } from '~/@pages/@common/(ProblemScoring)/@trait/ProblemScoring.trait'

export class StudentWorkbookDetailDTO<T extends WorkbookDomain.Type = WorkbookDomain.Type> {
  round: StudentWorkbookEntity.Revision['round']
  workbook: WorkbookEntity.Workbook<T>
  private _pageMap: Map<StudentWorkbookDetailPageDTO['pageNumber'], StudentWorkbookDetailPageDTO>
  autoScored = false

  constructor(
    { page, round, workbook }: MathflatApi.Response.StudentWorkbookDetail<T>,
    options?: {
      observable?: boolean
      makeAutoObservableAnnotaionMap?: Parameters<typeof makeAutoObservable>[1]
    },
  ) {
    if (options?.observable) {
      makeAutoObservable(this, options.makeAutoObservableAnnotaionMap)
    }

    this.round = round
    this.workbook = workbook
    let prevPageNumber: number | null = null
    this._pageMap = new Map(
      page.content.map((pageContent, pageIndex) => {
        const nextPage = page.content[pageIndex + 1] ?? null
        const value = new StudentWorkbookDetailPageDTO(
          {
            ...pageContent,
            prevPageNumber,
            nextPageNumber: nextPage ? nextPage.page.page : null,
            autoScored: this.autoScored,
          },
          { observable: true },
        )

        const key = value.pageNumber
        prevPageNumber = key
        return [key, value]
      }),
    )
  }

  get allPageNumbers() {
    return [...this._pageMap.keys()]
  }

  get allPages() {
    return [...this._pageMap.values()]
  }

  getPage(pageNumber: number) {
    return this._pageMap.get(pageNumber)
  }
}

export class StudentWorkbookDetailPageDTO {
  progressId: number
  status: StudentWorkbookEntity.Progress['status']
  pageNumber: StudentWorkbookEntity.StudentWorkbookPage['page']
  prevPageNumber: number | null
  nextPageNumber: number | null
  autoScored: boolean
  title: string
  problemScoringColl?: ProblemScoringColl<'WORKBOOK'>

  constructor(
    params: MathflatApi.Response.StudentWorkbookDetail['page']['content'][number] & {
      prevPageNumber: number | null
      nextPageNumber: number | null
      autoScored: boolean
    },
    options?: {
      observable?: boolean
      makeAutoObservableAnnotaionMap?: Parameters<typeof makeAutoObservable>[1]
    },
  ) {
    if (options?.observable) {
      makeAutoObservable(this, options.makeAutoObservableAnnotaionMap)
    }
    this.progressId = params.progressId
    this.status = params.status
    this.title = params.page.title
    this.pageNumber = params.page.page

    this.prevPageNumber = params.prevPageNumber
    this.nextPageNumber = params.nextPageNumber
    this.autoScored = params.autoScored
  }
}

// 내려주는 정보가 많고, 정작 중요 데이터가 depth가 깊숙히 있는 이슈가 있어서 쓰는 값들 위주로 뽑아서 쓴다.
export class StudentWorkbookListItem {
  roundId: StudentWorkbookEntity.Revision['id'] // roundId는 없음. revisionId가 아닐까 추측.
  round: StudentWorkbookEntity.Revision['round']
  studentWorkbookId: StudentWorkbookEntity.StudentWorkbook['id']
  교재명: WorkbookEntity.Workbook['subtitle']
  recentPageNumber: StudentWorkbookEntity.StudentWorkbookPage['page']
  recentRevisionRound: StudentWorkbookEntity.Revision['round']
  진척도: StudentWorkbookEntity.Revision['percent']
  출제일: Date
  thumbnailImageUrl?: WorkbookEntity.CustomSignatureWorkbook['thumbnailImageUrl']
  autoScored: WorkbookEntity.Workbook['autoScored']
  constructor(params: {
    roundId: StudentWorkbookEntity.Revision['id'] // roundId는 없음. revisionId가 아닐까 추측.
    round: StudentWorkbookEntity.Revision['round']
    title: WorkbookEntity.Workbook['subtitle']
    createDatetime: ISODate
    recentRevisionRound: StudentWorkbookEntity.Revision['round']
    recentPageNumber: StudentWorkbookEntity.Revision['recentPageNumber']
    studentWorkbookId: StudentWorkbookEntity.StudentWorkbook['id']
    percent: StudentWorkbookEntity.Revision['percent']
    autoScored: WorkbookEntity.Workbook['autoScored']
    thumbnailImageUrl?: WorkbookEntity.CustomSignatureWorkbook['thumbnailImageUrl']
  }) {
    this.출제일 = new Date(params.createDatetime)
    this.roundId = params.roundId
    this.round = params.round
    this.studentWorkbookId = params.studentWorkbookId
    this.교재명 = params.title
    this.recentRevisionRound = params.recentRevisionRound
    this.recentPageNumber = params.recentPageNumber
    this.진척도 = params.percent
    this.autoScored = params.autoScored
    this.thumbnailImageUrl = params.thumbnailImageUrl
  }
}
