import type { MathflatApi } from '@mathflat/domain/@entities/Academy/api'
import { mapKeys } from 'lodash'

export const academyMap = {
  cellPhone: 'smsPhone',
  freeStartDate: 'freeStartDatetime',
  freeEndDate: 'freeEndDatetime',
  payDate: 'payDay',
  payStartDate: 'payStartDatetime',
  payEndDate: 'payEndDatetime',
  worksheetShareFlag: 'worksheetShared',
}

export const convertAcademy = (_academy: Partial<MathflatApi.Response.Academy>) => {
  const academy = mapKeys(_academy, (value, key) => {
    if (key in academyMap) {
      return academyMap[key]
    }
    return key
  }) as Partial<MathflatApi.Response.Academy>

  if ('metadata' in academy) {
    const { metadata, ...rest } = academy
    if (typeof metadata === 'object' && 'columnDefs' in metadata) {
      const { columnDefs, ..._metadata } = metadata
      return { ...rest, ..._metadata }
    }
    return { ...rest, ...metadata }
  } else {
    return academy
  }
}
