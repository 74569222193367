import type { WorkbookDomain } from '@mathflat/domain/@entities/(Content)/Workbook/domain.ts'
import type { Entity as WorkbookEntity } from '@mathflat/domain/@entities/(Content)/Workbook/dto.ts'
import type { ValueOf } from '@mathflat/shared/@types/utilityTypes'
import { makeAutoObservable, runInAction, toJS } from 'mobx'

import { workbookApi } from '~/@common/api/workbookApi.ts'
import { commonRepo } from '~/@common/services/repo.service.ts'

export type SearchWorkbookType = ValueOf<
  Pick<typeof WorkbookDomain.TYPE, '커스텀시그니처교재' | '시중교재' | '교과서'>
>

const WORKBOOK_TYPES: SearchWorkbookType[] = ['CUSTOM_SIGNATURE', 'PUBLIC', 'SCHOOL']

const ASSIGN_SEARCH_TYPE_BY_WORKBOOK = {
  CUSTOM_SIGNATURE: 'ONLY_ASSIGNED',
  PUBLIC: 'WITH_ASSIGNED',
  SCHOOL: 'WITH_ASSIGNED',
} as const

const WORKBOOK_TYPE_KR = {
  CUSTOM_SIGNATURE: '시그니처',
  PUBLIC: '시중교재',
  SCHOOL: '교과서',
} as const

const MAX_SIZE_BY_WORKBOOK_TYPE = {
  CUSTOM_SIGNATURE: 5,
  PUBLIC: 5,
  SCHOOL: 3,
}

export type RequiredWorkbookInfo = Pick<WorkbookEntity.Workbook, 'id' | 'type' | 'fulltitle'>

type WorkbookId = number
const defaultCheckedWorkbookColl = {
  CUSTOM_SIGNATURE: new Map<WorkbookId, RequiredWorkbookInfo>(),
  PUBLIC: new Map<WorkbookId, RequiredWorkbookInfo>(),
  SCHOOL: new Map<WorkbookId, RequiredWorkbookInfo>(),
} as const

export class WorkbookSearchService {
  static WORKBOOK_TYPES = WORKBOOK_TYPES
  static WORKBOOK_TYPE_KR = WORKBOOK_TYPE_KR
  static MAX_SIZE_BY_WORKBOOK_TYPE = MAX_SIZE_BY_WORKBOOK_TYPE

  _isChanged = false

  searchText = ''
  _initCurriculumKey: string | null = null
  curriculumKey: string | null = null

  private checkWorkbookColl = toJS(defaultCheckedWorkbookColl)

  selectedWorkbookType: SearchWorkbookType = 'CUSTOM_SIGNATURE'

  searchWorkbookMap = new Map<SearchWorkbookType, Map<WorkbookId, WorkbookEntity.Workbook>>()

  get checkedWorkbookToArray() {
    return [...Object.values(this.checkWorkbookColl)]
  }

  getCheckedWorkbookSizeBy(workbookType: SearchWorkbookType) {
    return this.checkWorkbookColl[workbookType].size
  }

  checkIsMaxSizeReached(workbookType: SearchWorkbookType) {
    return (
      this.getCheckedWorkbookSizeBy(workbookType) >=
      WorkbookSearchService.MAX_SIZE_BY_WORKBOOK_TYPE[workbookType]
    )
  }

  changed() {
    this._isChanged = true
  }

  unchanged() {
    this._isChanged = false
  }

  get isChanged() {
    return this._isChanged
  }

  setSelectWorkbookType(type: SearchWorkbookType) {
    this.selectedWorkbookType = type
  }

  clear() {
    this.checkWorkbookColl = toJS(defaultCheckedWorkbookColl)
    if (this._initCurriculumKey && this._initCurriculumKey !== this.curriculumKey) {
      this.setCurriculumKey(this._initCurriculumKey)
    }
    this._isChanged = true
  }

  getFilteredWorkbookList(type: SearchWorkbookType) {
    const workbookData = this.searchWorkbookMap.get(type)
    if (!workbookData) {
      return
    }
    return (
      [...workbookData.values()].filter(({ fulltitle }) =>
        fulltitle
          .replaceAll(' ', '')
          .toLowerCase()
          .includes(this.searchText.replaceAll(' ', '').toLowerCase()),
      ) ?? []
    )
  }

  get hasFilteredWorkbooks() {
    return Boolean(WORKBOOK_TYPES.find((type) => this.getFilteredWorkbookList(type)?.length))
  }

  get selectedWorkbookList() {
    const selectedWorkbooks = this.searchWorkbookMap.get(this.selectedWorkbookType)
    if (!selectedWorkbooks) return []
    return [...selectedWorkbooks.values()]
  }

  checkWorkbook(type: SearchWorkbookType, workbook: WorkbookEntity.Workbook) {
    if (this.checkWorkbookColl[type].has(workbook.id)) return true

    return !(this.checkWorkbookColl[type].size >= MAX_SIZE_BY_WORKBOOK_TYPE[type])
  }

  toggleWorkbook(type: SearchWorkbookType, workbook: WorkbookEntity.Workbook) {
    if (!this._isChanged) this.changed()
    const checkWorkbookColl = this.checkWorkbookColl[type]
    if (checkWorkbookColl.has(workbook.id)) {
      checkWorkbookColl.delete(workbook.id)
    } else {
      checkWorkbookColl.set(workbook.id, workbook)
    }
  }

  setSearchText = (text: string) => {
    this.searchText = text
  }
  setCurriculumKey(curriculumKey: string) {
    if (this.curriculumKey === curriculumKey) return
    if (!this._isChanged) this.changed()

    this.curriculumKey = curriculumKey

    this.checkWorkbookColl = toJS(defaultCheckedWorkbookColl)

    this.fetchWorkbookForTypes()
  }

  fetchWorkbookForTypes() {
    if (!this.curriculumKey) return
    for (const type of WORKBOOK_TYPES) {
      // 교과서 커리큘럼키 계산
      const curriculumKey =
        type === 'SCHOOL'
          ? commonRepo.curriculumTreeColl?.getCurriculumKeyForSchoolbook(this.curriculumKey)
          : this.curriculumKey
      if (!curriculumKey) continue

      WorkbookSearchService.fetchWorkbooks(type, curriculumKey).then((workbooks) => {
        runInAction(() =>
          this.searchWorkbookMap.set(
            type,
            new Map(workbooks.map((workbook) => [workbook.id, workbook])),
          ),
        )
      })
    }
  }

  static fetchWorkbooks(workbookType: SearchWorkbookType, curriculumKey: string) {
    return workbookApi.getWorkbook({
      curriculumKey,
      type: workbookType,
      assignStudentId: commonRepo.studentId,
      assignSearchType: ASSIGN_SEARCH_TYPE_BY_WORKBOOK[workbookType],
      assignType: 'STUDENT',
      size: 6000,
    })
  }

  init({
    curriculumKey,
    selectedWorkbookType,
    searchText,
    selectedWorkbooks,
  }: {
    curriculumKey: string
    searchText: string
    selectedWorkbookType: SearchWorkbookType
    selectedWorkbooks: RequiredWorkbookInfo[]
  }) {
    this.searchText = searchText
    this.selectedWorkbookType = selectedWorkbookType
    this._initCurriculumKey = this.curriculumKey = curriculumKey

    this.checkWorkbookColl = toJS(defaultCheckedWorkbookColl)

    for (const workbook of selectedWorkbooks) {
      const { type, id } = workbook
      this.checkWorkbookColl[type].set(id, workbook)
    }
  }

  constructor() {
    makeAutoObservable(this)
  }
}
