import clsx from 'clsx'
import type { ComponentPropsWithRef } from 'react'

const RadioItem = ({ children, className, ...props }: ComponentPropsWithRef<'div'>) => {
  return (
    <div className={clsx('ms-component-radio-item', className)} {...props}>
      {children}
    </div>
  )
}

export default RadioItem
