import styled from '@emotion/styled'
import { observer } from 'mobx-react'
import { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router'

import { studentWorksheetApi } from '~/@common/api'
import { HandwrittenNoteType } from '~/@common/api/handwrittenNoteApi'
import { routeName } from '~/@common/constants'
import { TOAST_STRING } from '~/@common/constants/strings'
import { useLearningTime } from '~/@common/hooks/useLearningTime'
import { useRepository } from '~/@common/hooks/useRepository'
import { errorHandlerService, WorksheetProblemNoteService } from '~/@common/services'
import modalService from '~/@common/services/modal.service'
import { commonRepo } from '~/@common/services/repo.service'
import ProblemScoringViewGuideOption from '~/@pages/@common/(ProblemScoring)/ProblemScoringViewGuideOption'
import 전체정답_전체삭제_제출 from '~/@pages/@common/(ProblemScoring)/전체정답_전체삭제_제출'
import { QuickScoringTemplate } from '~/@pages/@common/(QuickScoring)/QuickScoring.template'
import { openUnavailableAlertMessageModal } from '~/@pages/@widgets/(Worksheet)/접근권한모달'
import { ScoringSubmitConfirmModal } from '~/@pages/student/@widgets/StudentWorksheetScoring/ScoringSubmitConfirmModal'
import { useLearningProcessReference } from '~/@pages/student/learning-process/@common/hooks/useGetLearningProcessReference'

import { StudentWorksheetDetailPageService } from '../@service/StudentWorksheetDetailPage.service'
import { StudentWorksheetHeader } from '../@widgets/StudentWorksheetHeader'
import { alertClearAllAnswerModal } from './@widgets/alertClearAllAnswerModal'
import {
  alertWorksheetExceptionErrorModal,
  ExceptionModal,
} from './alertWorksheetExceptionErrorModal'

const StudentWorksheetScoringPage = observer(() => {
  useLearningTime()
  const { studentWorksheetId } = useParams<{ studentWorksheetId: string }>()

  // TODO: /student-worksheet 나갈 때 dynamicRepo.del 시킬 수 있도록, student-worksheet을 감싸는 페이지 컴포넌트 신설 필요
  const service = useRepository([StudentWorksheetDetailPageService, studentWorksheetId!])

  const noteService = useRepository(WorksheetProblemNoteService)
  const navigate = useNavigate()

  const referenceParam = useLearningProcessReference()

  const confirmToSubmitAnswers = async () => {
    modalService.openModal(
      <ScoringSubmitConfirmModal
        onClick={async () => {
          if (!studentWorksheetId) {
            return
          }
          try {
            modalService.showLoader()
            modalService.closeModal()
            const problemIds = service.problemScoringColl?.toScoredArr.map((item) => item.id)

            try {
              await noteService.uploadNotes(
                Number(studentWorksheetId),
                problemIds,
                HandwrittenNoteType.STUDENT_WORKSHEET_SCORING,
              )
              window.freshpaint?.track('필기 제출', { ...referenceParam })
            } catch (err) {
              errorHandlerService.handle(err, {
                message: TOAST_STRING.saveHandwrittenNoteFailed,
                useRemoteLogging: true,
              })
            }

            await service.onSubmitWorksheetProblems(studentWorksheetId)
          } catch (error) {
            if (error instanceof Error) {
              // CASE: 학습지가 삭제되거나 수정된 경우
              if (
                error.message === 'WORKSHEET_ALREADY_DELETED' ||
                error.message === 'STUDENT_WORKSHEET_NOT_FOUND'
              ) {
                alertWorksheetExceptionErrorModal()
              } else if (error.message === 'WORKSHEET_PERMISSION_DENIED') {
                openUnavailableAlertMessageModal()
              } else {
                // CASE: 학습지에 대한 접근 권한이 없을 때
                openUnavailableAlertMessageModal()
              }
            }
          } finally {
            modalService.hideLoader()
          }
        }}
      />,
      {
        modalName: 'ScoringSubmitConfirmModal',
      },
    )
  }

  useEffect(() => {
    ;(async () => {
      if (commonRepo.studentId && studentWorksheetId) {
        try {
          await studentWorksheetApi.postStudentCheat(commonRepo.studentId, studentWorksheetId)
        } catch (error) {
          if (error instanceof Error) {
            if (error.message === 'STUDENT_WORKSHEET_NOT_FOUND') {
              modalService.closeModal()
              modalService.openModal(<ExceptionModal />, {
                modalName: '삭제&수정_학습지_안내',
              })
            }
          }
        }
      }
    })()
  }, [studentWorksheetId])

  useEffect(() => {
    if (studentWorksheetId) {
      ;(async () => {
        try {
          await service.loadAndSetStudentWorksheetScorings(+studentWorksheetId)
        } catch (error) {
          if (error instanceof Error) {
            if (error.message === 'STUDENT_WORKSHEET_NOT_FOUND') {
              modalService.closeModal()
              modalService.openModal(<ExceptionModal />, {
                modalName: '삭제&수정_학습지_안내',
              })
            }
          }
        }
      })()
    }
  }, [service, studentWorksheetId])

  useEffect(() => {
    if (!studentWorksheetId) {
      navigate(routeName.student.studentWorksheet)
    }
  }, [studentWorksheetId])

  return (
    <S.StudentWorksheetScoringPage>
      <StudentWorksheetHeader title={service.worksheet?.title} />
      <ProblemScoringViewGuideOption viewOption={service.problemScoringViewOption} />
      <QuickScoringTemplate
        type="WORKSHEET"
        viewOption={service.problemScoringViewOption}
        problemScoringColl={service.problemScoringColl}
        score={service.studentWorksheet?.score ?? 0}
        scoring={{
          ...{ total: 0, correct: 0, incorrect: 0, unknown: 0, none: 0 },
          ...service.problemScoringColl?.gradingCount,
          total: service.problemScoringColl?.toArr.length || 0,
        }}
      />
      {!service.problemScoringViewOption?.채점완료_혹은_채점불가능 && (
        <전체정답_전체삭제_제출
          className="flex-end"
          onAllClearClick={() => {
            alertClearAllAnswerModal({
              onAllClear: () => {
                service.problemScoringColl?.onAllClear()
              },
            })
          }}
          onSubmitClick={() => {
            if (!service.problemScoringColl?.toScoredArr.length) {
              return
            }
            confirmToSubmitAnswers()
          }}
          submitTitle={
            service.problemScoringColl
              ? `${service.problemScoringColl?.toScoredArr.length}문제 제출`
              : ''
          }
          {...(!service.worksheet?.autoScorable && {
            onAllCorrectClick: () => {
              service.problemScoringColl?.onAllCheckCorrect()
            },
          })}
        />
      )}
    </S.StudentWorksheetScoringPage>
  )
})

export default StudentWorksheetScoringPage

const S = {
  StudentWorksheetScoringPage: styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
  `,
  StudentWorksheetScoringEmptyFooter: styled.div`
    flex: 0 0 48px;
  `,
}
