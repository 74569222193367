import { createBrowserRouter, Navigate, redirect } from 'react-router-dom'

import { routeName } from '~/@common/constants'
import ErrorBoundary from '~/@common/ui/AsyncBoundary/ErrorBoundary'
import ErrorFallback from '~/@common/ui/AsyncBoundary/ErrorFallback'
import { GuideVideoPage } from '~/@pages/guide-video/GuideVideo.page'
import { StudentWorksheetListPage } from '~/@pages/student/student-worksheet/StudentWorksheetList.page'

import CBTRoute from './(Route)/CBTRoute'
import ProtectedRoute from './(Route)/ProtectedRoute'

export interface RouteHandle {
  ignoreMobileContainerPadding?: boolean // breakpoints 기준 mobile이면 <Container />에 padding: 0 지정
}

import LoginPage from '~/@pages/login/Login.page.tsx'
import ProfilePage from '~/@pages/profile'
import QRPage from '~/@pages/qr/QR.page'
import ServerMaintenance from '~/@pages/serverMaintenance'
import ChallengeEntry from '~/@pages/student/challenge/entry.tsx'
import ConceptLearningPage from '~/@pages/student/concept-learning/ConceptLearning.page'
import ConceptualLearningPage from '~/@pages/student/conceptual-learning/ConceptualLearning.page'
import LearningProcess from '~/@pages/student/learning-process/LearningProcess.page'
import LectureDetailPage from '~/@pages/student/lecture/$lectureId/LectureDetail.page'
import LectureListPage from '~/@pages/student/lecture/LectureList.page'
import SignatureWorkbookLectureListPage from '~/@pages/student/lecture/signature-workbook/$curriculumKey/SignatureWorkbookLectureList.page'
import StudentExamByIdScoringPage from '~/@pages/student/student-exam/$studentExamId/scoring/StudentExamByIdScoring.page'
import StudentHomePage from '~/@pages/student/student-home/StudentHome.page'
import StudentWorkbookDetailPage from '~/@pages/student/student-workbook/detail/StudentWorkbookDetail.page'
import StudentWorkbookListPage from '~/@pages/student/student-workbook/list/StudentWorkbookList.page'
import StudentWorkbookViewerPage from '~/@pages/student/student-workbook/viewer/StudentWorkbookViewer.page'
import StudentWorksheetScoringPage from '~/@pages/student/student-worksheet/$studentWorksheetId/scoring/StudentWorksheetScoring.page'
import StudentWorksheetDetailPage from '~/@pages/student/student-worksheet/$studentWorksheetId/StudentWorksheetDetail.page'
import DraggableTestPage from '~/@pages/test/DraggableTest.page'
import WorksheetScoringPage from '~/@pages/worksheet/$worksheetId/$dateTime/scoring/WorksheetScoring.page'

import App from './App'

// type issue: https://github.com/remix-run/react-router/issues/10787
export const appRouter: ReturnType<typeof createBrowserRouter> = createBrowserRouter(
  [
    {
      element: <App />,
      ErrorBoundary: () => <ErrorBoundary FallbackComponent={ErrorFallback} />,
      loader: ({ request }) => {
        const url = new URL(request.url)
        const redirectURI = url.searchParams.get('redirectURI')
        if (redirectURI && redirectURI.startsWith('/')) {
          return redirect(redirectURI)
        }
        return null
      },
      children: [
        {
          path: 'lecture/:lectureId',
          loader: ({ params }) => {
            return redirect(`/student/lecture/${params.lectureId}`)
          },
        },
        {
          path: routeName.login,
          element: (
            <CBTRoute>
              <LoginPage />
            </CBTRoute>
          ),
        },
        {
          path: routeName.worksheet.scoring,
          element: (
            <ProtectedRoute>
              <WorksheetScoringPage />
            </ProtectedRoute>
          ),
          handle: {
            ignoreMobileContainerPadding: true,
          },
        },
        {
          path: routeName.qr,
          element: (
            <ProtectedRoute>
              <QRPage />
            </ProtectedRoute>
          ),
        },
        {
          path: routeName.guideVideo,
          element: (
            <ProtectedRoute>
              <GuideVideoPage />
            </ProtectedRoute>
          ),
        },
        {
          path: '/auth/*',
          element: (
            <ProtectedRoute>
              <Navigate to={routeName.student.defaultPath} />
            </ProtectedRoute>
          ),
        },
        {
          path: routeName.student.defaultPath,
          element: (
            <ProtectedRoute>
              <StudentHomePage />
            </ProtectedRoute>
          ),
          handle: {
            ignoreMobileContainerPadding: true,
          },
        },
        {
          path: routeName.student.studentWorksheet,
          element: (
            <ProtectedRoute>
              <StudentWorksheetListPage />
            </ProtectedRoute>
          ),
          handle: {
            ignoreMobileContainerPadding: true,
          },
        },
        {
          path: routeName.student.studentWorksheetById,
          element: (
            <ProtectedRoute>
              <StudentWorksheetDetailPage />
            </ProtectedRoute>
          ),
        },
        {
          path: routeName.student.studentWorksheetScoringById,
          element: (
            <ProtectedRoute>
              <StudentWorksheetScoringPage />
            </ProtectedRoute>
          ),
          handle: {
            ignoreMobileContainerPadding: true,
          },
        },
        {
          path: routeName.student.studentExamScoringById,
          element: (
            <ProtectedRoute>
              <StudentExamByIdScoringPage />
            </ProtectedRoute>
          ),
          handle: {
            ignoreMobileContainerPadding: true,
          },
        },
        {
          path: routeName.student.challenge,
          element: (
            <ProtectedRoute>
              <ChallengeEntry />
            </ProtectedRoute>
          ),
          handle: {
            ignoreMobileContainerPadding: true,
          },
        },
        {
          path: routeName.student.studentWorkbook,
          children: [
            {
              index: true,
              element: (
                <ProtectedRoute>
                  <Navigate to={routeName.student.generalStudentWorkbookList} replace />
                </ProtectedRoute>
              ),
            },
            {
              path: routeName.student.studentWorkbookList,
              element: (
                <ProtectedRoute>
                  <StudentWorkbookListPage />
                </ProtectedRoute>
              ),
              handle: {
                ignoreMobileContainerPadding: true,
              },
            },
            {
              path: routeName.student.studentWorkbookDetail,
              element: (
                <ProtectedRoute>
                  <StudentWorkbookDetailPage />
                </ProtectedRoute>
              ),
              handle: {
                ignoreMobileContainerPadding: true,
              },
            },
            {
              path: routeName.student.studentWorkbookViewer,
              element: (
                <ProtectedRoute>
                  <StudentWorkbookViewerPage />
                </ProtectedRoute>
              ),
              handle: {
                ignoreMobileContainerPadding: true,
              },
            },
            {
              path: '*',
              element: (
                <ProtectedRoute>
                  <Navigate to={routeName.student.generalStudentWorkbookList} replace />
                </ProtectedRoute>
              ),
            },
          ],
        },
        {
          path: routeName.student.lecture,
          children: [
            { index: true, element: <Navigate to={routeName.student.lectureList} /> },
            {
              path: routeName.student.lectureList,
              element: (
                <ProtectedRoute>
                  <LectureListPage />
                </ProtectedRoute>
              ),
            },
            {
              path: routeName.student.lectureDetail,
              element: (
                <ProtectedRoute>
                  <LectureDetailPage />
                </ProtectedRoute>
              ),
              handle: {
                ignoreMobileContainerPadding: true,
              },
            },
            {
              path: routeName.student.signatureWorkbookLectureList,
              element: (
                <ProtectedRoute>
                  <SignatureWorkbookLectureListPage />
                </ProtectedRoute>
              ),
            },
          ],
        },
        {
          path: routeName.student.conceptLearning, // 삭제 예정 (진입 시 conceptualLearning으로 redirect)
          element: (
            <ProtectedRoute>
              <ConceptLearningPage />
            </ProtectedRoute>
          ),
          handle: {
            ignoreMobileContainerPadding: true,
          },
        },
        {
          path: routeName.student.conceptualLearning,
          element: (
            <ProtectedRoute>
              <ConceptualLearningPage />
            </ProtectedRoute>
          ),
          handle: {
            ignoreMobileContainerPadding: true,
          },
        },
        {
          path: routeName.profile,
          element: (
            <ProtectedRoute>
              <ProfilePage />
            </ProtectedRoute>
          ),
        },
        {
          path: routeName.studentLearningProcess,
          element: (
            <ProtectedRoute>
              <LearningProcess />
            </ProtectedRoute>
          ),
          handle: {
            ignoreMobileContainerPadding: true,
          },
        },
        {
          path: routeName.serverMaintenance,
          element: <ServerMaintenance />,
        },
        {
          path: '/test/draggable',
          element: <DraggableTestPage />,
        },
      ],
    },
  ],
  {
    future: {
      v7_normalizeFormMethod: true,
      v7_fetcherPersist: true,
    },
  },
)

export type AppRouter = typeof appRouter
