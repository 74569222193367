import { decodeSync } from '@effect/schema/Parser'
import { css } from '@emotion/react'
import { ConceptChipDomain } from '@mathflat/domain/@entities/ConceptChip/domain.ts'
import type { Entity } from '@mathflat/domain/@entities/ConceptChip/dto'
import { observer } from 'mobx-react'
import type { FC, MouseEvent } from 'react'

import { colors } from '~/@common/styles'
import type { DefaultProps } from '~/@common/types'
import { Icon } from '~/@common/ui/Icon/Icon'
import ConceptChipAchievementLevel from '~/@pages/@common/(ConceptChip)/ConceptChipAchievmentLevel/ConceptChipAchievementLevel.tsx'
import ConceptChipDifficultyLevel from '~/@pages/@common/(ConceptChip)/ConceptChipDifficultyLevel/ConceptChipDifficultyLevel.tsx'

type Props = {
  size?: 'normal' | 'small' | number
  tagName?: 'div' | 'li'
  item: Pick<Entity.ConceptChip, 'levelOfAchievement' | 'levelOfConceptChip' | 'conceptName'>
  isShowArrow?: boolean
  textColor?: string
  onClick?: (e: MouseEvent<HTMLButtonElement>) => void
} & DefaultProps

const { LevelOfAchievement, LevelOfDifficulty } = ConceptChipDomain

const ConceptChip: FC<Props> = ({
  size = 'normal',
  tagName = 'div',
  item,
  isShowArrow = true,
  className,
  textColor = colors.black,
  onClick,
}) => {
  let height = 58

  if (typeof size === 'number') {
    height = size
  } else if (size === 'normal') {
    height = 70
  }
  const Comp = tagName

  return (
    <Comp css={_Style} style={{ height: `${height}px` }} data-component="ms__ConceptChip">
      <button
        type="button"
        className={className}
        css={_ButtonStyle}
        style={{
          cursor: onClick ? 'pointer' : 'default',
          color: textColor,
        }}
        onClick={(e) => {
          e.preventDefault()
          onClick && onClick(e)
        }}
      >
        <ConceptChipAchievementLevel
          level={decodeSync(LevelOfAchievement)(item.levelOfAchievement)}
        />
        <ConceptChipDifficultyLevel
          level={decodeSync(LevelOfDifficulty)(item.levelOfConceptChip)}
          className="difficulty-level"
        />
        <p className="concept-name">{item.conceptName}</p>
        {isShowArrow && <Icon name="icon_chevron_right" size={16} className="arrow-icon" />}
      </button>
    </Comp>
  )
}

export default observer(ConceptChip)

const _ButtonStyle = css`
  display: flex;
  height: 100%;
  width: 100%;
  align-items: center;
  gap: 4px;
  background-color: ${colors.white};
  color: ${colors.black};
  padding: 0 20px;
`

const _Style = css`
  .difficulty-level {
    margin-right: 6px;
    flex-shrink: 0;
  }
  .concept-name {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
  .arrow-icon {
    margin-left: auto;
  }
`
