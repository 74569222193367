import type { SchoolType } from '~/@entities/(SchoolSystem)/schema.ts'
import type { StudentDomain } from '~/@entities/Student/domain.ts'

import type { Entity as ParentEntity } from '../Parent/dto'

export namespace Entity {
  export class Student {
    id: StudentDomain.Id
    loginId?: string
    name?: string
    schoolType?: SchoolType
    schoolName?: string
    grade?: 1 | 2 | 3 | 4 | 5 | 6
    email?: string
    address?: string
    phone?: string
    homePhone?: string
    memo?: string
    status?: 'ACTIVE' | 'INACTIVE' | 'DELETED'
    attendanceCode?: number
    parent?: Pick<ParentEntity.Parent, 'id' | 'loginId' | 'phone'>
    siblings?: Pick<Student, 'id' | 'name' | 'schoolType' | 'grade'>[]
    appSetting?: StudentAppSetting

    constructor(params: Partial<Student> & { id: Student['id'] }) {
      Object.assign(this, params)
      this.id = params.id
      if (params.appSetting) {
        this.appSetting = new StudentAppSetting(params.appSetting)
      }
    }
  }

  export class StudentAppSetting {
    일반채점_채점전정답공개 = false
    채점후정답해설공개 = false
    일반채점_채점전문풀동공개 = false
    채점후문풀동공개 = false
    lectureService = false
    개념힌트_사용 = false
    풀이분석_사용 = false
    풀이확인_사용 = false

    constructor(params?: Partial<StudentAppSetting>) {
      Object.assign(this, params)
    }
  }
}
