import styled from '@emotion/styled'
import clsx from 'clsx'
import { observer } from 'mobx-react'

import { useStudentAppMediaQuery } from '~/@common/hooks/useMediaQuery'
import { useRepository } from '~/@common/hooks/useRepository'

import { StudentWorkbookViewerService } from '../@service/StudentWorkbookViewer.service'
import StudentWorkbookViewerContent from './StudentWorkbookViewerContent'
import StudentWorkbookViewerScoring from './StudentWorkbookViewerScoring'

const StudentWorkbookViewerBody = () => {
  const service = useRepository(StudentWorkbookViewerService)
  const { isScoringMode } = service
  const { isMobile } = useStudentAppMediaQuery()
  const isShowScoring = !isMobile || isScoringMode

  return (
    <S.Container className={clsx(isShowScoring ? 'scoring' : 'viewer')}>
      {isShowScoring && <StudentWorkbookViewerScoring />}
      <StudentWorkbookViewerContent />
    </S.Container>
  )
}

export default observer(StudentWorkbookViewerBody)

export const S = {
  Container: styled.div`
    display: flex;
    height: 100%;
    gap: 23px;
  `,
}
