import styled from '@emotion/styled'

import { colors, typo } from '~/@common/styles'
import { mediaQuery } from '~/@common/styles/mediaQuery'

const S = {
  StudentWorkbookDetailPage: styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;

    height: 100%;
    .problem-card-grid-container {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      overflow-y: auto;
    }
    .problem-card-grid-footer {
      margin-left: auto;
    }

    ${mediaQuery.underTablet} {
      gap: 0px;

      .problem-card-grid-container {
        justify-content: flex-start;
        padding-top: 20px;
      }
    }
  `,
  PageList: styled.div`
    display: flex;
    flex-direction: column;

    width: 384px;
    height: 378px;

    padding: 0 22px 18px;
    overflow: auto;
    .page-list-item + .page-list-item {
      margin-top: 10px;
    }
    ${mediaQuery.underTablet} {
      width: 100%;
      height: 100%;
      padding: 0;
      .page-list-item {
        padding: 20px 24px;
      }
      .page-list-item + .page-list-item {
        margin-top: 0px;
        border-top: 1px solid ${colors.gray.$100};
      }
    }
  `,
  PageListTitle: styled.strong`
    flex: 0 0 58px;
    display: flex;
    align-items: center;

    color: ${colors.gray.$900};
    &.no-title {
      flex: 0 0 20px;
    }
    ${mediaQuery.underTablet} {
      flex-basis: 38px;
      padding: 0 24px;

      background-color: ${colors.gray.$100};

      color: ${colors.gray.$800};
      ${typo.caption01};
      font-weight: bold;
    }
  `,
  PageListItem: styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex: 0 0 54px;
    padding: 0px 14px;

    border-radius: 12px;
    background: ${colors.white};
    box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.05);
    cursor: pointer;
    > .left {
      display: flex;
      flex: auto;
      gap: 12px;
      .page-list-item-icon-wrapper {
        width: 40px;
        height: 40px;
        border-radius: 12px;
        background: #fafafa;
      }
      .page-list-item-page-number-text {
        display: flex;
        align-items: center;
        color: ${colors.gray.$500};
        &.COMPLETE,
        &.PROGRESS {
          color: ${colors.gray.$900};
        }

        ${mediaQuery.underTablet} {
          color: ${colors.gray.$800};
          &.COMPLETE,
          &.PROGRESS {
            color: ${colors.gray.$500};
          }
        }
      }
    }
    > .right {
      display: flex;
      align-items: center;
      flex: 0 0 20px;
      .page-list-item-icon-wraper {
        display: flex;
        align-items: center;
        white-space: nowrap;
        display: none;
      }
    }

    ${mediaQuery.underTablet} {
      box-shadow: none;
      border-radius: 0;
      > .left .page-list-item-icon-wraper {
        display: none;
      }
      > .right {
        display: flex;
        gap: 10px;
        .page-list-item-icon-wraper {
          display: flex;
          gap: 4px;
          color: ${colors.gray.$500};
        }

        .right-icon {
          color: ${colors.gray.$800};
        }
      }
    }
  `,
}

export default S
