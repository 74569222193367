import styled from '@emotion/styled'

export const S = {
  Container: styled.div`
    display: flex;
    align-items: center;
    gap: 4px;

    span {
      min-width: fit-content;
    }
  `,
}
