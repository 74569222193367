import type { SchoolType } from '@mathflat/domain/@entities/(SchoolSystem)/schema'
import type {
  Accessible,
  GradeI,
  LevelI,
  WorksheetI,
} from '@mathflat/domain/@entities/StudentWorksheet/api2'

export class Worksheet implements WorksheetI {
  id: number
  type: 'CUSTOM' | 'EXAM'
  school: SchoolType
  grade: GradeI
  title: string
  titleTag: string
  chapter: string
  tag: 'ETC'
  problemCount: number
  level: LevelI
  autoScorable: boolean
  accessModifierToStudent: Accessible

  constructor(worksheetFormResponse: WorksheetI) {
    this.id = worksheetFormResponse.id
    this.type = worksheetFormResponse.type
    this.school = worksheetFormResponse.school
    this.grade = worksheetFormResponse.grade
    this.title = worksheetFormResponse.title
    this.titleTag = worksheetFormResponse.titleTag
    this.chapter = worksheetFormResponse.chapter
    this.tag = worksheetFormResponse.tag
    this.problemCount = worksheetFormResponse.problemCount
    this.level = worksheetFormResponse.level
    this.autoScorable = worksheetFormResponse.autoScorable
    this.accessModifierToStudent = worksheetFormResponse.accessModifierToStudent
  }
}
