import React from 'react'
import { useNavigate } from 'react-router'

import { routeName } from '~/@common/constants'
import modalService from '~/@common/services/modal.service'
import Modal from '~/@common/ui/modal/Modal'

export const 접근권한모달 = () => {
  const navigate = useNavigate()

  return (
    <Modal.Alert
      confirm={{
        style: {
          padding: '10px',
        },
        onClick: () => {
          modalService.closeModal()
          navigate(routeName.student.defaultPath, { replace: true })
        },
      }}
    >
      <p>
        해당 학습지에
        <br />
        접근 권한이 없습니다.
      </p>
    </Modal.Alert>
  )
}

export const openUnavailableAlertMessageModal = () => {
  modalService.closeModal()
  modalService.openModal(<접근권한모달 />, {
    modalName: 'unavailableWorksheet',
  })
}
