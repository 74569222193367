import styled from '@emotion/styled'
import { format } from 'date-fns'

import { colors, typo } from '~/@common/styles'

import type { PlayerTime } from '../MathflatPlayer'

type Props = {
  playerTime: PlayerTime
}

const TimeDisplay = ({ playerTime }: Props) => {
  return (
    <S.TimeDisplay>
      <TimeDisplay.Current playerTime={playerTime} />
      <TimeDisplay.Total playerTime={playerTime} />
    </S.TimeDisplay>
  )
}

const Current = ({ playerTime }: Props) => {
  return (
    <S.CurrentTime className="current-time">
      {format(playerTime.current * 1000, 'mm:ss')}
    </S.CurrentTime>
  )
}
TimeDisplay.Current = Current

const Total = ({ playerTime }: Props) => (
  <S.TotalTime className="total-time">{format(playerTime.total * 1000, 'mm:ss')}</S.TotalTime>
)
TimeDisplay.Total = Total

const S = {
  TimeDisplay: styled.div`
    display: flex;

    .total-time {
      &::before {
        content: '';
        display: flex;
        width: 1px;
        height: 11px;
        margin: 0 4px;
        background: ${colors.gray.$500};
      }
    }
  `,
  CurrentTime: styled.p`
    display: flex;
    align-items: center;
    color: #fff;
    ${typo.body02};
  `,
  TotalTime: styled.p`
    display: flex;
    align-items: center;
    color: ${colors.gray.$500};
    ${typo.body02};
  `,
}

export default TimeDisplay
