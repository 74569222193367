import qs from 'qs'
import { useEffect } from 'react'
import { generatePath, useNavigate } from 'react-router'

import { routeName } from '~/@common/constants'
import { localStorageService } from '~/@common/services/storage.service'

export const useExternalLink = () => {
  const navigate = useNavigate()
  useEffect(() => {
    if (window) {
      // @ts-ignore
      window.mathflat = {
        isCBT: true,
        goToQRScoring(e) {
          const worksheetId = e.detail?.worksheetId
          const dateTime = e.detail?.dateTime
          localStorageService.isInitialPasswordClear()
          if (worksheetId && dateTime) {
            navigate(`worksheet/${worksheetId}/${dateTime}/scoring?size=all`)
          }
        },
        goToLecture(e) {
          const lectureId = e.detail?.videoId
          localStorageService.isInitialPasswordClear()
          if (lectureId) {
            navigate(
              `${generatePath(routeName.student.lectureDetail, { lectureId })}?hideList=true`,
            )
          }
        },
        goToSignatureLecture(e) {
          const signatureType = e.detail?.signatureType
          const curriculumKey = e.detail?.curriculumKey
          localStorageService.isInitialPasswordClear()
          if (signatureType && curriculumKey) {
            const params = qs.stringify({ curriculumKey }, { addQueryPrefix: true })
            navigate(routeName.student.signatureWorkbookLectureList + params)
          }
        },
      }
    }
  }, [])
}
