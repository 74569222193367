import styled from '@emotion/styled'
import { observer } from 'mobx-react'
import { useNavigate, useParams } from 'react-router'
import { Link, useSearchParams } from 'react-router-dom'

import { HandwrittenNoteType } from '~/@common/api/handwrittenNoteApi'
import { routeName } from '~/@common/constants'
import { TOAST_STRING } from '~/@common/constants/strings'
import { useRepository } from '~/@common/hooks/useRepository'
import { errorHandlerService, WorksheetProblemNoteService } from '~/@common/services'
import { useLastLocationManager } from '~/@common/services/(LastLocationManager)'
import modalService from '~/@common/services/modal.service'
import { colors, typo } from '~/@common/styles'
import { mediaQuery } from '~/@common/styles/mediaQuery'
import { Icon } from '~/@common/ui/Icon/Icon'
import { PortalRootLayoutHeader } from '~/@common/ui/PortalRootLayoutHeader'
import GlobalHeader from '~/@pages/@widgets/(Navigation)/GlobalHeader/GlobalHeader'
import { StudentLearningContentBackConfirmModal } from '~/@pages/student/@widgets/learningContent/StudentLearningContentBackConfirmModal'
import { useLearningProcessReference } from '~/@pages/student/learning-process/@common/hooks/useGetLearningProcessReference'

import { StudentExamService } from '../@service/StudentExamPage.service'

export const StudentExamPageHeader = observer(() => {
  const { studentExamId } = useParams<{ studentExamId: string }>()
  const [searchParams] = useSearchParams()

  const locationManager = useLastLocationManager()
  const navigate = useNavigate()

  const studentExamService = useRepository(StudentExamService)
  const noteService = useRepository(WorksheetProblemNoteService)
  const referenceParam = useLearningProcessReference()

  const title = searchParams.get('title')
  const scoringSize = searchParams.get('size') as 'all' | 'one'

  const defaultPrevRouteName = routeName.student.studentWorksheet

  const navigateBack = () => {
    if (!locationManager.prevState?.location.pathname) {
      navigate(defaultPrevRouteName)
      return
    }
    navigate(-1)
  }

  if (!studentExamId) return null

  return (
    <PortalRootLayoutHeader showMAATTImer>
      <GlobalHeader
        SlotCenter={title}
        SlotRight={
          scoringSize === 'all' ? (
            <Link
              to={{
                search: `?title=${title}&size=one`,
              }}
            >
              <RightLink>
                <Icon name="icon_description_paper" size={20} color={colors.gray.$900} />
                <span>한문제씩</span>
              </RightLink>
            </Link>
          ) : (
            <Link
              to={{
                search: `?title=${title}&size=all`,
              }}
            >
              <RightLink>
                <Icon name="icon_list" size={20} color={colors.gray.$900} />
                <span>빠른채점</span>
              </RightLink>
            </Link>
          )
        }
        prevLinkProps={{
          to: defaultPrevRouteName,
          onClick: (e) => {
            e.preventDefault()
            if (studentExamService.problemScoringColl?.toScoredArr.length) {
              modalService.openModal(
                <StudentLearningContentBackConfirmModal
                  content={
                    <>
                      답안을 제출하지 않고 화면을
                      <br />
                      이동하시겠습니까?
                      <br />
                      그대로 이동해도 입력한 답안은 <br />
                      사라지지 않습니다.
                    </>
                  }
                  defaultPrevRouteName={defaultPrevRouteName}
                  onSubmit={async () => {
                    if (!studentExamId) {
                      return
                    }
                    try {
                      modalService.showLoader()
                      modalService.closeModal()

                      const examProblemIds = studentExamService.problemScoringColl?.toScoredArr.map(
                        (item) => item.id,
                      )

                      try {
                        await noteService.uploadNotes(
                          Number(studentExamId),
                          examProblemIds,
                          HandwrittenNoteType.STUDENT_EXAM_SCORING,
                        )
                        window.freshpaint?.track('필기 제출', { ...referenceParam })
                      } catch (err) {
                        errorHandlerService.handle(err, {
                          message: TOAST_STRING.saveHandwrittenNoteFailed,
                          useRemoteLogging: true,
                        })
                      }

                      await studentExamService.submitAutoScoringExamProblems(studentExamId)
                    } catch (error) {
                      errorHandlerService.handle(error)
                    } finally {
                      modalService.hideLoader()
                    }
                  }}
                />,
                {
                  modalName: '답안제출_안내',
                },
              )
              return
            }
            navigateBack()
          },
        }}
      />
    </PortalRootLayoutHeader>
  )
})

const RightLink = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2px;
  ${typo.caption01};
  color: ${colors.gray.$900};
  font-weight: bold;

  cursor: pointer;
  ${mediaQuery.underDesktop} {
    ${typo.body02};
    font-weight: normal;
    color: ${colors.gray.$600};
    svg {
      color: ${colors.gray.$600};
    }
  }
`
