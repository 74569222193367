import React, { type ComponentPropsWithoutRef, useCallback, useMemo } from 'react'

import CheckboxInput from '../CheckboxInput'
import {
  CheckboxGroupConsumerName,
  type CheckboxGroupInputValue,
  useCheckboxGroupContext,
} from './CheckboxGroup.context'

export type CheckboxGroupInputProps = Omit<
  ComponentPropsWithoutRef<'input'>,
  'onChange' | 'value' | 'min' | 'max'
> & { value: CheckboxGroupInputValue }

export const CheckboxGroupInput = React.forwardRef<HTMLInputElement, CheckboxGroupInputProps>(
  ({ value, ...props }, ref) => {
    const _value = String(value)
    const context = useCheckboxGroupContext(CheckboxGroupConsumerName)
    const isChecked = useMemo(() => {
      return props.checked || context?.values.includes(_value)
    }, [_value, context])

    const handleChange = useCallback(
      (e: React.ChangeEvent<HTMLInputElement>) => {
        if (context) {
          if (
            isChecked &&
            (context.min === undefined || context.values.length - 1 >= context.min)
          ) {
            const nextState = context.values.filter((v) => v !== _value) ?? []
            context?.onChange?.(nextState, e)
            return
          }
          if (
            !isChecked &&
            (context.max === undefined || context.values.length + 1 <= context.max)
          ) {
            context?.onChange?.([...context.values, _value], e)
            return
          }
        }
      },
      [isChecked, context],
    )
    return (
      <CheckboxInput
        {...props}
        value={_value}
        className={`${CheckboxGroupConsumerName}-input`}
        name={context?.name}
        checked={isChecked}
        ref={ref}
        onChange={handleChange}
      />
    )
  },
)
CheckboxGroupInput.displayName = 'CheckboxGroupInput'
