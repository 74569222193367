const S3URL = `https://frontend-assets.mathflat.com`

export const s3URL = {
  student(url: string) {
    return `${S3URL}/student/${url}`
  },
  teacher(url: string) {
    return `${S3URL}/teacher/${url}`
  },
  common(url: string) {
    return `${S3URL}/common/${url}`
  },
  homepage(url: string) {
    return `${S3URL}/homepage/${url}`
  },
}
