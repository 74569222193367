import { observer } from 'mobx-react'

import type { ProblemScoringColl } from '~/@pages/@common/(ProblemScoring)/@trait/ProblemScoring.trait'
import type { ProblemScoringViewOption } from '~/@pages/@common/(ProblemScoring)/@trait/ProblemScoringViewOption.trait'
import ProblemScoringViewGuideOption from '~/@pages/@common/(ProblemScoring)/ProblemScoringViewGuideOption'
import 전체정답_전체삭제_제출 from '~/@pages/@common/(ProblemScoring)/전체정답_전체삭제_제출'
import { QuickScoringTemplate } from '~/@pages/@common/(QuickScoring)/QuickScoring.template'
import { alertClearAllAnswerModal } from '~/@pages/student/student-worksheet/$studentWorksheetId/scoring/@widgets/alertClearAllAnswerModal'

interface Props {
  problemScoringColl: ProblemScoringColl<'WORKSHEET'>
  problemScoringViewOption: ProblemScoringViewOption<'NOT_학습모듈'>
  openConfirmToSubmitAnswersModal: () => void
  gradingCount: ProblemScoringColl<'WORKSHEET'>['gradingCount']
  clearAllAnswer: () => void
}

export const WorksheetQuickScoring = observer(
  ({
    problemScoringColl,
    problemScoringViewOption,
    gradingCount,
    clearAllAnswer,
    openConfirmToSubmitAnswersModal,
  }: Props) => {
    const alertClearAllAnswer = () => {
      alertClearAllAnswerModal({
        onAllClear() {
          clearAllAnswer()
        },
      })
    }

    return (
      <>
        {problemScoringViewOption && (
          <ProblemScoringViewGuideOption
            viewOption={problemScoringViewOption}
            guideDisabled={problemScoringViewOption.content.type === 'MAAT'}
          />
        )}
        <QuickScoringTemplate
          type="WORKSHEET"
          viewOption={problemScoringViewOption}
          problemScoringColl={problemScoringColl}
          score={0}
          scoring={{
            ...gradingCount,
            total: problemScoringColl?.toArr.length || 0,
          }}
        />
        {!problemScoringViewOption.채점완료_혹은_채점불가능 && (
          <전체정답_전체삭제_제출
            className="flex-end"
            onAllClearClick={alertClearAllAnswer}
            onSubmitClick={() => {
              if (!problemScoringColl.toScoredArr.length) return
              openConfirmToSubmitAnswersModal()
            }}
            submitTitle={`${problemScoringColl.toScoredArr.length}문제 제출`}
          />
        )}
      </>
    )
  },
)
