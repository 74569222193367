import { observer } from 'mobx-react'
import React, { useEffect, useState } from 'react'

import { useRepository } from '~/@common/hooks/useRepository'

import { LearningProcessService } from '../../../service/LearningProcess.service'
import ResultView from './ResultView'
import WrongLearningView from './WrongLearningView'
import { AiTutorService } from '~/@common/services/AiTutor.service.ts'

const 오답유형학습 = () => {
  const [isVideoTab, setIsVideoTab] = useState(true)
  const [isOpenResultTable, setIsOpenResultTable] = useState(false)

  const service = useRepository(LearningProcessService)
  const aiTutorService = useRepository(AiTutorService)

  useEffect(() => {
    if (service.relationId) {
      service.initWrongConceptSelfLearning()
    }
  }, [service, service.relationId])

  useEffect(() => {
    return () => aiTutorService.close()
  }, [])

  useEffect(() => {
    setIsOpenResultTable(service.isAllScoredPairSimilarWorksheet)
  }, [service.isAllScoredPairSimilarWorksheet])

  if (!service.오답유형학습_리스트.length) return null

  return (
    <>
      {!isOpenResultTable ? (
        <WrongLearningView
          isVideoTab={isVideoTab}
          setIsVideoTab={setIsVideoTab}
          setIsOpenResultTable={setIsOpenResultTable}
        />
      ) : (
        <ResultView setIsOpenResultTable={setIsOpenResultTable} />
      )}
    </>
  )
}

export default observer(오답유형학습)
