import * as S from '@effect/schema/Schema'

export const CONTENT_STATUS = {
  전체채점: 'COMPLETE',
  일부채점: 'PROGRESS',
  채점전: 'INCOMPLETE',
} as const

export const CONTENT_STATUS_KO = {
  COMPLETE: '학습완료',
  PROGRESS: '풀이중',
  INCOMPLETE: '학습가능',
} as const
export const MAAT_CONTENT_STATUS_KO = {
  COMPLETE: '응시완료',
  PROGRESS: '응시중',
  INCOMPLETE: '응시가능',
} as const

export const ContentLevel = S.literal(1, 2, 3, 4, 5)
export type ContentLevel = S.Schema.To<typeof ContentLevel>
