import type { ReactNode } from 'react'

import { Drawer } from '~/@common/ui/(Drawer)/BaseDrawer'

import { mobileSizeDrawerContentStyles } from '.'

export const SelfLearningGroupInnerDrawerContent = ({ children }: { children: ReactNode }) => {
  return (
    <Drawer.Content>
      <Drawer.Header>보충학습</Drawer.Header>
      <Drawer.Body css={mobileSizeDrawerContentStyles}>{children}</Drawer.Body>
    </Drawer.Content>
  )
}
