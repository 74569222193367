import { PIXEL_RATIO } from '../constants'
import type { Point, RawPoint } from '../types'
import type { PathStyle } from './handwrittenNote.types'
import { PathType } from './service/HandwrittenNotePathItem'

/* 
  Smooth a Svg path with cubic bezier curves
  https://francoisromain.medium.com/smooth-a-svg-path-with-cubic-bezier-curves-e37b49d46c74
  https://codepen.io/francoisromain/pen/dzoZZj
 */
const SMOOTHING_FACTOR = 0.2

const getLineData = (pointA: RawPoint, pointB: RawPoint) => {
  const lengthX = pointB[0] - pointA[0]
  const lengthY = pointB[1] - pointA[1]
  return {
    length: Math.sqrt(Math.pow(lengthX, 2) + Math.pow(lengthY, 2)),
    angle: Math.atan2(lengthY, lengthX),
  }
}

const getControlRawPoints = (
  current: RawPoint,
  previous: RawPoint,
  next: RawPoint,
  reverse?: boolean,
) => {
  const p = previous || current
  const n = next || current
  const o = getLineData(p, n)
  const angle = o.angle + (reverse ? Math.PI : 0)
  const length = o.length * SMOOTHING_FACTOR
  const x = current[0] + Math.cos(angle) * length
  const y = current[1] + Math.sin(angle) * length
  return [x, y]
}

const bezierFunc = (point: RawPoint, i: number, points: RawPoint[]) => {
  const cps = getControlRawPoints(points[i - 1], points[i - 2], point)
  const cpe = getControlRawPoints(point, points[i - 1], points[i + 1], true)
  return `C ${cps[0]},${cps[1]} ${cpe[0]},${cpe[1]} ${point[0]},${point[1]}`
}

export const getSvgPathDAttr = (
  points: RawPoint[],
  width: number,
  pathType: PathType,
  pathPixelRatio: number,
) => {
  const options: StrokeOptions = {
    size: width,
  }

  let pixelFactor = 1

  if (pathPixelRatio !== PIXEL_RATIO) {
    pixelFactor = PIXEL_RATIO / pathPixelRatio
  }

  if (pixelFactor && pixelFactor !== 1) {
    points = points.map((point) => [point[0] * pixelFactor, point[1] * pixelFactor])
  }

  let pathStyle: PathStyle = 'raw'

  if (pathPixelRatio === 1) {
    pathStyle = pathType === PathType.SubPixel ? 'soft' : 'ios-soft'
  }

  if (pathStyle === 'ios-soft') {
    points = points.map(([x, y]) => [Math.round(x), Math.round(y)])
    const strokePoints = getStrokePoints(points, options)
    points = strokePoints.map((item) => item.point as [number, number])
    return getSvgPathFromStroke(points, false)
  } else if (pathStyle === 'raw') {
    return points.reduce(
      (acc, point, i) =>
        i === 0 ? `M ${point[0]},${point[1]}` : `${acc} L ${point[0]},${point[1]}`,
      '',
    )
  } else {
    return points.reduce(
      (acc, point, i, a) =>
        i === 0 ? `M ${point[0]},${point[1]}` : `${acc} ${bezierFunc(point, i, a)}`,
      '',
    )
  }
}

export const pointToRawPoint = (point: Point, precision = 3): RawPoint => {
  const x = Number(point.x.toFixed(precision))
  const y = Number(point.y.toFixed(precision))
  return [x, y]
}

const average = (a: number, b: number) => (a + b) / 2

// https://github.com/steveruizok/perfect-freehand
const getSvgPathFromStroke = (points: number[][], closed = true) => {
  const len = points.length

  if (len < 4) {
    return ``
  }

  let a = points[0]
  let b = points[1]
  const c = points[2]

  let result = `M${a[0].toFixed(2)},${a[1].toFixed(2)} Q${b[0].toFixed(2)},${b[1].toFixed(
    2,
  )} ${average(b[0], c[0]).toFixed(2)},${average(b[1], c[1]).toFixed(2)} T`

  for (let i = 2, max = len - 1; i < max; i++) {
    a = points[i]
    b = points[i + 1]
    result += `${average(a[0], b[0]).toFixed(2)},${average(a[1], b[1]).toFixed(2)} `
  }

  if (closed) {
    result += 'Z'
  }

  return result
}

export interface StrokePoint {
  point: number[]
}

interface StrokeOptions {
  size: number
  last?: boolean
}

export function getStrokePoints(points: number[][], options = {} as StrokeOptions): StrokePoint[] {
  const { size, last: isComplete = false } = options

  // If we don't have any points, return an empty array.
  if (points.length === 0) return []

  // Find the interpolation level between points.
  const t = 0.15

  // Whatever the input is, make sure that the points are in number[][].
  let pts = points

  // Add extra points between the two, to help avoid "dash" lines
  // for strokes with tapered start and ends. Don't mutate the
  // input array!
  if (pts.length === 2) {
    const last = pts[1]
    pts = pts.slice(0, -1)
    for (let i = 1; i < 5; i++) {
      pts.push(lrp(pts[0], last, i / 4))
    }
  }

  // If there's only one point, add another point at a 1pt offset.
  // Don't mutate the input array!
  if (pts.length === 1) {
    pts = [...pts, [...add(pts[0], [1, 1]), ...pts[0].slice(2)]]
  }

  // The strokePoints array will hold the points for the stroke.
  // Start it out with the first point, which needs no adjustment.
  const strokePoints: StrokePoint[] = [
    {
      point: [pts[0][0], pts[0][1]],
    },
  ]

  // A flag to see whether we've already reached out minimum length
  let hasReachedMinimumLength = false

  // We use the runningLength to keep track of the total distance
  let runningLength = 0

  // We're set this to the latest point, so we can use it to calculate
  // the distance and vector of the next point.
  let prev = strokePoints[0]

  const max = pts.length - 1

  // Iterate through all of the points, creating StrokePoints.
  for (let i = 1; i < pts.length; i++) {
    const point =
      isComplete && i === max
        ? // If we're at the last point, and `options.last` is true,
          // then add the actual input point.
          pts[i].slice(0, 2)
        : // Otherwise, using the t calculated from the streamline
          // option, interpolate a new point between the previous
          // point the current point.
          lrp(prev.point, pts[i], t)

    // If the new point is the same as the previous point, skip ahead.
    if (isEqual(prev.point, point)) continue

    // How far is the new point from the previous point?
    const distance = dist(point, prev.point)

    // Add this distance to the total "running length" of the line.
    runningLength += distance

    // At the start of the line, we wait until the new point is a
    // certain distance away from the original point, to avoid noise
    if (i < max && !hasReachedMinimumLength) {
      if (runningLength < size) continue
      hasReachedMinimumLength = true
      // TODO: Backfill the missing points so that tapering works correctly.
    }
    // Create a new strokepoint (it will be the new "previous" one).
    prev = {
      // The adjusted point
      point,
    }

    // Push it to the strokePoints array.
    strokePoints.push(prev)
  }

  // Set the vector of the first point to be the same as the second point.
  // strokePoints[0].vector = strokePoints[1]?.vector || [0, 0]

  return strokePoints
}

/**
 * Add vectors.
 * @param A
 * @param B
 * @internal
 */
const add = (A: number[], B: number[]) => {
  return [A[0] + B[0], A[1] + B[1]]
}

/**
 * Subtract vectors.
 * @param A
 * @param B
 * @internal
 */
const sub = (A: number[], B: number[]) => {
  return [A[0] - B[0], A[1] - B[1]]
}

/**
 * Vector multiplication by scalar
 * @param A
 * @param n
 * @internal
 */
const mul = (A: number[], n: number) => {
  return [A[0] * n, A[1] * n]
}

/**
 * Get whether two vectors are equal.
 * @param A
 * @param B
 * @internal
 */
const isEqual = (A: number[], B: number[]) => {
  return A[0] === B[0] && A[1] === B[1]
}

/**
 * Dist length from A to B
 * @param A
 * @param B
 * @internal
 */
const dist = (A: number[], B: number[]) => {
  return Math.hypot(A[1] - B[1], A[0] - B[0])
}

/**
 * Interpolate vector A to B with a scalar t
 * @param A
 * @param B
 * @param t scalar
 * @internal
 */
const lrp = (A: number[], B: number[], t: number) => {
  return add(A, mul(sub(B, A), t))
}
