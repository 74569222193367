import { useLocation } from 'react-router'

import { useExternalLink } from './useExternalLink'
import { useFreshpaint } from './useFreshpaint'
import { useNativeApp } from './useNativeApp'
import { usePopup } from './usePopup'
import { useServerMaintenance } from './useServerMaintenance'

const useApp = () => {
  const location = useLocation()

  useExternalLink()

  useNativeApp()

  useFreshpaint()

  useServerMaintenance()

  usePopup()

  return { location }
}

export default useApp
