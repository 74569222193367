import { dual } from 'effect/Function'

export const $: {
  <AttrT extends string, V extends string>(v: V, attr: AttrT): `[data-${AttrT}='${V}']`
  <AttrT extends string>(attr: AttrT): <V extends string>(V: V) => `[data-${AttrT}='${V}']`
} = dual(
  2,
  <AttrT extends string, V extends string>(v: V, attr: AttrT) => `[data-${attr}='${v}']` as const,
)

const _kindSelector = $('kind')

/**
 * @example
 * const inputFieldSelector = makeSelector({
 *   kind: 'ms__InputField',
 *   attr: {
 *     size: 'small',
 *     variant: 'primary',
 *   },
 *   parts: {
 *     label: 'ms__InputField_label',
 *   },
 * })
 * inputFieldSelector.kind // "[data-component='ms__InputField']"
 * inputFieldSelector.attr('label') // "[data-component='ms__InputField'] .label"
 * inputFieldSelector.parts('size', 'small') // "[data-component='ms__InputField'][data-small='size']"
 */
export const makeSelector = <
  const M extends {
    kind: string
    attr?: Record<string, string>
    parts?: Record<string, string>
  },
>(
  m: M,
) => ({
  kind: _kindSelector(m.kind),
  attr<A extends keyof M['attr']>(attr: A, value: M['attr'][A]) {
    return m.attr ? (`${this.kind}${$(value as string, attr as string)}` as const) : ''
  },
  parts(partsName: keyof M['parts']) {
    return m.parts ? (`${this.kind} .${partsName as string}` as const) : ''
  },
})

/**
 * 모듈의 kind를 통해 선택하는 셀렉터를 만듭니다.
 * @example
 * kindSelect({ kind: 'ms__InputField' }) // "[data-component='ms__InputField']"
 */
export const kindSelector = <M extends { kind: string }>({ kind }: M) =>
  `${_kindSelector(kind)}` as const /*?*/
