export const colors = {
  gray: {
    $500: '#C0C0C0',
    $900: '#333333',
  },
  red: {
    $300: '#FF707D',
  },
}

export const PEN_WIDTHS = [2, 4, 6, 10, 14]
export const HIGHLIGHT_PEN_WIDTHS = [10, 14, 18, 22, 26]
export const ERASER_WIDTHS = [24, 40, 60]
export const PEN_COLORS = [colors.gray.$900, '#30A4FF', '#70D000', '#FFB300', colors.red.$300]
export const HIGHLIGHT_PEN_COLORS = [
  'rgba(76, 255, 14, 0.3)',
  'rgba(28, 255, 255, 0.3)',
  'rgba(255, 5, 185, 0.3)',
  'rgba(255, 230, 1, 0.3)',
  'rgba(254, 123, 103, 0.3)',
]
