import type { ConceptChipDLevel } from '@mathflat/domain/@entities/ConceptChip/types'

import type { ConceptChipDto, RecentChallengeItem } from '../response'

export default class RecentChallenge {
  readonly littleChapterId: number
  readonly littleChapterName: string
  readonly learningConceptChip: Map<ConceptChipDLevel, number>
  readonly conceptChips: ConceptChipDto[]

  constructor(data: RecentChallengeItem) {
    this.littleChapterId = data.littleChapterId
    this.littleChapterName = data.littleChapterName
    this.learningConceptChip = new Map<ConceptChipDLevel, number>([
      ['A', data.learningConceptChip.conceptChipA],
      ['B', data.learningConceptChip.conceptChipB],
      ['C', data.learningConceptChip.conceptChipC],
    ])
    this.conceptChips = data.conceptChips
  }
}
