import * as S from '@effect/schema/Schema'

export module TeacherSchema {
  /**
   * 선생님 고유 식별 ID
   * @example 'T3244'
   */
  export const Id = S.templateLiteral(S.literal('T'), S.string)
  export type Id = S.Schema.To<typeof Id>
}
