export type TimeStamp = number

export interface Size {
  width: number
  height: number
}

export interface Point {
  x: number
  y: number
}

export type RawPoint = [number, number]

export const getZeroPoint = () => ({ x: 0, y: 0 })
