import smoothscroll from 'smoothscroll-polyfill'

const polyfill = () => {
  if (!String.prototype.replaceAll) {
    String.prototype.replaceAll = function (str, newStr) {
      if (Object.prototype.toString.call(str).toLowerCase() === '[object regexp]') {
        return this.replace(str, newStr)
      }
      return this.replace(new RegExp(str, 'g'), newStr)
    }
  }
}

export const initPolyfill = () => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  globalThis.event = new Event('onBackPressed')
  smoothscroll.polyfill()
  polyfill()
}
