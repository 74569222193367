import { observer } from 'mobx-react'
import { type ComponentProps, type FC, useId, useState } from 'react'
import { Tooltip as ReactTooltip } from 'react-tooltip'

import { colors } from '~/@common/styles'
import type { DefaultProps } from '~/@common/types'
import { Icon } from '~/@common/ui/Icon/Icon'

import type { IconNames } from '../Icon/iconNames.type'

export const kind = 'ms__Tooltip' as const

const DEFAULT_ICON_NAME = 'icon_info_fill'

type ReactTooltipProps = ComponentProps<typeof ReactTooltip>

type Props = Pick<ReactTooltipProps, 'place' | 'offset'> & {
  zIndex?: number
  iconName?: IconNames
  iconSize?: number
  iconColor?: string
  iconActiveColor?: string
  openOnClick?: ReactTooltipProps['openOnClick']
  afterShow?: ReactTooltipProps['afterShow']
  afterHide?: ReactTooltipProps['afterHide']
} & DefaultProps

const TooltipComponent: FC<Props> = ({
  zIndex = 1,
  iconName = DEFAULT_ICON_NAME,
  iconSize = 20,
  iconColor = colors.gray.$500,
  iconActiveColor = colors.gray.$700,
  place = 'right-start',
  openOnClick = true,
  afterShow,
  afterHide,
  offset,
  children,
}) => {
  const tooltipId = useId()
  const [tooltipIconColor, setTooltipIconColor] = useState(iconColor)

  const handleAfterShow = () => {
    setTooltipIconColor(iconActiveColor ?? iconColor)

    if (afterShow) {
      afterShow()
      return
    }
  }

  const handleAfterHide = () => {
    setTooltipIconColor(iconColor)

    if (afterHide) {
      afterHide()
      return
    }
  }

  return (
    <>
      <Icon
        name={iconName}
        size={iconSize}
        data-tooltip-id={tooltipId}
        color={tooltipIconColor}
        style={{ cursor: openOnClick ? 'pointer' : 'default' }}
      />
      <ReactTooltip
        id={tooltipId}
        place={place}
        offset={offset}
        disableStyleInjection={true}
        openOnClick={openOnClick}
        afterShow={handleAfterShow}
        afterHide={handleAfterHide}
        data-component={kind}
        css={{
          zIndex,
        }}
      >
        {children}
      </ReactTooltip>
    </>
  )
}

export default observer(TooltipComponent)
