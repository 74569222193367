import { useEffect } from 'react'

import { academyApi } from '~/@common/api/academyApi'
import authService from '~/@common/services/auth.service'
import { commonRepo } from '~/@common/services/repo.service'
import { convertAcademy } from '~/@common/utils/freshpaintUtils'

export const useFreshpaint = () => {
  useEffect(() => {
    const student = commonRepo.student
    if (student) {
      if (authService.token) {
        academyApi
          .getAcademies()
          .then((academy) => {
            const _academy = convertAcademy(academy)
            window.freshpaint?.addEventProperties({
              academy: _academy,
              student,
            })
            window.freshpaint?.identify(student.id, {
              $phone: student.phone,
              $name: student.name,
              unique_id: student.id,
              ...student,
              academy,
            })
          })
          .catch((e) => {
            console.error(e)
          })
      } else {
        window.freshpaint?.addEventProperties({ student })
      }
    }
  }, [commonRepo.student, authService.token])
}
