import type { ClassT } from ':/@types/utilityTypes'

import type DynamicRepo from '../repository/dynamicRepo.service'
import type ServiceRepo from '../repository/repo.service'

export const makeRepoHook =
  (serviceRepo: ServiceRepo, dynamicRepo: DynamicRepo) =>
  <T extends ClassT>(
    identifier: [service: T, key: string] | T,
    ...param: ConstructorParameters<T>
  ) => {
    if (Array.isArray(identifier)) {
      return dynamicRepo.reg(identifier, ...param)
    }
    return serviceRepo.reg(identifier, ...param)
  }

export const makeSetRepoHook =
  (serviceRepo: ServiceRepo, dynamicRepo: DynamicRepo) =>
  <T extends ClassT>(identifier: [T, string] | T, ...param: ConstructorParameters<T>) => {
    if (Array.isArray(identifier)) {
      return dynamicRepo.set(identifier, ...param)
    }
    return serviceRepo.set(identifier, ...param)
  }

export const makeGetRepoHook =
  (serviceRepo: ServiceRepo, dynamicRepo: DynamicRepo) =>
  <T extends ClassT>(identifier: [T, string] | T) => {
    if (Array.isArray(identifier)) {
      return dynamicRepo.get(identifier)
    }
    return serviceRepo.get(identifier)
  }

//example
// const useRepository = makeRepoHook(new ServiceRepo(), new DynamicRepo())

// TODO: useDynamicRepo 형식에 맞추고 기존에 param 넘겨서 쓰고 있던 것들은 각자 알아서 memo해서 쓰도록...
// const useRepository = <T extends ClassT>(
//   identifier: [service: T, key: string] | T,
//   ...param: ConstructorParameters<T>
// ) => {
//   if (Array.isArray(identifier)) {
//     return dynamicRepo.reg(identifier, ...param)
//   }
//   return serviceRepo.reg(identifier, ...param)
// }
// export default useRepository
