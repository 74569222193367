import styled from '@emotion/styled'

import { colors } from '~/@common/styles'
import { zIndex } from '~/@common/styles'

export const Backdrop = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  background-color: ${colors.overlay};
  z-index: ${zIndex.drawer_backdrop};
`
