import styled from '@emotion/styled'
import { clsx } from 'clsx'
import { Fragment, useState } from 'react'

import { colors, textEllipsis, typo } from '~/@common/styles'
import { Drawer } from '~/@common/ui/(Drawer)/BaseDrawer'
import Badge from '~/@common/ui/Badge/Badge.tsx'
import { Icon } from '~/@common/ui/Icon/Icon.tsx'
import type {
  Items,
  ParentItem,
  SelectedItem,
} from '~/@pages/student/learning-process/@widgets/ui/LearingProcessNavigation/types/LearningProcessNavigation.type.ts'
import { addCustomLabel } from '~/@pages/student/learning-process/@widgets/ui/LearingProcessNavigation/utils/label.ts'

type Props = {
  isOpened: boolean
  handleOpened: (value: boolean) => void
  items: Items
  selectedItem: SelectedItem
  lastSelectedItems?: SelectedItem[]
  onClickItem?: (value: SelectedItem) => void
}

export const kind = 'ms__LearningProcessStepList' as const
const parts = {
  parentItem: `${kind}_parent_item`,
  childrenItem: `${kind}_children_item`,
} as const

const LearningProcessStepDrawer = ({
  isOpened,
  handleOpened,
  selectedItem,
  items,
  onClickItem,
  lastSelectedItems,
}: Props) => {
  const [openedRenderItem, setOpenedRenderItem] = useState<
    ({ renderLabel: string } & ParentItem) | null
  >(null)

  const renderItems = addCustomLabel({
    allItems: items,
    lastSelectedItems,
    currentSelectedItem: selectedItem,
  })

  return (
    <Drawer
      isOpened={isOpened}
      closeDrawer={() => {
        handleOpened(false)
        setOpenedRenderItem(null)
      }}
      size="550px"
      hasCloseButton={!openedRenderItem}
      hasPrevButton={!!openedRenderItem}
      onClickPrev={() => setOpenedRenderItem(null)}
    >
      <Drawer.Content>
        <Drawer.Header>
          {openedRenderItem
            ? `step${Number(openedRenderItem.value) + 1}. ${openedRenderItem.label}`
            : '스텝 선택'}
        </Drawer.Header>
        <Drawer.Body>
          <S.Container>
            {renderItems.map((item) => {
              const isSelected = selectedItem.parent.value === item.value

              return (
                <Fragment key={item.label}>
                  <li
                    onClick={() => {
                      if (!item.children) {
                        handleOpened(false)
                        onClickItem && onClickItem({ parent: item })
                        return
                      }

                      setOpenedRenderItem(item)
                    }}
                    className={clsx(parts.parentItem, isSelected && 'selected')}
                  >
                    {item.renderLabel}
                    {isSelected && <Badge theme="primaryBlue">진행중</Badge>}
                    {!!item.children && (
                      <div className="children-open-indicator">
                        유형선택
                        <Icon name="icon_chevron_right" color={colors.gray.$500} size={20} />
                      </div>
                    )}
                  </li>
                  {!!item.children && (
                    <S.ChildrenList className={clsx(!!openedRenderItem && 'opened')}>
                      {item.children.map((each) => {
                        const isSelected = selectedItem.children?.value === each.value

                        return (
                          <li
                            className={clsx(parts.childrenItem, isSelected && 'selected')}
                            key={each.value}
                            onClick={() => {
                              onClickItem?.({ parent: item, children: each })
                              setOpenedRenderItem(null)
                              handleOpened(false)
                            }}
                          >
                            <span className={`${parts.childrenItem}-text`}>{each.label}</span>
                            {isSelected && <Badge theme="primaryBlue">진행중</Badge>}
                          </li>
                        )
                      })}
                    </S.ChildrenList>
                  )}
                </Fragment>
              )
            })}
          </S.Container>
        </Drawer.Body>
      </Drawer.Content>
    </Drawer>
  )
}

export default LearningProcessStepDrawer

const S = {
  Container: styled.ul`
    position: relative;

    .${parts.parentItem} {
      position: relative;
      padding: 20px 24px;
      border-bottom: 1px solid ${colors.gray.$100};
      color: ${colors.gray.$800};
      ${typo.body02}

      &.selected {
        display: flex;
        gap: 10px;
        background-color: rgba(57, 95, 226, 0.1);
        color: ${colors.blue.$500};
        font-weight: 700;
      }

      &:active {
        background-color: ${colors.gray.$100};
      }
    }

    .children-open-indicator {
      display: flex;
      align-items: center;
      gap: 2px;
      position: absolute;
      top: 50%;
      right: 24px;
      transform: translateY(-50%);
      color: ${colors.gray.$600};
      font-weight: 400;
    }
  `,

  ChildrenList: styled.ul`
    position: absolute;
    top: 0;
    transform: translateX(100%);
    width: 100%;
    height: 100%;
    transition: transform 0.3s;
    background-color: ${colors.white};

    &.opened {
      transform: unset;
    }

    .${parts.childrenItem} {
      width: 100%;
      padding: 20px 24px;
      border-bottom: 1px solid ${colors.gray.$100};
      color: ${colors.gray.$800};
      ${typo.body02};

      .${parts.childrenItem}-text {
        ${textEllipsis(1)}
      }

      &.selected {
        display: flex;
        gap: 10px;
        background-color: rgba(57, 95, 226, 0.1);
        color: ${colors.blue.$500};
        font-weight: 700;
      }

      &:active {
        background-color: ${colors.gray.$100};
      }
    }
  `,
}
