import * as S from '@effect/schema/Schema'

export module StudentDomain {
  export const STATUS = {
    활성: 'ACTIVE',
    비활성: 'INACTIVE',
    삭제됨: 'DELETED',
  } as const

  /**
   * 학생 로그인 ID
   * @example 'S68199589'
   */
  export const LoginId = S.templateLiteral(S.literal('S'), S.string)
  export type LoginId = S.Schema.To<typeof LoginId>

  /**
   * 학생 고유 식별 ID
   * @example 'I726693'
   */
  export const Id = S.templateLiteral(S.literal('I'), S.string)
  export type Id = S.Schema.To<typeof Id>

  export const Status = S.enums(STATUS)
}
