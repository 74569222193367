import _ from 'lodash'
import { nanoid } from 'nanoid'

import { S } from './@widgets/LectureGroup.style'

export const LectureListSkeleton = () => {
  return (
    <S.LectureGroupList>
      {_.range(0, 3).map(() => (
        <S.LectureGroup key={nanoid()} />
      ))}
    </S.LectureGroupList>
  )
}
