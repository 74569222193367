import type React from 'react'
import { useNavigate } from 'react-router'

import { useLastLocationManager } from '~/@common/services/(LastLocationManager)'
import modalService from '~/@common/services/modal.service'
import Modal from '~/@common/ui/modal/Modal'

interface Props {
  defaultPrevRouteName: string
  onSubmit: () => void
  content?: React.ReactElement
}

export const StudentLearningContentBackConfirmModal = ({
  defaultPrevRouteName,
  onSubmit,
  content,
}: Props) => {
  const navigate = useNavigate()
  const locationManager = useLastLocationManager()

  const navigateBack = () => {
    if (!locationManager.prevState?.location.pathname) {
      navigate(defaultPrevRouteName)
      return
    }
    navigate(-1)
  }

  const navigateBackAndCloseModal = () => {
    navigateBack()
    modalService.closeModal()
  }

  return (
    <Modal.Confirm.Positive
      cancel={{
        children: '취소하기',
        onClick: () => {
          modalService.closeModal()
        },
      }}
      confirm={{
        children: '바로 이동',
        onClick: () => {
          navigateBackAndCloseModal()
        },
      }}
    >
      {content ?? (
        <>
          답안을 제출하지 않고 화면을
          <br />
          이동하시겠습니까?
          <br />
          그대로 이동해도 입력한 답안은
          <br />
          사라지지 않습니다.
          <br />
          (제출한 답안은 다시 수정할 수 없습니다)
        </>
      )}
    </Modal.Confirm.Positive>
  )
}
