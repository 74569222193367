import { isBefore } from 'date-fns'
import { useEffect } from 'react'

import strapiApi from '~/@common/api/strapiApi'
import { routeName } from '~/@common/constants'
import authService from '~/@common/services/auth.service'
import modalService from '~/@common/services/modal.service'
import { commonRepo } from '~/@common/services/repo.service'
import NoticePopup from '~/@pages/@common/NoticePopup/NoticePopup'

export const usePopup = () => {
  useEffect(() => {
    if (
      commonRepo.student?.id &&
      authService.isLoggedIn &&
      window.location.pathname !== routeName.login &&
      window.location.pathname !== routeName.serverMaintenance
    ) {
      const storageKey = `noticePopupExpiresAt_${commonRepo.student.id}`
      const storedPopups = JSON.parse(localStorage.getItem(storageKey) ?? '[]')

      // 1. localStorage에서 strapi fetch에서 제외할 팝업들을 구한다.
      const excludedPopups = storedPopups.filter(({ expiredDate }) => {
        return expiredDate === 'infinite' || isBefore(new Date(), new Date(expiredDate))
      })

      // 2. 제외된 팝업은 expiredDate가 지난 것들이 삭제된 최신의 값과 같으므로, 겸사겸사 localStorage값을 리뉴얼 시켜준다.
      localStorage.setItem(storageKey, JSON.stringify(excludedPopups))

      // 3. id만 추출후, 필터 조건에 넣는다.
      const excludedIds = excludedPopups.map(({ id }) => id)
      const filters = excludedIds.length > 0 ? { id: { $notIn: excludedIds } } : undefined
      strapiApi
        .getNotice({
          sort: ['priority:desc'],
          filters,
          pagination: {
            limit: 1,
          },
        })
        .then((popups) => {
          if (popups.length > 0) {
            const popup = popups[0]
            modalService.openModal(
              { content: <NoticePopup {...popup} /> },
              {
                modalName: '팝업 공지 모달 - ' + popup.title,
              },
            )
          }
        })
        .catch((e) => {
          console.error(e)
        })
    }
  }, [commonRepo.student?.id, authService.isLoggedIn])
}
