import styled from '@emotion/styled'
import { observer } from 'mobx-react'
import { e } from 'node_modules/@storybook/preview-api/dist/hooks-655fa363'
import { useEffect } from 'react'
import { useNavigate } from 'react-router'
import { useSearchParams } from 'react-router-dom'

import useScrollNavigation, {
  NAV_BUTTON_STATE,
  SWIPE_STATE,
} from '~/@app/hooks/useScrollNavigation'
import { routeName } from '~/@common/constants'
import { useStudentAppMediaQuery } from '~/@common/hooks/useMediaQuery'
import { useRepository } from '~/@common/hooks/useRepository'
import { commonRepo } from '~/@common/services/repo.service'
import { colors, typo } from '~/@common/styles'
import { SwiperDefaultBackButton, SwiperDefaultNextButton } from '~/@common/ui/(StyledButton)'
import { PortalRootLayoutHeader } from '~/@common/ui/PortalRootLayoutHeader'
import GlobalHeader from '~/@pages/@widgets/(Navigation)/GlobalHeader/GlobalHeader'

import { LectureService } from '../../@service/Lecture.service'
import { LECTURE_GROUP_CARD_SIZE } from '../../@widgets/LectureGroup.style'
import LectureGroupList, { LECTURE_GROUP_LIST_ID } from '../../@widgets/LectureGroupList.widget'

const SignatureWorkbookLectureListPage = () => {
  const service = useRepository(LectureService)
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const { isMobile } = useStudentAppMediaQuery()

  useEffect(() => {
    const curriculumKey = searchParams.get('curriculumKey')

    if (!curriculumKey) {
      navigate(routeName.student.defaultPath)
      return
    }

    if (commonRepo.studentId) {
      service
        .fetchSetLectureGroups(
          { trieKey: curriculumKey, studentId: commonRepo.studentId },
          'MIDDLE_CHAPTER',
        )
        .catch(() => {
          navigate(routeName.student.defaultPath)
        })
    }
  }, [searchParams, commonRepo.studentId])

  const { handleSwipe, nextButtonState, prevButtonState } = useScrollNavigation(
    { elementId: LECTURE_GROUP_LIST_ID, distance: LECTURE_GROUP_CARD_SIZE },
    [service.lectureGroups],
  )

  if (!service.lectureGroups) {
    return <></>
  }

  return (
    <>
      <PortalRootLayoutHeader>
        <GlobalHeader
          SlotCenter="강의"
          prevLinkProps={{
            onClick: (e) => {
              e.preventDefault()
              navigate(routeName.student.defaultPath)
            },
          }}
        ></GlobalHeader>
      </PortalRootLayoutHeader>
      <S.SignatureWorkbookLectureListPage>
        <header>
          <div className="left">
            <img src="/images/lecture-teacher.png" width={60} height={60} />
            <div className="text-wrapper">
              <div className="title">
                {service.lectureGroups[0].middleChapterLectures[0].bigChapterName}
              </div>
              <div className="sub-title">다음 강의를 보고 학습할 수 있어요.</div>
            </div>
          </div>
          {!isMobile && service.lectureGroups.length > 0 && (
            <div className="right">
              {prevButtonState !== NAV_BUTTON_STATE.INVISIBLE && (
                <SwiperDefaultBackButton
                  size={38}
                  theme="white"
                  disabled={prevButtonState === NAV_BUTTON_STATE.DISABLED}
                  onClick={() => {
                    handleSwipe(SWIPE_STATE.PREV)
                  }}
                />
              )}
              {nextButtonState !== NAV_BUTTON_STATE.INVISIBLE && (
                <SwiperDefaultNextButton
                  size={38}
                  theme="white"
                  disabled={nextButtonState === NAV_BUTTON_STATE.DISABLED}
                  onClick={() => {
                    handleSwipe(SWIPE_STATE.NEXT)
                  }}
                />
              )}
            </div>
          )}
        </header>
        <LectureGroupList lectureGroups={service.lectureGroups} isFetching={service.isFetching} />
      </S.SignatureWorkbookLectureListPage>
    </>
  )
}

export default observer(SignatureWorkbookLectureListPage)

const S = {
  SignatureWorkbookLectureListPage: styled.div`
    display: flex;
    flex-direction: column;
    /* gap: 20px; */
    height: 100%;
    > header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .left {
        display: flex;
        align-items: center;
        gap: 11px;
        .text-wrapper {
          display: flex;
          flex-direction: column;
          justify-content: center;
          gap: 2px;
          margin-bottom: 9px;
          .title {
            color: ${colors.gray.$900};
            ${typo.heading04};
            font-weight: bold;
          }
          .sub-title {
            color: ${colors.gray.$700};
          }
        }
      }
      .right {
        display: flex;
        align-items: center;
        gap: 17px;
      }
    }
    .curriculum-list {
      display: flex;
      align-items: center;
      gap: 8px;
    }
  `,
}
