import { makeAutoObservable } from 'mobx'

class HandwrittenNoteTooltipService {
  isShowHandwrittenNoteInfoTooltip = false

  constructor() {
    makeAutoObservable(this)
  }

  showHandwrittenInfoTooltip() {
    this.isShowHandwrittenNoteInfoTooltip = true
  }

  hideHandwrittenInfoTooltip() {
    this.isShowHandwrittenNoteInfoTooltip = false
  }
}

export const handwrittenNoteTooltipService = new HandwrittenNoteTooltipService()
