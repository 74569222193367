import { computed, makeObservable, observable } from 'mobx'
import type { createBrowserRouter, Path } from 'react-router-dom'

export type RouterState = {
  location: Path
  historyAction: 'PUSH' | 'POP' | 'REPLACE'
}

export class Service {
  static prevPathName = 'prev-path' as const

  private _lastTwoRouteStates: [prev?: RouterState, current?: RouterState]

  public get currentState() {
    switch (this._lastTwoRouteStates.length) {
      case 0:
        return null
      case 1:
        return this._lastTwoRouteStates[0]!
      case 2:
        return this._lastTwoRouteStates[1]!
    }
  }

  public get prevState() {
    switch (this._lastTwoRouteStates?.length) {
      case 0:
      case 1:
        return null
      case 2:
        return this._lastTwoRouteStates[0]!
    }
  }

  private addRouteState(state: RouterState) {
    switch (state.historyAction) {
      case 'PUSH': {
        this._lastTwoRouteStates.push(state)
        if (this._lastTwoRouteStates.length > 2) this._lastTwoRouteStates.shift()
        break
      }

      case 'REPLACE': {
        this._lastTwoRouteStates.pop()
        this._lastTwoRouteStates.push(state)
        break
      }
      case 'POP': {
        this._lastTwoRouteStates.pop()
        break
      }
    }
  }

  constructor(
    { state, subscribe }: Pick<ReturnType<typeof createBrowserRouter>, 'state' | 'subscribe'>,
    initialState: Service['_lastTwoRouteStates'] = [state],
  ) {
    this._lastTwoRouteStates = initialState
    subscribe((state) => this.addRouteState(state))

    makeObservable<Service, '_lastTwoRouteStates'>(this, {
      _lastTwoRouteStates: observable,
      currentState: computed,
      prevState: computed,
    })
  }
}
