import styled from '@emotion/styled'
import { WorksheetDomain } from '@mathflat/domain/@entities'

import { colors } from '~/@common/styles'
import { Icon } from '~/@common/ui/Icon/Icon'

import type { SelfLearning } from '../model/SelfLearning.dto'

const LearningBadge = ({ type }: { type: SelfLearning['worksheetType'] }) => {
  return (
    <S.LearningBadge>
      <div className="wrapper">
        <Icon
          name="icon_bookmark"
          width={30}
          height={32}
          className="bookmark"
          color={
            type === WorksheetDomain.SELF_LEARNING_TYPE.오답유형학습
              ? colors.green.$100
              : colors.blue.$500
          }
        />
      </div>
      <Icon
        name={
          type === WorksheetDomain.SELF_LEARNING_TYPE.오답유형학습 ? 'icon_balloon_x' : 'icon_graph'
        }
        size={12}
        color="white"
        className="inner-icon"
      />
    </S.LearningBadge>
  )
}

export default LearningBadge

const S = {
  LearningBadge: styled.div`
    position: relative;
    /* TODO: CBT 2차 배포 - box-shadow가 북마크 shape에 맞게 들어가야함 */
    .inner-icon {
      position: absolute;
      top: 6px;
      left: 9px;
    }
  `,
}
