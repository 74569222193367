import useMediaQuery from '@mathflat/design-system/@common/hooks/useMediaQuery'

import { mediaQuery } from '../styles/mediaQuery'

export const useStudentAppMediaQuery = () => {
  const underDesktop = useMediaQuery(mediaQuery.underDesktop.replace('@media ', ''))
  const underTablet = useMediaQuery(mediaQuery.underTablet.replace('@media ', ''))

  const isDesktop = !underDesktop
  const isMobile = underTablet
  const isTablet = !isDesktop && !isMobile

  return { isMobile, isTablet, isDesktop }
}
