import { WorksheetDomain } from '@mathflat/domain/@entities'
import { CONTENT_STATUS } from '@mathflat/domain/@entities/(Content)/module'
import type { LearningContentStatus } from '@mathflat/domain/@entities/StudentWorksheet/api2'
import type { ValueOf } from '@mathflat/shared/@types/utilityTypes'
import { format } from 'date-fns'

import type { IconNames } from '~/@common/ui/Icon/iconNames.type'
import type { LearningProcessService } from '~/@pages/student/learning-process/@widgets/service/LearningProcess.service'

import type { StudentSelfLearningWorksheetList, StudentWorksheetList } from '.'

export class StudentSelfLearningWorksheetGroupList implements StudentWorksheetList {
  id: number

  /**
   * 부모 학습지(StudentCustomWorksheetList)가
   * 자기주도 학습지(StudentSelfLearningWorksheetGroupList)를 가진 경우 생성되는
   * 객체이기 때문에 selfLearnings[0]으로 접근할 때 undefined가 발생하지 않는다.
   * @link https://github.com/mathFLAT/new-mathflat-frontend/pull/931#discussion_r1568258910
   */
  get labelSelfLearning(): StudentSelfLearningWorksheetList {
    return this.selfLearnings[0]
  }

  get 자기주도_그룹_여부() {
    return this.selfLearnings.length > 1
  }

  get completedLevelUp() {
    const levelUpTypeSelfLearning = this.selfLearnings.filter(
      (selfLearning) =>
        selfLearning.worksheet.selfLearningType === WorksheetDomain.SELF_LEARNING_TYPE.심화학습,
    )

    return (
      levelUpTypeSelfLearning.every((selfLearning) => selfLearning.status === 'COMPLETE') &&
      levelUpTypeSelfLearning.every((selfLearning) => selfLearning.wrongCount === 0)
    )
  }

  get completedWrong() {
    const wrongTypeSelfLearning = this.selfLearnings.filter(
      (selfLearning) =>
        selfLearning.worksheet.selfLearningType === WorksheetDomain.SELF_LEARNING_TYPE.오답유형학습,
    )

    return (
      wrongTypeSelfLearning.every((selfLearning) => selfLearning.status === 'COMPLETE') &&
      wrongTypeSelfLearning.every((selfLearning) => selfLearning.wrongCount === 0)
    )
  }

  get 진척도() {
    return Math.floor(
      (this.selfLearnings.filter((item) => item.status === CONTENT_STATUS.전체채점).length /
        this.selfLearnings.length) *
        100,
    )
  }

  constructor(private selfLearnings: StudentSelfLearningWorksheetList[]) {
    this.id = selfLearnings[0].id
    this.selfLearnings = selfLearnings
  }

  checkIsSelfLearningCompleted() {
    const checkAllCompletedZeroWrong = (selfLearningList: StudentSelfLearningWorksheetList[]) => {
      return (
        selfLearningList.every((selfLearning) => selfLearning.status === 'COMPLETE') &&
        selfLearningList.every((selfLearning) => selfLearning.wrongCount === 0)
      )
    }

    if (
      this.labelSelfLearning.worksheet.selfLearningType ===
      WorksheetDomain.SELF_LEARNING_TYPE.오답유형학습
    ) {
      return checkAllCompletedZeroWrong(
        this.selfLearnings.filter(
          (selfLearning) =>
            selfLearning.worksheet.selfLearningType ===
            WorksheetDomain.SELF_LEARNING_TYPE.오답유형학습,
        ),
      )
    }
    if (
      this.labelSelfLearning.worksheet.selfLearningType ===
      WorksheetDomain.SELF_LEARNING_TYPE.심화학습
    ) {
      return checkAllCompletedZeroWrong(
        this.selfLearnings.filter(
          (selfLearning) =>
            selfLearning.worksheet.selfLearningType === WorksheetDomain.SELF_LEARNING_TYPE.심화학습,
        ),
      )
    }
  }

  checkIsSelfLearningCtaVisible() {
    return null
  }

  getTitle(): string {
    return this.labelSelfLearning.worksheet.title
  }

  getIconName(): IconNames {
    return 'icon_inside_point'
  }

  getLabels(): ('숙제' | '비공개' | '자동채점')[] {
    const tags: ('숙제' | '비공개' | '자동채점')[] = []
    if (this.labelSelfLearning.worksheet.accessModifierToStudent === 'PRIVATE') tags.push('비공개')
    if (this.labelSelfLearning.worksheet.autoScorable) tags.push('자동채점')
    return tags
  }

  getProblemCount(): number {
    return this.selfLearnings.reduce((prev, curr) => prev + curr.worksheet.problemCount, 0)
  }

  getScore(): number {
    return Math.floor(
      this.selfLearnings.reduce((prev, curr) => prev + curr.score, 0) / this.selfLearnings.length,
    )
  }

  getWrongCount(): number {
    return this.selfLearnings.reduce((prev, curr) => prev + curr.getWrongCount(), 0)
  }

  getCorrectCount(): number {
    return this.selfLearnings.reduce((prev, curr) => prev + curr.getCorrectCount(), 0)
  }

  getFormattedAssignDatetime(): string {
    return format(this.labelSelfLearning.assignDatetime, 'yy.MM.dd')
  }

  getRoute(): string {
    return ''
  }

  async getSelfLearningRoutePath(service: LearningProcessService, studentWorksheetId: string) {
    const url =
      this.labelSelfLearning.worksheet.selfLearningType ===
      WorksheetDomain.SELF_LEARNING_TYPE.심화학습
        ? await service.보충_심화학습지_학습하기({
            studentWorksheetId,
          })
        : await service.보충_오답학습지_학습하기({
            studentWorksheetId,
          })

    return url
  }

  checkIsHomework(): boolean {
    return false
  }

  checkIsExam(): boolean {
    return false
  }

  getStatus(): LearningContentStatus {
    if (this?.selfLearnings.every((selfLearning) => selfLearning.getStatus() === 'COMPLETE')) {
      return 'COMPLETE'
    } else if (
      this?.selfLearnings.every((selfLearning) => selfLearning.getStatus() === 'INCOMPLETE')
    ) {
      return 'INCOMPLETE'
    }
    return 'PROGRESS'
  }

  getSelfLearning(
    status?: Omit<ValueOf<typeof CONTENT_STATUS>, 'ALL'>,
  ): StudentSelfLearningWorksheetGroupList[] | null {
    if (status) {
      return [
        new StudentSelfLearningWorksheetGroupList(
          this.selfLearnings.filter((selfLearning) => selfLearning.getStatus() === status),
        ),
      ]
    }

    return this.selfLearnings.length > 0 ? [this] : null
  }

  getAssignDatetime(): Date {
    return this.labelSelfLearning.assignDatetime
  }

  getTotalWorksheetCount() {
    return this.selfLearnings.length
  }

  getSelfLearningGroup() {
    return this.selfLearnings
  }
}
