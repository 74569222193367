import { css } from '@emotion/react'
import { isAxiosError } from 'axios'
import { observer } from 'mobx-react'
import { type FC, useMemo } from 'react'
import { generatePath, useNavigate } from 'react-router'

import { routeName } from '~/@common/constants'
import { useRepository } from '~/@common/hooks/useRepository'
import modalService from '~/@common/services/modal.service'
import { colors, fontWeight, typo } from '~/@common/styles'
import { mediaQuery } from '~/@common/styles/mediaQuery'
import Button from '~/@common/ui/(Button)/Button/Button'
import { LearningUnavailableAlertModal } from '~/@pages/@widgets/LearningUnavailableAlertModal'
import { ConceptualLearningService } from '~/@pages/student/conceptual-learning/@widgets/service/ConceptualLearning.service'

interface Props {
  littleChapterId: number
  isMobile: boolean
}

const congratulationIcon = (
  <img src="/images/icons/icon_congratulation.svg" width={28} height={28} alt="" />
)

const ConceptualLearningStatus: FC<Props> = ({ littleChapterId, isMobile }) => {
  const conceptualLearningService = useRepository(ConceptualLearningService)
  const conceptualWorksheet = conceptualLearningService.worksheetData.get(littleChapterId)
  const navigate = useNavigate()

  const handleConceptualLearningStart = async () => {
    let studentWorksheetId = conceptualWorksheet?.id

    if (!studentWorksheetId) {
      try {
        studentWorksheetId = await conceptualLearningService.createStudentWorksheet(littleChapterId)
      } catch (err) {
        if (isAxiosError(err)) {
          const errorCode = err.response?.data.code

          switch (errorCode) {
            case 'SELF_LEARNING_ALREADY_CREATED':
              studentWorksheetId = err.response?.data.data
              break
            case 'SELF_LEARNING_ALREADY_EXHAUSTED':
            case 'WORKSHEET_PROBLEM_EMPTY':
              modalService.openModal(<LearningUnavailableAlertModal />, {
                modalName: '소단원개념학습 불가',
              })
              return
          }
        }
      }
    }

    if (!studentWorksheetId || Number.isNaN(studentWorksheetId)) {
      throw new Error(`invalid studentWorksheetId: ${studentWorksheetId}`)
    }

    const nextPath = generatePath(routeName.student.conceptualLearning, {
      studentWorksheetId,
    })
    navigate(nextPath)
  }

  const startButtonText = useMemo(() => {
    if (!conceptualWorksheet) {
      return '개념 익히기'
    }
    if (conceptualWorksheet.learningStep === 'BACKLOG') {
      return '이어 풀기'
    }
    return '채점결과보기'
  }, [conceptualWorksheet])

  const conceptualLearningStateText = useMemo(() => {
    if (!conceptualWorksheet || conceptualWorksheet.learningStep === 'BACKLOG') {
      return (
        <>
          소단원 기초 학습을 위한
          <br /> 강의보고 예제 풀기
        </>
      )
    }
    const totalCount = conceptualWorksheet.correctCount + conceptualWorksheet.wrongCount

    if (totalCount === conceptualWorksheet.correctCount) {
      return (
        <>
          <strong>다 맞았어요!</strong>
          <br /> 챌린지도 도전해봐요 {congratulationIcon}
        </>
      )
    } else if (totalCount === conceptualWorksheet.wrongCount) {
      return (
        <>
          <strong>더 노력해봐요!</strong>
          <br /> 챌린지도 도전해봐요
        </>
      )
    } else {
      return (
        <>
          {totalCount}문제 중
          <br /> <strong>{conceptualWorksheet.correctCount}문제</strong> 맞혔어요!{' '}
          {congratulationIcon}
        </>
      )
    }
  }, [conceptualWorksheet])
  return (
    <div css={_css}>
      <p className="state-text">{conceptualLearningStateText}</p>
      {!conceptualWorksheet || conceptualWorksheet.learningStep === 'BACKLOG'}
      {
        <Button
          type="button"
          theme="secondary"
          size={isMobile ? 'small' : 'large'}
          style={{
            width: isMobile ? '100%' : 160,
          }}
          onClick={handleConceptualLearningStart}
        >
          {startButtonText}
        </Button>
      }
    </div>
  )
}

export default observer(ConceptualLearningStatus)

const _css = css`
  padding: 24px 16px;
  display: flex;
  flex-direction: column;
  gap: 12px;
  background: linear-gradient(180deg, rgba(220, 226, 255, 0.2) 0%, rgba(255, 255, 255, 0) 100%);

  ${mediaQuery.underTablet} {
    gap: 14px;
    background: unset;
    br {
      display: none;
    }
  }

  .state-text {
    ${typo.body02};
    font-weight: ${fontWeight.bold};
    color: ${colors.gray.$900};
    strong {
      color: ${colors.blue.$500};
    }
    img {
      margin: -10px 0 -5px -2px;
    }

    ${mediaQuery.underTablet} {
      br {
        display: none;
      }
    }
  }

  & > button {
    margin-top: auto;
  }
`
