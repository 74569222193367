import { colors } from '~/@common/styles'
import { Icon } from '~/@common/ui/Icon/Icon'

import { S } from '.'

export const GradingResultNotification = () => {
  return (
    <S.Container>
      <span>정답은</span>
      <Icon name="icon_answer_correct" color={colors.blue.$300} />
      <span>오답은</span>
      <Icon name="icon_answer_wrong" color={colors.red.$300} />
      <span>모르면</span>
      <Icon name="icon_answer_question" color={colors.yellow} />
      <span>으로 채점 결과를 선택해주세요.</span>
    </S.Container>
  )
}
