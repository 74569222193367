export const ELEMENTARY = 'ELEMENTARY'
export const MIDDLE = 'MIDDLE'
export const HIGH = 'HIGH'

export const SCHOOL_TYPE = {
  ELEMENTARY,
  MIDDLE,
  HIGH,
} as const

export const SCHOOL_TYPE_KO = {
  ELEMENTARY: '초등',
  MIDDLE: '중등',
  HIGH: '고등',
} as const

export const SCHOOL_TYPE_KO_FULL = {
  ELEMENTARY: '초등학교',
  MIDDLE: '중학교',
  HIGH: '고등학교',
} as const

export const SCHOOL_TYPE_KO_SHORT = {
  ELEMENTARY: '초',
  MIDDLE: '중',
  HIGH: '고',
} as const
