import { action, computed, makeObservable, observable, runInAction } from 'mobx'

import { studentHomeApi } from '../@common/api'
import RecentStudy from '../@common/model/RecentStudy'
import type { RecentStudiesRequestDto } from '../@common/request'
import type { RecentStudyType } from '../@common/types'
import sortBy from 'lodash/sortBy'

// const delay = () => new Promise((resolve) => setTimeout(resolve, 3000))
const recentStudyItemsPerPage = 15

export default class RecentStudyService {
  private _recentStudyType: RecentStudyType = 'ALL'
  private _recentStudies?: RecentStudy[] = undefined
  private _moreRecentStudyParams?: RecentStudiesRequestDto = undefined
  private _isLastPage: boolean = false

  constructor() {
    makeObservable<
      this,
      '_recentStudyType' | '_recentStudies' | '_moreRecentStudyParams' | '_isLastPage'
    >(this, {
      _recentStudyType: observable,
      _recentStudies: observable,
      _moreRecentStudyParams: observable,
      _isLastPage: observable,
      isReady: computed,
      setRecentStudyType: action,
      setRecentStudies: action,
      setMoreRecentStudyParams: action,
      setIsLastPage: action,
    })
  }

  get recentStudyType() {
    return this._recentStudyType
  }

  get recentStudies() {
    return sortBy(this._recentStudies, 'desc')
  }

  get moreRecentStudyParams() {
    return this._moreRecentStudyParams
  }

  get isLastPage() {
    return this._isLastPage
  }

  get isReady() {
    return this._recentStudies !== undefined
  }

  async loadRecentStudies(viewType: RecentStudyType) {
    this.setRecentStudies(undefined)
    const { contents, pageable } = await studentHomeApi.fetchRecentStudies({
      materialViewType: viewType,
      lastStudentExamId: null,
      lastStudentHomeworkId: null,
      lastStudentWorkbookRevisionId: null,
      lastStudentWorksheetId: null,
    })

    if (!contents.length) {
      this.setRecentStudies([])
      this.setIsLastPage(true)
      this.setMoreRecentStudyParams(undefined)
      return
    }

    this.setRecentStudies(contents.map((dto) => new RecentStudy(dto)))
    const isLastPage = contents.length < recentStudyItemsPerPage
    this.setMoreRecentStudyParams(isLastPage ? undefined : pageable)
    this.setIsLastPage(isLastPage)
  }

  async loadMoreRecentStudies() {
    if (!this.recentStudies?.length || !this.moreRecentStudyParams) {
      return
    }
    const { contents, pageable } = await studentHomeApi.fetchRecentStudies(
      this.moreRecentStudyParams,
    )

    if (!contents.length) {
      this.setIsLastPage(true)
      this.setMoreRecentStudyParams(undefined)
      return
    }

    runInAction(() => {
      this._recentStudies?.push(...contents.map((dto) => new RecentStudy(dto)))
      const isLastPage = contents.length < recentStudyItemsPerPage
      this.setMoreRecentStudyParams(isLastPage ? undefined : pageable)
      this.setIsLastPage(isLastPage)
    })
  }

  setRecentStudyType(value: RecentStudyType) {
    this._recentStudyType = value
  }

  setRecentStudies(value: RecentStudy[] | undefined) {
    this._recentStudies = value
  }

  setMoreRecentStudyParams(value: RecentStudiesRequestDto | undefined) {
    this._moreRecentStudyParams = value
  }

  setIsLastPage(value: boolean) {
    this._isLastPage = value
  }
}
