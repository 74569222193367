import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

export const useCallbackOnVisibilityChange = (onVisible?: () => void) => {
  const navigate = useNavigate()

  const onVisibleDefault = () => {
    if (!document.hidden && !window.localStorage.getItem('no-refresh')) {
      navigate(0)
    }

    window.localStorage.removeItem('no-refresh')
  }

  useEffect(() => {
    const fn = () => {
      if (document.visibilityState === 'visible') {
        onVisible ? onVisible() : onVisibleDefault()
      }
    }

    window.addEventListener('visibilitychange', fn)

    return () => {
      window.removeEventListener('visibilitychange', fn)
    }
  }, [])

  return null
}
