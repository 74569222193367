import styled from '@emotion/styled'
import { WorksheetDomain } from '@mathflat/domain/@entities'
import type { Entity as WorksheetEntity } from '@mathflat/domain/@entities/(Content)/Worksheet/dto'
import type { Entity as StudentWorksheetEntity } from '@mathflat/domain/@entities/StudentWorksheet/dto'
import { observer } from 'mobx-react'
import { useState } from 'react'
import type SwiperCore from 'swiper'
import { Navigation } from 'swiper/modules'
import { Controller } from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/react'

import { CustomEventService } from '~/@common/services/event.service'
import Button from '~/@common/ui/(Button)/Button/Button'
import { Drawer } from '~/@common/ui/(Drawer)/BaseDrawer'
import {
  SwiperControllerConsumerName,
  useSwiperControllerContext,
} from '~/@common/ui/SwiperController/SwiperController.context'
import type {
  ProblemScoring,
  ProblemScoringColl,
} from '~/@pages/@common/(ProblemScoring)/@trait/ProblemScoring.trait'
import type { ProblemScoringViewOption } from '~/@pages/@common/(ProblemScoring)/@trait/ProblemScoringViewOption.trait'
import { ProblemSolvingStatus } from '~/@pages/@common/ProblemSolvingStatusBoard/ProblemSolvingStatusBoard'

import { WorksheetScoringCardMobile } from './WorksheetScoringCard.mobile'
import { virtualKeyboardService } from '~/@common/ui/(Keypad)/keypad.service.ts'

type NotFetched = undefined

type Props<T extends '출제' | '미출제'> = {
  studentWorksheet: (T extends '출제' ? StudentWorksheetEntity.StudentWorksheet : null) | NotFetched
  problemScoringColl: ProblemScoringColl<'WORKSHEET'> | NotFetched
  viewOption: ProblemScoringViewOption<'학습모듈' | 'NOT_학습모듈'> | NotFetched
  type: WorksheetEntity.Worksheet['type'] | NotFetched
  isLastScreen?: boolean
  problemIndex?: number | null
  onProblemIndexChange?: (index: number) => void
  onSubmit?: () => void
}

export const WorksheetScoringByOneBodyMobile = observer(
  <T extends '출제' | '미출제'>({
    problemScoringColl,
    isLastScreen,
    type,
    viewOption,
    studentWorksheet,
    problemIndex,
    onProblemIndexChange,
    onSubmit,
  }: Props<T>) => {
    const swiperController = useSwiperControllerContext(SwiperControllerConsumerName)
    const [isOpenedDrawer, setIsOpenedDrawer] = useState(false)

    const initSwiperInstance = (swiper: SwiperCore) => {
      swiperController.setSwiper(swiper)
    }

    if (!problemScoringColl || !viewOption || !type) {
      return null
    }

    return (
      <>
        <Swiper
          modules={[Navigation, Controller]}
          slidesPerView={problemScoringColl.toArr.length <= 2 ? 1 : 'auto'}
          spaceBetween={100}
          touchAngle={30}
          threshold={6}
          grabCursor
          controller={{ control: swiperController.control }}
          navigation={{
            prevEl: '.ui__SwiperController_prevButton',
            nextEl: '.ui__SwiperController_nextButton',
          }}
          style={{
            width: '100%',
            height: '100%',
          }}
          onInit={initSwiperInstance}
          initialSlide={problemIndex || 0}
          onSlideChange={(swiper) => {
            onProblemIndexChange?.(swiper.activeIndex)
            CustomEventService.tooltipOff.dispatch() // 슬라이드 변경시 키패드 툴팁을 닫기 위한 로직
            virtualKeyboardService.close()
          }}
        >
          {problemScoringColl.toArr.map((problemScoring, index) => {
            const isLastProblem = index + 1 === problemScoringColl.toArr.length

            return (
              <SwiperSlide key={problemScoring.id}>
                <S.Container>
                  <WorksheetScoringCardMobile
                    problemScoring={problemScoring}
                    type={type}
                    viewOption={viewOption}
                  />
                  <div className="button-container">
                    {type !== WorksheetDomain.TYPE.자기주도학습 && (
                      <Button
                        theme="white"
                        onClick={() => setIsOpenedDrawer(true)}
                        style={{ marginRight: 12 }}
                      >
                        문제 풀이 현황
                      </Button>
                    )}
                    <SubmitButton
                      isLastProblem={isLastProblem}
                      isLastScreen={isLastScreen}
                      problemScoring={problemScoring}
                      studentWorksheet={studentWorksheet}
                      type={type}
                      onNextOrSubmit={() => {
                        if (isLastProblem) {
                          onSubmit?.()
                          return
                        }
                        swiperController.control?.slideNext()
                      }}
                      disabled={isLastProblem && problemScoringColl.toScoredArr.length === 0}
                    />
                  </div>
                </S.Container>
              </SwiperSlide>
            )
          })}
        </Swiper>
        <Drawer
          isOpened={isOpenedDrawer}
          closeDrawer={() => {
            setIsOpenedDrawer(false)
          }}
          size="570px"
        >
          <Drawer.Content>
            <Drawer.Header>문제 풀이 현황</Drawer.Header>
            <Drawer.Body>
              <ProblemSolvingStatus
                onClickItem={() => setIsOpenedDrawer(false)}
                problemScorings={problemScoringColl.toArr}
              />
            </Drawer.Body>
          </Drawer.Content>
        </Drawer>
      </>
    )
  },
)

const SubmitButton = ({
  isLastProblem,
  problemScoring,
  isLastScreen,
  onNextOrSubmit,
  studentWorksheet,
  disabled,
  type,
}: {
  isLastProblem: boolean
  problemScoring: ProblemScoring<'WORKSHEET'>
  isLastScreen: boolean | undefined
  onNextOrSubmit: () => void
  studentWorksheet: StudentWorksheetEntity.StudentWorksheet | null | undefined
  disabled: boolean
  type: WorksheetEntity.Worksheet['type']
}) => {
  const isSubmitted = studentWorksheet?.status === 'COMPLETE'

  if (type === WorksheetDomain.TYPE.챌린지) {
    return (
      <Button
        onClick={onNextOrSubmit}
        theme="primary"
        type="submit"
        style={{
          flexGrow: '1',
        }}
      >
        {isLastProblem ? (isSubmitted ? '다음 단계' : '제출하기') : '다음'}
      </Button>
    )
  }

  if (type === WorksheetDomain.TYPE.자기주도학습) {
    return (
      <Button onClick={onNextOrSubmit} theme="primary" type="submit" style={{ flexGrow: '1' }}>
        {!problemScoring.isSubmitted && isLastScreen && isLastProblem ? '제출하기' : '다음'}
      </Button>
    )
  }

  return (
    <Button
      onClick={onNextOrSubmit}
      theme="primary"
      type="submit"
      style={{
        flexGrow: '1',
        ...(disabled && { backgroundColor: '#395FE24D' }),
      }}
    >
      {isLastProblem ? '제출하기' : '다음'}
    </Button>
  )
}

const S = {
  Container: styled.section`
    display: flex;
    flex-direction: column;
    height: 100%;
    gap: 20px;

    .button-container {
      display: flex;
      height: 50px;
    }
  `,
}
