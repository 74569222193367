import type { HandwrittenNoteTypes } from '@mathflat/handwritten-note'
import * as Sentry from '@sentry/react'
import axios, { type AxiosResponse } from 'axios'
import { toPng } from 'html-to-image'

import { errorHandlerService } from '~/@common/services'
import type { StudentWorksheetNoteValue } from '~/@common/services/indexedDb.service'

import maxios from '../utils/maxios'

export enum HandwrittenNoteType {
  STUDENT_WORKSHEET_SCORING = 'STUDENT_WORKSHEET_SCORING',
  STUDENT_EXAM_SCORING = 'STUDENT_EXAM_SCORING',
  SIGNATURE_WORKBOOK_PAGE = 'SIGNATURE_WORKBOOK_PAGE',
}

type NoteData = HandwrittenNoteTypes.NoteData

interface NoteParams {
  id: number
  subId: number
  type: HandwrittenNoteType
}

interface PresignedUrlResponse extends NoteParams {
  presignedUrl: string
}

interface HandwrittenNoteResponse extends NoteParams {
  handwrittenNoteUrl: string | null
}

interface SignatureWorkbookNoteParams {
  studentWorkbookRevisionId: number
  pageNumber: number
}

interface Api {
  fetchNoteByUrl: (jsonUrl: string) => Promise<NoteData>
  fetchSignatureWorkbookNote: (params: SignatureWorkbookNoteParams) => Promise<NoteData | undefined>
  uploadSignatureWorkbookNote: (
    params: SignatureWorkbookNoteParams,
    data: NoteData,
  ) => Promise<void>
  uploadStudentWorksheetNotes: (
    noteValues: StudentWorksheetNoteValue[],
    noteType: HandwrittenNoteType,
  ) => Promise<void>
  uploadStudentWorksheetPng: (data: NoteParams) => Promise<string>
}

export const handwrittenNoteApi = ((): Api => {
  const _fetchNote = async ({ id, type, subId }: NoteParams) => {
    const { data } = await maxios.get<HandwrittenNoteResponse>(`/handwritten-note/${id}/${type}`, {
      params: {
        subId,
      },
    })
    if (data.handwrittenNoteUrl) {
      return await fetchNoteByUrl(data.handwrittenNoteUrl)
    }
  }

  const _fetchPresignedUrls = async (params: NoteParams[]) => {
    const { data } = await maxios.post<Array<PresignedUrlResponse>>(
      '/handwritten-note/presigned-url',
      params,
    )
    return data
  }

  const _uploadNote = async (url: string, noteData: NoteData) => {
    return await maxios.put<void>(url, JSON.stringify(noteData))
  }

  // S3 업로드가 성공했음을 DB에 저장
  const _saveNoteUrl = async (params: NoteParams, uploadUrl: string) => {
    await maxios.post('/handwritten-note', {
      ...params,
      handwrittenNoteUrl: uploadUrl.split('?')[0],
    })
  }

  const fetchNoteByUrl: Api['fetchNoteByUrl'] = async (jsonUrl) => {
    // 필기 JSON 파일만 허용
    if (!jsonUrl.toLowerCase().endsWith('.json')) {
      throw new Error(`invalid json url: ${jsonUrl}`)
    }

    // maxios가 아니라 axios 사용함
    const { data } = await axios.get<NoteData>(jsonUrl)
    return data
  }

  const fetchSignatureWorkbookNote: Api['fetchSignatureWorkbookNote'] = async (params) => {
    const noteParams = {
      id: params.studentWorkbookRevisionId,
      subId: params.pageNumber,
      type: HandwrittenNoteType.SIGNATURE_WORKBOOK_PAGE,
    }
    return await _fetchNote(noteParams)
  }

  const uploadSignatureWorkbookNote: Api['uploadSignatureWorkbookNote'] = async (
    params,
    data: NoteData,
  ) => {
    const noteParams = {
      id: params.studentWorkbookRevisionId,
      subId: params.pageNumber,
      type: HandwrittenNoteType.SIGNATURE_WORKBOOK_PAGE,
    }
    const presignedUrlData = await _fetchPresignedUrls([noteParams])

    if (!presignedUrlData?.length) {
      throw new Error('no presignedUrlData')
    }

    const uploadUrl = presignedUrlData[0].presignedUrl

    await _uploadNote(uploadUrl, data)
    await _saveNoteUrl(noteParams, uploadUrl)
  }

  const uploadStudentWorksheetNotes: Api['uploadStudentWorksheetNotes'] = async (
    noteValues,
    noteType,
  ) => {
    if (
      noteType !== HandwrittenNoteType.STUDENT_WORKSHEET_SCORING &&
      noteType !== HandwrittenNoteType.STUDENT_EXAM_SCORING
    ) {
      throw new Error(`invalid noteType: ${noteType}`)
    }

    const validNoteValues = noteValues.filter((item) => item.noteData.paths.length > 0)

    if (!validNoteValues.length) {
      return
    }

    interface UploadItem {
      uploadUrl: string
      type: HandwrittenNoteType
      noteValue?: StudentWorksheetNoteValue
      uploadTask?: Promise<AxiosResponse<void, unknown>>
      isSuccess?: boolean
    }

    // 업로드 결과에 따라서 uploadItems 배열 내 객체의 isSuccess를 변경한 후 다시 return
    const uploadNoteItems = async (uploadItems: UploadItem[]) => {
      const validUploadItems = uploadItems.filter((item) =>
        Boolean(item.noteValue && item.uploadTask),
      )

      if (validUploadItems.length) {
        const result = await Promise.allSettled(validUploadItems.map((item) => item.uploadTask))

        result.forEach((item, index) => {
          // 업로드 성공하면 S3에서 200 응답, promsie가 reject되지 않으면 성공으로 간주
          if (item.status === 'fulfilled') {
            validUploadItems[index].isSuccess = true
          }
        })
      }
      return uploadItems
    }

    const getFailedUploadItems = (uploadItems: UploadItem[]) => {
      return uploadItems.filter((item) => !item.isSuccess)
    }

    const getValidNoteValueMapKey = (studentWorksheetId: number, worksheetProblemId: number) =>
      `${studentWorksheetId}:${worksheetProblemId}`

    const uploadUrlData = await _fetchPresignedUrls(
      validNoteValues.map(({ studentWorksheetId, worksheetProblemId }) => {
        return {
          id: studentWorksheetId,
          subId: worksheetProblemId,
          type: noteType,
        }
      }),
    )

    const validNoteValueMap: Map<string, StudentWorksheetNoteValue> = new Map(
      validNoteValues.map((noteValue) => [
        getValidNoteValueMapKey(noteValue.studentWorksheetId, noteValue.worksheetProblemId),
        noteValue,
      ]),
    )

    const uploadItems: UploadItem[] = uploadUrlData.map((item) => {
      const noteValue = validNoteValueMap.get(getValidNoteValueMapKey(item.id, item.subId))
      let uploadTask: Promise<AxiosResponse<void, unknown>> | undefined

      if (noteValue) {
        uploadTask = _uploadNote(item.presignedUrl, noteValue.noteData)
      }

      return {
        uploadUrl: item.presignedUrl,
        type: item.type,
        noteValue,
        uploadTask,
      }
    })

    let uploadResult = await uploadNoteItems(uploadItems)
    let failedUploadItems = getFailedUploadItems(uploadResult)

    // 최초 업로드 실패하면 재시도
    if (failedUploadItems.length) {
      uploadResult = await uploadNoteItems(failedUploadItems)
    }

    const uploadedItems = uploadResult.filter((item) => item.isSuccess && item.noteValue)

    if (uploadedItems.length) {
      // 업로드 성공을 BE에 전송 (실패하더라도 S3 트리거에서 중복으로 처리함)
      const postUploadTasks = uploadedItems.map((item) => {
        if (!item.noteValue) {
          throw new Error('no noteValue')
        }
        const noteParams = {
          id: item.noteValue.studentWorksheetId,
          subId: item.noteValue.worksheetProblemId,
          type: item.type,
        }
        return _saveNoteUrl(noteParams, item.uploadUrl)
      })
      await Promise.allSettled(postUploadTasks)
    }

    failedUploadItems = getFailedUploadItems(uploadResult)

    if (failedUploadItems.length) {
      try {
        Sentry.captureEvent({
          fingerprint: ['handwrittenNoteApi', 'failedUploadItems'],
          extra: {
            total: uploadItems.length,
            failed: failedUploadItems.length,
          },
        })
      } catch (err) {
        // ignore error
      }
    }
  }

  const _fetchTempPresignedUrls = async (params: NoteParams[]) => {
    const { data } = await maxios.post<PresignedUrlResponse>(
      '/handwritten-note/presigned-url/temp',
      params,
    )
    return data
  }

  const _uploadPng = async (url: string, file: File) => {
    return await maxios.put<void>(url, file)
  }

  const _dataURLtoBlob = (dataUrl: string) => {
    const arr = dataUrl.split(',')
    const mime = arr[0].match(/:(.*?);/)?.[1]
    const bstr = atob(arr[1])
    let n = bstr.length
    const u8arr = new Uint8Array(n)
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n)
    }
    return new Blob([u8arr], { type: mime })
  }

  const _blobToFile = (blob: Blob, fileName: string) => {
    return new File([blob], fileName, { type: blob.type })
  }

  const _toPngAndUpload = async (url: string) => {
    const activeSwiper = document.querySelector('.swiper-slide-active')
    const scalableContent = activeSwiper?.querySelector(
      '[data-component="mn__ScalableContent"]',
    ) as HTMLElement

    const problemArea = activeSwiper?.querySelector('.problem-area') as HTMLElement

    try {
      const dataUrl = await toPng(scalableContent ?? problemArea, {
        cacheBust: true,
        skipFonts: true,
        pixelRatio: 0.5,
      })
      const blob = _dataURLtoBlob(dataUrl)
      const file = _blobToFile(blob, 'handWritten.png')
      return await _uploadPng(url, file)
    } catch (err) {
      errorHandlerService.handle(err)
    }
  }

  const uploadStudentWorksheetPng = async (data: NoteParams) => {
    const presignedUrlData = await _fetchTempPresignedUrls([data])

    if (presignedUrlData.presignedUrl.length < 1) {
      throw new Error('no presignedUrlData')
    }

    const uploadUrl = presignedUrlData.presignedUrl

    await _toPngAndUpload(uploadUrl)

    return uploadUrl.split('?')[0]
  }

  return {
    fetchNoteByUrl,
    fetchSignatureWorkbookNote,
    uploadSignatureWorkbookNote,
    uploadStudentWorksheetNotes,
    uploadStudentWorksheetPng,
  }
})()
