import { css } from '@emotion/react'

export const elevation = {
  $01: css`
    box-shadow:
      0px 2px 6px rgba(41, 42, 43, 0.08),
      0px 1px 0px rgba(41, 42, 43, 0.04);
  `,
  $02: css`
    box-shadow:
      0px 4px 8px rgba(41, 42, 43, 0.08),
      0px 12px 16px rgba(41, 42, 43, 0.05);
  `,
  $03: css`
    box-shadow: 0px 12px 16px rgba(41, 42, 43, 0.1);
  `,
  $05: css`
    box-shadow: 0px 24px 32px 0px rgba(41, 42, 43, 0.12);
  `,
} as const
