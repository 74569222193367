import styled from '@emotion/styled'
import { WorksheetDomain } from '@mathflat/domain/@entities'
import type { Entity as WorksheetEntity } from '@mathflat/domain/@entities/(Content)/Worksheet/dto'
import type { Entity as StudentWorksheetEntity } from '@mathflat/domain/@entities/StudentWorksheet/dto'
import { clsx } from 'clsx'
import { observer } from 'mobx-react'
import { type Dispatch, type FC, type ReactNode, type SetStateAction, useState } from 'react'

import modalService from '~/@common/services/modal.service.tsx'
import { mediaQuery } from '~/@common/styles/mediaQuery.tsx'
import Modal from '~/@common/ui/modal/Modal.tsx'
import type { ProblemScoringColl } from '~/@pages/@common/(ProblemScoring)/@trait/ProblemScoring.trait'
import type { ProblemScoringViewOption } from '~/@pages/@common/(ProblemScoring)/@trait/ProblemScoringViewOption.trait'
import WorksheetAiTutorDrawer from '~/@pages/student/learning-process/WorksheetAiTutorDrawer.tsx'

import WorksheetScoringByOneBody from './(Body)/WorksheetScoringByOneBody'
import WorksheetScoringByOneHeader from './(Header)/WorksheetScoringByOneHeader'

export type WorksheetIdParsedFromUrlParam = WorksheetEntity.CustomWorksheet['id'] | undefined

type NotFetched = undefined

type Props<T extends '출제' | '미출제'> = {
  worksheet: WorksheetEntity.Worksheet | NotFetched
  studentWorksheet: (T extends '출제' ? StudentWorksheetEntity.StudentWorksheet : null) | NotFetched
  problemScoringColl: ProblemScoringColl<'WORKSHEET'> | NotFetched
  viewOption: ProblemScoringViewOption<'학습모듈' | 'NOT_학습모듈'> | NotFetched
  isLastScreen?: boolean
  problemIndex?: number
  onProblemIndexChange?: (index: number) => void
  onSubmit: () => void
  isAiTutorOpened?: boolean
  setIsAiTutorOpened?: Dispatch<SetStateAction<boolean>>
  containerTopNode?: ReactNode
}

const WorksheetScoringByOne = observer(
  <T extends '출제' | '미출제'>({
    isLastScreen,
    problemScoringColl,
    worksheet,
    viewOption,
    studentWorksheet,
    problemIndex,
    onProblemIndexChange,
    onSubmit,
    isAiTutorOpened,
    setIsAiTutorOpened,
    containerTopNode,
  }: Props<T>) => {
    const [isProblemSolvingStatusShown, setIsProblemSolvingStatusShown] = useState(false)

    if (!worksheet || !problemScoringColl || !viewOption) {
      return <S.WorksheetScoringByOne />
    }

    const openExitConfirmationAiTutor = ({ onConfirm }: { onConfirm: () => void }) => {
      modalService.openModal(
        <Modal.Confirm.Positive
          cancel={{
            children: '돌아가기',
            onClick: () => {
              modalService.closeModal()
            },
          }}
          confirm={{
            children: '이동하기',
            onClick: () => {
              modalService.closeModal()
              setIsAiTutorOpened?.(false)
              onConfirm()
            },
          }}
        >
          <p>
            AI 튜터가 아직 진행 중입니다.
            <br />
            그래도 이동할까요?
            <br />
            (이동 시 데이터는 사라집니다.)
          </p>
        </Modal.Confirm.Positive>,
        {
          modalName: 'AI 튜터 종료하기',
        },
      )
      return
    }

    return (
      <>
        <S.WorksheetScoringByOne
          className={clsx(
            isAiTutorOpened && !isProblemSolvingStatusShown && 'is-ai-tutor-opened',
            worksheet.type === WorksheetDomain.TYPE.자기주도학습 && 'wrongLearning',
          )}
        >
          {worksheet.type !== 'CONCEPTUAL' && (
            <WorksheetScoringByOneHeader
              type={worksheet.type}
              totalProblemCount={problemScoringColl?.toArr.length}
              isProblemSolvingStatusShown={isProblemSolvingStatusShown}
              isAiTutorOpened={isAiTutorOpened}
              setIsProblemSolvingStatusShown={setIsProblemSolvingStatusShown}
              problemScoringColl={problemScoringColl}
              problemIndex={problemIndex}
              setIsAiTutorOpened={setIsAiTutorOpened}
              openExitConfirmationAiTutor={openExitConfirmationAiTutor}
            />
          )}
          {/* TODO: 학생앱, 학부모앱 페이지 제대로 나누기 -  */}
          <WorksheetScoringByOneBody
            isProblemSolvingStatusShown={isProblemSolvingStatusShown}
            onSubmit={onSubmit}
            isLastScreen={isLastScreen}
            problemScoringColl={problemScoringColl}
            viewOption={viewOption}
            worksheet={worksheet}
            studentWorksheet={studentWorksheet}
            problemIndex={problemIndex}
            onProblemIndexChange={onProblemIndexChange}
            isAiTutorOpened={isAiTutorOpened}
            openExitConfirmationAiTutor={openExitConfirmationAiTutor}
            containerTopNode={containerTopNode}
          />
        </S.WorksheetScoringByOne>
        <WorksheetAiTutorDrawer
          isOpened={isAiTutorOpened}
          closeDrawer={() => setIsAiTutorOpened?.(false)}
          problemScoring={problemScoringColl.toArr[problemIndex ?? 0]}
        />
      </>
    )
  },
)

export default WorksheetScoringByOne

const S = {
  WorksheetScoringByOne: styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;
    height: 100%;
    margin-left: auto;

    &.is-ai-tutor-opened {
      max-width: calc(50vw + 48px);

      ${mediaQuery.underDesktop} {
        min-width: 689px;
      }

      &.wrongLearning {
        background-color: #fff;
        border-radius: 14px 0 14px 14px;
      }
    }

    .student-worksheet-contents {
      display: flex;
      gap: 25px;
      overflow: hidden;
      flex: 1 1 100%;
    }

    .note-toolbar {
      position: relative;
      z-index: 10;
      margin-left: auto;
    }
  `,
}
