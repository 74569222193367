import { css } from '@emotion/react'
import { observer } from 'mobx-react'
import { useEffect } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'

import { routeName } from '~/@common/constants'
import { useLearningTime } from '~/@common/hooks/useLearningTime.ts'
import { useRepository } from '~/@common/hooks/useRepository'
import { errorHandlerService } from '~/@common/services'
import { useLastLocationManager } from '~/@common/services/(LastLocationManager)'
import modalService from '~/@common/services/modal.service.tsx'
import Modal from '~/@common/ui/modal/Modal.tsx'
import { PortalRootLayoutHeader } from '~/@common/ui/PortalRootLayoutHeader'
import { LearningProcessService } from '~/@pages/student/learning-process/@widgets/service/LearningProcess.service'
import 개념학습 from '~/@pages/student/learning-process/@widgets/ui/(step)/개념학습'
import 결과확인 from '~/@pages/student/learning-process/@widgets/ui/(step)/결과확인'
import 문제풀이 from '~/@pages/student/learning-process/@widgets/ui/(step)/문제풀이/문제풀이'
import 오답유형학습 from '~/@pages/student/learning-process/@widgets/ui/(step)/오답유형학습'
import LearningProcessNavigation from '~/@pages/student/learning-process/@widgets/ui/LearingProcessNavigation/LearningProcessNavigation.tsx'
import type {
  Items,
  SelectedItem,
} from '~/@pages/student/learning-process/@widgets/ui/LearingProcessNavigation/types/LearningProcessNavigation.type.ts'

import type { ConstructedReference } from './@common/hooks/useGetLearningProcessReference'

const LearningProcess = () => {
  useLearningTime()
  const [searchParams, setSearchParams] = useSearchParams()
  const reference = searchParams.get('reference')
  const navigate = useNavigate()
  const locationManager = useLastLocationManager()

  const service = useRepository(LearningProcessService)

  useEffect(() => {
    if (reference) {
      service.initProcess(reference as ConstructedReference)
    }

    return () => {
      service.resetProcess()
    }
  }, [reference])

  const renderStepView = () => {
    const step = service.process[service.currentStep]?.step

    switch (step) {
      case '개념학습':
        return <개념학습 />
      case '문제풀이':
        return <문제풀이 />
      case '결과확인':
        return <결과확인 />
      case '오답 유형학습':
        return <오답유형학습 />
    }
  }

  const processItem = service.process.map((step, index) => {
    const children =
      step.screen.length > 1
        ? step.screen.map((eachScreen, index) => ({ label: eachScreen.title, value: index }))
        : undefined
    const newStep: Items[number] = {
      label: step.step,
      value: index,
      disabled: step.screen.length < 1,
    }

    if (children) {
      newStep.children = children
    }

    return newStep
  })

  const selectedItem = {
    parent: processItem.filter((item) => item.value === service.currentStep)[0],
    children: processItem.filter((item) => item.value === service.currentStep)[0]?.children
      ? processItem
          .filter((item) => item.value === service.currentStep)[0]
          .children?.filter((child) => child.value === service.currentStatus.screen)[0]
      : undefined,
  }

  const 모든_문제_정답_여부 = service.학습모듈_문제풀이.problemScoringColl?.toArr.every(
    (problemScoring) => problemScoring.채점결과 === 'CORRECT',
  )
  const 문제풀이_완료_및_오답유형학습_없음_여부 =
    !service.학습모듈_문제풀이.problemScoringColl?.isNothingSubmitted &&
    !service.process.find((eachStep) => eachStep.step === '오답 유형학습')

  const 문제풀이_완료_및_오답유형학습_완료_여부 =
    (!service.학습모듈_문제풀이.problemScoringColl?.isNothingSubmitted ||
      !service.process.find((eachStep) => eachStep.step === '문제풀이')) &&
    service.isAllScoredPairSimilarWorksheet

  const 학습종료_여부 =
    모든_문제_정답_여부 ||
    문제풀이_완료_및_오답유형학습_없음_여부 ||
    문제풀이_완료_및_오답유형학습_완료_여부

  const navigateBack = () => {
    const defaultPrevRouteName = routeName.student.defaultPath

    if (!locationManager.prevState?.location.pathname) {
      navigate(defaultPrevRouteName)
      return
    }
    navigate(-1)
  }

  const onClickParent = (item: SelectedItem) => {
    const 문제풀이_완료 = !service.학습모듈_문제풀이.problemScoringColl?.isNothingSubmitted
    if (item.parent.disabled) {
      문제풀이_완료
        ? 모든_문제_정답_여부
          ? modalService.openModal(
              <Modal.Alert
                confirm={{
                  children: '종료하기',
                  onClick: async () => {
                    try {
                      if (service.relationId) {
                        service.챌린지학습_완료하기(+service.relationId)
                        modalService.closeModal()
                        navigate(-1)
                      }
                    } catch (e) {
                      errorHandlerService.handle(e)
                    }
                  },
                }}
              >
                <>
                  모든 문제를 다 맞았어요! <br />
                  학습을 종료할까요?
                </>
              </Modal.Alert>,
              { modalName: '자기주도학습_비활성화_단계_학습종료' },
            )
          : modalService.openModal(
              <Modal.Alert
                confirm={{
                  children: '시작하기',
                  onClick: async () => {
                    try {
                      await service.오답_유형학습_시작하기()
                      modalService.closeModal()
                    } catch (e) {
                      errorHandlerService.handle(e)
                    }
                  },
                }}
              >
                <>오답 유형학습을 시작할까요?</>
              </Modal.Alert>,
              { modalName: '자기주도학습_비활성화_단계_오답유형시작' },
            )
        : modalService.openModal(
            <Modal.Alert
              confirm={{
                onClick: () => modalService.closeModal(),
              }}
            >
              <>문제를 먼저 다 푼 후 확인할 수 있습니다.</>
            </Modal.Alert>,
            { modalName: '자기주도학습_비활성화_단계_기본' },
          )
      return
    }

    service.changeStep(Number(item.parent.value))
  }

  useEffect(() => {
    // (데이터 트래킹) 현재 스텝을 searchParams에 반영만 하지,
    // searchparams이 세팅된다고 그 쪽으로 이동하진 않는다.
    if (service.process[service.currentStep]?.step) {
      setSearchParams(
        (prev) => {
          prev.set('step', service.process[service.currentStep]?.step)
          return prev
        },
        { replace: true },
      )
    }
  }, [service.currentStep, service.process])

  return (
    <>
      <PortalRootLayoutHeader>
        <LearningProcessNavigation
          title={service.chapterName}
          items={processItem}
          selectedItem={selectedItem}
          onClickItem={(item) =>
            !item?.children
              ? onClickParent(item)
              : service.changeStepAndScreen(Number(item.parent.value), Number(item.children.value))
          }
          onClickBack={(e) => {
            e.preventDefault()

            if (학습종료_여부) {
              navigateBack()
              return
            }
            modalService.openModal(
              <Modal.Confirm.Positive
                cancel={{
                  children: '돌아가기',
                  onClick: () => {
                    modalService.closeModal()
                  },
                }}
                confirm={{
                  children: '종료하기',
                  onClick: () => {
                    navigateBack()
                    modalService.closeModal()
                  },
                }}
              >
                <p>
                  학습 중인 내용이 아직 남아있어요.
                  <br />
                  그래도 종료할까요?
                  <br />
                  (학습 중인 데이터는 임시저장됩니다.)
                </p>
              </Modal.Confirm.Positive>,
              {
                modalName: '자기주도학습 나가기',
              },
            )
          }}
          onClickNext={() => service.goToNextStep()}
          onClickPrev={() => service.goToPrevStep()}
        />
      </PortalRootLayoutHeader>
      <div css={_css}>{renderStepView()}</div>
    </>
  )
}

export default observer(LearningProcess)

const _css = css`
  display: flex;
  flex-direction: column;
  height: 100%;
`
