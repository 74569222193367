import type { MathflatApi } from '@mathflat/domain/@entities/Login/api'
import type { StudentDomain } from '@mathflat/domain/@entities/Student/domain.ts'
import { BroadcastChannel } from 'broadcast-channel'

import { loadInitialData } from '~/@app/init/loadInitialData'
import chatAxios from '~/@common/utils/chatAxios.ts'
import maxios from '~/@common/utils/maxios'

import { routeName } from '../constants'
import { envUtils } from '../utils/envUtils'
import { Logger } from '../utils/logger'
import { webviewService } from '.'
import { localStorageService } from './storage.service'

const token = Symbol('token')

const setTokenFromAxios = (newToken: string) => {
  maxios.defaults.headers.common['x-auth-token'] = newToken
  chatAxios.defaults.headers.common['x-auth-token'] = newToken
}

const deleteTokenFromAxios = () => {
  delete maxios.defaults.headers.common['x-auth-token']
  delete chatAxios.defaults.headers.common['x-auth-token']
}

// 토큰은 로컬스토리지서비스, 세션스토리지 서비스에서 관리 안하고 여기에서만 한다.
export class AuthService {
  private _broadcastChannel: BroadcastChannel
  private [token]?: string

  constructor() {
    const localStorageToken = localStorage.getItem('x-auth-token')
    if (localStorageToken) {
      const newToken = localStorageToken.replace(/"/g, '')
      this[token] = newToken
    }
    const sessionStorageToken = sessionStorage.getItem('x-auth-token')
    if (sessionStorageToken) {
      const newToken = sessionStorageToken.replace(/"/g, '')
      this[token] = newToken
    }

    if (this[token]) {
      setTokenFromAxios(this[token])
    }

    this._broadcastChannel = new BroadcastChannel('x-auth-token')
    this._broadcastChannel.onmessage = (data) => {
      switch (data.message) {
        case 'REQUEST_TOKEN':
          if (this[token]) {
            this._broadcastChannel.postMessage({
              message: 'RESPONSE_TOKEN',
              payload: { token: this[token], studentId: localStorage.getItem('studentId') },
            })
          } else {
            this._broadcastChannel.postMessage({ message: 'RESPONSE_TOKEN_NOT_FOUND' })
          }
          break
        case 'RESPONSE_TOKEN_NOT_FOUND':
          if (window.location.pathname !== routeName.login) {
            window.location.pathname = routeName.login
          }
          break
        case 'RESPONSE_TOKEN':
          if (!this[token]) {
            this.setSessionStorageToken(data.payload.token)
            localStorage.setItem('studentId', data.payload.studentId)
            window.location.reload()
          }
          break
        case 'REQUEST_LOGOUT':
          this.logout()
          if (window.location.pathname !== routeName.login) {
            window.location.pathname = routeName.login
          }
      }
    }
  }

  get userType() {
    if (localStorageService.user.parentId) {
      return 'PARENT'
    }
    return 'STUDENT'
  }

  get isLoggedIn() {
    return !!this[token] && maxios.defaults.headers.common['x-auth-token']
  }

  get localStorageToken() {
    const token = localStorage.getItem('token')
    return token ? token.replace(/"/g, '') : null
  }

  get sessionStorageToken() {
    const token = sessionStorage.getItem('token')
    return token ? token.replace(/"/g, '') : null
  }

  get token() {
    return this[token]
  }

  async login({
    data,
    beforeLoginId,
    isKeepSignInChecked,
  }: {
    data: MathflatApi.Response.Login
    beforeLoginId: string
    isKeepSignInChecked?: boolean
  }) {
    Logger.setAuthorizedUser(data)

    setTokenFromAxios(data.token)
    localStorageService.update({
      user: {
        beforeLoginId,
        ...(data.userType === 'STUDENT'
          ? { studentId: data.relationId as StudentDomain.Id }
          : { parentId: data.relationId }),
        academyId: data.academyId,
      },
    })

    if (isKeepSignInChecked) {
      this.setLocalStorageToken(data.token)
    } else {
      this.setSessionStorageToken(data.token)
    }

    if (data.userType === 'STUDENT') {
      loadInitialData(data.relationId as StudentDomain.Id)
    }
  }

  logout() {
    Logger.setUnauthorizedUser()

    localStorageService.clear()
    sessionStorage.clear()
    webviewService.logout()
    this.clearToken()
    deleteTokenFromAxios()

    envUtils.redirectToBasicStudentApp()
  }

  setLocalStorageToken(newToken: string) {
    localStorage.setItem('x-auth-token', newToken)
    setTokenFromAxios(newToken)

    this[token] = newToken
  }

  setSessionStorageToken(newToken: string) {
    sessionStorage.setItem('x-auth-token', newToken)

    setTokenFromAxios(newToken)
    this[token] = newToken
  }

  clearLocalStorageToken() {
    localStorage.removeItem('x-auth-token')
    deleteTokenFromAxios()
    this[token] = undefined
  }

  clearSessionStorageToken() {
    sessionStorage.removeItem('x-auth-token')
    deleteTokenFromAxios()
    this[token] = undefined
  }

  clearToken() {
    this.clearLocalStorageToken()
    this.clearSessionStorageToken()
  }

  requestToken() {
    this._broadcastChannel.postMessage({ message: 'REQUEST_TOKEN' })
  }

  requestLogout() {
    this._broadcastChannel.postMessage({ message: 'REQUEST_LOGOUT' })
  }
}

const authService = new AuthService()
export default authService
