import { makeAutoObservable } from 'mobx'

import type { Point, TimeStamp } from '../../types'
import type { NoteData } from '../handwrittenNote.types'
import { pointToRawPoint } from '../handwrittenNote.utils'
import HandwrittenNoteEraserItem from './HandwrittenNoteEraserItem'
import type { PathType } from './HandwrittenNotePathItem'
import HandwrittenNotePathItem from './HandwrittenNotePathItem'

const MIN_PATH_PX = 10
const DATA_PATH_ID_ATTR = 'data-path-id'

export interface HandwrittenNoteItem {
  id: number
  undo: () => void
  redo: () => void
}

export default class HandwrittenNoteData {
  items: HandwrittenNoteItem[] = []
  lastModified: TimeStamp = 0

  private itemId = 0
  private activeItemId = 0
  private undoIndex = -1

  constructor() {
    makeAutoObservable(this)
  }

  private get lastItem() {
    return this.items[this.items.length - 1]
  }

  private get pathItems() {
    return this.items.filter(
      (item) => item instanceof HandwrittenNotePathItem,
    ) as HandwrittenNotePathItem[]
  }

  get visiblePathItems() {
    return this.pathItems.filter((item) => !item.isDeleted)
  }

  get status() {
    return {
      canUndo: this.undoIndex > -1,
      canRedo: this.undoIndex !== this.items.length - 1,
      canEraseAll: Boolean(this.visiblePathItems.length),
    }
  }

  updateLastModified() {
    this.lastModified = new Date().getTime()
  }

  private syncUndoIndex() {
    this.undoIndex = this.items.length - 1
  }

  private clearRedoItems() {
    if (this.status.canRedo) {
      this.items.splice(this.undoIndex + 1)
    }
  }

  addPathItem(
    point: Point,
    width: number,
    color: string,
    pathType: PathType,
    useSubPixel?: boolean,
    pixelRatio?: number,
  ) {
    this.activeItemId = ++this.itemId
    this.clearRedoItems()
    this.items.push(
      new HandwrittenNotePathItem(
        this.activeItemId,
        [pointToRawPoint(point)],
        width,
        color,
        pathType,
        useSubPixel,
        undefined,
        new Date().getTime(),
        undefined,
        pixelRatio ?? 1,
      ),
    )
    this.syncUndoIndex()
  }

  addEraserItem(point: Point) {
    this.activeItemId = ++this.itemId
    this.deletePaths(point)
  }

  addPoint(point: Point) {
    if (this.lastItem instanceof HandwrittenNotePathItem) {
      // const lastPoint = this.lastItem.points[this.lastItem.points.length - 1]
      // if (lastPoint[0] === point.x && lastPoint[1] === point.y) {
      //   return
      // }
      this.lastItem.addPoint(point)
    }
  }

  deletePaths(point: Point) {
    const pathItems: HandwrittenNotePathItem[] = []
    const { x, y } = point
    let el: Element | null

    while ((el = document.elementFromPoint(x, y))) {
      if (el.tagName !== 'path') {
        break
      }
      ;(el as SVGPathElement).style.display = 'none'
      const itemId = Number(el.getAttribute(DATA_PATH_ID_ATTR))

      if (Number.isNaN(itemId)) {
        continue
      }

      const deletedItem = this.visiblePathItems.find((item) => item.id === itemId)

      if (!deletedItem) {
        return
      }

      deletedItem.setIsDeleted(true)
      pathItems.push(deletedItem)
    }
    if (!pathItems.length) {
      return
    }

    if (this.activeItemId !== this.lastItem.id) {
      this.clearRedoItems()
      this.items.push(new HandwrittenNoteEraserItem(this.activeItemId))
      this.syncUndoIndex()
    }

    if (this.lastItem instanceof HandwrittenNoteEraserItem) {
      this.lastItem.addPathItems(pathItems)
    }
  }

  handleInvalidPathItems() {
    const isValid = this.visiblePathItems.some((item) => {
      // item.pathRect이 없는 경우도 valid로 처리
      return (
        !item.pathRect || item.pathRect.width > MIN_PATH_PX || item.pathRect.height > MIN_PATH_PX
      )
    })
    if (!isValid) {
      this.eraseAll()
    }
  }

  checkModified() {
    if (!this.lastItem) {
      return
    }
    if (this.activeItemId === this.lastItem.id) {
      this.updateLastModified()
    }
    this.activeItemId = 0
  }

  undo() {
    const targetItem = this.items[this.undoIndex]
    if (!targetItem) {
      return
    }
    targetItem.undo()
    this.undoIndex--
    this.updateLastModified()
  }

  redo() {
    const newUndoIndex = this.undoIndex + 1
    const targetItem = this.items[newUndoIndex]
    if (!targetItem) {
      return
    }
    this.undoIndex = newUndoIndex
    targetItem.redo()
    this.updateLastModified()
  }

  eraseAll() {
    const eraserItem = new HandwrittenNoteEraserItem(this.activeItemId)
    const penItems: HandwrittenNotePathItem[] = []

    if (this.status.canRedo) {
      this.items.splice(this.undoIndex + 1)
    }

    this.items.forEach((item) => {
      if (item instanceof HandwrittenNotePathItem && !item.isDeleted) {
        penItems.push(item)
        item.setIsDeleted(true)
      }
    })
    eraserItem.addPathItems(penItems)
    this.items.push(eraserItem)
    this.syncUndoIndex()
    this.updateLastModified()
  }

  setPathRect() {
    if (this.lastItem instanceof HandwrittenNotePathItem) {
      this.lastItem.setPathRect()
    }
  }

  toPathDataItems() {
    return this.visiblePathItems.map((item) => item.toData())
  }

  fromJSON(data: NoteData) {
    if (!data.paths.length) {
      return
    }
    const pathItems = data.paths.map(HandwrittenNotePathItem.fromData)
    this.items = pathItems
    this.itemId = this.lastItem.id
    this.syncUndoIndex()
  }

  reset() {
    this.items = []
    this.itemId = 0
    this.activeItemId = 0
    this.undoIndex = -1
    this.lastModified = 0
  }
}
