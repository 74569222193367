import styled from '@emotion/styled'
import clsx from 'clsx'
import { useRef } from 'react'
import { useHotkeys } from 'react-hotkeys-hook'

import { Icon } from '../../Icon/Icon'
import type { PlayerConfig } from '../MathflatPlayer'
// import type { PlayerConfig } from '~old/component/organism/MathflatPlayer/MathflatPlayer.tsx'

type Volume = PlayerConfig['volume']

type Props = {
  volumeStep: Volume
  volume: Volume
  showSliderWhenHover?: boolean
  onChange: (volume: Volume) => void
}

const MAX_VOLUME = 100
const MIN_VOLUME = 0

const VolumeDisplay = ({ volumeStep, volume, showSliderWhenHover = true, onChange }: Props) => {
  const beforeVolume = useRef(volume)

  const handleMute = () => {
    if (volume === 0) {
      onChange(beforeVolume.current)
    } else {
      beforeVolume.current = volume
      onChange(0)
    }
  }

  const handleVolumeUp = () => {
    if (volume === MAX_VOLUME) return

    if (volume >= MAX_VOLUME - volumeStep) {
      onChange(MAX_VOLUME)
    } else {
      onChange(volume + volumeStep)
    }
  }
  const handleVolumeDown = () => {
    if (volume === MIN_VOLUME) return

    if (volume <= MIN_VOLUME + volumeStep) {
      onChange(MIN_VOLUME)
    } else {
      onChange(volume - volumeStep)
    }
  }

  useHotkeys('up', handleVolumeUp)
  useHotkeys('down', handleVolumeDown)

  return (
    <S.VolumeDisplay className={clsx(showSliderWhenHover && 'slider-hide')}>
      <button onClick={handleMute}>
        <Icon
          name={volume === 0 ? 'icon_volume_mute' : 'icon_volume_high'}
          size={22}
          color="#fff"
        />
      </button>
      <S.VolumeSlider
        type="range"
        max={100}
        min={0}
        value={volume}
        className="time-slider"
        onChange={(e) => {
          onChange(Number(e.target.value))
        }}
        onKeyDown={(e) => {
          // slider(input)를 클릭한 채로 ArrowUp/Down의 keyDown 이벤트가 발생하면 value가 1씩 변경되는 이슈가 있음.
          // step으로 구현하는 것보다 모든 동작이 handler를 거칠 수 있게 e.preventDefault()를 실행시킴.

          if (e.key === 'ArrowUp') {
            e.preventDefault()
            handleVolumeUp()
          }
          if (e.key === 'ArrowDown') {
            e.preventDefault()
            handleVolumeDown()
          }
        }}
      />
    </S.VolumeDisplay>
  )
}

const S = {
  VolumeDisplay: styled.div`
    display: flex;
    align-items: center;
    gap: 19px;

    > button {
      padding: 5px;
    }

    &.slider-hide:hover .time-slider {
      width: 120px;
      margin-right: 24px;
      opacity: 1;
    }

    &.slider-hide .time-slider {
      width: 0px;
      opacity: 0;
      transition: width 0.2s cubic-bezier(0.4, 0, 1, 1);
    }
  `,
  VolumeSlider: styled.input<{ value: number }>`
    -webkit-appearance: none;
    display: flex;
    justify-content: center;
    width: 120px;
    background: transparent;
    margin: 0px;
    outline: none;
    cursor: pointer;

    &::-webkit-slider-thumb {
      -webkit-appearance: none;
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background-color: #fff;
      margin-top: -3px;
    }

    &::-webkit-slider-runnable-track {
      -webkit-appearance: none;
      height: 4px;
      background: ${(props) => `
        linear-gradient(
          to right,
          #fff ${props.value}%,
          rgba(232, 232, 232, 0.5) ${props.value}% 100%
        );
        `};
    }
  `,
}

export default VolumeDisplay
