import type { HighSubjectFrom, MiddleGradeSemester } from '~/@entities/(SchoolSystem)/schema.ts'

/**
 * 고등학생 과목(학년) <br/>
 * 고등학생은 학년이 없고 과목만 있다. <br/>
 * 다른 SchoolType의 Grade에 해당
 */
export const SUBJECT_HIGH = {
  '고등수학(상)': '고등수학(상)',
  '고등수학(하)': '고등수학(하)',
  '수학 I': '수학 I',
  '수학 II': '수학 II',
  '확률과 통계': '확률과 통계',
  미적분: '미적분',
  기하: '기하',
} as const

export const SUBJECT_HIGH_SHORT = {
  '고등수학(상)': '수학(상)',
  '고등수학(하)': '수학(하)',
  '수학 I': '수학 I',
  '수학 II': '수학 II',
  '확률과 통계': '확통',
  미적분: '미적분',
  기하: '기하',
} as const

/**
 * 학교 종류
 */
export const SCHOOL_TYPE = {
  // 초등학교
  초등학교: 'ELEMENTARY',
  // 중학교
  중학교: 'MIDDLE',
  // 고등학교
  고등학교: 'HIGH',
} as const

export const HIGH_SUBJECT = {
  '수학(상)': {
    schoolType: 'HIGH',
    grade: '고등수학(상)',
  },
  '수학(하)': {
    schoolType: 'HIGH',
    grade: '고등수학(하)',
  },
  '수학 I': {
    schoolType: 'HIGH',
    grade: '수학 I',
  },
  '수학 II': {
    schoolType: 'HIGH',
    grade: '수학 II',
  },
  확통: {
    schoolType: 'HIGH',
    grade: '확률과 통계',
  },
  미적분: {
    schoolType: 'HIGH',
    grade: '미적분',
  },
  기하: {
    schoolType: 'HIGH',
    grade: '기하',
  },
} satisfies Record<string, HighSubjectFrom>

export const MIDDLE_GRADE_SEMESTER = {
  '중 1-1': {
    schoolType: 'MIDDLE',
    grade: 1,
    semesters: 1,
  },
  '중 1-2': {
    schoolType: 'MIDDLE',
    grade: 1,
    semesters: 2,
  },
  '중 2-1': {
    schoolType: 'MIDDLE',
    grade: 2,
    semesters: 1,
  },
  '중 2-2': {
    schoolType: 'MIDDLE',
    grade: 2,
    semesters: 2,
  },
  '중 3-1': {
    schoolType: 'MIDDLE',
    grade: 3,
    semesters: 1,
  },
  '중 3-2': {
    schoolType: 'MIDDLE',
    grade: 3,
    semesters: 2,
  },
} satisfies Record<string, MiddleGradeSemester>

/**
 * 학교 종류 한글(Short)
 */
export const SCHOOL_TYPE_KO = {
  ELEMENTARY: '초등',
  MIDDLE: '중등',
  HIGH: '고등',
} as const

/**
 * 학교 종류 한글(Full)
 */
export const SCHOOL_TYPE_KO_FULL = {
  ELEMENTARY: '초등학교',
  MIDDLE: '중학교',
  HIGH: '고등학교',
} as const

export const SEMESTER_HIGH = null

/**
 * 개정
 */

export const REVISION = { CURRICULUM_15: 'CURRICULUM_15', CURRICULUM_22: 'CURRICULUM_22' } as const
