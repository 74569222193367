import { css } from '@emotion/react'
import { noop } from 'lodash'

import { useStudentAppMediaQuery } from '~/@common/hooks/useMediaQuery'
import modalService from '~/@common/services/modal.service.tsx'
import { colors, fontWeight, typo } from '~/@common/styles'
import { mediaQuery } from '~/@common/styles/mediaQuery'
import { Drawer } from '~/@common/ui/(Drawer)/BaseDrawer'

export const kind = 'ms__SmileChallengeHelpModal' as const

const title = '스마일 챌린지에 대해 알려드려요'

interface Props {
  isShowDrawer?: boolean
  onCloseDrawer?: () => void
}

const SmileChallengeHelpModal = (props: Props) => {
  const { isMobile } = useStudentAppMediaQuery()

  const InfoContent = (props: { isShow: boolean }) => {
    return (
      props.isShow && (
        <p>
          매쓰플랫이 개개인의 학습 성취도를 분석하여, <br />
          학습 데이터를 바탕으로 추천 학습을 제공합니다.
        </p>
      )
    )
  }

  const imageSuffix = isMobile ? '-mobile' : ''

  const content = (
    <div css={_css}>
      <InfoContent isShow={!isMobile} />
      <div>
        <img src={`/images/smileChallenge/green${imageSuffix}.svg`} alt="최고 등급 도전" />
        <div>
          <b>최고 등급 도전</b>
          <p>
            아쉽게 최고 등급에 도달하지 못한 유형이에요. 한번 더 도전해서 최고등급을 달성해보세요.
          </p>
        </div>
      </div>
      <hr />
      <div>
        <img src={`/images/smileChallenge/red${imageSuffix}.svg`} alt="최고 등급 도전" />
        <div>
          <b>취약 유형 탈출</b>
          <p>취약한 유형을 매쓰플랫이 분석해봤어요. 조금만 더 공부하면 성취도를 올릴 수 있어요.</p>
        </div>
      </div>
    </div>
  )

  if (isMobile) {
    return (
      <Drawer
        isOpened={Boolean(props.isShowDrawer)}
        closeDrawer={() => {
          if (props.onCloseDrawer) {
            props.onCloseDrawer()
          }
        }}
        size="370px"
      >
        <Drawer.Content>
          <Drawer.Header>{title}</Drawer.Header>
          <Drawer.Info>
            <InfoContent isShow={true} />
          </Drawer.Info>
          <Drawer.Body>{content}</Drawer.Body>
        </Drawer.Content>
      </Drawer>
    )
  }

  return content
}

SmileChallengeHelpModal.open = () => {
  return modalService.openModal(
    {
      header: title,
      content: <SmileChallengeHelpModal />,
    },
    {
      modalName: 'SmileChallengeHelpModal',
      modalStyle: {
        padding: '0',
      },
    },
  )
}

const _css = css`
  display: flex;
  flex-direction: column;
  width: 400px;
  padding: 16px 36px 52px;
  overflow-y: auto;

  > p {
    text-align: center;
    margin-bottom: 28px;
  }

  > hr {
    height: 1px;
    width: 100%;
    border: none;
    background-color: ${colors.gray.$200};
    margin: 24px 0;
  }

  > div {
    display: flex;
    height: 154px;

    > div {
      display: flex;
      flex-direction: column;
      align-self: end;
      gap: 10px;
      margin-left: 18px;
    }

    b {
      font-weight: ${fontWeight.bold};
    }

    p {
      ${typo.caption01};
    }
  }

  ${mediaQuery.underTablet} {
    overflow: hidden;
    padding: 0;
    width: 100%;

    > hr {
      margin: 0;
    }

    > div {
      height: auto;
      padding: 20px 30px;
      gap: 14px;

      > div {
        margin-left: 0;
        gap: 4px;
        padding-top: 3px;
        align-self: start;
      }

      b {
        ${typo.body02};
      }
    }
  }
`
export default SmileChallengeHelpModal
