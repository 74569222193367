import { css } from '@emotion/react'
import {
  HandwrittenNoteControllerService,
  HandwrittenNoteModeService,
  type HandwrittenNoteTypes,
  WritableHandwrittenNote,
} from '@mathflat/handwritten-note'
import { observer } from 'mobx-react'
import qs from 'qs'
import {
  type FC,
  type ReactEventHandler,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { isMobile as isMobileDevice } from 'react-device-detect'
import { generatePath, useNavigate, useParams } from 'react-router'
import { useSearchParams } from 'react-router-dom'

import { handwrittenNoteApi } from '~/@common/api/handwrittenNoteApi'
import { routeName, type studentWorkbookDetailPathParams } from '~/@common/constants'
import { useStudentAppMediaQuery } from '~/@common/hooks/useMediaQuery'
import { useRepository } from '~/@common/hooks/useRepository'
import { errorHandlerService } from '~/@common/services'
import { colors } from '~/@common/styles'
import { mediaQuery } from '~/@common/styles/mediaQuery'
import type { Size } from '~/@common/utils/domUtils'

import { StudentWorkbookViewerService } from '../@service/StudentWorkbookViewer.service'
import ImageViewerSubToolbar from './image-viewer/image-viewer-toolbar/ImageViewerSubToolbar'
import ImageViewerToolbarContainer from './image-viewer/image-viewer-toolbar/ImageViewerToolbarContainer'
import type { ImageViewerToolbarPagingProps } from './image-viewer/image-viewer-toolbar/ImageViewerToolbarPaging'
import StudentWorkbookViewerMobileToolbar from './StudentWorkbookViewerMobileToolbar'

const StudentWorkbookViewerContent: FC = () => {
  const { studentWorkbookId, revisionId, pageNumber } =
    useParams<typeof studentWorkbookDetailPathParams>()
  const service = useRepository(StudentWorkbookViewerService)
  const [noteContentSize, setNoteContentSize] = useState<Size>()
  const ref = useRef<HTMLDivElement>(null)
  const [imageUrl, setImageUrl] = useState('')
  const handwrittenNoteModeService = useRepository([
    HandwrittenNoteModeService,
    'signature-workbook',
  ])
  const [noteControllerService] = useState(
    new HandwrittenNoteControllerService(handwrittenNoteModeService),
  )

  const { isMobile } = useStudentAppMediaQuery()
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const searchParamObject = Object.fromEntries(searchParams.entries())
  const [isReady, setIsReady] = useState(false)

  const [timestamp, setTimestamp] = useState<number>()
  const [isResetButtonDisplayed, setIsResetButtonDisplayed] = useState(false)

  const resetNoteScale = () => {
    service.setFitMode('center')
    setTimestamp(Date.now())
    setIsResetButtonDisplayed(false)
  }

  const pageData = service.pageData.get(Number(pageNumber))

  useEffect(() => {
    setIsReady(true)
  }, [])

  const onPageNumberChange = useCallback(
    (value: number | undefined) => {
      if (value === undefined || Number.isNaN(value)) {
        return false
      }
      const pageData = service.pageData.get(value)
      if (!pageData) {
        return false
      }

      const path = generatePath(routeName.student.studentWorkbookViewer, {
        studentWorkbookId,
        revisionId,
        pageNumber: String(value),
      })

      navigate(
        {
          pathname: path,
          search: qs.stringify(searchParamObject),
        },
        { replace: true },
      )
      return true
    },
    [navigate, revisionId, searchParamObject, service.pageData, studentWorkbookId],
  )

  const pagingProps: ImageViewerToolbarPagingProps | undefined = useMemo(() => {
    if (!pageData) {
      return
    }
    return {
      pageNumber: Number(pageNumber),
      prevPageNumber: pageData.prevPageNumber,
      nextPageNumber: pageData.nextPageNumber,
      lastPageNumber: service.lastPageNumber,
      onPageNumberChange,
    }
  }, [onPageNumberChange, pageData, pageNumber, service.lastPageNumber])

  const readNote = useCallback(async () => {
    try {
      const data = await handwrittenNoteApi.fetchSignatureWorkbookNote({
        studentWorkbookRevisionId: Number(revisionId),
        pageNumber: Number(pageNumber),
      })
      if (data) {
        return data
      }
    } catch (err) {
      errorHandlerService.handle(err)
    }
  }, [pageNumber, revisionId])

  const writeNote = useCallback(
    async (data: HandwrittenNoteTypes.NoteData) => {
      try {
        await handwrittenNoteApi.uploadSignatureWorkbookNote(
          {
            studentWorkbookRevisionId: Number(revisionId),
            pageNumber: Number(pageNumber),
          },
          data,
        )
      } catch (err) {
        errorHandlerService.handle(err)
      }
    },
    [pageNumber, revisionId],
  )

  const handleImageLoad: ReactEventHandler<HTMLImageElement> = (e) => {
    setNoteContentSize({
      width: e.currentTarget.width,
      height: e.currentTarget.height,
    })
    setImageUrl(pageData!.imageUrl)
  }

  const handleImageError: ReactEventHandler<HTMLImageElement> = (e) => {
    e.currentTarget.onerror = null
  }

  const isShowViewer = !isMobile || !service.isScoringMode
  const mobileContainerPadding = 30
  const containerPadding = isMobile ? mobileContainerPadding : service.containerPadding

  return (
    <>
      <div
        ref={ref}
        css={_Style}
        style={{
          display: isShowViewer ? undefined : 'none',
        }}
      >
        {isReady && pageData && noteContentSize && ref.current && (
          <>
            <WritableHandwrittenNote
              key={timestamp}
              contentSize={noteContentSize}
              readFunc={readNote}
              writeFunc={writeNote}
              controllerService={noteControllerService}
              fitMode={service.fitMode}
              containerPadding={containerPadding}
              zoomLevel={service.zoomLevel}
              preventOverflow={isMobileDevice}
              onTransformChange={({ changed }) => setIsResetButtonDisplayed(!changed)}
            >
              <img src={imageUrl} alt="교재 이미지" />
            </WritableHandwrittenNote>
            {!isMobile && (
              <ImageViewerToolbarContainer
                containerEl={ref.current}
                containerPadding={10}
                pagingProps={pagingProps}
                controllerService={noteControllerService}
                isDefaultNoteScale={!isResetButtonDisplayed}
                onResetNoteScale={resetNoteScale}
              />
            )}
            <ImageViewerSubToolbar
              controllerService={noteControllerService}
              onResetNoteScale={resetNoteScale}
            />
          </>
        )}
      </div>
      {isMobile && (
        <StudentWorkbookViewerMobileToolbar
          pagingProps={pagingProps}
          isDefaultNoteScale={!isResetButtonDisplayed}
          onResetNoteScale={resetNoteScale}
        />
      )}

      {pageData?.imageUrl && (
        <img
          src={pageData.imageUrl}
          onLoad={handleImageLoad}
          onError={handleImageError}
          alt=""
          css={_preloadImageStyle}
        />
      )}
    </>
  )
}

export default observer(StudentWorkbookViewerContent)

const _Style = css`
  flex-grow: 1;
  position: relative;
  overflow: hidden;
  border-radius: 14px;
  background-color: ${colors.gray.$500};
  display: flex;

  ${mediaQuery.underTablet} {
    border-radius: 0;
    background-color: ${colors.gray.$300};
    padding-bottom: 138px;
  }
`
const _preloadImageStyle = css`
  position: absolute;
  left: -999999px;
`
