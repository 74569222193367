import { css } from '@emotion/react'
import type { ComponentProps } from 'react'

import { ButtonModule } from '../Button'

export const kind = `ms__ButtonSegmentedControl` as const

export default function ButtonSegmentedControl(props: ComponentProps<'div'>) {
  return <div css={_css} data-component={kind} {...props} />
}

const _css = css`
  display: flex;

  [data-component='${ButtonModule.kind}'] {
    &:not(:first-of-type) {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }

    &:not(:last-of-type) {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
  }

  [data-component='${ButtonModule.kind}'] + [data-component='${ButtonModule.kind}'] {
    border-left-width: 0;
  }
`
