import { CalendarDate } from '@mathflat/shared/@modules/(dto)/CalendarDate'
import { format } from 'date-fns'
import { action, computed, makeObservable, observable } from 'mobx'

import { studentExamApi } from '~/@common/api/studentExamApi2'
import { studentWorksheetApi } from '~/@common/api/studentWorksheetApi2'
import { commonRepo } from '~/@common/services/repo.service'
import type { CalendarDateRange } from '~/@common/ui/Calendar/Calendar'

import {
  StudentCustomWorksheetList,
  StudentExamWorksheetList,
  type StudentWorksheetList,
} from '../@common/model/(StudentWorksheetList)'
import type { UiStatusFilterOptions } from '../@common/types'

export class StudentWorksheetListUiState {
  private _isFetching: boolean = false
  private _dateRange: CalendarDateRange
  private _isOnlyHomeWorkOptionToggled: boolean = false
  private _isOnlyShowExamWorksheetOptionToggled: boolean = false
  private _statusFilterOption: UiStatusFilterOptions = 'ALL'

  get isFetching() {
    return this._isFetching
  }

  get dateRange() {
    return this._dateRange
  }

  get decodedDateRange() {
    return {
      startDate: format(this._dateRange.start?.dateObj || new Date(), 'yyyy-MM-dd'),
      endDate: format(this._dateRange.end?.dateObj || new Date(), 'yyyy-MM-dd'),
    }
  }
  get isOnlyHomeWorkOptionToggled() {
    return this._isOnlyHomeWorkOptionToggled
  }

  get isOnlyShowExamWorksheetOptionToggled() {
    return this._isOnlyShowExamWorksheetOptionToggled
  }

  get statusFilterOption() {
    return this._statusFilterOption
  }

  constructor() {
    makeObservable<
      this,
      | '_isFetching'
      | '_dateRange'
      | '_isOnlyHomeWorkOptionToggled'
      | '_isOnlyShowExamWorksheetOptionToggled'
      | '_statusFilterOption'
    >(this, {
      _isFetching: observable,
      isFetching: computed,
      updateIsFetching: action.bound,

      _dateRange: observable,
      dateRange: computed,
      decodedDateRange: computed,
      updateDateRange: action.bound,

      _isOnlyHomeWorkOptionToggled: observable,
      _isOnlyShowExamWorksheetOptionToggled: observable,
      isOnlyHomeWorkOptionToggled: computed,
      isOnlyShowExamWorksheetOptionToggled: computed,
      toggle: action.bound,

      _statusFilterOption: observable,
      statusFilterOption: computed,
      updateOption: action.bound,
      updateStatusFilterOption: action.bound,
    })

    const date = new CalendarDate()
    const initialDateRange = {
      start: date.getPrevMonth(),
      end: date,
    }

    this._dateRange = initialDateRange
  }

  updateIsFetching(isFetching: boolean) {
    this._isFetching = isFetching
  }

  updateDateRange(dateRange: CalendarDateRange) {
    this._dateRange = dateRange
  }

  updateStatusFilterOption(statusFilterOption: UiStatusFilterOptions) {
    this._statusFilterOption = statusFilterOption
  }

  toggle(key: '_isOnlyHomeWorkOptionToggled' | '_isOnlyShowExamWorksheetOptionToggled') {
    this[key] = !this[key]
  }

  updateOption(
    key: '_isOnlyHomeWorkOptionToggled' | '_isOnlyShowExamWorksheetOptionToggled',
    value: boolean,
  ) {
    this[key] = value
  }
}

export class StudentWorksheetListStore {
  private _studentWorksheetList: StudentWorksheetList[] = []
  uiState = new StudentWorksheetListUiState()

  get studentWorksheetList() {
    return this._studentWorksheetList
  }

  constructor() {
    makeObservable<this, '_studentWorksheetList'>(this, {
      _studentWorksheetList: observable,
      studentWorksheetList: computed,
      fetchStudentWorksheets: action.bound,
      fetchStudentExams: action.bound,
      uiState: observable,
      updateStudentWorksheetList: action.bound,
    })
  }

  updateStudentWorksheetList(studentWorksheetList: StudentWorksheetList[]) {
    this._studentWorksheetList = [...studentWorksheetList]
  }

  async fetchStudentWorksheets() {
    const studentId = commonRepo.student?.id
    if (!studentId) throw new Error('학생 정보가 없습니다.')

    const { data: studentWorksheets } = await studentWorksheetApi.getStudentWorksheetWithHomeworks(
      studentId,
      this.uiState.decodedDateRange,
    )

    return studentWorksheets.map((worksheet) => new StudentCustomWorksheetList(worksheet))
  }

  async fetchStudentExams() {
    const studentId = commonRepo.student?.id
    if (!studentId) throw new Error('학생 정보가 없습니다.')

    const { data: studentExams } = await studentExamApi.getStudentExams({
      studentId,
      dateTime: this.uiState.decodedDateRange,
    })

    return studentExams.map((exam) => new StudentExamWorksheetList(exam))
  }
}
