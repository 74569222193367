import type { Entity } from './dto'

export const curriculumUtils = {
  extractUpperCurriculumKey: (
    lowerTrieKey: Entity.CurriculumTree['trieKey'],
    lowerCurriculumId: Entity.Curriculum['id'],
  ) => {
    const regex = new RegExp('.' + lowerCurriculumId, 'g')
    return lowerTrieKey.replace(regex, '')
  },
}
