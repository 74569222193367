import { css } from '@emotion/react'
import clsx from 'clsx'
import { observer } from 'mobx-react'
import type { FC } from 'react'
import { parts as WorkbookSearchModalParts } from 'src/@pages/@widgets/WorkbookSearchModal/index.ts'
import { WorkbookSearchService } from 'src/@pages/@widgets/WorkbookSearchModal/WorkbookSearch.service.ts'

import { useStudentAppMediaQuery } from '~/@common/hooks/useMediaQuery'
import { useRepository } from '~/@common/hooks/useRepository.ts'
import modalService from '~/@common/services/modal.service.tsx'
import { colors, fontWeight, typo } from '~/@common/styles'
import { mediaQuery } from '~/@common/styles/mediaQuery'
import IconButton from '~/@common/ui/(Button)/IconButton/IconButton.tsx'
import SearchInput from '~/@common/ui/(Input)/SearchInput/SearchInput.tsx'

const WorkbookSearchModalHeader: FC = () => {
  const service = useRepository(WorkbookSearchService)
  const { isMobile } = useStudentAppMediaQuery()

  const placeholder = isMobile
    ? '교재, 교과서 이름을 검색해보세요'
    : '시그니처 교재 / 시중교재 / 교과서 모두 검색해보세요.'

  const iconButton = (
    <IconButton
      name="icon_close"
      iconSize={20}
      iconStyle={{
        color: isMobile ? colors.gray.$900 : colors.gray.$500,
      }}
      onClick={modalService.closeModal}
    />
  )

  if (!service) return null

  return (
    <header className={WorkbookSearchModalParts.header} css={_css}>
      {isMobile && <div className="title">교재 선택 {iconButton}</div>}
      <div className={clsx(WorkbookSearchModalParts.headerSearch, 'search')}>
        <SearchInput
          placeholder={placeholder}
          defaultValue={service.searchText}
          onSearch={service.setSearchText}
        />
      </div>
      {!isMobile && iconButton}
    </header>
  )
}

export default observer(WorkbookSearchModalHeader)

const _css = css`
  ${mediaQuery.underTablet} {
    .title {
      height: 61px;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      ${typo.body02};
      font-weight: ${fontWeight.bold};
      border-bottom: 1px solid ${colors.gray.$300};
      margin-bottom: 20px;

      > button {
        position: absolute;
        right: 24px;
        top: 50%;
        margin-top: -10px;
      }
    }
    .search {
      input::placeholder {
        ${typo.body02};
      }
    }
  }
`
