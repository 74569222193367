import { observer } from 'mobx-react'

import { colors } from '~/@common/styles'
import { Icon } from '~/@common/ui/Icon/Icon'

import type { LectureGroup as _LectureGroup } from '../@service/Lecture.service'
import { LectureListSkeleton } from '../LectureListSkeleton'
import { S } from './LectureGroup.style'
import LectureGroup from './LectureGroup.widget'

export const LECTURE_GROUP_LIST_ID = 'lecture-group-list'

type Props = {
  isFetching: boolean
  lectureGroups?: _LectureGroup[]
}

const LectureGroupList = ({ lectureGroups, isFetching }: Props) => {
  if (isFetching) {
    return <LectureListSkeleton />
  }

  if (!lectureGroups) return null

  if (lectureGroups.length === 0) {
    return (
      <S.Empty>
        <div className="circle">
          <Icon name="icon_video" size={24} color={colors.white} />
        </div>
        <p>강의가 준비되지 않았어요</p>
      </S.Empty>
    )
  }

  return (
    <S.LectureGroupList className="lecture-group-list" id={LECTURE_GROUP_LIST_ID}>
      {lectureGroups.map((lectureGroup) => (
        <LectureGroup key={lectureGroup.leadingCurriculum.id} lectureGroup={lectureGroup} />
      ))}
    </S.LectureGroupList>
  )
}

export default observer(LectureGroupList)
