import './katex.css'

import type { ComponentProps } from 'react'
import { InlineMath } from 'react-katex'

const Katex = ({ children }: ComponentProps<'div'>) => {
  const _children =
    typeof children === 'string' ? children.replace(/\\placeholder/g, '☐') : children
  return <InlineMath>{_children}</InlineMath>
}

export default Katex
