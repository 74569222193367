import { observer, useLocalObservable } from 'mobx-react'
import type { FC } from 'react'

import { useRepository } from '~/@common/hooks/useRepository'
import { errorHandlerService } from '~/@common/services'
import modalService from '~/@common/services/modal.service'
import { commonRepo } from '~/@common/services/repo.service'
import Button from '~/@common/ui/(Button)/Button/Button'
import type { WorkbookSearchModalOnSubmit } from '~/@pages/@widgets/WorkbookSearchModal'

import { WorkbookSearchService } from './WorkbookSearch.service'

type Props = {
  onSubmit: WorkbookSearchModalOnSubmit
}

const WorkbookSearchApplyButton: FC<Props> = ({ onSubmit }) => {
  const service = useRepository(WorkbookSearchService)

  const _store = useLocalObservable(() => ({
    get totalCheckedWorkbookSize() {
      if (!service) return 0
      return service.checkedWorkbookToArray.reduce((prev, cur) => prev + cur.size, 0)
    },
    isFetching: false,
    setIsFetching(value: boolean) {
      this.isFetching = value
    },
    async handleSubmit() {
      if (!service.curriculumKey) return

      const payload = {
        curriculumKey: service.curriculumKey,
        searchText: service.searchText,
        selectedWorkbookType: service.selectedWorkbookType,
        workbookList: service.checkedWorkbookToArray.flatMap((items) => [...items.values()]),
      }
      try {
        this.setIsFetching(true)
        await onSubmit(payload)
        window.freshpaint?.track('[챌린지] 교재 필터 적용하기', {
          ...payload,
          ...commonRepo.curriculumTreeColl?.getCurriculumInfoByCurriculumKey(service.curriculumKey),
        })
      } catch (err) {
        errorHandlerService.handle(err)
      } finally {
        this.setIsFetching(false)
      }

      modalService.closeModal()
    },
  }))

  const handleSubmit = () => {
    _store.handleSubmit()
  }

  return (
    <Button
      theme="primary"
      size="small"
      style={{
        height: '40px',
        padding: '10px',
      }}
      disabled={!service.isChanged}
      isLoading={_store.isFetching}
      onClick={handleSubmit}
    >
      적용하기
    </Button>
  )
}

export default observer(WorkbookSearchApplyButton)
