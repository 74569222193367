class CryptoUtils {
  constructor() {}
  // string => base64
  encodeBase64(value: string) {
    return btoa(value)
  }
  // base64 => string 변환
  decodeBase64(value: string) {
    return atob(value)
  }
}

export const cryptoUtils = new CryptoUtils()
