import 'swiper/swiper-bundle.css'

import { Global } from '@emotion/react'
import { domAnimation, LazyMotion } from 'framer-motion'
import ReactDOM from 'react-dom/client'
import { RouterProvider } from 'react-router'
import { ToastContainer } from 'react-toastify'

import { globalCss } from '~/@common/styles/globalCss.ts'
import { LastLocationManager } from '~/@pages/@common/index.ts'

import { appRouter } from './appRouter.tsx'
import { initialize } from './init/initialize.ts'

initialize().then(() => {
  ReactDOM.createRoot(document.getElementById('app') as HTMLElement).render(
    <>
      <Global styles={globalCss} />
      <LazyMotion features={domAnimation} strict>
        <LastLocationManager.Provider value={new LastLocationManager.Service(appRouter)}>
          <RouterProvider router={appRouter} future={{ v7_startTransition: true }} />
        </LastLocationManager.Provider>
      </LazyMotion>
      <ToastContainer />
    </>,
  )
})
