import { css } from '@emotion/react'
import { observer } from 'mobx-react'
import { type FC, type ReactNode, useState } from 'react'

import { useStudentAppMediaQuery } from '~/@common/hooks/useMediaQuery'
import { colors, typo } from '~/@common/styles'
import IconButton from '~/@common/ui/(Button)/IconButton/IconButton'
import { Drawer } from '~/@common/ui/(Drawer)/BaseDrawer'
import Tooltip from '~/@common/ui/Tooltip'

const WeeklyInfoTooltip: FC = () => {
  const { isMobile } = useStudentAppMediaQuery()
  const [isShowModal, setIsShowModal] = useState(false)

  const title = '이번주에 학습한 내용을 요약해드려요'
  const items: ReactNode[] = [
    <>
      챌린지, 교재, 학습지, 강의 등을 통해 학습을
      <br />
      하면 학습시간이 누적됩니다.
    </>,
    '제출한 문제 수에 따라 푼 문제 수가 누적됩니다.',
  ]

  const defaultContent = (
    <Tooltip zIndex={1}>
      <Tooltip.Content>
        <Tooltip.Title>{title}</Tooltip.Title>
        {items.map((item, index) => (
          <Tooltip.Item key={index}>{item}</Tooltip.Item>
        ))}
      </Tooltip.Content>
    </Tooltip>
  )

  const handleInfoIconClick = () => {
    setIsShowModal((value) => !value)
  }

  if (isMobile) {
    return (
      <>
        <IconButton
          name="icon_info_fill"
          size={20}
          iconSize={20}
          iconStyle={{
            color: colors.gray.$500,
          }}
          onClick={handleInfoIconClick}
        />
        <Drawer isOpened={isShowModal} closeDrawer={() => setIsShowModal(false)}>
          <Drawer.Content
            css={css`
              > header + div {
                flex-shrink: 0;
              }
            `}
          >
            <Drawer.Header>{title}</Drawer.Header>
            <Drawer.Body
              style={{
                padding: '20px 18px 0',
              }}
            >
              <ul css={_css}>
                {items.map((item, index) => (
                  <li key={index}>
                    <span className="bullet"></span>
                    {item}
                  </li>
                ))}
              </ul>
            </Drawer.Body>
          </Drawer.Content>
        </Drawer>
      </>
    )
  }

  return defaultContent
}

export default observer(WeeklyInfoTooltip)

const _css = css`
  display: flex;
  flex-direction: column;
  gap: 4px;
  padding-bottom: 20px;

  li {
    display: flex;
    align-items: start;
    gap: 6px;
    ${typo.body02};

    br {
      display: none;
    }

    .bullet {
      display: inline-block;
      width: 3px;
      height: 3px;
      border-radius: 3px;
      background-color: ${colors.gray.$800};
      margin-top: 8px;
    }
  }
`
