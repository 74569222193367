import * as PR from '@effect/schema/ParseResult'
import * as S from '@effect/schema/Schema'
import { identity } from 'effect'
import { isNumber } from 'effect/Predicate'
import * as A from 'effect/ReadonlyArray'

const EmptyString = S.literal('')

const NullableZero = S.union(S.null, S.undefined, S.literal(0))
/**
 * 스키마에 빈 문자열을 허용하게 합니다.
 * @category combinators
 */
export const allowEmptyString = <I, O>(schema: S.Schema<I, O>) => S.union(EmptyString, schema)

/**
 * 스키마에 null, undefined, 0을 허용하게 하고 저 셋에 해당하는 값이 오면 null로 변환합니다.
 * null, undefined, 0 -> null
 * @category combinators
 */
export const nonZeroNullable = <T>(schema: S.Schema<T>) =>
  S.transform(
    S.union(NullableZero, schema),
    S.nullable(schema),
    (v) => (v === 0 || !isNumber(v) ? null : v),
    identity,
  )

export const fuzzyBoolean =
  <T extends { truthyValues: string[]; falsyValues: string[] }>({ truthyValues, falsyValues }) =>
  <I extends T['truthyValues'][number] | T['falsyValues'][number]>(
    self: S.Schema<I, string>,
  ): S.Schema<I, boolean> => {
    const schema: S.Schema<I, boolean> = S.transformOrFail(
      self,
      S.boolean,
      (s) => {
        const lowerStr = s.toLowerCase()
        if (A.contains(truthyValues, lowerStr)) {
          return PR.succeed(true)
        }
        if (A.contains(falsyValues, lowerStr)) {
          return PR.succeed(false)
        }

        return PR.fail(PR.type(self.ast, s))
      },
      (n) => PR.succeed(String(n)),
    )
    return schema
  }

export const toNullable = <A>(schema: S.Schema<A>) => S.union(S.undefined, S.null, schema)
