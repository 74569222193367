import { Observer, observer } from 'mobx-react'
import { useEffect } from 'react'
import { isDesktop } from 'react-device-detect'
import { Outlet } from 'react-router-dom'

import { routeName } from '~/@common/constants'
import { useStudentAppMediaQuery } from '~/@common/hooks/useMediaQuery'
import { useOrientation } from '~/@common/hooks/useOrientation'
import { handwrittenNoteTooltipService } from '~/@common/services/HandwrittenTooltip.service'
import { VirtualKeyboardDrawer } from '~/@common/ui/(Keypad)'
import { virtualKeyboardService } from '~/@common/ui/(Keypad)/keypad.service'
import Modal from '~/@common/ui/modal/Modal'
import { PortalRootLayoutHeader } from '~/@common/ui/PortalRootLayoutHeader'
import SwiperProvider from '~/@common/ui/SwiperController/Swiper'
import { envUtils } from '~/@common/utils/envUtils'
import { GNB } from '~/@pages/@widgets/(Navigation)/GNB'
import Main from '~/@pages/@widgets/Main'
import ScreenSizeGuide from '~/@pages/@widgets/ScreenSizeGuide'

import useApp from './hooks/useApp'
import { useCallbackOnVisibilityChange } from '~/@common/hooks/useCallbackOnVisibilityChange'
import { CustomEventService } from '~/@common/services/event.service'

const App = () => {
  const { location } = useApp()
  const { type } = useOrientation()
  useCallbackOnVisibilityChange(() => {
    CustomEventService.MAATTimerOn.dispatch()
  })

  const desktopSmallScreenWhitelist: string[] = [routeName.login, routeName.profile]
  const isShowSmallScreenGuide =
    isDesktop && envUtils.isLive && !desktopSmallScreenWhitelist.includes(location.pathname)

  const { isMobile } = useStudentAppMediaQuery()

  useEffect(() => {
    if (type === 'portrait') {
      handwrittenNoteTooltipService.showHandwrittenInfoTooltip()
    }
  }, [type])

  return (
    <>
      {isShowSmallScreenGuide && <ScreenSizeGuide />}
      {location.pathname !== routeName.serverMaintenance && (
        <PortalRootLayoutHeader showMAATTImer>
          <GNB />
        </PortalRootLayoutHeader>
      )}
      <SwiperProvider>
        <Main>
          <Outlet />
        </Main>
      </SwiperProvider>
      <Modal.Container />
      {isMobile && (
        <Observer>
          {() => (
            <VirtualKeyboardDrawer
              open={virtualKeyboardService.show && virtualKeyboardService.showType === 'drawer'}
              keypadType={virtualKeyboardService.type}
              closeKeyboard={() => virtualKeyboardService.close()}
              onKeyButtonClick={(e, latexNodeType) => {
                virtualKeyboardService.onKeyButtonClick(e, latexNodeType)
              }}
            />
          )}
        </Observer>
      )}
    </>
  )
}

export default observer(App)
