import { useCallback, useEffect } from 'react'
import { useNavigate } from 'react-router'
import { routeName } from '~/@common/constants'

import { webviewService } from '~/@common/services'

export const useNativeApp = () => {
  const navigate = useNavigate()

  const memoizedPopState = useCallback(() => navigate(-1), [])

  //  TODO: 앱 연동 모듈 만들어서 대체
  useEffect(() => {
    // 기존 사용성에 따라 아래의 라우트들에서 뒤로가기 버튼을 누르면 앱이 종료되도록 설정
    const primaryRoute = [
      routeName.login,
      routeName.student.defaultPath,
      routeName.student.challenge,
      routeName.student.generalStudentWorkbookList,
      routeName.student.studentWorksheet,
      routeName.student.lectureList,
    ]

    const isPrimaryRoute = primaryRoute.some((route) => route === location.pathname)

    const closeApp = () => {
      webviewService.closeApp()
    }

    if (isPrimaryRoute) {
      window.addEventListener('onBackPressed', closeApp)
    } else if (!isPrimaryRoute) {
      window.addEventListener('onBackPressed', memoizedPopState)
      window.removeEventListener('onBackPressed', closeApp)
    }

    return () => {
      window.removeEventListener('onBackPressed', memoizedPopState)
      window.removeEventListener('onBackPressed', closeApp)
    }
  }, [location.pathname, memoizedPopState])
}
