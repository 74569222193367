import { useEffect, useState } from 'react'

export const useLoadMoreList = <T>({
  list = [],
  defaultListCount,
  countPerPage,
}: {
  list?: T[]
  defaultListCount: number
  countPerPage: number
}) => {
  const [currentIndex, setCurrentIndex] = useState(1)
  const [pagingList, setPagingList] = useState<T[]>([])

  useEffect(() => {
    setPagingList(list.slice(0, defaultListCount))
  }, [defaultListCount, JSON.stringify(list)])

  const onClickMore = () => {
    if (list.length < defaultListCount + countPerPage) {
      setPagingList(list)
      return
    }

    const lastIndex = Math.floor((list.length - defaultListCount) / countPerPage) + 1

    // 마지막 인덱스일떄
    if (lastIndex === currentIndex) {
      setPagingList(list)
    }

    // 이전 인덱스일때
    if (lastIndex > currentIndex) {
      setPagingList(list.slice(0, defaultListCount + currentIndex * countPerPage))
      setCurrentIndex((prev) => prev + 1)
    }
  }

  const onResetList = () => {
    setPagingList(list.slice(0, defaultListCount))
    setCurrentIndex(1)
  }

  return {
    pagingList,
    isLastPage: pagingList.length === list.length,
    onClickMore,
    onResetList,
    enableMore: list.length > defaultListCount,
  }
}
