import { css } from '@emotion/react'

import { colors, typo } from '~/@common/styles'
import { Icon } from '~/@common/ui/Icon/Icon'

interface WrongCorrectCountProps {
  wrongCount: number
  correctCount: number
}

export const WrongCorrectCount = ({ wrongCount, correctCount }: WrongCorrectCountProps) => {
  return (
    <div
      className="wrapper"
      css={css`
        display: flex;
        align-items: center;
        gap: 10px;
        color: ${colors.gray.$700};
        ${typo.caption01}
        .flex {
          display: flex;
          align-items: center;
          gap: 2px;
        }
      `}
    >
      <div className="flex">
        <Icon name="icon_answer_wrong" color={colors.red.$300} size={12} />: {wrongCount}개
      </div>
      <div className="flex">
        <Icon name="icon_answer_correct" color={colors.blue.$300} size={12} />: {correctCount}개
      </div>
    </div>
  )
}
