import { observer } from 'mobx-react'
import type { FC } from 'react'

import { PIXEL_RATIO } from '../constants'
import { getSvgPathDAttr } from './handwrittenNote.utils'
import type HandwrittenNoteService from './service/HandwrittenNote.service'
import type HandwrittenNotePathItem from './service/HandwrittenNotePathItem'

interface Props {
  item: HandwrittenNotePathItem
  service: HandwrittenNoteService
}

const HandwrittenNotePath: FC<Props> = ({ item, service }) => {
  const { activeTool, toolWidth } = service.controllerService
  const pathPixelRatio = item.pixelRatio ?? 1

  const pathDAttr = getSvgPathDAttr(item.points, item.width, item.pathType, pathPixelRatio)
  const eraserStrokeWidth = (toolWidth + item.width) * PIXEL_RATIO

  return (
    <>
      <path
        data-path-id={item.id}
        stroke={item.color}
        strokeWidth={item.width * PIXEL_RATIO}
        strokeLinecap="round"
        fill="none"
        d={pathDAttr}
        style={{
          display: item.isDeleted ? 'none' : 'unset',
        }}
      />
      {activeTool === 'eraser' && !item.isDeleted && (
        <path
          data-path-id={item.id}
          stroke="transparent"
          strokeWidth={eraserStrokeWidth}
          strokeLinecap="round"
          fill="none"
          d={pathDAttr}
        />
      )}
    </>
  )
}

export default observer(HandwrittenNotePath)
