import type { Entity } from '@mathflat/domain/@entities/ConceptChip/dto'
import { action, computed, makeObservable, observable } from 'mobx'

import type { WeeklyInfoDto } from '../response'

const NO_LEARNING_TIME_TEXT = '00:00'

export default class WeeklyInfo {
  private _totalLearningTime: number = 0
  private _problemsSolvedCount: number = 0
  private _bestIncreasedGrade: Entity.ConceptChip[] | null = null
  private _bestGradeAchieved: Entity.ConceptChip[] | null = null

  constructor() {
    makeObservable<
      this,
      '_totalLearningTime' | '_problemsSolvedCount' | '_bestIncreasedGrade' | '_bestGradeAchieved'
    >(this, {
      _totalLearningTime: observable,
      _problemsSolvedCount: observable,
      _bestIncreasedGrade: observable.ref,
      _bestGradeAchieved: observable.ref,
      totalLearningTimeText: computed,
      setData: action,
    })
  }

  get problemsSolvedCount() {
    return this._problemsSolvedCount
  }

  get bestIncreasedGrade() {
    return this._bestIncreasedGrade
  }

  get bestGradeAchieved() {
    return this._bestGradeAchieved
  }

  get totalLearningTimeText() {
    if (!this._totalLearningTime) {
      return NO_LEARNING_TIME_TEXT
    }
    const hours = Math.floor(this._totalLearningTime / 60)
    const minutes = this._totalLearningTime % 60
    return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}`
  }

  get totalLearningTime() {
    return this._totalLearningTime
  }

  setData(data: WeeklyInfoDto) {
    this._totalLearningTime = data.totalLearningTime
    this._problemsSolvedCount = data.problemsSolvedCount
    this._bestIncreasedGrade = data.bestIncreasedGrade
    this._bestGradeAchieved = data.bestGradeAchieved
  }
}
