import { action, computed, makeObservable, observable } from 'mobx'
import { studentHomeApi } from 'src/@pages/student/student-home/@common/api.ts'
import SmileChallenge from 'src/@pages/student/student-home/@common/model/SmileChallenge.ts'
import type { SmileChallengeDto } from 'src/@pages/student/student-home/@common/response.ts'

import { localStorageService } from '~/@common/services/storage.service.ts'

export default class SmileChallengeService {
  private _smileChallenge?: SmileChallenge

  constructor() {
    makeObservable<this, '_smileChallenge'>(this, {
      _smileChallenge: observable,
      isReady: computed,
      setSmileChallenge: action,
    })
  }

  get smileChallenge() {
    return this._smileChallenge
  }

  get isReady() {
    return this._smileChallenge !== undefined
  }

  private _getStudentId() {
    return localStorageService.user.studentId
  }

  async loadSmileChallenge(curriculumKey?: string) {
    const studentId = this._getStudentId()
    if (!studentId) {
      throw new Error('studentId required')
    }
    const data = await studentHomeApi.fetchSmileChallenge(studentId, curriculumKey)
    this.setSmileChallenge(data)
  }

  setSmileChallenge(data: SmileChallengeDto | undefined) {
    if (data) {
      this._smileChallenge = new SmileChallenge(data)
    } else {
      this._smileChallenge = data
    }
  }
}
