import type { CONTENT_STATUS } from '@mathflat/domain/@entities/(Content)/module'
import { Entity as WorksheetEntity } from '@mathflat/domain/@entities/(Content)/Worksheet/dto'
import type { MathflatApi as StudentExamApi } from '@mathflat/domain/@entities/StudentExam/api'
import { Entity as StudentWorksheetEntity } from '@mathflat/domain/@entities/StudentWorksheet/dto'
import { Entity as StudentWorksheetScoringEntity } from '@mathflat/domain/@entities/StudentWorksheet/StudentWorksheetScoring/dto'
import type { ValueOf } from '@mathflat/shared/@types/utilityTypes'
import zipWith from 'lodash/zipWith'
import { action, computed, makeObservable, observable, runInAction } from 'mobx'

import { RequestScoring, studentExamApi } from '~/@common/api'
import { toastService } from '~/@common/services'
import { commonRepo } from '~/@common/services/repo.service'
import { localStorageService } from '~/@common/services/storage.service'
import {
  ProblemScoring,
  ProblemScoringColl,
} from '~/@pages/@common/(ProblemScoring)/@trait/ProblemScoring.trait'
import {
  ProblemScoringViewOption,
  type ProblemScoringViewOptionParams,
} from '~/@pages/@common/(ProblemScoring)/@trait/ProblemScoringViewOption.trait'

export class StudentExamService {
  private _problemScoringColl?: ProblemScoringColl<'WORKSHEET'>
  private _problemScoringViewOption?: ProblemScoringViewOption<'NOT_학습모듈'>
  private _studentExamWorksheet: StudentWorksheetEntity.StudentWorksheet | null = null
  private _exam?: WorksheetEntity.ExamWorksheet

  get studentExamWorksheet() {
    return this._studentExamWorksheet
  }

  get exam() {
    return this._exam
  }

  get problemScoringColl() {
    return new ProblemScoringColl(this._problemScoringColl?.toArr ?? [])
  }

  get problemScoringViewOption() {
    const currentProblemScoringViewOption = this._problemScoringViewOption

    if (
      currentProblemScoringViewOption?.content &&
      currentProblemScoringViewOption?.studentAppSetting &&
      currentProblemScoringViewOption.content.worksheetId
    ) {
      return new ProblemScoringViewOption<'NOT_학습모듈'>({
        content: {
          worksheetId: currentProblemScoringViewOption.content.worksheetId,
          autoScored: currentProblemScoringViewOption.content.autoScored,
          status: currentProblemScoringViewOption.content.status,
          kind: 'WORKSHEET',
          type: currentProblemScoringViewOption.content.type === 'MAAT' ? 'MAAT' : 'EXAM',
        },
        studentAppSetting: currentProblemScoringViewOption.studentAppSetting,
        문제같이보기: currentProblemScoringViewOption?.문제같이보기,
        안푼문제만보기: currentProblemScoringViewOption?.안푼문제만보기,
        오답_모르는문제만보기: currentProblemScoringViewOption?.오답_모르는문제만보기,
      })
    }

    return currentProblemScoringViewOption
  }

  constructor() {
    makeObservable<
      this,
      '_problemScoringColl' | '_problemScoringViewOption' | '_studentExamWorksheet' | '_exam'
    >(this, {
      _exam: observable,
      _studentExamWorksheet: observable,
      studentExamWorksheet: computed,
      exam: computed,
      _problemScoringColl: observable,
      _problemScoringViewOption: observable,
      problemScoringColl: computed,
      problemScoringViewOption: computed,
      loadStudentExamScoring: action.bound,
      submitAutoScoringExamProblems: action.bound,
    })
  }

  async loadStudentExamScoring(studentExamId: string) {
    const { data: studentExamScoring } = await studentExamApi.getStudentExam(studentExamId)

    const {
      studentWorksheet,
      exam,
    }: {
      studentWorksheet: {
        id: number
        assignDatetime: string
        openDatetime: string
        score: number
        scoreDatetime: string
        status: ValueOf<typeof CONTENT_STATUS>
        correctCount: number | null
        wrongCount: number | null
      }
      exam: WorksheetEntity.ExamWorksheet | WorksheetEntity.MAATWorksheet
    } = {
      exam: {
        id: studentExamScoring.id,
        title: '',
        type: studentExamScoring.examType === 'MAAT' ? 'MAAT' : 'EXAM',
        level: 1,
        grade: 1,
        problemCount: 0,
        accessModifierToStudent: 'PUBLIC',
        autoScorable: true,
        chapter: '1',
        school: 'ELEMENTARY',
        tag: 'BASIC',
        titleTag: 'BASIC',
      },
      studentWorksheet: {
        id: studentExamScoring.id,
        assignDatetime: studentExamScoring.assignDatetime,
        openDatetime: new Date().toISOString(),
        scoreDatetime: new Date().toISOString(),
        score: studentExamScoring.score || 0,
        status: studentExamScoring.status,
        correctCount: 0,
        wrongCount: 0,
      },
    }

    const { data: studentExamProblems } = await studentExamApi.getStudentExamWithProblems(
      studentExamScoring.examId.toString(),
    )

    const customizer = (
      problem: StudentExamApi.Response.ExamProblem,
      scoring: StudentExamApi.Response.ExamScoring,
    ) => ({
      ...problem,
      ...scoring,
    })

    const mergedExamProblemWithScoring = zipWith(
      studentExamProblems,
      studentExamScoring.scoringList,
      customizer,
    ).map((problemScoring) => ({
      problem: problemScoring.problem,
      worksheetProblemId: problemScoring.examProblemId,
      scoring: {
        id: problemScoring.id,
        userAnswer: problemScoring.userAnswer,
        result: problemScoring.result,
        handwrittenNoteUrl: problemScoring.handwrittenNoteUrl,
        updateDatetime: problemScoring.scoreDatetime,
      },
    }))

    const examProblemScoringList = mergedExamProblemWithScoring.map(
      ({ problem, worksheetProblemId, scoring }, index) =>
        new ProblemScoring<'WORKSHEET'>(
          {
            scoring: new StudentWorksheetScoringEntity.StudentWorksheetScoring<'시험지'>({
              scoring: {
                ...scoring,
                updateDatetime: scoring.updateDatetime ?? undefined,
                examProblemId: scoring.id,
              },
              problem: {
                ...problem,
                answerUnits: problem.answerUnits,
                keypadTypes: problem.keypadTypes,
              },
              worksheet: {
                autoScorable: exam.autoScorable ?? false,
                label: `${index + 1}번`,
              },
            }),
          },
          worksheetProblemId,
          scoring.handwrittenNoteUrl,
        ),
    )
    const problemScoringViewOptionSettings: ProblemScoringViewOptionParams<'NOT_학습모듈'> = {
      content: {
        worksheetId: exam.id,
        autoScored: exam.autoScorable,
        status: studentWorksheet.status,
        kind: 'WORKSHEET',
        type: studentExamScoring.examType === 'MAAT' ? 'MAAT' : 'EXAM',
      },
      studentAppSetting: {
        일반채점_채점전정답공개: !!commonRepo.studentAppSetting?.일반채점_채점전정답공개,
        채점후정답해설공개: !!commonRepo.studentAppSetting?.채점후정답해설공개,
        일반채점_채점전문풀동공개: !!commonRepo.studentAppSetting?.일반채점_채점전문풀동공개,
        채점후문풀동공개: !!commonRepo.studentAppSetting?.채점후문풀동공개,
      },
    }

    runInAction(() => {
      this._problemScoringColl = new ProblemScoringColl<'WORKSHEET'>(examProblemScoringList)
      this._exam = new WorksheetEntity.ExamWorksheet(exam)
      this._studentExamWorksheet = new StudentWorksheetEntity.StudentWorksheet(studentWorksheet)
      this._problemScoringViewOption = new ProblemScoringViewOption<'NOT_학습모듈'>(
        problemScoringViewOptionSettings,
      )
    })
  }

  async submitAutoScoringMAATProblems(studentExamId: string) {
    const { data: studentExamScoring } = await studentExamApi.getStudentExam(studentExamId)
    const { data: studentExamProblems } = await studentExamApi.getStudentExamWithProblems(
      studentExamScoring.examId.toString(),
    )
    const customizer = (
      problem: StudentExamApi.Response.ExamProblem,
      scoring: StudentExamApi.Response.ExamScoring,
    ) => ({
      ...problem,
      ...scoring,
    })

    const mergedExamProblemWithScoring = zipWith(
      studentExamProblems,
      studentExamScoring.scoringList,
      customizer,
    ).map((problemScoring) => ({
      problem: problemScoring.problem,
      worksheetProblemId: problemScoring.examProblemId,
      scoring: {
        id: problemScoring.id,
        userAnswer: problemScoring.userAnswer,
        result: problemScoring.result,
        handwrittenNoteUrl: problemScoring.handwrittenNoteUrl,
        updateDatetime: problemScoring.scoreDatetime,
      },
    }))

    const examProblemScoringList = mergedExamProblemWithScoring.map(
      ({ problem, worksheetProblemId, scoring }, index) =>
        new ProblemScoring<'WORKSHEET'>(
          {
            scoring: new StudentWorksheetScoringEntity.StudentWorksheetScoring<'시험지'>({
              scoring: {
                ...scoring,
                updateDatetime: scoring.updateDatetime ?? undefined,
                examProblemId: scoring.id,
              },
              problem: {
                ...problem,
                answerUnits: problem.answerUnits,
                keypadTypes: problem.keypadTypes,
              },
              worksheet: {
                autoScorable: true,
                label: `${index + 1}번`,
              },
            }),
          },
          worksheetProblemId,
          scoring.handwrittenNoteUrl,
        ),
    )

    const payload = new ProblemScoringColl<'WORKSHEET'>(examProblemScoringList).toScoredArr

    if (payload && payload.length && payload[0].localUserInput instanceof RequestScoring.자동채점) {
      await studentExamApi.patchStudentExamAutoScoringById(
        studentExamId.toString(),
        payload.map(({ localUserInput }) =>
          RequestScoring.자동채점.toRequestParams<'WORKSHEET', '시험지'>(
            localUserInput as RequestScoring.자동채점,
          ),
        ),
      )

      for (const item of payload) {
        localStorageService.removeScoringData({ scoringId: item.id })
      }
      await this.loadStudentExamScoring(studentExamId)
    }
  }

  async submitAutoScoringExamProblems(studentExamId: string) {
    try {
      const payload = this._problemScoringColl?.toScoredArr

      // 시험지는 자동채점만 가능
      if (
        payload &&
        payload.length &&
        payload[0].localUserInput instanceof RequestScoring.자동채점
      ) {
        await studentExamApi.patchStudentExamAutoScoringById(
          studentExamId,
          payload.map(({ localUserInput }) =>
            RequestScoring.자동채점.toRequestParams<'WORKSHEET', '시험지'>(
              localUserInput as RequestScoring.자동채점,
            ),
          ),
        )

        for (const item of payload) {
          localStorageService.removeScoringData({ scoringId: item.id })
        }
        await this.loadStudentExamScoring(studentExamId)
      }
    } catch (error: any) {
      if (error?.response?.data?.code === 'STUDENT_EXAM_PROBLEM_DUPLICATE_SUBMIT') {
        toastService.error('이미 제출한 답안은 수정할 수 없습니다.', { allowMultipleToast: true })
      }

      console.error(error)
    }
  }

  async startMAATExam(studentExamId: number) {
    return await studentExamApi.postStudentExamStart(studentExamId)
  }

  async getRemainingTime(studentExamId: number) {
    const { data } = await studentExamApi.getStudentExamRemainingTime(studentExamId)
    return data
  }
}
