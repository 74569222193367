import { css } from '@emotion/react'
import { observer } from 'mobx-react'
import { type FC, useEffect } from 'react'

import { tooltipContainerCss } from '~/@common/styles'

interface Props {
  imageUrl?: string
  onClose: () => void
}

const CorrectAnswerImageModal: FC<Props> = ({ imageUrl, onClose }) => {
  useEffect(() => {
    document.addEventListener('click', onClose, { once: true })

    return () => {
      document.removeEventListener('click', onClose)
    }
  }, [onClose])

  if (!imageUrl) {
    return null
  }

  return (
    <div css={_css}>
      <div className="answer-image-container">
        <img src={imageUrl} alt="answer-image" />
      </div>
    </div>
  )
}

export default observer(CorrectAnswerImageModal)

const _css = css`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  .answer-image-container {
    ${tooltipContainerCss};
  }
`
