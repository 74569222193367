import { useCallback, useEffect, useState } from 'react'

const useInfiniteScroll = (
  targetRef: React.MutableRefObject<HTMLElement | null>,
  onScroll: () => void,
  options: IntersectionObserverInit = {
    root: null,
    rootMargin: '0px',
    threshold: 0.01,
  },
) => {
  const [isReady, setIsReady] = useState(false)
  const handleObserver = useCallback(
    ([target]: IntersectionObserverEntry[]) => {
      if (target.isIntersecting) {
        onScroll()
      }
    },
    [onScroll],
  )

  useEffect(() => {
    setIsReady(true)
  }, [])

  useEffect(() => {
    const observer = new IntersectionObserver(handleObserver, options)

    if (!targetRef.current || !isReady) return

    observer.observe(targetRef.current)

    return () => {
      if (targetRef.current) {
        observer.unobserve(targetRef.current)
      }
    }
  }, [
    targetRef,
    onScroll,
    options.root,
    options.rootMargin,
    options.threshold,
    isReady,
    handleObserver,
    options,
  ])
}

export default useInfiniteScroll
