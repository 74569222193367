import type { ValueOf } from '@mathflat/shared/@types/utilityTypes'
import { runInAction } from 'mobx'
import { useLocalObservable } from 'mobx-react'
import { type DependencyList, useEffect } from 'react'

export const NAV_BUTTON_STATE = {
  INVISIBLE: 'invisible',
  DISABLED: 'disabled',
  VISIBLE: 'visible',
} as const

export const SWIPE_STATE = {
  PREV: 'prev',
  NEXT: 'next',
} as const

const useScrollNavigation = (
  { elementId, distance }: { elementId: string; distance: number },
  deps: DependencyList = [],
) => {
  const _store = useLocalObservable(() => ({
    prevButtonState: NAV_BUTTON_STATE.INVISIBLE as ValueOf<typeof NAV_BUTTON_STATE>,
    nextButtonState: NAV_BUTTON_STATE.INVISIBLE as ValueOf<typeof NAV_BUTTON_STATE>,
  }))

  const handleSwipe = (type: ValueOf<typeof SWIPE_STATE>) => {
    const scrollEl = document.getElementById(elementId)
    const dist = (type === 'prev' ? -1 : 1) * (distance + 25)

    if (scrollEl) {
      scrollEl.scrollLeft += dist
    }
  }

  useEffect(() => {
    const scrollEl = document.getElementById(elementId)

    if (!scrollEl) return

    const handleScrollButtonState = () => {
      if (scrollEl) {
        globalThis.scrollEl = scrollEl

        if (scrollEl.scrollWidth <= scrollEl.clientWidth) {
          runInAction(() => {
            _store.prevButtonState = NAV_BUTTON_STATE.INVISIBLE
            _store.nextButtonState = NAV_BUTTON_STATE.INVISIBLE
          })
          return
        }

        let prevButtonState: ValueOf<typeof NAV_BUTTON_STATE> = NAV_BUTTON_STATE.VISIBLE
        let nextButtonState: ValueOf<typeof NAV_BUTTON_STATE> = NAV_BUTTON_STATE.VISIBLE

        if (scrollEl.scrollLeft <= 0) {
          prevButtonState = NAV_BUTTON_STATE.DISABLED
        } else if (scrollEl.scrollLeft + scrollEl.clientWidth >= scrollEl.scrollWidth) {
          nextButtonState = NAV_BUTTON_STATE.DISABLED
        }
        runInAction(() => {
          _store.prevButtonState = prevButtonState
          _store.nextButtonState = nextButtonState
        })
      }
      // 기존의 scrollend는 사파리에서 지원하지 않음
      scrollEl.addEventListener('scroll', handleScrollButtonState)
    }

    handleScrollButtonState()

    if (scrollEl) {
      scrollEl.scrollLeft = 0
    }
    return () => {
      scrollEl.removeEventListener('scroll', handleScrollButtonState)
    }
  }, deps)

  return {
    handleSwipe,
    nextButtonState: _store.nextButtonState,
    prevButtonState: _store.prevButtonState,
  }
}

export default useScrollNavigation
