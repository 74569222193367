import { S } from '.'

interface Props {
  leftNodes?: React.ReactNode
  rightNodes?: React.ReactNode
  wrapperStyle?: React.CSSProperties
  wrapperClassName?: string
}

export const ScoringGuide_ViewOption = ({
  leftNodes,
  rightNodes,
  wrapperStyle,
  wrapperClassName,
}: Props) => {
  return (
    <S.SwitchFiltersWrapper style={wrapperStyle} className={wrapperClassName}>
      {leftNodes}
      {rightNodes}
    </S.SwitchFiltersWrapper>
  )
}
