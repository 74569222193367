import { css } from '@emotion/react'

import { colors } from '~/@common/styles'

const S = {
  inputContainerStyle: css`
    flex: auto;
    position: relative;
  `,
  guideMsgStyle: [
    css`
      position: absolute;
      left: 0;
      line-height: 20px;
      font-size: 12px;
      color: ${colors.gray.$700};
    `,
  ],
  errorMsgStyle: css`
    margin-top: 4px;
    font-size: 12px;
    color: ${colors.red.$400} !important;
  `,
}

export default S
