import styled from '@emotion/styled'
import { WorksheetDomain } from '@mathflat/domain/@entities'
import type { Entity as WorksheetEntity } from '@mathflat/domain/@entities/(Content)/Worksheet/dto'
import clsx from 'clsx'
import type { Dispatch, SetStateAction } from 'react'

import { commonRepo } from '~/@common/services/repo.service.ts'
import Switch from '~/@common/ui/Switch/Switch'
import type { ProblemScoringColl } from '~/@pages/@common/(ProblemScoring)/@trait/ProblemScoring.trait.ts'
import { useLearningProcessReference } from '~/@pages/student/learning-process/@common/hooks/useGetLearningProcessReference.ts'
import { REFERENCE_TYPE_STUDENT_WORKSHEET } from '~/@pages/student/learning-process/@widgets/service/LearningProcess.service.ts'
import WorksheetAiTutor from '~/@pages/student/learning-process/WorksheetAiTutor.tsx'

import { WorksheetScoringByOneSwiperController } from './WorksheetScoringByOneSwiperController'

type Props = {
  type: WorksheetEntity.Worksheet['type']
  isProblemSolvingStatusShown: boolean
  setIsProblemSolvingStatusShown: Dispatch<SetStateAction<boolean>>
  isAiTutorOpened?: boolean
  setIsAiTutorOpened?: Dispatch<SetStateAction<boolean>>
  totalProblemCount?: number
  problemScoringColl: ProblemScoringColl<'WORKSHEET'>
  problemIndex?: number | null
  openExitConfirmationAiTutor?: ({ onConfirm }: { onConfirm: () => void }) => void
}

const WorksheetScoringByOneHeader = ({
  type,
  isProblemSolvingStatusShown,
  setIsProblemSolvingStatusShown,
  isAiTutorOpened,
  setIsAiTutorOpened,
  totalProblemCount,
  problemScoringColl,
  problemIndex,
  openExitConfirmationAiTutor,
}: Props) => {
  const problemScoring = problemScoringColl.toArr[problemIndex ?? 0]
  const referenceParams = useLearningProcessReference()

  return (
    <S.WorksheetScoringByOneHeader
      className={clsx(type === WorksheetDomain.TYPE.자기주도학습 && 'student-wrong-concept')}
    >
      <WorksheetScoringByOneSwiperController
        type={type}
        totalProblemCount={totalProblemCount}
        isAiTutorOpened={isAiTutorOpened}
        openExitConfirmationAiTutor={openExitConfirmationAiTutor}
      />
      {type !== WorksheetDomain.TYPE.자기주도학습 && (
        <Switch
          checked={isProblemSolvingStatusShown}
          onChange={() => setIsProblemSolvingStatusShown((prevP) => !prevP)}
          className="problem-solving-status-switch"
        >
          문제 풀이 현황
        </Switch>
      )}
      {problemScoring &&
        type === WorksheetDomain.TYPE.챌린지 &&
        referenceParams?.referenceType !== REFERENCE_TYPE_STUDENT_WORKSHEET &&
        commonRepo.Ai튜터_사용가능_여부 && (
          <WorksheetAiTutor
            isSelected={isAiTutorOpened}
            problemScoring={problemScoring}
            onClick={() => setIsAiTutorOpened?.((prev) => !prev)}
          />
        )}
    </S.WorksheetScoringByOneHeader>
  )
}

export default WorksheetScoringByOneHeader

const S = {
  WorksheetScoringByOneHeader: styled.div`
    display: flex;
    align-items: center;
    flex: 0 0 48px;

    &.student-wrong-concept {
      position: absolute;
      top: 8px;
      right: 22px;
      z-index: 100;
    }
    .problem-solving-status-switch {
      margin-left: 24px; // TODO: 확인해보기
    }
  `,
}
