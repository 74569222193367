// 학습지문제(WorksheeetProblem)
// 문제은행의 문제(Problem), 교재문제(WorkbookProblem)와 다르다.

import type { ValueOf } from '@mathflat/shared/@types/utilityTypes'

import { WorksheetDomain } from '~/@entities'
import type { AllGrade, SchoolType } from '~/@entities/(SchoolSystem)/schema.ts'

import type { ContentLevel } from '../module'

class _WorksheetCommon {
  id: number
  type: ValueOf<typeof WorksheetDomain.TYPE>
  school: SchoolType
  grade: AllGrade
  title: string
  titleTag: string
  chapter: string
  tag: 'BASIC' | 'CONCEPT_CHIP_WEAK_PROBLEM' // TODO: 혜은) ENUM 알아내기 -> contants에 업데이트 후 거기서 ValueOf로 가져오기
  problemCount: number
  level: ContentLevel
  autoScorable: boolean
  accessModifierToStudent: 'PUBLIC' | 'PRIVATE' // TODO: 혜은) ENUM 알아내기 -> contants에 업데이트 후 거기서 ValueOf로 가져오기

  constructor(params: _WorksheetCommon) {
    this.id = params.id
    this.type = params.type
    this.school = params.school
    this.grade = params.grade
    this.title = params.title
    this.titleTag = params.titleTag
    this.chapter = params.chapter
    this.tag = params.tag
    this.problemCount = params.problemCount
    this.level = params.level
    this.autoScorable = params.autoScorable
    this.accessModifierToStudent = params.accessModifierToStudent
  }
}

export namespace Entity {
  export class CustomWorksheet extends _WorksheetCommon {
    override type = WorksheetDomain.TYPE.CUSTOM
  }

  export class ExamWorksheet extends _WorksheetCommon {
    override type = WorksheetDomain.TYPE.시험
  }

  export class MAATWorksheet extends _WorksheetCommon {
    override type = WorksheetDomain.TYPE.MAAT
  }

  // 챌린지 - 문제풀이
  export class ChanllengeWorksheet extends _WorksheetCommon {
    override type = WorksheetDomain.TYPE.챌린지
  }

  // 자기주도
  class SelfLearningWorksheet extends _WorksheetCommon {
    override type = WorksheetDomain.TYPE.자기주도학습
  }

  // 자기주도 - 심화학습
  export class LevelUpWorksheet extends SelfLearningWorksheet {
    selfLearningType = WorksheetDomain.SELF_LEARNING_TYPE.심화학습
  }

  // 자기주도 - 오답유형학습 === 챌린지 끝나고 오답유형학습
  export class WrongConceptWorksheet extends SelfLearningWorksheet {
    selfLearningType = WorksheetDomain.SELF_LEARNING_TYPE.오답유형학습
  }

  // 소단원 개념학습
  export class ConceptualWorksheet extends _WorksheetCommon {
    override type = WorksheetDomain.TYPE.CONCEPTUAL
  }

  // * Worksheet *
  export type Worksheet =
    | CustomWorksheet
    | ExamWorksheet
    | ChanllengeWorksheet
    | LevelUpWorksheet
    | WrongConceptWorksheet
    | MAATWorksheet
    | ConceptualWorksheet
}
