import TooltipComponent from './Tooltip'
import { TooltipContent, TooltipItem, TooltipTitle } from './TooltipContent'

const Tooltip = Object.assign(TooltipComponent, {
  Content: TooltipContent,
  Title: TooltipTitle,
  Item: TooltipItem,
})

export default Tooltip
