import type { IconButtonModule } from '../(Button)/IconButton'
import IconButton from '../(Button)/IconButton/IconButton'

export const SwiperDefaultBackButton = (props: Omit<IconButtonModule.Props, 'name'>) => (
  <IconButton name="icon_navigation_arrow_back" theme="white" {...props} />
)

export const SwiperDefaultNextButton = (props: Omit<IconButtonModule.Props, 'name'>) => (
  <IconButton name="icon_navigation_arrow_right" theme="white" {...props} />
)

export const SwiperBackButton2 = (props: Omit<IconButtonModule.Props, 'name'>) => (
  <IconButton
    name="icon_chevron_left"
    theme="white"
    data-component={'ms__SwiperBackButton'}
    {...props}
    style={{ boxShadow: 'none', backgroundColor: 'transparent' }}
  />
)

export const SwiperNextButton2 = (props: Omit<IconButtonModule.Props, 'name'>) => (
  <IconButton
    name="icon_chevron_right"
    theme="white"
    data-component={'ms__SwiperNextButton'}
    {...props}
    style={{ boxShadow: 'none', backgroundColor: 'transparent' }}
  />
)
