import { css } from '@emotion/react'
import type { FC, ReactNode } from 'react'

import { typo } from '~/@common/styles'

export const kind = 'ms__ToastContent' as const

interface Props {
  text: string
  icon: ReactNode
  subContent?: ReactNode
}

const ToastContent: FC<Props> = ({ text, icon, subContent }) => {
  return (
    <div css={_ToastContent} data-component={kind}>
      {icon}
      <div className="content">{text}</div>
      {subContent}
    </div>
  )
}

export default ToastContent

const _ToastContent = css`
  display: flex;
  align-items: center;
  gap: 16px;
  ${typo.body02};
  font-family: Spoqa Han Sans Neo;

  .content {
    white-space: pre-line;
    flex-grow: 1;
    margin-right: 16px;
  }
`
