import styled from '@emotion/styled'
import type { CSSProperties } from 'react'
import { isAndroid, isChrome, isIOS, isSafari } from 'react-device-detect'

import useQRScan from '~/@common/hooks/useQRScan'
import { localStorageService } from '~/@common/services/storage.service'
import { zIndex } from '~/@common/styles'

type Props = {
  qrButton: (isAppUpdated: boolean) => React.ReactNode
  tooltip?: React.ReactNode
  contentsStyle?: CSSProperties
}

export const QRScanButton = ({ tooltip, qrButton, contentsStyle }: Props) => {
  const ver = localStorageService.device.ver ? +localStorageService.device.ver : 1
  const isApp = (isAndroid || isIOS) && !isSafari && !isChrome

  const { isAppUpdated, performQrScan, turnOffQrGuide } = useQRScan()
  const isAvailableQRscanAppVer = ver > 1

  const isTooltipVisible = tooltip && isAppUpdated

  if (!isApp || !isAvailableQRscanAppVer) return null

  return (
    <>
      {isTooltipVisible && <S.Dim onClick={turnOffQrGuide} />}
      <S.Contents style={contentsStyle}>
        {isTooltipVisible && tooltip}
        <div onClick={performQrScan}>{qrButton(isAppUpdated)}</div>
      </S.Contents>
    </>
  )
}

const S = {
  Dim: styled.div`
    position: absolute;
    top: 0;
    left: 0;
    z-index: ${zIndex.QR스캔};
    width: 100vw;
    height: 100vh;
    background-color: #00000080;
  `,
  Contents: styled.div`
    position: relative;
    z-index: ${zIndex.QR스캔};
  `,
}
