import type { Entity } from '@mathflat/domain/@entities/Problem/dto'
import { observer } from 'mobx-react'

import Switch from '~/@common/ui/Switch/Switch.tsx'

import S from './ProblemScoringCard.style'

const ProblemScoringBody = observer(
  ({
    문제이미지,
    문제같이보기,
    showFooter,
  }: {
    문제이미지?: Entity.Problem['problemImageUrl']
    문제같이보기?: boolean
    showFooter: boolean
  }) => {
    if (!문제이미지 || !문제같이보기) return <></>

    if (!문제같이보기 && !showFooter) {
      return (
        <S.HideProblem>
          <p>
            문제 같이 보기
            <Switch disabled />
          </p>
          <p>옵션을 키면 문제가 나타납니다.</p>
        </S.HideProblem>
      )
    }
    return (
      <S.ProblemScoringContent className="problem-content">
        <div className="problem-content-container">
          <img className="problem-image" src={문제이미지} alt="problem-image" />
        </div>
      </S.ProblemScoringContent>
    )
  },
)

export default ProblemScoringBody
