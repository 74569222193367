import { css } from '@emotion/react'
import cx from 'clsx'
import { type FC, type ReactNode, useState } from 'react'

import { colors, fontWeight, typo } from '~/@common/styles'
import { mediaQuery } from '~/@common/styles/mediaQuery'
import { Icon } from '~/@common/ui/Icon/Icon'

type ItemsType = 'concepts' | 'lectures' | 'mixed'

interface Props {
  type: ItemsType
  isPreLearned: boolean
  isDefaultOpened?: boolean
  children: ReactNode
}

const GroupBox: FC<Props> = ({ type, isPreLearned, isDefaultOpened, children }) => {
  const isAccordian = type !== 'concepts'
  const [isOpened, setIsOpened] = useState(!isAccordian || isDefaultOpened)

  const handleHeaderClick = () => {
    if (!isAccordian) {
      return
    }
    setIsOpened((value) => !value)
  }

  let title = ''

  switch (type) {
    case 'concepts':
      title = isPreLearned ? '사전개념 학습' : '개념 학습'
      break
    case 'lectures':
      title = isPreLearned ? '사전개념 영상' : '개념 영상'
      break
    case 'mixed':
      title = isPreLearned ? '사전개념 & 강의' : '개념 & 강의'
      break
  }

  let description = ''

  switch (type) {
    case 'concepts':
      description = isPreLearned
        ? '추가 학습이 필요하다면 사전개념 학습을 시작해보세요.'
        : '개념영상과 함께 문제를 보며 학습해보세요.'
      break
    case 'lectures':
      description = isPreLearned
        ? '추가 학습이 필요하다면 사전개념 영상으로 학습할 수 있어요.'
        : '개념영상을 통해 개념 학습을 해보세요.'
      break
    case 'mixed':
      description = isPreLearned
        ? '필수 학습 이후 추가적으로 학습이 필요하다면 사전개념과 강의로 선택 학습할 수 있어요.'
        : '개념과 강의를 통해 학습을 해보세요.'
      break
  }

  const badgeText = isPreLearned ? '선택 학습' : '필수 학습'
  const badgeCx = ['badge']

  if (isPreLearned) {
    badgeCx.push('badge-prelearned')
  }

  return (
    <div css={_css}>
      <div
        className="header"
        onClick={handleHeaderClick}
        style={{
          cursor: isAccordian ? 'pointer' : 'unset',
        }}
      >
        <div className="header-content">
          <div>
            <span className={cx(badgeCx)}>{badgeText}</span>
            <strong className="title">{title}</strong>
          </div>
          <div className="description">{description}</div>
        </div>
        {isAccordian && (
          <div
            style={{
              rotate: `${isOpened ? 180 : 0}deg`,
              transition: 'rotate 100ms',
              lineHeight: 0,
            }}
          >
            <Icon name="icon_chevron_down" size={16} color={colors.gray.$900} />
          </div>
        )}
      </div>
      {isOpened && <div className="list">{children}</div>}
    </div>
  )
}

export default GroupBox

const _css = css`
  background-color: ${colors.white};
  border: 1px solid ${colors.gray.$200};
  border-radius: 14px;
  overflow: hidden;

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px;
    color: ${colors.gray.$900};
    ${typo.body02};
  }

  .header-content {
    display: flex;
    flex-direction: column;
    gap: 4px;
    padding-right: 20px;

    > div {
      display: flex;
      align-items: center;
      gap: 8px;
    }

    .badge {
      padding: 2px 4px;
      border-radius: 4px;
      color: ${colors.white};
      background-color: ${colors.gray.$900};
      border: 1px solid transparent;
      ${typo.caption02};
      font-weight: ${fontWeight.bold};

      &.badge-prelearned {
        color: ${colors.gray.$700};
        background-color: ${colors.white};
        border-color: ${colors.gray.$800};
      }
    }

    .title {
      ${typo.heading05};
      font-weight: ${fontWeight.bold};
    }
  }

  ${mediaQuery.underTablet} {
    .header {
      padding: 16px;
      ${typo.caption01};
    }
    .header-content {
      .title {
        ${typo.body01};
      }
    }
  }
`
