import { makeAutoObservable, toJS } from 'mobx'
import { isIOS } from 'react-device-detect'

import { PIXEL_RATIO } from '../../constants'
import type { Point, Size, TimeStamp } from '../../types'
import type { NoteData } from '../handwrittenNote.types'
import HandwrittenNoteControllerService from './HandwrittenNoteController.service'
import HandwrittenNoteData from './HandwrittenNoteData'
import { PathType } from './HandwrittenNotePathItem'

const USE_SUBPIXEL = !isIOS
const PATH_TYPE = isIOS ? PathType.NoSubPixel : PathType.SubPixel

interface PanPointData {
  clientX: number
  clientY: number
  offsetX: number
  offsetY: number
}

export default class HandwrittenNoteService {
  static VERSION = 1

  isSaving = false
  eraserPoint?: Point = undefined
  writerFunc?: (data: NoteData) => Promise<void>
  noteData: HandwrittenNoteData = new HandwrittenNoteData()
  controllerService: HandwrittenNoteControllerService
  isHiddenNote = false

  private lastSaveTimestamp: TimeStamp = 0
  private saveTimer = 0
  private viewBoxSize: Size | undefined
  private lastPointX: number | undefined
  private lastPointY: number | undefined

  constructor(controllerService?: HandwrittenNoteControllerService) {
    makeAutoObservable(this)
    if (!controllerService) {
      this.controllerService = new HandwrittenNoteControllerService()
    } else {
      this.controllerService = controllerService
    }
  }

  setIsSaving(value: boolean) {
    this.isSaving = value
  }

  setWriterFunc(value: (data: NoteData) => Promise<void>) {
    this.writerFunc = value
  }

  setEraserPoint(value: Point | undefined) {
    this.eraserPoint = value
  }

  setViewBoxSize(value: Size | undefined) {
    this.viewBoxSize = value
  }

  handlePanStart(data: PanPointData) {
    const { clientX, clientY, offsetX, offsetY } = data
    const { activeTool, toolColor, toolWidth } = this.controllerService

    if (activeTool === 'eraser') {
      this.noteData.addEraserItem({ x: clientX, y: clientY })
      this.setEraserPoint({ x: offsetX, y: offsetY })
    } else if (['pen', 'highlight-pen'].includes(activeTool) && toolColor) {
      this.lastPointX = offsetX
      this.lastPointY = offsetY

      this.noteData.addPathItem(
        { x: offsetX, y: offsetY },
        toolWidth,
        toolColor,
        PATH_TYPE,
        USE_SUBPIXEL,
        PIXEL_RATIO,
      )
    }
  }

  handlePan(data: PanPointData) {
    const { clientX, clientY, offsetX, offsetY } = data
    const { activeTool } = this.controllerService

    if (activeTool === 'eraser') {
      this.noteData.deletePaths({ x: clientX, y: clientY })
      this.setEraserPoint({ x: offsetX, y: offsetY })
    } else if (this.lastPointX !== offsetX || this.lastPointY !== offsetY) {
      this.noteData.addPoint({ x: offsetX, y: offsetY })
    }
  }

  handlePanEnd() {
    this.setEraserPoint(undefined)
    this.noteData.checkModified()
    this.noteData.setPathRect()
    this.noteData.handleInvalidPathItems()
  }

  private updateLastSaveTimestamp() {
    this.lastSaveTimestamp = new Date().getTime()
  }

  get isModified() {
    return this.lastSaveTimestamp < this.noteData.lastModified
  }

  async saveNote() {
    if (!this.writerFunc) {
      return false
    }

    if (!this.viewBoxSize || !this.viewBoxSize.width || !this.viewBoxSize.height) {
      throw new Error(`invalid viewBoxSize`)
    }

    const paths = toJS(this.noteData.toPathDataItems())

    if (!paths.length) {
      // 데이터가 없어도 저장
      // return false
    }

    const noteData: NoteData = {
      version: HandwrittenNoteService.VERSION,
      paths,
      viewBox: [this.viewBoxSize.width, this.viewBoxSize.height],
    }

    let isSuccess = false

    try {
      this.setIsSaving(true)
      await this.writerFunc(noteData)
      this.updateLastSaveTimestamp()
      isSuccess = true
    } catch (err) {
      isSuccess = false
    } finally {
      this.setIsSaving(false)
    }

    return isSuccess
  }

  requestSave(delay = 2 * 1000) {
    window.clearTimeout(this.saveTimer)
    return new Promise<void>((resolve, reject) => {
      this.saveTimer = window.setTimeout(async () => {
        try {
          await this.saveNote()
          resolve()
        } catch (err) {
          reject(err)
        }
      }, delay)
    })
  }

  reset() {
    this.noteData.reset()
  }
}
