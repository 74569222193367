import { keyframes } from '@emotion/react'
import styled from '@emotion/styled'
import { clsx } from 'clsx'
import { observer } from 'mobx-react'
import React, { useEffect } from 'react'

import { useRepository } from '~/@common/hooks/useRepository.ts'
import { AiTutorService } from '~/@common/services/AiTutor.service.ts'
import authService from '~/@common/services/auth.service.ts'
import { colors, typo } from '~/@common/styles'
import type { ProblemScoring } from '~/@pages/@common/(ProblemScoring)/@trait/ProblemScoring.trait.ts'

type Props = {
  problemScoring: ProblemScoring<'WORKSHEET'>
  isSelected?: boolean
  onClick: () => void
}

const WorksheetAiTutor = ({ problemScoring, isSelected = false, onClick }: Props) => {
  const aiTutorService = useRepository(AiTutorService)

  useEffect(() => {
    aiTutorService.loadAndSetSessionId()
  }, [problemScoring])

  const onClickToRequestQuestion = async () => {
    onClick()
  }

  return (
    <S.Button
      onClick={onClickToRequestQuestion}
      className={clsx('button', isSelected && 'isSelected')}
    >
      <img
        src={`/images/icons/${isSelected ? 'icon_ai_bot_active' : 'icon_ai_bot_default'}.svg`}
        alt="AI 튜터"
        width="18"
        height="auto"
      />
      <div className="button-text">AI 튜터</div>
      <div className="beta">beta</div>
    </S.Button>
  )
}

export default observer(WorksheetAiTutor)

const gradientAnimation = keyframes`
    0% {
        background-position: 0 50%;
    }
    
    100% {
        background-position: 100% 50%;
    }
  `

const S = {
  Button: styled.div`
    display: flex;
    align-items: center;
    gap: 4px;
    margin-left: auto;
    border-radius: 10px;
    border: 1.5px solid #c7d2fe; // new-color-indigo-200
    padding: 9px 14px;
    background-color: #fff;
    color: #3730a3; // new-color-indigo-800
    cursor: pointer;
    ${typo.caption01};
    font-weight: 700;

    &.isSelected {
      border-color: #6366f1; // new-color-indigo-500
      background-color: #eef2ff; // new-color-indigo-50
      color: #3730a3; // new-color-indigo-800

      .beta {
        background-color: #c7d2fe; // new-color-indigo-200
      }
    }

    &:hover {
      background-color: #eef2ff; // new-color-indigo-50

      .beta {
        background-color: #c7d2fe; // new-color-indigo-200
      }
    }

    &:not(.isSelected) {
      .button-text {
        /* 텍스트 색상 */
        color: transparent;
        /* 그라데이션 시작 색상 */
        background: linear-gradient(90deg, #818cf8, #6366f1);

        background-size: 200% 100%;
        /* 그라데이션을 텍스트에만 적용 */
        -webkit-background-clip: text;
        background-clip: text;
        /* 그라데이션 애니메이션 설정 */
        animation: ${gradientAnimation} 1500ms infinite alternate;
      }
    }

    .beta {
      border-radius: 4px;
      background-color: #e0e7ff; // new-color-indigo-100
      padding: 2px 4px;
      ${typo.caption02};
      font-weight: 700;
      color: #818cf8; // new-color-indigo-400
    }
  `,
}
