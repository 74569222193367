import { css } from '@emotion/react'
import type { HandwrittenNoteControllerService } from '@mathflat/handwritten-note'
import { observer } from 'mobx-react'
import type { FC } from 'react'
import { isMobile as isMobileDevice } from 'react-device-detect'

import { TOAST_STRING } from '~/@common/constants/strings'
import { useStudentAppMediaQuery } from '~/@common/hooks/useMediaQuery'
import { useRepository } from '~/@common/hooks/useRepository'
import { errorHandlerService, toastService } from '~/@common/services'
import { colors, typo } from '~/@common/styles'
import { Icon } from '~/@common/ui/Icon/Icon'
import type { IconNames } from '~/@common/ui/Icon/iconNames.type'

import { StudentWorkbookViewerService } from '../../../@service/StudentWorkbookViewer.service'

const ZOOM_LEVEL_STEP = 0.3
const MIN_ZOOM_LEVEL = 1
const MAX_ZOOM_LEVEL = 1 + ZOOM_LEVEL_STEP * 3

interface Props {
  controllerService: HandwrittenNoteControllerService
  onResetNoteScale?: () => void
}

const ImageViewerSubToolbar: FC<Props> = ({ controllerService }) => {
  const viewerService = useRepository(StudentWorkbookViewerService)
  const { isMobile } = useStudentAppMediaQuery()
  const { isFullscreenMode } = viewerService

  const fullscreenToggleData: {
    iconName: IconNames
    label: string
  } = {
    iconName: isFullscreenMode ? 'icon_normal_screen' : 'icon_full_screen',
    label: isFullscreenMode ? '전체화면' : '기본화면',
  }

  const handleZoom = (type: 'plus' | 'minus') => {
    const zoomLevelStep = type === 'plus' ? ZOOM_LEVEL_STEP : -ZOOM_LEVEL_STEP
    viewerService.setZoomLevel(Math.max(1, viewerService.zoomLevel + zoomLevelStep))
  }

  const handleSave = async () => {
    if (!controllerService?.noteService) {
      return
    }
    try {
      await controllerService.noteService.requestSave(0)
      toastService.success(TOAST_STRING.saveHandwrittenNote, { isMobile })
    } catch (err) {
      errorHandlerService.handle(err)
    }
  }

  const isShowZoomControl = !isMobileDevice && !isMobile

  return (
    <div css={_Style}>
      {isShowZoomControl && (
        <div className="zoom-control">
          <button
            type="button"
            className="button"
            onClick={() => handleZoom('minus')}
            disabled={viewerService.zoomLevel <= MIN_ZOOM_LEVEL}
          >
            <Icon name="icon_remove" size={20} />
          </button>
          <div className="delimiter">
            <div></div>
          </div>
          <button
            type="button"
            className="button"
            onClick={() => handleZoom('plus')}
            disabled={viewerService.zoomLevel >= MAX_ZOOM_LEVEL}
          >
            <Icon name="icon_add" size={20} />
          </button>
        </div>
      )}
      {!isMobile && (
        <button
          type="button"
          className="button save"
          onClick={handleSave}
          disabled={
            !controllerService.noteService?.isModified || controllerService.noteService?.isSaving
          }
        >
          필기저장
        </button>
      )}
      <button
        type="button"
        className="button toggle-fullscreen"
        onClick={() => {
          viewerService.setIsFullscreenMode(!viewerService.isFullscreenMode)
        }}
      >
        <Icon name={fullscreenToggleData.iconName} size={20} />
      </button>
    </div>
  )
}

export default observer(ImageViewerSubToolbar)

const _Style = css`
  position: absolute;
  z-index: 2;
  right: 10px;
  top: 10px;
  display: flex;
  gap: 6px;

  .button {
    min-width: 44px;
    height: 44px;
    border-radius: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(0, 0, 0, 0.1);
    ${typo.body01};
    color: ${colors.gray.$700};

    &[disabled] {
      color: ${colors.gray.$500};
    }
    &:not([disabled]):hover {
      background: rgba(0, 0, 0, 0.2);
    }
    &:not([disabled]):active {
      background: rgba(0, 0, 0, 0.3);
    }
  }
  .toggle-fullscreen {
    border-radius: 22px;
  }
  .zoom-control {
    border-radius: 14px;
    overflow: hidden;
    display: flex;
    align-items: center;

    .button {
      border-radius: 0;
      &[disabled] {
        color: inherit;
      }
    }
    .delimiter {
      background: rgba(0, 0, 0, 0.1);
      height: 100%;
      display: flex;
      align-items: center;
      > div {
        width: 1px;
        height: 14px;
        background-color: ${colors.gray.$600};
        opacity: 0.4;
      }
    }
  }
  .save {
    padding: 0 10px;
    &[disabled] {
      color: rgba(112, 112, 112, 0.3);
      background-color: rgba(0, 0, 0, 0.1);
    }
  }
`
