import { keyframes } from '@emotion/react'
import styled from '@emotion/styled'

import { typo } from '~/@common/styles'

const bounce = keyframes`
    0% {
        opacity: 0;
        transform: scale(1);
    }

    20% {
        opacity: 1;
    }

    45% {
        transform: scale(1.2);
    }

     100% {
        opacity: 0;
        transform: scale(1);
    }
`
const bounce2 = keyframes`
    0% {
        opacity: 0;
        transform: scale(1);
    }

    20% {
        opacity: 1;
    }

    45% {
        transform: scale(1.2);
    }

    100% {
        opacity: 0;
        transform: scale(1);
    }
`

const show1 = keyframes`
  0% {
    opacity: 1;
  }
  80% {
    opacity: 0;
  }
`
const show2 = keyframes`
  0% {
    opacity: 0;
  }
  20% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
`
const show3 = keyframes`
  0% {
    opacity: 0;
  }
  40% {
    opacity: 1;
  }
  100% {
    opacity: 0.4;
  }
`
const _show1 = keyframes`
  0% {
    opacity: 1;
  }
  80% {
    opacity: 0;
  }
`
const _show2 = keyframes`
  0% {
    opacity: 0;
  }
  20% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
`
const _show3 = keyframes`
  0% {
    opacity: 0;
  }
  40% {
    opacity: 1;
  }
  100% {
    opacity: 0.4;
  }
`

const S = {
  Wrapper: styled.div`
    position: relative;

    .start {
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      width: 100%;
      height: 100%;

      z-index: 2;

      .wrap {
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        width: 100px;
        height: 100px;

        opacity: 0;

        &.play {
          animation: ${bounce} 0.5s ease-in-out;
        }
        &.pause {
          animation: ${bounce2} 0.5s ease-in-out;
        }
        &.skip_back {
          &.first {
            animation: ${bounce} 0.5s ease-in-out;
          }

          &.second {
            animation: ${bounce2} 0.5s ease-in-out;
          }
        }
        &.skip_forward {
          &.first {
            animation: ${bounce} 0.5s ease-in-out;
          }

          &.second {
            animation: ${bounce2} 0.5s ease-in-out;
          }
        }
      }

      .text {
        z-index: 3;
        position: absolute;
        color: #fff;
      }
      .circle {
        z-index: 1;
        position: absolute;
        width: 100px;
        height: 100px;
        border-radius: 50%;
        background: #000;
        opacity: 0.5;
      }

      svg {
        z-index: 2;
        position: relative;
      }
    }
  `,
  MobileControlLayer: styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 3;

    .left,
    .right {
      position: absolute;
      background: transparent;
      width: 50%;
      height: 100%;

      > div {
        display: flex;
        flex-direction: column;
        gap: 8px;
        position: absolute;
        top: 50%;
        left: 25%;
        transform: translate(100%, -50%);

        > div {
          display: flex;
          justify-content: center;

          > svg {
            margin: -2px;
          }
        }

        > p {
          color: #fff;
          ${typo.caption01};
        }
      }
    }
    .left {
      svg {
        opacity: 0;
        transform: rotateY(180deg);
      }
      .animation-text {
        opacity: 0;
      }

      .first {
        .a {
          animation: ${show3} 0.8s;
        }
        .b {
          animation: ${show2} 0.8s;
        }
        .c {
          animation: ${show1} 0.8s;
        }
        .animation-text {
          animation: ${show3} 0.8s;
        }
      }
      .second {
        .a {
          animation: ${_show3} 0.8s;
        }
        .b {
          animation: ${_show2} 0.8s;
        }
        .c {
          animation: ${_show1} 0.8s;
        }
        .animation-text {
          animation: ${_show3} 0.8s;
        }
      }
    }
    .right {
      left: 50%;
      svg {
        opacity: 0;
      }
      .animation-text {
        opacity: 0;
      }

      .first {
        .a {
          animation: ${show1} 0.8s;
        }
        .b {
          animation: ${show2} 0.8s;
        }
        .c {
          animation: ${show3} 0.8s;
        }
        .animation-text {
          animation: ${show3} 0.8s;
        }
      }
      .second {
        .a {
          animation: ${_show1} 0.8s;
        }
        .b {
          animation: ${_show2} 0.8s;
        }
        .c {
          animation: ${_show3} 0.8s;
        }
        .animation-text {
          animation: ${_show3} 0.8s;
        }
      }
    }

    .thumbnail-play-button-wrap {
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 4;

      width: 100%;
      height: 100%;
      background-size: cover;
      background-repeat: no-repeat;

      > button {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 60px;
        height: 60px;
        border-radius: 50%;
        background: rgba(0, 0, 0, 0.5);
      }
    }

    .layer {
      width: 100%;
      height: 100%;
      display: none;
      z-index: 3;

      &.show-layer {
        display: flex;
        background: rgba(0, 0, 0, 0.5);
      }

      .loader-wrap {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 80px;
        height: 80px;
      }
    }

    .icon-button-wrap {
      z-index: 3;

      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      display: flex;
      gap: 30px;
      align-items: center;
      justify-content: center;

      > button {
        position: relative;
        cursor: pointer;

        > p {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          color: #fff;
          ${typo.caption02};
        }
      }
    }
  `,
  MobileProgressBar: styled.div`
    position: absolute;
    bottom: 0;
    z-index: 3;
    display: flex;
    align-items: center;
    width: 100%;
    height: 56px;
    padding: 0 24px;

    .seek-bar-wrapper {
      width: 100%;
      padding: 0 24px;

      > div {
        width: 100%;
        margin: 0;
      }
    }

    .setting-button {
      margin: 0 6px;
    }
  `,
  ProgressBar: styled.div`
    z-index: 3;
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 56px;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.6) 100%);

    .progress-bar-wrapper {
      height: 33px;
    }
    .button-wrapper {
      display: flex;
      justify-content: space-between;
      padding: 4px 12px 2px;

      > .left {
        display: flex;

        .play-button {
          margin-right: 14px;
        }
      }

      > .right {
        display: flex;

        .setting-button {
          margin-right: 14px;
        }
      }
    }
  `,

  ThumbnailContainer: styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    overflow: hidden;
  `,
}

export default S
