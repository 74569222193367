import type { Entity as CurriculumEntity } from '@mathflat/domain/@entities/Curriculum/dto'
import type { MathflatApi } from '@mathflat/domain/@entities/Lecture/api'
import type { Entity as LectureEntity } from '@mathflat/domain/@entities/Lecture/dto'
import type { StudentDomain } from '@mathflat/domain/@entities/Student/domain.ts'

import maxios from '../utils/maxios'

export const lectureApi = {
  getLectureByTrieKey: async (trieKey: CurriculumEntity.CurriculumTree['trieKey']) => {
    const { data } = await maxios.get<MathflatApi.Response.LectureByTrieKey[]>('/lecture/group', {
      params: { curriculumKey: trieKey },
    })
    return data.map(({ middleChapterLectures, curriculum }) => ({
      leadingCurriculum: curriculum,
      middleChapterLectures,
    }))
  },
  getDoneLectureIdByTrieKey: async (params: {
    studentId: StudentDomain.Id
    trieKey: CurriculumEntity.CurriculumTree['trieKey']
  }) => {
    const {
      data: { lectureIds },
    } = await maxios.get<MathflatApi.Response.StudentLecture>(
      `/student-lecture/${params.studentId}`,
      {
        params,
      },
    )
    return lectureIds
  },
  getLectureByLectureId: async (lectureId: LectureEntity.Lecture['id']) => {
    const { data } = await maxios.get<MathflatApi.Response.Lecture>(`/lecture/${lectureId}`)
    return data
  },
  postStudentLecture: async (params: {
    studentId: StudentDomain.Id
    lectureId: LectureEntity.Lecture['id']
  }) => {
    return await maxios.post('student-lecture', params)
  },
}
