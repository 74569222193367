import {
  type CONTENT_STATUS,
  CONTENT_STATUS_KO,
  MAAT_CONTENT_STATUS_KO,
} from '@mathflat/domain/@entities/(Content)/module'
import clsx from 'clsx'
import type { ValueOf } from 'type-fest'

export const StatusTag = ({
  contentStatus,
  type,
}: {
  contentStatus: ValueOf<typeof CONTENT_STATUS> | 'IMPOSSIBLE'
  type?: 'MAAT' | 'default'
}) => {
  return (
    <span className={clsx('tag', contentStatus)}>
      {contentStatus === 'IMPOSSIBLE'
        ? '응시불가'
        : type === 'MAAT'
          ? MAAT_CONTENT_STATUS_KO[contentStatus]
          : CONTENT_STATUS_KO[contentStatus]}
    </span>
  )
}
