import modalService from '~/@common/services/modal.service'
import { localStorageService } from '~/@common/services/storage.service'
import PasswordChange from '~/@pages/@widgets/PasswordChange.widget'
import { GuideVideoModal } from '~/@pages/student/student-home/@widgets/GuideVideoModal/GuideVideoModal'

export const openGuideVideoPopup = () => {
  modalService.openModal(
    {
      content: <GuideVideoModal />,
    },
    {
      modalName: '사용가이드 영상 안내 모달',
      headerOption: { hasBottomBorder: false },
    },
  )
}

export const openPasswordChangePopup = (isMobile: boolean) => {
  modalService.openModal(
    {
      content: <PasswordChange isInitial />,
      header: '비밀번호 변경',
    },
    {
      modalName: '최초 진입 비밀번호 변경 모달',
      hasCloseButton: isMobile,
      headerOption: {
        hasBottomBorder: false,
      },
    },
  )
  setTimeout(() => {
    localStorageService.isInitialPasswordClear()
  })
}
