import { observer } from 'mobx-react'
import type { FC } from 'react'

import { PIXEL_RATIO } from '../constants'
import { colors } from './handwrittenNote.const'
import type HandwrittenNoteService from './service/HandwrittenNote.service'

interface Props {
  service: HandwrittenNoteService
}

const HandwrittenNoteEraserIndicator: FC<Props> = ({ service }) => {
  const { eraserPoint } = service

  if (!eraserPoint) {
    return null
  }

  const eraserWidth = service.controllerService.toolWidth

  return (
    <div
      style={{
        position: 'absolute',
        top: 0,
        left: 0,
        width: eraserWidth,
        height: eraserWidth,
        marginLeft: -eraserWidth / 2,
        marginTop: -eraserWidth / 2,
        transform: `translate(${eraserPoint.x / PIXEL_RATIO}px, ${eraserPoint.y / PIXEL_RATIO}px)`,
        backgroundColor: 'rgba(51, 51, 51, 0.05)',
        border: `1px solid ${colors.gray.$500}`,
        borderRadius: '50%',
        pointerEvents: 'none',
        transformOrigin: '0 0',
      }}
    ></div>
  )
}

export default observer(HandwrittenNoteEraserIndicator)
