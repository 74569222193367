import { CONTENT_STATUS } from '@mathflat/domain/@entities/(Content)/module'
import clsx from 'clsx'
import { observer } from 'mobx-react'
import { Fragment } from 'react'

import modalService from '~/@common/services/modal.service'
import { colors, colorTheme } from '~/@common/styles'
import { Icon } from '~/@common/ui/Icon/Icon'
import type { StudentWorkbookDetailPageDTO } from '~/@entities/StudentWorkbook/StudentWorkbook.dto'

import S from '../StudentWorkbookDetail.style'

const StudentWorkbookDetailPageList = ({
  onPageNavigate,
  allPages,
}: {
  allPages: StudentWorkbookDetailPageDTO[]
  onPageNavigate: (pageTo?: StudentWorkbookDetailPageDTO['prevPageNumber']) => void
}) => {
  let lastChapter = ''

  return (
    <S.PageList>
      {allPages.map((page) => {
        const isChapterChange = page.title.replace(/\s/g, '') !== '' && page.title !== lastChapter

        lastChapter = page.title
        return (
          <Fragment key={page.progressId}>
            {isChapterChange && (
              <S.PageListTitle
                className={clsx(page.status, page.title.replace(/\s/g, '') === '' && 'no-title')}
              >
                {page.title}
              </S.PageListTitle>
            )}
            <S.PageListItem
              className="page-list-item"
              onClick={() => {
                onPageNavigate(page.pageNumber)
                modalService.closeModal()
              }}
            >
              <div className="left">
                <button className="page-list-item-icon-wraper">
                  {page.status === CONTENT_STATUS.채점전 ? (
                    <Icon name="icon_list" size={20} color={colors.gray.$500} />
                  ) : (
                    <Icon name="icon_playlist_add_check" size={20} color={colorTheme.primary} />
                  )}
                </button>
                <span className={clsx('page-list-item-page-number-text', page.status)}>
                  {page.pageNumber}P
                </span>
              </div>
              <div className="right">
                <button className="page-list-item-icon-wraper">
                  {page.status !== CONTENT_STATUS.채점전 && (
                    <>
                      <Icon name="icon_playlist_add_check" size={20} color={colors.gray.$500} />
                      <span className="mobile-scoring-done-text">
                        {page.status === CONTENT_STATUS.일부채점 ? '채점중' : '채점완료'}
                      </span>
                    </>
                  )}
                </button>
                <Icon
                  name="icon_chevron_right"
                  size={20}
                  color={colors.gray.$500}
                  className="right-icon"
                />
              </div>
            </S.PageListItem>
          </Fragment>
        )
      })}
    </S.PageList>
  )
}

export default observer(StudentWorkbookDetailPageList)
