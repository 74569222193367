import { css } from '@emotion/react'
import clsx from 'clsx'
import { observer } from 'mobx-react'
import { type FC, type ReactElement, useRef, useState } from 'react'
import { Navigation } from 'swiper/modules'
import type { SwiperClass } from 'swiper/react'
import { Swiper, SwiperSlide } from 'swiper/react'

import { colors } from '~/@common/styles'
import { Icon } from '~/@common/ui/Icon/Icon'

type ButtonType = 'prev' | 'next'

interface Props {
  items: ReactElement[]
  index: number
  onIndexChange: (index: number) => void
}

interface ControlButtonProps {
  type: ButtonType
  swiper?: SwiperClass
  className?: string
}

const ControlButton: FC<ControlButtonProps> = observer(({ type, swiper, className }) => {
  const isPrev = type === 'prev'

  const handleClick = () => {
    if (!swiper) {
      return
    }

    switch (type) {
      case 'prev':
        swiper.slidePrev()
        break
      case 'next':
        swiper.slideNext()
    }
  }

  const deg = isPrev ? 0 : 180

  return (
    <button type="button" onClick={handleClick} className={clsx('button', className)}>
      <Icon name="icon_dropdown_arrow_left_fill" size={16} rotate={deg} color={colors.gray.$500} />
    </button>
  )
})

const RecentChallengeSlider: FC<Props> = ({ items, index, onIndexChange }) => {
  const swiperRef = useRef<SwiperClass>()
  const [isReady, setIsReady] = useState(false)
  const buttonTypes: ButtonType[] = ['prev', 'next']

  return (
    <div css={_Style}>
      <div
        css={{
          flexGrow: 1,
          overflow: 'hidden',
          order: 2,
        }}
      >
        <Swiper
          onInit={(instance) => {
            swiperRef.current = instance
            setIsReady(true)
          }}
          modules={[Navigation]}
          initialSlide={index}
          loop={true}
          onSlideChange={({ realIndex }) => onIndexChange(realIndex)}
        >
          {items.map((item, index) => (
            <SwiperSlide key={index}>
              <div className="slider-content">{item}</div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
      {items.length > 0 &&
        buttonTypes.map((type) => (
          <ControlButton
            key={type}
            type={type}
            css={{
              visibility: isReady ? 'visible' : 'hidden',
              order: type === 'prev' ? 1 : 3,
            }}
            swiper={swiperRef.current}
          />
        ))}
    </div>
  )
}

export default observer(RecentChallengeSlider)

const _Style = css`
  display: flex;
  align-items: center;
  gap: 8px;

  .slider-content {
    > div > span {
      text-align: center;
      overflow: hidden;
      white-space: nowrap;
      width: 100%;
      text-overflow: ellipsis;
      display: block;
    }
  }
  .button {
    line-height: 0;
    .swiper-slide {
      text-overflow: ellipsis;
      whitep-space: nowrap;
    }
  }
`
