import { css } from '@emotion/react'
import styled from '@emotion/styled'

const degreeArr = Array.from({ length: 360 / 15 + 1 }, (v, i) => i * 15)

export const S = {
  GroupArrow: {
    _IconWrap: styled.div`
      display: flex;
      align-items: center;
      height: 100%;
    `,
    Icon: css`
      &.is-animate {
        transition: 0.28s transform cubic-bezier(0.33, 1, 0.68, 1);
      }

      ${degreeArr.reduce((pv, cv) => pv + `&.degree-${cv} {transform: rotate(${cv}deg);}`, '')}
    `,
  },
}
