import { css } from '@emotion/react'
import { observer } from 'mobx-react'
import type { MouseEvent } from 'react'
import { type FC, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router'

import { useLearningTime } from '~/@common/hooks/useLearningTime'
import { useStudentAppMediaQuery } from '~/@common/hooks/useMediaQuery'
import { useRepository } from '~/@common/hooks/useRepository'
import { errorHandlerService } from '~/@common/services'
import modalService from '~/@common/services/modal.service'
import { colors, fontWeight, typo } from '~/@common/styles'
import { mediaQuery } from '~/@common/styles/mediaQuery'
import PrevLink from '~/@common/ui/(Link)/PrevLink/PrevLink'
import { Icon } from '~/@common/ui/Icon/Icon'
import Modal from '~/@common/ui/modal/Modal'
import { PortalRootLayoutHeader } from '~/@common/ui/PortalRootLayoutHeader'
import GlobalHeader, { parts } from '~/@pages/@widgets/(Navigation)/GlobalHeader/GlobalHeader'

import { ConceptualLearningService } from './@widgets/service/ConceptualLearning.service'
import ConceptualLearningEduMaterialSection from './@widgets/ui/ConceptualLearningEduMaterialSection'

const ConceptualLearningPage: FC = () => {
  useLearningTime()
  const service = useRepository(ConceptualLearningService)
  const { studentWorksheetId } = useParams<{ studentWorksheetId: string }>()
  const { worksheetProblemScoring } = service
  const { isMobile } = useStudentAppMediaQuery()
  const navigate = useNavigate()

  useEffect(() => {
    if (!studentWorksheetId) {
      return
    }

    const fetchData = async () => {
      try {
        await Promise.all([
          service.featchConceptualEduMaterial(studentWorksheetId),
          service.fetchAssignedStudentWorksheetWithProblems(Number(studentWorksheetId)),
        ])
      } catch (err) {
        errorHandlerService.handle(err)
      }
    }
    fetchData()
  }, [service, studentWorksheetId])

  useEffect(() => {
    return () => {
      service.cleanup()
    }
  }, [service])

  const title = worksheetProblemScoring?.worksheet?.title

  const handlePrevLinkClick = (e: MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault()

    if (
      worksheetProblemScoring?.studentWorksheet?.status === 'COMPLETE' ||
      !worksheetProblemScoring?.problemScoringColl?.toScoredArr.length
    ) {
      navigate(-1)
      return
    }
    modalService.openModal(
      <Modal.Confirm.Positive
        cancel={{
          children: '돌아가기',
          onClick: () => {
            modalService.closeModal()
          },
        }}
        confirm={{
          children: '종료하기',
          onClick: () => {
            navigate(-1)
            modalService.closeModal()
          },
        }}
      >
        <p>
          학습 중인 내용이 아직 남아있어요.
          <br />
          그래도 종료할까요?
          <br />
          (학습 중인 데이터는 임시저장됩니다.)
        </p>
      </Modal.Confirm.Positive>,
      {
        modalName: '소단원개념학습 나가기',
      },
    )
  }

  return (
    <div css={_css}>
      <PortalRootLayoutHeader>
        <GlobalHeader
          css={_css}
          SlotLeft={
            <div className="header-left">
              <PrevLink className={parts.slotLeftPrevLink} onClick={handlePrevLinkClick}>
                <Icon name="icon_chevron_left" size={20} />
              </PrevLink>
              {!isMobile && <div className="header-title">{title}</div>}
            </div>
          }
          SlotCenter={isMobile && <div className="header-title">{title}</div>}
        ></GlobalHeader>
      </PortalRootLayoutHeader>
      <ConceptualLearningEduMaterialSection />
    </div>
  )
}

export default observer(ConceptualLearningPage)

const _css = css`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  justify-content: center;
  flex: 1 0;

  ${mediaQuery.underTablet} {
    flex: unset;
  }

  ${mediaQuery.underTablet} and (orientation: landscape) {
    overflow: auto;
  }

  .header-left {
    display: flex;
    width: max-content;
    gap: 14px;
  }
  .header-title {
    ${typo.body01};
    font-weight: ${fontWeight.bold};
    color: ${colors.gray.$900};

    ${mediaQuery.underTablet} {
      ${typo.body02};
    }
  }
`
