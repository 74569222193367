import styled from '@emotion/styled'

import { colors, typo, whiteAreaCss } from '~/@common/styles'
import { mediaQuery } from '~/@common/styles/mediaQuery'

export const LECTURE_GROUP_CARD_SIZE = 384

export const S = {
  LectureGroup: styled.div`
    ${whiteAreaCss}
    display: flex;
    flex-direction: column;
    box-shadow: none;

    overflow: hidden;
    flex: 0 0 ${LECTURE_GROUP_CARD_SIZE}px;
    height: 100%;

    ${mediaQuery.underTablet} {
      flex: 0 0 auto;
      height: auto;
    }

    header {
      display: flex;
      align-items: center;
      gap: 8px;
      flex: 0 0 53px;
      padding: 6px 16px;
      color: ${colors.gray.$900};
      border-bottom: 1px solid ${colors.gray.$200};

      .header-icon {
        width: 40px;
        height: 40px;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        flex-shrink: 0;
        border-radius: 12px;
        background: #fafafa;
      }
    }
    .lecture-group-item-list {
      display: flex;
      flex-direction: column;
      overflow-y: hidden;
      flex: 1 1 auto;
    }
    .lecture-group-item {
      display: block;
    }
    .is-active.lecture-group-item {
      background-color: rgba(57, 95, 226, 0.1);
    }
  `,

  LectureGroupItem: styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 20px 24px;
    color: ${colors.gray.$900};
    cursor: pointer;

    &:hover {
      background-color: ${colors.gray.$100};
    }
  `,

  LectureGroupList: styled.div`
    flex: 1 1 auto;

    display: flex;
    gap: 24px;

    scroll-behavior: smooth;
    overflow-y: hidden;
    overflow-x: hidden;

    ${mediaQuery.underTablet} {
      flex-direction: column;
      height: 100%;
      overflow-x: hidden;
      overflow-y: auto;
    }
  `,

  Empty: styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 100%;
    gap: 15px;

    .circle {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 50px;
      height: 50px;
      background-color: rgba(57, 95, 226, 0.5);
      border-radius: 50%;
    }
    p {
      ${typo.body01};
      color: ${colors.gray.$800};
    }
  `,
}
