import { css } from '@emotion/react'
import { observer } from 'mobx-react'
import type { FC } from 'react'
import { Link } from 'react-router-dom'

import { routeName } from '~/@common/constants'
import { colors, typo } from '~/@common/styles'
import type { DefaultProps } from '~/@common/types'
import Button from '~/@common/ui/(Button)/Button/Button'

import type { RecentStudyType } from '../@common/types'

export type NoDataType =
  | 'SMILE_CHALLENGE'
  | 'SMILE_CHALLENGE_STRENGTHS'
  | 'SMILE_CHALLENGE_WEAKNESSES'
  | 'RECENT_CHALLENGE'
  | RecentStudyType

const RECENT_STUDY_INFO = '\n선생님에게 요청해주세요.'

const noDataText: Record<NoDataType, string> = {
  SMILE_CHALLENGE: '추천할 수 있는 유형칩이 없어요\n챌린지 메뉴에서 학습을 진행해주세요.',
  SMILE_CHALLENGE_STRENGTHS: '추천할 수 있는 유형칩이 없어요!',
  SMILE_CHALLENGE_WEAKNESSES: '추천할 수 있는 유형칩이 없어요!',
  RECENT_CHALLENGE: '유형을 학습한 기록이 없어요.\n챌린지 메뉴에서 학습을 진행해주세요.',
  ALL: `출제된 학습지, 교재가 없어요.${RECENT_STUDY_INFO}`,
  HOMEWORK: `출제된 숙제가 없어요.${RECENT_STUDY_INFO}`,
  WORKSHEET: `출제된 학습지가 없어요.${RECENT_STUDY_INFO}`,
  WORKBOOK: `출제된 교재가 없어요.${RECENT_STUDY_INFO}`,
  EXAM: `출제된 시험지가 없어요.${RECENT_STUDY_INFO}`,
}

type Props = {
  type: NoDataType
} & DefaultProps

const NoData: FC<Props> = ({ type, className }) => {
  const text = noDataText[type]

  return (
    <div css={_Style} className={className}>
      <p>{text}</p>
      {type === 'RECENT_CHALLENGE' && (
        <Button
          theme="secondary"
          size="small"
          asChild={true}
          minWidth={200}
          style={{ backgroundColor: colors.blue.$110 }}
        >
          <Link to={routeName.student.challenge}>챌린지 메뉴로 이동하기</Link>
        </Button>
      )}
    </div>
  )
}

export default observer(NoData)

const _Style = css`
  ${typo.body02};
  text-align: center;
  color: ${colors.gray.$600};
  white-space: pre-line;
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
`
