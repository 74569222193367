import { createContext } from '@radix-ui/react-context'
import type { InputHTMLAttributes, PropsWithRef } from 'react'

export const RadioGroupConsumerName = 'ui/radio-group'

export const [RadioGroupProvider, useRadioGroupContext] =
  createContext<RadioGroupContextValue | null>(RadioGroupConsumerName)

export type RadioGroupInputValue = string | number

export type RadioGroupContextValue = PropsWithRef<
  Pick<InputHTMLAttributes<HTMLInputElement>, 'name'>
> & {
  value?: RadioGroupInputValue
  onChange?: (v: RadioGroupInputValue, e?: React.ChangeEvent<HTMLInputElement>) => void
}
