import styled from '@emotion/styled'
import { useState } from 'react'

import { useStudentAppMediaQuery } from '~/@common/hooks/useMediaQuery.ts'
import { toastService } from '~/@common/services'
import modalService from '~/@common/services/modal.service'
import { colors, typo } from '~/@common/styles'
import { mediaQuery } from '~/@common/styles/mediaQuery'
import Button from '~/@common/ui/(Button)/Button/Button'
import { studentHomeApi } from '~/@pages/student/student-home/@common/api'

const OpinionModal = () => {
  const [text, setText] = useState('')
  const { isMobile } = useStudentAppMediaQuery()

  return (
    <S.ModalContainer>
      <header>의견 남기기</header>
      <main>
        <textarea
          maxLength={500}
          value={text}
          onChange={(e) => {
            setText(e.target.value)
          }}
          placeholder="매쓰플랫 학생앱을 사용해보고 느낀 점, 불편한 점 등 자유롭게 의견을 남겨주세요
(욕설 및 비속어는 지양해주세요.)"
        />
      </main>
      <footer>
        <Button
          theme="primary"
          size="small"
          disabled={text.length === 0}
          onClick={async () => {
            try {
              await studentHomeApi.fetchUserOpinion(text)

              toastService.success('의견이 전달 완료되었습니다.', { isMobile })
            } catch (e) {
              toastService.error('전송 실패하였습니다.', {
                subContent: (
                  <S.ToastSubContent
                    onClick={() => {
                      modalService.openModal(<OpinionModal />, {
                        hasCloseButton: true,
                        modalName: 'opinion-modal',
                        modalStyle: {
                          padding: '0px',
                        },
                      })
                    }}
                  >
                    다시 쓰기
                  </S.ToastSubContent>
                ),
                isMobile,
              })
            }

            modalService.closeModal()
          }}
        >
          제출하기
        </Button>
      </footer>
    </S.ModalContainer>
  )
}

const S = {
  ModalContainer: styled.div`
    display: flex;
    flex-direction: column;
    width: 600px;
    height: 434px;

    ${mediaQuery.underTablet} {
      width: 100dvw;
      margin-left: var(--g-safe-area-left);
      margin-right: var(--g-safe-area-right);
    }

    > header {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 64px;
      color: ${colors.gray.$900};
      ${typo.body01};
      font-weight: 700;
    }

    > main {
      padding: 0px 21px;

      > textarea {
        resize: none;
        font-family:
          'Spoqa Han Sans Neo',
          system-ui,
          -apple-system,
          BlinkMacSystemFont,
          'Segoe UI',
          Roboto,
          Oxygen,
          Ubuntu,
          Cantarell,
          'Open Sans',
          'Helvetica Neue',
          sans-serif;

        width: 100%;
        height: 100%;
        padding: 20px;
        border: 1px solid ${colors.gray.$300};
        border-radius: 8px;

        ${typo.body01};

        ::placeholder {
          color: ${colors.gray.$500};
        }
      }
    }

    > footer {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      padding: 14px 0px 20px 0px;

      > button {
        width: 180px;
      }
    }
  `,
  ToastSubContent: styled.p`
    padding: 0px 16px;
    color: ${colors.blue.$400};
    font-weight: bold;
    text-decoration: underline;
  `,
}

export default OpinionModal
