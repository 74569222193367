import { observer } from 'mobx-react'
import { useState } from 'react'
import { isMobileOnly } from 'react-device-detect'

import { DROPDOWN_ITEM_HEIGHT } from '~/@common/constants'
import { useOrientation } from '~/@common/hooks/useOrientation'
import { useRepository } from '~/@common/hooks/useRepository'
import { colors } from '~/@common/styles'
import { Dropdown, DropdownItem } from '~/@common/ui/Dropdown'
import { Icon } from '~/@common/ui/Icon/Icon'

import { STUDENT_WORKSHEET_STATUS_FILTER_OPTIONS } from '../../@common/constants'
import { S } from '../../@common/style/common.style'
import { StudentWorksheetListService } from '../../@service/StudentWorksheetList.service'
import { StudentWorksheetListMobileOptionDrawer } from './StudentWorksheetListMobileOptionDrawer'

export const StudentWorksheetListMobileDropdownFilter = observer(() => {
  const { uiState } = useRepository(StudentWorksheetListService)
  const { type: orientationType } = useOrientation()

  const [drawerOpen, setDrawerOpen] = useState(false)

  const isMobileLandscape = isMobileOnly && orientationType === 'landscape'

  return (
    <>
      <StudentWorksheetListMobileOptionDrawer
        isOpened={drawerOpen}
        onChangeDrawer={(isOpened) => {
          setDrawerOpen(isOpened)
        }}
      />

      <S.Filters>
        <Dropdown
          width={120}
          listHeight={isMobileLandscape ? DROPDOWN_ITEM_HEIGHT * 2 : undefined}
          value={uiState.statusFilterOption}
          onChange={uiState.updateStatusFilterOption}
        >
          {STUDENT_WORKSHEET_STATUS_FILTER_OPTIONS.map((option) => (
            <DropdownItem key={option.value} value={option.value}>
              {option.label}
            </DropdownItem>
          ))}
        </Dropdown>
        <button className="filterOptionsButton" onClick={() => setDrawerOpen(true)}>
          <Icon name="icon_filter" color={colors.blue.$500} size={16} />
        </button>
      </S.Filters>
    </>
  )
})
