import axios, { HttpStatusCode } from 'axios'
import qs from 'qs'

import { TOAST_STRING } from '~/@common/constants/strings'
import { errorHandlerService, toastService } from '~/@common/services'
import { envUtils } from '~/@common/utils/envUtils.ts'

const BASE_URL = (() => {
  if (envUtils.isStaging) {
    return 'https://chat-staging.mathflat.com'
  }
  if (envUtils.isLive) {
    return 'https://chat.mathflat.com'
  }
  return localStorage.getItem('api') ?? 'https://chat-dev.mathflat.com'
})()

const chatAxios = axios.create({
  baseURL: BASE_URL,
  headers: {
    common: { 'x-platform': 'STUDENT' },
    post: { 'Content-Type': 'application/json' },
  },
  paramsSerializer: {
    serialize: (params) => {
      return qs.stringify(params, { arrayFormat: 'comma' })
    },
  },
})

chatAxios.interceptors.response.use(
  (res) => Object.assign(res, { statusCode: res.status }),
  async (error) => {
    // Sentry.captureException(error)

    // 인터넷 연결을 보장하지 못함 (https://developer.mozilla.org/en-US/docs/Web/API/Navigator/onLine)
    // navigator.onLine이 undefined일 수 있어서 false와 비교
    if (navigator.onLine === false) {
      toastService.error(TOAST_STRING.networkOffline)
      return Promise.reject(error)
    }

    const status = error?.response?.status
    const errorCode = error?.response?.data?.code

    if (!status) {
      errorHandlerService.handle(error)
      return Promise.reject(error) // TO-DO: check usage
    }

    if (status === HttpStatusCode.NotFound) {
      return error
    }

    errorHandlerService.handle(error, {
      logType: 'error',
      useRemoteLogging: false,
    })

    return Promise.reject(error)
  },
)

export default chatAxios

export const fetchSSE = (url: string) => {
  return fetch(`${BASE_URL}${url}`, {
    method: 'POST',
    headers: {
      'x-platform': 'STUDENT',
      'x-auth-token': chatAxios.defaults.headers.common['x-auth-token'],
      'Content-Type': 'application/json; charset=utf-8',
    } as any,
  })
}
